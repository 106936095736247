.nda-tab {
  @extend .accordion-item-content-body;

  &__options {
    display: flex;
    flex-direction: column;
  }
  &__radio-container {
    border: 1px solid $investmentBorder;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: none;
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
      @include radio-label;
    }
  }
  .radio-group {
    cursor: pointer;
    &__with-opacity {
      opacity: 0.5;
      cursor: auto;
    }
  }
  .nda-locked-radio {
    margin-bottom: 0rem;
    padding: 2rem;
    border-bottom: 1px solid $borderColor;
    background-color: $darkerWhite;
    @include transition(.3s all ease-in-out);
    &.checked{
      background-color: $white;
      label:before {
        content: '\f00c';
        font-family: FontAwesome;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 1.8rem;
        color: $colorCheck;
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
    & * {
      cursor: inherit;
    }
    &__label{
      @include radio-label;
      position: relative;
      padding-left: 3.8rem;
      margin-bottom: 0;
      line-height: 2rem;
      color: $black;
      @include breakpoint(smallLaptop){
        padding-left: 3.9rem;
      }
      &:after {
        content: '';
        width: 10px;
        height: 10px;
        background: $brand;
        position: absolute;
        top: 5.5px;
        left: 5.2px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
    }
  }
  &__mode {
    margin-top: 1.5rem;
  }
  &__buttons {
    margin-top: 1.5rem;
    padding-bottom: 5px;
    &--save {
      padding: 1.3rem 2.3rem !important;
      font-size: 1.2rem !important;
      font-weight: 500 !important;
      background-color: $brand !important;
      color: $white !important;
      border-radius: 3px !important;
      position: relative !important;
      &[disabled="disabled"] {
        opacity: 0.3;
        cursor: default;
      }
    }
  }
  .preview-nda {
    font-size: 1.1rem;
    color: $brand;
    font-weight: 500;
    font-style: italic;
    line-height: normal;
    font-family: $regular;
    cursor: pointer;
  }
}