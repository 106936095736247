.investor-track-modal {
  display: flex;
  padding: 0;

  .loadingDetails {
    .track-modal-body {
      height: calc(100vh - 13.5rem);
    }
  }

  .do-modal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .track-modal-header {
      width: 100%;
      height: auto;
      min-height: 8.4rem;
      background-color: $white;
      box-shadow: 0 1px 0 0 $investmentTitle;
      border-bottom: 1px solid $investmentTitle;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &--left {
        display: flex;
        align-items: center;

        .investor-image {
          padding: 1.5rem;
          height: 5.4rem;
          width: 5.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 5.4rem;

          img {
            max-width: 100%;
          }

          &--syndicated {
            position: absolute;
            left: 6px;
            top: 5px;

            img {
              height: 20px;
              width: 20px;
            }
          }
        }

        .investor-info {
          padding: 1.5rem 0;
          background-color: inherit;
          box-shadow: none;
          flex-direction: column;
          align-items: normal;
          margin-bottom: 10px;

          .investor-name, .investor-company {
            font-weight: 500;
            word-break: break-all;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
            padding-right: 2rem;
          }

          .investor-name {
            font-size: 2.6rem;

            .badge {
              img {
                width: 1.9rem;
              }
            }
          }

          .investor-company {
            margin-top: 0.5rem;
            font-size: 1.2rem;
            color: $grayLight;
          }
        }
      }

      &--right {
        min-width: 10rem;

        &__buttons {
          margin-right: 1rem;

          span {
            margin-right: 1rem;
            cursor: pointer;
            padding: 1rem 0.8rem 0.6rem;
            background-color: $white;
            border-radius: 50%;

            &:hover {
              background-color: rgba(31, 123, 232, 0.1);

              i {
                color: $brand;
              }
            }

            &.email-icon {
              padding: 1.1rem 0.6rem 0.6rem;
              &:hover {
                i {
                  color: $brand;
                }
              }

              i {
                color: $black;
              }
            }
          }
        }
      }
    }

    .track-modal-body {
      width: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: calc(100vh - 8rem);
      margin-bottom: 8rem;

      .accordion-item {
        background-color: $white;
        box-shadow: 0 1px 0 0 $investmentTitle;
        border-bottom: 1px solid $investmentTitle;
        padding: 2rem 2rem 0.5rem;
        position: relative;
        display: block;

        .my-dropdown-dd {
          position: relative!important; // otherwise it's overwritten by dynamic direct style assignment
        }

        span {
          span {
            display: inline;
          }
        }

        &-title {
          width: 100%;
          display: flex;

          .toggle-accordion {
            width: 4.5rem;

            i {
              font-size: 2rem;
              margin-left: 1rem;
              color: $brand;
              cursor: pointer;
            }
          }

          .accordion-heading {
            margin-bottom: 1.5rem;
            cursor: pointer;
            width: 100%;

            h3 {
              font-size: 1.8rem;
              font-weight: normal;
              text-align: left;
              margin: 0 0 0.5rem;
              color: $brand;
            }

            .subtitle {
              font-size: 1.4rem;
              color: $grayLight
            }
          }
        }

        &-content {
          width: 100%;

          .accordion-item-content-body {
            background-color: $white;
            padding-left: 4.1rem;
            padding-bottom: 1.5rem;
            padding-right: 1.5rem;
            form {
              padding-left: 0;
            }
            .place-order-online-box {
              border-radius: 5px;
              border: 1px solid $color-grey-14;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 1.2rem;
              margin-bottom: 1.5rem;

              .label-text {
                font-size: 1.4rem;
                font-weight: 500;
                color: $black;
              }

              .option {
                font-size: 1.4rem;
                font-weight: 500;
                display: flex;
                align-items: center;

                .switch {
                  position: relative;
                  display: inline-block;
                  margin: 0;
                  width: 26px;
                  height: 11px;
                  background-color: rgba(31, 123, 232, 0.5);
                  border-radius: 5px;
                  cursor: pointer;
                }

                .switch input {display:none;}

                .slider-button {
                  background-color: $white;
                  -webkit-transition: .4s;
                  transition: .4s;
                }

                .slider-button:before {
                  position: absolute;
                  content: "";
                  height: 15px;
                  width: 15px;
                  left: 0;
                  bottom: -3px;
                  background-color: $white;
                  -webkit-transition: .4s;
                  transition: .4s;
                  border: .5px solid $borderColor;
                }

                input:checked + .slider-button:before {
                  background-color: $brand;
                  border: .5px solid $brand;
                }

                input:focus + .slider-button {
                  box-shadow: 0 0 1px $brand;
                }

                input:checked + .slider-button:before {
                  -webkit-transform: translateX(10px);
                  -ms-transform: translateX(10px);
                  transform: translateX(10px);
                }

                .slider-button.round {
                  border-radius: 100%;
                }

                .slider-button.round:before {
                  border-radius: 100%;
                }

                span {
                  color: $color-grey-6;

                  &[class=disallow] {
                    margin-right: 1.5rem;
                  }

                  &[class=allow] {
                    margin-left: 1.5rem;
                  }
                }

                .option-checked {
                  color: $brand;
                }
              }
            }
            .add-new-order {
              width: 12rem;
              height: 4rem;
              color: $white;
              padding: 0;
              position: relative;
              border-radius: 3px;
              background-color: $brand;
              font-weight: 500;
              &:hover {
                background-color: $darkerBrand;
              }
              &:disabled {
                opacity: 0.3;
                cursor: text;
                &:hover {
                  background-color: $brand;
                }
              }
            }
            .placed-orders-block {
              margin-bottom: 1.5rem;

              .single-order {
                min-height: 11rem;
                width: 100%;
                margin-bottom: 1.5rem;

                .single-order-header {
                  height: 3.5rem;
                  background-color: $color-green-dark-1;
                  color: $white;
                  font-size: 1.2rem;
                  font-weight: 500;
                  align-items: center;
                  display: flex;
                  padding-left: 1.5rem;
                  border: 1px solid #7fc137;
                  border-radius: 5px 5px 0 0;
                }

                .single-order-body {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  border: 1px solid $color-grey-14;
                  border-radius: 0 0 5px 5px;

                  .existing-order {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 1.5rem 1rem;

                    .order-data {
                      display: flex;
                      width: 22rem;
                      justify-content: space-between;

                      .amount-wrapper {
                        .amount {
                          span {
                            color: $color-green-dark-1;
                            font-size: 2.6rem;
                            font-weight: 300;
                          }
                        }

                        .amount-label {
                          color: $grayLight;
                          font-size: 1.2rem;
                        }
                      }
                    }

                    .options {
                      span {
                        cursor: pointer;
                        margin-right: 2rem;
                        padding: 1.2rem .8rem .8rem;
                        background-color: $white;
                        border-radius: 50%;
                        &:hover {
                          background-color: rgba(31, 123, 232, 0.1);

                          i {
                            color: $brand;
                          }
                        }

                        i {
                          color: $black;
                        }
                      }
                    }
                  }
                }
              }
            }
            .add-new-order-block {
              display: flex;
              flex-direction: column;
              flex-basis: 100%;
              min-height: 15rem;
              padding: 2rem 2.6rem;
              background-color: $color-green-dark-1;
              border-radius: 5px;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

              .order-input-block {
                display: flex;

                .order-input-box-holder {
                   flex-basis: 100%;
                  .currencyBlock {
                    margin-top: 0;

                    .currency {
                      position: absolute;
                      top: 25.5%;
                      left: 1rem;
                      font-size: 1.5rem;
                      font-weight: normal;
                      color: $color-grey-4;
                      z-index: 1;
                    }

                    input {
                      padding: 1rem 1.5rem 1rem 5rem;
                    }
                  }

                  label {
                    font-size: 1.2rem;
                    font-weight: 500;
                    color: $white;
                  }

                  input {
                    font-size: 1.7rem;
                    font-weight: normal;
                    color: $color-grey-6;
                  }

                  .errorText {
                    display: block;
                    position: absolute;
                    right: 0;
                    color: $red;
                    font-size: 1.2rem;
                  }
                }

                .showError {
                  position: relative;

                  input {
                    border: 1px solid $red;
                  }
                }
              }

              .additional-order-input-block {
                align-items: center;
                margin-top: 1rem;

                .checkbox-container {
                  font-size: 1.2rem;
                  cursor: pointer;
                  color: $white;
                  display: flex;
                  width: fit-content;

                  input {
                    margin: 0 0.9rem 0 0;
                    background-color: $white;
                    width: 1.5rem;
                    height: 1.5rem;
                    cursor: pointer;

                    &::after {
                      color: $positive;
                    }
                  }
                }
              }

              .message {
                display: flex;
                flex-direction: column;
                label {
                  font-size: 1.2rem;
                  font-weight: 500;
                  color: $white;
                  margin-top: 2rem;
                }
                &__box {
                  font-size: 1.5rem;
                  outline: none;
                  border: 1px solid $color-grey-15;
                  transition: 0.3s all ease-in-out;
                  background-color: $color-grey-1;
                  border-radius: 5px;
                  padding: .5rem;
                  color: $color-grey-6;
                  resize: none;
                  font-family: $regular;
                  &:focus, &:hover {
                    background-color: $color-white;
                    border-color: $color-grey-6;
                  }
                }
              }
              .options-block {
                font-size: 1.4rem;
                color: $white;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .options-block-left {
                  display: flex;
                  width: 11rem;
                  justify-content: space-between;
                  align-items: center;
                  .save {
                    background-color: $color-white;
                    border-radius: 5px;
                    color: $color-green-dark-1;
                    padding: 1rem 1.8rem;
                    margin-right: 2rem;
                    font-weight: 500;
                  }
                }

                span {
                  cursor: pointer;
                }
              }
            }

            .investor-feedback {
              width: 100%;
              margin-top: 1.5rem;

              .investor-feedback-track-orders {
                color: $color-grey-6;
                font-size: 1.2rem;
                font-style: italic;
                line-height: 2rem;
                word-break: break-word;
                white-space: pre-wrap;
              }
            }
            .labels-options {
              &__buttons {
                display: flex;
                flex-direction: row;
                &--order-placed,
                &--not-interested,
                &--feedback-sent {
                  display: flex;
                  flex-direction: row;
                  padding: 12px 10px;
                  font-size: 1.2rem;
                  font-weight: 500;
                  color: $white;
                  align-items: center;
                  margin-right: 15px;
                  letter-spacing: 0.4px;
                  border-radius: 3px;
                  span {
                    padding-left: 12px;
                    cursor: pointer;
                  }
                }
                &--order-placed {
                  border: 1px solid $positive;
                  color: #a0d468;
                }
                &--feedback-sent {
                  border: 1px solid $orange-badge;
                  color: #f6bb42;
                }
                &--not-interested {
                  border: 1px solid $red-badge;
                  color: #da4353;
                }
                .order-placed {
                  color: $white;
                  background-color: $positive;
                }
                .feedback-sent {
                  color: $white;
                  background-color: $orange-badge;
                }
                .not-interested {
                  color: $white;
                  background-color: $red-badge;
                }
              }
            }
            .track-data-room-access {
              &__content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;
                &--modify-access {
                  button {
                    font-size: 1.2rem;
                    letter-spacing: 0.4px;
                    width: 13.8rem;
                    height: 4rem;
                    color: $white;
                    padding: 0;
                    position: relative;
                    border-radius: 3px;
                    background-color: $brand;
                    font-weight: 500;
                    &:hover {
                      background-color: $darkerBrand;
                    }
                  }
                }
                &--email {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  margin-right: 15px;
                  .email-update {
                    margin-right: 10px;
                    span {
                      font-size: 1.2rem;
                      font-weight: 500;
                      color: $black;
                    }
                  }
                  .email-button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 6px 8px;
                    background-color: $darkerWhite;
                    cursor: pointer;
                    .email-icon {
                      margin-right: 5px;
                    }
                    .email-number {}
                    span {
                      font-size: 1.2rem;
                      font-weight: 500;
                    }
                  }
                }
              }
            }
            .track-approval-settings {
              &__input-group {
                .radio-container {
                  border-radius: 5px;
                  .radio-group {
                    border-radius: 0;
                    .fa {
                      position: absolute;
                      left: 20px;
                      font-size: 1.8rem;
                      color: $colorCheck;
                    }
                    &:first-of-type {
                        border-top-right-radius: 5px;
                        border-top-left-radius: 5px;
                    }

                    &:last-of-type {
                      border-bottom-right-radius: 5px;
                      border-bottom-left-radius: 5px;
                    }

                    label {
                      @include radio-label;
                      font-size: 1.4rem;
                      cursor: inherit;
                      &.approved {
                        margin-left: 3.8rem;
                      }
                    }
                    p {
                      font-size: 1.4rem;
                      margin-top: 1.5rem;
                      color: $black;
                    }
                    &__with-opacity {
                      opacity: 0.5;
                      cursor: auto;
                    }
                  }
                }
                .common{
                  &__input-box {
                    margin-bottom: 3rem;
                  }
                }
                .approval-not-required {
                  display: flex;
                  flex-direction: row;
                  span {
                    margin-left: 5px;
                    color: $lightGray;
                    text-transform: lowercase;
                  }
                }
              }
              &__buttons {
                padding-bottom: 5px;
                &--save {
                  padding: 13px 23px;
                  font-size: 1.2rem;
                  font-weight: 500;
                  background-color: $brand;
                  color: $white;
                  border-radius: 3px;
                }
              }
            }
            .track-approval-investor {
              display: flex;
              flex-direction: row;
              &__image {
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 35px;
                width: 35px;
                img {
                  max-width: 100%;
                }
              }
              &__info {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                &--approved-date {
                  font-size: 1.2rem;
                  color: $grayLight;
                }
                &--approved-by {
                  font-size: 1.4rem;
                  color: $black;
                }
              }
            }
            .track-timeline {
              &__block {
                display: flex;
                margin-bottom: 2rem;
                &--content {
                  width: 100%;
                  &-title {
                    color: $grayLight;
                    font-size: 1.2rem;
                    margin-bottom: 0.2rem;
                  }
                  &-body {
                    color: $black;
                    font-size: 1.4rem;
                    &-panel {
                      width: 100%;
                      &-heading {
                        height: 3.5rem;
                        color: $white;
                        font-size: 1.2rem;
                        font-weight: 500;
                        align-items: center;
                        display: flex;
                        margin-top: 1.5rem;
                        padding-left: 1.5rem;
                        border-radius: 5px 5px 0 0;
                      }
                      .red {
                        background-color: $red-badge;
                        border: 1px solid $red-badge;
                      }
                      .orange {
                        background-color: $orange-badge;
                        border: 1px solid $orange-badge;
                      }
                      .green {
                        background-color: #7fc137;
                        border: 1px solid #7fc137;
                      }
                      .blue {
                        background-color: $brand;
                        border: 1px solid $brand;
                      }
                      &-body {
                        color: $black;
                        font-size: 1.4rem;
                        line-height: 1.57;
                        padding: 1.5rem;
                        border-radius: 0 0 5px 5px;
                        border: 1px solid $investmentBorder;
                        word-break: break-all;
                      }
                    }
                    &-rating {
                      font-size: 1.2rem;
                      font-weight: 500;
                      line-height: 1.17;
                      margin-top: 1.5rem;
                      display: flex;
                      flex-direction: row;
                      flex-wrap: wrap;
                      div {
                        display: flex;
                        color: $grayLight;
                        width: 33%;
                        margin-bottom: 1rem;
                        span {
                          margin-left: 1rem;
                          color: $black;
                          display: inline-block;
                        }
                      }
                    }
                    &-button {
                      margin-top: 1.5rem;
                      button {
                        width: fit-content;
                        height: 4rem;
                        background-color: $white;
                        border-radius: 3px;
                        font-size: 1.2rem;
                        font-weight: 500;
                        letter-spacing: 0.4px;
                        text-align: center;
                        color: $brand;
                        text-transform: uppercase;
                        cursor: pointer;
                        border: 1px solid $investmentBorder;
                        padding: 1.2rem 1.5rem;
                        position: relative;
                      }
                    }
                    .timeline__message {
                      > a {
                        color: $brand;
                      }
                    }
                  }
                }
              }
              &__no-results {
                text-align: center;
                span {
                  display: inline-block;
                  padding-top: 2rem;
                  font-size: 1.4rem;
                }
              }
              &__image {
                margin-right: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 3.5rem;
                height: 3.5rem;
                img {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }

      .note-block {
        position: fixed;
        left: calc(100% - 680px);
        width: 680px;
        bottom: 0;
        right: 0;
        height: 8rem;
        background-color: $white;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
        @include breakpoint(tabletScreen) {
          left: calc(100% - 540px);
          width: 540px;
        }
        @include breakpoint(mobileScreen) {
          left: 0;
          width: 100%;
        }
        &-input {
          padding: 2rem 1.5rem;
          display: flex;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          input {
            flex-grow: 1;
          }
          .error-text {
            display: block;
            position: absolute;
            right: 9.2rem;
            bottom: 0.7rem;
            color: $red;
          }
          .button {
            width: 6.5rem;
            background-color: $brand;
            color: $white;
            font-size: 1.2rem;
            font-weight: 500;
            letter-spacing: 0.4px;
            position: relative;
            padding: 0;
            &:enabled:hover {
              background-color: $darkerBrand;
            }
            &:disabled {
              opacity: 0.3;
              cursor: default;
            }
          }
        }
        .error-input {
          border: 1px solid $red;
        }
      }
    }
  }
}

.syndicated-details {
  background: $color-blue-main;
  padding: 1.5rem;
  &__text {
    color: $color-white;
    font-size: 1.2rem;
    padding-bottom: 1.5rem;
    margin: 0;
    font-weight: 500;
  }
  &__info {
    background: $color-white;
    border-radius: 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    .advisor-image {
      margin: 1rem;
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .advisor-data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &__left {
        margin-top: 1rem;
        justify-content: flex-start;
        .advisor-name {
          font-size: 1.4rem;
          font-weight: 500;
          margin: 0;
          color: $color-grey-6;
        }
        .advisor-network {
          font-size: 1.2rem;
          font-weight: normal;
          color: $color-grey-4;
          margin-top: 0.3rem;
        }
      }
      &__right {
        justify-content: flex-end;
        padding-right: 2rem;
        .contact-details {
          font-size: 1.2rem;
          color: $color-grey-6;
          text-align: right;
          .fa {
            margin-left: 0.5rem;
            font-size: 1.4rem;
          }
          &.margin {
            margin-top: 0.5rem;
          }
          &.no-telephone {
            margin-top: -1.65rem;
          }
        }
      }
    }
  }
}

.track-tooltip {
  .tooltip-arrow {
    border-color: $black;
  }

  .tooltip-inner {
    padding: 5px 10px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 3px;
    background-color: $black;
  }
}
