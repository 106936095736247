.match{
    margin-top: 13.7rem;
    width: auto;
    padding: 0 5rem;
    &__container{
        background-color: $white;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        max-height: 80vh;
        overflow: hidden;
        &-investors,
        &-companies{
            width: 50%;
            flex-basis: 50%;
        }
        &-investors{
            &-body{
                padding: 0 2.5rem;
                padding-top: 3rem;
                overflow-y: scroll;
                max-height: 70vh;
                padding-bottom: 3rem;
            }
            &-block{
                margin-bottom: 2.5rem;
                cursor: pointer;
            }
            &-highlight{
                display: none;
                &.showInvestor{
                    display: block;
                }
                position: relative;
                .peoples__bigBlock{
                    width: 100%;
                }
                .orangeBox{
                    position: absolute;
                    top: -1rem;
                    left: -1rem;
                    right: -1rem;
                    bottom: 1.5rem;
                    border: 1px solid $brand;
                    content: '';
                    display: block;
                }
                .peoples__smallBodyBlock{
                    display: flex;
                    padding: 0 !important;
                    &:after{
                        display: none;
                    }
                    .peoples__body-info{
                        width: 33.33% !important;
                        padding: 2rem 0 !important;
                        border-right: 1px solid $borderColor;
                        &:last-child{
                            border-right: 0;
                        }
                    }
                }
                .edit__profile{
                    &.red{
                        color: $red;
                    }
                }
                .peoples__bodyBlock{
                    width: 45% !important;
                }
                .peoples__bodyBlockBigger{
                    width: 55% !important;
                }
                .peoples__info{
                    padding: 1.71rem 2rem !important;
                    display: flex;
                }
            }
            &-number{
                background-color: $black;
                color: $white;
                text-align: center;
                font-size: 1.4rem;
                padding: 1rem;
                display: none;
            }
        }
        &-companies{
            &.peoples__bigBlock{
                box-shadow: none;
            }
            &-highlight{
                position: relative;
                background-color: $editorBackground;
                display: none;
                &.showCompanies{
                    display: block;
                }
                .match__container-companies-block{
                    background-color: $white;
                }
                .orangeBox{
                    position: absolute;
                    top: -1rem;
                    left: -1rem;
                    right: -1rem;
                    bottom: -1rem;
                    border: 1px solid $brand;
                    content: '';
                    display: block;
                }
                .edit__profile{
                    &.red{
                        color: $red;
                    }
                }
            }
            &-body{
                padding: 0 2.5rem;
                padding-top: 3rem;
                overflow-y: scroll;
                max-height: 70vh;
                .peoples__info{
                    padding: 1.75rem 2rem !important;
                }
                .peoples__bodyBlock{
                    width: 40% !important;
                }
                .peoples__bodyBlockBigger{
                    width: 60% !important;
                }
            }
            &-block{
                border: 1px solid $borderColor;
                margin-bottom: 2.5rem;
                &.selectedCompany{
                    border: 1px solid $brand;
                    .match__container-companies-number{
                        display: block;
                    }
                }
                cursor: pointer;
                .deal-icon{
                    display: inline-flex;
                }
            }
            .match__container-companies-number{
                background-color: $black;
                color: $white;
                text-align: center;
                font-size: 1.4rem;
                padding: 1rem;
                display: none;
            }
        }
        .peoples__header{
            margin: 1.4rem 2.6rem;
            padding: 0;
            padding-bottom: 1rem;
        }
        .peoples__body-name{
            span{
                font-size: 1.6rem;
            }
            small{
                font-size: 1.2rem;
            }
        }
    }
}
.tooltip {
    position: relative;
    display: inline-block;
    color: $color-white;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 4.5rem;
    background-color: $color-grey-24;
    color: $color-white;
    text-align: center;
    border-radius: 0.4rem;
    padding: 0.5rem;
    position: absolute;
    z-index: 2;
    bottom: 4rem;
    margin-left: -1rem;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
    font-size: 1.2rem;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $color-grey-24 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.tooltip-icon {
    font-size: 2rem;

}
.copy-link-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1.5rem 1rem 2rem;
}
.copy-link-icon-holder {
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    background-color: $brand;
    display: flex;
    align-items: center;
    padding: 0.7rem;
    cursor: pointer;
}
#textToCopy{
    width: 13.5rem;
    display: block;
    overflow: hidden;
    font-size: 1.1rem;
    border: 0;
    font-family: $regular;
    outline: none;
    font-weight: 600;
    &:focus{
        outline: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    @media screen and (min-width: 1800px) {
        width: 16.5rem;
    }
}
#linkToCopy{
    display: block;
    overflow: hidden;
    font-size: 1.2rem;
    border: 0;
    font-family: $regular;
    outline: none;
    font-weight: 500;
    &:focus{
        outline: none;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
