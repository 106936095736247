.track {
    height: 104px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    background-color: $color-white;
    padding: 2rem;

    .track-label {
        width: 47px;
        font-size: 18px;
        font-weight: bold;
        color: $darkBlack;
    }

    .track-bar {
        display: flex;
        height: 30px;
        margin-top: 1rem;

        .track-bar__item {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: bold;
            color: $color-white;

            .investors-count {
                @media (max-width: 600px) {
                    display: none;
                }
            }

            &:first-child {
                border-radius: 15px 0 0 15px;
            }

            &:last-child {
                border-radius: 0 15px 15px 0;
            }

            &:only-child {
                border-radius: 15px;
            }
        }
    }
}
