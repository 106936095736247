.round-progress {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    background-color: $color-white;

    .header {
        height: 24px;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .loading-bar {
        position: relative;
        width: 100%;
        height: 30px;
        box-sizing: border-box;
        border-radius: 15px;
        overflow: hidden;
        border: 1px solid $color-white-grey;
        background-color: $color-white;

        .percentage {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;
            border-bottom-left-radius: 15px;
            background-color: $color-green-dark-1;

            .value {
                float: right;
                color: $color-white;
                font-size: 14px;
                margin-right: 5px;
                &.in-the-middle {
                    color: $color-green-dark-1;
                    position: absolute;
                    left: 20px;
                }
            }

            &.zero-percentage {
                background-color: $color-cyan-secondary;
                .value {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    width: 32px;
                    position: absolute;
                    left: 0;
                    background: $color-cyan-secondary;
                    color: $color-white;
                }
            }
        }
    }

    .info {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        margin-top: 10px;

        .interest {
            color: $color-green-dark-1;

            &.zero-cost {
                color: $color-cyan-secondary;
            }
        }

        .days-left {
            font-weight: bold;
            color: $darkBlack;
        }
    }
}
