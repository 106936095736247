@media print {
  * {
    -webkit-print-color-adjust:exact;
    -webkit-transition: none !important;
    transition: none !important;
  }
  body {
    background-color: #ffffff !important;
    font-size: 12pt;
  }
  p {
    color: black !important;
    display: block;
  }
  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
    page-break-before: avoid;
  }
  table, pre {
    page-break-inside: avoid
  }
  ul, ol, dl  {
    page-break-before: avoid;
  }

  .do-originator {
    &__container,
    .do-navigation,
    .homeLink {
      display: none;
    }
    .layer-container {
      position: absolute;
      left: 0;
      top: 0 !important;
    }
  }
}
