.new-investor-creation {
    overflow: hidden;
    overflow-y: auto;
    margin: 20px 30px;
    @include multiple-media(tabletScreen, mobileScreen) {
        margin: 0;
    }

    &__two_colums {
        display: flex;
        @include breakpoint(mobileScreen) {
            flex-direction: column;
        }

        > div {
            @include multiple-media(largeScreen, mediumScreen, smallScreen, tabletScreen) {
                flex: 50%;
                &:first-child {
                    margin-right: 0.9rem;
                }
                &:last-child {
                    margin-left: 0.9rem;
                }
            }
        }
    }

    &__search-input {
        margin-left: 3.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;

        .newForm__input {
            width: 86%;
        }

        button {
            margin-left: 20px;
            margin-top: 20px;
            padding: 1.6rem 2rem;
        }
    }

    &__import-item {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 1.5rem 0;
        border-top: 1px solid $color-grey-3;
        font-size: 1.6rem;
        font-weight: 500;

        &:last-child {
            border-bottom: 1px solid $color-grey-3;
            margin-bottom: 1.5rem;
        }

        .import-item-id {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 5px;
        }

        .import-item-note {
            font-size: 1.8rem;
            color: $color-grey-6;
            margin: 0 0 0 2.8rem;
            display: inline-block;
            width: 90%;
        }

        .fa-cloud-download,
        .fa-cloud-upload {
            color: $color-blue-main;
            cursor: pointer;
            font-size: 2.5rem;

            &:hover {
                color: $color-blue-dark;
            }
        }

        .import-contacts {
            width: 97%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__link {
                margin-left: 2.8rem;
                font-size: 1.8rem;
                color: $color-blue-main;
                cursor: pointer;

                &:hover {
                    color: $color-blue-dark;
                }
            }
        }
    }

    .match-creation-layer-buttons {
        display: flex;

        a {
            font-size: 1.1rem;
        }

        button {
            padding: 1.5rem 2rem;
            margin-top: 0;
            margin-right: 0.9rem;
        }

    }

    .uploaded-file {
        width: 91%;
        margin-left: 3.8rem;
    }

    .my-dropdown-dd {
        margin: 0;
        padding-bottom: 20px;
    }

    .section-title {
        background-color: transparent;
        padding: 15px 25px;
    }

    .accordion-item {
        background-color: white;
        border: 1px solid $color-grey-2;
        border-bottom-width: 0;

        &:first-of-type {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }

        &:last-of-type {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            border-bottom-width: 1px;
        }

        &-content {
            padding: 0 20px;

            .investor-type-label {
                text-transform: unset;
            }

            .submit-button {
                padding: 15px 25px;
                text-transform: none;
                font-size: 14px;
            }

            input {
                background-color: $color-white;
                border-radius: 5px;
            }

            > div {
                .loader-position {
                    text-align: right;
                    margin-top: -49px;
                    margin-right: 10px;
                    margin-bottom: -16px;
                }
            }

            .uploaded-file-error_header {
                .uploaded-file-error_footer {
                    .add-v-scroll-error {
                        min-height: auto;
                        max-height: 35vh;
                        overflow-y: auto;
                    }
                }
            }
        }
    }
}
