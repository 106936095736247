$toBigPhone: 639px;
$bigPhone: 640px;
$phone: 767px;
$tablet: 768px;
$toTabletLandscape: 959px;
$tabletLandscape: 960px;
$toDesktop: 1024px;
$desktop: 1025px;
$toSmallLaptop: 1279px;
$smallLaptop: 1280px;
$ipadPro: 1366px;
$toLaptop: 1599px;
$laptop: 1600px;
$toBigScreen: 1919px;
$bigScreen: 1920px;
$hugeScreen: 2048px;
//-------------------------
//--------MIXIN BREAKPOINTS
//-------------------------
// EXAMPLE
//   @include breakpoint (phone) {
//       width: 40%;
//   }
//
@mixin breakpoint($point) {
    // from 2048px
    @if $point==hugeScreen {
        @media (min-width: $hugeScreen) {
            @content;
        }
    }
    // from 1920px
    @if $point==bigScreen {
        @media (min-width: $bigScreen) {
            @content;
        }
    }
    // from 1025px
    @else if $point==desktop {
        @media (min-width: $desktop) {
            @content;
        }
    }
    // from 1025px to 1279px
    @else if $point==miniLaptop {
        @media (min-width: $desktop) and (max-width: $toSmallLaptop) {
            @content;
        }
    }
    // from 1280px to 1919px
    @else if $point==laptop {
        @media (min-width: $smallLaptop) and (max-width: $toBigScreen) {
            @content;
        }
    }
    // from 1600px to 1919px
    @else if $point==bigLaptop {
        @media (min-width: $laptop) and (max-width: $toBigScreen) {
            @content;
        }
    }
    // from 1280px to 1599px
    @else if $point==smallLaptop {
        @media (min-width: $smallLaptop) and (max-width: $toLaptop) {
            @content;
        }
    }
    // from 960px to 1279px
    @else if $point==tabletLandscape {
        @media (min-width: $tabletLandscape) and (max-width: $toSmallLaptop) {
            @content;
        }
    }
    // 1366px and orientation landscape (this is only for iPadPro)
    @else if $point==ipadProLandscape {
        @media only screen and (min-device-width: $ipadPro) and (max-device-width: $ipadPro) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
            @content;
        }
    }
    // to 1024px
    @else if $point==toDesktop {
        @media (max-width: $toDesktop) {
            @content;
        }
    }
    // from 320px to 959px (all mobile and tablet devices)
    @else if $point==devices {
        @media (min-width: 320px) and (max-width: $toTabletLandscape) {
            @content;
        }
    }
    // from 320px to 600px  (mobile and tablet devices)
    @else if $point==smallDevices {
        @media (min-width: 320px) and (max-width: 600px) {
            @content;
        }
    }
    // from 639px to 959px
    @else if $point==tabletPortrait {
        @media (min-width: $toBigPhone) and (max-width: $toTabletLandscape) {
            @content;
        }
    }
    // from 640px to 767px
    @else if $point==smallTablet {
        @media (min-width: $bigPhone) and (max-width: $phone) {
            @content;
        }
    }
    // from 768px to 1024px
    @else if $point==tablet {
        @media (min-width: $tablet) and (max-width: $toDesktop) {
            @content;
        }
    }
    // to 640px
    @else if $point==toBigPhone {
        @media (max-width: $bigPhone) {
            @content;
        }
    }
    // to 767px (all mobile devices)
    @else if $point==toPhone {
        @media (max-width: $phone) {
            @content;
        }
    }
    // to 850px (all mobile devices landscape)
    @else if $point==toPhoneLandscape {
        @media (max-width: 1023px) and (orientation: landscape) {
            @content;
        }
    }

/*************************** Use these breakpoints. Don't use old ones. ******************************/

    // from 0px to 450px
    @else if $point==smallMobileScreen {
        @media (min-width: 0px) and (max-width: 450px) {
            @content;
        }
    }
    // from 0px to 599px
    @else if $point==mobileScreen {
        @media (min-width: 0px) and (max-width: 599px) {
            @content;
        }
    }
    // from 0px to 600px
    @else if $point==mobileScreen2 {
        @media (min-width: 0px) and (max-width: 600px) {
            @content;
        }
    }
    // from 0px to 800px
    @else if $point==allMobileScreen {
        @media (min-width: 0px) and (max-width: 900px) {
            @content;
        }
    }
    // mobiles up to 599px height in landscape orientation
    @else if $point==mobileScreenLandscape {
        @media (max-height: 599px) and (max-width: 830px) and (orientation: landscape) {
            @content;
        }
    }
    // from 600px to 899px
    @else if $point==tabletScreen {
        @media (min-width: 600px) and (max-width: 899px) {
            @content;
        }
    }
    // from 610px to 900px
    @else if $point==tabletScreen2 {
        @media (min-width: 610px) and (max-width: 900px) {
            @content;
        }
    }
    // from 600px to 1366px in landscape orientation
    @else if $point==tabletScreenLandscape {
        @media (min-width: 600px) and (max-width: 1366px) and (min-height: 600px) and (max-height:1024px) and (orientation: landscape) {
            @content;
        }
    }
    // from 900px to 1199px
    @else if $point==smallScreen {
        @media (min-width: 900px) and (max-width: 1199px) {
            @content;
        }
    }
    // from 1200px to 1799px
    @else if $point==mediumScreen {
        @media (min-width: 1200px) and (max-width: 1799px) {
            @content;
        }
    }
    // from 1800px
    @else if $point==largeScreen {
        @media (min-width: 1800px) {
            @content;
        }
    }
}

@mixin multiple-media($media...) {
    @each $query in $media {
        @include breakpoint($query) {
            @content
        }
    }
}

// Retina mixin
@mixin retina {
    @media only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-device-pixel-ratio: 2) { @content; }
}

// Windows 150% zoom mixin
@mixin windowsZoom {
    @media only screen and (max-width: $bigScreen) {
        @media only screen and (min-resolution: 120dpi) {
            @media only screen and (max-device-pixel-ratio: 1.99),
            only screen and (-webkit-max-device-pixel-ratio: 1.99),
            only screen and (max--moz-device-pixel-ratio: 1.99) {
                @content;
            }
        }
    }
}
