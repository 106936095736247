.v-toaster {
  position: fixed;
  top: 50px;
  left: 0;
  z-index: 10000;
  width: 30rem;
  padding-left: 10px;
  padding-right: 10px;
  a {
    cursor: pointer;
  }
  .v-toast {
    display: block;
    width: 30rem;
    background-color: $white;
    font-family: $regular;
    font-size: 1.3rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $black;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 0;
    margin-bottom: 2px;

    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
      width: 40rem;
    }

    span {
      display: inline-block;
      word-break: break-word;
    }
    .v-toast-btn-clear {
      background: transparent;
      border: 0;
      color: $dark-gray;
      opacity: 0.45;
      text-decoration: none;
      float: right;
      cursor: pointer;
    }
    a {
      color: $brand;
      font-size: 1.3rem;
      font-weight: 500;
      text-transform: uppercase;
    }
    a.fa {
      color: $dark-gray;
      display: inline-block;
      float: right;
    }
    .message-holder {
      padding: 2.4rem 2rem;
      position: relative;
      .fa-times {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
      }
    }
    .action-items {
      border-top: 1px solid #e7e9ed;
      padding: 1.2rem 2rem;
    }
    .v-toast-btn-clear:hover {
      opacity: 0.85;
    }
    .flag-top{
      position: absolute;
      top: 0;
      left: 0;
      width: 2.5rem;
      height: 2.5rem;
      overflow: hidden;
      border-top-left-radius: 5px;
      &:after{
        transform: rotate(45deg);
        background: white;
        width: 5rem;
        height: 5rem;
        position: absolute;
        bottom: -3rem;
        right: -3rem;
        content: '';
      }
    }
    &.v-toast-success{
      .flag-top{
        background-color: $positive;
      }
      .action-items{
        a{
          color: $positive;
        }
      }
    }
    &.v-toast-warning{
      .flag-top{
        background-color: #f6bb42;
      }
      .action-items{
        a{
          color: #f6bb42;
        }
      }
    }
    &.v-toast-error{
      .flag-top{
        background-color: $red;
      }
      .action-items{
        a{
          color: $red;
        }
      }
    }
  }
  .v-toast.v-toast-primary {
    background: white;
  }
  .v-toast.v-toast-info {
    background: white;
  }
  .v-toast.v-toast-error {
    background: white;
  }
  .v-toast.v-toast-enter,
  .v-toast.v-toast-leave-to {
    -webkit-transform: translate(100%);
    transform: translate(100%);
  }
  .v-toast.v-toast-success {
    background: white;
  }
  .v-toast.v-toast-warning {
    background: white;
  }

}
