.new-company-creation,
.new-investor-creation,
.new-deal-creation {
  .modal-container {
    .buttonSave {
      padding: 0px;
      margin: 0 0 0 1.75rem;
      background-color: transparent;
      &:disabled {
        margin-top: 0;
        margin-left: -2.75rem;
        &:hover {
          background-color: transparent;
        }
      }
    }
    .ladda-spinner {
      left: 4rem !important;
      > div{
        div{
          background-color: $black !important;
        }
      }
    }
  }
}