.reset-password-background.proraise-image-background {
    background-image: url('/images/group.png');
}

body.hubx-password-reset-page {
    font-family: Nunito, sans-serif;
    font-size: 1.6rem !important;

    .login__holder .fos_user_resetting_reset input[type=submit] {
        border-radius: 5px;
    }

    .info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0;

        @include multiple-media(mobileScreen, tabletScreen) {
            padding: 20px;
        }

        @include breakpoint(smallScreen) {
            padding: 40px;
        }

        @include multiple-media(mediumScreen, largeScreen) {
            padding: 65px;
        }

        &--title {
            font-size: 18px;
            margin: auto;
            font-family: 'NunitoBold', sans-serif;
            text-shadow: 0 0 15px rgba(0, 0, 0, 0.52);
            line-height: 1.13;
            color: $color-white;

            @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                margin: 0 0 4.5rem 0;
            }

            @include multiple-media(smallScreen, tabletScreen) {
                font-size: 36px;
            }

            @include multiple-media(mediumScreen, largeScreen) {
                font-size: 60px;
            }

            @include multiple-media(mobileScreen, tabletScreen) {
                .break {
                    display: none;
                }
            }

        }

        .info-block {
            font-family: 'NunitoBold', sans-serif;
            font-size: 1.8rem;
            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
            color: $color-white;
            line-height: 1.56;

            @media screen and (max-width: 899px) {
                display: none;
            }

            &--title {
                padding-bottom: 4.5rem;
                display: flex;
            }

            &--footer {
                padding-top: 4.5rem;
                display: flex;
            }

            .info-items {
                display: flex;
                flex-direction: column;
                min-width: 40rem;
            }
        }
    }
}
body.proraise-password-reset-page {
    font-family: Nunito, sans-serif;
    font-size: 1.6rem !important;

    .login__holder .fos_user_resetting_reset input[type=submit] {
        border-radius: 5px;
    }
    .founders-sign .founders-info {
        width: 100%;
        color: #390153;
    }

    .info {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 0;
        color: inherit;

        @include multiple-media(mobileScreen, tabletScreen) {
            padding: 20px;
        }

        @include breakpoint(smallScreen) {
            padding: 40px;
        }

        @include multiple-media(mediumScreen, largeScreen) {
            padding: 65px;
        }

        &--title {
            font-size: 18px;
            margin: auto;
            font-family: 'NunitoBold', sans-serif;
            line-height: 1.13;
            color: #390153;

            @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                margin: 0 0 4.5rem 0;
            }

            @include multiple-media(smallScreen, tabletScreen) {
                font-size: 36px;
            }

            @include multiple-media(mediumScreen, largeScreen) {
                font-size: 60px;
            }

            @include multiple-media(mobileScreen, tabletScreen) {
                .break {
                    display: none;
                }
            }

        }

        .info-block {
            font-family: 'Nunito', sans-serif;
            font-size: 1.9rem;
            line-height: 1.56;
            color: #390153;
            @media screen and (max-width: 899px) {
                display: none;
            }

            &--title {
                padding-bottom: 4.5rem;
                display: flex;
            }

            &--footer {
                padding-top: 4.5rem;
                display: flex;
            }

            .info-items {
                display: flex;
                flex-direction: column;
                min-width: 40rem;
            }
        }
    }
}
