.investor-edit {
  form {
    display: flex;
    flex-wrap: wrap;
    & > div:nth-of-type(3n+3) {
      margin-right: 0;
    }
    .text-input, .select-input {
      margin-bottom: 1rem;
      flex: 0 0 32%;
      margin-right: 2rem;
      @-moz-document url-prefix() {
        flex: 0 0 31.9%;
      }
      @include breakpoint(smallLaptop) {
        flex: 0 0 31.7%;
        margin-bottom: 2rem;
        @-moz-document url-prefix() {
          flex: 0 0 31.8%;
        }
      }
      @include breakpoint(mobileScreen) {
        flex: 0 0 100%;
      }
      @include multiple-media(tabletScreen, smallScreen) {
        flex: 0 0 49% !important;
        margin-right: 0 !important;
        &:nth-child(even) {
          margin-left: 1%;
        }
      }
    }
    .select-group {
      margin-right: 0.5rem;
      @include multiple-media(tabletScreen, smallScreen) {
        margin-left: 1%;
      }
    }
    .do-security-block-footer {
      margin-top: 2rem;
      width: 11rem;
      padding: 1.2rem 3rem;
    }
    .client-types {
      margin-top: 0.5rem;
      width: 100%;
      h3 {
        display: block;
        margin-bottom: 1rem;
        color: $dark-gray;
        font-size: 1rem;
        font-weight: 500;
        text-transform: none;
        text-align: inherit;
      }
      .table {
        border: 2px solid $borderColor;
        padding: 2rem;
        .checkbox-wrapper {
          width: 33.33%;
          display: inline-block;
          vertical-align: top;
          .checkbox {
            display: block;
            position: relative;
            user-select: none;
            margin-right: 6rem;
            margin-bottom: 1.7rem;
            font-size: 1.2rem;
            vertical-align: middle;
            padding-left: 3.5rem;
            input {
              position: absolute;
              opacity: 0;
              &:checked ~ .checkmark {
                text-align: center;
                color: $brand;
                position: absolute;
                &:before {
                  font-family: FontAwesome;
                  content: '\f00c';
                }
                &:after {
                  display: block;
                }
              }
            }
            .checkmark {
              top: -0.1rem;
              height: 1.5rem;
              width: 1.5rem;
              position: absolute;
              left: 0;
              background-color: $white;
              border: 2px solid $investmentBorder;
              border-radius: 5px;
              &:after {
                content: "";
                position: absolute;
                display: none;
              }
            }
            &:hover input ~ .checkmark {
              background-color: $white;
              border-color: $black;
            }
          }
        }
      }
    }
  }
  .investorMask {
    z-index: 1000;
  }
  .modal-container {
    z-index: 1001;
    .footer-button {
      padding: 0;
      .buttonSave {
        background-color: transparent;
        width: auto !important;
        padding-right: 0;
        &:hover {
          background-color: transparent;
        }
        &:disabled {
          background-color: transparent;
          opacity: 1;
        }
      }
    }
  }

}