.do-deals {
  .layer-body__top-left-single-item {
    margin-left: 0rem;
  }
  .layer-body__top-single__dropdown {
    .todo-widget__filters-older ul li:last-child {
      margin-top: .5rem;
      margin-bottom: 0;
    }
  }
  .do-modal {
    h1 {
      margin: 1.5rem 0px 2.5rem !important;
      word-break: break-word;
      overflow-wrap: break-word;
    }
  }
  .layer-body__top-left-single {
    h1 {
      @include multiple-media(mobileScreen, tabletScreen) {
        display: none;
      }
    }
    .todo-widget__filters-older {
      cursor: pointer;
      &:hover {
        a.is-active {
          cursor: default;
          &:hover {
            cursor: default;
          }
        }
        a.is-active i.fa-caret-down {
          cursor: pointer;
        }
      }
    }
  }
  .layer-body__top-right-single {
    @include multiple-media(mobileScreen) {
      display: none;
    }
  }
}
