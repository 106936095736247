.compact-navigation-menu-mask {
	position: fixed;
	top: 0;
	left: initial;
	right: -100%;
	bottom: 0;
	background-color: transparentize($color-grey-6, .5);
	z-index: 2;
	box-sizing: border-box;
	&--open {
		left: 0;
		right: -.3rem;
	}
	.compact-navigation-menu {
		width: 100%;
		position: fixed;
		top: 0;
		bottom: 0;
		background-color: $color-grey-2;
		z-index: 3;
		left: 100vw;
		transition: all .35s ease-in-out;
		&__content {
			height: 100vh;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding-top: 4rem;
			left: 100vw;
			transition: all .35s ease-in-out;
		}
		&--open {
			left: 0;
			right: -.3rem;
			@include multiple-media(smallScreen, mediumScreen, largeScreen) {
				left: calc(100vw - 40.5%);
			}
		}
		@include multiple-media(smallScreen, mediumScreen, largeScreen) {
			min-width: initial;
			max-width: 40.5%;
		}
		&__header {
			position: absolute;
			top: 0;
			width: 100%;
			background-color: $color-white;
			text-align: right;
			border-top: .1rem solid $color-grey-2;
			padding: 1rem;
			box-sizing: border-box;
			&__link {
				text-transform: uppercase;
				font-size: 12px;
				color: $color-blue-main;
				cursor: pointer;
			}
		}
		&__link {
			text-transform: uppercase;
			font-size: 24px;
			color: $color-grey-6;
			margin-bottom: 5rem;
			max-width: 80%;
			text-align: center;
			cursor: pointer;
			&:hover,
			&:active {
				color: $color-blue-main;
			}
		}
	}
}
