.hubx-inputs {
    display: inline-flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    &.activated {
        textarea.hubx-input {
            background-color: $color-white;
        }
    }
    label {
        font-size: 1.4rem;
        display: block;
        color: $color-grey-6;
        font-weight: 500;
        order: 1;
        margin-bottom: .8rem;
    }
    textarea.hubx-input {
        width: 100%;
        box-sizing: border-box;
        outline: none;
        border-radius: .5rem;
        color: $color-grey-6;
        font-family: $regular;
        padding: 1.1rem 1.5rem;
        border: .1rem solid $color-grey-2;
        font-size: 1.4rem;
        background-color: $color-grey-1;
        outline: 0;
        order: 2;
        font-weight: normal;
        &:hover {
            border-color: $color-grey-3;
        }
        &:focus {
            background-color: $color-white;
            &::placeholder {
                display: none;
            }
        }
        &:active {
            background-color: $color-white;
            border-color: transparentize($color-grey-2, .5);
        }
        &:disabled {
            background-color: $color-grey-1;
            border-color: $color-grey-2;
            opacity: .5;
            pointer-events: none;
            ~ label.hubx-input-label {
                opacity: .5;
            }
        }
        &::placeholder {
            opacity: .5;
            font-style: italic;
            &:not(:placeholder-shown) {
                border: .1rem solid $color-grey-2;
                background-color: $color-white;
                &:hover {
                    border-color: $color-grey-3;
                }
            }
        }
    }
    &.error {
        textarea.hubx-input {
            border-color: $color-red-main;
            background-color: $color-white;
        }
    }
    &.showError {
        textarea.hubx-input {
            border-color: $color-red-main;
            background-color: $color-white;
        }
        span.hubx-errorText {
            position: relative;
            order: 3;
            color: $color-red-main;
            font-size: 1.2rem;
            margin-top: .8rem;
        }
    }
    a.hubx-input-link {
        color: $color-blue-main;
        font-size: 1.2rem;
        order: 3;
        margin: .8rem 0 0 auto;
        width: fit-content;
        max-width: 100%;
        @include break-words-nicely;
        &:hover {
            cursor: pointer;
        }
    }
}
