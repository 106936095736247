.add-new-seat {
    padding: 0;
    background-color: #fafbfd;

    &__title {
        box-shadow: -2px 5px 12px -6px $lightGray;
        font-size: 1.8rem;
        font-weight: 600;
        padding-top: 2rem;
        margin-bottom: 3rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        background-color: white;
    }
    &__content {
        width: 100%;
        flex-basis: 100%;
        display: block;
    }

    .hubx-inputs {
        margin-bottom: 25px;
        color:#727a82;

        .hubx-input {
            background-color: white;
        }

        .hubx-input-label {
            color:#727a82;
        }
    }

    .name-inputs {
        display: flex;
        flex-direction: row;
    }

    .name-inputs > div:first-child {
        margin-right: 15px;
    }

    button[type=submit] {
      background-color: $brand;
    }

    height: 100%;
    overflow-y: auto;

    form {
        background: transparent;
        padding: 25px;
        margin-bottom: 20px;
    }

    &--select-label {
        font-size: 1.4rem;
        color: #727a82;
        font-weight: 500;
    }

    &--select-radio {
        margin-bottom: 25px;
        margin-top: 10px;
        border: 1px solid $color-grey-3;
        border-radius: 3px;

        &--button {
            .hubx-radio {
                > label {
                    padding-top: 0 !important;
                }
            }

            cursor: pointer;
            padding: 2rem 2rem;
            background-color: $color-grey-1;

            &:not(:last-child) {
                border-bottom: 1px solid $color-grey-3;
            }

            &__title {
                text-transform: uppercase;
                font-size: 1.4rem;
                font-weight: 600;
                line-height: 2rem;
            }

            &__subtitle {
                margin-bottom: 0;
                font-size: 1.4rem;
                line-height: 1.46;
                letter-spacing: normal;
                text-align: justify;
                color: $color-grey-5;
                margin-top: 0;
            }
        }
        .checked {
            background-color: $color-white;
        }
    }
}
