.new-firm-creation {
  overflow: hidden;
  overflow-y: auto;
  form {
    padding: 0 2rem 0 2rem;
    background: $white;
    border-bottom: 1px solid $borderColor;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    input {
      outline: none;
      border: none;
      padding: 1.7rem 2rem;
      font-family: $regular;
      font-size: 1.2rem;
      font-weight: 500;
      background-color: $white;
      width: 100%;
    }
  }
  > div {
    display: flex;
    padding-bottom: 1rem;
    margin-bottom: 2.2rem;
  }
  .firmList {
    min-height: calc(100vh - 20rem);
    @include radioGroupMixin;
    .radio-group {
      label {
        font-size: 1.4rem;
        position: relative;
        bottom: 7px;
        margin-top: 11px;
      }

      p {
        margin-bottom: 0;
        font-size: 1.4rem;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: justify;
        color: $black;
        padding-left: 3.8rem;
        margin-top: 0;
      }

      &:first-of-type {
        border-top: 1px solid #ececec;
      }
      padding: 1.5rem;
    }
  }
  &__search-component {
    .firmList {
      min-height: calc(100vh - 23rem);
    }
  }
  .emptyFirmList {
    text-align: center;
    font-size: 1.4rem;
    padding-bottom: 4rem;
    padding-top: 4rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: $grayLight;
  }
}
