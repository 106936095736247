@mixin radio-label{
  display: flex;
  font-size: 1.4rem;
  font-weight: 500;
  text-transform: none;
  span {
    margin-left: 5px;
    color: $grayLight;
    text-transform: lowercase;
    font-weight: normal;
  }
}