.do-industry{
  .layer-body__middle-holder{

    overflow: hidden;
    overflow-y: auto;
    @include breakpoint (toDesktop){
      min-height: auto;
      max-height: inherit;
      margin-bottom: 0;
    }
    @include breakpoint(toBigPhone) {
      max-height: calc(100vh - 150px);
    }
  }
  .container-height {
    @include breakpoint(laptop) {
      max-height: calc(100vh - 170px);
    }
  }
}
