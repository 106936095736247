@mixin founderButton {
    font-size: 1.6rem;
    font-family: NunitoBold, sans-serif;
    color: $color-white;
    padding: 1.6rem 3rem;
    border: none;
    border-radius: 5px;
    background-color: $founderDarkerBrand;
    width: fit-content;
    cursor: pointer;
    outline: none;

    @include multiple-media(mobileScreen) {
        width: 100%;
    }
}

.founder-team {
    font-family: Nunito, sans-serif;

    .big-container__right__main__side-bar {
        display: none;
    }

    .modal-layer-mask {
        z-index: 1001;
    }

    &-section {
        display: flex;
        padding: 0 30px;
        flex-grow: 1;
        flex-direction: column;

        .founder-team-block {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            &__head {
                display: flex;
                align-items: center;
                margin-top: 1.5rem;
                margin-bottom: 2rem;
                background-color: transparent;
                padding: 0 2rem;

                .team-head-item {
                    flex: 1 1 0;
                    display: flex;
                    align-items: flex-end;

                    @include multiple-media(mobileScreen) {
                        display: none;
                    }

                    &__title {
                        color: $color-grey-21;
                        font-size: 1.4rem;
                        cursor: pointer;
                    }

                    .material-icons {
                        font-size: 1.6rem;
                        color: $color-grey-21;
                    }
                }
            }

            &__items {
                display: flex;
                flex-direction: column;

                .team-member {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    border-radius: 5px;
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                    background-color: $color-white;
                    padding: 2rem;
                    margin-bottom: 2rem;

                    @include multiple-media(mobileScreen) {
                        position: relative;
                        padding: 2rem 2rem 3.5rem 2rem;
                    }

                    &__name {
                        display: flex;
                        align-items: center;
                        flex: 1 1 0;
                        font-size: 1.6rem;
                        color: $darkBlack;

                        &__profile {
                            width: 4rem;
                            max-height: 4rem;
                            border-radius: 5px;

                            &--image {
                                width: 100%;
                                max-height: 100%;
                            }
                        }

                        &--value {
                            margin-left: 1.5rem;
                            font-family: NunitoBold, sans-serif;
                            font-size: 1.6rem;
                            color: $darkBlack
                        }
                    }

                    &__role {
                        flex: 1 1 0;

                        @include multiple-media(mobileScreen) {
                            position: absolute;
                            bottom: .5rem;
                            flex: 0;
                        }

                        &--title {
                            font-size: 1.6rem;
                            color: $darkBlack;
                        }
                    }

                    &__login {
                        flex: 1 1 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        @include multiple-media(mobileScreen) {
                            justify-content: flex-end;
                            flex: 0;
                        }

                        .more-items__hub-block {
                            right: 38px;
                            min-width: auto;
                        }

                        &--date {
                            font-size: 1.6rem;
                            color: $darkBlack;

                            @include multiple-media(mobileScreen) {
                                display: none;
                            }

                        }

                        &__option {
                            display: flex;
                            align-items: center;

                            .material-icons {
                                font-size: 1.6rem;
                                color: $founderBrand;
                            }

                            &__title {
                                font-size: 1.6rem;
                                padding-left: 0;
                            }
                        }

                        .more-items--horizontal {
                            color: $founderBrand;
                        }
                    }
                }
            }

            &--add {
                @include founderButton;
            }
        }
    }

    .seat-remove {
        &__modalTitle {
            font-size: 1.4rem;
            text-align: left;
            font-weight: 500;
            border-bottom: 1px solid $layerBorder;
            margin-top: 2rem;
            margin-bottom: 3rem;
            padding-bottom: 1rem;
        }

        .button {
            @include founderButton;
        }

        .do-details [type="radio"]:checked + label:after, 
        .do-details [type="radio"]:not(:checked) + label:after {
            background-color: $founderDarkerBrand;
        }
    }
}
