.custom-table {
  @include custom_table();
  margin: 2rem 0;
  overflow-x: auto;
  @media not all and (min-resolution: .001dpcm) {
    padding-top: 2px;
  }
  .rowBlock {
    &--add {
      background: $white;
      margin-bottom: 2px;
      @media not all and (min-resolution: .001dpcm) {
        margin-top: 4px;
      }
    }
    &--square {
      background: $white;
      margin-top: 4px;
    }
    &--empty {
      padding: 2.4rem;
      border: 1px solid $borderColor;
      @media not all and (min-resolution: .001dpcm) {
        height: 45px;
        padding: 0;
      }
    }
    &--addColumn {
      height: 100%;
      margin-top: -3px;
    }
  }

  >div {
    background-color: $darkerWhite;
    input {
      margin-top: 0 !important;
      &:focus {
        background-color: $darkerWhite;
      }
    }
  }
}
.custom-black-table {
  @include custom_table();
  overflow-x: scroll;
  .columnFirst {
    @media not all and (min-resolution: .001dpcm) {
      margin-top: 2px;
    }
  }
  .rowBlock {
    &--add {
      background: $darkBlack;
      @media not all and (min-resolution: .001dpcm) {
        margin-top: 2px;
        margin-bottom: auto;
      }
    }
    &--square {
      background: $darkBlack;
    }
    &--empty {
      padding: 2.4rem;
      @media not all and (min-resolution: .001dpcm) {
        height: 45px;
        padding: 0;
      }
    }
  }
  >div {
    background-color: $darkBlack;
    input {
      &:focus {
        background-color: $black;
      }
    }
  }
}
