.pipeline  {
	@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
		overflow: hidden;
	}
	@include breakpoint(mobileScreen) {
		padding: 1.3rem 1rem 3rem;
		height: calc(100vh - 10rem);
	}
	@include breakpoint(tabletScreen) {
		padding: 2.3rem 4rem 3rem;
		height: calc(100vh - 11rem);
	}
	@include breakpoint(smallScreen) {
		padding: 0 4rem 3rem;
		height: calc(100vh - 11rem);
	}
	@include breakpoint(mobileScreenLandscape) {
		padding: 0 2rem 0;
		height: calc(100vh - 6rem);
	}
	&-container {
		@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
			height: 100%;
			overflow: hidden;
		}
		.layer-body {
			&__top {
				@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
					padding-bottom: 1.7rem;
					margin-bottom: 2rem;
					align-items: baseline;
				}
				@include breakpoint(mobileScreen) {
					flex-direction: column;
					border-bottom: 0;
					padding-bottom: 0;
				}
				@include breakpoint(tabletScreen) {
					padding-bottom: 0;
					margin-bottom: 3rem;
				}
				@include breakpoint(mobileScreenLandscape) {
					margin-bottom: 0;
					padding-bottom: 0;
					border-bottom: 0;
					flex-direction: row;
				}
				&-left {
					@include breakpoint(mobileScreen) {
						min-height: initial;
						flex: 1 0 100%;
						width: 100%;
					}
					@include multiple-media(mobileScreenLandscape, tabletScreen) {
						min-height: initial;
						flex-basis: 30%;
						width: 30%;
					}
					h1 {
						@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
							font-size: 2.2rem;
						}
						@include breakpoint(mobileScreen) {
							margin-bottom: 2rem;
						}
					}
				}
				&-right {
					@include breakpoint(mobileScreen) {
						flex: 1 0 100%;
						width: 100%;
						justify-content: space-between;
						display: flex;
						align-items: baseline;
					}
					@include multiple-media(mobileScreenLandscape, tabletScreen) {
						flex-basis: 70%;
						width: 70%;
					}
					.do-button {
						@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
							margin: 0;
							font-weight: 500;
						}
					}
					.activeLink {
						@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
							display: inline-block;
							text-align: center;
							font-weight: 500;
							min-width: 12rem;
							margin-left: 3.7rem;
							border-radius: .3rem;
						}
						@include breakpoint(mobileScreen) {
							margin: 0;
						}
					}
				}
			}
			&__middle {
				flex-wrap: nowrap;
				@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
					flex-wrap: wrap;
					align-items: stretch;
					height: auto;
					justify-content: space-between;
				}
				.pipeline-matched .todo-matchesBlock__content > a {
					@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
						margin-right: 1rem;
					}
				}
				&-filters {
					margin-right: 3rem;
					@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
						flex: 1 0 100%;
						margin-bottom: 2rem;
						font-weight: 500;
						&-holder {
							display: none;
						}
					}
				}
				&-holder--wrapper {
					display: flex;
					flex: 100%;
					@include multiple-media(smallScreen) {
						flex: 100%;
					}
					@include breakpoint(mobileScreen) {
						overflow-x: scroll;
						flex: 185vw;
					}
					@include multiple-media(mobileScreenLandscape, tabletScreen) {
						overflow-x: initial;
						flex: 100%;
						width: 100%;
					}
					.layer-body__middle-holder {
						width: calc(50% - 1.5rem);
						flex-basis: calc(50% - 1.5rem);
						&:first-child {
							margin-right: 3rem;
						}
						&:nth-child(2) {
							@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
								.action__buttons {
									display: none;
								}
							}
						}
						@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
							position: relative;
							width: calc(50% - 1.5rem);
							margin-bottom: 0;
							flex: initial;
							height: calc(100vh - 27rem);
							overflow: hidden;
							&:first-child {
								margin-right: 3rem;
							}
						}
						@include multiple-media(tabletScreen, smallScreen) {
							height: calc(100vh - 26rem);
						}
						@include breakpoint(mobileScreen) {
							width: 100%;
							flex: 0 0 calc(100vw - 4.2rem);
						}
						@include breakpoint(mobileScreenLandscape) {
							height: calc(100vh - 19rem);
							width: calc(50% - 4.8rem);
							flex: calc(50% - 4.8rem);
						}
						&-top {
							@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
								position: absolute;
								left: 0;
								right: 0;
								top: 0;
								flex-direction: column;
								justify-content: flex-start;
								align-items: flex-start;
								padding: 2rem 2rem 0 2rem;
							}
							@include breakpoint(mobileScreenLandscape) {
								display: flex;
							}
							.todo-widget__filters-older {
								@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
									padding: 1rem 0;
									width: 100%;
									font-weight: 500 !important;
								}
								@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
									i {
										position: absolute;
										top: 1.1rem;
										right: 0;
									}
								}
								.my-dropdown-dd .dropdownFilter {
									@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
										left: 0;
										right: auto;
										top: -1.1rem;
									}
								}
							}
						}
						&-content {
							@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
								position: relative;
								overflow: hidden;
								overflow-y: scroll;
								padding: 0;
								margin-top: 9.5rem;
								height: calc(100vh - 36.6rem);
							}
							@include multiple-media(tabletScreen, smallScreen) {
								height: calc(100vh - 35.4rem);
							}
							@include breakpoint(mobileScreenLandscape) {
								height: calc(100vh - 26rem);
							}
							.pipeline-deals,
							.pipeline-matched {
								.todo-dealsBlock,
								.todo-matchesBlock {
									> .todo-widget__block-right {
										@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
											width: 100%;
										}
									}
									> .action__buttons {
										> a:only-child {
											@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
												margin-left: 0;
											}
										}
									}
									.specialDealBlock {
										@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
											max-height: initial;
											flex-direction: column;
											align-items: flex-start;
										}
										.todo-widget__block-right {
											@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
												width: 100%;
												padding-bottom: 2rem;
											}
											&-info {
                                                width: auto;

												.media__info-desc {
													@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
														font-size: 1rem;
														color: $color-grey-4;
													}
												}
											}
										}
										.action__buttons {
											> a:only-child {
												@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
													margin-left: 0;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
