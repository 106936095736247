.investor-profile-layer {
  display: flex;
  align-items: flex-start;
  .side-bar {
    flex-basis: 200px;
    width: 200px;
    @include multiple-media(mobileScreen, tabletScreen) {
      display: none;
    }
    @include breakpoint(largeScreen) {
      flex-basis: 250px;
      width: 250px;
    }

    .do-user__item-passport {
      width: 200px !important;
      @media screen and (min-width: 1800px){
        width: 250px !important;
      }
      &:last-child {
        margin-left: 0;
      }
      &-block {
        &:first-child {
          padding: 2.5rem;
          margin-bottom: 20px;
        }
        &.relationship-owner {
          padding: 0;
          @include breakpoint(smallScreen) {
            display: none;
          }
          .relationship-owner-title {
            text-align: left !important;
            .media__image-document-profile {
              p {
                font-size: 1.3rem;
              }
            }
          }
          .relationship-owner-body {
            height: auto !important;
            width: unset;
          }
          .media__image-document,
          .relationship-owner-title {
            padding: 2rem;
            text-align: center;
            font-weight: 600;
            border: 0;
          }
        }
        .todo-widget__block-right-image {
          width: 100%;
          border: 0;
          height: 14.85rem;
        }
        .media {
          &__image {
            width: 100%;
            height: 100%;
            &-big {
              width: 150px;
              height: 150px;
              @media screen and (min-width: 1800px) {
                width: 200px;
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
  .investorProfileFull {
    overflow-y: auto;
    height: calc(100vh - 20rem);
    flex-basis: 100%;
    padding-left: 20px;
    width: 100%;
    @include breakpoint(largeScreen) {
      padding-left: 25px;
    }
    @include multiple-media(mobileScreen, tabletScreen) {
        padding-left: 0;
    }
    @include breakpoint(hugeScreen) {
      height: calc(100vh - 18rem);
    }
    @include breakpoint(laptop) {
      height: calc(100vh - 23rem);
    }
    @include breakpoint(bigLaptop) {
      height: calc(100vh - 21rem);
    }
    .kyc-status {
      max-height: 1000%;
      .kyc-data {
        @include multiple-media(mobileScreen, tabletScreen) {
          display: flex;
          flex-direction: column;
        }
        .left {
          @include breakpoint(mobileScreen) {
            margin: auto;
            width: auto;
          }
        }
      }
    }
    .do-user {
      &__item {
        &.fullProfile {
          padding: 0;
          overflow: visible;
          max-height: none;
          &:first-of-type {
            margin-top: 0;
          }
          height: auto;
          margin-top: 1.7rem;
          &:last-child {
            margin-bottom: 0;
          }
          .no-border {
            border: 0;
          }
          .badge img {
            width: 2.2rem;
            height: 2.2rem;
            margin-right: 2rem;
            margin-top: 0.1rem;
          }
          .professional {
            margin-top: -1rem;
            margin-bottom: 3rem;
            span {
              font-size: 1.4rem;
              font-weight: 500;
              line-height: 1.71;
              color: $tagBackground;
            }
          }
          .edit-btn {
            &--disabled {
              cursor: default;
              opacity: 0.5;
              color: #fff;
            }
          }
          .kyc-data {
            display: flex;
            &.elective {
              margin-top: -4rem;
              .left {
                width: 40%;
              }
              .kyc-country {
                margin-bottom: 4rem;
              }
            }
            .left {
              margin-top: 2rem;
              width: 34%;
              p {
                margin-bottom: 2rem;
                font-size: 1.2rem;
                color: $smallColor;
                &:last-child {
                  margin-bottom: 0;
                }
              }
              .pdf-holder {
                background: $white;
                border: 1px solid $smallBlockBorder;
                border-radius: 3px;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                width: 19rem;
                margin-bottom: 3rem;
                div {
                  padding: 2rem;
                }
                button {
                  padding: 1.5rem;
                  font-size: 1.2rem;
                }
                .badge img {
                  width: 1.3rem;
                  height: auto;
                  top: -12rem;
                  left: 9.5rem;
                  position: relative;
                }
                .pdf {
                  height: 10.5rem;
                  margin-bottom: 2rem;
                  margin-left: -0.5rem;
                  &.pdf-no-badge {
                    margin-left: 3.3rem;
                  }
                }
                p {
                  font-weight: 500;
                  text-transform: uppercase;
                  color: $black;
                  border-bottom: 1px solid $smallBlockBorder;
                  margin: 0;
                  padding: 1.5rem;
                }
                .disabled-btn {
                  cursor: default;
                }
              }
            }
            .right {
              margin-top: 2rem;
              p {
                margin-bottom: 2rem;
                font-size: 1.2rem;
                color: $tagBackground;
                font-weight: 500;
                &:last-child {
                  margin-bottom: 0;
                }
              }
              .kyc-holder {
                margin-bottom: 2rem;
                display: flex;
                .kyc-result {
                  font-size: 1.4rem;
                  color: $tagBackground;
                }
              }
            }
          }
          .editResult {
            background: $white;
            border: 1px solid $investmentBorder;
            border-radius: 5px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            font-weight: 500;
            color: $black;
            font-size: 1.4rem;
            .edit {
              display: inline-block;
              padding: 2rem;
              background: $brand;
              margin-right: 2rem;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
              cursor: pointer;
              .fa-pencil {
                color: $white;
                font-size: 1.5rem;
              }
            }
          }
          .approval {
            background: $brand;
            padding: 2.5rem;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            &__result,
            &__assessment {
              width: 100%;
              display: flex;
              flex-direction: row;
              @include multiple-media(mobileScreen, tabletScreen) {
                flex-direction: column;
              }
              &--title {
                width: 300px;
                &:nth-child(1) {
                  padding-top: 20px;
                }
                @include multiple-media(mobileScreen, tabletScreen) {
                  padding-bottom: 20px;
                  width: 100%;
                  &:nth-child(1) {
                    padding-top: 0;
                  }
                }
                span {
                  font-size: 1.8rem;
                  font-family: $regular;
                  color: $white;
                }
              }
              &--option {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: $white;
                min-height: 60px;
                max-height: 100px;
                border: 1px solid #185fb2;
                width: 100%;
                cursor: pointer;
                &:hover {
                  background-color: $light-blue;
                }
                &.disabled {
                  background: $disabled-grey;
                  cursor: default;
                  .container {
                    .checkmark {
                      background-color: #c4c6c9;
                      border: none !important;
                    }
                  }
                }
                .container {
                  display: flex;
                  align-items: center;
                  max-width: unset;
                  margin: unset;
                  position: relative;
                  padding-left: 20px;
                  .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 2rem;
                    width: 2rem;
                    background-color: $white;
                    border-radius: 50%;
                    margin-top: -0.3rem;
                    margin-left: 20px;
                    &.green {
                      border: 1px solid $colorCheck;
                    }
                    &.orange {
                      border: 1px solid $orange-badge;
                    }
                    &.red {
                      border: 1px solid $red-badge;
                    }
                  }
                }
                .badge {
                  width: 0;
                  margin: 0;
                }
                .approval-text {
                  padding-left: 40px;
                  font-size: 1.4rem;
                  font-family: $regular;
                  color: $black;
                }
              }
            }

            &__result {
              margin-bottom: 30px;
              &--option {
                border-radius: 5px;
                .badge {
                  padding-left: 20px;
                }
                .approval-text {
                  font-weight: 600;
                }
              }
            }
            &__assessment {
              .assessment-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                .approval__assessment--option {
                  &:last-of-type {
                    border-bottom-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                  }
                  &:first-of-type {
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                  }
                }
                button {
                  background-color: $white;
                  color: $brand;
                  margin-top: 3rem;
                  font-size: 1.2rem;
                  font-weight: 500;
                  width: 105px;
                  height: 45px;
                  padding: 0;
                  &.disabled {
                    color: $smallBlockBorder;
                    cursor: default;
                  }
                }
              }
            }
          }
          .investor-edit {
            .modal-container {
              .button-discard {
                width: 40%;
              }
              .footer-button {
                .ladda-label {
                  font-size: 1.2rem !important;
                }
              }
            }
          }
        }
        &-content {
          & > * {
            padding: 2.5rem;
          }
          .edit {
            padding-top: 0;
          }
          .common {
            &__info-title {
              font-size: 1.8rem;
            }
          }
          .common__info-title {
            border-bottom: 1px solid $smallBlockBorder;
          }
          .kyc-text {
            font-size: 1.4rem;
            line-height: 1.71;
            color: $addCompanySmallTitle;
          }
          .kyc-margin {
            margin-top: 2rem;
          }
          button {
            border-radius: 3px;
            width: 11rem;
            &.disabled {
              cursor: default !important;
              opacity: 1 !important;
              color: rgba(31,123,232, 0.4) !important;
            }
          }
          .radio-container {
            margin-bottom: 30px;
            .radio-group {
              cursor: pointer;
              background-color: #f5f7fa;
              border: solid 1px #e6e9ed;
              padding: 23px 20px 20px 20px;
              &:first-child {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
              }
              &:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
              }
              label {
                color: $black;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
                padding-left: 1.8rem;
              }
              p {
                font-size: 12px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.67;
                letter-spacing: normal;
                color: #656d78;
                padding-left: 3.8rem;
                margin: 0;
              }
              &.checked{
                background-color: $white;
              }
              .badge img {
                width: 1.3rem;
                height: auto;
              }
            }
            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
              position: absolute;
              left: -9999px;
            }
            [type="radio"]:checked + label,
            [type="radio"]:not(:checked) + label {
              position: relative;
              padding-left: 3.8rem;
              margin-bottom: 0;
              cursor: pointer;
              line-height: 2rem;
              display: inline-block;
              font-weight: 600;
              color: $black;
              text-transform: uppercase;
              @include breakpoint(smallLaptop){
                padding-left: 3.9rem;
              }
            }
            [type="radio"]:checked + label:before,
            [type="radio"]:not(:checked) + label:before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 18px;
              height: 18px;
              border: 1px solid #e6e9ed;
              border-radius: 100%;
              background: #ffffff;
            }
            [type="radio"]:checked + label:after,
            [type="radio"]:not(:checked) + label:after {
              content: '';
              width: 10px;
              height: 10px;
              background: $brand;
              position: absolute;
              top: 4.5px;
              left: 5px;
              border-radius: 100%;
              -webkit-transition: all 0.2s ease;
              transition: all 0.2s ease;
            }
            [type="radio"]:not(:checked) + label:after {
              opacity: 0;
              -webkit-transform: scale(0);
              transform: scale(0);
            }
            [type="radio"]:checked + label:after {
              opacity: 1;
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
          button {
            padding: 1.5rem 5rem;
            line-height: 1.3rem;
            font-size: 1.1rem;
            background-color: $brand;
            color: $white;
            left: inherit;
            right: inherit;
            width: auto;
            position: relative;
            border: 0;
            border-radius: 3px;
            @include transition(.3s all ease-in-out);
            &:hover{
              background-color: $darkerBrand;
            }
          }
          button[disabled=disabled], button:disabled {
            background-color: $brand;
            border-radius: 3px;
            opacity: 0.3;
            cursor: default;
          }
        }
      }
    }
    .mifid {
      .radio-container {
        .radio-group {
          cursor: pointer;
          label {
            display: block;
            margin-bottom: 1rem;
            color: $dark-gray;
            font-size: 1.1rem;
            font-weight: 600;
            padding-left: 0;
          }
        }
      }
      &__desc {
        width: 100%;
        font-size: 1.4rem;
        color: $black;
        font-weight: 500;
        margin-bottom: 1.4rem;
      }
      .searchFirmButton {
        padding: 1.8rem 5rem;
        line-height: 1.3rem;
        font-size: 1.1rem;
        background-color: $brand;
        color: $white;
        margin-top: 2rem;
        width: auto;
        position: relative;
        border: 0;
        transition: 0.3s all ease-in-out;
        text-transform: uppercase !important;
        cursor: pointer;
        font-family: $regular;
        border-radius: 2px;
        outline: none;
        font-weight: 600;
        bottom: 0;
        text-align: center;
        &:hover {
          background-color: #004699 !important;
        }
      }
    }
  }
  .mifid {
    margin-left: 0;
  }
}
.tooltip-disabled {
  display: none !important;
}
