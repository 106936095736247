.firm-add-contact {
  max-height: calc(100vh - 11rem);
  background: white;
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  @include breakpoint (smallLaptop) {
    max-height: calc(100vh - 15rem);
  }
  .layer-body__middle-holder-content {
    padding: 20px;
    flex: 1;
    height: auto;
    @include breakpoint (smallLaptop) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .todo-widget__block-right-info {
      .media__info-title{
        cursor: auto;
        &:hover {
          color: inherit;
        }
      }
      .media__info-desc{
        cursor: auto;
        &:hover {
          color: $dark-gray;
        }
      }
    }
  }
  .do-network-block {
    &__first {
        width: 90px;
        @media screen and (max-width: 1199px) {
          flex-direction: column;
          border-bottom: unset;
          align-items: flex-start;
          &:first-child {
            padding-left: 0;
          }
          a {
            padding-left: 10px;
          }
        }
    }
    &__second {
      height: 78px;
      width: calc(100% - 120px);
      border-left: 1px solid $lightGray;
      padding-left: 20px;
      .todo-widget__block-right-info {
        width: calc(100% - 60px);
      }
    }
  }
}