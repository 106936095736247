.data-room-compact {
    height: 100%;
    display: flex;
    flex-grow: 1;

    @include multiple-media(largeScreen) {
        justify-content: center;
    }

    .content {
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        .compact-intro {
            display: none;

            @include multiple-media(mediumScreen, largeScreen) {
                display: block;
            }
        }

        .content__inner {
            flex-direction: column;
            flex-grow: 1;
            width: 100%;

            .accordion {
                display: block;

                &__title {
                    align-items: center;
                    background-color: $color-white;
                    display: flex;
                    padding: 2rem 2rem 2rem 3rem;
                    border-bottom: 1px solid $color-grey-1;

                    &:hover {
                        color: $color-blue-dark;
                        cursor: pointer;
                    }

                    &__element {
                        align-items: center;
                        color: $color-blue-main;
                        display: flex;

                        &:hover {
                            color: $color-blue-dark;

                            .accordion__title__element {
                                color: $color-blue-dark;
                            }
                        }

                        &--caret {
                            font-size: 1rem;
                            margin-right: 2rem;
                            width: 2.4rem;
                            height: 2.4rem;
                            justify-content: center;
                        }

                        &--text {
                            font-weight: 500;
                            font-size: 20px;
                            margin: 0;
                        }
                    }
                }

                .my-dropdown-dd {
                    position: relative !important;
                }

                &-content {
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    padding: 3rem;
                    background-color: $color-compact-background;

                    .audit-since-last-login {
                        background-color: $color-white;
                        border: solid 1px $color-grey-3;
                        border-radius: 3px;
                        display: block;
                        padding: 1.6rem 2rem 2rem 2rem;

                        &__header {
                            display: flex;
                            margin: 0 0 1.7rem 0;

                            &__title {
                                color: $color-grey-6;
                                flex-grow: 1;
                                font-size: 14px;
                                line-height: 2.6rem;
                            }

                            &__dismiss {
                                color: $color-blue-main;
                                flex-grow: 0;
                                font-size: 1.2rem;

                                &:hover {
                                    color: $color-blue-dark;
                                }
                            }
                        }

                        .audit-items {
                            .audit-item {
                                display: flex;
                                justify-content: space-between;
                                margin: 0 0 2rem 0;

                                &:last-child {
                                    margin-bottom: 0;
                                }

                                &__message {
                                    color: $color-grey-6;
                                    font-size: 1.2rem;
                                    @include break-words-nicely;

                                    &__attention {
                                        color: $color-blue-main;
                                    }

                                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                                        margin-right: 1.5rem;
                                    }
                                }

                                &__dismiss {
                                    color: $color-grey-6;
                                    display: none;
                                    font-size: 14px;

                                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    .files-search {
                        background-color: $color-white;
                        border: solid 1px $color-grey-3;
                        border-radius: 3px;
                        display: flex;
                        margin: 2rem 0 0 0;
                        padding: 1.7rem;

                        &__icon {
                            color: $color-grey-6;
                            font-size: 1.7rem;
                            flex-grow: 0;
                            margin: 0 1rem 0 0;
                        }

                        &__input {
                            border: none;
                            font-family: $regular;
                            font-size: 14px;
                            flex-grow: 1;
                            outline: 0;

                            &::placeholder {
                                font-weight: 600;
                            }
                        }
                    }

                    .files {
                        font-size: 2rem;
                        margin: 2rem 0 2rem 0;

                        .path {
                            margin: 0 0 2rem 0;

                            &__root {
                                color: $color-blue-main;
                                cursor: pointer;

                                &:hover {
                                    color: $color-blue-dark;
                                }
                            }

                            &__separator {
                                font-size: 1rem;
                                color: $color-grey-4;
                                margin: 0 2rem 0 2rem;
                                position: relative;
                                top: -3px;
                            }

                            &__dir-name {
                                color: $color-blue-main;
                                cursor: pointer;
                                margin: 0 2rem 0 0;
                                @include break-words-nicely;

                                &:last-child {
                                    margin: 0;
                                }

                                &:hover {
                                    color: $color-blue-dark;
                                }
                            }
                        }

                        .files-list {
                            background-color: $color-white;
                            flex-direction: column;
                            display: flex;
                            border: 1px solid $color-grey-3;
                            border-radius: 3px;

                            &__item-row {
                                align-items: center;
                                border-bottom: 1px solid $color-grey-3;
                                color: $color-grey-6;
                                display: flex;
                                font-size: 14px;
                                padding: 12px 20px;

                                .item-row-content {
                                    align-items: center;
                                    cursor: pointer;
                                    display: flex;
                                    flex-grow: 1;

                                    &__icon {
                                        color: $color-blue-main;
                                        font-size: 2.6rem;
                                        margin: 0 1.5rem 0 0;

                                        .fa-file-excel-o {
                                            color: $color-file-excel;
                                        }

                                        .fa-file-word-o {
                                            color: $color-file-word;
                                        }

                                        .fa-file-pdf-o {
                                            color: $color-file-pdf;
                                        }

                                        .fa-file-powerpoint-o {
                                            color: $color-file-powerpoint;
                                        }

                                        .fa-file-image-o {
                                            color: $color-file-image;
                                        }
                                    }

                                    &__name {
                                        @include break-words-nicely;
                                        flex-grow: 1;

                                        input {
                                            background-color: $color-grey-1;
                                            border: 1px solid $investmentBorder;
                                            border-radius: 5px;
                                            color: $color-grey-6;
                                            font-family: $regular;
                                            font-size: 14px;
                                            outline: none;
                                            padding: 0.75rem 2rem;
                                            // 4rem - left & right padding
                                            // 1.5 rem - same distance as icon to input
                                            width: calc(100% - 4rem - 1.5rem);

                                            &:hover {
                                                border-color: $color-grey-3;
                                            }

                                            &:focus {
                                                background-color: $color-white;
                                                border-color: $color-grey-3;
                                            }

                                            &.has-content {
                                                background-color: $color-white;
                                            }
                                        }


                                    }

                                    &__edit-actions {
                                        .action {
                                            color: $color-blue-main;

                                            &--save {
                                                margin: 0 1.5rem 0 0;
                                            }

                                            &:hover {
                                                color: $color-blue-dark;
                                            }
                                        }
                                    }

                                    &:hover {
                                        .item-row-content__name {
                                            color: $color-blue-main;
                                        }
                                    }

                                    &--new-folder {
                                        cursor: default;
                                    }
                                }

                                &:last-child {
                                    border-bottom: none;
                                }
                            }

                            .more-items__hub-block {
                                right: 43px;

                                @include multiple-media(tabletScreen, smallScreen, mediumScreen) {
                                    right: 39px;
                                }
                                @media screen and (min-width: 1800px) and (max-width: 1919px) {
                                    right: 40px;
                                }
                                @media not all and (min-resolution:.001dpcm) {
                                    right: 44px;
                                }
                            }
                        }

                        .no-content {
                            display: flex;
                            justify-content: center;

                            &__message {
                                font-size: 2rem;
                                font-weight: 900;
                            }
                        }
                    }

                    .upload {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        &__progress-bar {
                            border-radius: 0;
                            -webkit-appearance: none;
                            appearance: none;
                            width: 100%;

                            @-moz-document url-prefix() {
                                width: calc(100% - 2px);
                            }

                            &[value]::-webkit-progress-bar {
                                background-color: $color-grey-7;
                                border-radius: 2px;
                                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
                            }

                            &::-webkit-progress-value {
                                background: $positive;
                            }

                            &::-moz-progress-bar {
                                background: $positive;
                            }
                        }

                        .upload-area {
                            margin: 0 0 2rem 0;
                            width: 100%;

                            &__progress-message{
                                font-size:1.3rem;
                            }

                            &__inner {
                                align-items: center;
                                background-color: $color-grey-1;
                                border: 3px dotted $color-grey-3;
                                border-radius: 3px;
                                color: $smallBlockBorder;
                                display: flex;
                                font-weight: 500;
                                height: 7.4rem;
                                justify-content: center;

                                .fa-spinner {
                                    font-size: 2.5rem;
                                }

                                .fa-file-o {
                                    font-size: 2.5rem;
                                    margin-right: 1.3rem;
                                }

                                &__text {
                                    font-size: 14px;

                                    .linkText {
                                        color: $color-blue-main;
                                        cursor: pointer;
                                        position: relative;

                                        .tooltipText {
                                            visibility: hidden;
                                            width: 20rem;
                                            background-color: $color-grey-6;
                                            color: $color-white;
                                            text-align: center;
                                            padding: .5rem;
                                            font-size: 1.2rem;
                                            font-weight: 600;
                                            border-radius: 6px;
                                            border: 1px solid $color-grey-6;
                                            position: absolute;
                                            z-index: 1;
                                            right: -15%;
                                            top: 3rem;

                                            &::before,
                                            &::after {
                                                content: '';
                                                display: block;
                                                position: absolute;
                                                bottom: 100%;
                                                width: 0;
                                                height: 0;
                                            }
                                            &::before {
                                                right: 44%;
                                                border: 10px solid transparent;
                                                border-bottom-color: $color-grey-6;
                                            }

                                            &::after {
                                                right: 45%;
                                                border: 9px solid transparent;
                                                border-bottom-color: $color-grey-6;
                                            }
                                        }

                                        &:hover {
                                            color: $color-blue-dark;

                                            .tooltipText{
                                                visibility: visible;
                                            }
                                        }
                                    }

                                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                                        display: none;
                                    }

                                    &--long {
                                        display: none;

                                        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                                            display: inline;
                                        }
                                    }
                                }
                            }

                            &__file {
                                display: none;
                            }
                        }
                    }

                    .dataroom-actions {
                        display: flex;
                        flex-direction: column;
                        position: relative;

                        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen, mobileScreen) {
                            align-items: center;
                            flex-direction: row;
                            justify-content: space-between;

                            &--only-zip {
                                flex-direction: row-reverse;
                            }
                        }

                        &__action {
                            font-family: $regular;
                            font-size: 13px;
                            font-weight: 600;
                            color: $smallBlockBorder;
                        }

                        &__action-button {
                            color: $color-white;
                            cursor: pointer;
                            background-color: $color-blue-main;
                            border: none;
                            border-radius: 3px;
                            margin: 0 0 1.5rem 0;
                            outline: none;
                            padding: 1.2rem 2.7rem;

                            &:hover {
                                background-color: $color-blue-dark;
                            }

                            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                                margin: 0;
                            }
                            @include multiple-media(mobileScreen) {
                                width: 15.5rem;
                                padding: 1.2rem 2rem;
                                margin: 0;
                            }
                        }

                        &__right-side {
                            justify-content: space-between;
                            display: flex;
                            width: 30rem;
                            flex-direction: column;

                            &__child {
                                display: flex;
                                justify-content: flex-end;

                                @include multiple-media(mobileScreen) {
                                    width: 11rem;
                                    display: inherit;
                                    float: right;
                                    text-align: right;

                                    &--message {
                                        width: 25rem;
                                        position: absolute;
                                        bottom: -2.5rem;
                                        right: 0;
                                    }
                                }
                            }

                            @include multiple-media(mobileScreen) {
                                display: block;
                                width: auto;

                                &--align-right {
                                    float: right;
                                }
                            }

                            &--without-zip {
                                width: 14rem;
                                @include multiple-media(mobileScreen) {
                                    width: 4.3rem;
                                }
                            }

                        }

                        &__separator {
                            color: $smallBlockBorder;
                            margin: 0 1rem;
                        }

                        &__action-index {
                            margin-left: 1rem;
                        }

                        &__action-zip, &__action-index {
                            color: $color-blue-main;

                            &__generating-message {
                                img {
                                    height: 13px;
                                    padding-left: 0.5rem;
                                }
                            }

                            &__zip-in-progress-message {
                                display: inline-block;
                                vertical-align: middle;
                                margin-left: 50px;
                                color: $black;
                            }

                            a {
                                color: $color-blue-main;

                                &:hover {
                                    color: $color-blue-dark;
                                }
                            }
                        }
                    }

                    .audit-actions {
                        display: flex;
                        margin-bottom: 2rem;

                        .btn {
                            color: $color-grey-6;
                            font-size: 1.3rem;
                            font-weight: 600;
                            padding: 1.2rem 2.5rem;
                        }
                    }

                    .audit-complete {
                        max-height: calc(100vh - 39rem);
                        overflow-y: auto;
                        &__message {
                            font-size: 14px;
                            color: $color-grey-6;
                            line-height: 2.6rem;
                            margin: 0;
                            @include break-words-nicely;
                        }
                    }
                }
            }
        }
    }
}
