.compact-feedback {
  .layer-container {
    @include multiple-media(mobileScreen, tabletScreen) {
      position: fixed;
      left: 0;
    }
    .layer-header,
    .layer-body {
      padding: 0;
      background-color: $color-white;
    }
    .layer-body {
      height: calc(100% - 7.3rem);
      overflow-x: hidden;
    }
  }
  &__header {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.1);
    padding: 0 3rem;
    position: relative;
  }
  &__title {
    font-size: 1.4rem;
    font-weight: normal;
    font-family: $regular;
    color: $color-grey-6;
    text-transform: none;
  }
  &__close-button {
    font-size: 2rem;
    font-weight: bold;
    cursor: pointer;
    color: $color-grey-6;
  }
  .feedback-block {
    &__content {
      padding: 3rem;
      background-color: $color-grey-1;
      .hubx-inputs {
        padding: 2rem 0;
        textarea {
          background-color: $color-white;
          resize: none;
          height: 12rem;
        }
      }
      .vue-slider-dot {
        background-color: $color-blue-main !important;
        font-size: 1rem !important;
        height: 2.5rem !important;
        width: 2.5rem !important;
        padding: 0;
        left: -0.2rem !important;
        top: -1.2rem !important;
        border-radius: 50%;
        .vue-slider-dot-handle {
          background-color: $color-blue-main !important;
          box-shadow: none !important;
        }
      }
      .vue-slider-piecewise-item {
        top: 6px !important;
        border-radius: 0 !important;
      }
      .vue-slider-component {
        width: 300px !important;
        @include breakpoint(mobileScreen) {
            width: calc(100% - 11rem) !important;
        }
        .vue-slider-piecewise-dot {
          background-color: $investmentBorder;
          border-radius: 0;
        }
        .vue-slider {
          background-color: $color-grey-12 !important;
          border-radius: 0 !important;
        }
      }
      .vue-slider-piecewise-label {
        margin-top: 1rem;
        font-size: 1rem !important;
        color: $color-grey-6 !important;
      }
    }
    .my-dropdown-dd {
      position: relative !important;
    }
    .accordion {
      height: 6.5rem;
      background-color: $color-white;
      color: $color-blue-main;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 2rem;
      font-weight: 300;
      cursor: pointer;
      width: 100%;
      border-bottom: 0;
      &:hover {
        color: $color-blue-dark;
        cursor: pointer;
      }
      &__toggle {
        width: 1rem;
        padding-left: 3rem;
        .fa {
          font-weight: bold;
        }
      }
      &__title {
        padding-left: 2.5rem;
      }
      &__deal {
        display: flex;
        flex-direction: column;
        width: calc(100% - 2.8rem);
        padding-top: 2rem;
        .deal-info {
          display: flex;
          flex-direction: row;
          padding-left: 2.5rem;
          padding-bottom: 1rem;
          .item {
            font-size: 1.4rem;
            color: $color-grey-4;
            &:nth-of-type(2) {
              padding-left: 2.5rem;
              font-weight: 600;
            }
            &__name {
              font-weight: 500;
            }
            &__value {
              font-weight: 600;
              text-transform: capitalize;
            }
          }
        }
      }
    }

    .slider {
      display: flex;
      flex-direction: row;
      height: 70px;
      justify-content: space-between;
      @include breakpoint(mobileScreen) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
      }
      &__title {
        font-family: $regular;
        font-size: 1.4rem;
        font-weight: 500;
        color: $color-grey-6;
      }
      &__box {
        display: flex;
        flex-direction: row;
        @include breakpoint(mobileScreen) {
          width: 100%;
        }
        .min-label,
        .max-label {
          font-family: $regular;
          font-size: 10px;
          font-weight: 500;
          color: $color-grey-6;
          line-height: 2.2;

        }
        .min-label {
          padding-right: 1.5rem;
          text-align: right;
          width: 5rem;
          @include breakpoint(mobileScreen) {
            text-align: center;
          }
        }
        .max-label {
          padding-left: 1rem;
          text-align: left;
          width: 4rem;
        }
      }
    }
  }
}