.todo-wrapper {
    @media (min-width: 900px) {
      border-radius: 5px;
    }
    min-height: 124px;
    box-sizing: border-box;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    background-color: $color-white;
    padding: 20px;
    text-align: center;

  .todo-label {
    width: 80px;
    font-size: 18px;
    font-weight: bold;
    color: $darkBlack;
    margin-bottom: 10px;
    font-weight: 500;
    font-family: 'NunitoBold';
  }

  .todo-button {
    @media (max-width: 599px) {
      display: none;
    }
    display: block;
    min-width: 100px;
    height: 50px;
    border: none;
    border-radius: 0 5px 5px 0;
    background-color: $color-purple-main;
    font-family: Nunito, serif;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: $white;
    cursor: pointer;

    &:focus {
        outline-width: 0;
    }

    &--mobile {
      @media (max-width: 599px) {
        display: block;
        margin-top: 15px;
        border-radius: 5px;
      }
      display: none;
    }
  }
  
  .todo-add-container {
    display: flex;

    .todo-input {
      @media (max-width: 599px) {
        border-radius: 0 5px 5px 0;
      }
      box-sizing: border-box;
      height: 50px;
      width: 100%;
      border: 1px solid $color-grey-15;
      font-family: Nunito, serif;
      font-size: 16px;
      color: $color-grey-17;
      padding-left: 20px;
      &::placeholder {
        font-style: italic;
      }

      &-error {
          border: 1px solid $color-red-main;
      }

      &:focus {
          outline-width: 0;
      }
    }

    .todo-person {
      min-width: 79px;
      width: 79px;

      img {
        height: 30px;
        width: 30px;
      }
      .select {
        box-sizing: border-box;
        .multiselect {
          height: 50px;
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-start;
          align-items: center;
          background-color: #fff;
          border-right: none;
          border-radius: 5px 0 0 5px;
          &__single {
            border: none;
            padding: 0;
          }
          .arrow {
            margin-left: 11px;
            margin-right: 16px;
            transition: transform .15s ease-in-out;
            transform-origin: center;
            i {
              color: $color-cyan-secondary;
            }
          }
          &--active .arrow {
            transform: rotate3d(1, 0, 0, 180deg);
          }
          &__content {
            overflow: visible;
            top: 60px;
            border: 1px solid $color-grey-16;
            border-radius: 5px;
            min-width: 225px;
            &__arrow {
              border-style: solid;
              position: absolute;
              border-color: transparent transparent $color-grey-16 transparent ;
              border-width: 0px 8px 8px 8px;
              top: -8px;
              left: 47px;
              &::after {
                border-color: transparent transparent #fff transparent;
                border-style: solid;
                border-width: 0px 7px 7px 7px;
                top: 1px;
                left: -7px;
                content: "";
                position: absolute;
              }
            }
          }
          &__element {
            border: none;
            border-radius: inherit;
            span {
              background-color: unset;
              .multiselect__element-container {
                display: flex;
                align-items: center;
                .multiselect__element__user-avatar {
                  margin-right: 10.6px;
                  height: 18.9px;
                  width: 18.9px;
                }
                .option__desc {
                  font-size: 14px;
                  color: #434a54;
                  line-height: 1.57;
                }
                input[type="radio"] {
                  margin-left: 6px;
                  margin-right: 15.6px;
                }
              }
            }
          }
          &__tags {
            .multiselect__input {
              display: none;
            }
          }
        }
      }
    }
  }
}

.tasks-wrapper {
  @media (min-width: 1200px) {
    padding: 0 60px;
  }

  h3 {
    @media (max-width: 899px) {
      margin-left: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    text-align: left;
    color: black;
    font-size: 24px;
    font-weight: 500;
    font-family: 'NunitoBold';
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .tasks-todo-wrapper {
    .task-item {
      @media (min-width: 900px) {
        border-radius: 5px;
      }
      box-sizing: border-box;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff;
      padding: 20px;
      margin-bottom: 15px;
      &__first-line {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        &__title {
          font-size: 16px;
          &.strikethrough-if-done {
            text-decoration: line-through;
          }
        }
        .task-checkbox {
          display: inline-block;
          width: 20px;
          height: 20px;
          border: 1px solid $color-grey-15;
          border-radius: 3px;
          margin-right: 15px;
          .fa-check {
            display: none;
          }
          &.checked .fa-check {
            display: block;
            font-size: 16px;
            color: $color-cyan-secondary;
            transform: translate(2px, 1.4px);
          }
        }
      }
      &__second-line {
        font-size: 12px;
        color: $color-grey-21;
        display: flex;
        &__assigned-to {
          font-weight: bold;
        }
        &__space {
          white-space: pre;
        }
        &__done-button {
          margin-left: auto;
          color: $red-badge;
          cursor: pointer;
        }
      }
    }
    .completed-tasks-arrow {
      margin-left: 8px;
      margin-right: 23px;
      i {
        color: $color-cyan-secondary;
        font-size: 20px;
        transform: translateY(-2px);
        transition: transform .15s ease-in-out;
        &.arrow-rotated {
          transform: rotate(90deg);
        }
      }
    }
    .completed-header {
      cursor: default;
    }
  }
}