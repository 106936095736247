.appendix{
    &__popup{
        left: 10rem;
        right: 10rem;
        background-color: #fff;
        position: fixed;
        display: block;
        padding: 0.5rem 3.6rem;
        z-index: 1;
        overflow: auto;
        height: 100vh;
        input {
            background-color: #f5f7fa;
            border-radius: 5px;
            height: 30px;
            border: 1px solid #e6e9ed;
            font-size: 10px;
            padding-left: 20px;
            color: #434a54;
            font-weight: 200;
            width: 95%;
        }
        input:focus {
            outline: none;
            background-color: #fff;
        }
        .error {
            color: red !important;
            font-size: 8px;
            border-bottom: 0px;
            margin-bottom: 0;
            padding-bottom: 0;
        }
    }
    &__header{
        text-align: center;
        padding-top: 3.5rem;
        h1{
            font-size: 2.4rem;
            font-weight: 500;
            margin-top: 1rem;
            letter-spacing: 2px;
            margin-bottom: 3rem;
        }
        span{
            display: block;
            font-weight: 500;
            margin-bottom: 0.6rem;
            .orange{
                color: $brand;
                display: inline-block;
            }
        }
    }
    &__body{
        padding: 0 6rem;
        span{
            color: $addCompanySmallTitle;
            font-size: 1.13rem;
        }
        &-block{
            margin-top: 2.5rem;
            label{
                color: $brand;
                display: block;
                font-size: 1.4rem;
                margin-bottom: 2.5rem;
            }
            .right-text-vertical-centering {
                span {
                    padding-bottom: 20px;
                }
                @media only screen and (min-width: 1024px) and (max-width: 1440px) {
                    span {
                        padding-bottom: 10px;
                    }
                }
            }
            &-left,
            &-right{
                display: inline-block;
                vertical-align: middle;
                div, span{
                    display: block;
                    font-size: 1.2rem;
                    margin-bottom: 1.2rem;
                    padding: 1rem 0;
                    color: $black;
                }
            }
            &-left{
                width: 27%;
                span{
                    font-weight: 500;
                    color: $smallColor;
                    border-bottom: 1px solid $borderColor;
                }
            }
            &-right{
                width: 73.5%;
                margin-left: -0.4rem;
                div, span{
                    font-weight: 500;
                    border-bottom: 1px solid $borderColor;
                    color: $black;
                    padding-left: 2rem;
                }
            }
            &-holder{
              display: flex;
              align-items: flex-end;
            }
        }
        .specialBlock{
            margin-top: 1rem;
            label{
                margin-bottom: 1rem;
            }
            .appendix__text{
                text-align: justify;
                line-height: 2rem;
                margin-top: 0;
                display: block;
                margin-bottom: 3rem;
                font-weight: 500;
                color: $black;
            }
        }
    }
    &__footer{
        text-align: center;
        padding-bottom: 4rem;
        &:before{
            @extend .borderMask;
        }
        &-confirm{
            border: 0;
            background-color: $colorCheck;
            color: $white;
            font-size: 1.5rem;
            text-transform: uppercase;
            font-weight: 500;
            padding: 2rem;
            margin: 0 auto;
            margin-top: 5rem;
            letter-spacing: 1px;
        }
    }
    &__terms{
        text-align: center;
        display: block;
        margin-top: 4rem;
        display: block;
        text-align: center;
        position: relative;
        padding-bottom: 2.5rem;
        border-bottom: 1px solid $borderColor;
    }
    &__confirmation{
        .appendix__body-block-left,
        .appendix__body-block-right{
            width: 49.5%;
            @include breakpoint(smallLaptop) {
                width: 48.5%;
            }
            span{
                border-bottom: 0;
                font-weight: 300;
                &:nth-child(1){
                    border-bottom: 1px solid $borderColor;
                    padding-bottom: 2rem;
                }
                &:nth-child(2){
                    margin-bottom: 0;
                    padding-bottom: 0;
                    font-weight: 500;
                }
            }
        }
        .appendix__body-block-left{
            padding-right: 7rem;
            width: 39%;
        }
    }
}
