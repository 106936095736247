.Password__container{
    margin-top: 5rem;
    padding: 2rem 1rem;
    height: 4rem;
    border: 1px solid $borderColor;
    border-radius: 5px;
    @include breakpoint(toBigPhone){
        padding: 20px;
        margin-top: 50px;
        height: auto;
    }
    >label {
        text-transform: uppercase;
        color: $black;
        margin: 0;
        padding-bottom: 1rem;
        font-size: 1.3rem;
        font-weight: 900;
        @include breakpoint(toBigPhone){
            font-size: 12px;
        }
    }
}
.Password_disabled{
    opacity: 0.4;
}

.Password__strength-meter {
    position: relative;
    height: .5rem;
    background: $color-white-grey;
    margin: 1rem auto;
    justify-content: center;
}
.Password__strength-meter:before, .Password__strength-meter:after {
    content: '';
    background: transparent;
    display: inline-block;
    border-color: $color-white;
    border-style: solid;
    border-width: 0 2vw 0 2vw;
    position: absolute;
    z-index: 10;
    top: -.1rem;
    height: .8rem;
}
.Password__strength-meter:before {
    left: calc((100% - 8vw) / 5);
    width: calc((100% - 8vw) / 5);
}
.Password__strength-meter:after {
    right: calc((100% - 8vw) / 5);
    width: calc((100% - 8vw) / 5);
}
.Password__strength-meter--fill {
    background: transparent;
    height: inherit;
    position: absolute;
    width: 0;
    border-radius: inherit;
    transition: width 0.5s ease-in-out, background 0.25s;
    justify-content: center;
}
.Password__strength-meter--fill[data-score='0'] {
    background: $brand;
    width: 19.5%;
}
.Password__strength-meter--fill[data-score='1'] {
    background: $brand;
    width: 39.5%;
}
.Password__strength-meter--fill[data-score='2'] {
    background: $brand;
    width: 60.5%;
}
.Password__strength-meter--fill[data-score='3'] {
    background: $brand;
    width: 81%;
}
.Password__strength-meter--fill[data-score='4'] {
    background: $brand;
    width: 100%;
}
.Password_holder{
    input {
        @include breakpoint(desktop) {
            @media screen and (max-height: 700px) {
                float: none !important;
            }
        }
    }
}
.security-page {
    .reset-password-background {
        background-image: url('/images/background/whitelabel/hubx_background.jpg');
        width: 64.5%;
        height: 100vh;
        background-position: center center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        overflow: hidden;
    }
    .reset-password-background.no-image-background {
        background-color: #1f7be8;
        background-image: initial;
    }
    .login__hosted{
        @include breakpoint(smallTablet) {
            @media screen and (max-height:940px) {
                position: relative;
                display: block;
            }
        }
        @include breakpoint(tablet) {
            @media screen and (max-height:700px) {
                position: relative;
                display: block;
            }
        }
        @include breakpoint(toBigPhone) {
            @media screen and (max-height:725px) {
                position: relative;
                display: block;
            }
        }
        @media screen and (min-width:1024px) and (max-width:1255px) {
            @media screen and (max-height:450px) {
                position: relative;
                display: block;
            }
        }
        @include breakpoint(smallLaptop) {
            @media screen and (max-height:550px) {
                position: relative;
                display: block;
            }
        }
        @include breakpoint(bigLaptop) {
            @media screen and (max-height:680px) {
                position: relative;
                display: block;
            }
        }
        @include breakpoint(bigScreen) {
            @media screen and (max-height:790px) {
                position: relative;
                display: block;
            }
        }
        @include breakpoint(mobileScreen) {
            position: relative !important;
            height: 100px;
            top: 20vmax;
            img {
                position: absolute;
                left: 0;
                right: 0;
            }
        }
        &.reset-password {
            bottom: 1rem;
        }
    }
    .login__holder {
        height: 100vh;
        overflow: hidden;
        &-block {
            overflow-y: scroll;
        }
    }
    .login__holder-block {
        height: 100vh;
        padding: 8rem 20px 0;
        overflow-y: scroll;
        @include breakpoint(desktop) {
            @media screen and (max-height: 700px) {
                padding: 15rem 7.7rem 0;
                padding-bottom: 0;
                height: calc(100vh - 15rem);
            }
        }
        @include breakpoint(toBigPhone){
            padding: 6rem 20px 3rem;
            height: calc(100vh - 9rem);
            &:not(:root:root){
                padding: 5.8rem 20px 3rem;
            }
        }
        @include breakpoint(desktop){
            height: calc(100vh - 8.2rem);
            padding: 8rem 40px 0rem;
        }
        @include breakpoint(tablet) {
            height: calc(100vh - 8rem);
        }
        @include breakpoint(smallTablet) {
            @media screen and (max-height:850px) {
                height: calc(100vh - 8rem);
                padding: 8rem 20px 0;
            }
        }
        .reset_text,
        .login__holder-block-layout {
            max-width: 640px;
            margin-left: auto;
            margin-right: auto;
            #fos_user_resetting_form {
                label {
                    color: $color-grey-6 !important;
                }
            }
            .username {
                &:disabled {
                    border: 0.1rem solid $color-grey-2;
                    border-radius: .5rem;
                    padding: 1.6rem 2rem;
                    color: $color-grey-6;
                    font-family: $regular;
                    font-size: 1.4rem;
                    font-weight: normal;
                    font-style: unset;
                    background-color: $color-grey-1;
                    opacity: .5;
                }
            }
        }
    }

    .login__back_home{
        @include breakpoint(toBigPhone){
            font-size: 11px;
            text-align: right;
            padding: 1rem 20px;
        }
    }
    .login__hosted img{
        max-width: 20rem;
        height: 10rem;
        object-fit: contain;
        width: auto;
        padding-bottom: 0;
        @include breakpoint(toBigPhone){
            width: 25rem;
            max-width: 60%;
            margin: 0 auto;
        }
        &.login__hosted-whitelabel {
            padding-bottom: 3rem;
            @include breakpoint(mobileScreen) {
                height: 90px;
                width: 100%;
                object-fit: scale-down;
                padding-bottom: 0;
            }
        }
    }
    .reset_text{
        @include breakpoint(toBigPhone){
            font-size: 12px;
            line-height: 1.5;
        }
    }
    .login__holder-block-layout{
        @include breakpoint(toBigPhone){
            margin-top: 25px;
        }
    }
    .login__holder .fos_user_resetting_reset input[type=submit]{
        @include breakpoint(toBigPhone) {
            margin: o auto;
            margin-top: 50px;
            font-size: 12px;
            padding: 20px 40px;
            width: 100%;
        }
    }
}

