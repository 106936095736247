.peoples-body > section {
    border: 1px solid #f1f1f1;
    box-shadow: 0 1px 6px #ececec;
    margin-bottom: 15px;
}

.peoples-body .member-panel-title-section {
    padding: 20px 7px 20px 20px;
    display: flex;
    flex-direction: row;
}
.peoples-body-invited section {
    padding: 20px 7px 20px 20px;
}
.peoples-body-invited.peoples-body .member-panel-title-section{
    padding: 0;
}
.peoples-body .member-panel-title-section > span {
    flex-grow: 1;
}
.peoples-body-contacts .member-panel-title-section > span.right-profile-section:last-of-type,
.peoples-body-investors .member-panel-title-section > span.right-profile-section:last-of-type {
    text-align: right;
}
.peoples-body .member-panel-title-section > span:last-of-type i {
    cursor: pointer;
}
.member-contact-info,
.member-investors-info {
    display: flex;
    flex-direction: row;
    color: #bdbdbd;
    width: 600px;
}
.member-contact-info > div,
.member-investors-info > div{
    flex: 1 1;
    padding: 12px;
    border-top: 1px solid #f4f4f4;
    text-align: center;
    min-width: 100px;
    font-size: 14px;
}
.member-contact-info > div:not(:first-child),
.member-investors-info > div:not(:first-child) {
    border-left: 1px solid #f4f4f4;
}

.member-investors-info > div .email {
    font-size: 10px;
    margin-top: 16px;
    word-break: break-word;
}

.member-contact-info .section-title,
.member-investors-info .section-title {
    font-size: 1rem;
    margin-bottom: 8px;
}

.peoples-body .section-content {
    font-size: 0.8rem;
    color: #424953;
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
}
.peoples-body .section-content > div {
    flex: 1 0 30%;
    width: 0;
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 50px;
}

.peoples-body  small {
    font-size: 0.7rem;
    color: #A3ABB8;
    margin-top: 3px;
    display: flex;
}

.selected-deal {
    background-color: #f6ffeb;
}

.peoples-body-invited > section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.peoples-body-invited > section > div {
    flex-grow: 1;
    min-width: 50%;
}
.peoples-body .invite-member-actions {
    margin: 1em 0 1em 0;
    text-align: center;
}
.peoples-body .witdraw-invite{
    color: #EE6500;
}
.peoples-body .right-profile-section a.edit-profile {
    vertical-align: middle;
    margin-right: 10px;
    color: #EE6500;
}
.peoples-body .right-profile-section {
    font-size: 0.9em;
}
.peoples-body .right-profile-section a.send-invitation {
    vertical-align: middle;
    margin-right: 10px;
    color: #3EBDE9;
}
.peoples-body .right-profile-section i {
    vertical-align: middle;
}
.resend-invite {
    color: #4EC1E7;
}

.peoples-body-invited > section > div > a {
    padding: 0 2vh;
    min-width: 50%;
    cursor: pointer;
}
.peoples-body .disabled-color {
    color: #E5E7EC;
}
.peoples-body .deal-icon {
    height: 36px;
    width: 36px;
    margin-top: 2px;
    border: 1px solid #e0e0e0;
    margin-right: 3px;
    margin-left: 3px;
    overflow: hidden;
    border-radius: 7px;
    display: flex;
}
.peoples-body .deal-icon img {
    width: 100%;
    align-self: center;
}
.peoples-body .invitation-available {
    background-color: #F2FAFC;
}
.peoples-body .invitation-available .member-contact-info > div:last-of-type {
    border-left: 1px solid #DAF2FC;
}
.peoples-body .invitation-available .member-contact-info > div {
    border-top: 1px solid #DAF2FC;
}

.hub-drop-block {
    border: medium none;
    border-radius: 0;
    box-shadow: 0 0 1px rgba(86, 96, 117, 0.7);
    background-color: #fff;
    text-transform: none;
    top: 20px;
    right: 11px;
    color: #676a6c;
    list-style: none outside none;
    padding: 0;
    position: absolute;
    text-shadow: none;
    margin: 2px 0 0;
    z-index: 10000;
}

.hub-drop-block .drop-body {
    padding: 10px;
    font-size: 0.7rem;
    background: #F1F2F3;
}
.hub-drop-block .drop-body a{
    color: #A3ABB8;
    cursor: pointer;
}
.more-items-icon {
    color: $color-grey-6;
    cursor: pointer;
}
