//Animation mixin setup
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
// Define animation name, and properties
@include keyframes(fade-in) {
  0% { opacity: 0; }
  90% { opacity: 1; }
}
@include keyframes(fade-out) {
  0% { opacity: 1; }
  90% { opacity: 0; }
}
/*  Usage @include animation('fade-in 5s 3'); */
/*  Usage @include animation('fade-out 5s 3'); */