.dealNew {
  &__preview{
    .maskNew{
      right: 70.8%;
      opacity: 0.5;
      z-index: 101;
    }
    .fixed{
      bottom: 5.4rem !important;
    }
  }
  .number{
    padding-left: 4.5rem !important;
  }
  .categorySelect .multiselect__tags{
    border: 0;
    padding: 0;
  }
  .input-error{
    letter-spacing: 1px;
  }
  label{
    margin-bottom: 1rem;
  }
  .multiselect{
    &__content{
      padding-left: 0 !important;
    }
    &:focus{
      outline: none;
    }
    &__input{
      border: 1px solid $borderColor;
      &::placeholder{
        color: $smallColor;
        font-weight: 500;
        font-style: italic;
      }
    }
    &__content {
      border: 1px solid $layerBackground;
      background-color: $layerBackground;
      top: 5.6rem;
    }
    &__option--highlight{
      background-color: $layerBackground;
      color: $brand;
    }
    &__element{
      font-size: 1.2rem;
      color: $black;
      background: $white;
      @include transition(.3s all ease-in-out);
      border-bottom: 1px solid $layerBorder;
      &:hover{
        color: $brand;
        span{
          color: $brand;
        }
      }
      &:last-child{
        border-bottom: 0;
      }
    }
    &__select{
      &:before{
        border-color: $smallColor transparent transparent;
      }
    }
    &__option--highlight{
      background-color: $white;
      color: $brand;
    }
    &__single{
      font-size: 1.2rem;
      color: $black;
      background-color: $darkerWhite;
      width: 100%;
      font-weight: 600;
      box-sizing: border-box;
      outline: none;
      border: 1px solid $borderColor;
      display: block;
      padding: 2rem 2rem 2rem 3rem;
      border-radius: 3px;
    }
    &.multiselect--disabled {
      .multiselect__single {
        background-color: $color-white;
        color: $color-grey-2;
      }
    }
  }
  .select__small{
    input{
      &:focus{
        border: 1px solid $black;
      }
    }
  }
  .dealNew__cover {
    background-size: inherit;
    background-position: 50% 50%;
  }
  .dealNew__input{
    label{
      font-size: 1.1rem;
      letter-spacing: 0px;
      color: $smallColor;
      font-weight: 500;
      margin-bottom: 1.5rem;
      display: block;
    }
    input {
      margin-top: 0;
      padding: 2rem 3rem;
      outline: none;
      border-radius: 3px;
      border: 1px solid $investmentBorder;
      width: 100%;
      box-sizing: border-box;
      color: $black;
      background: $darkerWhite;
      font-weight: 600;
      &::placeholder{
        color: $smallColor;
        font-weight: 600;
        font-style: italic;
      }
    }
    &.disabled {
      opacity: 0.2;
    }
  }

  .dealNew__input .currency,
  .dealNew__input .percentage {
    position: absolute;
    top: 2.1rem;
    left: 3rem;
    color: $black;
    font-weight: 600;
    font-size: 1.2rem;
    width: 2rem;
  }
  .companyNew__cover-add{
    top: 3rem;
    left: 3rem;
    background-color: $brand;
    border-radius: 5px;
    padding: 2rem 3rem;
    text-transform: uppercase;
    bottom: inherit;
    right: inherit;
  }
  .companyNew__biggerTitle {
    margin-bottom: 2.5rem;
    font-weight: bold;
  }
  .deal_text {
    font-size: 1.4rem;
    font-weight: bold;
  }
  .nonSelect {
    opacity: 0.2;
    position: relative;
    &:after {
      position: absolute;
      top: 0;
      left: -1.5rem;
      right: 0;
      bottom: 0;
      z-index: 5;
      content: '';
      display: block;
    }
  }
  &__cover {
    height: 48rem;
    &-info {
      display: none;
      &-header {
        display: none;
      }
    }
  }
  &__description {
    .dealNew__input {
      width: 30.6%;
      display: inline-block;
      vertical-align: top;
      margin: 2rem 0;
      @include breakpoint(smallLaptop) {
        width: 44.2%;
        margin-right: 4rem;
      }
      &.middle {
        margin-left: 4rem;
        margin-right: 4rem;
        @include breakpoint(smallLaptop) {
          margin-left: 0;
        }
      }
      &.customSection {
        width: 100%;
        margin-left: 0;
        margin-top: 1rem;
      }
    }
    p{
      font-size: 1.2rem;
      font-weight: normal;
      color: $black;
    }
    ul{
      padding-left: 1.75rem;
      li{
        font-size: 2rem;
        font-weight: normal;
        color: $black;
        span{
          font-size: 1.2rem;
          font-weight: 300;
          display: block;
          vertical-align: middle;
          color: $black;
          margin-left: 1rem;
        }
      }
    }
    .editor__input{
      .companyNew__input-mask{
        margin-bottom: 2rem;
      }
      label{
        margin-bottom: 0;
      }
    }
    .table .companyNew__table-cell{
      padding: 2rem;
      background-color: $darkerWhite;
      font-weight: 500;
    }
    .table .companyNew__table-cell-info .companyNew__table-cell{
      padding: 0;
    }
    .table .currency{
      color: $black;
      font-weight: 500;
    }
    .table .companyNew__table-cell{
      border-color: $borderColor;
      border-top: 0;
      border-left: 0;
    }
    .table .multiselect__input{
      margin-bottom: 0;
      border-radius: 0;
      border: 0;
      padding: 2.24rem;
      border-top: 1px solid $borderColor;
      color: $black;
      font-weight: 600;
    }
    .table .currency{
      font-size: 1.2rem;
      font-weight: 600;
      top: 36%;
    }
    .table .companyNew__table-cell > input{
      padding-left: 4rem;
      color: $black;
      font-weight: 600;
      &:focus{
        border: 0;
      }
    }
    .table .companyNew__table-row .companyNew__table-cell:first-child{
      padding: 2.98rem;
    }
    .table .companyNew__table-row{
      border: 1px solid $borderColor;
      border-radius: 5px;
      border-right: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0;
    }
    .table .companyNew__table-cell:nth-child(2) .multiselect__input{
      padding: 2.24rem;
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
    }
    .table .addColumn {
      border: 1px solid $brand;
      height: 50px;
      width: 50px;
      text-align: center;
      a {
        color: $brand;
        display: inline-block;
        font-size: 45px;
        line-height: 50px;
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
    }
    .table addNew{
      border: 1px solid $brand;
      color: $brand;
      padding: 2.85rem 0;
      border-radius: 0;
      line-height: 0;
      width: 6rem;
      button{
        padding: 1.2rem;
      }
    }
    .table{
      padding-right: 0;
      position: relative;
      .companyNew__table-cell-info{
        width: 146px;
        input{
          margin-top: 0;
        }
        .companyNew__table-cell{
          &:last-child{
            height: 5.5rem;
          }
        }
      }
      .companyNew__table-row{
        width: 146px;

        &.headerColumn {
          color: #000000;
        }
      }
      .companyNew__table{
        margin-top: 2rem;
      }
    }
  }
  .select-input {
    width: 87%;
    background: transparent;
    margin-left: -1px;
    border: 0;
    outline: none;
    -webkit-appearance: none;
    height: 100%;
    padding-left: 4rem;
  }
  .biggerSelect {
    width: 100%;
    height: 6rem;
    border: 1px solid $borderColor;
    .material-icons {
      z-index: 0;
      color: $black;
    }
  }
  &__timeline {
    .biggerSelect {
      width: 60%;
      margin-left: -0.4rem;
    }
    .select__small{
      width: 40%;
      flex-basis: 40%;
      &:first-child{
        input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
      &:nth-child(2){
        input {
          border-radius: 0;
        }
      }
    }
    .select__big{
      flex-basis: 55%;
      width: 55%;
      &:last-child{
        input {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    .smallerSelect {
      width: 37%;
      margin-left: 0;
    }
  }
  &__financials {
    .specialBiggerSelect {
      width: 61.8%;
      margin-left: -0.4rem;
    }
    .number{
      padding-left: 4.5rem !important;
    }
    .specialSmallerSelect {
      width: 36.9%;
      margin-left: 0;
    }
    .dealNew__input{
      width: 30.6%;
      @include breakpoint(smallLaptop) {
        width: 44.2%;
      }
      input{
        width: 100%;
      }
    }
    .select__small{
      input{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .select__big{
      input{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  &__purpose {
    &-table {
      &-header {
        margin-top: 4rem;
        display: flex;
        align-items: center;
        .dealNew__purpose-table-cell {
          font-size: 1.1rem;
          letter-spacing: 0px;
          color: #aab2bd;
          font-weight: 500;
          margin-bottom: 1rem;
          display: block;
          &:nth-child(2n+1) {
            width: 79%;
          }
          &:nth-child(2n+2) {
            width: 20%;
          }
        }
      }
      &-body {
        display: flex;
        align-items: center;
        .dealNew__purpose-table-cell {
          margin-top: 0rem;
          vertical-align: middle;
          border: 1px solid $borderColor;
          padding: 1.5rem 1rem;
          height: 2rem;
          position: relative;
          color: $borderColor;
          font-weight: 300;
          font-size: 1.2rem;
          background-color: $darkerWhite;
          input{
            background-color: $darkerWhite;
            font-size: 1.2rem;
            font-weight: 600;
            color: $black;
            &::placeholder{
              color: $smallColor;
              font-style: italic;
            }
          }
          span {
            font-size: 1.1rem;
            letter-spacing: 1px;
          }
          &:nth-child(2n+1) {
            width: 80%
          }
          &:nth-child(2n+2) {
            width: 20%;
            border-left: 0;
          }
          input{
            border: 0;
            width: 100%;
            padding: 0.5rem 0;
            outline: none;
            font-size: 1.1rem;
          }
          span{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 1.5rem 1rem;
          }
        }
      }
      button {
        width: 100%;
        border-radius: 5px;
        display: block;
        font-size: 2.6rem;
        background: transparent;
        border: 1px solid $brand;
        padding: 1rem 0;
        color: $brand;
        outline: none;
        cursor: pointer;
        margin-top: 4rem;
      }
    }
  }
  &__companyDetails {
    .required-part {
      margin-bottom: 4rem;
    }

    .optionalTextEdit {
      margin-top: 4rem;
      margin-bottom: 4rem;

      label {
        margin-bottom: 12px;
      }

      .remove__button {
        top: -7px;
      }

      .wordCount {
        position: absolute;
        top: -26px;
        right: 0;
      }
    }

    .optionalBlocks {

      .financialProjection {
        margin-bottom: 4rem;

        .table {
          padding: 6rem;
        }
      }

      .team {
        position: relative;
        border: 1px solid $borderColor;
        margin: 0 0 4rem 0;
        padding: 6rem;

        .teamMember {
          margin: 10px 2% 0 0;
          width: 32%;

          &:nth-child(3n+2) {
            margin-right: 0;
          }

          label {
            margin-bottom: 10px;
          }

          .categorySelect {
            margin: 0;
          }

          .removeMemberButton {
            top: 0;
          }
        }

      }
    }
  }
  &__tag {
    &-bubble{
      position: relative;
      b{
        position: absolute;
        top: -1.5rem;
        right: -1.2rem;
        background: $red;
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        align-items: center;
        text-align: center;
        font-size: 1rem;
        margin: 0 auto;
        margin-left: 0.2rem;
        padding: 0;
        /* display: block; */
        justify-content: center;
        color: white;
        border-radius: 4rem;
      }
    }
    &-block {
      display: inline-block;
      vertical-align: top;
      width: 100%;
      position: relative;
      input {
        width: 100%;
        margin-bottom: 0;
        border: 1px solid $borderColor;
        background-color: $darkerWhite;
        padding-left: 2rem;
        color: $black;
        font-style: italic;
        font-family: $regular;
        outline: none;
        @include transition (.3s all ease-in-out);
        &:focus {
          border: 1px solid $black;
        }
      }
      .material-icons {
        position: absolute;
        top: 1.5rem;
        right: 2rem;
        font-size: 2.8rem;
        cursor: pointer;
      }
      label, span {
        font-size: 1.1rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        color: $black;
        font-weight: 500;
        margin-bottom: 1rem;
        display: block;
        padding-left: 2rem;
      }
      span {
        border: 1px solid $brand;
        padding: 1.5rem 3rem;
        margin-left: 2rem;
        margin-top: 1.5rem;
        border-radius: 5px;
        display: inline-block;
        @include transition (.3s all ease-in-out);
      }
    }
    .multiselect__element{
      span{
        border-right: 0;
        padding: 1rem;
        margin: 0;
        border: 0;
        display: block;
        &:hover{
          background-color: transparent;
          color: $brand;
        }
      }
    }

    & .multiselect__input {
      transition: none;
    }

    & .multiselect__placeholder {
      margin: 0;
      padding: 2rem 3rem !important;
      border: 1px solid $color-grey-3;
      font-size: 1.4rem;
    }
  }
  .tagsBlock {
    padding: 6rem 17.5rem;

    .companyNew__smallerTitle {
      margin-bottom: 3.5rem;
    }
  }
  .showRight{
    span{
      display: block;
      text-align: right;
      font-size: 1.2rem;
      letter-spacing: 1px;
      color: #aab2bd;
    }
  }
  .table{
    .error{
      bottom: 4rem !important;
      right: 0;
    }
  }
  .custom-radio{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.96rem 1rem;
    @include breakpoint (toDesktop) {
      padding: 1.93rem 1rem;
    }
    @include breakpoint (smallLaptop) {
      padding: 1.93rem 1rem;
    }
    border: 1px solid #e6e9ed;
    border-left: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    &__check{
      position: relative;
      width: auto;
      margin-right: 0.3rem;
      height: 1.1rem;
      input{
        width: 100%;
        box-sizing: border-box;
        padding-left: 3rem;
        color: $black;
        background: #f5f7fa;
        font-weight: 600;
        border-radius: 3px;
      }
      input[type=radio] {
        position: absolute;
        visibility: hidden;
      }
      input[type=radio]:checked ~ .check {
        border: 1px solid #ccd1d9;
      }
      .check::before {
        display: block;
        position: absolute;
        content: '';
        border-radius: 100%;
        height: 8px;
        width: 8px;
        top: 4px;
        left: 4px;
        margin: auto;
        transition: background 0.25s linear;
        -webkit-transition: background 0.25s linear;
      }
      input[type=radio]:checked ~ .check::before {
        background: #1f7be8;
      }
      label{
        font-size: 1.1rem;
        letter-spacing: 0;
        color: #aab2bd;
        font-weight: 500;
        display: block;
        z-index: 9;
        cursor: pointer;
        -webkit-transition: all 0.25s linear;
        height: 0;
        opacity: 0;
        margin: 0 0 1.5rem;
        vertical-align: middle;
        position: relative;
      }
      .check{
        display: block;
        position: absolute;
        border: 1px solid #AAAAAA;
        border-radius: 100%;
        height: 15px;
        width: 15px;
        transition: border .25s linear;
        -webkit-transition: border .25s linear;
        top: -30%;
        z-index: 0;
        @include breakpoint(smallLaptop){
          top: -50%;
        }
      }
    }
    &__holder{
      width: 93%;
      flex-basis: 93%;
      margin-left: 0.7rem;
      label{
        letter-spacing: 0;
        color: $black;
        font-weight: 500;
        display: block;
        margin-bottom: 0 !important;
        margin-top: 0;
        font-size: 1.3rem;
      }
    }
  }
  .select_valuation__small {
    flex-basis: 43%;
    width: 43%;
  }
  .valuation_date {
    input[type="text"] {
      margin: 0;
    }
  }
  &__financials {
    .to-be-defined-input-option {
      input, .select__big {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  .buttons-holder {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .button-link {
    display: flex;
    text-align: left;
    color: $brand;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    img.button-image {
      width: 12px;
      height: 14px;
      margin-right: 10px;
    }

    .fa-plus-circle {
      margin-right: 10px;
      &:before {
        font-size: 14px;
      }
    }

  }

  .documentsSection {
    .document__parent {
      margin-top: 4rem;

      .document__block {
        margin-top: 0;
        margin-bottom: 4rem;
      }
    }

    .companyNew__button {
      margin-top: 1rem;

      input {
        cursor: pointer;
      }
    }
  }
}
.categorySelect{
  .multiselect{
      outline: none;
    &__tags{
      padding: 0rem;
      font-size: 1.2rem;
      color: black;
      background-color: $darkerWhite;
      width: 100%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      outline: none;
      border: 0px solid $borderColor;
    }
    &__single{
      border: 1px solid $borderColor;
      border-radius: 5px;
    }
    &__element{
      border-bottom: 1px solid $borderColor;
    }
    &__element,
    &__option--highlight{
      background: $darkerWhite;
      color: $black;
    }
    &__select:before{
      border-color: $black transparent transparent;
    }
    &__content {
      border: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
    }
  }
  .preview-nda {
      float: right;
      font-size: 1.1rem;
      color: $brand;
      font-weight: 500;
      font-style: italic;
      line-height: normal;
      font-family: $regular;
      cursor: pointer;
  }
}
