.deal-details-compact {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__views {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      &__items {
        flex-grow: 1;
      }

      .deal-details-tab {

        .my-dropdown-dd {
          position: relative !important;
        }

        &__header {
          background-color: $color-white;
          cursor: pointer;
          padding: 2rem 2rem 2rem 3rem;
          display: flex;
          align-items: center;
          border-bottom: 1px solid $color-grey-1;
          color: $color-blue-main;

          &--title {
            font-size: 20px;
            font-weight: 500;
            user-select: none;
          }

          &--icon {
            align-items: center;
            font-size: 1rem;
            margin-right: 2rem;
            min-width: 2.4rem;
            height: 2.4rem;
            display: flex;
            justify-content: center;
          }

          &:hover {
            color: $color-blue-dark;
          }
        }

        .deal-details-content {
          display: flex;
          flex-direction: column;
          padding: 20px;
          background: $color-compact-background;

          @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            padding: 30px;
          }

          &__block {
            display: flex;
            flex-direction: column;

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
              flex-direction: row;
              flex-wrap: wrap;
            }

            &__pair {
              padding-bottom: 30px;
              width: 100%;

              @include breakpoint(tabletScreen) {
                flex: 0 49.5%;
                &:nth-of-type(odd) {
                  padding-right: 1%;
                }
              }

              @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                flex: 0 32%;

                &:nth-of-type(3n+1) {
                  padding-right: 1%;
                }

                &:nth-of-type(3n+2) {
                 padding-right: 1%;
                }

              }

              &.compact-traction {
                @include breakpoint(mediumScreen) {
                  width: calc(100vw - 51rem);
                }
              }


              &__title {
                font-size: 12px;
                color: $color-grey-10;
                word-break: break-word;
              }

              &__value {
                font-size: 14px;
                line-height: 1.73;
                color: $color-grey-6;
                word-break: break-word;
              }

              .value--blue {
                color: $color-blue-main;
                text-transform: capitalize;
              }

              .custom-image {
                width: 100%;
                max-height: 40rem;
                object-fit: contain;
              }

              .caption-image {
                font-size: 12px;
                margin-top: 1rem;
                margin-bottom: 2rem;
                color: $color-grey-6;
                text-align: center;
              }

              .custom-content-block {

                &:first-child {
                  margin-top: 4rem;
                }

                &:not(:last-child) {
                  margin-bottom: 4rem;
                }
              }
            }

            &__description {
              color: $color-grey-6;
              font-size: 14px;
              line-height: 1.86;
              text-align: justify;
              margin: 0;

              p, ol, span, li  {
                margin: 0;
                color: $color-grey-6;
                span, em, u {
                  color: $color-grey-6 !important;
                }
              }
              ol, ul {
                margin-block-start: 0;
                margin-block-end: 0;
                font-size: 14px;
                color: $color-grey-6;
              }
              span, u, strong, em, pre {
                background-color: unset !important;
                font-size: 14px;
                color: $color-grey-6 !important;
              }
              h1, h2, h3, h4 {
                letter-spacing: normal;
                color: $color-grey-6 !important;
                text-align: left;
                margin-bottom: 2rem;
              }
              h1 {
                font-size: 24px;
                font-weight: 500;
              }
              h2 {
                font-size: 20px;
                font-weight: 500;

              }
              h3 {
                font-size: 18px;
                font-weight: 500;
              }
              h4 {
                font-size: 16px;
                font-weight: 500;
              }
              h5 {
                font-size: 14px;
                font-weight: 500;
              }
              h6 {
                font-size: 12px;
                font-weight: 500;
              }
              p {
                font-size: 14px;
                font-weight: normal;
              }
            }

            &.custom-section {
              flex-direction: column;
              .custom-content {
                @extend .deal-details-content__block__description;
              }

              @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                flex-direction: column;
              }
            }

            .custom-stats {

              @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
              }
            }
          }

          &__purpose {
            display: flex;
            flex-direction: column;

            &__description {
              color: $color-grey-6;
              font-size: 14px;
              line-height: 1.86;
              text-align: justify;
              margin: 0;

              @extend .deal-details-content__block__description;
            }

            &__header {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 1.2rem 2rem .5rem 2rem;

              &__title {
                font-size: 12px;
                color: $color-grey-10;
              }
            }
            &__body {
              border-radius: 3px;
              border: 1px solid $color-grey-11;
            }
            &__type {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 1.2rem 2rem;
              background-color: $color-white;
              border-bottom: 1px solid $color-grey-1;
              border-radius: 2px;

              &__text {
                font-size: 14px;
                line-height: 1.86;
                color: $color-grey-6;
                word-break: break-all;

                &.first-element {
                  padding-right: 2rem;
                }

                &.last-element {
                  padding-left: 2rem;
                }
              }

              &__img {
                width: 3rem;
                height: 2.6rem;

                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .compact-documents {

        .my-dropdown-dd {
          position: relative !important;

          .documents {
            padding: 2rem 3rem;

            .file-image {
              .fa {
                font-size: 2.8rem;
              }
              .pdf-icon {
                color: $color-file-pdf;
              }
              .doc-icon {
                color: $color-file-word;
              }
              .xls-icon {
                color: $color-file-excel;
              }
              .ppt-icon {
                color: $color-file-powerpoint;
              }
              .image-icon {
                color: $color-file-image;
              }
            }
          }
        }

        .accordion {
          background-color: $color-white;
          cursor: pointer;
          padding: 2rem 2rem 2rem 3rem;
          display: flex;
          align-items: center;
          border-bottom: 1px solid $color-grey-1;
          color: $color-blue-main;

          &__toggle {
            align-items: center;
            font-size: 1rem;
            margin-right: 2rem;
            min-width: 2.4rem;
            height: 2.4rem;
            display: -webkit-box;
            display: flex;
            justify-content: center;

            .fa {
              font-weight: 600;
              font-size: 1.5rem;
            }
          }

          &__title {
            font-size: 20px;
            font-weight: 500;
          }
        }
      }
  }
}
