.founders {
    display: flex;
    height: 100vh;
    overflow: hidden;
    font-family: 'Nunito', sans-serif;


    & .add-new-seat--select-radio {
        border: 0;
    }

    & .compose-invitation {
        &-info {
            color: $black;
            font-size: 1.4rem;
            font-weight: 600;

            a {
                color: $brand;
            }
        }

        .section-title {

            h3,
            .fa-caret-down:before {
                color: $founderBrand;
            }
        }

        .newForm__input button {
            background-color: $founderDarkerBrand;

            &:hover {
                background-color: $founderDarkerBrandHover;
            }
        }
    }

    & .searchFirm {

        .do-details [type="radio"]:checked+label:after,
        .do-details [type="radio"]:not(:checked)+label:after,
        div button {
            background-color: $founderDarkerBrand;
        }
    }

    &-navigation {
        &__dropdown {
            .my-dropdown-dd {
                background-color: $white;
                top: 0 !important;
            }
        }

        &__close-button {
            background-color: unset;
            border: none;
            font-size: 20px;
        }

        &__header {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
        }
    }

    &__sidebar {
        display: none;

        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
            display: flex;
        }

        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        width: 38.5rem;

        .founders-sidebar {
            padding: 2rem;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            background-color: $color-white;

            &__info {
                display: flex;
                background-image: url('/images/background/company-default.jpg');
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                padding-bottom: 12rem;
                border-radius: 5px;

                &--wrapper {
                    display: flex;
                    background-color: rgba(0, 0, 0, 0.5);
                    border-radius: 5px;
                    width: 100%;

                    .sidebar-details {
                        display: flex;
                        flex-direction: column;
                        padding: 1.5rem;
                        width: calc(100% - 6.4rem);

                        &__item {
                            font-size: 2rem;


                            &--field {
                                color: $color-grey-4;
                            }

                            &--value {
                                color: $color-white;
                            }
                        }
                    }

                    .sidebar-logo {
                        width: 6.4rem;
                        height: 6.4rem;
                        padding-top: 2rem;
                        padding-right: 2rem;

                        &--image {
                            width: 100%;
                        }
                    }
                }
            }

            &__profile {
                margin-top: 1.5rem;
                padding: 2rem 1.5rem;
                border-radius: 5px;
                border: solid 1px $color-white-3;
                background-color: $color-white-2;

                .profile-info {
                    display: flex;
                    flex-direction: column;
                    color: $color-purple-main;

                    &__title {
                        font-size: 1.6rem;

                        &--value {
                            font-weight: 600;
                        }
                    }

                    &--progress {
                        display: flex;
                        width: 100%;
                        margin-top: 1rem;
                    }

                    progress[value] {
                        -webkit-appearance: none;
                        appearance: none;
                        height: 1.5rem;
                    }

                    progress[value]::-webkit-progress-bar {
                        background-color: $color-grey-19;
                        border-radius: 5px;
                    }

                    progress[value]::-webkit-progress-value {
                        background-image: linear-gradient(to left, #9794cf, $color-cyan-secondary);
                        border-radius: 5px;
                        // background-size: 35px 20px, 100% 100%, 100% 100%;
                    }
                }

                .profile-bio {
                    display: flex;
                    flex-direction: column;
                    margin-top: 1.5rem;
                    font-size: 1.4rem;

                    &--title {
                        color: $color-grey-17;
                        font-weight: 600;
                    }

                    &--link {
                        margin-top: 1rem;
                        color: $color-purple-main;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }

            &__tabs {
                margin-top: 1.5rem;

                .sidebar-tab {
                    display: flex;
                    align-items: center;
                    padding: 1.4rem 0;
                    font-size: 1.6rem;
                    box-shadow: 0 1px 0 0 $color-white-3;
                    cursor: default;

                    i {
                        font-size: 2rem;
                    }

                    &--item {
                        cursor: pointer;
                        color: black;
                        display: flex;
                        align-items: center;

                        .material-icons-outlined {
                            color: $color-cyan-secondary;
                        }

                        &--label {
                            padding-left: 1.7rem;
                        }
                    }

                    .material-icons-outlined {
                        color: $founderBrand;
                    }
                }
            }
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: calc(100% - 38.5rem);
        height: 100vh;
        overflow-y: auto;

        @media only screen and (max-width: 900px) {
            max-width: 100%;
        }

        &--navigation {
            display: flex;
            height: 60px;
            background: $color-white;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

            @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                padding: 0 0 0 30px;
            }

            .founders-navigation {
                display: flex;
                flex-grow: 1;

                @include breakpoint(smallScreen) {
                    padding-right: 40px;
                }

                height: 60px;

                &__menu {
                    display: flex;
                    flex-grow: 1;

                    @media (min-width: 1800px) {
                        padding-left: 320px;
                    }

                    .menu-tabs {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 auto;

                        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                            margin: 0;
                        }

                        .collapsed__sidebar {
                            margin-right: 20px;
                            cursor: pointer;

                            @media only screen and (min-width: 901px) {
                                display: none;
                            }
                        }

                        &-item {
                            flex-grow: 1;
                            display: flex;
                            height: 100%;
                            width: 7rem;

                            &__link {
                                display: flex;
                                flex-grow: 1;
                                align-items: center;
                                justify-content: center;
                                color: $color-grey-20;

                                &:hover {
                                    color: $color-cyan-secondary;
                                }
                            }

                            .is-active {
                                border-bottom: 2px solid $color-cyan-secondary;
                                color: $color-cyan-secondary;
                            }
                        }
                    }

                    .menu-tabs.apps {
                        margin: 0;

                        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                            display: none;
                        }
                    }
                }

                &__profile {
                    display: flex;
                    align-items: center;
                    margin-right: 20px;

                    @include breakpoint(smallScreen) {
                        margin-right: 0;
                    }

                    &__logo {
                        border-radius: 5px;
                        border: solid 1px $color-white-3;
                        background-color: $color-white-2;
                        width: 4rem;
                        height: 4rem;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        cursor: pointer;

                        @include multiple-media(mediumScreen, largeScreen) {
                            margin-right: 20px;
                        }

                        &--item {
                            width: 100%;
                            height: 4rem;
                        }

                        &__initials {
                            font-size: 1.8rem;
                            font-family: Nunito, sans-serif;
                            font-weight: bold;
                            text-align: center;
                            color: $color-white-3;
                        }
                    }

                    .popup-menu-items {
                        width: 16rem;
                        right: 16px;

                        .menu-item {
                            display: flex;
                            flex-grow: 1;
                            padding: 1.4rem 2rem;
                            border-bottom: 1px solid $color-grey-18;


                            &__content {
                                display: flex;
                                flex-grow: 1;
                                align-items: center;
                                color: $black;

                                i {
                                    font-size: 2rem;
                                }

                                &--title {
                                    margin-left: 1.5rem;
                                    font-size: 1.6rem;
                                }
                            }
                        }
                    }

                    .material-icons-outlined,
                    .material-icons {
                        color: $founderBrand;
                    }
                }

                .three-dot-menu {
                    .sidebar-tab:first-child {
                        border-top: 1px solid #d2d4d8;
                    }

                    .sidebar-tab:first-of-type {
                        border-top-right-radius: .5rem;
                    }

                    .material-icons-outlined {
                        margin-right: 15px;
                    }

                    .sidebar-tab {
                        margin-left: 2px;
                        font-family: Nunito;
                        color: #434a54;
                        background-color: white;
                        font-size: 1.2rem;
                        font-weight: 500;
                        line-height: 2;
                        cursor: pointer;
                        padding: 10px 0;
                        border-bottom: 1px solid #d2d4d8;

                        &--item {
                            display: flex;
                        }
                    }

                    .founders-sidebar {
                        padding: 20px;
                    }

                    .founders-sidebar__tabs {
                        height: 100vh;
                        background-color: $white;

                        & * {
                            box-sizing: border-box;
                        }
                    }

                    .sidebar-tab--item--label {
                        vertical-align: super;
                        font-size: 1.4rem;
                        font-weight: 500;
                        line-height: 2;
                        color: #434a54;
                        margin-left: 2px;
                    }
                }
            }
        }

        &--view {
            .network-wrapper {
                padding-top: 20px;
                padding-bottom: 60px;

                @media (min-width: 900px) {
                    padding-top: 30px;
                    padding-left: 30px;
                    padding-right: 30px;
                }

                @media (min-width: 1800px) {
                    padding-left: 350px;
                    padding-right: 350px;
                }

                .track-wrapper {
                    min-height: 104px;
                }

                .investors-wrapper {

                    @media (min-width: 1800px) {
                        padding: 0 60px;
                    }

                    @media (min-width: 1200px) {
                        padding: 0 30px;
                    }

                    .investors-list--header {
                        @media (max-width: 900px) {
                            padding: 0px 2rem;
                        }

                        .title {
                            font-size: 24px;
                            font-weight: bold;
                            color: #000;

                            @media (max-width: 600px) {
                                font-size: 18px;
                            }
                        }

                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 32px;
                        margin: 3rem 0;

                        .investors-form {
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            width: calc(100% - 150px);

                            .material-icons-outlined {
                                cursor: pointer;

                                &:hover {
                                    color: #5e5e5e;
                                }
                            }

                            .search-investors-icon {
                                padding-right: 2rem;
                            }

                            .add-investor-icon {
                                margin-left: 1.5rem;
                            }

                            .search-investors-icon,
                            .add-investor-icon {
                                color: $color-cyan-secondary;
                            }

                            .search-investors {
                                display: flex;
                                align-items: center;
                                height: 32px;
                                width: 100%;
                                padding: 0 2rem;
                                border-radius: 16px;
                                box-sizing: border-box;
                                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                                background-color: #ffffff;

                                input {
                                    width: 100%;
                                    height: 100%;
                                    margin-left: 1rem;
                                    border: none;
                                    border-radius: 16px;
                                    outline: none;
                                }

                                .close__icon {
                                    color: $color-cyan-secondary;
                                }
                            }
                        }
                    }

                    .investors-list {
                        overflow-y: scroll;
                        height: calc(100vh - 335px);
                        max-height: calc(100vh - 335px);
                        min-width: 100%;
                    }
                }
            }
        }

        &--no-scroll {
            overflow: hidden;
        }
    }
}

.welcome-no-sidebar {
    max-width: 100% !important;
}

.welcome-no-sidebar .investor-welcome {
    max-width: 50%;
    margin: auto;
}

.welcome-add-icon {
    margin-left: 5px;
    cursor: pointer;
}

.investor-welcome .clickable-text {
    cursor: pointer;
}

.investor-welcome .clickable-text:hover {
    color: $brand;
}

.tooltip.investor-tooltip {
    &:focus {
        outline: none;
    }

    .tooltip-inner {
        width: 190px;
        padding: 0;
        background-color: #fff;
        box-sizing: border-box;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        color: #000;
        border-radius: 5px;
        transform: translateX(8px);

        .investor-tooltip__item {
            display: flex;
            align-items: center;
            padding: 14px 5px 14px 20px;
            font-size: 16px;
            font-family: Nunito;
            box-shadow: 0 1px 0 0 #dedede;

            &:hover {
                background-color: #f7f7f7;
                cursor: pointer;
            }

            .material-icons-outlined {
                color: $color-cyan-secondary;
                margin-right: 1.3rem;
            }
        }
    }

    &[x-placement^="bottom"] {
        .tooltip-arrow {
            border-width: 0 14px 14px 14px;
            top: -14px;
            border-color: #fffafa;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 0;
                height: 0;
            }

            &:before {
                right: -15px;
                bottom: -14px;
                border: 14px solid transparent;
                border-bottom-color: #e4dada;
            }

            &:after {
                right: -14px;
                bottom: -14px;
                border: 13px solid transparent;
                border-bottom-color: #fdfeff;
            }
        }
    }

    &[x-placement^="top"] {
        .tooltip-arrow {
            border-width: 14px 14px 0px 14px;
            bottom: -14px;
            border-color: #fffafa;

            &:before,
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 0;
                height: 0;
            }

            &:before {
                left: -15px;
                top: -14px;
                border: 14px solid transparent;
                border-top-color: #e4dada;
            }

            &:after {
                left: -14px;
                top: -14px;
                border: 13px solid transparent;
                border-top-color: #fdfeff;
            }
        }
    }

}
