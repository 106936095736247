.do-investor-profile-holder {
  .do-user__item{
    max-height: calc(100vh - 30rem);
  }
  @include multiple-media(toDesktop, tabletLandscape, smallLaptop){
    .do-user__item{
      max-height: calc(100vh - 34rem);
      &.do-user__item-passport {
        max-height: calc(100vh - 25rem);
      }
    }
  }
  .buttons__holder {
    background: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 6rem;
    justify-content: center;
    a {
      padding: 1rem;
    }
  }
  &__match-info {
    margin-top: 2rem;
    border-bottom: $black solid 2px;
    &__top {
      background-color: $black;
      position: relative;
      height: 4.4rem;
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    &__bottom {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      background: $white;
      padding: 0.2rem 1.2rem 0.8rem;
      position: relative;
      text-align: justify;
      font-size: 1.1rem;
      p {
        color: $black;
        line-height: 1.69;
      }
      hr {
        color: $borderWhite;
        border-color: $borderWhite;
        background-color: $borderWhite;
      }
    }
  }
}
