.founders-dataroom-wrapper {

    .do-dataroom {

        .insights-tab {
            overflow: visible;

            .search-box {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                background: $color-white;
                border-radius: 5px;
                padding: 1.7em 2.2em;
            }

            .layer-body__middle-filters-search {
                box-sizing: border-box;
                border: solid 1px $color-grey-18;
                border-radius: 5px;
                box-shadow: none;
                height: 5.6em;
                padding: 1rem 2rem;

                input {
                    font-size: 1.8rem;
                    font-family: NunitoItalic, sans-serif;

                    &::placeholder {
                        color: #9a9a9a;
                    }
                }

                i {
                    font-size: 16px;
                }
            }

            &__container {
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                border: none;

                &--block {
                    h2 {
                        font-family: NunitoBold, sans-serif;
                        font-size: 2.2rem;
                        color: #000;
                        margin: 20px;
                    }
                }
            }

            .insights-stats {
                border-radius: 5px;
            }
        }
    }
}
