.network__preview {
  // New Lounge Style preview
  .lounge-page {
    max-height: none;

    .lounge-page__container {
      min-height: calc(100vh - 110px);

      .lounge-companies__more-deals-number-1,
      .lounge-companies__more-deals-number-2 {
        .company-block {
          @include multiple-media(mediumScreen, largeScreen) {
            height: calc(100vh - 450px);
            min-height: 25rem;
          }
        }
      }

      .lounge-companies__number-3 {
        @include multiple-media(mediumScreen, largeScreen) {
          width: 1000px;
          margin: 0 auto;
          height: calc(100vh - 450px);
        }
      }

      .lounge-companies__more-deals-number-3,
      .lounge-companies__more-deals-number-4,
      .lounge-companies__more-deals-number-5,
      .lounge-companies__more-deals-number-6 {
        @include multiple-media(mediumScreen, largeScreen) {
          height: calc(100vh - 450px);
        }
      }
    }
  }
}
.back-button {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 50px;
    color: white;
    z-index: 999;
    cursor: pointer;
}
