.peoples-wrapper {
    margin-top: 10rem;
    width: 100%;
}
.people-section {
  .peoples__body {
    max-height: calc(100vh - 270px) !important;
    @include breakpoint (bigScreen){
      max-height: calc(100vh - 288px) !important;
    }
  }

}
.peoples {
  &__container{
    font-size: 1.4rem;
    padding: 2rem 3rem;
    color: $color-grey-6;
    max-height: calc(100vh - 130px);
      @include breakpoint (bigScreen){
          max-height: calc(100vh - 141px);
      }
  }
  &__links {
    background-color: $white;
    padding: 1.7rem 0px;
    @include breakpoint (toDesktop){
      padding: 8.7rem 0;
    }
    ul{
      @include breakpoint (toDesktop){
        padding-left: 4.5rem;
      }
    }
    li{
      list-style: none;
      display: inline-block;
      a{
        color: $black;
        font-size: 1.2rem;
        margin-right: 4rem;
        padding: 0rem 1rem 1rem 1rem;
        font-weight: 500;
        @include breakpoint (tablet){
          font-size: 1.8rem;
        }
        @include breakpoint (phone){
          font-size: 3.2rem;
        }
        &.is-active{
          color: $brand;
          border-bottom: 2px solid $brand;
        }
      }
    }
  }
  &__smallBlock,
  &__bigBlock{
    display: inline-block;
    vertical-align: top;
    background-color: $color-white;
    @include breakpoint (toDesktop){
      max-height: none;
    }
  }
  &__smallBlock{
      width: calc(30% - 3rem);
      margin-right: 2.5rem;
  }
  &__biggerBlock{
    width: 100%;
  }
  &__header{
    padding: 0 2rem;
    height: 60px;
    border-bottom: 1px solid $borderColor;
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2{
      font-size: 1.4rem;
      letter-spacing: 0;
      color: $titleBlack;
      font-weight: 500;
      display: inline-block;
      vertical-align: middle;
      width: auto;
      margin-top: 0.8rem;
      @include breakpoint (tablet){
        font-size: 1.8rem;
      }
      @include breakpoint (phone){
        font-size: 2.2rem;
        margin-bottom: 0.5rem;
      }
    }
    &-search{
      display: inline-block;
      vertical-align: middle;
      width: auto;
      text-align: right;
      i{
        font-size: 3.5rem;
        cursor: pointer;
        @include breakpoint (tablet){
          font-size: 3.9rem;
        }
        @include breakpoint (phone){
          font-size: 4.3rem !important;
        }
      }
      input{
        border: 0;
        outline: 0;
        font-size: 1.6rem;
        width: 0rem;
        border-bottom: 1px solid $borderColor;
        &.activeInput{
          width: 20rem;
          @include breakpoint (toDesktop){
            width: 29rem !important;
            font-size: 16px;
          }
        }
        @include transition (.3s all ease-in-out);
      }
      input,
      i{
        vertical-align: middle;
      }
    }
  }
  &__body {
      padding: 1.6rem 2rem;
      overflow-y: scroll;
      max-height: calc(100vh - 200px);
      @include breakpoint (bigScreen){
          max-height: calc(100vh - 214px);
      }
      section{
        background: $white;
        border: 1px solid $borderColor;
        position: relative;
        margin-bottom: 2rem;
      }
      &-name{
        color: $black;
        font-weight: 500;
        padding: 2rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: default;
        @include transition (.3s all ease-in-out);
        &:hover{
          color: $brand;
        }
        small{
          color: $smallColor;
          display: block;
          margin-top: 0.3rem;
          font-size: 1.4rem;
          @include breakpoint (toDesktop){
            font-size: 2.4rem;
          }
          &.smallColor{
            color: $smallColor;
          }
        }
        .people-label{
          flex-grow: 1;
        }
      }
      .deal{
          background-color: $dealColor;
          border-color: $borderColorBlue;
      }
  }
  &__info{
    font-size: 0;
    position: relative;
    display: flex;
    &:before{
      position: absolute;
      background-color: $borderColor;
      height: 1px;
      left: 0;
      right: 0;
      top: 0;
      content: '';
    }
    &Block{
      width: 49.8%;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      font-size: 1rem;
      padding: 1.5rem 0;
      @include breakpoint (toDesktop){
        font-size: 2rem;
      }
      .deal-icon{
          width: 2.5rem;
          height: 2.5rem;
      }
      @include breakpoint (tablet){
        font-size: 1.4rem;
      }
      @include breakpoint (phone){
        font-size: 1.8rem;
      }
    }
    &-title{
      color: $gray;
      margin-bottom: 8px;
      @include breakpoint (phone){
        font-size: 2.8rem;
      }
    }
    &-content{
      color: $smallColor;
      font-size: 1.3rem;
      @include breakpoint (tablet){
        font-size: 1.7rem;
      }
      @include breakpoint (phone){
        font-size: 3.1rem;
      }
      &.assigned{
        color: $black;
        font-weight: 500;
      }
      &Block{
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        margin: 0.6rem;
        border-radius: 0.5rem;
        @include breakpoint (tablet){
          width: 3rem;
          height: 3rem;
        }
        @include breakpoint (phone){
          width: 3.5rem;
          height: 3.5rem;
        }
        img{
          width: 100%;
        }
        .green{
          color: $green;
        }
        .orange{
          color: $lightOrange;
        }
      }
    }
  }
  &__footer{
    text-align: right;
    position: relative;
    margin-top: 3rem;
    &:before{
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      content: '';
      display: block;
      @extend .shadow;
    }
    ul{
      padding-left: 0;
    }
    li{
      list-style: none;
      display: inline-block;
      margin: 0 2rem;
      a{
        color: $brand;
        font-size: 1.3rem;
        font-weight: 500;
        @include breakpoint (tablet){
          font-size: 1.7rem;
        }
        @include breakpoint (phone){
          font-size: 2.1rem;
        }
      }
    }
  }

  &__bodyBlock {
    width: 49.9% !important;
  }
  &__bigBlock{
    width: 40%;
    .hub-drop-block{
      width: 13rem;
    }
    .peoples__header{
      position: relative;
    }
    .peoples__infoBlock{
      width: 50%;
      position: relative;
      &:after{
        width: 1px;
        top: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        display: block;
        content: '';
        background-color: $borderColor;
      }
      @include breakpoint (toDesktop){
        width: 50%;
      }
    }
    .peoples__infoBlock:nth-child(1){
      width: 17%;
      display: none;
    }
    .peoples__infoBlock:last-child{
      &:after{
        display: none;
      }
    }
  }
}

//Quick fixes

.peoples__container{
  .peoples__body{
    overflow-y: scroll;
    padding-bottom: 0rem;
  }
  .peoples__smallBlock{
    position: relative;
    .peoples__footer{
        background: $white;
        padding: 0.5rem;
        margin-top: 0;
    }
  }
}
.edit__investor-preferences-description-text{
  color: $brand;
  font-size: 1.1rem;
}
.edit__investor-preferences-description-span{
  color: $black;
  font-size: 1.1rem;
}
.smallYellowColor{
  color: $yellow;
}
