///------------------------------------
//    #TRUMPS
//------------------------------------

// Globaly available hacks or overwrits





//-------------------------
//    #Print
//-------------------------
//
//Very crude, reset-like styles taken from the HTML5 Boilerplate:
//https://github.com/h5bp/html5-boilerplate/blob/5.3.0/dist/doc/css.md#print-styles
//https://github.com/h5bp/html5-boilerplate/blob/master/dist/css/main.css#L205-L282
//

@media print {
    // Sets default text properties for printing
    //Removed shadows from the elements
    *,
    *:before,
    *:after,
    *:first-letter,
    *:first-line {
        color: #000 !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }
    // Links have by default underline
    a,
    a:visited {
        text-decoration: underline;
    }
    //Shows the value of href
    a[href]:after {
        content: " (" attr(href) ")";
    }
    //Shows the title value of attribute
    abbr[title]:after {
        content: " (" attr(title) ")";
    }
    // Prevents displaying href content
    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }
    // Prevents pre, blockquote to be separated on two pages
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    // Table-header-group forces behaviour of thead repeating
    thead {
        display: table-header-group;
    }
    // Prevents tr, image to be separated on two pages
    tr,
    img {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }
    // Sets how many text elements should left behind
    // if page should go on new page
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    //  h2, h3 prevented page break after the element
    h2,
    h3 {
        page-break-after: avoid;
    }
}
