.legals-tab {
	height: calc(100vh - 25rem);
	overflow: hidden;
	flex-direction: column;
	@include multiple-media(mobileScreen, tabletScreen) {
		height: calc(100vh - 23rem);
		overflow: hidden;
		justify-content: flex-start;
	}
	.do-legals-originator__center {
		@include multiple-media(mobileScreen, tabletScreen) {
			max-height: 100%;
			margin-top: 0;
			overflow-x: hidden;
			@include hide-scrollbar;
		}
		@media (min-width:900px) {
			max-height: calc(100vh - 25rem);
			margin-top: 0;
		}
		&-block {
			margin-right: 2rem;

			@include multiple-media(mobileScreen, tabletScreen) {
				width: 100%;
				margin-right: 0;
				border-radius: .3rem;
				box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .1);
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				&:nth-child(5n+5) {
					margin-right: 0;
				}
				&:last-child {
					margin-bottom: 2rem;
				}
                &__identity {
                    flex-direction: column;
                    .do-legals-originator__center-block-text {
                        @include multiple-media(mobileScreen, tabletScreen) {
                            display: inline-flex;
                            padding-left: 2rem;
                        }
                    }
                }
			}
			@include multiple-media(smallScreen, mediumScreen) {
				width: calc(100% / 4 - 1.5rem);
				box-sizing: content-box;
				&:nth-child(5n+5) {
					margin-right: 2rem;
				}
				&:nth-child(4n+4) {
					margin-right: 0;
				}
			}
			@include breakpoint(largeScreen) {
				width: calc(100% / 5 - 1.6rem);
				&:nth-child(4n+4) {
					margin-right: 2rem;
				}
				&:nth-child(5n+5) {
					margin-right: 0;
				}
			}
			&-title {
				@include multiple-media(mobileScreen, tabletScreen) {
					flex: 100%;
				}
				> span {
					font-weight: 500;
					@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
						font-size: 1.2rem;
						flex: initial;
					}
				}
			}
			&-img {
				@include multiple-media(mobileScreen, tabletScreen) {
					margin: 0;
					height: auto;
					display: inline-flex;
					flex-flow: row nowrap;
					flex: initial;
				}
				> a > img {
					@include multiple-media(mobileScreen, tabletScreen) {
						width: 100%;
						display: block;
						margin: 0 auto;
						max-width: 6rem;
						max-height: 9rem;
						padding: 1.5rem;
					}
				}
			}
			&-text {
				@include multiple-media(mobileScreen, tabletScreen) {
					display: inline-flex;
					border-top: 0;
					padding-left: 0;
				}
				@include breakpoint(mobileScreen) {
					flex: 60%;
					@media (max-width:317px) {
						flex: 59%;
					}
				}
				@include breakpoint(tabletScreen) {
					flex: 70%;
				}
				> span {
					&:first-child {
						@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
							font-size: 1.3rem;
							font-weight: 500;
						}
					}
					&:nth-child(2) {
						@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
							font-size: 1rem;
							font-weight: 500;
						}
					}
				}
			}
		}
	}
	.view-documents {
		width: 99%;
		background-color: $white;
		margin-bottom: 2rem;
		padding: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__description {
			justify-content: flex-start;
			font-size: 1.2rem;
			font-weight: 500;
			margin-left: 1rem;
			&--btn {
				justify-content: flex-end;
				background-color: $color-blue-main;
				color: $white;
				padding: 1.5rem 1.5rem;
				text-transform: uppercase;
				cursor: pointer;
				font-family: $regular;
				border: 0;
				font-size: 1.2rem;
				font-weight: 600;
				border-radius: 2px;
				outline: none;
				margin-right: 1rem;
				letter-spacing: 0.4px;
				transition: 0.3s all ease-in-out;
				&:hover {
					background-color: $color-blue-dark;
				}
			}
		}
	}
}
