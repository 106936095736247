.fee-input {
  display: flex;
  flex-direction: column;
  &__item {
    display: flex;
    align-items: stretch;
    border: 1px solid $borderColor;
    width: 100%;
    position: relative;
    .errorText {
      color: $red;
      position: absolute;
      left: 2.5rem;
      bottom: 0.2rem;
    }
    .close {
      cursor: pointer;
      font-size: 1.5rem;
      color: $black;
      margin-top: -3px;
      position: absolute;
      right: -0.9rem;
      top: -0.9rem;
      background: $layerBackground;
      border-radius: 100%;
      padding: 0.2rem 0.5rem;
      line-height: 2rem;
      z-index: 1;
      i {
        color: $black;
      }
    }
    .currency {
      position: absolute;
      margin-left: -1.5rem;
      margin-top: 0.1rem;
    }
    > div {
      padding: 1.6rem 2rem;
      font-size: 1.1rem;
      border-right: 1px solid $borderColor;
      align-items: center;
      display: flex;
      position: relative;
      &.fundraising-input {
        background-color: $tableGray;
        border-top-left-radius: 5px;
        .errorText {
          left: 1.2rem;
        }
      }
      &.fundraising-text {
        span {
          display: block;
          text-align: center;
          margin: 0 auto;
        }
      }
      &.amount-text {
        color: $black;
        position: relative;
        .my-dropdown-dd {
          position: absolute !important;
          bottom: -1.7rem !important;
          left: 0 !important;
          right: -2.7rem !important;
        }
        .todo-widget__filters-older {
          display: flex;
          width: 100%;
          justify-content: space-between;
        }
        .todo-widget__filters-older ul {
          position: absolute;
          right: 0;
          top: 0;
          padding: 0;
          left: auto;
          list-style: none;
          border: 1px solid #ececec;
          border-radius: 5px;
          background-color: $color-white;
          z-index: 1;
          min-width: 12.5rem;
          margin-top: 0;
        }
      }
      &.amount-input {
        border-right: 0;
        background-color: $tableGray;
        border-top-right-radius: 5px;
        input {
          color: $black;
          &::placeholder {
            color: $smallColor;
          }
        }
      }
    }
    input {
      box-sizing : border-box;
      width: 100%;
      background-color: transparent;
      font-weight: 600;
      font-size: 1.2rem;
      font-family: $regular;
      border: 0;
      outline: none;
      &::placeholder{
        font-style: italic;
        color: $smallColor;
      }
    }
    &-bigger-box {
      width: 35%;
      color: $black;
      font-weight: 600;
      input{
        color: $black;
      }
    }
    &-smaller-box {
      width: 15%;
      color: $smallColor;
      font-weight: 600;
      input{
        color: $black;
        &::placeholder{
          color: $smallColor;
        }
      }
    }
  }

  &__add {
    padding: 0.75rem;
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    color: $black;
    border-bottom: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
    width: 100.4%;
    box-sizing: border-box;
    @include transition (.3s all ease-in-out);
    &:hover{
      background-color: $black;
      color: $white;
      border-color: $borderColor;
    }
  }
}
