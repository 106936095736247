.hubx-radio {
    position: relative;
    display: inline-block;


    > label {
        display: block;
        position: relative;
        font-weight: normal;
        font-size: 1.4rem;
        padding: 0.5rem 0 0 3.8rem;
        margin: 0 !important;
        color: $color-grey-6;
        height: 2.2rem;
        line-height: 1.4rem;
        cursor: pointer;
        transition: all 0.25s linear;
        z-index: 1;

        &:hover .checker {
            background-color: $color-grey-1;
            border-color: $color-grey-3;
        }

        > input[type="radio"] {
            position: absolute;
            visibility: hidden;
            &:checked .checker {
                border: .5rem solid $color-grey-2;
            }
            &:checked ~ .checker::before {
                background: $color-blue-main;
            }
        }

        .checker {
            display: block;
            cursor: pointer;
            position: absolute;
            border: 1px solid $color-grey-2;
            border-radius: 100%;
            height: 20px;
            width: 20px;
            top: 0;
            left: 0;
            z-index: 0;
            transition: border .25s linear;
            &::before {
                display: block;
                position: absolute;
                content: '';
                border-radius: 100%;
                height: 1rem;
                width: 1rem;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                margin: auto;
                transition: background 0.25s linear;
            }
        }
    }

    > span.errorText {
        display: block;
        font-size: 1.4rem;
        color: $color-red-main;
    }
}