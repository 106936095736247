.industry-selection {
  margin-bottom: -3.5rem;

  .search-container {
    height: 100px;
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    border: solid 1px $smallBlockBorder;
    @include breakpoint(hugeScreen) {
      height: 8rem;
    }

    .search-box {
      padding: 25px;
      height: 80px;
      position: relative;
      @include breakpoint(hugeScreen) {
        padding: 2.2rem;
      }

      input[type=text] {
        height: 45px;
        width: 100%;
        font-size: 1.95rem;
        font-family: "Montserrat", sans-serif;
        color: $black;
        border: none;
        border-bottom: 1px solid $borderWhite;
        @include breakpoint(devices) {
          font-size: 20px;
        }
        @include breakpoint(toBigPhone) {
          font-size: 16px;
        }
        @include breakpoint(hugeScreen) {
          height: 3rem;
          border-bottom: 3px solid $borderWhite;
        }
      }

      input[type=text]::placeholder {
        color: $dark-gray;
        opacity: 1;
      }

      input[type=text]:focus {
        outline: none;
      }

      i {
        position: absolute;
        right: 0;
        top: 43px;
        margin-right: 28px;
        font-size: 1.35rem;
        color: $black;
        @include breakpoint(mobileScreen) {
            top: 35px;
        }
        @include breakpoint(hugeScreen) {
          top: 35px;
          margin-right: 3rem;
        }
        @include breakpoint(smallLaptop) {
          font-size: 1.55rem;
        }
      }

      .errorText {
        position: absolute;
        right: 0;
        top: 110px;
        font-size: 12px;
        color: $red;
        letter-spacing: 1px;
      }
    }
  }

  .hasError {
    border: solid 1px $red;
  }

  .results-list-wrapper {
    position: relative;

    .results-list {
      display: none;
      height: auto;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: $resultsBackground;
      border: solid 1px $smallBlockBorder;
      position: absolute;
      width: 100%;
      z-index: 1;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 319px;
      @include breakpoint(toDesktop) {
        width: 45%;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin-bottom: 0;

        li {
          padding: 24px 40px;
          border-bottom: 1px solid $smallBlockBorder;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          @include breakpoint(hugeScreen) {
            padding: 1.5rem 2rem;
          }

          span {
            display: inline-block;
          }

          span[class=option] {
            font-size: 1.2rem;
            font-weight: 500;
            color: $brand;
            cursor: pointer;
            width: 15px;
            display: block;
            @include breakpoint(hugeScreen) {
              font-size: 1.1rem;
            }
          }

          .remove {
            color: $red;
            font-size: 1.2rem;
            font-weight: 500;
            cursor: pointer;
            width: 15px;
            display: block;
            @include breakpoint(hugeScreen) {
              font-size: 1.1rem;
            }
          }

          span[class=result] {
            font-size: 1.75rem;
            color: $black;
            display: block;
            position: absolute;
            margin-left: 75px;
            margin-top: -18px;
            cursor: pointer;
            @include breakpoint(hugeScreen) {
              margin-left: 5.5rem;
              margin-top: -1.5rem;
              font-size: 1.2rem;
            }
            @include multiple-media(smallLaptop, tabletLandscape) {
              margin-top: -13px;
            }
          }
        }

        li:focus {
          background-color: $lightGray;
          outline: none;
        }

        li:first-child {
          margin-top: -10px;
        }

        li:last-child {
          border-bottom: none;
        }

        .focus {
          background-color: $lightGray;
          outline: none;
        }
      }
    }

    .show-results {
      display: block;
    }
  }

  .selected-list {
    display: grid;
    grid-template-columns: repeat(4, 24%);
    grid-gap: 10px;
    grid-column-gap: 10px;
    overflow: auto;
    padding-bottom: 15px;
    max-height: calc(100vh - 450px);
    @include multiple-media(smallScreen, mediumScreen) {
      grid-template-columns: repeat(2, 48.9%);
      @-moz-document url-prefix() {
        grid-template-columns: repeat(2, 48%);
      }
    }
    @include multiple-media(mobileScreen, tabletScreen) {
      grid-template-columns: repeat(1, 100%);
      max-height: calc(100vh - 500px);
    }
    .tag-selected{
      min-height: 40px;
      border-radius: 3px;
      border: solid 1px $smallBlockBorder;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      align-self: flex-start;
      &__header{
        background-color: $brand;
        letter-spacing: 1px;
        height: 20px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        text-transform: uppercase;
        color: $white;
        font-size: 10px;
        font-weight: bold;
        padding: 5px 5px 0;
      }

      &__body{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
        @include breakpoint(hugeScreen) {
          padding: 0.5rem;
        }

        &-text{
          font-size: 12px;
          font-weight: 500;
          padding: 10px;
          color: $black;
          word-break: break-word;
          @include breakpoint(hugeScreen) {
            font-size: 0.7rem;
          }
        }

        &-buttons{
          font-size: 10px;
          display: flex;
          justify-content: flex-end;
          margin-right: 5px;
          user-select: none;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;

          .star,
          .trash{
            width: 20px;
            height: 20px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-left: 10px;
          }
        }
      }
    }

    .tag-primary {
      border: solid 1px $brand;
    }
  }

  .popular-tags {
    padding-top: 20px;
    position: relative;

    .popular-label {
      display: inline;
      width: 20%;
    }

    ul {
      list-style-type: none;
      display: inline;
      padding: 20px;
    }

    .tag {
      display: inline;
      color: $brand;
      cursor: pointer;
      margin-right: 15px;
      font-weight: 500;
      font-size: 12px;
    }
  }

  .add-global {
    display: inline;
    font-size: 12px;
    font-weight: 500;
    color: $brand;
    position: absolute;
    cursor: pointer;
    right: 0;
    top: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .remove-global {
    color: $red;
  }

  hr {
    margin-top: 15px;
    background-color: $smallBlockBorder;
    opacity: 0.5;
  }
}

.tag-tooltip {
  @include breakpoint(toBigPhone) {
    top: 25px !important;
  }

  .tooltip-inner {
    padding: 5px 15px !important;
    font-size: 12px !important;
    font-weight: 600;
    border-radius: 3px;
    background-color: $black;
    @include breakpoint(hugeScreen) {
      font-size: 1.2rem !important;
    }
    @include breakpoint(toBigPhone) {
      padding: 0 5px !important;
    }
  }
}
.geographic_focus,
.industry {
  .selected-list {
    max-height: unset;
    grid-row-gap: 10px;
    .tag-selected {
      align-self: flex-end;
      &:not(.tag-primary) {
        margin-top: 25px;
      }
    }
  }
}
