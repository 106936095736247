.email-tool-wrapper {
  margin-top: 6.7rem;
  width: 100%;
  &__loader {
    opacity: 0.65;
    background-color: $black;
    position: fixed;
    z-index: 999;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    div.loading-circle {
      position: absolute;
      left: 50%;
      top: 50%;
      z-index: 1000;
      margin: 0 auto;
    }
  }
  .investorMask {
    z-index: 99;
    opacity: 0.65;
    background-color: $black;
  }
  .modal-container {
    z-index: 100;
  }
}

.email-tool {
  &__container {
    font-size: 1.6rem;
    padding: 9.54rem 2% 0 2%;
    color: $black;
    padding-bottom: 0;
    @include breakpoint (tablet) {
      font-size: 2.2rem;
    }
    @include breakpoint (desktop) {
      font-size: 1.4rem;
      padding-top: 4.77rem;
    }
  }
  &__bigBlock {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    background-color: $white;
    @include breakpoint (toDesktop) {
      width: 100%;
      display: block;
      max-height: none;
    }
  }
  &__header {
    position: relative;
    padding: 1.4rem 2.6rem;
    border-bottom: 1px solid $borderColor;
    margin-bottom: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 2.2rem;
      margin-bottom: 0.5rem;
      letter-spacing: 0;
      color: $titleBlack;
      font-weight: 500;
      display: inline-block;
      vertical-align: middle;
      width: auto;
      margin-top: 0.8rem;
      @include breakpoint (tablet) {
        font-size: 2rem;
        margin-bottom: 0rem;
      }
      @include breakpoint (desktop) {
        font-size: 1.6rem;
        margin-bottom: 0rem;
      }
    }
  }
  &__body {
    font-size: 2rem;
    max-height: calc(100vh - 220px);
    overflow-y: scroll;
    padding: 1.6rem 2rem;
    display: flex;
    flex-wrap: wrap;
    @include breakpoint (tablet) {
      font-size: 1.8rem;
      margin-bottom: 0rem;
    }
    @include breakpoint (desktop) {
      font-size: 1.6rem;
      margin-bottom: 0rem;
    }
    section {
      width: 100%;
      margin-bottom: 1.4rem;
      > h3 {
        text-align: left;
        font-size: 2.2rem;
        font-weight: normal;
        margin-bottom: 1.2rem;
        @include breakpoint (tablet) {
          font-size: 2rem;
        }
        @include breakpoint (desktop) {
          font-size: 1.8rem;
        }
      }
      > button {
        margin-top: 1.2rem;
      }
      @include breakpoint(desktop) {
        width: 46%;
        margin: 0 2%;
      }
    }
    &__field {
      margin-bottom: 1.8rem;
    }
  }
}