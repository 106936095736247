.recertification {
  .modal-layer-container {
    .modal-layer-header {
    border: 0;
    }
  }
}
.background-white {
  background-color: $white;
}
.re-certification-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: $white;
  padding: 0;
  border-radius: 5px;
  max-width: 600px;
  max-height: 420px;
  overflow: hidden;
  & .do-modal-header {
    .re-certification-header {
      font-size: 18px;
      font-weight: 500;
      padding: 30px 30px 20px 30px;
    }
  }
}
.do-modal-body {
  .re-certification-content {
    overflow-y: auto;
    height: 240px;
    padding: 2px 30px 20px 30px;
    border-top: 1px solid $color-grey-3;
    > div {
      p {
        font-size: 14px;
        line-height: 1.57;
        margin: 20px 0 0 0;
      }
      ul {
        padding-left: 19px;

        .investor-onboarding-step__list.list_custom li {
          padding-bottom: 0;
          line-height: 1.57;
        }
        li {
          font-size: 20px;
          font-weight: normal;
          line-height: 1.5;
          list-style: initial;
          padding-bottom: 0;
          padding-left: 12px;
        }
      }
    }
    &__highlight {
      color: #434a54;
    }
  }
}
.re-certification-modal-footer{
  padding: 20px 30px;
  border-top: 1px solid $color-grey-3;
  > div{
    display: flex;
    justify-content: space-between;
    button{
      text-transform: uppercase;
      border: 0;
      font-size: 1.2rem;
      padding: 0;
      outline: none;
      cursor: pointer;
      font-weight: 500;
      background: $white;
      @include transition(.3s all ease-in-out);
      &.red{
        color: $red;
        &:hover{
          color: $darkerRed;
        }
      }
      &.brand{
        color: $brand;
        &:hover{
          color: $brandHover;
        }
      }
    }
  }
}