.do-match{
  @include multiple-media(mobileScreen, tabletScreen) {
    display: none;
  }
  @media screen and (max-width: 899px){
    display: flex;
  }
  .layer-body {
    display: flex;
    flex-direction: column;

    &__middle-filters {
      @include multiple-media(mobileScreen, tabletScreen) {
        flex-basis: 100%;
        width: 100%;
        padding-bottom: 2rem;
        min-height: 0;
      }
      max-height: calc(100vh - 22rem);
      min-height: calc(100vh - 22rem);
      overflow-x: hidden;
      overflow-y: auto;
      .button-area{
        width: 100%;
        padding-top: 2.5rem;
        @include multiple-media(mobileScreen, tabletScreen) {
          display: none;
        }
        button{
          height: 43px;
          width: 100%;
          line-height: 1.3rem;
          text-transform: uppercase;
          cursor: pointer;
          font-size: 1.2rem;
          font-weight: 600;
          font-family: $regular;
          background-color: $brand;
          color: $white;
          left: inherit;
          right: inherit;
          position: relative;
          border: 0;
          border-radius: 2px;
          &:hover{
            background-color: $darkerBrand;
          }
        }
      }
    }
    &__middle-filters-holder {
      @include multiple-media(mobileScreen, tabletScreen) {
        display: none;
      }
    }
    &__middle-holder {
      height: calc(100vh - 24.5rem);
      @include multiple-media(mobileScreen, tabletScreen) {
        height: calc(100vh - 33rem);
        overflow-y: hidden;
      }
      &-top {
        a.do-button {
          @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
            display: none;
          }
        }
        button.do-button__brand {
          @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
            margin-left: auto;
          }
        }
        .ladda-label {
          @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
            font-size: 1.2rem;
          }
        }
      }
      &-bottom {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-top: 1%;
          color: $color-blue-main;
          font-size: 13px;
          border-top: 1px solid $smallBlockBorder;
          padding: 18px 28px;
      }
    }
  }
  .do-originator__bigBlock-right {
    padding: 2rem;
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 30rem);

    @include breakpoint (smallLaptop) {
      max-height: calc(100vh - 33rem);
    }

    .draggable-wrapper-max-height {
      min-height: 100%;
      > span {
        min-height: calc(93vh - 30rem);
        display: block;
      }
      @include breakpoint (smallLaptop) {
        min-height: calc(93vh - 32rem);
        > span {
          min-height: calc(93vh - 32rem);
          display: block;
        }
      }
    }

  }
  .todo-widget {
    &__block-left {
      width: 22%;
      flex-basis: 22%;
      min-width: 18%;
      .media__image {
        padding: 2rem;
        color: $white;
        font-size: 1.8rem;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 100%;
        border: 0;
        &.whiteDeal {
          cursor: default;
        }
      }
      &:after{
        display: none;
      }
    }
    &__block-right{
      width: 78%;
      flex-basis: 78%;
      padding-left: 2rem;
      .media__image {
        width: 4rem;
        height: 4rem !important;
        a{
          margin: 0;
        }
      }
    }
  }
  .media__buttons{
    position: relative;
    padding-right: 2rem;

    @media screen and (min-width: 1025px) and (max-width: 1799px) {
      padding-right: 0.4rem !important;
    }
    @include breakpoint(toDesktop) {
      flex-basis: 10%;
    }
    @include breakpoint(desktop) {
      display: flex;
      align-items: center;
      height: 0;
    }

    &-item {
      &-custom-email {
        color: #0d5aa7;
        width: 3rem;
        font-size: 1.5rem;
        position: relative;
        transition: none;

        &_to-all {
          i {
            font-size: 1.5rem;
          }
          margin: -2rem;
          margin-right: 4px;
          width: 3rem;
          color: $brand;
          &:hover{
            color: #1f7be8;
            background-color: #dcdee2;
            padding: 0.7rem;
            border-radius: 50%;
          }
          @-moz-document url-prefix() {
            margin-right: 14px;
            @media (min-width: 900px) and (max-width:1200px) {
              margin-right: 12px;
            }
            @media (min-width: 1200px) and (max-width:1799px) {
              margin-right: 14px;
            }
            @media (min-width:1800px) {
              margin-right: 12px;
            }
          }
        }
        &:hover {
          color: $brand;
          background-color: $lightGray;
          padding: 0.3rem;
          border-radius: 50%;
        }
      }
    }
  }
  .do-profile {
    &__item-holder {
      padding: 2rem 2rem 1rem;
      position: relative;
      .empty-content {
        color: #666;
        font-size: 10px;
        text-align: center;
        padding: 1rem 0;
      }
      &:before{
        position: absolute;
        top: 2rem;
        left: 0rem;
        right: 0rem;
        height: 1px;
        background-color: $borderColor;
        content: '';
        display: block;
      }
    }
    &__item-feedback {
      padding: 0rem 2rem 1rem;
      .empty-content {
        color: $color-brown-1;
        font-size: 10px;
        text-align: center;
        padding: 0rem 0;
        margin-top: 1rem;
      }
      &:before {
        position: absolute;
        top: 0rem;
        left: 0rem;
        right: 0rem;
        height: 1px;
        background-color: $borderColor;
        content: '';
        display: block;
      }
    }
    &__item {
      &:first-child {
        width: auto;
        flex-width: auto;
      }
      margin-top: 2rem;
      @include multiple-media(mobileScreen, tabletScreen) {
        display: none;
      }
      .media__image-big {
        width: 150px !important;
        height: 150px !important;
        @media screen and (min-width: 1800px) {
          width: 200px !important;
          height: 200px !important;
        }
      }
    }
  }
  .feedback-sidebar{
    .do-profile__item-info{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.5rem;
      &:first-child{
        margin-top: 1rem;
      }
    }
    h4{
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 500;
      color: $black;
      padding: 1.5rem 2rem;
      position: relative;
      &:after{
        position: absolute;
        bottom: 0rem;
        left: 0rem;
        right: 0rem;
        height: 1px;
        background-color: $borderColor;
        content: '';
        display: block;
      }
    }
  }
  .preferences-sidebar{
    h4 {
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: 500;
      color: $black;
      padding: 1.5rem 2rem;
      position: relative;
      &:after{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: $borderColor;
        content: '';
        display: block;
      }
    }
    .do-profile__item-holder-preferences{
      padding: 0 2rem 2rem;
      position: relative;
    }
  }
  .highcharts {
    max-width: 100%;
    height: 15rem;
    margin: 0 auto;
    text {
      font-size: 0.9rem !important;
      font-weight: bold !important;
      @media screen and (min-width: 1800px) {
        font-size: 1.1rem !important;
      }
    }
  }
  .do-hubx-deals-block{
    padding: 0;
    min-height: 7.995rem;
    height: 100%;
    background-color: $white;
    margin-bottom: 1.3rem;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 5px;

    .more-items__hub-block {

      @include multiple-media(mobileScreen, tabletScreen, tablet) {
        right: 1rem;
        top: 7rem;
      }

      @include breakpoint(largeScreen) {
        right:  1rem;
      }
    }

    .todo-widget__block-right-info {
      width: 16rem !important;
      @media screen and (min-width: 1800px) {
        width: 22rem !important;
      }
    }
  }
  &.do-investor {
    .available-deals {
      &__content {
        height: calc(100vh - 33rem);
        overflow-y: hidden;
      }
      .do-originator__bigBlock-right {
        height: 100vh;
        max-height: calc(100vh - 37rem) !important;
      }
      .draggable-wrapper-max-height {
        @media not all and (min-resolution:.001dpcm) {
          max-height: 100%;
          min-height: 100%;
        }
      }
      .draggable {
        max-height: calc(93vh - 35rem);
        height: calc(100vh - 35rem);
        @-moz-document url-prefix() {
          height: calc(100vh - 35rem);
        }
        @media not all and (min-resolution:.001dpcm) {
          height: calc(100vh - 35rem);
         .edit {
           &__investor-preferences-parent {
             &:last-child {
               margin-bottom: 0 !important;
             }
           }
         }
        }
      }
      .layer-body {
        &__middle-holder-content {
          @include breakpoint(smallLaptop) {
            height: calc(100vh - 34rem);
          }
        }
        &__middle-holder-top {
          z-index: 0;
        }
      }
    }

    .matched-deals {
      &__content {
        height: calc(100vh - 26.5rem);
        overflow-y: hidden;
        .draggable-wrapper-max-height {
          padding-bottom: 20px;
        }
      }
    }
  }

  .content-bottom {
    .do-button {
      .do-button__brand {
        @include multiple-media(mobileScreen, tabletScreen) {
          display: none;
        }
      }
      .fa-arrow-right{
        font-size: 1.5rem;
        margin-left: 0.5rem;
      }
      .fa-arrow-left {
        font-size: 1.5rem;
        margin-right: 0.5rem;
      }
    }
    &-deal-updates {
      box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.05);
      z-index: 0;
      .fa-arrow-left {
        font-size: 1.5rem;
        margin-right: 0.5rem;
      }
    }
  }
}
.layer-bottom {
  .do-profile {
    &__item-info-small {
      margin: 0;
    }
    &__item-info-big {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
}
.feedback__column {
  .todo-widget {
    &__block-right {
      padding-bottom: 1rem;
      flex-wrap: wrap;
    }
    &__block-right-info {
      padding-top: 1rem;
      width: 15rem;
    }
  }
  .do-investors__body-investors-item-bottom {
    width: 100% !important;
  }
  &--investor {
    width: calc(100% - 50px);
  }
}
.hideCreation {
  z-index: 1;
}
.matching-layer {
  padding: 2rem;
  background: $white;
  margin-top: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: calc(100vh - 15rem);
  max-height: calc(100vh - 15rem);
  h2{
    font-size: 1.6rem;
    margin: 0;
    letter-spacing: 0;
    text-transform: none;
    font-weight: 600;
  }
  .media__image {
    width: fit-content;
    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $borderColor;
    padding-bottom: 1.5rem;
    .media {
      &__image {
        height: 4rem!important;
        padding: 0 !important;
        border-radius: 0 !important;
        border: 1px solid $borderColor !important;
        margin: 0 auto 0.5rem;
      }
      &__info-desc {
        font-size: 0.9rem !important;
        font-weight: 400 !important;
        color: $smallColor !important;
        padding-right: 0 !important;
      }
      &__info-title {
        span {
          font-size: 1.3rem !important;
          font-weight: 500 !important;
          color: #434a54 !important;
        }
      }
    }
    .todo-widget__block-right {
      width: auto !important;
      flex-basis: auto !important;
      padding-left: 0 !important;
      display: block;
      text-align: center;
      a {
        margin: 0 !important;
        width: 4rem;
        height: 4rem;
      }
    }
  }
  &__body{
    &-block{
      color: $black;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $borderColor;
      padding: 1.5rem 0;
      > div{
        text-align: center;
        width: 33.33%;
        font-size: 1.3rem;
        font-weight: 600;
        &.green{
          color: $positive;
        }
        &.red{
          color: $red;
        }
        &.yellow{
          color: $yellow;
        }
      }
    }
  }
  &__holder{
    margin-top: 5rem;
  }
  &__score{
    font-weight: 500;
    text-align: center;
    span{
      font-size: 2rem;
      display: block;
    }
    i{
      font-size: 1.8rem;
    }
    &.green{
      span,i{
        color: $positive;
      }
    }
    &.red{
      span,i{
        color: $red;
      }
    }
    &.yellow{
      color: $yellow;
    }
  }
  .layer-right-info {
    position: relative;
    width: 17rem;
    padding-left: 0;
  }
}
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.deal-body-right-wrapper,
.company-body-right-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: hidden;
  user-select: none;
  @media screen and (max-width: 600px) {
    overflow-x: scroll;
    padding-top: 10px;
  }
  @include multiple-media(smallScreen, mediumScreen){
    width: calc(100% - 220px);
  }
  @include breakpoint(largeScreen) {
    width: calc(100% - 270px);
  }
  .investors-and-contacts,
  .matched-investors {
    width: 50%;
    flex-basis: unset;
    .company-match-column {
      height: calc(100vh - 29rem);
      overflow: hidden;
      .do-originator__bigBlock-right {
        max-height: calc(100vh - 33rem);
        height: inherit;
      }
    }
    .layer-body__middle-holder-content-with-footer {
      .do-originator__bigBlock-right {
        height: inherit;
        max-height: calc(100vh - 37rem) !important;
        @-moz-document url-prefix() {
          .do-hubx-deals-block {
            &:last-of-type {
              margin-bottom: 10px;
            }
          }
        }
      }
    }

    .media__info-desc.preferences-score-element {
        float: left;
        color: black;
        font-size: 1.2rem;
    }

    @include multiple-media(mobileScreen, tabletScreen) {
      min-width: 350px;
      .layer-body__middle-holder-content {
        min-width: 350px;
        height: calc(100vh - 44rem);
        .do-originator__bigBlock-right {
          max-height: calc(100vh - 47rem) !important;
        }
      }
    }

    .deals-holder-header {
        display: flex;
        align-items: center;
        position: relative;

        @include breakpoint(toDesktop) {
          display: contents;
          text-align: left;
          align-items: center;
        }

      .more-items {
        margin-left: 1rem;

        @include  breakpoint(toDesktop) {
          margin-left: 0;
        }
      }

      .more-items__hub-block {
        right: -1rem;
        top: 4rem;
        width: 21rem;

        @include  breakpoint(toDesktop) {
          right: 1rem;
          top: 6rem;
        }
      }

      .disabled {
        pointer-events: none;
        opacity: 0.4;
      }
    }

  }
  .matched-investors {
    margin-left: 20px;
  }
}



.greenDeal {
  user-select: none;
  background-color: $positive;
}

.yellowDeal {
  user-select: none;
  background-color: $yellow;
}

.redDeal {
  user-select: none;
  background-color: $red;
}

.grayDeal {
  user-select: none;
  background-color: $investmentTitle;
}

.whiteDeal {
  user-select: none;
  background-color: $white;
  color: $investmentTitle !important;
  border-right: 1px solid $investmentTitle !important;
  text-align: center;
}
