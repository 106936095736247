.investmentFirm{
	.peoples__header-search{
		input{
			width: 0rem;
			&.activeInput{
				width: 19rem !important;
				@include breakpoint (toDesktop){
					width: 29rem !important;
					font-size: 16px;
				}
			}
		}
	}
	.peoples__header{
		.edit{
			right: 2.6rem;
		}
	}
	.peoples__footer{
		margin-top: 0;
		padding-bottom: 1.6rem;
		li{
			margin-right: 3rem;

		}
	}
	.peoples__biggerBlock{
		.peoples__body{
			display: flex;
			@include breakpoint (toDesktop){
				display: block;
				max-height: none !important;
			}
		}
		.investmentFirm__form{
			overflow-x: hidden;
		}
		.investmentFirm__form,
		.investmentFirm__results{
			position: relative;
			@include breakpoint (toDesktop){
				width: 100%;
				display: block;
			}
			.peoples__footer{
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: $white;
				z-index: 1;
				ul{
					margin-top: 3.5rem;
	    			margin-bottom: 0.5rem;
    			}
			}
		}
	}
	.peoples__body-name,
	.peoples__biggerBlock{
		position: relative;
		.line-1,
		.line-2,
		.line-3,
		.line-4
		{
			position: absolute;
			background-color: $brand;
			content: '';
			display: block;

		}
		.line-1{
			left: 0;
			top: 50%;
			bottom: 50%;
			width: 2px;
		}
		.line-2{
			top: 0;
			left: 0;
			right: 100%;
			height: 2px;
		}
		.line-3{
			bottom: 0;
			left: 0;
			right: 100%;
			height: 2px;
		}
		.line-4{
			right: 0;
			top: 50%;
			bottom: 50%;
			width: 2px;
		}
	}
	.firms{
		.peoples__body-name{
			justify-content: unset;
		}
	}
	.activeTab{
		.line-1,
		.line-4{
				top: 0;
				bottom: 0;
			}
		.line-2,
		.line-3{
			right: 0;
		}
		.line-4{
		}
		.line-5{
		}
	}
	.activePersonBlock{
		.line-1,
		.line-4{
				top: 0;
				bottom: 0;
				z-index: 1;
			}
		.line-2,
		.line-3{
			right: 0;
		}
		.line-4{
		}
		&.peoples__biggerBlock{
			.peoples__header{
				display: flex;
				justify-content: space-between;
			}
		}
		> .peoples__body{
			overflow: hidden;
		}
	}
	&__form{
		overflow: hidden;
		.form-submit{
			cursor: not-allowed;
        	pointer-events: none;
		}
	}
	&__form,
	&__results{
		width: 39%;
		display: inline-block;
		vertical-align: top;
		&-inner{
			padding: 2rem 3rem;
			margin-bottom: 10rem;
			overflow-y: scroll;
			max-height: calc( 100vh - 50rem);
			overflow-x: hidden;
			textarea{
				width: 85%;
				display: block;
				outline: none;
				-webkit-appearance: none;
				padding: 1.5rem 3rem;
				background-color: #f5f7fa;
				margin-bottom: 2rem;
				border: 1px solid #e6e9ed;
				border-radius: 10px;
				resize: none;
				height: 5rem;
			}
			@media screen and (min-height: 400px) and (max-height: 650px){
				max-height: 45vh;
			}
			@include breakpoint (toDesktop){
				max-height: none;
			}
		}
		label{
			color: $investmentTitle;
			font-size: 1.2rem;
			font-weight: 400;
			text-align: left;
			display: block;
			margin-bottom: 1rem;
			@include breakpoint (toDesktop){
				font-size: 2.2rem;
			}
		}
	}
	&__results{
		width: 58%;
		display: inline-block;
		vertical-align: top;
		margin-left: 2rem;
		@include breakpoint (toDesktop){
			margin-left: 0;
			margin-top: 4rem;
		}
		.investmentFirm__form-inner{
			margin-bottom: 2rem;
			padding: 0rem;
		}
		.investor__block{
			padding: 0 3rem;
		}
		.peoples__infoBlock {
			width: 50%;
			position: relative;
		}
	}
	&__input{
		display: block;
		padding: 1.5rem 3rem;
		background-color: $darkerWhite;
		margin-bottom: 2rem;
		border: 1px solid $investmentBorder;
		border-radius: 10px;
	}
	&__block{
		background-color: $black;
		padding: 3rem;
		label{
			color: $white;
			margin-bottom: 0.5rem;
		}
		span{
			display: block;
			font-weight: 500;
		}
		small{
			color: $smallColor;
		    display: block;
		    margin-top: 0.3rem;
		    font-weight: 500;
		}
		&-inner{
			background-color: $white;
			padding: 2rem;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.edit{
				right: 0rem;
				top: 40%;
			}
		}
	}
	.change__info{
		overflow-x: hidden;
		input{
			@extend .investmentFirm__input;
			font-size: 1.5rem;
			width: 85%;
			display: block;
			outline: none;
			-webkit-appearance:none;
			@include breakpoint (toDesktop){
				width: 90%;
				font-size: 16px;
			}
		}
		select{
			@extend .investmentFirm__input;
			background-color: transparent;
			width: 100%;
			font-size: 1.5rem;
			border: 0;
			margin: 1.5rem 0;
			outline: none;
			padding: 0;
			@include breakpoint (toDesktop){
				font-size: 16px;
			}
		}
		.rounded{
			border-radius: 10px;
			border: 1px solid $investmentBorder;
			background-color: $darkerWhite;
			padding: 0 2rem;
			margin-bottom: 1.5rem;
		}
	}
	.modal{
		h2{
			margin: 0;
			margin-bottom: 3rem;
			margin-top: -3rem;
			font-size: 2rem;
			font-weight: bold;
			letter-spacing: 0;
			text-transform: none;
			color: $black;
			@include breakpoint (toDesktop){
				margin-top: -7rem;
				font-size: 3rem;
			}
		}
		button{
			width: auto;
			font-size: 1.4rem !important;
			padding: 2em 6em !important;
			font-weight: 600;
			-webkit-font-smoothing: auto;
			-webkit-tap-highlight-color: auto;
			@include breakpoint (toDesktop){
				padding: 2em 4em !important;
				font-size: 2rem !important;
			}
			span{
				padding: 0;
			}
		}
		input{
			width: auto !important;
		}
		.close {
			margin-top: 0rem !important;
			font-size: 2rem !important;
			@include breakpoint (toDesktop){
				font-size: 8.4rem !important;
			}
		}
		.input-error{
			right: 0 !important;
			font-size: 1.4rem !important;
		}
		.input-gr.block {
			display: block;
			font-size: 1.6rem;
			line-height: 3rem;
			@include breakpoint (toDesktop){
				font-size: 2.2rem;
				line-height: 3.5rem;
			}
		}
		.title{
			font-size: 1.6rem;
			@include breakpoint (toDesktop){
				font-size: 2.6rem;
			}
		}
		.modal-step{
			font-size: 1.8rem;
			@include breakpoint (toDesktop){
				font-size: 2.6rem;
			}
		}
	}
	.tab-steps > div{
		@include breakpoint (toDesktop){
			margin: 0 5rem;
			font-size: 2.2rem;
		}
	}
}
.form__submit{
	position: relative;
	opacity: 0.3;
		border: 0;
		background-color: transparent;
		color: $brand;
		font-size: 1.3rem;
		font-weight: 500;
		outline: none;
		@include transition (.3s all ease-in-out);
		@include breakpoint (toDesktop){
			font-size: 2.3rem;
		}
		&.changed{
			opacity: 1;
			cursor: pointer;
			pointer-events: inherit;
			margin-left: 2.5rem;
		}
}
.investorAddModalPopup{
	    max-width: 62.5rem;
    margin: 0 auto;
	.peoples__smallBlock{
		width: 100%;
		max-height: 85vh;
		overflow: hidden;
		@include breakpoint (toDesktop){
			max-height: none;
		}
	}
	.peoples__header{
		border-bottom: 0;
		margin-bottom: 0;
	}
	.peoples__body{
		padding: 1.6rem 2.7rem;
		section{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		&.add-investor{
			max-height: 70vh;
			overflow-y: scroll;
			height: auto;
			@include breakpoint (toDesktop){
				max-height: none;
			}
		}
	}
	.peoples__header-search{
		display: block;
    	float: none;
		position: relative;
		width: 100%;
    	input{
		    width: 94%;
		    background: $darkerWhite;
		    padding: 1rem 1.5rem;
		    border: 1px solid $investmentBorder;
			border-radius: 5px;
			height: 2.5rem;
    	}
    	i{
    		position: absolute;
    		top: 1.2rem;
    		right: 1rem;
    	}
	}
	.peoples__body-name{
		span{
			font-size: 1.4rem;
		}
		small{
			font-size: 1.2rem;
		}
	}
	.edit{
		right: 1.2rem;
	    top: 37%;
	    font-size: 1.4rem;
	}
}
.vue-slider-piecewise{
	top: -2px !important;
}
.text{
	font-size: 1.6rem;
	@include breakpoint (toDesktop){
		font-size: 2.6rem;
	}
}
.investmentFirm__form-inner.verification {
	max-height: 30rem;
	overflow-x: hidden;
	overflow-y: auto;
	@include breakpoint (toDesktop){
		max-height: none;
	}
}

.investmentFirm__block {
	max-height: 30rem;
	@include breakpoint (toDesktop){
		max-height: none;
	}
}

.investor__block-active {
	max-height: 38rem;
	@include breakpoint (toDesktop){
		max-height: none;
	}
}
