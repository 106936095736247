.btn {
  padding: 12px 20px;
  border-radius: 3px;
  border: 0;
  font-family: $regular;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-align: center;
  cursor: pointer;
  color: $color-white;
  transition: 0.3s all ease-in-out;
  text-transform: uppercase;
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
  &--default {
    background-color: $color-white;
    color: $color-grey-5;
    border: 1px solid $borderWhite;
    &:hover {
      color: $color-grey-6;
    }
  }
  &--positive {
    background-color: $color-blue-main;
    &:hover {
      background-color: $color-blue-dark;
      color: $white;
    }
  }
  &--positive-light {
    color: $color-blue-main;
    background-color: $color-white;
    border: 1px solid $borderWhite;
    &:hover {
      color: $color-blue-dark;
    }
  }
  &--success {
    background-color: $color-green-main;
    &:hover {
      background-color: $color-green-dark;
    }
  }
  &--success-light {
    background-color: $color-white;
    color: $color-green-main;
    border: 1px solid $borderWhite;
    &:hover {
      color: $color-green-dark;
    }
  }
  &--danger {
    background-color: $color-red-main;
    &:hover {
      background-color: $color-red-dark;
    }
  }
  &--danger-light {
    background-color: $color-white;
    color: $color-red-main;
    border: 1px solid $borderWhite;
    &:hover {
      color: $color-red-dark;
    }
  }
  &--warning {
    background-color: $color-yellow-main;
    &:hover {
      background-color: $color-yellow-dark;
    }
  }
  &--warning-light {
    background-color: $color-white;
    color: $color-yellow-main;
    border: 1px solid $borderWhite;
    &:hover {
      color: $color-yellow-dark;
    }
  }
  &--sm-padding {
    padding: 12px 40px;
  }
  &--md-padding {
    padding: 12px 50px;
  }
  &--lg-padding {
    padding: 12px 60px;
  }
}