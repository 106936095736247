.billing-tab {
	.do-billing {
		&__box {
			border-radius: .3rem;
			overflow: hidden;
			@include multiple-media(mobileScreen, tabletScreen) {
				width: calc(100% - .3rem);
				flex: calc(100% - 1.5rem);
			}
			> .do-billing {
				&__holder {
					box-sizing: content-box;
					width: calc(100% - 3.2rem);
					min-height: auto;
					@include breakpoint(tabletScreen) {
						max-height: initial;
						height: calc(100vh - 40rem);
						@include hide-scrollbar;
					}
					@include multiple-media(smallScreen, mediumScreen, largeScreen) {
						max-height: initial;
						height: calc(100vh - 42rem);
					}
					@include breakpoint(mobileScreen) {
						flex-flow: column wrap;
						max-height: calc(100vh - 44rem);
						@include hide-scrollbar;
						@supports (-webkit-overflow-scrolling: touch) {
							max-height: calc(100vh - 47rem);
						}
					}
					@media (min-width: 1024px) {
						margin-right: 0;
						padding-right: 2rem;
						width: calc(100% - 3.5rem);
					}
					@media (min-width: 1279px) {
						padding-right: 1.8rem;
					}
					.todo-widget__block-right {
						border-radius: .3rem;
						@include breakpoint(mobileScreen) {
							flex-flow: row wrap;
							align-items: flex-start;
						}
						&-image {
							@include breakpoint(mobileScreen) {
								margin-bottom: 2rem;
							}
						}
						&-info {
							@include breakpoint(mobileScreen) {
								width: calc(100% - 7rem);
								margin-top: 1.5rem;
							}
						}
						.action__buttons {
							@include breakpoint(mobileScreen) {
								margin-left: 0;
								width: 100%;
							}
							.fa.border {
								@include breakpoint(mobileScreen) {
									margin-left: 0;
									border-radius: .3rem;
								}
								&:not(:only-child) {
									@include breakpoint(mobileScreen) {
										margin-left: 0;
										flex: initial;
									}
								}
							}
							span.border {
                                min-width: 74px;
								cursor: default;
								&.reactivate,
								&.todo-widget__filters-older {
									cursor: pointer;
								}
							}
							.todo-widget__filters-older,
							.border {
								@include breakpoint(mobileScreen) {
									margin: initial;
									flex: initial;
									flex: 100%;
									border-radius: .3rem;
								}
								@include multiple-media(mobileScreen, tabletScreen, smallScreen, mediumScreen, largeScreen) {
									font-weight: 500 !important;
								}
								.fa {
									@include breakpoint(mobileScreen) {
										margin-left: 1rem;
									}
								}
								&:not(:only-child) {
									@include breakpoint(mobileScreen) {
										margin-left: 1rem;
									}
								}
								.todo-widget__filters-older .fa {
									@include breakpoint(mobileScreen) {
										display: block;
										position: absolute;
										right: 0;
										top: 0;
									}
								}
							}

						}
					}
				}
				&__button {
					@include multiple-media(mobileScreen, tabletScreen) {
						border-radius: 0 0 .3rem .3rem;
					}
				}
			}
		}
	}
	.layer-body__top {
		&-left {
			overflow: hidden;
			max-height: calc(100vh - 11rem);
			@include multiple-media(mobileScreen, tabletScreen) {
				max-height: calc(100vh - 15rem);
			}
			.common__modalBox {
				overflow-x: hidden;
				overflow-y: auto;
				max-height: calc(100vh - 23rem);
				@include multiple-media(mobileScreen, tabletScreen) {
					max-height: calc(100vh - 23rem);
					@include hide-scrollbar;
				}
				@media (min-width: 1024px) {
					margin-right: 0;
					padding-right: 0;
					max-height: calc(100vh - 20rem);
				}
				@supports (-webkit-overflow-scrolling: touch) {
					max-height: calc(100vh - 25rem);
				}
				.tabs-content {
					@include multiple-media(mobileScreen, tabletScreen) {
						font-size: 1rem;
					}
					.form__body.showForm {
						> label {
							@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
								font-size: 1rem;
							}
						}
						.common__modalInput {
							> label {
								@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
									font-size: 1rem;
								}
							}
							.input {
								border-radius: .5rem;
							}
						}
					}
				}
			}
		}
	}
	.seat-remove {
        &__modalTitle {
            font-size: 1.4rem;
            text-align: left;
            font-weight: 500;
            border-bottom: 1px solid $layerBorder;
            margin-top: 2rem;
            margin-bottom: 3rem;
            padding-bottom: 1rem;
        }
        .form__body {
            label {
                font-size: 1.4rem;
                color: $black;
                font-weight: 500;
            }
        }
		.hubx-inputs {
			margin-bottom: 20px;
		}
	}
}
