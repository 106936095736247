#app{
  height: 100%;
}
.do-originator  {
  min-height: 100%;
  .gdpr-content{
    .layer-body__middle-holder{
      min-height: inherit;
    }
    .layer-options {
      flex-basis: 20%;
    }
  }
  .do-experience {
    background: none;
    z-index: 100;
  }
  .brand-company{
    background-color: transparent;
    border: 1px solid $borderColor !important;
  }

  .do-preferences .do-appendix__third-block textarea{
    font-size: 1.2rem !important;
    height: 10rem !important;
    padding: 2rem !important;
    margin-top: 0 !important;
  }
}
.do-experience {
  background: url('/images/investor-background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: $regular;
  h4{
    text-transform: uppercase;
    position: relative;
    padding-bottom: 20px;
    padding-left: 20px;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    color: $black;
    &:after {
      background-color: $borderColor;
      position: absolute;
      left: -25px;
      bottom: 0;
      right: -25px;
      content: '';
      display: block;
      height: 1px;
    }
  }
  h3{
    margin: 30px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    @include breakpoint (devices){
      margin-bottom: 0;
    }
    @include breakpoint(toBigPhone) {
      margin-bottom: 20px;
    }
  }
  textarea{
    margin-top: 10px !important;
    height: 100px !important;
    font-size: 12px !important;
    padding: 20px !important;
  }
  span {
    &.red {
      font-size: 12px;
      margin-left: 12%;
    }
  }
  a {
    text-decoration: none;
  }
  &__link {
    position: absolute;
    top: 50%;
    right: 30px;
    color: $brand;
    text-align: center;
    display: ruby !important;
    cursor: pointer;
    @include multiple-media(mobileScreen, tabletScreen) {
      top: unset !important;
      bottom: 10px;
      right: 60px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1366px) and (max-height: 700px) {
      position: fixed;
      right: 60px;
      top: calc(100% - 280px);
    }
    span{
      display: block;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 5px;
      z-index: 1;
      @include breakpoint(smallLaptop) {
        font-size: 10px;
      }
      @include breakpoint(toBigPhone){
        margin-bottom: -2px;
      }
    }
    i{
      font-size: 20px;
    }
    &-left{
      left: 30px;
      right: auto;
      color: $smallColor !important;
      @include multiple-media(mobileScreen, tabletScreen) {
        left: 60px;
      }
      @media screen and (min-width: 1200px) and (max-width: 1366px) and (max-height: 700px) {
        position: fixed;
        left: 490px;
        top: calc(100% - 280px);
      }
    }
  }
  &__parent {
    display: flex;
    flex-direction: row;
    &-holder{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
    }
    .layer-body__middle-filters{
      border-radius: 5px;
      position: relative;
      background: $white;
      padding-top: 20px;
      @include transition(.3s all ease-in-out);
      @include breakpoint (devices){
        border-radius: 0;
        width: auto;
        flex-basis: auto;
        min-height: auto;
        position: fixed;
        z-index: 4;
        left: -53%;
        bottom: 0;
        top: unset;
        right: 100%;
        overflow: hidden;
      }
      @include breakpoint (toBigPhone){
        top: 66px;
      }
      &.swipeRight{
        z-index: 7;
        @include transition(.3s all ease-in-out);
        @include breakpoint(devices){
          right: 50%;
          left: 0;
          border: 1px solid $smallBlockBorder;
          bottom: 0;
          top: -100px;
          height: auto;
          width: auto;
        }
      }
    }
  }
  &__first-step {
    .layer-body__top{
      display: block;
      margin-top: 2rem;
      font-weight: 400;
      font-size: 1.3rem;
      p{
        font-size: 1.2rem;
        margin: 2rem 0 0.5rem;
        @include breakpoint(devices){
          margin: 20px 0 5px;
          font-size: 12px;
        }
      }
    }
    .box{
      background-color: $white;
      padding: 2rem;
      h2{
        margin: 0;
        letter-spacing: 0;
        margin-bottom: 3rem;
        font-size: 1.6rem;
        font-weight: 600;
        text-transform: none;
        @include breakpoint(devices){
          font-size: 18px;
        }
      }
    }
    &-block{
      display: flex;
      width: 50%;
      justify-content: space-between;
      font-size: 1.1rem;
      margin-bottom: 20px;
      label{
        color: $smallColor;
        width: 45%;
        display: block;
        @include breakpoint(devices){
          font-size: 12px;
        }
      }
      span{
        color: $black;
        font-weight: 600;
        display: block;
        width: 55%;
        @include breakpoint(devices){
          font-size: 12px;
        }
      }
      &-footer{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &-confirm{
        background-color: $brand;
        color: $white;
        text-transform: uppercase;
        padding: 1.5rem 3rem;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 500;
        @include transition(.3s all ease-in-out);
        @include breakpoint(devices){
          padding: 20px 40px;
          font-size: 12px;
        }
        &:hover{
          background-color: $darkerBrand;
        }
      }
      &-proceed{
        color: $brand;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 500;
        @include transition(.3s all ease-in-out);
        @include breakpoint(devices){
          font-size: 12px;
        }
        &:hover{
          color: $darkerBrand;
        }
      }
    }
    &-checkbox{
      margin-bottom: 2rem;
      .checkbox-container{
        font-weight: 500;
        font-size: 1.1rem;
        cursor: pointer;
        @include breakpoint(devices){
          font-size: 11px;
        }
        input{
          margin-right: 1rem;
          @include breakpoint(devices){
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
          }
        }
        a{
          display: inline-block;
          color: $brand;
          cursor: pointer;
          @include transition(.3s all ease-in-out);
          &:hover{
            color: $darkerBrand;
          }
        }
        &.first-step {
          @include breakpoint(devices) {
            display: block !important;
            margin-bottom: 25px !important;
          }
        }
      }
    }
  }
  &__first-step-block {
    &-footer {
      a {
        @include breakpoint(devices) {
          margin-left: 30px;
          padding: 15px;
        }
      }
    }
  }
  &__link-left {
    span {
      @include breakpoint(smallLaptop) {
        margin-left: -10px !important;
      }
    }
  }
  &__text{
    font-size: 12px;
    display: block;
    text-align: center;
    font-weight: 500;
    @include breakpoint(toBigPhone){
      display: none;
    }
  }
  &__swipe{
    display: none;
    height: 50px;
    width: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    @include transition(.3s all ease-in-out);
    @include multiple-media(mobileScreen, tabletScreen) {
      display: flex;
      align-items: center;
      background-color: $black;
      z-index: 7;
    }
    &.swipeRightButton{
      left: 50%;
      @include breakpoint (toBigPhone){
        z-index: 2;
      }
    }
    .rotateIcon {
      @include transition (.3s all ease-in-out);
      @include breakpoint (devices){
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        left: 0;
      }
      @include breakpoint(toBigPhone){
        bottom: 14px;
      }
    }
    i {
      color: $white;
      margin: auto;
      font-size: 30px;
      @include transition(.3s all ease-in-out);
    }
  }
  &__child{
    height: 100%;
    margin-left: 20px;
    @include multiple-media(mobileScreen, tabletScreen) {
      margin-left: 0;
    }
    @include multiple-media(smallScreen, mediumScreen) {
      flex-basis: calc(100% - 200px);
    }
    @include breakpoint(largeScreen) {
      flex-basis: calc(100% - 250px);
    }
  }
  .industry-accordion-container {
    display: flex;
    flex-direction: column;
    @include breakpoint(tabletPortrait){
      max-height: calc(100vh - 200px);
      padding-top: 80px;
      margin-top: -100px;
    }
    @include breakpoint (toBigPhone){
      max-height: calc(100vh - 250px);
      overflow-y: auto;
    }
    .accordion-item {
      @include breakpoint(desktop) {
        margin: 0 0 20px 150px;
      }
      @include breakpoint(tablet) {
        margin: 0 0 20px 150px;
      }
      @include breakpoint(tabletPortrait) {
        margin: 0 0 20px 150px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .accordion-item-content {
    .radio-group {
      margin: 0;
      margin-right: 1.5rem;
      display: inline-block;
      vertical-align: middle;
    }
  }
  .layer-container{
    @include breakpoint (toDesktop){
      left: 0;
    }
  }
  .do-preferences {
    height: 100%;
    .do-protection__holder {
      @include breakpoint (toBigPhone){
        padding: 0 15px !important;
        max-height: 100vh;
      }
      button.do-button {
        color: $white;
        position: relative;
        margin: 0 auto;
        display: block;
        width: 250px;
        padding: 20px 40px;
        font-size: 14px;
        margin-top: 80px;
        @include breakpoint (toBigPhone){
          margin-top: 70px;
          left: 0;
        }
      }
    }
  }
  .my-dropdown-dd{
    position: relative !important;
    li,p,label{
      font-weight: 500;
      color: $black;
      @include breakpoint (toDesktop){
        font-size: 2rem;
        line-height: 3.4rem;
        width: 100%;
      }
    }
  }
  .common__input-box{
    .my-dropdown-dd{
      position: absolute !important;
      background: $white;
      z-index: 1;
      max-height: 30rem;
      overflow-y: auto;
      label{
        @include breakpoint (toDesktop){
          font-size: 2rem;
          line-height: 3.4rem;
        }
      }
      ul {
        list-style: none;
        padding-left: 0;
        border: 1px solid $borderColor;
        margin: 0;
        li {
          border-bottom: 1px solid $borderColor;
          padding: 2rem;
          font-size: 1.1rem;
          @include transition (.3s all ease-in-out);
          cursor: pointer;
          border-top: 0;
          @include breakpoint (toDesktop){
            font-size: 2rem;
            line-height: 3.4rem;
          }
          &:hover{
            background-color: $dropdownColor;
            color: $brand;
          }
          &:last-child{
            border-bottom: 0;
          }
        }

      }
    }
    i{
      position: absolute;
      top: 3rem;
      font-size: 2rem;
    }
  }
  .slideDown{
    height: 47rem !important;
    @include breakpoint (toDesktop){
      height: 91rem !important;
    }
    .accordion-item-title{
      h3,i{
        color: $brand;
      }
    }
  }
  .slideDownMedium{
    height: 34rem !important;
    @include breakpoint (toDesktop){
      height: 82rem !important;
    }
    .accordion-item-title{
      h3,i{
        color: $brand;
      }
    }
  }
  .slideDownSmall{
    height: 20rem !important;
    @include breakpoint (toDesktop){
      height: 35rem !important;
    }
    .accordion-item-title{
      h3,i{
        color: $brand;
      }
    }
  }
  .warning-margin {
    margin-right: 10px !important;
    cursor: pointer;
    @include breakpoint(desktop) {
      margin-top: 0px !important;
    }
  }
  .investor-account-link{
    padding-top: 8rem;
    z-index: 1 !important;
  }
  .accordion-item {
    height: auto !important;
    margin: 0 150px 20px;
    padding: 0 20px;
    border: 1px solid #ccd0d9;
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.1);
    @include breakpoint (devices){
      margin: 0 150px 20px;
    }
    @include breakpoint (toBigPhone){
      margin: 0 10px 20px;
    }
    .accordion-item-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h3, i {
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        margin: 20px 0;
        @include transition (.3s all ease-in-out);
      }
      i {
        font-size: 20px;
        font-weight: 500;
        cursor: pointer;
        margin: 20px 0;
      }
    }
    .accordion-item-content {
      display: flex;
      flex-wrap: wrap;
    }
    .option-box {
      margin-right: 2.7%;
      font-weight: 700;
      font-size: 12px;
      border: 1px solid #ccd0d9;
      border-radius: 5px;
      width: 22.5%;
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      line-height: 1.75;
      margin-bottom: 20px;
      cursor: pointer;
      @include breakpoint (devices){
        width: 48%;
      }
      @include breakpoint(toBigPhone) {
        width: 47%;
      }
      @include breakpoint (tabletLandscape){
        width: 48%;
      }
      @include breakpoint(laptop) {
        width: 22%;
      }
      &.selected {
        background-color: $positive;
        color: $white;
        border: 1px solid $positive;
      }
      &:hover {
        @include breakpoint (desktop) {
          background-color: $positive;
          color: $white;
          border: 1px solid $positive;
        }
      }
      &:nth-child(4n+4) {
        @include breakpoint(laptop) {
          margin-right: 2.7% !important;
        }
        @include breakpoint(desktop) {
          margin-right: 0;
        }
        @include breakpoint(toDesktop) {
          margin-right: 2.7%;
        }
      }
      &:nth-child(2n+2){
        @include breakpoint (toDesktop){
          margin-right: 0;
        }
      }
    }
    .input{
      padding: 20px;
    }
    .search-box {
      margin: 25px 0;
      position: relative;
      &:after {
        font-family: FontAwesome;
        content: "\f002";
        position: absolute;
        top: 18px;
        right: 15px;
        font-size: 20px;
        color: $black;
      }
      input {
        font-size: 1.2rem;
      }
    }
  }
  .layer-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    @include breakpoint(toBigPhone){
      max-height: inherit;
    }
    @include breakpoint (devices){
      padding: 0;
    }
    &__top {
      flex-wrap: wrap;
      min-height: 50px;
      margin-bottom: 30px;
      padding-bottom: 10px;
      @include breakpoint (devices){
        padding: 20px;
        margin-bottom: 0;
      }
      @include breakpoint (toBigPhone){
        min-height: 25px;
      }
      h1 {
        text-transform: inherit;
        font-size: 30px;
        font-weight: bold;
        @include breakpoint (tabletPortrait){
          font-size: 20px;
        }
        @include breakpoint (toBigPhone){
          font-size: 13px;
        }
      }
      p {
        font-weight: 500;
        font-size: 1.5rem;
        margin: 0.5rem 0;
        color: $black;
        @include breakpoint (toDesktop){
          font-size: 2.5rem;
        }
      }
      &-left{
        min-height: 50px;
        align-items: center;
        @include breakpoint (toBigPhone){
          min-height: 25px;
        }
      }
      &-right {
        a {
          font-size: 12px;
          font-weight: 500;
          margin-left: 20px;
          @include breakpoint (toBigPhone){
            font-size: 10px;
          }
        }
      }
    }
    &__middle-holder {
      color: $black;
      display: flex;
      flex-direction: column;
      background-color: transparent;
      width: 100%;
      flex-basis: 100%;
      a {
        color: $brand;
        cursor: pointer
      }
    }
    &__middle-filters-block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1.6rem 2rem;
      position: relative;
      @include breakpoint (toDesktop){
        padding: 2rem;
      }
      &:after{
        height: 1px;
        position: absolute;
        left: -25px;
        right: -25px;
        bottom: 0rem;
        content: '';
        display: block;
        background-color: $borderColor;
      }
      &-right{
        i{
          font-size: 2rem;
          color: $smallColor;
          cursor: pointer;
          @include breakpoint (toDesktop){
            font-size: 2.4rem;
          }
        }
      }
    }
  }
  .accordion-container {
    display: flex;
    flex-direction: column;
    .accordion-item {
      padding: 0 3.5rem;
      height: 8rem;
      border-bottom: 1px solid #ccd0d9;
      background-color: $white;
      @include transition (.3s all ease-in-out);
      @include breakpoint (toDesktop){
        height: 10rem;
      }
      &.selected {
        h3,i {
          color: $brand;
        }
        .accordion-item-content {
          display: block;
        }
      }
    }
    .accordion-item-title {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      h3, i {
        font-size: 1.67rem;
        font-weight: 900;
        cursor: pointer;
        text-transform: inherit;
        @include breakpoint (toDesktop){
          font-size: 2.1rem;
        }
        .additional {
          font-weight: normal !important;
          color: $grayColor;
        }
      }
    }
    .accordion-item-content {
      font-size: 1.1rem;
      font-weight: 700;
      padding-bottom: 3rem;
      text-transform: none;
      h4 {
        text-transform: none;
        color: #464A4E;
        font-size: 1.5rem;
        @include breakpoint (toDesktop){
          font-size: 2.5rem;
        }
        &:after {
          content: none;
        }
      }
      ul {
        padding: 0 0 0 1rem;
        margin: 0;
        line-height: 2.05rem;
        list-style: none;
        li {
          &:before {
            content: "•";
            color: $brand;
            display: inline-block;
            width: 2.3rem;
            margin-left: -1rem;
            font-size: 2rem;
          }
        }
      }
      p {
        margin: 1.7rem 0 1.2rem 0;
      }
      .checkbox-container {
        height: 20px;
        display: flex;
        align-items: center;
      }
    }
    .checkbox-container {
      label {
        margin-left: 1.5rem;
      }
      input {
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        border: 1px solid #E7EAED;
        border-radius: 3px;
        display: flex;
        position: relative;
        width: 1.7rem;
        height: 1.7rem;
        align-items: center;
        @include breakpoint (toDesktop){
          width: 2.7rem;
          height: 2.7rem;
        }
      }
      input:checked:after {
        font-family: FontAwesome;
        content: '\f00c';
        outline: none;
        font-size: 1.4rem;
        color: $brand;
        margin: 0 auto;
      }
    }
  }
  .preferences-container {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    flex-grow: 1;
    justify-content: center;
    @include breakpoint(toBigPhone){
      margin-top: -66px;
    }
    @media screen and (max-height: 700px) {
      height: 100%;
      .deal {
        height: 100px;
        width: 100px;
        margin: 5px;
      }
    }
    .preferences-row {
      display: flex;
      justify-content: center;
    }
    .preferences-item {
      font-weight: 500;
      font-size: 12px;
      background-color: #E6E9F0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include breakpoint (toBigPhone){
        font-size: 10px;
      }
      @media not all and (pointer: coarse) {
        &:hover {
          background-color: $positive;
          color: $white;
        }
      }
      &.selected {
        background-color: $positive;
        color: $white;
      }
      &.stage {
        polygon {
          fill: #E6E9F0;
        }
        text {
          text-anchor: middle;
          dominant-baseline: central;
          font-size: 12px;
          fill: $black;
          @include breakpoint (smallLaptop){
            font-size: 15px;
          }
          @include breakpoint (devices){
            font-size: 15px;
          }
          @include breakpoint (toBigPhone){
            font-size: 20px;
          }
        }
        &:hover {
          polygon {
            @media not all and (pointer: coarse) {
              fill: $positive;
            }
          }
          text {
            @media not all and (pointer: coarse) {
              fill: $white;
            }
          }
        }
        &.selected {
          polygon {
            fill: $positive;
          }
          text {
            fill: $white;
          }
        }
      }
      &.deal {
        height: 130px;
        width: 130px;
        border-radius: 50%;
        margin: 15px;
        text-align: center;

        @include breakpoint(mobileScreen) {
            padding: .5rem;
        }
        @include breakpoint(mediumScreen) {
          height: 120px;
          width: 120px;
        }
        @include breakpoint(largeScreen) {
          height: 160px;
          width: 160px;
        }
        @media screen and (min-width: 1200px) and (max-width: 1366px) and (max-height: 700px) {
          height: 100px;
          width: 100px;
          margin: 10px;
        }
      }
    }
  }
  .bigText {
    font-size: 1.3rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-all;
  }
  .smallText{
    font-size: 1.1rem;
    font-weight: 500;
    color: $smallColor;
    @include breakpoint (toDesktop){
      font-size: 2.2rem;
    }
  }
  .investor-preferences-wrapper {
    @-moz-document url-prefix() {
      @include breakpoint(smallScreen) {
        height: calc(100vh - 220px);
      }
      @include multiple-media(mediumScreen, largeScreen) {
        height: calc(100vh - 190px) !important;
      }
    }
  }
  &.investor-second-login-preferences {
    .do-experience__parent {
      @include breakpoint(largeScreen) {
        height: 100%;
      }
    }
  }
}
.do-preferences {
  .layer-body {
    &__middle-holder.box {
      @include multiple-media(mobileScreen, tabletScreen) {
        height: calc(100% - 50px);
        overflow-y: auto;
      }
    }
  }
}
.do-professional {
  .label-checker{
    span{
      height: 20px;
      width: 20px;
      display: block;
      background: black;
      content: '';
      position: absolute;
      top: -10px;
      opacity: 0;
    }
  }
  .rotateIcon{
    @include breakpoint(tabletPortrait){
      bottom: 20px;
    }
  }
  .layer-body__middle-filters{
    flex-basis: 250px;
    display: flex;
    flex-direction: column;
    @include multiple-media(smallScreen, mediumScreen) {
      flex-basis: 200px;
    }
    @-moz-document url-prefix() {
      height: 96.5%;
    }
  }
  .multiselect__input{
    @extend .input;
  }
  h4{
    &:after{
      left: -25px;
      right: -25px;
      background-color: $borderColor;
    }
  }
  .bigText{
    color: $smallColor;
    font-size: 12px;
    font-weight: 500;
  }
  .layer-body__middle-filters-block{
    padding: 20px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after{
      position: absolute;
      bottom: 0;
      left: -25px;
      right: -25px;
      display: block;
      height: 1px;
      background-color: $borderColor;
      content: '';
    }
    &-right{
      i{
        font-size: 16px;
      }
    }
    &.doneBlock{
      .layer-body__middle-filters-block-right{
        i{
          color: $positive;
          display: block;
          @include breakpoint (toDesktop){
            display: none;
          }
        }
      }
      .bigText{
        color: $positive;

      }
    }
    &.currentBlock{
      .bigText{
        color: $black;

      }
    }
  }
  .common {
    &__input-box {
      margin-bottom: 0;
      @include breakpoint (devices){
        margin-bottom: 10px;
      }
      label {
        color: $smallColor;
        font-size: 10px;
        font-weight: 500;
        @include breakpoint(devices) {
          font-size: 13px;
        }
      }
      input {
        margin-top: 10px;
        padding: 17px 20px;
        font-size: 12px;
        font-weight: 500;
        &::placeholder{
          font-style: italic;
          color: $investmentTitle;
        }
      }
    }
  }
  &__results{
    flex-wrap: wrap;
    overflow-y: hidden;
    h4{
      display: block;
      text-transform: uppercase;
      margin: 0 auto;
      &:after{
        display: none;
      }
    }
    &-block{
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid $borderColor;
      padding: 1.5rem;
      position: relative;
      cursor: pointer;
      @include transition(.3s all ease-in-out);
      &:hover{
        background-color: $brand;
        border-color: $brand;
        .do-professional__results-block-right{
          color: $white;
        }
      }
      button{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: $brand;
        border: 1px solid $brand;
        margin: 0 auto;
        display: block;
        width: 100%;
        color: $white;
        font-weight: 500;
        font-family: $regular;
        font-size: 12px;
        outline: none;
        cursor: pointer;
      }
      &-left{
        width: 4rem;
        height: 4rem;
        display: inline-block;
        vertical-align: middle;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-right: 1.5rem;
        img{
          width: 100%;
        }
      }
      &-right{
        color: $black;
        font-size: 1.2rem;
        font-weight: 500;
      }
      &:last-child{
        padding: 3.5rem;
        border-color: $brand;
      }
    }
    .results-list{
      max-height: calc(100vh - 25rem);
      overflow-y: auto;
      @include breakpoint(smallScreen) {
        max-height: calc(100vh - 380px);
      }
      @include breakpoint (desktop){
        max-height: calc(100vh - 280px);
      }
      @include breakpoint (toDesktop){
        max-height: calc(100vh - 280px);
      }
    }
  }
  .select-firm{
    @include breakpoint (desktop){
      margin: 0;
      padding: 0 280px !important;
    }
    .shrink{
      @include breakpoint (desktop){
        padding: 0;
        margin-bottom: 100px;
      }
      @include breakpoint (toDesktop){
        padding: 0;
      }
    }
  }
  .additional-information{
    @include breakpoint(tabletLandscape){
      overflow-y: auto;
    }
    margin: 0;
  }
  .firm-form{
    div {
      margin-bottom: 2rem;
      @include breakpoint (desktop){
        margin-bottom: 20px;
      }
      .errorText {
        @include breakpoint (desktop){
          position: absolute;
          bottom: -15px;
        }
      }
    }
    div:last-child {
      margin-bottom: 0;
    }
  }
  &__holder{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-height: calc(100vh - 350px);
    overflow-y: auto;
    padding: 0 170px;
    @include breakpoint (toBigPhone){
      max-height: calc(100vh - 300px);
      padding: 0;
      overflow-y: auto;
    }
    @include breakpoint (tabletLandscape){
      padding: 0;
    }
    @include breakpoint (tabletPortrait){
      padding: 0;
    }
    @include breakpoint(smallLaptop) {
      padding: 0;
      max-width: 80%;
    }
    @include breakpoint(desktop) {
      padding: 0;
      max-width: 80%;
    }
  }
  &__institution{
    h4{
      text-transform: none;
      margin-bottom: 25px;
    }
    &-block{
      margin-bottom: 20px;
      padding: 25px;
      width: 100%;
      display: block;
      border: 1px solid $smallBlockBorder;
      @include breakpoint (toBigPhone){
        width: 100%;
      }
      .label-checker:before{
        top: -14px !important;
      }
      h5{
        margin: 0;
        text-transform: none;
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 2rem;
      }
      ul{
        padding: 0;
        list-style: none;
        li{
          margin-bottom: 2rem;
          font-size: 1.2rem;
          font-weight: 500;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .login {
        &__list-checkboxLogin {
          width: 5%;
          flex-basis: 5%;
          @include breakpoint (toDesktop){
            width: 10%;
            flex-basis: 10%;
          }
          .check{
            border-color: $borderColor;
            height: 16px;
            width: 16px;
            top: 0;
          }
        }
        &__list-item-holder {
          width: 95%;
          flex-basis: 95%;
          label {
            text-transform: inherit;
            font-weight: 500;
            font-size: 14px;
            letter-spacing: 0.5px;
          }
        }
      }
      > div{
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        &:last-child{
          margin-bottom: 0;
        }
        @include breakpoint (toDesktop){
          align-items: flex-start;
        }
      }
      &.biggerList{
        label{
          font-size: 1.6rem;
        }
        > div{
          margin-bottom: 0;
        }
        .check{
          top: -0.9rem;
        }
      }
    }
  }
  &__results-block {
    &-right {
      font-size: 1.2rem;
    }
  }
}
.do-industry {
  &.industry {
    .layer-body {
      &__middle-holder {
        @include breakpoint(desktop) {
          overflow-y: hidden !important;
        }
        @include breakpoint(tablet) {
          overflow-y: hidden !important;
        }
        @include breakpoint(tabletPortrait) {
          overflow-y: hidden !important;
        }
      }
    }
  }

}
.do-addition {
  .common {
    &__input-box {
      margin-bottom: 10px;
      &.showError {
        &:before {
          @include breakpoint (toDesktop){
            top: 5.8rem;
          }
        }
      }
    }
  }
  &__holder{
    align-items: flex-start;
    display: flex;
    max-height: calc(100vh - 300px);
    @include breakpoint (devices){
      flex-wrap: wrap;
      overflow-y: auto;
      padding: 0 135px;
    }
    @include breakpoint (tabletPortrait){
      padding: 0 120px;
      margin: 0px;
      flex-grow: 1;
    }
    @include breakpoint (tabletLandscape){
      padding: 0;
      margin: 0px;
      flex-grow: 1;
    }
    @include breakpoint(smallLaptop){
      max-height: calc(100vh - 240px);
    }
    @include breakpoint(toBigPhone){
      padding: 0;
      margin: 0 10px;
      flex-grow: 1;
    }
    &-block{
      margin: 0 10px;
      padding-bottom: 0px;
      @include breakpoint (tabletPortrait){
        width: 100%;
      }
      @include breakpoint (devices){
        text-align: center;
        margin: 0 auto;
      }
      label{
        color: $smallColor;
        font-weight: 500;
        font-size: 10px;
        @include breakpoint(devices){
          font-size: 13px;
        }
      }
    }
    .showError{
      &:before{
        display: none;
      }
    }
    .errorText{
      position: relative;
      bottom: 0;
      right: 0;
      text-align: right;
      font-size: 12px;
    }
  }
  &__box{
    margin-top: 10px;
    padding: 20px;
    position: relative;
    width: 150px;
    @include breakpoint (toDesktop){
      margin: 0 auto;
      border: 1px solid $layerBorder;
    }
    .errorText{
      position: relative;
      color: $red;
      margin-top: 0.5rem;
      display: block;
    }
    .line{
      position: absolute;
      left: 0;
      right: 0;
      bottom: 27%;
      z-index: 0;
      height: 1px;
      background-color: $borderColor;
      content: '';
      display: block;
    }
    &-image{
      width: 100%;
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      overflow: hidden;
      z-index: 1;
      position: relative;
      box-sizing: border-box;
      img{
        width: 100%;
        position: relative;
      }
    }
    &-button{
      position: relative;
      box-sizing: border-box;
      width: 100%;
      input{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        opacity: 0;
        width: 100%;
        cursor: pointer;
      }
      button{
        border: 0;
        background: $buttonColor;
        display: block;
        width: 100%;
        padding: 7px 0;
        color: $white;
        font-family: $regular;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 12px;
      }
    }
  }
}
.do-fundraising {
  .vue-slider-dot{
    left: -1rem !important;
    top: -1rem !important;
  }
  .common__input-box.showError:before{
    display: none;
  }
  .preferences-container {
    padding: 0 100px;
    @include breakpoint(tabletScreen) {
      padding: 0 20px;
    }
    @include breakpoint(toBigPhone){
      padding: 20px;
    }
    @include breakpoint(tabletLandscape){
      padding: 0 115px;
    }
    svg {
      width: 100%;
      @include breakpoint(largeScreen) {
        width: 90%;
      }
    }
  }
  .protection-warning {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 1.2rem;
    ul{
      padding-left: 2rem;
      margin-top: -3rem;
      @include breakpoint(tablet) {
        margin-top: 0 !important;
      }
      @include breakpoint(phone) {
        margin-top: 0 !important;
      }
      li{
        margin-bottom: -2rem;
        @include breakpoint(tablet) {
          margin-bottom: 0 !important;
        }
        @include breakpoint(phone) {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  .investor-preferences-wrapper {
    height: 100vh;
  }
}
.do-elective {
  .do-experience {
    &__parent {
      position: relative;

      @-moz-document url-prefix() {
        @media screen and (max-height: 700px) {
          height: calc(100vh - 180px);
        }
      }
    }
    &__child {
      flex-grow: 1;
    }
  }
  .layer-body {
    &__middle-fiters {
      box-sizing: border-box;
      padding: 0;
      flex-basis: 200px;
      width: 200px;
      min-height: 0;
      @include breakpoint(largeScreen) {
        width: 250px;
        flex-basis: 250px;
      }
      h4 {
        padding: 15px;
      }
    }
    &__middle-filters-block {
      padding: 15px;
      &:after {
        left: -15px;
        right: -15px;
      }
    }
  }
  .bigText {
    font-size: 12px;
    font-weight: 500;
    color: $black;
  }
  .smallText {
    font-size: 11px;
    font-weight: 500;
  }
  @supports (-ms-ime-align:auto) {
    .do-experience__child {
      @include breakpoint(smallLaptop){
        height: calc(100vh - 200px);
        min-height: calc(100vh - 200px);
        max-height: calc(100vh - 200px);
      }
      @include breakpoint(tabletLandscape){
        height: calc(100vh - 200px);
        min-height: calc(100vh - 200px);
        max-height: calc(100vh - 200px);
      }
      @include breakpoint(bigScreen){
        height: calc(100vh - 200px);
        min-height: calc(100vh - 200px);
        max-height: calc(100vh - 200px);
      }
    }
  }
}
.do-protection {
  &__holder{
    display: block;
    @include breakpoint (toDesktop){
      padding: 0;
    }
    &-block{
      margin-bottom: 40px;
      color: $black;
      cursor: pointer;
      label{
        color: $black;
        line-height: 18px;
        font-weight: 500;
        font-size: 12px;
        @include breakpoint (toDesktop){
          justify-content: space-between;
        }
        span{
          font-size: 12px;
          cursor: pointer;
          @include breakpoint (toDesktop){
            display: block;
            width: 100%;
            text-align: left;
          }
        }
      }
      .checkbox-container{
        align-items: flex-start;
        input{
          height: 20px;
          width: 20px;
          margin-right: 20px;
          @include  breakpoint(smallLaptop) {
            margin-right: -10px;
            margin-top: 0;
            margin-left: 35px;
          }
          @include breakpoint(toBigPhone){
            margin-right: 10px;
          }
          &:after{
            font-size: 12px;
          }
        }
      }
    }
  }
}

.layer-body__middle-holder.hub-100-width{
  width: 100%;
  flex-basis: 100%;
}
.preferences-container {
  .do-addition {
    &__holder.do-protection {
        &__holder {
          @include breakpoint(smallLaptop) {
            overflow-y: auto;
          }
        }
    }
  }
}
.accordion-item-content {
  h4{
    padding-left: 0;
  }
}
.spacer-2-5 { height: 2.5rem; }
.proceed-button {
  background-color: $brand;
  color: #fff;
  padding: 1.3rem 3.6rem;
  text-transform: uppercase;
  cursor: pointer;
  font-family: $regular;
  border: 0; font-size: 1.2rem;
  border-radius: 2px;
  outline: none;
  font-weight: 500;
  @include breakpoint(toDesktop){
    padding: 2.3rem 5.6rem;
    font-size: 2.2rem;
  }
}
.first-step {
  width: fit-content;
}
.geography-margin {
  overflow-y: auto;
  @include breakpoint(tablet) {
    margin-top: 200px !important;
  }
}
.content-margin{
  @-moz-document url-prefix() {
    @include breakpoint(smallLaptop){
      max-height: calc(100vh - 520px);
    }
  }
  @supports (-ms-ime-align:auto) {
    @include breakpoint(smallLaptop){
      max-height: calc(100vh - 450px);
    }
  }
}
.experience{
  @include breakpoint(bigScreen) {
    margin-right: 150px;
  }
  @include breakpoint(smallLaptop){
    padding: 0;
    padding-top: 0 !important;
    padding-right: 75px;
    margin: 0px 120px auto;
    margin-right: 170px;
    min-height: calc(100vh - 330px);
    max-height: calc(100vh - 390px);
    @-moz-document url-prefix() {
      padding-top: 10px !important;
    }
  }
  @include breakpoint(laptop){
    padding-right: 150px;
    padding-top: 20px !important;
    max-height: calc(100vh - 200px);
  }
  @include breakpoint (tabletLandscape){
    max-height: calc(100vh - 250px);
    padding: 220px 75px 0 !important;
    margin: 0;
    margin-right: 135px;
    margin-top: 0 !important;
  }
  @include breakpoint (ipadProLandscape){
    max-height: calc(100vh - 250px);
    padding-right: 120px !important;
    margin: 0 120px auto;
    margin-top: 0 !important;
  }
  @include breakpoint(toBigPhone) {
    padding-top: 280px;
  }
  @-moz-document url-prefix() {
    @include breakpoint(smallLaptop){
      max-height: calc(100vh - 460px);
    }
  }
  @supports (-ms-ime-align:auto) {
    @include breakpoint(smallLaptop){
      max-height: calc(100vh - 360px);
    }
  }
}
.checkbox-container {
  height: 35px;
  display: flex;
  align-items: center;
  label {
    margin-left: 1.5rem;
    font-weight: 500;
    color: $black;
  }
  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: 1px solid #E7EAED;
    border-radius: 3px;
    display: flex;
    position: relative;
    width: 1.7rem;
    height: 1.7rem;
    align-items: center;
  }
  input:checked:after {
    font-family: FontAwesome;
    content: '\f00c';
    outline: none;
    font-size: 1.4rem;
    color: $brand;
    margin: 0 auto;
  }
}
.checkbox-error-font {
  font-size: 12px;
  color: #ed5565;
  position: absolute;
  display: block;
  z-index: 1;
  margin-top: 8px;
  font-weight: normal;
  @include breakpoint(toDesktop) {
    position: relative;
  }
  @include breakpoint(laptop) {
    bottom: auto !important;
  }
  @include breakpoint(bigScreen) {
    bottom: auto !important;
  }
}
.error-font {
  font-size: 12px;
  right: 0;
  color: #ed5565;
  position: absolute;
  display: block;
  z-index: 1;
  @include breakpoint(toDesktop) {
    font-size: 12px !important;
    bottom: -1.5rem !important;
  }
  @include breakpoint(laptop) {
    bottom: -1rem !important;
    right: 1rem;
  }
  @include breakpoint(bigScreen) {
    bottom: -1rem !important;
    right: 1rem;
  }
}
.industry-scroll {
  overflow: hidden;
  overflow-y: auto;
  @include breakpoint(bigScreen) {
    margin-right: 140px;
    max-height: calc(100vh - 300px);
  }
  @include breakpoint(laptop) {
    margin-right: 140px;
    max-height: calc(100vh - 290px);
  }
  @include breakpoint(smallLaptop) {
    margin-right: 140px;
    max-height: calc(100vh - 290px);
  }
  @include breakpoint(tablet) {
    margin-right: 140px;
    max-height: calc(100vh - 340px);
  }
  @include breakpoint(tabletLandscape) {
    margin-right: 140px;
    max-height: calc(100vh - 340px);
  }
  @include breakpoint(tabletPortrait) {
    margin-right: 140px;
    margin-top: 40px;
    max-height: calc(100vh - 280px);
  }
  @-moz-document url-prefix() {
    @include breakpoint(desktop) {
      overflow: hidden;
      overflow-y: auto;
      margin-right: 140px;
      max-height: calc(100vh - 290px);
    }
    @include  breakpoint(toDesktop) {
      max-height: calc(100vh - 290px);
    }
    @include breakpoint(tablet) {
      overflow: hidden;
      overflow-y: auto;
      margin-right: 140px;
    }
    @include breakpoint(tabletPortrait) {
      overflow: hidden;
      overflow-y: auto;
      margin-right: 140px;
    }
  }
}
.placeOrderModal .common__input-box.showError:before {
  @include breakpoint(tablet) {
    top: 3.8rem !important;
  }
  @include breakpoint(phone) {
    top: 3.8rem !important;
  }
}
.common__input-box.showError:before {
  @include breakpoint(tablet) {
    top: 4.8rem !important;
  }
  @include breakpoint(phone) {
    top: 4.8rem !important;
  }
}
.accordion-container{
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  .accordion-item-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  a{
    color: $brand;
    cursor: pointer;
  }
  h3{
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 500;
    text-transform: none;
    @include transition(.3s all ease-in-out);
    @include breakpoint (toBigPhone){
      font-size: 13px;
      text-align: left;
      line-height: 1.8;
    }
    .additional{
      font-weight: normal;
      color: $grayLight;
    }
  }
  .accordion-item{
    background-color: $white;
    padding: 30px;
    border-bottom: 1px solid $smallBlockBorder;
    @extend .boxShadow;
    @include breakpoint (toBigPhone){
      padding: 15px;
    }
    &:last-child{
      border-bottom: 0;
    }
    .fa-caret-down{
      color: $dark-gray;
    }
    h3{
      cursor: pointer;
    }
    &.slideDown,
    &.slideDownSmall{
      h3{
        color: $brand;
        .additional{
          color: $brand;
        }
      }
    }
  }
  .my-dropdown-dd{
    position: relative !important;
    padding: 25px 0;
    h4{
      font-size: 12px;
      font-weight: 500;
      text-transform: none;
      color: $black;
      margin-bottom: 15px;
      @include breakpoint (toBigPhone){
        line-height: 1.8;
      }
    }
    ul{
      padding-left: 19px;
    }
    li{
      font-size: 20px;
      font-weight: normal;
      line-height: 1.5;
      list-style: initial;
      @include breakpoint (toBigPhone){
        margin-bottom: 12px;
        line-height: 1.8;
      }
      span{
        padding-left: 15px;
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
        @include breakpoint (toBigPhone){
          line-height: 1.8;
        }
      }
    }
    p{
      font-size: 12px;
      font-weight: normal;
      line-height: 1.5;
      margin-top: 25px;
      @include breakpoint (toBigPhone){
        line-height: 1.8;
      }
    }
  }
  .checkbox-container {
    cursor: pointer;
    label {
      margin-left: 15px;
      font-size: 12px;
      font-weight: normal;
      cursor: pointer;
      @include breakpoint (toBigPhone){
        width: 90%;
      }
    }
    input {
      width: 20px;
      height: 20px;
      cursor: pointer;
      &:checked{
        &:after{
          font-size: 14px;
        }
      }
    }
  }
  button{
    font-size: 12px;
    padding: 15px 40px;
    margin-top: 10px;
  }
}
.biggerFont {
  @include breakpoint(desktop){
    font-size: 12px;
  }
  @include breakpoint(toDesktop){
    font-size: 12px !important;
  }
}
.form-position {
  @include breakpoint(bigScreen) {
    margin-top: -60px;
    .errorText {
      bottom: -15px;
    }
  }
  @include breakpoint(laptop) {
    margin-top: -60px;
    .errorText {
      bottom: -15px;
    }
  }
  @include breakpoint(smallLaptop) {
    margin-top: -60px;
    .errorText {
      bottom: -15px;
    }
  }
  @include breakpoint(tabletLandscape) {
    margin-top: -60px;
    .errorText {
      bottom: -15px;
    }
  }
}
.removeScroll {
  overflow-y: hidden !important;
  @include breakpoint(laptop) {
    max-height: calc(100vh - 170px);
  }
}
.profile-new {
  .do-experience {
    &__parent-holder {
      margin-top: 0;
    }
  }
}
.scrollShow {
  overflow-y: auto;
  overflow-x: hidden;
  display: block;
  height: 100%;
  margin-bottom: 10px;
}
.investor-preferences-sidebar-buttons {
  width: calc(100% - 30px);
  padding-left: 15px;
  .do-button,
  .do-button-skip {
    color: $white;
    padding: 20px;
    text-transform: uppercase;
    cursor: pointer;
    font-family: $regular;
    border: 0;
    font-size: 12px;
    border-radius: 2px;
    outline: none;
    font-weight: 600;
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }
  .do-button {
    background-color: $brand;
  }
  .do-button-skip {
    background-color: $orange-pink;
  }
}
.investor-second-login-preferences {
  .results-list{
    @include breakpoint(mediumScreen) {
      max-height: 35vh !important;
    }
  }
  .layer-new {
    &__right {
      .do-experience__parent {
        padding-bottom: 0 !important;
        @include multiple-media(mobileScreen, tabletScreen) {
          height: calc(100% - 80px);
        }
      }
    }
  }
  .deal-type {
    & > {
      div {
        &:first-of-type {
          @media screen and (max-height: 700px) {
            position: relative;
            top: 15px;
          }
        }
      }
    }
  }
  .preferences-deal-size {
    top: -125px;
  }
  .fundraising-wrapper {
    padding: 0 15%;
    @include multiple-media(mobileScreen, tabletScreen) {
      padding: 0 5%;
    }
    svg {
      width: 100%;
    }
  }
  .do-experience {
    &__parent-holder {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .swipeRight {
        top: 0;
      }
    }
    &__child {
      .do-experience {
        &__link {
            @include breakpoint(tabletScreen) {
              right: 20px;
            }
        }
        &__link-left {
          @include breakpoint(tabletScreen) {
            left: 20px;
            right: auto;
          }
        }
      }
      .container-height {
        @include breakpoint(tabletScreen) {
          height: calc(100% - 50px) !important;
        }
      }
      .geography {
        @include breakpoint(tabletScreen) {
          height: calc(100% - 50px);
        }
      }
    }
  }
  .investor-preferences-wrapper {
    height: calc(100% - 90px);
    @include multiple-media(mobileScreen, tabletScreen) {
      height: calc(100% - 10px);
    }
  }
  .do-stage {
    position: absolute;
    left: 0;
    right: 0;
    width: auto;

    @include multiple-media(smallScreen) {
        left: -1rem;
        right: -1rem;
    }

    @include multiple-media(mediumScreen, largeScreen) {
        left: -4rem;
        right: -4rem;
    }
  }
}
.do-professional {
  h4 {
    padding: 20px 0;
  }
  .layer-body__middle-filters{
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100%;
    @include breakpoint(largeScreen) {
      min-width: 250px;
      max-width: 250px;
    }
  }
  .do-experience__child {
    .do-button {
      background-color: $brand;
      color: $white;
      padding: 20px;
      text-transform: uppercase;
      cursor: pointer;
      font-family: $regular;
      border: 0;
      font-size: 1.2rem;
      border-radius: 2px;
      outline: none;
      font-weight: 600;
    }
    .layer-body__middle-holder, .layer-body__middle {
      height: 100%;
    }
    .layer-body__middle-holder {
      overflow-y: auto;
    }
  }
  .do-experience__parent {
    height: 100%;
    @include breakpoint(largeScreen) {
      height: calc(100vh - 22.4rem);
    }
  }
  .investor-preferences-wrapper {
    h4 {
      padding: 20px;
    }
    .layer-body__middle-filters {
      padding: 0;
    }
  }
}
.investor-profile-preferences {
  .do-protection {
    &__holder {
      display: block;
      padding: 0 150px;
    }
  }
  .do-experience {
    &__link {
      @include breakpoint(tabletScreen) {
        right: 20px;
      }
    }
    &__link-left {
      @include breakpoint(tabletScreen) {
        left: 20px;
        right: auto;
      }
    }
    &__child {
      @include breakpoint(tabletScreen) {
        height: calc(100% - 50px);
      }
    }
  }
}
