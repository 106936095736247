.login{
    &__holder{
        .layout.center {
          align-items: center;
        }
        background-position: 0% 0%;
        background-size: cover;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        @include breakpoint (toDesktop){
            height: 100%;
        }
        &-item{
            position: relative;
            .request-invite-error{
                position: absolute;
                bottom: -3rem;
                right: 0;
                font-size: 1.4rem;
            }
        }
        &-block{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 64.5%;
            content: '';
            display: block;
            background-color: $white;
            padding: 26rem 7.7rem;
            font-size: 1.5rem;
            @include breakpoint (toDesktop){
                left: 0;
                font-size: 2.2rem;
                padding: 30rem 3rem;
                height: 100%;
            }
            @media screen and (min-width:640px) and (max-width:768px) {
                padding: 7rem 3rem 0rem;
            }
            @include breakpoint(tablet) {
                padding: 7rem 3rem 0rem;
            }
            @media screen and (min-width: 1024px) and (max-height: 450px){
              height: auto;
              overflow-x: hidden;
              overflow-y: auto;
            }
            &-tabs{
                display: flex;
                align-items: center;
                flex-basis: 100%;
                width: 100%;
                border-bottom: 1px solid $investmentTitle;
                span,
                button{
                    text-transform: uppercase;
                    color: $black;
                    padding: 0 2rem;
                    padding-top: 0;
                    padding-bottom: 0.8rem;
                    letter-spacing: 0.5px;
                    font-size: 1.1rem;
                    font-weight: 500;
                    background-color: transparent;
                    border: 0;
                    outline: none;
                    position: relative;
                    cursor: pointer;
                    @include breakpoint (toDesktop){
                        font-size: 2.7rem;
                        padding: 0 3rem;
                        padding-bottom: 1rem;
                    }
                    &.active{
                        color: $brand;
                        &:after{
                            background-color: $brand;
                            height: 1px;
                            position: absolute;
                            bottom: -0.08rem;
                            left: 0;
                            right: 0;
                            content: '';
                            display: block;
                            @include breakpoint (toDesktop){
                                bottom: -0.55rem;
                            }
                        }
                    }
                }
            }
            &-layout{
                label{
                    display: block;
                    margin: 1rem 0;
                    margin-top: 1.4rem;
                    font-weight: 500;
                    font-size: 1.1rem;
                    color: $grayColor;
                    @include breakpoint (toDesktop){
                        font-size: 2.8rem;
                    }
                }
                #fos_user_resetting_form label {
                    color: $grayColor !important;
                }
                input{
                    @extend .input;
                    outline: none;
                    width: 100%;
                    font-style: italic;
                    font-size: 1.2rem;
                    border-radius: 3px;
                    @include transition (.3s all ease-in-out);
                    @include breakpoint (toDesktop){
                        -webkit-appearance: none;
                        font-size: 16px;
                        padding: 2.7rem 2rem;
                    }
                }
                button{
                    margin-top: 4rem;
                    float: left;
                    background: $brand;
                    border: 0;
                    color: white;
                    padding: 1.4rem 5rem;
                    outline: none;
                    font-size: 1.1rem;
                    text-transform: uppercase;
                    cursor: pointer;
                    @include transition (.3s all ease-in-out);
                    @include breakpoint (toDesktop){
                        padding: 3.5rem 11rem;
                        outline: none;
                        font-size: 2.8rem;
                        margin-top: 10rem;
                    }
                    &:hover:enabled{
                        background-color: $black;
                        color: $white;
                    }
                }
            }
        }
        .fos_user_resetting_reset{
            width: 100%;
            padding-top: 0;
            input[type=submit]{
                margin-top: 4rem;
                background: $brand;
                border: 0;
                color: $white;
                padding: 1.4rem 5rem;
                outline: none;
                font-size: 1.1rem;
                text-transform: uppercase;
                cursor: pointer;
                @include transition (.3s all ease-in-out);
                width: auto;
                letter-spacing: 0px;
                font-style: normal;
                border-radius: 0;
                &:hover:enabled {
                    background-color: $black !important;
                    color: $white;
                }
            }
        }
        .form-proraise {
            label {
                font-family: Nunito;
                font-size: 16px;
                color: #000000;
            }
            input {
                border: solid 1px #dedede;
                background-color: #ffffff;
            }
        }
    }
    &__hosted{
        position: absolute;
        bottom: 4rem;
        right: 0;
        left: 0;
        text-align: center;
        opacity: 0;
        transition-delay: 1.2s;
        animation-delay: 1.2s;
        @media screen and (min-width: 1024px) and (max-height: 450px){
          position: relative;
          bottom: 0;
          margin-top: 10rem;
        }
        @include breakpoint (toDesktop){
            top: auto;
            right: 0;
            left: auto;
            bottom: 0;
            width: 100%;
        }
        img{
            width: 20rem;
            @include breakpoint (toDesktop){
                width: 30rem;
                max-width: 60%;
                margin: 0 auto;
            }
        }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: none;
        }
    }
    &__list{
        border: 1px solid $investmentTitle;
        border-radius: 5px;
        list-style: none;
        padding: 0;
        padding-bottom: 1px;
        &-checkboxLogin{
            width: 7%;
            flex-basis: 7%;
            position: relative;
                input[type=radio]{
                    position: absolute;
                    visibility: hidden;
                }
                label{
                    display: inline-block;
                    vertical-align: middle;
                    position: relative;
                    font-weight: 300;
                    font-size: 1.35em;
                    z-index: 9;
                    cursor: pointer;
                    -webkit-transition: all 0.25s linear;
                    height: 0;
                    opacity: 0;
                    margin: 0;
                }
                .check{
                    display: block;
                    position: absolute;
                    border: 1px solid #AAAAAA;
                    border-radius: 100%;
                    height: 15px;
                    width: 15px;
                    z-index: 5;
                    transition: border .25s linear;
                    -webkit-transition: border .25s linear;
                    top: 0.2rem;
                    @include breakpoint (toDesktop){
                        height: 3.5rem;
                        width: 3.5rem;
                    }
                }
                .check::before {
                    display: block;
                    position: absolute;
                    content: '';
                    border-radius: 100%;
                    height: 8px;
                    width: 8px;
                    top: 4px;
                    left: 4px;
                    margin: auto;
                    transition: background 0.25s linear;
                    -webkit-transition: background 0.25s linear;
                    @include breakpoint (toDesktop){
                        height: 8px;
                        width: 8px;
                        top: 4px;
                        left: 4px;
                    }
                }
                input[type=radio]:checked ~ .check {
                    border: 1px solid $investmentTitle;
                }
                input[type=radio]:checked ~ .check::before{
                    background: $brand;
                }
        }
        &-item{
            display: flex;
            align-items: flex-start;
            padding: 1.5rem;
            &:last-child{
                background-color: $darkerWhite;
                margin-bottom: 0;
            }
            label{
                margin-top: 0;
            }
            &-holder{
                width: 93%;
                flex-basis: 93%;
                label{
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: $black;
                    margin-bottom: 0rem;
                    text-transform: uppercase;
                    cursor: pointer;
                    display: inline-block;
                    letter-spacing: 0.05rem;
                    @include breakpoint (toDesktop){
                        font-size: 2.4rem;
                        margin-bottom: 0.5rem;
                    }
                }
                span{
                    font-size: 1.2rem;
                    font-weight: 300;
                    display: block;
                    @include breakpoint (toDesktop){
                        font-size: 2.4rem;
                        line-height: 3.5rem;
                    }
                }
            }
        }
    }
    &__forgot{
        color: $brand;
        font-style: italic;
        font-size: 1.1rem;
        float: right;
        display: block;
        margin-top: 2.5rem;
        cursor: pointer;
        @include breakpoint (toDesktop){
            font-size: 2.7rem;
            margin-right: 2rem;
        }
    }
    &__back{
        color: $brand;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid $investmentTitle;
        padding: 2rem;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        @include breakpoint (toDesktop){
            padding: 3rem;
            font-size: 4rem;
        }
        span{
            display: inline-block;
            margin-right: 0.7rem;
        }
    }
    &__back_home{
        color: $brand;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border-bottom: 1px solid $investmentTitle;
        padding: 2rem;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        text-align: right;
        @include breakpoint (toDesktop){
            padding: 2rem 3rem;
            font-size: 12px;
        }
        @media screen and (min-width:640px) and (max-width:768px) {
            padding: 1rem 3rem;
            font-size: 12px;
        }
        span{
            display: inline-block;
            margin-right: 0.7rem;
        }
    }
    &__message, &__messageSession{
        position: absolute;
        top: 1rem;
        font-size: 1.1rem;
        border-radius: 5px;
        padding: 1rem;
        padding-right: 3rem;
        left: 19.7rem;
        right: 1rem;
        display: none;
        z-index: 1;
        @include breakpoint (toDesktop){
            font-size: 2.5rem;
            left: 2.5rem;
            right: 2.5rem;
            padding-right: 5rem;
            top: 10rem;
        }
        span{
            position: absolute;
            top: 0.8rem;
            right: 1.5rem;
            cursor: pointer;
        }
        &.blue{
            border: 1px solid $brand;
            color: $brand;
            background: $darkerBlue;
            display: block;
        }
        &.red{
            border: 1px solid $red;
            color: $red;
            background: $errorBackground;
            display: block;
        }
    }
}
.tabblock{
    display: none;
}
#Sign{
    display: block;
}
.reset_text{
    color: $black;
    line-height: 3rem;
    font-weight: 500;
    font-size: 1.5rem;
    @media screen and (min-width:640px) and (max-width:768px) {
        font-size: 12px;
    }
}
.page-content {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-top: 3rem!important;
  }
  padding-left: 100px;
    padding-right: 100px;
    margin-top: 50px;
    margin-bottom: 100px;
    a{
        color: $brand;
        transition: all 0.2s linear;
        &:hover{
            color: $darkerBrand;
        }
    }
    .title {
        margin-top: 45px;
        font-size: 30px;
    }
}

.ie-warning {
    display: none;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        div {
            &:first-child {
                margin-bottom: 15px;
                color: $color-red-main;
                font-weight: 500;
            }
            line-height: 2.4rem;
            font-family: $regular;
            font-size: 1.4rem;
            color: $color-grey-6;
        }
        border-radius: 5px;
        border: solid 1px #ffdee1;
        padding: 15px;
        background-color: #fef4f5;
        margin: 0 auto 15px;
        max-width: 640px;
    }
}
