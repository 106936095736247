.compact-place-order {
  .layer-container {
    @include multiple-media(mobileScreen, tabletScreen) {
      position: fixed;
      left: 0;
    }
    .layer-header,
    .layer-body {
      padding: 0;
      background-color: $color-white;
    }
    .layer-body {
      height: calc(100% - 8.8rem);
      overflow-x: hidden;
      padding-top: 1.5rem;
    }
  }
  &__header {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.1);
    padding: 0 3rem;
    position: relative;
  }
  &__title {
    font-size: 14px;
    font-weight: normal;
    font-family: $regular;
    color: $color-grey-6;
    text-transform: none;
  }
  &__close-button {
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    color: $color-grey-6;
  }
  &__content {
    padding: 3rem;
    background-color: $color-grey-1;
    form {
      padding: 0;
      background-color: $color-grey-1;
    }
    .order {
      display: flex;
      flex-direction: row;
      padding-bottom: 15px;
      position: relative;
      &__item {
        display: flex;
        flex-direction: column;
        position: relative;
        flex-basis: 100%;
          &.disabled {
          background-color: $color-grey-1;
          border-color: $color-grey-2;
          opacity: .5;
          pointer-events: none;
          input{
            &:disabled {
              background-color: $color-grey-1;
              border-color: $color-grey-2;
              opacity: .5;
              pointer-events: none;
            }
          }
        }
        &__name {
          color: $color-grey-6;
          font-size: 14px;
          font-weight: 500;
          margin-bottom: 10px;
        }
        &__field-wrapper {
          display: flex;
          align-items: center;
          .input {
            color: $color-grey-6;
            background-color: $color-white;
            border: 1px solid $color-grey-2;
            height: 50px;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            font-size: 14px;
          }
          .currency {
            font-size: 14px;
            color: $color-grey-4;
            position: absolute;
            z-index: 1;
            left: 8px;
          }
          .input-error {
            border: 1px solid $color-red-main;
            margin: 0;
          }
        }
        &__error {
          font-size: 12px;
          color: $color-red-main;
          padding-right: 10px;
        }
      }
      &__close {
        font-size: 18px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        color: $color-grey-6;
      }
    }
    .message {
      .hubx-inputs {
        padding: 2rem 0;
        textarea {
          background-color: $color-white;
          resize: none;
          height: 12rem;
        }
      }
    }
    .deal-closed {
      font-size: 1.4rem;
      color: $color-grey-6;
      font-weight: 500;
    }
  }
  .my-dropdown-dd {
    position: relative !important;
  }
  .accordion {
    height: auto;
    background-color: $color-white;
    color: $color-blue-main;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    font-weight: 300;
    cursor: pointer;
    width: 100%;
    padding: 0.5rem 0 0.6rem 0;
    &:hover {
      color: $color-blue-dark;
      cursor: pointer;
    }
    &__deal {
      display: flex;
      flex-direction: column;
      width: calc(100% - 2.8rem);
      &__title {
        padding-left: 3rem;
        .toggle {
          display: inline-block;
          width: .5rem;
          .fa {
            font-weight: bold;
          }
        }
        .label {
          padding-left: 2rem;
        }
      }
      &__info {
        display: flex;
        flex-direction: row;
        padding-left: 6rem;
        padding-bottom: 1.5rem;
        @include breakpoint(mobileScreen) {
          flex-wrap: wrap;
        }
        .item {
          font-size: 14px;
          color: $color-grey-4;
          padding-right: 2rem;
          &__name {
            font-weight: 500;
          }
          &__value {
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }
    }
  }
}
