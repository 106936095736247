.tag {
  padding: 10px 25px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.05);
  @include breakpoint (smallLaptop) {
    padding: 7px 20px;
  }
  &-input {
    border-radius: 5px;
    background-color: $darkerWhite;
    border: solid 1px $investmentBorder;
    padding: 10px 10px 0 10px;
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-wrap: wrap;
    max-height: 16.5rem;
    overflow-y: auto;
    @include breakpoint (smallLaptop) {
      padding: 5px 5px 0 5px;
    }
    button {
      position: absolute;
      width: 60px;
      height: 40px;
      border-radius: 5px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px $brand;
      background-color: $brand;
      font-size: 1.2rem;
      text-align: center;
      padding: 0;
      color: $white;
      left: auto;
      right: 10px;
      bottom: auto;
      @include breakpoint (smallLaptop) {
        width: 40px;
        height: 30px;
        right: 5px;
      }
      &.disabled {
        opacity: 0.3;
        cursor: text;
      }
    }
    &-content {
      display: flex;
      flex-basis: 88%;
      flex-wrap: wrap;
      @include breakpoint (smallLaptop) {
        flex-basis: 86%;
      }
      .input-group {
        border-radius: 0;
        box-shadow: none;
        border: none;
        background-color: transparent;
        padding: 0;
        flex-grow: 2;
        input {
          border: none;
          background: inherit;
          outline: none;
          height: 40px;
          font-size: 1.2rem;
          width: 100%;
          &::placeholder {
            font-style: italic;
            color: $investmentTitle;
          }
          @include breakpoint (smallLaptop) {
            height: 30px;
          }
        }
      }
      &-tags {
        padding-left: 10px;
        padding-right: 10px;
        height: 40px;
        border-radius: 5px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        background-color: $white;
        border: solid 1px $smallBlockBorder;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-right: 7px;
        margin-bottom: 10px;
        @include breakpoint (smallLaptop) {
          height: 30px;
          margin-right: 5px;
          margin-bottom: 5px;
        }
        .user-image {
          width: 19px;
          height: 19px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          border: 1px solid #f2f2f2;
          position: relative;
          background: $color-white;
          margin-right: 5px;
          img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        span {
          font-size: 1.3rem;
          font-weight: 500;
          color: $black;
        }
      }
    }
  }
}
