.do-billing{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &__holder{
    min-height: calc(100vh - 38rem);
    max-height: calc(100vh - 38rem);
    overflow: hidden;
    overflow-y: auto;
    padding: 1.7rem;
  }
  .todo-widget__block-right-info{
    width: 25rem;
    .seats-investor-name {
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
  &__box{
    background-color: $white;
    width: 100%;
    flex-basis: 100%;
    position: relative;
    @include breakpoint(tabletLandscape) {
      padding: 0.2rem;
    }
    &-header{
      display: flex;
      justify-content: space-between;
      padding: 1.7rem 1.7rem 2rem;
      position: relative;
      align-items: center;
      &:after{
        display: block;
        content: '';
        height: 1px;
        background-color: $borderColor;
        left: -2rem;
        bottom: 0rem;
        right: -2rem;
        position: absolute;
      }
      .todo-widget__filters-older{
        text-transform: uppercase;
      }
    }
    &-first{
      width: 17%;
      flex-basis: 17%;
      padding: 0;
      background-color: transparent;
      > div{
        background-color: $white;
        margin-bottom: 2rem;
        padding: 1rem 1.5rem;
      }
      .do-billing__box-header{
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        &:after{
          left: -1.5rem;
          right: -1.5rem;
        }
        h2{
          font-size: 1.1rem;
          text-transform: uppercase;
          font-weight: 500;
        }
      }
      .do-billing__box-body{
        &-item{
          display: flex;
          justify-content: space-between;
          font-size: 1.1rem;
          align-items: center;
          color: $black;
          margin-bottom: 1rem;
          border-bottom: 1px solid $borderColor;
          padding-bottom: 1rem;
        }
        .do-billing__box-body-item-subtotal{
          .do-billing__box-body-item-info,
          .do-billing__box-body-item-price {
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
        .do-billing__box-body-item-credit{
          .do-billing__box-body-item-info,
          .do-billing__box-body-item-price {
              color: $smallColor;
          }
        }
        .do-billing__box-body-item-total{
          border: 0;
          .do-billing__box-body-item-info,
          .do-billing__box-body-item-price {
            font-size: 1.3rem;
            text-transform: uppercase;
            font-weight: bold;
            > span{
              display: block;
              color: $smallColor;
              font-size: 1rem;
              text-transform: none;
              font-weight: 400;
            }
          }
        }
      }
      .do-billing__box-footer{
        button {
          background-color: $brand;
          color: $white;
          padding: 1.5rem 2rem;
          text-transform: uppercase;
          cursor: pointer;
          font-family: $regular;
          border: 0;
          font-size: 1rem;
          border-radius: 2px;
          outline: none;
          display: block;
          width: 100%;
          @include transition(.3s all ease-in-out);
          &:hover {
            background-color: $darkerBrand;
          }
        }
      }
    }
    h2{
      letter-spacing: 0;
      text-transform: none;
      font-weight: 600;
      font-size: 1.8rem;
      margin: 0;
      position: relative;
    }
    .todo-widget__block-right{
      width: 100%;
      flex-basis: 100%;
      padding: 2rem;
      box-sizing: border-box;
      margin-bottom: 2rem;
    }
    .todo-widget__block-right-info:after{
      display: none;
    }
    .action__buttons{
      margin-left: auto;
      border-radius: 5px;
      font-weight: 500;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      i{
        font-size: 1.6rem;
      }
    }
    .border{
      border: 1px solid $borderColor;
      margin-left: 3rem;
      padding: 0.7rem 1rem;
      cursor: pointer;
    }
    .todo-widget__filters-older ul{
      right: 0;
      left: 0;
    }
    &-invoices{
      .media__image{
        width: 3rem;
      }
      .media__buttons{
        padding-right: 0rem;
        flex-grow: 1;
        button{
          text-transform: uppercase;
        }
      }
    }
  }
  &__seats-disabled{
    font-size: 0.9rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $smallColor;
    line-height: 1rem;
    margin: 4px 0 0 0;
  }
  &__button{
    background: white;
    padding: 2.6rem;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.4rem;
    color: $brand;
    border-top: 1px solid $borderColor;
    font-weight: 500;
    cursor: pointer;
    @include transition (.3s all ease-in-out);
    &:hover{
      background-color: $brand;
      color: $white;
    }
  }
  &__dropdown{
    margin-bottom: 5rem;
    .todo-widget__filters-older ul{
      left: 0;
    }
  }
  .billing-box{
    span{
      color: $smallColor;
      display: block;
    }
    .do-billing__box-body-item{
      flex-wrap: wrap;
      border-bottom: 0;
      margin-bottom: 0;
      span{
        width: 100%;
        line-height: 1.8rem;
      }
    }
  }
  .referral-box{
    .do-billing__box-body-item{
      border-bottom: 0;
      margin-bottom: 0;
    }
    span{
      text-transform: uppercase;
      font-weight: 600;
    }
  }
}
.do-details{
  max-height: calc(100vh - 15rem);
  overflow: hidden;
  overflow-y: auto;
  .layer-body__top-single{
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .multiselect--active{
    .multiselect__select{
      top: 1.3rem !important;
    }
  }
  .common__modalBox{
    margin-bottom: 2rem;
    margin-top: 0 !important;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &-item{
    background-color: $white;
  }
  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $borderColor;
    padding: 0.5rem 1.5rem;
    h2{
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 400;
    }
    &-total{
      font-size: 1.3rem !important;
      font-weight: bold;
      color: $black;
    }
  }
  &__body{
     padding: 1.5rem;
    .do-details__body-price{
      font-size: 1.1rem;
      font-weight: 400;
      text-transform: uppercase;
    }
    .do-details__body-green{
      color: $positive;
    }
    .do-details__body-red{
      color: $red;
    }
    &-item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      &-left{
        width: 80%;
        flex-basis: 80%;
      }
      &:last-child{
        margin-bottom: 0;
      }
      .do-details__body-title{
        display: block;
        font-weight: 500;
        color: $black;
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }
      .do-details__body-info{
        color: $smallColor;
        font-weight: 400;
        font-size: 1.1rem;
      }
    }
  }
  &__footer{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 2rem 4rem;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .do-details__footer-info,
    .do-details__footer-price{
      font-size: 1.3rem;
      text-transform: uppercase;
      font-weight: bold;
      color: $black;
      > span{
        display: block;
        color: $smallColor;
        font-size: 1rem;
        text-transform: none;
        font-weight: 400;
      }
    }
  }
  form{
    padding: 0;
  }
  .tabs-content{
    padding: 2rem;
    background-color: $white;
  }
  .form__body{
    margin-top: 2rem;
    display: none;
    &.showForm{
      display: block;
    }
  }
  .form-actions{
    button{
      position: relative;
      left: 0;
      right: 0;
      width: auto;
      background: $brand;
      color: $white;
      margin: 2rem 0 0;
      padding: 1.5rem 5rem;
      font-weight: 400;
      @include transition(.3s all ease-in-out);
      &:hover{
        background-color: $darkerBrand;
      }
    }
  }
  @include radioGroupMixin;
}
