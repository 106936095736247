.dataRoom {
  display: block;
  @include breakpoint(smallLaptop){
    width: auto;
  }
  @media screen and (max-width: 1024px) {
    width: auto;
  }
  p {
    font-size: 1.4rem;
    line-height: 1.86;
    color: $tagBackground;
    font-family: "Montserrat", sans-serif;
    padding-bottom: 4rem;
    &.changes {
      font-size: 1.2rem;
      line-height: 1.83;
      margin-top: -3rem;
    }
  }
  &-btn {
    padding: 1.5rem 5rem;
    border-radius: 5px;
    background: $brand;
    font-size: 1.1rem;
    text-transform: uppercase;
    color: $white;
    font-weight: 500;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    &.disabled {
      background: $smallBlockBorder;
      cursor: default;
      color: $grayColor;
    }
    &.investor-btn {
      float: left;
      margin-top: 30px;
      letter-spacing: 0.4px;
      border-radius: 3px;
      padding: 15px 40px;
    }
  }
  .do-dataroom {
    &__body {
      &-right {
        &-block {
          &.dealEdit {
            max-height: calc(100vh - 35rem) !important;
          }
          .audit {
            &__container {
              &.dealEdit {
                height: calc(100vh - 20rem) !important;
              }
            }
          }
        }
      }
    }
    &__access {
      &-item-name {
        a {
          color: $black;
          text-decoration: none;
        }
      }
      &-block {
        &.dealEdit {
          max-height: calc(100vh - 16rem) !important;
        }
      }
    }
  }
  .access-info {
    p {
      margin: 15px !important;
    }
  }
}