.modal-pdf-wrapper {
  @media (max-width: 1199px) {
    .layer-header {
      padding: 1rem;
    }
  }
}
.modal-pdf {

  h1.header-pdf {
    margin: 1.5rem 0px 2.5rem;
    word-break: break-word;
    overflow-wrap: break-word;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #pdf, #pdf-img {
    position: relative;
    height: calc(100vh - 20rem);
    overflow: hidden;
    overflow-y: scroll;

    .page-canvas {
      display: block;
      border: 0.5px solid $pdfPageBorder;
      width: calc(100% - 2px);
    }

    .full-view {
      position: fixed;
      font-size: 30px;
      bottom: 9vh;
      right: 70px;
      padding: 5px;
      border-radius: 50%;
      background-color: $white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      @include breakpoint(smallLaptop) {
        bottom: 8.5vh;
      }
      @media screen and (min-width:1400px) and (max-width:1600px) {
        bottom: 8.5vh;
      }
      @include breakpoint(bigScreen) {
        bottom: 10.5vh;
      }
      @include breakpoint(hugeScreen) {
        bottom: 10.5vh;
        right: 95px;
      }
    }

    @include breakpoint(desktop) {
      height: calc(100vh - 170px);
    }
    @include breakpoint(smallLaptop) {
      height: calc(100vh - 180px);
    }
    @media screen and (min-width:1400px) and (max-width:1600px) {
      height: calc(100vh - 190px);
    }
    @include multiple-media(bigLaptop, bigScreen) {
      height: calc(100vh - 23rem);
    }
    .pdf-button {
      cursor: pointer;
    }
    @media only screen and (max-width: 600px) {
      .pdf-button {
        display: none;
      }
    }
  }
  #pdf::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: $pdfScrollBar;
  }
  #pdf::-webkit-scrollbar-thumb {
    background: $pdfScrollBarThumb;
  }
  .download {
    font-size: 2rem;
    color: $brand;
    padding: 11px;
    margin-bottom: 6px;
    &:hover {
      background-color:$pdfDownloadHover;
      border-radius: 50%;
      cursor: pointer;
    }
  }
  .preview-image {
    width: 100%;
  }

  .modal-layer-container{
    left: calc(100% - 680px);
    @media screen and (min-width: 600px) and (max-width: 900px) {
      left:calc(100% - 580px);
    }
    @include breakpoint(mobileScreen) {
      left: 0;
    }
    &.is-expanded {
      left: calc(100% - 1600px);
      @include multiple-media(mediumScreen) {
        left: calc(100% - 1100px);
      }
      @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
        left: 0;
      }
    }
  }
}
.modal-pdf-tooltip {

  .tooltip-inner {
    font-size: 1.2rem !important;
    font-family: Montserrat, sans-serif;
    font-weight: 600;
    color: $white;
    border-radius: 3px;
    padding: 3px 4.5px !important;
  }
}
.save-modal-option {
  top: 0 !important;
}


