.blind-deal-compact {
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	@include breakpoint(largeScreen) {
		width: 1440px;
		margin: 0 auto;
	}

	.compact-description-container {
		display: flex;
		flex-direction: column;
		height: 100%;
		overflow-y: auto;
		overflow-x: hidden;
		flex-grow: 1;
		@media (overflow-block: scroll) {
			.compact-description-block {
				box-shadow: none;
			}
		}
		@include multiple-media(mediumScreen, largeScreen) {
			padding-top: 54px;
			flex-direction: row;
		}

		@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
			margin-top: 60px;
		}

		.compact-intro {

			@include multiple-media(mediumScreen, largeScreen) {
				position: fixed;
			}
		}

		.compact-description-block {
			background-color: $color-white;
			flex: 1 100%;
			box-shadow: -0.6rem 0 1.1rem 0 rgba(0, 0, 0, 0.1);
			align-self: stretch;
			display: flex;
			flex-direction: column;

			@include multiple-media( mediumScreen, largeScreen) {
				margin-left: 45rem;
			}

			&.no-shadow {
				box-shadow: none;
			}
			&__description {
				flex-grow: 1;

				&__title {
					font-size: 2rem;
					font-weight: 300;
					color: $color-blue-main;
					padding: 2rem;
					@include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
						padding: 2rem 3rem;
					}
				}
				&__tagline {
					font-size: 1.4rem;
					color: $color-grey-6;
					margin: 0;
					padding: 10px 30px;
					background-color: $color-compact-background;
					line-height: 1.86;
				}
				&__files {
					padding: 0 30px;
					background-color: $color-compact-background;

					@extend .description-file-container;
					.file-container {
						&:first-child {
							margin-top: 0;
						}
					}
				}
			}
			&__interest {
				&__access {
					font-size: 1.4rem;
					color: $color-grey-6;
					margin: 0;
					padding: 2rem 2rem 0 2rem;
					line-height: 1.86;

					.accent-color {
						color: #58b100;
					}
				}
				&__actions {
					display: flex;
					flex-wrap: wrap;
					padding: 2rem 3rem;
					@include multiple-media(smallScreen, mediumScreen, largeScreen) {
						display: flex;
						flex-wrap: wrap;
						flex-direction: row;
						align-items: center;
					}

					.request-access,
					.not-interested {
						min-width: 100%;
						height: 100%;
						padding: 1.2rem 2.2rem;
						border-radius: .3rem;
						outline: 0;
						border: 0;
						color: $color-white;
						font-weight: 500;
						font-size: 1.3rem;
						text-transform: uppercase;
						font-family: $regular;
						&:hover {
							cursor: pointer;
						}

						@include multiple-media(smallScreen, tabletScreen) {
							min-width: calc(50% - 1rem);
							&:last-child {
								margin-right: 0;
							}
						}
						@include multiple-media(mediumScreen, largeScreen) {
							min-width: 18rem;
							margin: 0 1rem 0 0;
						}
					}
					.request-access {
						background-color: $color-green-dark-1;
						transition: all .25s ease-in-out;
						margin-bottom: 1rem;
						margin-left: 0;
						&:hover {
							background-color: $color-green-compact-hover;
						}
						@include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
							margin-right: 1rem;
							margin-bottom: 0;
						}
					}
					.selection-button {
						position: relative;
						display: inline-block;
						width: 100%;
						margin-bottom: 1rem;
						border-radius: 3px;

						&:hover .not-interested:not(.disabled) {
							background-color: $color-red-dark;
						}

						@include multiple-media(smallScreen, tabletScreen) {
							width: calc(50% - .5rem);
							margin-right: 0;
							margin-bottom: 0;
						}
						@include multiple-media(mediumScreen, largeScreen) {
							width: 100%;
							max-width: 18rem;
							margin-bottom: 0;
						}
						.not-interested {
							background-color: $color-red-main;
							margin-right: 0;
							margin-left: 0;
							transition: all .25s ease-in-out;

							@include multiple-media(smallScreen, tabletScreen) {
								width: 100%;
							}
							&:hover {
								+ .dropdown {
									display: block;
								}
							}
						}
						.dropdown {
							display: none;
							position: absolute;
							box-sizing: border-box;
							width: 100%;
							left: 0;
							bottom: 100%;
							background-color: $pendingBackground;
							text-align: center;
							font-weight: 500;
							letter-spacing: 0.4px;

							&:hover {
								display: block;
							}
							&__list {
								list-style: none;
								margin: 0;
								padding: 0;
								box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
								&__item {
									color: $darkBlack;
									padding: 12px;
									text-decoration: none;
									display: block;
									text-transform: uppercase;
									font-size: 14px;
									cursor: pointer;
									&:hover {
										cursor: pointer;
										background-color: $color-white-1;
										font-weight: 500;
									}
									&:last-child {
										border-bottom: 0;
									}
								}
							}
						}
					}
				}
				.interest-status {
					display: flex;
					margin: auto;
					font-family: $regular;
					font-size: 1.4rem;
					padding: 0 3rem 2rem;
					text-align: center;
					&__message {
						margin: 0;
						display: flex;
						text-align: center;
						justify-content: center;
						flex-direction: column;
						color: $color-green-dark-1;
					}
				}
			}
		}
	}
}
