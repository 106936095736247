.todo-widget {
    &__filters{
        display: inline-block;
        vertical-align: middle;
        float: right;
        margin-top: 1rem;
        font-size: 1.2rem;
        text-transform: uppercase;
        font-weight: 600;
        .layer-body__middle-filters-select {
            display: inline-block;
        }
        &-task{
            @extend .buttonBackground;
            margin-right: 3rem;
            cursor: pointer;
        }
        &-older{
            cursor: pointer;
            position: relative;
            font-size: 1.2rem !important;
            font-weight: 600 !important;
            > span{
                display: block;
                width: 100%;
            }
            i{
                margin-left: 1rem;
                display: inline-block;
                font-size: 1.2rem;
            }
            ul{
                position: absolute;
                right: 0;
                top: 0;
                padding: 0;
                left: auto;
                list-style: none;
                border: 1px solid $smallBlockBorder;
                border-radius: 0;
                background-color: $white;
                z-index: 2;
                min-width: 10rem;
                li{
                    border-bottom: 1px solid $smallBlockBorder;
                    padding: 1rem;
                    @include transition (.3s all ease-in-out);
                    &:last-child{
                        border-bottom: 0;
                    }
                    &:hover{
                        color: $brand;
                        background-color: $darkerWhite;
                    }
                }
            }
        }
    }
    &__upper-section {
        display: flex;
        align-items: center;
        background-color: $color-white;
        flex-wrap: wrap;
        margin-bottom: 0;
        position: relative;
        flex-direction: row;
        @include multiple-media (mobileScreen, tabletScreen) {
            flex-direction: column;
            align-items: normal;
        }
        .todo-widget-left-wrapper {
            width: 14rem;
            padding-left: 2.5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &__title {
                font-size: 1.2rem;
                color: $color-grey-6;
                font-weight: 500;
                margin-bottom: 0.5rem;
            }
            &__info {
                font-size: 1rem;
                font-weight: 500;
                color: $color-grey-4;
            }
            @include breakpoint (smallScreen) {
                width: 135px;
            }
            @include multiple-media (mobileScreen, tabletScreen) {
                width: calc(100% - 3rem);
                border-bottom: 1px solid $smallBlockBorder;
                border-right: none;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                height: 35px !important;
                padding: 0 1.5rem;
            }
        }

        .todo-widget-right-wrapper {
            display: flex;
            flex-direction: row;
            width: calc(100% - 21.15rem);
            padding: 2rem 1.5rem 2rem 3rem;
            border-left: 1px solid $smallBlockBorder;
            @include breakpoint (mobileScreen) {
                flex-direction: column;
            }
            @include multiple-media (mobileScreen, tabletScreen) {
                width: calc(100% - 3rem);
                border-left: none;
                padding: 2rem 1.5rem 2rem 1.5rem;
            }
            @include breakpoint (smallScreen) {
                width: calc(100% - 23rem);
            }

            &__content {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                @include multiple-media (mobileScreen, tabletScreen, smallScreen) {
                    flex-direction: column;
                }
            }
            .widget-block {
                display: flex;
                align-items: flex-start;
                min-height: 4rem;
                position: relative;
                width: calc(50% - 5rem);
                @include multiple-media(mobileScreen, smallScreen) {
                    width: 100%;
                }
                @include multiple-media (mobileScreen, tabletScreen) {
                    width: calc(100% - 1rem);
                }
                &__links-wrapper {
                    display: flex;
                    flex-direction: column;
                    padding-left: 2rem;
                    min-height: 40px;
                    justify-content: center;
                    background-color: $color-white;
                    @include breakpoint(smallScreen) {
                        padding-left: 1.5rem;
                    }
                }
                &__image-syndicated {
                    position: relative;
                    z-index: 0;
                    img {
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        z-index: 1;
                        top: -50px;
                        left: -5px;
                    }
                }
                .widget-link {
                    margin-bottom: 0.5rem;
                    color: $color-grey-6;
                    font-size: 13px;
                    font-weight: 500;
                    word-break: break-word;
                    &:hover {
                        color: $color-blue-main;
                    }
                    &--disabled {
                        cursor: default;
                        margin: 0;
                        &:hover {
                            color: $color-grey-6;
                        }
                    }
                    &--grey {
                        font-size: 10px;
                        font-weight: 500;
                        color: $color-grey-4;
                        &:hover {
                            color: $color-grey-4;
                        }
                    }
                }
                .investor-info-user {
                    margin-left: 2rem;
                }
            }
            .action__buttons {
                display: flex;
                justify-content: flex-end;
                min-width: 150px;
                @include breakpoint(mobileScreen) {
                    margin-left: 0;
                    margin-top: 2rem;
                    height: 20px;
                    justify-content: flex-start;
                }
                .button {
                    margin-left: 0;
                }
            }
            .media-image {
                width: 40px;
                height: 40px;
                object-fit: scale-down;
                border: 1px solid $dealBlockColor;
                box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
            }
        }

        .show-arrow {
            width: 50% !important;
            @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
                position: relative;
                width: calc(100% - 7rem) !important;
                top: 1rem;
                left: 6rem;
            }
            &:before {
                background-image: url('/images/arrow-right.png');
                position: absolute;
                background-size: 100%;
                background-repeat: no-repeat;
                content: '';
                width: 1.7rem;
                height: 1.7rem;
                top: 1.5rem;
                left: -4.5rem;
            }
        }

        .button__approve {
            color: $color-blue-main;
            font-size: 1.2rem;
            &:hover{
                color: $darkerBrand;
            }
        }
        .confirm-task {
            margin-right: 5px;
        }
    }
    &__lower-section {
        background-color: $color-white;
        &-table {
            border-top: 1px solid $lightGray;
            padding: 2rem;
            &-cell {
                display: flex;
                margin-bottom: 1rem;
            }
            &-header{
                margin-bottom: 1rem;
                &-cell{
                    font-size: 1.1rem;
                    color: $smallColor;
                    font-weight: 600;
                    margin-right: 1rem;
                    display: inline-block;
                    width: 6.5rem;
                    margin-bottom: 0.7rem;
                    min-width: 2rem;
                }
            }
            &-body{
                display: block;
                vertical-align: top;
                max-width: 100%;
                line-height: 1.83;
                p {
                    margin-bottom: 0;
                    font-weight: 400;
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    color: $color-grey-6;
                    margin-top: 1rem;
                    white-space: pre-wrap;
                }
                &-info {
                    display: flex;
                    align-items: center;
                    margin-bottom: .5rem;
                    span {
                        color: $color-grey-6;
                        font-size: 1.3rem;
                        font-weight: 600;
                        text-transform: uppercase;
                        &:first-child{
                            min-width: 6.2rem;
                            margin-right: 1rem;
                        }
                    }
                }
                &-bigger{
                    display: block;
                    vertical-align: middle;
                    .bar{
                        width: 7rem;
                        height: 1.1rem;
                        display: inline-block;
                        vertical-align: top;
                        margin-right: 1rem;
                        &__blue{
                            background-color: $color-blue-main;
                        }
                        &__green{
                            background-color: $color-green-main;
                        }
                    }
                }
                &-cell{
                    margin-right: 1rem;
                    font-size: 1rem;
                    vertical-align: middle;
                    color: $color-grey-6;
                    font-weight: 500;
                    text-align: left;
                    display: block;
                    min-width: 6.2rem;
                }
                &-bar{
                    width: calc(100% - 12.4rem);
                }
                &-cell-right{
                    color: $color-green-main;
                    font-size: 1.3rem;
                    font-weight: 500;
                }
            }
        }
    }
    &_deal-feedback-values {
        display: flex;
        flex-wrap: wrap;
        background-color: $color-grey-6;
        font-weight: 500;
        font-size: 1.1rem;
        > div {
            flex: 1 0 16.666%;
            height: 50px;
            color: $color-grey-4;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 320px) {
                flex: 1 0 100% !important;
            }
            @include breakpoint(mobileScreen) {
                flex: 1 0 50%;
            }
            @include multiple-media (tabletScreen, smallScreen) {
                flex: 1 0 33%;
            }
            > span{
                color: $color-white;
                margin-left: 12px;
                margin-right: 6px;
            }
        }
    }

    .widget-header {
        display: flex;
        justify-content: space-between;
        margin: 3rem 0;
        @include multiple-media (tabletScreen, smallScreen, mediumScreen, largeScreen) {
            margin: 4rem 0;
        }
        .dropdown-accordion {
            cursor: pointer;
            position: relative;
            font-size: 1.2rem;
            font-weight: 600;
            .fa {
                margin-left: 1rem;
                display: inline-block;
                font-size: 1.2rem;
            }
            .my-dropdown-dd {
                position: relative !important;
            }
            &__filter {
                position: absolute;
                right: 0;
                top: 0;
                padding: 0;
                left: auto;
                list-style: none;
                border: 1px solid $smallBlockBorder;
                border-radius: 0;
                background-color: $color-white;
                z-index: 2;
                min-width: 12rem;
            }
            &__filter-item {
                border-bottom: 1px solid $smallBlockBorder;
                padding: 1rem;
                @include transition (.3s all ease-in-out);
                &:last-child{
                    border-bottom: 0;
                }
                &:hover{
                    color: $color-blue-main;
                    background-color: $color-grey-1;
                }
            }
        }
        &__title {
            font-size: 2.4rem;
            font-weight: bold;
            color: $color-grey-6;
            font-family: $regular;
            letter-spacing: 2px;
            margin: 0;
            @include transition(.3s all ease-in-out);
        }
        &__filters {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 1.2rem;
            text-transform: uppercase;
            font-weight: 600;
            @include breakpoint (mobileScreen) {
                display: none;
            }
        }
        &__add-task {
            @extend .buttonBackground;
            margin-right: 3rem;
            cursor: pointer;
        }
    }
    .widget-body {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3.5rem;
        .btn-transparent {
            background-color: transparent;
            font-weight: 600;
            max-width: 150px;
            display: flex;
            align-self: center;
            &:hover {
                color: $color-white;
                background-color: $color-blue-main;
            }
            &:focus {
                outline: none;
            }
        }
        .widget-left-section {
            width: 210px;
            overflow-y: auto;
            height: 100%;
            @include multiple-media (mobileScreen, tabletScreen) {
                display: none;
            }
            &__list {
                padding-left: 0;
                list-style: none;
                margin: 0;

            }
            &__list-item {
                font-size: 1.2rem;
                padding: 1.4rem 1.8rem;
                font-weight: 500;
                border-radius: 3px;
                cursor: pointer;
                border: 1px solid transparent;
                @include transition (.3s all ease-in-out);
                &:hover {
                    background-color: $color-white;
                    border: 1px solid $lightGray;
                }
                &.active {
                    background-color: $color-white;
                    color: $color-blue-main;
                    border: 1px solid $lightGray;
                    font-weight: bold;
                    padding: 1.4rem 1.8rem;
                }
            }
        }
        .widget-right-section {
            width: calc(100% - 235px);
            @include transition(.1s all ease-in-out);
            display: flex;
            flex-direction: column;
            @include multiple-media (mobileScreen, tabletScreen) {
                width: 100%;
            }
            .empty-content {
                text-align: center;
                margin-top: 5rem;
                font-size: 1.4rem;
            }
        }
    }

    .action__buttons {
        margin-left: auto;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 1.2rem;
        a {
            cursor: pointer;
            font-weight: 600;
        }
    }

    .user-task-text {
        &:hover {
            cursor: default;
        }
    }
    .box-shadow {
        margin-bottom: 20px;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid $smallBlockBorder;
    }
    .my-task {
        &__note {
            padding: 2rem;
            background-color: $color-white;
            border-top: 1px solid $smallBlockBorder;
        }
        .note-link {
            color: $color-grey-6;
            font-size: 1.2rem;
            cursor: pointer;
            word-break: break-all;
        }
    }
    .investor-profile {
        .widget-block {
            @-moz-document url-prefix() {
                @include breakpoint(smallScreen) {
                    width: 100%;
                }
            }
        }
        .button__approve{
            color: $brand;
            font-size: 1.2rem;
            &:hover{
                color: $darkerBrand;
            }
        }
    }
    .suggestion-gdpr {
        @include breakpoint(mobileScreen) {
            .widget-block__links-wrapper {
                padding-left: 0;
            }
        }
    }
    .suggestions{
        &.doneTask{
            .media__info-title{
                color: $smallColor;
                text-decoration: line-through;
            }
        }
        .action__buttons {
            .button__approve{
                color: $brand;
                font-size: 1.2rem;
                font-weight: 600;
                &:hover{
                    color: $darkerBrand;
                }
            }
        }
    }
    &__block {
        display: flex;
        align-items: center;
        background-color: $color-white;
        border-radius: 5px;
        margin-bottom: 2rem;
        flex-wrap: wrap;
        position: relative;
        flex-direction: row;
        @include multiple-media (mobileScreen, tabletScreen) {
            flex-direction: column;
            align-items: normal;
        }
        &--no-padding {
            padding: 0;
        }
        &-child {
            position: relative;
            width: 100%;
            padding: 2rem;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            &:before{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 1px;
                content: '';
                display: block;
                background-color: $color-grey-1;
            }
        }
        &-info {
            font-size: 1.2rem;
            font-weight: 500;
            display: block;
            margin-bottom: 0.5rem;
            color: $black;
        }
        &-date {
            color: $smallColor;
            font-size: 1rem;
            font-weight: 500;
        }
        &-desc {
            color: $black;
            padding-right: 0;
            font-size: 1.2rem;
            white-space: pre-line;
            a {
                color: $black;
                font-size: 1.2rem;
                &:visited {
                    color: $black;
                }
            }
        }
        &-left{
            width: 20%;
            flex-basis: 20%;
            @include breakpoint(smallLaptop) {
                flex-basis: 20%;
                @-moz-document url-prefix() {
                    flex-basis: 17%;
                }
            }
            position: relative;
        }
        &-right{
            &-company{
                margin-left: 5rem;
                width: 100%;
                @media (min-width: 600px) and (max-width: 1200px) {
                    width: 85% !important;
                }
                .media {
                    &__info {
                        width: calc(100% - 40px);
                        @include breakpoint (mobileScreen) {
                            width: calc(100% - 100px);
                        }
                    }
                    &__image {
                        width: 40px;
                        height: 40px;
                    }
                }
                @include breakpoint(smallLaptop) {
                    @-moz-document url-prefix() {
                        margin-left: 4rem;
                    }
                }
            }
            &-info {
                position: relative;
                width: 21rem;
                @include breakpoint(smallLaptop) {
                    @-moz-document url-prefix() {
                        width: 15rem;
                    }
                }
                &:after{
                    background-image: url('/images/arrow-right.png');
                    position: absolute;
                    right: -2rem;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    content: '';
                    width: 1.8rem;
                    height: 1.8rem;
                    display: block;
                    top: 35%;
                }
            }
        }
        &-bottom{
            font-weight: 600;
            line-height: 1.6rem;
            color: $black;
        }
    }
    &__company-approval {
        .action__buttons{
            .button__approve:first-child {
                margin-right: 10px;
            }
        }
    }
}

.my-dropdown-dd{
  position: inherit !important;
  left: 0 !important;
  right: 0 !important;
  top: inherit !important;
  bottom: inherit !important
}

.user-task-title:hover {
    color: $black;
    cursor: default;
}
