.equity-deal-wrapper-print {
  background-color: $white;
  .equity-deal-print-content,
  .equity-deal-print-terms,
  .equity-deal-print-deal-purpose,
  .equity-deal-print-deal-documentation {
    padding: 10rem 5rem;
    width: 1000px;
    margin: 0 auto;
  }
  .equity-deal-print-header {
    img {
      height: 500px;
      width: 100%;
    }
    &__content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;
      img {
        width: 40px;
        height: 40px;
        object-fit: scale-down;
      }
    }
  }
  p, span, li {
    font-size: 1.4rem;
  }
}
@media print {
  .equity-deal {
    display: none;
  }
  .layer-mask {
    position: relative !important;
    overflow-y: scroll;
    background-color: $white;
  }
  .deal__preview {
    .layer-container,
    .layer-body {
      background-color: $white;
      position: relative !important;
    }
  }

  .equity-deal-wrapper-print {
    display: block !important;
    .equity-deal-print-header {
      &__background {
        height: 400px;
        width: 100%;
      }
      &__content {
        display: flex;
        flex-direction: row;
        justify-content: left;
        height: 60px;
        margin-top: 20px;
        img {
          width: 50px;
          height: 50px;
          object-fit: scale-down;
          margin-right: 10px;
        }
        h3 {
          margin: 0;
          height: 50px;
          align-self: center;
        }
      }
    }
    .equity-deal-print-content {
      width: 100%;
      margin: unset;
      padding: unset;
      page-break-before: auto;
      page-break-after: auto;
      page-break-inside: avoid;
      h3 {
       text-align: left;
       margin: 30px 0;
      }
      div {
        display: flex;
        flex-direction: row;
        margin: 5px 0;
        span {
          font-size: 12pt;
        }
        .item-name {
          padding-right: 10px;
        }
      }
    }
    .equity-deal-print-terms,
    .equity-deal-print-deal-purpose {
      width: 100%;
      margin: unset;
      padding: unset;
      page-break-before: auto;
      page-break-after: auto;
      page-break-inside: avoid;
      h3 {
        text-align: left;
        margin: 30px 0;
      }
      p {
        font-size: 12pt;
        margin: 0;
      }
      ul, ol {
        padding-inline-start: 30px;
        li {
          font-size: 12pt;
        }
      }
      table  {
        border-collapse: collapse;
        width: 100%;
        margin-top: 50px;
        thead {
          tr {
            td {
              font-size: 14pt;
              font-weight: 600;
            }
          }
        }
        tbody {
          tr {
            td {
              border: 1px solid $black;
              font-size: 12pt;
              font-weight: 500;
              color: $black;
              min-width: 20%;
              text-align: center;
              padding: 2rem;
              &:first-of-type {
                width: 80%;
                text-align: left;
              }
            }
          }
        }
      }
    }
    .equity-deal-print-deal-documentation {
      width: 100%;
      margin: unset;
      padding: unset;
      h3 {
        text-align: left;
        margin: 30px 0;
      }
    }
  }
}