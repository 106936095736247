.profile-tab.my-account-profile-block {
	overflow: hidden;
	.my-account-profile__block {
		&--left {
			@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
				box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .1);
			}
			.do-user__item-change span {
				@include multiple-media(mobileScreen, tabletScreen) {
					opacity: .6;
				}
			}
			.common__info-box {
				align-items: flex-start;
				display: flex;
				flex-direction: column;
			}
			.common__info-box-left {
				font-size: 1.3rem;
				color: 	#A8A8A8;
				font-weight: 500;
				margin-bottom: 5px;
			}
			.common__info-box-right {
				font-weight: 600;
				font-size: 1.3rem;
			}
		}
		&--right {
			padding: 0;
			border: 0;
			background-color: initial;
			box-shadow: initial;
			border-radius: 0;
			max-height: initial;
			margin-right: -.4rem;
			@include multiple-media(mobileScreen, tabletScreen) {
				margin-left: 0;
				box-sizing: content-box;
				overflow-x: hidden;
				padding-bottom: 10rem;
			}
			@include breakpoint(smallScreen) {
				margin-left: 2rem;
			}
			@include breakpoint(mobileScreen) {
				margin-right: .3rem;
			}
			@include multiple-media(smallScreen, mediumScreen, largeScreen) {
				max-height: calc(100% - 24rem);
				overflow: hidden;
				overflow-y: auto;
			}
			@media (max-width: 1024px) {
				margin-right: -3rem;
				padding-right: 2.7rem;
			}
			.do-user__item-content {
				padding: 2.5rem 0;
				border: .1rem solid $smallBlockBorder;
				border-radius: .3rem;
				background-color: $color-white;
				margin-bottom: 2rem;
				box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .1);
				max-width: 100%;
				@media (min-width:899px) {
					max-width: calc(100% - .4rem);
				}
				@include breakpoint(largeScreen) {
					max-width: calc(100% - .6rem);
				}
				@include multiple-media(mobileScreen, tabletScreen) {
					&:last-child {
						margin-bottom: .5rem;
					}
				}
				@media (min-width: 1024px) {
					&:last-child {
						margin-bottom: 0;
					}
				}
				.common__info-title {
					padding-left: 2.5rem;
					padding-bottom: 2rem;
				}
				.user-notification {
					padding-left: 2.5rem;
				}
				.col-layout {
					display:flex;
					flex-direction: column;
				}
				> div > p > .checkbox-container {
					width: fit-content;
				}
				.common__info-box {
					padding-left: 2.5rem;
					&:last-child {
						margin-bottom: 0;
					}
					&-left {
						@include multiple-media(mobileScreen, tabletScreen) {
							flex-basis: 100%;
							width: 100%;
						}
					}
					&-right {
						@include breakpoint(tabletScreen) {
							flex: 150%;
							width: 150%;
						}
                        .profile-edit-input {
                            width: 200px;
                            .hubx-input{
                                height: 10px;
                            }
                        }
                        .profile-edit-button {
                            text-decoration: underline;
                            color: $brand;
                            cursor: pointer;
                            &:hover {
                                color: $brandHover;
                            }
                        }
					}
				}
				.checkbox-container {
					> input {
						margin-right: 1rem;
						font-size: 1.2rem;
						cursor: pointer;
						@include breakpoint(mobileScreen) {
							margin-right: 1.5rem;
						}
					}
				}
			}
			.layer-body {
				overflow-y: initial;
				@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
					padding: 1.5rem 2rem 1rem;
					overflow: hidden;
				}
				&__top {
					height: 100%;
					align-items: initial;
					justify-content: flex-start;
				}
				.do-modal {
					height: 100%;
					.common__modalTitle {
						@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
							font-weight: 500;
							font-size: 1.4rem;
						}
					}
					.common__modalBox {
						height: 100%;
						overflow: hidden;
						overflow-y: auto;
						position: relative;
						display: block;
						max-height: calc(100vh - 24rem);
						@include breakpoint(mobileScreen) {
							max-height: calc(100vh - 19rem);
							@media screen and (max-height:745px) {
								max-height: calc(100vh - 29rem);
							}
							@supports (-webkit-overflow-scrolling: touch) {
								max-height: calc(100vh - 31rem);
							}
						}
						> .box {
							#plain_password,
							#new_password {
								@include multiple-media(mobileScreen, tabletScreen) {
									border-radius: .5rem;
								}
							}
							> label {
								font-weight: 500;
								font-size: 1rem;
							}
						}
						@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
							@include hide-scrollbar;
						}

						@media (min-width: 1024px) {
							margin-right: 0;
							padding-right: 0;
						}
						.change-password {
							margin-bottom: 0;
							@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
								box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .1);
							}
							.reset_text {
								@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
									font-size: 1.2rem;
									line-height: 1.83;
								}
							}
							.Password__container {
								@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
									margin-top: 2rem;
								}
								> label {
									@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
										font-size: 1.3rem;
										font-weight: 500;
									}
								}
							}
							.investor-account-btn {
								@include breakpoint(mobileScreen) {
									left: 0;
									width: 100%;
									z-index: 11;

								}
								&.input-gr-o {
									opacity: 1;
									color: transparentize($color-blue-main, .7);
									&:hover {
										cursor: default;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
.layer-container.modal-layer-container.advisor .layer-body__top {
	&-left {
		.common__modalTitle {
			font-size: 1.4rem;
			font-weight: 500;
		}
		.common__modalBox .box .common__modalInput {
			> label {
				font-weight: 500;
				font-size: 1rem;
			}
		}
	}
}
