.profile-experience {
  margin: 0;
  .do-user__item-content {
    .common__info-title {
      padding-left: 0 !important;
      padding-bottom: 1.8rem !important;
      font-size: 1.4rem !important;
    }
    .common__info-box {
      padding: 0 !important;
      .common__info-box-left {
        flex-basis: auto;
        width: auto;
        min-width: 25%;
        max-width: 90%;
        margin-right: 1.2rem;
      }
      .common__info-box-right {
        flex-basis: auto;
        width: auto;
        line-height: normal;
      }
    }
  }
}