.multiselect{
  &__input {
    padding: 2rem;
    font-size: 1.4rem;
    color: $white;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border-radius: 5px;
    &:focus{
      background-color: $white;
      border: 1px solid $black;
      color: $black;
    }
    &:hover{
      border: 1px solid $black;
    }
  }
  &__content{
    border: 1px solid $layerBorder;
    position: absolute;
    top: 5.8rem;
    right: 0;
    left: 0;
    padding: 0;
    list-style: none;
    margin: 0;
    overflow: hidden;
    overflow-y: auto;
    max-height: 34rem;
    z-index: 3;
    background-color: $layerBackground;
    &::-webkit-scrollbar{
      background: $white;
    }
    @include breakpoint(smallLaptop) {
      max-height: 30rem;
    }
  }
  &__option{
    &--highlight{
      background-color: $darkerWhite;
      color: $brand;
    }
  }
  &__element{
    cursor: pointer;
    @include transition (.3s all ease-in-out);
    border-bottom: 1px solid $layerBorder;
    background-color: $white;
    &:last-child{
      border-bottom: 0;
    }
  }
  &__select{
    position: absolute;
    width: 2rem;
    height: 2rem;
    right: 0.5rem;
    top: 1.3rem;
    padding: 0.4rem 0.8rem;
    text-align: center;
    @include transition(.3s all ease-in-out);
    &:before{
      position: relative;
      right: 0;
      top: 65%;
      border-style: solid;
      border-width: 0.4rem 0.4rem 0.4rem;
      content: "";
      transform-origin: center;
      border-color: $white transparent transparent;
    }
  }
  &--active .multiselect__select {
    transform: rotate(180deg);
    top: 1.1rem;
  }
  &__option{
    padding: 1rem;
    display: block;
    font-size: 1rem;
  }
  &__single{
    padding: 2rem;
    font-size: 1.4rem;
    color: $black;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    border: 2px solid $borderColor;
    display: block;
    padding-left: 3rem;
  }
}
.select{
  position: relative;
  &__holder{
    display: flex;
    align-items: center;
  }
  &__small{
    flex-basis: 30%;
    width: 30%;
    &:first-child{
      input{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    &.error {
      border: 1px solid $red;
      position: initial !important;
      &:first-child{
        border-right: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      &:nth-child(3){
        border-left: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  &__big{
    flex-basis: 70%;
    width: 70%;
    &:last-child{
      input{
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}
.blackDropdown{
  background-color: $darkBlack;
  z-index: 3;
}
.blackDropdown__list{
  border-bottom: 1px solid $white;
  color: $white;
  font-size: 1.4rem;
  background: $darkBlack;
}
.blackDropdown__arrow{
  border-color: $white transparent transparent;
}

.multiselect--disabled {
  background: rgb(235, 235, 228);
  cursor: default;
  .multiselect__select {
    display: none;
  }
}
.multiselect--disabled:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
