.do-nda{

  &__top {
      justify-content: center;

      &__title {
          text-decoration: underline;
          font-weight: bold;
          text-align: center;
      }
  }

  .layer-mask{
    z-index: 3;
  }
  .layer-header{
    @include breakpoint(toDesktop){
      padding: 10px 20px;
    }
    i{
      @include breakpoint(toDesktop){
        font-size: 10px;
        margin-right: 5px;
      }
    }
  }
  .layer-body{
    @include breakpoint(toDesktop){
      padding: 20px;
    }
  }
  .layer-body__top-left{
    @include breakpoint(toDesktop){
      min-height: auto;
      flex-basis: 100%;
      width: 100%;
    }
  }
  #nda-modal-body{
    @include breakpoint(toDesktop){
      opacity: 1;
      max-height: inherit;
    }
  }
  .layer-body__top{
    @include breakpoint(toDesktop){
      min-height: auto;
    }
  }
  .layer-body__navigation{
    @include breakpoint(toDesktop){
      margin-top: 20px;
    }
  }
  h1{
    font-size: 20px;
    display: block !important;
    @include breakpoint(toPhone){
      font-size: 19px;
    }
  }
  .layer-header a{
    font-size: 12px;
    font-weight: 500;
  }
  #nda-modal-body{
    background: $white;
    padding: 20px;
  }
  .ladda-button{
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;
    right: 0;
    display: block;
    margin: 0;
    background: $white !important;
    color: $brand !important;
    padding: 20px 30px 30px;
    width: 100% !important;
    height: 0;
    font-weight: 600;
    font-size: 12px;
    @include transition (.3s all ease-in-out);
    @media screen and (min-width: 960px) and (max-width: 1024px) {
      left: 0;
      width: 100% !important;
    }
    @include breakpoint(mediumScreen) {
      left: calc(100% - 1100px);
      width: 1100px !important;
    }
    @include breakpoint(largeScreen) {
      left: calc(100% - 1600px);
      width: 1600px !important;
    }
    .ladda-label{
      @include breakpoint(toDesktop){
        font-size: 12px;
        padding: 20px;
      }
    }
    &:hover{
      background-color: $brand !important;
      color: $white !important;
    }
  }
  #date{
    font-size: 10px;
    font-weight: 600;
    height: 0;
    opacity: 1;
    display: block;
    margin-bottom: 40px;
    color: $smallColor;
    @include breakpoint (toDesktop){
      font-size: 10px;
      margin-bottom: 40px;
    }
  }
  &__text{
    text-align: justify;
    font-size: 12px !important;
    color: $black;
    letter-spacing: 0px;
    line-height: 1.83;
    font-weight: normal !important;
    @include breakpoint (toDesktop){
      font-size: 12px;
      line-height: 1.83;
    }
    p{
      @include breakpoint (toDesktop){
        font-size: 12px !important;
      }
    }
    li{
      @include breakpoint (toDesktop){
        font-size: 12px !important;
      }
    }
    .bold{
      font-weight: bold;
    }
    h4, h2{
      margin-top: 10px !important;
      color: $black;
      font-weight: bold;
      font-size: 14px !important;
      @include breakpoint (toDesktop){
        font-size: 14px !important;
        margin-bottom: 38px;
      }
    }
  }
  .layer-body__navigation{
    margin-left: 0;
  }
  #nda-modal-body{
    max-height: calc(100vh - 27rem);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .fillable {
    max-width: 300px;
    padding: 1.1rem 1.5rem;
  }

  .link {
    margin-right: 0;
    text-decoration: underline;
  }
}
