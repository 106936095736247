/*****General Tags*****/
html {
    height: 100%;
}

body {
    min-height: 100%;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

.main-bg{
    background: #f5f7fa;
}
.vue-tooltip {
    z-index: 100000 !important;
}

nav {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
nav.dashboard {
    width: 100%;
}

.top-4 {
    top: 4px;
}
.m-right-15 {
    margin-right: 15px;
}
.no-padding{
    padding: 0 !important;
}
.black-txt{
    color: #000 !important;
}
.success-txt{
    color: #a0d468 !important;
}
.danger-txt{
    color: #ED5464 !important;
}
.warning-txt{
    color: #EF7421 !important;
}
.primary-txt{
    color: #4EC1E7 !important;
}
.default-txt{
    color: #ccc !important;
}
.remove-margin {
    margin: 0 !important;
}
.dropzone-placeholder{
    display: flex !important;
    border: 2px dashed #c3c3c3 !important;
    height: 100px;
    background: transparent !important;
    box-shadow: none !important;
    border-radius: 10px;
}
.dropzone-placeholder .placeholder-center{
    margin: auto;
    color: #ccc;
}
.hide {
    display: none !important;
}
.hide-txt {
    visibility: hidden !important;
}
.show{
    display: block !important;
}
.pointer {
    cursor: pointer;
}
h2 {
    font-weight: 200;
    font-size: 1.8em;
    text-transform: uppercase;
    letter-spacing: 0.4em;
    color: #000;
    margin-top: 3%;
}

h3 {
    text-align: center;
    color: #434a54;
    font-size: 30px;
    font-weight: 900;
    margin: 0 0 2em 0;
}
@media screen and (max-width: 1024px){
    h3{
        margin: 0 0 1em 0;
    }
}
h4 {
    margin: 0;
    color: #656d78;
    font-size: 1.5em;
    font-weight: 400;
}

h5 {
    font-size: 1.4em;
    color: #434a54;
    text-transform: uppercase;
    font-weight: 900;
    display: inline-block;
    margin: 2em 0 1em 2.4em;
}

.container {
    margin: 0 auto;
}

.alldeals-wrapper{
    display: block;
    -webkit-transition: background-color 0.3s ease-in-out;
    transition: background-color 0.3s ease-in-out;
    text-align: center;
}
.alldeals:hover{
    background-color: white;
}
.alldeals {
    border: 2px solid;
    padding: 0px 30px;
    border-radius: 4px;
    text-transform: uppercase;
    font-size: 12px;
    -webkit-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 500;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

a {
    text-decoration: none;
}

/* The Modal (background) */
.modal {
    position: fixed;
    /* Stay in place */
    z-index: 1000;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: black;
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.8);
    /* Black w/ opacity */
}

/* The Close Button */
.modelclose {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1em 2em 0 2em;
}

.modelclose > span:first-child {
    display: inline-block;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    color: #434a54;
    font-size: 14px;
}

.close {
    color: #656d78;
    font-size: 3em;
}

.close:hover,
.close:focus {
    color: #434a54;
    text-decoration: none;
    cursor: pointer;
}

/* Modal Header */
.modal-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
}

/* Modal Body */
.modal-body {
    padding: 0 2em 2em 2em;
}

/* Modal Footer */
.modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 30%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    margin-top: 5%;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

/* Style the tab */
.tab2 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

div.tab {
    overflow: hidden;
}

/* Style the buttons inside the tab */
div.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 15px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-bottom: 1px solid #f5f7fa;
    color: #ccd1d9;
    text-transform: uppercase;
    font-size: 12px;
}

/* Change background color of buttons on hover */
div.tab button:hover {
    color: #1f7be8;
}

/* Create an active/current tablink class */
div.tab button.active {
    border-bottom: 1px solid #1f7be8;
    color: #1f7be8;
}

/* Style the tab content */
.tabcontent {
    border-top: none;
    padding-top: 2em;
}

.tabcontent {
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s;
    /* Fading effect takes 1 second */
}

.tabcontent > form > div > label {
    display: inherit;
    color: #434a54;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.tabcontent > form > div > input, .tabcontent > form > div > textarea {
    width: 100%;
    height: 3em;
    margin-bottom: 20px;
    font-weight: 200;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #e6e9ed;
}

.tabcontent > form > div > textarea {
    max-width: 100%;
    margin-top: 0.6em;
}

.tabcontent > form > div > input:focus, .tabcontent > form > div > textarea:focus {
    outline: none;
    border-bottom: 1px solid #656d78;
}

@-webkit-keyframes fadeEffect {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeEffect {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/*****End Login*****/

/*****End General Tags*****/

.navigation {
    background-color: #fff;
    box-shadow: 0 18px 16px 0 rgba(0, 0, 0, 0.05);
}

.navigation:hover {
    cursor: pointer;
    background-color: #f9f9f9;
   -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1), 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1), 0 0 0 30px rgba(255, 255, 255, 0);
    -webkit-transition: -webkit-box-shadow 0.5s ease-in-out;
    transition: box-shadow 0.5s ease-in-out;
}

.navigation > i {
    color: #000;
    font-size: 15px;
}
.scroll-down {
    background-color: #fff;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    cursor: pointer;
}

.scroll-top {
    background-color: #fff;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    margin-top: 4px;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.scroll-down > i,
.scroll-top > i {
    margin-right: 0.5em;
    font-size: 1em;
}

.scroll-down > span,
.scroll-top > span {
    font-size: 0.7em;
}

.up-arrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

/*****End Selected For You*****/
/*****Tile*****/
.tile-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.link-to-company {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 100%;
}

.align-s-tyles {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.brand-company {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: black;
    position: relative;
    color: #656d78;
}

.data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
}

.data > section > span {
    color: #aab2bd;
    text-transform: uppercase;
    font-size: 9px;
    margin-bottom: 0.7em;
    display: inline-block;
    font-weight: 200;
    letter-spacing: 0px;
}

h4 > sup {
    font-size: 0.5em;
}

.description {
    position: relative;
}

.company-profile-logo-block {
    background-size: cover;
    color: #656d78;
    position: absolute;
    width: 7em;
    height: 7em;
    bottom: 5em;
    left: 2em;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    background-color: #ffffff;
}
.description-text {
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px !important;
    letter-spacing: 0.4px !important;
    font-weight: 300;
}

/*****End Tile*****/
/*****Navigation*****/
.overlay {
    height: 0%;
    width: 0%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-y: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}
.overlay a {
    padding: 30px 0;
    text-decoration: none;
    font-size: 20px;
    color: #000;
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-transform: uppercase;
    letter-spacing: 16px;
    font-weight: 200;
}

.overlay a:hover, .overlay a:focus {
    color: #1f7be8;
    background-color: #f5f7fa;
}

.overlay a:active {
    color: #fff;
}

/*****End Navigation*****/
/*****Company*****/
.tags:first-child a:hover {
    background-color: #e1e1e1;
}
.tags:last-child > a:hover {
    background-color: transparent;
}
.tags:last-child > a{
    padding: 0;
}
.tags > a.is-active{
    color: #1f7be8 !important;
}
.ladda-spinner{
    left: 20% !important;
    top: 50% !important;
}
.white {
    background-color: #fff;
}

.blue {
    background-color: #fff;
}

.black {
    background-color: #000;
}

.black > div > h3 {
    color: #fff;
}
.management > div > section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.management > div > section > article > span {
    color: #434a54;
    font-weight: 500;
    font-size: 14px;
    display: block;
    text-align: center;
}

.management > div > section > article > div {
    border-radius: 50%;
    background-color: #fff;
    height: 7em;
    width: 7em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 1em auto;
}

.management > div > section > article {
    width: 33.33%;
}

.management > div > section > article > div > img {
    height: 0.7em;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.management > div > section > figure {
    width: 29.3%;
    margin: 0 2rem;
    flex-grow: 1;
}

.management > div > section > figure > img {
    height: 10rem;
    width: 10rem;
    border-radius: 50%;
    margin: 1em auto;
    display: block;
    -o-object-fit: cover;
    object-fit: cover;
}

.management > div > section > figure > figcaption {
    color: #434a54;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-bottom: 0.3em;
}

.management > div > section > figure > span {
    display: block;
    text-align: center;
    color: #656d78;
    font-size: 12px;
    margin-bottom: 0.5em;
}
.dropbtn {
    color: #fff;
    padding: 20px 35px;
    border: none;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 14px;
    margin: 0 5px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 1px;
    -webkit-transition: background-color 0.5s ease;
    transition: background-color 0.5s ease;
    font-family: 'Montserrat', sans-serif;
}
@media screen and (min-width: 1025px) and (max-width: 1599px){
    .dropbtn{
        font-size: 10px;
    }
}
.dropbtn.disabled {
    opacity: 0.6;
    cursor: default;
}
.btn-red {
    background-color: #ed5565 !important;
}
.btn-red.disabled-click{
    cursor: default;
}

.btn-gray {
    background-color: #424242 !important;
}

.btn-green {
    background-color: #a0d468 !important;
}

.btn-yellow {
    background-color: #ffcc00;
}

.btn-yellow.disabled-click {
    cursor: default;
}
.btn-green:hover {
    background-color: #82b34d;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
    bottom: 100%;
    left: 5px;
    right: 5px;
}

.dropdown-content a {
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    cursor: pointer;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #da4453;
}
.btn-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 4% 0% 2% 0%;
}

.btn-group > a.btn-green {
    margin-left: 0;
}

/*End Company*/
/*Deal Details*/

.item > div > article {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-left: 4rem;
    padding-bottom: 5%;
}

.item > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.item > div > aside {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.item > div > aside > div {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    background-color: #dcf3f9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.item > div > aside > div > i {
    color: #2e81e3;
    font-size: 25px;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
}

.item-details > h3 {
    text-align: left;
    margin-bottom: 0;
    margin-top: 35px;
}

.item-details > section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.item-details > section > div {
    width: 33%;
    margin-top: 4.6284rem;
}

.item-details > section > div > header {
    color: #656d78;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 500;
}

.item-details > section > div > footer {
    color: #434a54;
    font-size: 3rem;
    font-weight: 200;
    margin-top: 0.3em;
}

.item-details > section > div > footer > span {
    color: #1f7be8;
}

.item-details > section > div > footer > sup {
    font-size: 2rem;
    margin-right: 0.4rem;
    vertical-align: inherit;
}

.item-details > section > p {
    color: #434a54;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2.2rem;
}

.item-details > section > p:first-child {
    margin-top: 3em;
}
@media screen and (max-width: 1024px){
    .item-details > section > p:first-child {
        margin-top: 2rem;
    }
}
.item-details > section > a {
    margin-bottom: 0;
    margin-top: 20px;
}

.documentation {
    background-color: #f5f7fa;
    position: relative;
    padding: 40px 0;
    padding-bottom: 150px;
}
#company-documentation {
    background-color: #ffffff;
    padding: 10rem 0px;
}

.documentation > div > h3 {
    margin: 0;
}
.documentation > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
}

.documentation > div > section {
    margin: 4em 0 2em 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1000px;
    align-items: flex-end;
}

.documentation > div > section > div {
    margin: 0 2em;
    text-align: center;
    width: auto;
    margin-bottom: 20px;
}

.documentation > div > section > div > span {
    display: block;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
    color: #434a54;
    font-size: 12px;
    max-width: 205px;
    width: 205px;
}
@media screen and (min-width: 1025px) and (max-width: 1599px){
    .documentation > div > section > div > span {
        max-width: 210px;
        width: 210px;
    }
}
.documentation > div > section > div > a > img {
    height: 150px;
    box-shadow: 0 7px 16px 0 rgba(0, 0, 0, 0.19);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.documentation > div > section > div > a:hover > img {
    cursor: pointer;
    box-shadow: 0 7px 25px 0 rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 1920px) {

    .data {
        padding: 12px 27px 17px 27px;
    }
    .description-text > p {
        font-size: 9px;
        margin-left: 8.5rem;
    }

    .company-profile-logo-block {
        width: 9vh;
        height: 9vh;
        bottom: 7vh;
        left: 0.85em;
        border-radius: 5px;
    }
    .scroll-down > i,
    .scroll-top > i {
        font-size: 1em;
    }
}

@media only screen and (max-width: 1024px) {

    .modal-content {
        width: 50%;
    }
    .dropbtn{
        font-size: 2rem;
        padding: 4rem 0rem;
        width: 34.5rem !important;
        text-align: center;
    }
    div.tab button {
        font-size: 11px;
    }

    .tabcontent > form > div > label {
        font-size: 11px;
    }

    .tabcontent > form > div > input, .tabcontent > form > div > textarea {
        font-size: 16px;
    }
    .tags > a > div {
        font-size: 11px;
        padding: 23px;
    }

    h3 {
        font-size: 22px;
    }

    .management > div > section {
        margin-bottom: 0;
    }

    .management > div > section > figure > img {
        width: 70px;
        height: 70px;
    }

    .management > div > h3 {
        margin: 3em 0 1em 0;
    }

    .item > div > aside > div {
        width: 70px;
        height: 70px;
    }

    .item > div > aside > div > i {
        font-size: 20px;
    }

    .item-details > section > p {
        font-size: 12px;
    }

    .item-details > section > div > header {
        font-size: 11px;
    }

    .item-details > section > div > footer {
        font-size: 20px;
    }

    .item-details > h3 {
        margin-top: 23px;
    }

    .item-details > section > a {
        margin-top: 20px;
        font-size: 12px;
    }

    .documentation {
        margin-top: 30px;
        padding-top: 40px;
    }

    .documentation > div > section > div > a > img {
        height: 12em;
    }

    .documentation > div > section {
        margin-top: 50px;
    }

    .overlay a {
        font-size: 20px;
    }

    .alldeals {
        font-size: 12px;
    }
}

@media only screen and (max-width: 1024px) {
    h2 {
        font-size: 16px;
        margin-top: 10%;
        margin-bottom: 10%;
    }

    h4 {
        font-size: 10px;
    }

    h5 {
        margin: 1em 0 1em 0.7em;
    }

    .modal-content {
        width: 90%;
    }

    .tile-container {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .data > section > span {
        font-size: 6px;
        letter-spacing: 0.4px;
    }

    .align-s-tyles {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .tags {
        -ms-flex-item-align: auto;
        -ms-grid-row-align: auto;
        align-self: auto;
    }

    .tags > a {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        text-align: center;
        width: 50%;
    }

    .tags > a > div {
        font-size: 1em;
    }
    .black > div > div.modal-body {
        padding: 0;
    }

    .management > div > section > article > span {
        font-size: 0.8em;
    }

    .management > div > h3 {
        margin-top: 0em;
    }

    .management > div > section > figure > figcaption {
        font-size: 0.8em;
    }

    .management > div > section > figure > span {
        font-size: 0.6em;
    }

    .documentation > div > section > div > a > img {
        height: 10em;
    }

    .item > div > aside > div {
        width: 3em;
        height: 3em;
    }

    .item > div > aside > div > i {
        font-size: 1em;
    }

    .item-details > section > div {
        width: 50%;
    }

    .item > div > article {
        padding-bottom: 2%;
    }

    .item-details > h3 {
        margin-top: 0.5em;
    }

    .item-details > section > a {
        margin-top: 1em;
    }

    .btn-group {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .dropbtn {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 1em 0;
        width: 21.5rem;
        margin-left: 0px;
        display: block;

    }

    .dropdown > button {
        width: 100%;
    }

    .overlay a {
        font-size: 1em;
        letter-spacing: 6px;
    }

    .documentation {
        padding-top: 9%;
    }
    .scroll-down,
    .scroll-top {
        position: relative;
    }

    div.tab button {
        font-size: 9px;
    }

    .data {
        padding: 4px 15px 10px 15px;
    }

    .description-text > p {
        font-size: 8px;
        margin-left: 9em;
    }
}

@media only screen and (max-width: 375px) {

    .tabcontent > form > div > input, .tabcontent > form > div > textarea {
        height: 2em;
        font-size: 0.9em;
    }

    .item-details > section > a {
        margin-top: 1em;
        font-size: 0.7em;
        padding: 1.4em 2em;
    }

    .documentation > div > section > div {
        margin: 0 1em;
    }

    .documentation > div > section {
        margin-top: 3em;
    }
}

@media only screen and (max-width: 320px) {
    .modal-body {
        padding: 0 1.3em 2em 1.3em;
    }

    h2 {
        font-size: 1em;
    }

    .description-text > p{
        font-size: 0.5em;
        margin-left: 7em;
    }
}

.fadeslow-enter-active, .fadeslow-leave-active {
    transition: opacity .1s
}

.display-block {
    display: block;
}

.capitalize,
.item-details .deal-status {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}
.reset-password-error {
    color: red;
    font-size: 12px;
    margin-bottom: 13px;
    display: block;
}

.form-error {
    color: red;
    font-size: 12px;
}
.back-navigation-button {
    position: fixed;
    left: 0;
}

.full-width {
    width: 100% !important;
}
.company-profile-logo-block {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.pre-wrap {
    white-space: pre-wrap;
}
.ladda-button {
    border: none !important;
    padding: 2em 4em;
    font-size: 1.1em ;
    cursor: pointer ;
    color: #fff ;
    border-radius: 0px;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
}
.ladda-button:hover, .ladda-button[disabled], .ladda-button[disabled]:hover, .ladda-button[data-loading], .ladda-button[data-loading]:hover {
    border: none;
    background-color: #82b34d;
}
.input-error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}
.deal-order-content {
    margin-top: 15px;
}
.deal-order-content span {
    font-size: 14px;
    display: inline-block;
    margin-bottom: 4px;
}
.blind-deal-dismissed-text {
    color: white;
    text-align: center;
    display: inherit;
    font-size: 12px;
    padding-top: 7px;
}
.header-pdf {
    font-size: 2rem;
    margin: 0 auto;
}

#signedNdas .documents-holder,
#signedNdasInvestor .documents-holder{
    display: inline-block;
    width: 30%;
    background-color: #f5f7fa;
    border-radius: 5px;
    text-align: center;
    border: 1px solid #e6e9ed;
    margin: 10px;
    padding-bottom: 25px;
}
.all-deals-pagination > span {
    border: 2px solid;
    height: 31px;
    vertical-align: middle;
    display: inline-block;
    width: 35px;
    text-align: center;
    margin: 3px;
    outline: none;
    border-radius: 5px;
}
.all-deals-pagination > span:focus {
    outline: none;
}
.all-deals-pagination > span.pages {
    font-size: 14px;
    width: 100px;
}
.all-deals-pagination > span.pages > span {
    display: inline-block;
    padding-top: 7px;
}
.all-deals-pagination span > i {
    padding-top: 0;
    font-size: 30px;
}
.all-deals-pagination > span:not(.pages) {
    cursor: pointer;
}

.data.justify-content-start {
    justify-content: start;
}
