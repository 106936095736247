.investor-experience-selection {
  margin-top: 10px;
  @include breakpoint(toPhone) {
    margin-top: 80px;
  }

  .industry-selection {
    margin: 0 150px;
    @include breakpoint(mobileScreen) {
      margin: 0 20px;
    }
    @include breakpoint(tabletScreen) {
      margin: 0 120px;
    }
    .search-holder {
      margin-bottom: 40px;
    }

    .search-container {
      .search-box {
        i {
          @include breakpoint(tablet) {
            font-size: 16px;
          }
          @include breakpoint(toBigPhone) {
            font-size: 12px;
          }
        }
      }
    }

    .results-list {
      @include breakpoint(toBigPhone) {
        width: 100%;
        overflow-x: hidden;
        max-height: 189px;
      }
      @include breakpoint(tablet) {
        width: 100%;
        overflow-x: hidden;
        max-height: 298px;
      }
      @include breakpoint(toPhone) {
        width: 100%;
        overflow-x: hidden;
        max-height: 260px;
      }

      ul {
        li {
          @include breakpoint(toBigPhone) {
            padding: 15px 30px;
          }

          span[class=option] {
            @include multiple-media(toBigPhone, toPhone) {
              font-size: 12px;
            }
          }

          span[class=result] {
            @include multiple-media(toBigPhone, tablet, toPhone) {
              font-size: 12px;
              margin-top: -15px;
            }
          }

          .remove {
            @include multiple-media(toBigPhone, tablet) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .account-selection {
    .results-list {
      max-height: 255px;
      @media(max-height:680px) {
        max-height: 200px;
      }
      @include breakpoint(toBigPhone) {
        max-height: 145px;
      }

      ul {
        li {
          @include breakpoint(toBigPhone) {
            padding: 15px 30px;
          }
        }
      }
    }

    .search-container {
      @include breakpoint(toBigPhone) {
        height: 80px;
      }

      .search-box {
        @include breakpoint(toBigPhone) {
          padding: 15px;
          height: 60px;
        }

        i {
          @include breakpoint(toBigPhone) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
