.advisor-terms-and-conditions {
    width: 400px;
    height: 124px;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e7e9ed;
    background-color: $white;
    position: absolute;
    top: 70px;
    left: 30px;

    &__blue-triangle {
        width: 20px;
        height: 20px;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
    }

    &__content {
        width: 340px;
        height: 40px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        color: $charcoal-grey;
        position: absolute;
        top: 20px;
        left: 20px;
    }

    &__close {
        color: #aab2bd;
        position: relative;
        top: 18px;
        left: 370px;
        font-size: 15px;
        &:hover {
            cursor: pointer;
        }
    }

    &__line {
        width: 398px;
        height: 1px;
        background-color: #e7e9ed;
        position: absolute;
        top: 80px;
        left: 1px;
    }

    &__view_document {
        width: 120px;
        height: 15px;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        color: $azul;
        position: absolute;
        top: 95px;
        left: 20px;
        &:hover {
            cursor: pointer;
        }
    }
}