.compact-people {
  background-color: $color-grey-1;
  &__content {
    padding: 0 30px;
    background-color: $color-compact-background;
  }
  &__management {
    .content-title {
      margin-top: 3rem;
    }
    &__title {
      font-size: 18px;
      font-family: $regular;
      font-weight: 500;
      color: $color-grey-6;
      margin-bottom: 20px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include breakpoint(mobileScreen) {
        width: calc(100vw - 6rem);
      }
      .people-tabs {
        display: flex;
        flex-direction: row;
        margin: 2rem 0;
        overflow: auto;
        @-moz-document url-prefix() {
          padding-bottom: 1.5rem;
        }
        &__item {
          max-width: 20rem;
          min-width: fit-content;
          margin-right: 5rem;
          font-size: 14px;
          font-weight: 600;
          font-family: $regular;
          color: $color-grey-6;
          border-bottom: none;
          cursor: pointer;
          &--active {
            font-weight: 600;
            color: $color-blue-main;
            cursor: pointer;
            border-bottom: 1px solid $color-blue-main;
            padding-bottom: 1rem;
            &:hover {
              color: $color-blue-dark;
            }
            @include breakpoint(mobileScreen) {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }

    .members {
      .team {
        display: flex;
        flex-direction: column;
        &__overlay {
          display: flex;
          flex-direction: row;
          margin-bottom: 2rem;
          position: relative;
          &:last-of-type {
            margin-right: 0;
          }
          .team-photo {
            height: 14rem;
            width: 14rem;
            object-fit: contain;
            @include multiple-media(mobileScreen, tabletScreen) {
              display: none;
            }
          }
          .team-details {
            display: flex;
            flex-direction: column;
            width: calc(100% - 14rem);
            padding-left: 2rem;
            @include multiple-media(mobileScreen, tabletScreen) {
              width: 100%;
              padding-left: 0;
            }
            &__box {
              display: flex;
              flex-direction: column;
            }
            .identity-headline {
              display: flex;
              flex-direction: column;
              position: relative;
              height: 60px;
              &__name {
                font-size: 15px;
                font-weight: 600;
                line-height: 1.73;
                color: $color-grey-6;
                margin: 0;
                padding-top: 5px;
                width: max-content;
                word-break: break-word;
                @include breakpoint(tabletScreen) {
                  position: absolute;
                  left: 7rem;
                  top: 0;
                  padding-top: 0;
                }
                @include breakpoint(mobileScreen) {
                  position: relative;
                  left: 7rem;
                  top: -6rem;
                  padding-top: 0;
                  width: calc(100% - 70px);
                }
              }
              &__position {
                font-size: 12px;
                color: $color-grey-10;
                width: max-content;
                @include breakpoint(tabletScreen)  {
                  position: absolute;
                  left: 7rem;
                  top: 2.5rem;
                }
                @include breakpoint(mobileScreen) {
                  position: relative;
                  left: 7rem;
                  top: -6rem;
                }
              }
              &__logo {
                position: absolute;
                top: 0;
                right: 0;
                background-color: $brand;
                padding: 1rem;
                @include breakpoint(mobileScreen) {
                  padding: 0.33rem;
                  position: absolute;
                  top: 31.3px;
                  left: 0;
                }
              }
              &__mobile-photo {
                display: none;
                @include multiple-media(mobileScreen, tabletScreen) {
                  display: flex;
                  width: 60px;
                  height: 60px;
                  object-fit: contain;
                }
              }
            }
            &__biography {
              font-size: 14px;
              color: $color-grey-6;
              line-height: 1.86;
              text-align: justify;
              @include multiple-media(mobileScreen, tabletScreen) {
                margin-top: 2rem;
              }
            }
          }
        }
      }
    }
  }
}
