.agreements-update-banner {
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 999;

    padding: 10px 15px;
    background-color: $brand;
    
    &__content {
        color: $white;
        font-size: 1.2rem;
        line-height: 1;
        margin: 0;
    }

    &__button {
        background-color: $white;
        color: $brand;
        text-transform: uppercase;
        padding: 10px 15px;
        font-weight: bold;
        font-size: 1.2rem;
        border-radius: 3px;
        border: none;
        cursor: pointer;
        @include transition(.3s ease);

        &:hover {
            background-color: rgba($white, .8);
        }
    }
}