.preview-agreement{
  .legal-page{
    margin-top: 3rem;
    &__content{
      @include breakpoint(laptop){
        max-height: calc(103vh - 21rem);
        min-height: calc(100vh - 28rem);
      }
      @include breakpoint(smallLaptop){
        max-height: calc(100vh - 21rem);
        min-height: calc(100vh - 28rem);
      }
      @include breakpoint(toBigPhone){
        max-height: calc(100vh - 23rem);
        min-height: calc(100vh - 37rem);
      }
      @include breakpoint(tabletLandscape) {
        max-height: calc(100vh - 18rem);
        min-height: calc(100vh - 30rem);
      }
      max-height: calc(100vh - 18rem);
      min-height: calc(100vh - 21rem);
      overflow-x: hidden;
      overflow-y: auto;
      padding: 3rem 0 10rem 4rem!important;
      font-family: $regular;
      font-weight: 400;
      font-size: 1.3rem;
      #info{
        margin-bottom: 3rem;
      }
      h1{
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 2rem;
      }
      &--title{
        font-weight: bold !important;
        font-size: 1.6rem !important;
      }
      a{
        color: $brand;
        @include transition(.3s all ease-in-out);
        &:hover{
          color: $darkerBrand;
        }
      }
    }
    &__navigation {
      padding: 1rem 2.8rem;
      border-bottom: 1px solid $borderColor;
      left: 35px;
      position: relative;
      width: calc(100% - 80px);
      padding-left: 0;
      @include breakpoint (toDesktop){
        display: flex;
        justify-content: space-between;
      }
    }
    .item{
      margin-right: 5px;
      margin-bottom: -5rem;
      @include breakpoint (toDesktop){
        margin-bottom: -1rem;
      }
      &.selected a{
        color: $brand;
        &:hover {
          border-bottom: $brand 1px solid;
        }
      }
      a{
        color: $black;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-family: $regular;
        font-weight: 600;
        padding: 1.3rem 1.2rem 1rem;
        &:hover {
          color: $brand;
          border-bottom: none;
        }
      }
    }
  }
}