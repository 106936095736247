.compact-documents {
  background-color: $color-compact-background;

  &-deal-view{
    margin-bottom: 80px;
  }
  .documents {
    height: 100%;

    &__container {
      border: 1px solid $color-grey-2;
      border-radius: 5px;
      background-color: $color-white;
      border-bottom: 0;
      @include breakpoint(mobileScreen) {
        width: calc(100vw - 6rem);
      }
    }
    &__item {
      width: 100%;
      height: 5.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid $color-grey-2;

      &__name,
      &__extension {
        font-size: 14px;
        font-family: $regular;
        color: $color-grey-6;
      }
      &__name {
        margin-left: 2rem;
        word-break: break-word;
      }
      &__extension {
        text-transform: lowercase;
      }
      .file-item {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 5rem;
        cursor: pointer;

        &:hover {

          .documents__item__name,
          .documents__item__extension{
            color: $color-blue-dark;
          }
        }
      }
      .file-image {
        margin-left: 2rem;
      }
    }
  }
}
