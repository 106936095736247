.compact-intro {
	box-sizing: border-box;
	min-width: 100%;
	width: 100%;
	padding: 20px 30px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	background-color: $color-grey-13;
	flex-shrink: 0;
	background-attachment: fixed;
	align-items: stretch;;
	@include multiple-media(mobileScreen, tabletScreen, smallScreen) {
		height: max-content;
	}
	@include breakpoint(tabletScreen) {
		padding: 2rem 3rem;
	}
	@include multiple-media(mediumScreen, largeScreen) {
		min-width: 45rem;
		width: 45rem;
		padding: 2rem 3rem;
		height: calc(100vh - 54px);
		overflow-y: auto;
		overflow-x: hidden;
	}
	.statistics {
		padding: 0;
        margin-top: 50px;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		&__item {
			display: flex;
			width: 100%;
			flex-wrap: nowrap;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin: 0 0 1.6rem;
			@include breakpoint(tabletScreen) {
				width: 50%;
			}
			@include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
				flex-direction: column;
			}
			.title {
				color: $color-white;
				opacity: .5;
				font-size: 12px;
				font-weight: 500;
				margin: 0;
				text-transform: uppercase;
				width: 50%;
				@include break-words-nicely;
				@include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
					width: 100%;
				}
			}
			.value {
				color: $color-white;
				font-size: 20px;
				font-weight: 500;
				margin: 0;
				width: 50%;
				height: 100%;
				text-align: right;
				line-height: 1.5;
				@include break-words-nicely;
				@include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
					width: 100%;
					text-align: left;
				}
			}
			&--wide {
				width: 100%;
				flex-direction: column;
				.title,
				.value {
					width: 100%;
					text-align: left;
				}
				.value {
					line-height: 1.5;
				}
			}
			&--uppercase {
				.value {
					text-transform: uppercase;
				}
			}
			&:last-child {
				margin-bottom:0;
			}

		}
		.deal-type {
			text-transform: capitalize;
		}
		.deal-percentage {
			margin-left: -5px;
		}
	}
}
