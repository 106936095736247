.investor-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    background-color: $color-white;
    height: 80px;
    margin-top: 2rem;
    padding: 2rem;

    &:first-child {
        margin: 0;
    }

    .investor-profile {
        display: flex;

        .investor-image {
            height: 45px;
            width: 45px;
            min-width: 40px;
            margin-right: 15px;
            border-radius: 5px;

            .investor-initials {
                font-size: 16px;
            }
        }

        .name-info {
            width: 135px;
            min-width: 135px;

            .investor-name {
                display: block;
                font-size: 16px;
                font-weight: bold;
                max-width: 135px;
                color: $darkBlack;
            }

            .company-name {
                max-width: 135px;
                font-size: 12px;
                color: $color-grey-21;
            }


            @media (min-width: 1021px) and (max-width: 1200px) {
                width: 110px;
                min-width: 110px;
                .investor-name, .company-name {
                    max-width: 110px;
                }
            }

            @media (min-width: 951px) and (max-width: 1020px) {
                width: 100px;
                min-width: 100px;
                .investor-name, .company-name {
                    max-width: 100px;
                }
            }

            @media (min-width: 601px) and (max-width: 950px) {
                width: 75px;
                min-width: 75px;
                .investor-name, .company-name {
                    max-width: 75px;
                }
            }

            @media (max-width: 600px) {
                width: 135px;
                min-width: 135px;
                .investor-name, .company-name {
                    max-width: 135px;
                }
            }
        }
    }

    .investor-status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;
        height: 25px;
        border-radius: 5px;
        background-color: $color-green-dark-1;
        font-size: 11px;
        font-weight: bold;
        color: $color-white;

        @media (min-width: 901px ) and (max-width: 950px) {
            width: 100px;
        }

    }

    .investor-balance {
        font-size: 16px;
        font-weight: bold;
        color: $darkBlack;
        width: 100px;
        min-width: 30px;
    }

    .data-room {
        height: 27px;
        width: 27px;
        position: relative;

        .fa-folder {
            color: $brand;
            font-size: 25px;
            position: relative;
            color: $color-cyan-secondary;

            &.no-access {
               color: $color-grey-4;
            }
        }

        .fa-check, .fa-times {
            position: absolute;
            bottom: 6px;
            font-size: 12px;
            color: $color-white;
        }

        .fa-check {
            right: 5px;
        }

        .fa-times {
            right: 7px;
        }

    }

    .investor-name, .investor-balance, .company-name {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
    }

    .data-room, .investor-status, .investor-balance {
        @media (max-width: 600px) {
            display: none;
        }
    }

    .dots {
        font-size: 27px;
        color: $color-cyan-secondary;
        cursor: pointer;
        padding-top: 5px;
    }

}
