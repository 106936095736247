.dataroom-changes-modal {
  .common__modalBox--content {
    @include breakpoint(smallLaptop) {
      max-height: calc(100vh - 25rem);
    }
  }

  .helloLine {
    font-size: 1.2rem;
    font-family: $regular;
    font-weight: 500;
    color: $black;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .checkbox-container {
    input {
      background-color: $darkerWhite;
    }

    .checkboxText {
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .send-button {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding: 2rem 5rem;
    line-height: 1.3rem;
    font-size: 1.4rem;
    background-color: $brand;
    color: #fff;
    left: inherit;
    right: inherit;
    width: auto;
    position: relative;
    border: 0;
    border-radius: 5px;
    transition: 0.3s all ease-in-out;
    &:hover {
      background-color: $darkerBrand;
    }
  }

  .disabled {
    cursor: text;
    opacity: 0.5;
    &:hover {
      background-color: $brand;
    }
  }

  .ql-editor {
    max-height: calc(100vh - 70rem);
    background-color: $darkerWhite;
    font-size: 1.2rem !important;
    line-height: 2.2rem !important;
    font-family: "Montserrat", sans-serif;
    @include breakpoint(smallLaptop) {
      max-height: calc(100vh - 60rem);
    }
  }

  .showError{
    &:before {
      display: none !important;
    }
  }
}
