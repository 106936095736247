.dashboard-wrapper{
    display: flex;
    width: 95%;
}
.side-block {
    background: #434A54;
    flex-grow: 1;
}

.tab-block {
    background: #1D2228;
    a {
        display: inline-flex;
        color: #646D79;
        margin-left: 0.5rem;
        padding-bottom: 1rem;
    }
}
.tab-block a.active, .tab-block a:hover {
    color: #fff;
    border-bottom: 2px solid #fff;
}

.tab-content {
    display: flex;
    flex-direction: column;
}

.tab-content .tab-box-wrapper {
    padding: 10px;
    padding-top: 0px;
}

.tab-content .tab-box-wrapper .tab-box-days {
    color: #AAB2BD;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
}

.tab-box {
    background: #fff;
    .tab-box-header, .modal-header .modal-box-header{
        display: flex;
        border-bottom: 1px solid #DDE0E3;
    }
    .tab-box-header > div, .modal-header .modal-box-header > div {
        align-self: center;
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        color: #414A54;
        flex-basis: 10rem;
    }
    .tab-box-header > p, .modal-header .modal-box-header > p {
        display: flex;
        padding: 10px 20px;
        font-size: 1.2rem;
    }
    .tab-box-header > p > span, .modal-header .modal-box-header > p > span {
        margin-top: 4px;
        margin-left: 9px;
    }
    .tab-box-header > .v-line {
        border-right: 1px solid #DDE0E3;
    }
}
.tab-box .tab-box-header > div.arrow, .modal-header .modal-box-header > div.arrow {
    flex-grow: 1;
    color: #E6E8ED;
    flex-basis: 1rem;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    text-align: center;
}
.tab-box .tab-box-header > div.arrow .material-icons, .modal-header .modal-box-header > div.arrow .material-icons {
    font-size: 2rem;
}
.tab-box .tab-box-header > div.view-btn {
    flex-basis: 2rem;
}
.tab-box .tab-box-header > div > a {
    text-align: right;
}
.tab-box-content:last-of-type{
    border: none;
}
.tab-box-content.with-button > p{
    width: 90%;
    color: #A9B1BE;
}
.tab-box .tab-box-footer p.multiple:after {
    content: "clear";
    font-family: 'Material Icons';
    position: absolute;
    right: 0;
    top: 1.5rem;
    font-size: 1.7rem;
    color: #E6E8ED;
}
.tab-box .tab-box-footer p.equal:after {
    content: "drag_handle";
    font-family: 'Material Icons';
    position: absolute;
    right: 0;
    top: 1.5rem;
    font-size: 1.7rem;
    color: #E6E8ED;
}
.tab-box .tab-box-footer > p > small {
    color: #A9B1BE;
    font-size: 0.9rem;
}
.tab-box .tab-box-footer .left-line{
    border-left: 1px solid #DDE0E3;
}
.tab-box .tab-box-footer .left-line .ladda-button{
    background: none;
    color: inherit !important;
    padding: 0 !important;
    outline: none !important;
    font-size: 1.5rem !important;
    font-weight: 500;
}
.tab-box .tab-box-footer .left-line:first-of-type {
    border: none;
}

