.edit__investor{
  .edit__investor-preferences-number_small{
    .media__image {
      padding: 1.5rem 1.9rem;
      &.whiteDeal {
        font-size: 1.2rem;
      }
    }
  }
  font-family: $regular;
  padding-left: 20.5%;
  background-color: $editorBackground;
  &-preferences{
    margin-top: 4rem;
    border-top: 1px solid $borderColor;
    font-size: 0;
    &-parent{
      margin-bottom: 2rem;
      background-color: $white;
      &:last-of-type {
        margin-bottom: 0;
      }
      .edit__investor-preferences-block {
        .edit__investor-preferences-description {
          a {
            font-weight: 500;
            font-size: 1.2rem;
            color: $black;
            @include multiple-media(mobileScreen, tabletScreen) {
              padding-left: 15px;
            }
          }
        }
      }
      .borderTop {
        position: relative;
        .edit__investor-preferences-description-text {
          font-size: 1.2rem;
        }
        .edit__investor-preferences-description {
          a {
            padding-left: 10px;
            color: $brand;
          }
        }
      }
    }
    h4{
      margin-bottom: 2.6rem;
    }
    &-left,
    &-right{
      width: 54%;
      display: inline-block;
      font-size: 1.6rem;
      vertical-align: top;
      box-sizing: border-box;
      .error{
        right: 1.5rem !important;
      }
      @include breakpoint (phone){
        display: block;
        width: 100%;
        margin-bottom: 8rem;
      }
    }
    .sliderbox{
      @include breakpoint (toDesktop){
        height: 28.8rem;
      }
    }
    &-left{
      width: 44%;
      flex-grow: 1;
      flex-basis: 40%;
      margin-right: 2%;
      @include breakpoint (phone){
        display: block;
        width: 100%;
      }
      .leftBlock,
      .rightBlock{
        display: inline-block;
        vertical-align: middle;
        width: 75%;
        font-size: 1.4rem;
        @include breakpoint (phone){
          display: block;
          width: 100%;
          font-size: 2.4rem;
        }
      }
      .leftBlock{
        text-transform: uppercase;
        flex-basis: 30%;
        span{
          padding-left: 0.4rem;
          font-size: 1.2rem;
          display: block;
          line-height: 1.8rem;
          @include breakpoint (phone){
            font-size: 2.4rem;
          }
        }
      }
      .rightBlock{
        flex-basis: 70%;
        span{
          font-weight: 500;
          position: relative;
          line-height: 3rem;
          @include breakpoint (toDesktop){
            line-height: 4rem;
          }
           span{
             position: absolute;
             right: 0;
             color: $blue;
             cursor: pointer;
             &.normalText{
               color: $smallColor;
             }
           }
        }
      }
    }
    &-right{
        margin-top: -4rem;
        margin-right: 0;
        flex-basis: 50%;
        flex-grow: 2;
        ul{
          padding-left: 3rem;
          display: flex;
          flex-flow: row;
          padding: 0;
          margin: 0;
          @include breakpoint (toDesktop){
            padding-left: 0;
          }
        }
      li{
        padding: 0 .5rem .5rem;
        color: inherit;
        flex: auto;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        @include breakpoint (toDesktop){
          margin-right: 0.5rem;
        }
        @include breakpoint (phone){
          font-size: 1.8rem;
        }
        &.active{
          border-bottom: 1px solid $brand;
          color: $brand;
        }
      }
    }
    &-container{
      font-size: 1.6rem;
    }
    &-block{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      border-bottom: 1px solid $borderColor;
      &:last-child{
        border-bottom: 0;
      }
      &.borderTop{
        .matching-score-default {
          padding: 0;
          height: 50px !important;
          width: 90px !important;
          @include multiple-media(mobileScreen, tabletScreen) {
            height: 40px !important;
            width: 50px !important;
          }
          @include breakpoint(smallScreen) {
            height: 40px !important;
            width: 70px !important;
          }
        }

        .investor-profile-deals-tab-no-matching {
          min-height: 40px;
        }
        .edit__investor-preferences-description{
          padding: 0 0 0 2rem;
          font-weight: 500;
          span {
            color: $brand;
          }
        }
      }
    }
    &-number{
      font-size: 1.8rem;
      @include multiple-media(mobileScreen, tabletScreen) {
        padding: 0;
        font-size: 1.4rem;
      }
      @include breakpoint(smallScreen) {
        font-size: 1.6rem;
      }
      .media__image {
        text-align: center;
        color: $white;
        height: 2rem !important;
        padding: 3rem 1.9rem;
        width: 4rem !important;
        border: none !important;
        border-right: 1px solid #ccd1d9 !important;
        @include multiple-media(mobileScreen, tabletScreen) {
          height: 43px !important;
          width: 50px !important;
          padding: 0 !important;
        }

      }
    }
    &-description{
      flex-grow: 1;
      padding: 20px 20px 20px 25px;
      display: flex;
      align-items: center;
      position: relative;
      @include multiple-media(mobileScreen, tabletScreen) {
        padding: 0 !important;
        height: 40px;
      }
      .deal-icon {
        height: 40px;
        width: 40px;
        @include multiple-media(mobileScreen, tabletScreen) {
          display: none;
        }
        img {
          width: 100%;
          object-fit: contain;
          height: 40px;
        }
      }
      .media__smallImage {
        position: absolute;
        top: 15px;
        left: 19px;
        @include multiple-media(mobileScreen, tabletScreen) {
          position: relative;
          top: 0;
          left: 0;
          width: 14px;
          height: 14px;
          margin-left: 15px;
        }
        img {
          height: 14px;
          width: 14px;
        }
      }
      > span {
        font-size: 1.2rem;
        font-weight: 500;
        color: $black;
        width: 80%;
        @include multiple-media(mobileScreen, tabletScreen) {
          font-size: 1.3rem;
          margin-left: 10px;
        }
      }
      .syndicated-company-name {
        @include multiple-media(mobileScreen, tabletScreen) {
          margin-left: 5px;
        }
      }
    }
    &-desc{
      display: block;
      padding-bottom: 1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 0.9rem;
      font-weight: 400;
      @include breakpoint (toDesktop){
        font-size: 2.2rem;
      }
    }
    &-bigButton{
      background: $brand;
      border: 0;
      padding: 2.5rem 7rem;
      border-radius: 0;
      color: $white;
      font-size: 1.5rem;
      margin-bottom: 4rem;
      cursor: pointer;
      outline: none;
      @include transition (.3s all ease-in-out);
      @include breakpoint (toDesktop){
        font-size: 2.5rem;
      }
      &:hover{
        background-color: $black;
      }
    }
    &-button{
      font-size: 1.2rem;
      font-weight: 500;
      font-family: $regular;
      position: absolute;
      right: 25px;
      @include multiple-media(mobileScreen, tabletScreen) {
       right: 10px;
      }
      a{
        color: $red;
      }
      &.blue{
        a{
          color: $brand;
          &:hover{
            color: $darkerBrand;
          }
        }
      }
    }
  }
  &-client, &-documents{
    margin-top: 0.5rem;
    h3{
      font-size: 1.2rem;
      color: $black;
      text-transform: uppercase;
      text-align: inherit;
      margin: 0;
      font-weight: 400;
      margin-bottom: 1rem;
      @include breakpoint (phone){
        font-size: 2.2rem;
      }
    }
    &Table{
      border: 2px solid $borderColor;
      padding: 2rem;
    }
  }
}

.moveRight{
  margin-left: 10rem !important;
}
.logoHolder{
  position: absolute;
  width: 8.2rem;
  height: 8.2rem;
  background-size: 100%;
  background-position: 50% 50%;
  top: 3rem;
  left: 3rem;
  border-radius: 5px;
  border: 1px solid $borderColor;
}
.noBorder{
  border: 0;
}
.remove__button{
  position: absolute;
  right: -7px;
  top: -8px;
  cursor: pointer;
  z-index: 2;
}