.founder-homepage {
  height: 100vh;
  overflow-y: auto;
  bottom: 60px;
  top: 10px;
  position: relative;
  padding-top: 20px;

  @include multiple-media(smallScreen, mediumScreen) {
    padding: 30px;
  }
  @include breakpoint(largeScreen) {
    padding: 0 350px 0 350px;
  }
}
