.do-preferences{
  .bar-container{
    position: relative;
    padding: 0 150px;
    margin: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 300px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto;
    top: -60px;
    @include multiple-media(mobileScreen, tabletScreen) {
      padding: 0 50px;
    }
    .layer-body__middle-holder{
      position: relative;
    }
    &:last-child{
      margin-top: 10rem;
    }
    h4{
      font-size: 1.6rem;
      color: $black;
      font-weight: 500;
      text-transform: none;
      padding-bottom: 5rem;
      @include breakpoint (toDesktop){
        font-size: 2.6rem;
      }
      &:after{
        display: none;
      }
    }
    &.preferences-deal-size {
      @include multiple-media(mobileScreen) {
        margin-top: 0 !important;
      }
    }
  }
  .layer-body {
    &__middle {
      &-holder {
        background-color: $white;
        position: relative;
      }
      &-filters{
        height: calc(100vh - 19rem);
        position: relative;
        &-block{
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 2rem 0;
          position: relative;
          &:after{
            position: absolute;
            bottom: 0;
            left: -2rem;
            right: -2rem;
            display: block;
            height: 1px;
            background-color: $borderColor;
            content: '';
          }
          &-left{
            .bigText{
              color: $black;
              font-size: 1.3rem;
              display: block;
              font-weight: 500;
              margin-bottom: 0.5rem;
              flex-wrap: wrap;
            }
            .smallText{
              color: $smallColor;
              font-size: 1.1rem;
              display: block;
              font-weight: 500;
            }
          }
          &-right{
            span{
              cursor: pointer;
              @include transition(.3s all ease-in-out);
              &:hover{
                i{
                  color: $black;
                }
              }
            }
            i{
              color: $smallColor;
              font-size: 2rem;
              @include transition(.3s all ease-in-out);
            }
          }
        }
      }
    }
  }
  .do-button{
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    display: block;
    width: 78%;
  }
  .preferences-item{
    @include transition(.3s all ease-in-out);
  }
  .vue-slider-dot {
    width: 14px !important;
    height: 14px !important;
    background-color: $brand !important;
    top: -3px !important;
    z-index: 3 !important;
    border-radius: 50%;
    .vue-slider-dot-handle {
      background-color: $brand !important;
      box-shadow: none !important;
    }
  }
  .vue-slider-process{
    height: 5px !important;
    background-color: $brand !important;
    top: 1px !important;
  }
  .vue-slider-piecewise-label{
    color: $black !important;
    top: 10px !important;
  }
  .vue-slider-piecewise{
    top: 6px !important;
  }
  .vue-slider-component.vue-slider-horizontal-reverse .vue-slider-piecewise-label, .vue-slider-component.vue-slider-horizontal .vue-slider-piecewise-label{
    font-size: 10px !important;
    font-weight: 500;
  }
  .preferences-container.bar-container {
    .vue-slider-component.vue-slider-horizontal .vue-slider-dot {
      left: -10px !important;
      top: -10px !important;
    }
  }
  .vue-slider-component .vue-slider{
    height: 5px !important;
    background-color: $borderColor !important;
    border-radius: 0 !important;
  }
  .vue-slider-component .vue-slider-piecewise-item{
    height: 10px !important;
  }

  .preferences-deal-size {
    position: static;
    &__wrapper {
      width: 100%;
      padding: 0 5rem 3rem;
      @include multiple-media(smallScreen, mediumScreen, toPhone) {
        padding: 0 2rem 3.3rem;
      }
      border: 1px solid $color-grey-3;
      border-radius: 5px;
      box-shadow: 0 0.7rem 1rem -0.6rem $color-grey-4;
      &:first-child {
        margin-top: -4rem;
      }
      &:last-child {
        margin-top: 4rem;
      }
      @include breakpoint(mobileScreen) {
        padding-bottom: 1rem;
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-top: 2rem;
        }
      }
    }
    &__label {
      display: block;
      padding: 3rem 0 1rem;
      font-size: 1.3rem;
      font-weight: 600;
      @include breakpoint(mobileScreen) {
        padding: 1rem 0 1rem;
      }
    }
    &__section {
      display: flex;
      @include breakpoint(mobileScreen) {
        flex-direction: column;
      }
    }
    &__input {
      display: flex;
      flex: 1;
      &:first-child {
        padding-right: 2.5rem;
        @include multiple-media(smallScreen, mediumScreen, toPhone) {
          padding-right: 1rem;
        }
        @include breakpoint(mobileScreen) {
          padding: 0 0 2.2rem;
        }
      }
      &:last-child {
        padding-left: 2.5rem;
        @include multiple-media(smallScreen, mediumScreen, toPhone) {
          padding-left: 1rem;
        }
        @include breakpoint(mobileScreen) {
          padding: 0;
        }
      }
      .left {
        flex-basis: 15%;
        border: 0.1rem solid $color-grey-3;
        border-right: 0;
        border-radius: 5px 0 0 5px;
        padding: 1.8rem;
        font-size: 1.5rem;
        text-align: center;
        @include breakpoint(mobileScreen) {
          padding: 0.8rem;
        }
      }
    }
    .currencyBlock {
      margin-top: 0;
      flex: 1;
      input {
        border-radius: 0 5px 5px 0;
        padding: 1.8rem 3.5rem;
        border: 0.1rem solid $color-grey-3;
        font-size: 1.5rem;
        font-weight: 500;
        margin:0;
        &:hover {
          border-color: $color-grey-4;
        }
        &:focus {
          background-color: $white;
        }
        &:active {
          background-color: $white;
          border-color: rgba(231, 233, 237, 0.5);
        }
        @include breakpoint(mobileScreen) {
          padding: 0.8rem 3.5rem;
        }
      }
      .currency {
        position: absolute;
        font-size: 1.4rem;
        color: $color-grey-4;
        top: 2rem;
        left: 1.9rem;
        @include breakpoint(mobileScreen) {
          top: 1.1rem;
          left: 1.5rem;
        }
      }
      .input-error {
        line-height: 11px;
        position: absolute;
        right: 0;
        @include multiple-media(mediumScreen, smallScreen, tablet, toPhone) {
          left: -64px;
          text-align: end;
          margin-top: 0;
        }
      }
    }
  }
}
.do-elective {
  .vue-slider-horizontal{
    @include breakpoint (toBigPhone){
      width: 100% !important;
    }
  }
  .bar-container{
    > div{
      width: 100%;
      max-height: none;
    }
    h4{
      font-size: 12px;
      font-weight: 500;
      line-height: 1.83;
      padding: 40px 0;
      text-align: center;
    }
    .vue-slider-piecewise-label{
      font-size: 10px !important;
      font-weight: 500;
    }
  }
}