.more-items {
  &--icon {
    font-size: 1.8rem;
    color: $color-grey-6;
    height: 1.5rem;
    width: 1.5rem;
    text-align: center;
    padding: 0.7rem;

    &:hover {
      color: $color-blue-main;
      cursor: pointer;
      background-color: $lightGray;
      border-radius: 50%;
    }
  }

  &--horizontal {
     color: $darkBlack;
     cursor: pointer;
  }

  &__hub-block {
    min-width: 20.5rem;
    width: auto;
    height: auto;
    border: 1px solid $smallBlockBorder;
    border-radius: .5rem;
    position: absolute;
    right: -.5rem;
    top: 3rem !important;
    background-color: $color-white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 1;

    .arrow-top::before,
    .arrow-top::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 100%;
      width: 0;
      height: 0;
    }
    .arrow-top:before {
      right: 13px;
      border: 8px solid transparent;
      border-bottom-color: $smallBlockBorder;
    }
    .arrow-top:after {
      right: 14px;
      border: 7px solid transparent;
      border-bottom-color: $color-white;
    }

    &__content {

      &__body {
        display: block;
        align-items: flex-start;
        flex-direction: column;
        font-size: 1.2rem;
        font-weight: 500;
        position: relative;
        line-height: 1.67;
        color: $color-grey-6;

        &.proraise-branding {
          div:hover {
            i, span {
              color: $color-cyan-secondary;
            }
          }
        }

        div {
          text-align: left;
          padding: 1rem 1rem;
          border-bottom: 1px solid $smallBlockBorder;
          position: relative;

          &:not(:last-child) {
            border-bottom: .1rem solid $smallBlockBorder;
          }

          &:hover {
            cursor: pointer;

            i,span {
              color: $color-blue-main;
            }

            .email-sent {
              color: $color-green-main;
            }
          }

          i {
            font-size: 1.5rem !important;
          }

          i, span {
            padding: 0 1rem;
            font-size: 1.2rem;
            font-weight: 500;
          }

          .email-sent {
            border-radius: 50%;
            background-color: $color-white;
            color: $color-green-main;
            position: absolute;
            bottom: 13px;
            left: 23px;
            padding: 0;
            font-size: 1rem !important;
          }
          &.disabled {
              @include disabled;
          }
        }
      }
    }

  }

  &.proraise-branding {
    .more-items--icon:hover {
      color: $color-purple-main;
    }
  }
}
.founders-dataroom-wrapper .fa-ellipsis-h {
  color: $founderBrand;
}
.founder-menu-item span {
  font-size: 15px !important;
  color: #0a0a0a !important;
}
.founder-menu-item i.fa {
  color: $founderBrand;
  font-size: 16px !important;
}
.founder-menu-item:hover i.fa {
  color: $founderBrand;
}
