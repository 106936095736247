.company-disclaimer {
  &__content {
    padding: 20px 30px;
    height: calc(100% - 7rem);
    overflow-y: auto;
    background-color: $color-compact-background;
    @include multiple-media(mobileScreen, tabletScreen) {
      height: 100%;
    }
    .text-content {
      margin: 1rem 0;
      text-align: justify;
    }
  }
}
