.founder-profile {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    font-family: Nunito;

    &-view {
        height: 21rem;
        width: 100%;
        border-radius: 3px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &--image {
            width: 100%;
            max-height: 100%;
        }

        .investor-initials {
            font-size: 80px;

            @include multiple-media(tabletScreen, mobileScreen) {
                font-size: 50px;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;

        &__item {
            display: flex;
            flex-direction: column;
            margin-top: 1.5rem;

            &--title {
                font-size: 1.2rem;
                color: $color-grey-17;
            }

            &--description {
                font-size: 1.4rem;
                color: $darkBlack;
            }
        }

    }

    &-tabs {
        margin-top: 1.5rem;

        &__item {
            display: flex;
            align-items: center;
            padding: 1.4rem 0;
            font-size: 1.6rem;
            box-shadow: 0 1px 0 0 $color-white-3;
            cursor: default;

            &--title {
                cursor: pointer;
                color: $color-grey-6;
                padding: 0 1.7rem;
            }
        }

        .material-icons-outlined {
            color: $founderBrand;
        }
    }

    &-notifications {
        display: flex;
        flex-direction: column;

        &--title {
            font-size: 1.8rem;
            font-family: NunitoBold, sans-serif;
            color: $darkBlack;
            margin-bottom: 2rem;
        }

        &--item {
            font-size: 1.6rem;
            color: $darkBlack;
            display: flex;
            margin: 0 !important;

            .checkbox {
                margin-right: 1.5rem;
            }
        }

        .checkbox-container {
            margin: 0
        }
    }
}

.founder-password{

    &--title {
        font-size: 1.8rem;
        padding: 2rem 3rem;
        align-self: center;
        display: flex;
    }

    &-main {
        padding: 2rem 3rem;

        .hubx-inputs {
            margin-bottom: 2rem;

            label {
                color: $color-grey-17;
            }

            .hubx-input {
                background-color: white;
            }
        }

        .Password__strength2-container label {
            color: $color-grey-17;
        }
        .Password__strength2-meter .check {
            width: 120px;
        }

        .change-password--submit {
            margin-top: 2rem;
            border-radius: 5px;
            background-color: $founderDarkerBrand;
            color: white;
            padding: 1.5rem 3rem;
            font-size: 1.6rem;
            font-family: NunitoBold, sans-serif;
            outline: none;
            border: none;
            cursor: pointer;
        }


        .founder-edit-data {
            display: flex;

            .hubx-inputs {

                &:nth-child(2) {
                    margin-left: 2rem;
                }
            }
        }

        .founder-edit__photo {


            &__left {
                display: flex;
                flex-direction: column;
                margin-bottom: 2rem;

                &--title {
                    font-size: 1.4rem;
                    color: $color-grey-17;
                    margin-bottom: .5rem;
                    display: block;
                }

                .founder-edit-replace {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;

                    &__photo {
                        width: 10rem;
                        height: 10rem;
                        border-radius: 3px;

                        &--image {
                            width: 100%;
                            max-height: 100%;
                        }

                        .investor-initials {
                            font-size: 40px;
                
                            @include multiple-media(tabletScreen, mobileScreen) {
                                font-size: 30px;
                            }
                        }                
                    }

                    &--btn {
                        border-radius: 5px;
                        background-color: $color-purple-main;
                        color: white;
                        padding: .8rem 1.7rem;
                        font-size: 1.4rem;
                        font-family: NunitoBold, sans-serif;
                        outline: none;
                        border: none;
                        margin-left: 1.5rem;
                        cursor: pointer;
                    }
                }
            }

            &--submit {
                margin-top: 1rem;
            }
        }
    }
}
.founder-message {

    .founder-message-title{
        font-size: 1.8rem;
        padding: 2rem 3rem;
        align-self: center;
        display: flex;
    }

    &-main {
        padding: 2rem 3rem;
    }
    .send-message--submit {
        margin-top: 2rem;
        border-radius: 5px;
        background-color: $founderDarkerBrand;
        color: white;
        padding: 1.5rem 3rem;
        font-size: 1.6rem;
        font-family: NunitoBold, sans-serif;
        outline: none;
        border: none;
        cursor: pointer;
    }
    .founder-message-subtitle {
        font-size: 16px;
        display: block;
        color: #9a9a9a;
        font-weight: 500;
        order: 1;
        margin-bottom: .8rem;
    }
    .input-text-area {
        width: 100%;
        box-sizing: border-box;
        outline: none;
        border-radius: .5rem;
        color: #434a54;
        font-family: "Montserrat", sans-serif;
        padding: 1.1rem 1.5rem;
        border: 0.1rem solid #e7e9ed;
        font-size: 1.4rem;
        background-color: white;
        outline: 0;
        order: 2;
        font-weight: normal;
        resize: none;
        height: 12rem;
    }
}
