.advanced-filter {
  padding: 0;
  h3 {
    font-size: 1.8rem;
    font-weight: normal;
    text-align: left;
    margin: 0;
    color: $brand;
  }
  &__header {
    min-height: 11.7rem;
    background-color: $white;
    box-shadow: 0 1px 0 0 $investmentTitle;
    border-bottom: 1px solid $investmentTitle;
    padding-left: 4.7rem;
    &--top {
      display: block;
      padding: 1.5rem 0;
    }
    &--bottom {
      margin-bottom: 3rem;
      display: block;
      &-holder {
        display: flex;
        span {
          border: 1px solid $color-grey-2;
          font-size: 1.4rem;
          min-width: 10rem;
          padding: 1.6rem 0;
          cursor: pointer;
          text-align: center;
          margin-right: -1px;
          &:first-child {
            border-radius: 5px 0 0 5px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
          }
        }
        .selected {
          background-color: $brand;
          border: 1px solid $brand;
          color: $white;
          transition: .25s ease-in;
        }
      }
    }
  }
  &__body {
    width: 100%;
    height: calc(100vh - 25.2rem);
    overflow-y: auto;
    .accordion-item {
      background-color: $white;
      border-bottom: 1px solid $investmentTitle;
      padding: 1.5rem 0 1.5rem 2rem;
      position: relative;
      display: block;
      .my-dropdown-dd {
        position: relative !important;
      }
      &-title {
        width: 100%;
        display: flex;
        .toggle-accordion {
          width: 2.5rem;
          i {
            font-size: 2rem;
            color: $brand;
            cursor: pointer;
          }
        }
        .accordion-heading {
          cursor: pointer;
          width: 100%;
        }
      }
      &-content {
        width: 100%;
        .accordion-item-content-body {
          background-color: $white;
          padding-left: 2.5rem;
          padding-right: 1.5rem;
          padding-top: 1.5rem;
          margin-bottom: -1.5rem;
          &-input {
            margin-bottom: 1.5rem;
            .multiselect {
              input {
                border: .1rem solid $color-grey-2;
              }
            }
            .multiselect__tags {
              min-height: 4rem;
              font-size: 1.2rem;
              font-weight: 500;
              display: block;
              &-wrap {
                display: block;
                background-color: white;
                border: 0.1rem solid $color-grey-2;
                border-radius: 5px;
                &:hover {
                  border: 0.1rem solid $color-grey-3;
                }
                .multiselect__tag {
                  position: relative;
                  display: inline-block;
                  line-height: 1;
                  padding: 0.5rem 2.5rem 0.5rem 0.5rem;
                  margin: 0.8rem 0.5rem 0.5rem;
                  white-space: nowrap;
                  overflow: hidden;
                  color: $black;
                  max-width: 100%;
                  text-overflow: ellipsis;
                  border-radius: 3px;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                  border: 1px solid $smallBlockBorder;
                  background-color: $white;
                  &-icon {
                    cursor: pointer;
                    margin-right: 0.5rem;
                    position: absolute;
                    right: 0;
                    top: 0.4rem;
                    bottom: 0;
                    font-weight: 500;
                    font-style: normal;
                    width: 1.4rem;
                    height: 1.4rem;
                    text-align: center;
                    transition: all .2s ease;
                    border-radius: 50%;
                    background-color: $red-badge;
                    &::after {
                      content: "\D7";
                      color: $white;
                      font-size: 1.4rem;
                    }
                  }
                }
              }
              .multiselect__input {
                border: none;
                background-color: unset;
                border-color: unset;
                &:hover {
                  border-color: unset;
                  background-color: unset;
                }
              }
            }
            .multiselect {
              &__content {
                max-height: 20rem !important;
              }
            }
          }
          .no-results {
            .multiselect__content {
              border: 0;
            }
          }
          .range-input {
            &__heading {
              display: block;
              label {
                margin-bottom: 1rem;
                color: $black;
                font-size: 1.4rem;
                font-weight: 500;
              }
            }
            &__content {
              display: flex;
              justify-content: space-between;
              .currencyBlock {
                margin: 0;
                width: 22rem;
                .currency {
                  position: absolute;
                  font-size: 1.4rem;
                  color: $color-grey-4;
                  top: 1.2rem;
                  left: 1.5rem;
                }
                .number {
                  border-radius: 0 5px 5px 0;
                  padding-left: 3rem;
                  height: 4rem;
                  font-size: 1.4rem;
                  font-weight: normal;
                }
                input {
                  &:hover {
                    border-color: $color-grey-3;
                  }
                  &:focus {
                    background-color: $white;
                  }
                  &:active {
                    background-color: $white;
                    border-color: rgba(231, 233, 237, 0.5);
                  }
                }
                .activated {
                  input {
                    background-color: $white;
                  }
                }
              }
              .label-box {
                width: 8rem;
                border: 0.1rem solid $color-grey-2;
                border-right: 0;
                border-radius: 5px 0 0 5px;
                font-size: 1.4rem;
                height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              &--left {
                display: flex;
                align-items: center;
              }
              &--right {
                display: flex;
                align-items: center;
              }
            }
          }
          .one-label {
            .currencyBlock {
              width: 100%;
            }
          }
        }
      }
    }
    &--toggle {
      box-shadow: 0 1px 0 0 $investmentTitle;
      border-bottom: 1px solid $investmentTitle;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.6rem;
      background-color: $white;
      .option {
        font-size: 1.4rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        label {
          margin: 0;
          cursor: pointer;
        }
        .switch {
          position: relative;
          display: inline-block;
          margin: 0;
          width: 26px;
          height: 11px;
          background-color: $smallColor;
          border-radius: 5px;
          cursor: pointer;
          top: 1px;
        }
        .switch input {display:none;}
        .slider-button {
          background-color: $white;
          -webkit-transition: .4s;
          transition: .4s;
        }
        .slider-button:before {
          position: absolute;
          content: "";
          height: 15px;
          width: 15px;
          left: 0;
          bottom: -3px;
          background-color: $white;
          -webkit-transition: .4s;
          transition: .4s;
          border: .5px solid $borderColor;
        }
        input:checked + .slider-button:before {
          background-color: $brand;
          border: .5px solid $brand;
        }
        input:focus + .slider-button {
          box-shadow: 0 0 1px $brand;
        }
        input:checked + .slider-button:before {
          -webkit-transform: translateX(10px);
          -ms-transform: translateX(10px);
          transform: translateX(10px);
        }
        .slider-button.round {
          border-radius: 100%;
        }
        .slider-button.round:before {
          border-radius: 100%;
        }
        span[class=text] {
          color: $color-grey-6;
          margin-left: 1.2rem;
          opacity: 0.5;
          font-weight: 500;
        }
        .option-checked {
          color: $brand;
        }
      }
    }
    .filter-footer {
      position: fixed;
      left: calc(100% - 680px);
      width: 680px;
      bottom: 0;
      right: 0;
      height: 6.7rem;
      background-color: $brand;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
      @include breakpoint(tabletScreen) {
        left: calc(100% - 540px);
        width: 540px;
      }
      @include breakpoint(mobileScreen) {
        left: 0;
        width: 100%;
      }
      &-holder {
        padding: 2rem 1.5rem 2rem 3rem;
        display: flex;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        align-items: center;
        justify-content: flex-end;
        &-left {
          span {
            cursor: pointer;
            font-size: 1.2rem;
            font-weight: 500;
            letter-spacing: 0.4px;
            text-transform: uppercase;
            color: $white;
          }
        }
        &-right {
          display: flex;
          align-items: center;
          span {
            font-size: 2.4rem;
            font-weight: 300;
            color: $white;
            margin-right: 1.5rem;
          }
        }
      }
    }
  }
}
