//-------------------------
//-------------------HEADER
//-------------------------
nav.navbar{
  padding-top: 0;
  z-index: 0;
}
.logo-image{
  @include breakpoint (toDesktop){
    width: 28rem !important;
  }
}
.navbar-right{
    margin-top: 0;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: uppercase;
    color: $brand;
    i{
      font-size: 3.5rem;
    }
    li{
      margin-right: 5rem;
    }
}
.navigation{
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -1px;
  i{
    font-size: 28px;
    color: $brand;
  }
}
.is-active{
  color: $brand;
}
.specialLink{
  background-color: $brand;
  vertical-align: middle;
  height: 9rem;
  line-height: 9rem;
  font-size: 3.2rem;
  width: 9rem;
  margin-right: 0 !important;
  z-index: 0;
  text-align: center;
  display: inline-block !important;
  position: relative;
  @include breakpoint (toDesktop){
    height: 12rem;
    line-height: 12rem;
    font-size: 3.2rem;
    width: 12rem;
  }
  a{
    border: 1px solid $white;
    padding: 0rem 1.2rem;
    border-radius: 50%;
    span{
      color: $white;
    }
  }
}
.openDropdownCreate{
  display: block !important;
  z-index: 2;
  position: relative;
  ul{
    position: absolute;
    right: 0;
    background: $white;
    padding-left: 0;
    left: -9rem;
    text-align: left;
    li{
      margin-right: 0;
      display: block;
      line-height: 7rem;
      border-bottom: 1px solid $black;
      a{
        color: $black;
        font-size: 1.8rem;
        padding: 0;
        border: 0;
        padding-left: 3rem;
        display: block;
        &:hover{
          color: $brand;
        }
      }
    }
  }
}
.bars{
  position: relative;
  i{
    @include breakpoint (toDesktop){
      color: $black;
      font-size: 7rem !important;
    }
  }
}
.openDropdownBars{
  display: block !important;
  z-index: 2;
  ul{
    position: absolute;
    right: 0;
    background: $white;
    padding-left: 0;
    left: -32rem;
    text-align: left;
    top: 3rem;
    z-index: 2;

    @include breakpoint (toDesktop){
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 19rem;
    }
    li{
      margin-right: 0;
      display: block;
      line-height: 7rem;
      border-bottom: 1px solid $black;
      @include breakpoint (toDesktop){
        line-height: 10rem;
        border-bottom: 0;
      }
      a{
        color: $black;
        font-size: 1.4rem;
        padding: 0;
        border: 0;
        padding-left: 3rem;
        display: block;
        @include breakpoint (toDesktop){
          font-size: 3.4rem;
          padding: 0;
          border: 0;
          padding-left: 0;
          display: block;
          text-align: center;
        }
        &:hover{
          color: $brand;
        }
      }
    }
  }
  i{
    @include breakpoint (toDesktop){
      color: $black;
      font-size: 7rem !important;
    }
  }
}
.noDesktop{
  display: none !important;
  @include breakpoint (toDesktop){
    display: block !important;
  }
}