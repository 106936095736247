.active-deals {
    &__title {
        margin: 0;
        padding: 0;
        font-size: 2.7rem;
        letter-spacing: 0;
        font-weight: bold;
        text-transform: none;
        color: $black;
        margin-bottom: 3rem;
    }

    &__container {
        display: flex;
        align-items: flex-start;
        clear: both;
        height: 47rem;
        overflow: hidden;
        @include multiple-media (mobileScreen, tabletScreen) {
            height: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    &__big-block {
        margin-left: 26px;
        border: 1px solid $smallBlockBorder;
        box-shadow: -2px 5px 12px -6px $lightGray;
        display: flex;
        align-items: flex-start;
        background-color: $white;
        border-radius: 3px;
        min-height: 33.3rem;
        flex-wrap: wrap;
        width: calc(100% - 220px);
        @include transition (.1s all ease-in-out);
        @include breakpoint (smallLaptop) {
            justify-content: space-between;
        }
        @include breakpoint(tabletLandscape) {
            justify-content: space-between;
        }
        @include multiple-media (mobileScreen, tabletScreen) {
            width: 100%;
            margin-left: 0;
            align-items: normal;
            padding: 0;
        }

        &-left {
            width: 310px;
            margin-right: 20px;
            @include multiple-media (mobileScreen, tabletScreen) {
                display: none;
            }

            .data {
                section {
                    width: auto;
                    max-width: 25%;

                    &:first-child {
                        padding-left: 0.5rem;
                    }
                }
            }
        }

        &-right {
            width: calc(100% - 330px);
            min-height: 34.5rem;
            @include multiple-media (mobileScreen, tabletScreen) {
                min-height: 39rem;
                width: calc(100% - 30px);
                flex-basis: calc(100% - 30px);
                position: relative;
                top: 25px;
                left: 15px;
                margin-bottom: 1.3rem;
            }


        }
        &-left,
        &-right {
            h3 {
                @extend .do-originator__h3;
                margin-bottom: 1.7rem;
            }
        }

        &-body {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding: 2.5rem;
            @include multiple-media (mobileScreen, tabletScreen) {
                padding: 1rem;
            }
        }

        &-footer {
            width: 100%;
            height: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top: 1px solid $borderColor;
            @include breakpoint (mobileScreen) {
                flex-direction: column;
                height: max-content;
                padding: 5px 0;
            }
            button, a {
                background: transparent;
                border: 0;
                text-transform: uppercase;
                font-size: 1.2rem;
                cursor: pointer;
                margin: 0 1.5rem;
                font-family: $regular;
                padding: 1rem 2rem;
                @include transition (.3s all ease-in-out);
                font-weight: 500;
            }

            .do-button--brand {
                color: $color-blue-main;

                &:hover {
                    background-color: $color-blue-main;
                    color: $color-white;
                }
            }

            .do-button--green {
                color: $color-green-main;

                &:hover {
                    background-color: $color-green-main;
                    color: $color-white;
                }
            }
        }
    }

    &__company-media-info {
        background-color: transparent;
        border: 1px solid $investmentBorder;
        width: 100%;
        margin: 0;
        height: 310px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        section {
            padding: 0 5px;

            span {
                font-size: 9px;
                font-weight: normal;
                letter-spacing: 0;
                margin-bottom: 5px;
            }
        }

        h4 {
            font-size: 1.3rem;
            font-weight: 500;
        }

        .company-profile-logo-block {
            height: 55px;
            width: 55px;
            padding: 5px;
            border-radius: 5px;
            position: relative;
            bottom: 10px;
            left: 10px;
        }

        .data {
            section {
                &:nth-child(1) {
                    @-moz-document url-prefix() {
                        word-break: break-all;
                    }
                }
            }
        }
        .link-to-company-see-more {
            display: none;
        }
    }

    &__deal-info {
        margin-bottom: 3rem;
        @include multiple-media (mobileScreen, tabletScreen) {
            h3 {
                font-size: 1.8rem;
                font-weight: bold;
            }
        }

        &-body {
            background-color: $darkerWhite;
            height: 2.5rem;
            color: $white;
            align-items: center;
            display: flex;
            font-size: 1.5rem;
            font-weight: 500;

            .bar {
                padding: 0.3rem 0.5rem;
                text-align: left;
            }

            .network-bar {
                background-color: $positive;
                min-width: 5%;
                max-width: 95%;

                &.single {
                    background-color: $positive;
                    min-width: 5%;
                    max-width: 100%;
                }

                &.to-be-defined {
                    background-color: $darkerWhite;
                    min-width: 50%;
                    color: $color-grey-6;
                }
            }

            .no-approached {
                margin-left: 0.5rem;
                color: $color-grey-6;
            }

            .orders-bar {
                background-color: $color-blue-main;
                min-width: 5%;
            }

            .timeline-bar {
                background-color: $yellow;
                padding: 1.2rem 0.5rem;
            }
        }

        &-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 1rem;
            font-size: 1rem;
            font-weight: 400;
            @include breakpoint(mobileScreen) {
                flex-direction: column;
                align-items: flex-start;
            }
            &-item {
                display: flex;
                align-items: center;
                @include multiple-media (mobileScreen, tabletScreen) {
                    font-size: 1.2rem;
                    font-weight: 500;
                }
                @include breakpoint(mobileScreen) {
                    padding: 5px 0;
                }
            }
        }
    }

    &__dot {
        width: 1rem;
        height: 1rem;
        border-radius: 1.6rem;
        display: inline-block;
        margin-right: 0.7rem;

        &--green {
            background-color: $positive;
        }

        &--red {
            background-color: $red;
        }

        &--gray {
            background-color: $layerBackground;
            border-color: $layerBorder;
        }

        &--brand {
            background-color: $brand;
        }
    }
    .data{
        padding: 1rem;
    }

    .button-disabled {
        color: $positive;
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.3;

        &--withTooltip {
            pointer-events: all;
            cursor: not-allowed;

            &:active {
                pointer-events: none;
            }

            &:hover {
                background-color: transparent;
                color: $color-green-main;
                opacity: .3;
            }
        }
    }

    .loading-details {
        filter: blur(5px) grayscale(1);
    }

}
