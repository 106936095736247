.do-advisor__boarding{
  .layer-body__top-left{
    flex-wrap: wrap;
    h1{
      width: 100%;
      text-transform: none;
    }
    span{
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      margin-top: 1rem;
      color: $black;
      @include breakpoint (toDesktop){
        font-size: 2.4rem;
      }
    }
  }
  .legal-page{
    background-color: $white;
    &__navigation{
      border-bottom: 1px solid $borderColor;
      @include breakpoint (toDesktop){
        display: flex;
        justify-content: space-between;
      }
    }
    .item{
      margin-right: 2rem;
      a{
        color: $black;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-family: $regular;
        font-weight: 600;
        padding: 1.3rem 1.2rem 1rem;
        @include breakpoint(toDesktop){
          font-size: 2.2rem;
        }
      }
    }
    h1{
      color: $black;
      font-size: 2rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
      @include breakpoint (toDesktop){
        font-size: 3rem;
      }
    }
    &__content{
      padding: 0.5rem 0 5rem;
      font-family: $regular;
      font-weight: 400;
      font-size: 1.3rem;
      @include breakpoint (toDesktop){
        font-size: 2.3rem;
        line-height: 4rem;
      }
      #info{
        margin-bottom: 3rem;
      }
      h1{
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 2rem;
        @include breakpoint (toDesktop){
          font-size: 2.4rem;
        }
      }
      &--title{
        font-weight: bold !important;
        font-size: 1.6rem !important;
        @include breakpoint (toDesktop){
          font-size: 2.6rem !important;
        }
      }
      a{
        color: $brand;
        @include transition(.3s all ease-in-out);
        &:hover{
          color: $darkerBrand;
        }
      }
    }
    .item.selected a{
      color: $brand;
    }
  }
  &-footer{
    position: fixed;
    bottom: 0;
    background: $white;
    padding: 1.5rem 4rem;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $black;
    width: auto;
    @include breakpoint(mediumScreen) {
      left: calc(100% - 1100px);
    }
    @include breakpoint(largeScreen) {
      left: calc(100% - 1600px);
    }
    &-left,
    &-right{
      flex-basis: 50%;
      width: 50%;
      @include breakpoint (toDesktop){
        flex-basis: 100%;
        width: 100%;
      }
    }
    &-left{
      font-size: 1.3rem;
      font-weight: 500;
      .checkbox-container{
        height: auto;
        cursor: pointer;
        @include breakpoint (toDesktop){
          font-size: 2.3rem;
          text-align: right;
        }
        input{
          @include breakpoint (toDesktop){
            width: 7.7rem;
            height: 3rem;
          }
        }
      }
    }
    &-right{
      text-align: right;
      position: relative;
      .do-advisor-mask{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $white;
        @include transition(.3s all ease-in-out);
        content: '';
        opacity: 0.5;
        display: block;
      }
    }
    .login__list-item-holder{
      width: 100%;
      flex-basis: 100%;
    }
  }
  &-button{
    background-color: $brand;
    color: $white;
    padding: 1.5rem 4rem;
    font-weight: 500;
    font-size: 1.2rem;
    outline: none;
    text-transform: uppercase;
    cursor: pointer;
    font-family: $regular;
    border-radius: 3px;
    border: 0;
    @include transition(.3s all ease-in-out);
    @include breakpoint (toDesktop){
      margin-top: 2rem;
      padding: 2.5rem 8rem;
      font-size: 2.2rem;
    }
    &:hover{
      background-color: $darkerBrand;
    }
  }
}
.platform-agreement{
  &__left{
    max-height: calc(100vh - 15rem);
    min-height: calc(100vh - 15rem);
    overflow-x: hidden;
    overflow-y: auto;
    width: 18%;
    flex-basis: 18%;
    @include breakpoint (toDesktop){
      width: 100%;
      flex-basis: 100%;
      min-height: inherit;
    }
    .layer-body__middle-filters-holder{
      margin-top: 0;
      padding-bottom: 2rem;
    }
    h4{
      padding: 2rem;
      &:after{
        left: 0;
        right: 0;
      }
    }
    .pdf-holder{
      width: 9rem;
      text-align: center;
      margin: 2rem auto;
      @include breakpoint (toDesktop){
        width: 13rem;
      }
      img{
        width: 100%;
      }
    }
    a{
      text-align: center;
      background-color: $brand;
      color: $white;
      padding: 1.5rem;
      font-weight: 500;
      font-size: 1.2rem;
      outline: none;
      text-transform: uppercase;
      cursor: pointer;
      font-family: $regular;
      border-radius: 3px;
      margin: 0 2rem 0 2rem;
      display: block;
      border-color: $brand;
      @include breakpoint (toDesktop){
        padding: 2.5rem 7rem;
        font-size: 2.2rem;
      }
      @include transition(.3s all ease-in-out);
      &:hover{
        background-color: $darkerBrand;
      }
    }
  }
  &__right{
    max-height: calc(100vh - 15rem);
    min-height: calc(100vh - 15rem);
    overflow-x: hidden;
    overflow-y: auto;
    width: 80%;
    flex-basis: 80%;
    @include breakpoint (toDesktop){
      width: 100%;
      flex-basis: 100%;
      min-height: inherit;
      margin-top: 2rem;
    }
    .legal-page__content--title{
      text-transform: uppercase;
      color: $black;
      margin: 0 !important;
    }
    .legal-page{
      position: relative;
      padding: 3rem 3rem 8rem;
    }
    .do-advisor__boarding-footer{
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1.5rem 3rem;
      border-top: 1px solid $borderColor;
    }
  }
  .checkbox-container input{
    @include breakpoint(toDesktop){
      width: 3.8rem;
      height: 3rem;
    }
  }
  .do-experience__parent{
    @include breakpoint(toDesktop){
      display: block;
    }
  }
}
.billing{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  .layer-body__top{
    margin-bottom: 4rem;
  }
  .do-experience__parent{
    @include breakpoint (toDesktop){
      flex-wrap: wrap;
      max-height: calc(100vh - 30rem);
      overflow: hidden;
      overflow-y: auto;
    }
  }
  &__left,
  &__right{
    flex-basis: 48.5%;
    width: 48.5%;
    @include breakpoint(toDesktop){
      width: 100%;
      flex-basis: 100%;
    }
    h2{
      display: block;
      font-size: 1.2rem;
      font-weight: 500;
      margin: 0 0 1.5rem;
      color: $black;
      letter-spacing: 0;
      text-transform: none;
      @include breakpoint (toDesktop){
        padding: 0 2rem;
        font-size: 2.2rem;
      }
    }
    .common__modalInput{
      margin-bottom: 2rem;
      input::placeholder{
        font-style: italic;
        color: $smallColor;
      }
    }
    &-holder{
      background-color: $white;
      padding: 2rem;
    }
    label{
      display: block;
      margin-bottom: 1rem;
      color: $smallColor;
      font-size: 1.1rem;
      font-weight: 400;
      @include breakpoint (toDesktop){
        font-size: 2.1rem;
      }
    }
    .multiselect__input{
      @extend .input;
      color: $smallColor;
      &::placeholder{
        color: $smallColor;
      }
    }
    .multiselect__select{
      top: 3.5rem;
      &:before{
        border-color: $smallColor transparent transparent;
        z-index: 1;
      }
    }
    button{
      background-color: $brand;
      color: $white;
      padding: 1.5rem 6.5rem;
      font-weight: 500;
      font-size: 1.2rem;
      outline: none;
      text-transform: uppercase;
      cursor: pointer;
      font-family: $regular;
      border-radius: 3px;
      display: block;
      border-color: $brand;
      @include transition(.3s all ease-in-out);
      @include breakpoint (toDesktop){
        padding: 2.5rem 7.8rem;
        font-size: 2.2rem;
      }
      &:hover{
        background-color: $darkerBrand;
      }
    }
  }
  &__right{
    @include breakpoint(toDesktop){
      margin-top: 2rem;
    }
  }
  &__holder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .common__modalInput{
      width: 30%;
    }
  }
}
.legal-page{
  &__navigation{
    padding: 1rem 4rem;
  }
  &__content{
    ol{
      list-style: none;
    }
    div{
      > ol{
        padding-left: 0;
        ol{
          padding-left: 3rem;
          li{
            display: flex;
            align-items: flex-start;
            margin-bottom: 2rem;
            flex-wrap: wrap;
            span{
              display: inline-block;
              margin-right: 1.5rem;
              > span{
                margin: 0;
                width: 95%;
                display: inline-block;
                vertical-align: top;
                margin-left: 1rem;
              }
            }
            p{
              margin: 0 0 0 10px;
              width: 95%;
            }
            ol{
              display: block;
              margin-top: 1rem;
              width: 100%;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
.specialAdvisor{
  .legal-page{
    &__content{
      max-height: calc(100vh - 21rem);
      min-height: calc(100vh - 21rem);
      overflow-x: hidden;
      overflow-y: auto;
      padding: 3rem 0 10rem 4rem!important;

      @include breakpoint(toBigPhone){
        max-height: calc(100vh - 37rem);
        min-height: calc(100vh - 37rem);
        font-size: 2rem;
      }
      @include breakpoint(tabletLandscape) {
        max-height: calc(100vh - 30rem);
        min-height: calc(100vh - 30rem);
      }
      @include multiple-media(smallScreen, mediumScreen) {
        max-height: calc(100vh - 30rem);
        min-height: calc(100vh - 30rem);
      }
      @include multiple-media(largeScreen) {
        max-height: calc(100vh - 29rem);
        min-height: calc(100vh - 29rem);
      }
    }
  }
}
