.layer-mask{
  background-color: rgba(101,109,120, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  &.dataRoomMask {
    z-index: 11;
  }
}
.modal-layer-mask{
  background-color: rgba(101,109,120, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.modal-layer-mask-full{
  left: 0;
  z-index: 12;
}