.hubx-checkbox {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 1.4rem;
    user-select: none;
    height: 2.2rem;

    @include multiple-media(smallDevices) {
        height: auto;
    }

    &:hover {
        .checker {
            background-color: $color-grey-1;
            border-color: $color-grey-3;
        }
    }
    > input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        margin: 0;
        &:checked {
            ~ .checker {
                border-color: $color-grey-2;
                background-color: $color-white;
                &:after {
                    display: block;
                }
            }
            &:hover ~ .checker {
                background-color: $color-grey-1;
                border-color: $color-grey-3;
            }
        }

    }
    > label {
        cursor: pointer;
        margin: 0 !important;
        padding: 0.4rem 0 0 3.8rem;
        font-family: $regular;
        font-size: 1.3rem;
        font-weight: normal;
        color: $color-grey-6;

        a {
            color: $color-blue-main;
            cursor: pointer;
        }
    }
    .checker {
        position: absolute;
        cursor: pointer;
        left: 0;
        height: 2rem;
        width: 2rem;
        background-color: $color-white;
        border: .1rem solid $color-grey-2;
        border-radius: .5rem;
        pointer-events: none;
        &:after {
            content: "";
            position: absolute;
            display: none;
            left: .7rem;
            top: .3rem;
            width: .5rem;
            height: .9rem;
            border: solid $color-blue-main;
            border-width: 0 .2rem .2rem 0;
            transform: rotate(45deg);
        }
    }
    &.proraise-branding {
        > label {
            a {
                color: $color-cyan-secondary;
            }
        }
        .checker {
            &:after {
                border: solid $color-purple-main;
                border-width: 0 .2rem .2rem 0;
            }
        }
    }
}
