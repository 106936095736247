.expired-link {
    padding-left: 3rem !important;
    .text-center.align-vertical {
        margin-top: 15rem;
    }
    .intro {
        font-weight: 600;
    }
    .email, .submit {
        background: transparent;
        border: 2px solid $white;
        padding: 1.2rem;
        color: $white;
    }
    .email {
        margin-right: 1.5rem;
        width: 40rem;
    }
    .submit {
        cursor: pointer;
        width: 12rem;
    }
    span {
        position: absolute;
        margin-top: 6rem;
        left: 0;
        font-size: 1.6rem;
        color: $white;
    }
    input:focus {
        outline: none;
    }
    ::placeholder {
        color: $white;
        opacity: 1;
    }
    :-ms-input-placeholder {
        color: $white;
    }
    ::-ms-input-placeholder {
        color: $white;
    }
}
