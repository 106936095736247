.hubx-richtextarea {
    > label {
        display: block;
        margin-bottom: 1rem;
        color: $color-grey-6;
        font-size: 1.4rem;
        font-weight: 500;
    }
    .disabled-label {
        opacity: .5;
    }
    .quill-editor {
        .ql-toolbar {
            border: .1rem solid $color-grey-2;
            border-radius: .5rem .5rem 0 0;
            background-color: $color-white;
            padding: .8rem 1.5rem;
            .ql-formats {
                > button {
                    width: auto;
                    height: auto;
                    padding: .2rem .4rem;
                    &:hover {
                        background-color: transparentize($color-white, .5);
                    }
                    > svg {
                        width: 2rem;
                        height: 2rem;
                    }
                }
                > .ql-color-picker:hover {
                    .ql-picker-label {
                        background-color: transparentize($color-white, .5);
                    }
                }
            }
        }
        .ql-container {
            border: .1rem solid $color-grey-2;
            border-radius: 0 0 .5rem .5rem;
            overflow: hidden;
            .ql-editor {
                font-family: $regular;
                font-size: 1.4rem;
                font-weight: normal;
                line-height: 1.57;
                letter-spacing: normal;
                color: $color-grey-6;
                background-color: $color-white;
                &:hover {
                    background-color: $color-white;
                }
            }
            .ql-blank {
                background-color: $color-grey-1;
                &:hover {
                    background-color: $color-grey-1;
                }
            }
            &:focus {
                background-color: $color-white;
                border-color: $color-grey-3;
            }
        }
        &:hover {
            .ql-toolbar,
            .ql-container {
                border-color: $color-grey-3;
            }
        }
        &:focus {
            .ql-toolbar {
                background-color: $color-blue-main;
            }
            .ql-editor {
                background-color: $color-white;
            }
        }
    }
    > div:not(.quill-editor) {
        text-align: right;
        margin: .4rem .1rem 0 0;
        span {
            font-family: $regular;
            font-size: 1.2rem;
            font-weight: 500;
            text-align: right;
            color: $color-grey-4;
        }
    }
    &.focused {
        .ql-toolbar {
            background-color: $color-blue-main;

            // "hover" style is applied to selected buttons, as design is missing at this point
            button.ql-active,
            span.ql-active {
                background-color: rgba(255, 255, 255, 0.5);
            }
        }
        .ql-editor {
            background-color: $color-blue-main;
        }
        .ql-stroke, .ql-fill {
            stroke: $color-white;
        }
        &:hover {
            .ql-editor {
                background-color: $color-white;
            }
            .ql-blank {
                background-color: $color-grey-1;
            }
        }
    }
    &.ql-editor.ql-blank {
        &:hover {
            background-color: $color-grey-1;
        }
    }
    &.showError {
        .quill-editor {
            border: .1rem solid $red-badge;
            border-radius: .5rem;
            overflow: hidden;
            .ql-toolbar,
            .ql-container {
                box-sizing: border-box;
            }
            .ql-toolbar {
                border-bottom: .1rem solid $color-grey-2 !important;
            }
            .ql-container {
                border-top: 0;
            }
        }
        span.form-error {
            display: block;
            position: relative;
            order: 3;
            color: $red-badge;
            font-size: 1.2rem;
            margin-top: .8rem;
        }
    }

    .tiptap-editor {
        border: 1px solid $color-grey-2;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        position: relative;

        &:hover {
            border: 1px solid $color-grey-3;

            .editor-content {
                border-top: 1px solid $color-grey-3;
            }
        }

        &:focus-within {
            .menubar {
                background-color: $color-blue-main;

                &:focus {
                    outline: 0;
                }

                &__button {
                    background-color: transparent;
                    color: $color-white;
                }
            }
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        .menubar {
            background-color: $color-white;
            padding: 1.2rem 1.5rem;
            display: flex;
            box-sizing: border-box;
            z-index: 1;

            &__button {
                border: none;
                padding: .1rem .8rem;
                height: 24px;
                background-color: transparent;

                &:hover {
                    cursor: pointer;
                    color: #06c;
                }

                .fa {
                    font-size: 1.4rem;
                }

                &.is-active {
                    background-color: rgba(255, 255, 255, 0.5);
                }
            }
        }

        .editor-content {
            display: flex;

            border-top: 1px solid $color-grey-2;
            text-align: left;

            &.emptyContent {
                background-color: $color-grey-1;
                z-index: 1;
            }


            .ProseMirror {
                flex-grow: 1;
                outline: 0;
                padding: 1.2rem 1.5rem;
                min-height: 8rem;

                p {
                    margin: 0;
                    font-size: 1.4rem;
                    word-break: break-word;
                }

                a {
                    cursor: pointer;
                    color: #06c;
                    text-decoration: underline;
                }

                span {
                    font-size: 1.4rem;
                    font-weight: unset;
                }
            }
        }

        & p.is-empty:first-child::before {
            content: attr(data-empty-text);
            font-size: 1.4rem;
            font-weight: normal;
            line-height: 1.57;
            letter-spacing: normal;
            color: #8b8c8e;
            pointer-events: none;
            height: 0;
            font-style: italic;
        }

        .menububble {
            position: absolute;
            background-color: $color-white;
            color: #444;
            padding: .4rem 1.2rem;
            white-space: nowrap;
            height: 30px;

            &.is-active {
                border: 1px solid #ccc;
                box-shadow: 0 0 5px #ddd;
                z-index: 10;
            }

            &__form {
                display: flex;
                align-items: center;
                height: 30px;
            }

            &__title {
                color: #444;
                margin-right: .8rem;
                font-size: 1.1rem;
                font-weight: 500;
            }

            &__section {

                &--button {
                    line-height: 2.6rem;
                    border: none;
                    color: #06c;
                    font-size: 1.2rem;
                    cursor: pointer;
                    background-color: transparent;
                }
                &:not(:last-child) {
                    border-right: 1px solid #ddd;
                }

                &:only-child {
                    border-right: none;
                }
            }

            &__input {
                height: 20px;
                min-width: 170px;
            }
        }

        .color-picker {
            position: absolute;
            top: 35px;
            left: 100px;
            z-index: 1;
        }
    }
}
