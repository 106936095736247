.new-deal-creation {
  overflow: hidden;
  overflow-y: auto;

  & .multiselect__placeholder {
    display: block;
    padding: 1.4rem 2rem;
  }

  > div {
    display: flex;
  }
  &__subtitle {
    font-size: 1.4rem;
    font-weight: normal;
    padding-bottom: 2rem;
    line-height: 1.83;
    span {
      font-weight: 500;
    }
  }
  .dropArea {
    border-radius: 5px;
    span {
      color: $investmentTitle;
      font-weight: 500;
      font-size: 1.4rem;
      a {
        font-weight: 500;
        font-size: 1.4rem
      }
    }
  }
  .addNewCompany{
    display: block;
    background-color: $layerBackground;
  }
  .new-deal-message{
    margin-top: 2rem;
    h4 {
      margin-bottom: 0 !important;
    }
    .message {
      i {
        margin-right: 1.2rem;
        margin-left: 0.5rem;
      }
      margin-top: 1.5rem;
      background-color: $colorCheck;
      border: 1px solid $borderColor;
      padding: 1rem;
      border-radius: 5px;
      display: flex;
      align-items: center;
      color: $white;
      font-size: 1.4rem;
    }
  }
  .my-form{
    div{
      img{
        max-width: 100%;
      }
    }
  }
}
