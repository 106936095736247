/*Modal*/
.modal-body-steps {
    padding: 0em 5em 2em 5em;
}

.tab-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.tab-steps > div {
    cursor: pointer;
    margin: 0px 50px;
    text-align: center;
    font-size: 1.2rem;
}

.modelclose-steps {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.modelclose-steps > span:first-child {
    color: #656d78;
    font-size: 3em;
}

.modal-step {
    color: #aab2bd;
}

.modal-step > div {
    border: 2px solid #aab2bd;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 10px;
}

.modal-step-active {
    color: #434a54;
}

.modal-step-active > div {
    border-color: #434a54;
}

.modal-step-done {
    color: #a0d468;
}

.modal-step-done > div {
    border-color: #a0d468;
}
.fix-w3 {
    width: 240px;
}

.modal-body > div > div > div > div > select {
    height: 49px;
}

.mt {
    margin-top: 20px;
}
/*End Modal*/

.input-gr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 12px;
}

.input-gr > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
}

.left-size {
    margin-left: 26px;
}

.input-gr > div > label {
    font-size: 12px;
    color: #ccd1d9;
    margin-bottom: 5px;
    line-height: 20px;
}

.input-gr > div > input {
    background-color: #f5f7fa;
    border-radius: 5px;
    height: 45px;
    border: 1px solid #e6e9ed;
    font-size: 20px;
    padding-left: 20px;
    color: #434a54;
    font-weight: 200;
    letter-spacing: 1px;
    width: 100%;
}

.input-gr > div > input:focus {
    outline: none;
    background-color: #fff;
}

.input-gr > div > a {
    text-align: right;
    font-size: 10px;
    margin-top: 5px;
    color: $brand;
}

.input-gr > div > a:hover {
    text-decoration: underline;
}

.input-gr-btn {
    width: 150px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 10px;
}
.input-gr-o {
    opacity: 0.3;
}

.fix-w {
    width: 48%;
}
.profile-container > section > div > p {
    color: #434a54;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
}

.input-gr > div > div:hover {
    cursor: pointer;
}

.input-gr > div > div > i {
    font-size: 65px;
    color: #e6e9ed;
}

.input-gr > div > select {
    background-color: #f5f7fa;
    border-radius: 5px;
    height: 45px;
    border: 1px solid #e6e9ed;
    font-size: 16px;
    color: #434a54;
    font-weight: 200;
    text-indent: 14px;
    font-size: 20px;
    letter-spacing: 1px;
}

.input-gr > div > select:focus {
    outline: none;
}

/* --------- modal edit certification data ------------ */

.btn-yellow:disabled {
    background-color: #ffcc00;
}
.input-gr.block {
    display: block;
}

.input-gr.block > div {
    margin-top: 10px;
}
