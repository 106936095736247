.whitelabel-login {
  @media (max-width: 1024px){
    &.page {
      max-width: 680px;
    }
  }
  &.page {
    position: relative;
    height: 100vh;
    transform: none;
    float: right;
    @include breakpoint(mobileScreen) {
      position: absolute;
      .page-footer {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  @media (min-width: 1025px){
    width: 75rem!important;
  }
  .button {
    border-radius: 5px;
  }
}
.whitelabel-login-body.exotix_whitelabel {
  .reset-password-background {
    background-position: initial !important;
  }

  .whitelabel-login {
    border-left: 1px solid #e7e7e7;
  }
}

.whitelabel-login-body {
  .login__holder {
    display: flex;
    background: none;
    div.cover-image-whitelabel {
      flex-grow: 1;
      background-size: cover!important;
    }
  }
  padding: 0;
  .login__message {
    left: auto;
    min-width: 44rem;
  }
  .page-footer {
    img {
      max-width: 20rem;
      height: 10rem;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        height: auto;
      }
      object-fit: contain;
      width: auto;
    }
  }
}
.sign-in-page {
  width: 680px;
  input{
    &:hover:enabled {
      border-color: #434a54;
    }
    &:disabled {
    }
    &:focus:enabled {
      background-color: #fff;
      border-color: #434A54;
      color: #434A54;
    }
    border: 1px solid #e6e9ed;
    background-color: #f5f7fa;
    outline: none;
    box-sizing : border-box;
    width: 100%;
    border-radius: 5px;
    font-family: 'Montserrat' , sans-serif;
    position: relative;
    color: #434a54;
    font-weight: 500;
    transition: .3s all ease-in-out;
  }
  .page-footer .logo {
    margin: auto;
  }
  .close-action {
    position: relative;
  }
  &.active {
    .close-action {
      position: fixed;
    }
  }
  .page-content {
    margin-top: 140px;
    padding-left: 80px;
    padding-right: 80px;
    min-height: calc(100% - 140px - 100px - 140px);
  }
  .tab-menu-item {
    padding: 11px 10px;
    margin: 0;
    letter-spacing: .5px;
  }
  .tabs-menu .tab-menu-item-underline {
    height: 1px;
    margin-top: -1px;
  }
}
.underline-placeholder {
  width: 100%;
  height: 1px;
  background-color: $color-grey-3;
}

.sign-in-page .tabs-content-container {
  margin-top: 33px;
}
.form-error {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ff0000;
  font-size: 13px;
  font-weight: 500;
  display: none;
  animation: opac 0.3s;
}
.form-error.show-error {
  display: block;
}
.form-group input.show-error {
  border-color: #ff0000;
}
.form-group {
  margin-bottom: 18px;
  position: relative;
}
.form-actions {
  margin-top: 35px;
  margin-bottom: 0;
  position: relative;
}
.form-group > * {
  color: $dark-gray;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
.form-group label {
  margin-bottom: 11px;
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  display: block;
}
.form-group input {
  width: 100%;
  padding: 17px 20px;
  background-color: $color-grey-1;
  border: solid 1px $investmentBorder;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
  box-sizing: border-box;
  outline: none;
}
.form-group .suffix {
  position: absolute;
  top: 48px;
  right: 22px;
}
.triangle {
  position: relative;
  background-color: $dark-gray;
  text-align: left;
}
.triangle:before,
.triangle:after {
  content: '';
  position: absolute;
  background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
  width:  4px;
  height: 4px;
  border-top-right-radius: 70%;
}
.triantrianglegle {
  transform: skewX(-30deg) scale(1,.866);
}
.triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%);
}
.triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%);
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aab2bd;
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #aab2bd;
}
:-ms-input-placeholder { /* Microsoft Edge */
  color: #aab2bd;
}
.form-group .forgot-password {
  margin-top: 10px;
  font-size: 11px;
  font-weight: 500;
  text-align: right;
  color: $brand;
  display: block;
  cursor: pointer;
  font-style: italic;
}
.form-actions .button {
  min-width: 140px;
  height: auto;
  padding: 15px 30px;
  line-height: 13px;
  background-color: $brand;
  font-size: 11px;
  color: #ffffff;
  margin-top: 0;
}
#Forgot {
  display: none;
}
.request-invite-error {
  position: absolute;
  bottom: -2.6rem;
  right: 0;
}
.request-invite-error {
  color: #fb1000;
  margin-left: 0;
  margin-top: 5px;
  height: 20px;
  font-size: 11px;
  display: none;
}
.login__back {
  color: #4fc1e9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #ccd1d9;
  padding: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}
.login__back span{
  display: inline-block;
  margin-right: 5px;
}
.login__message.red {
  border: 1px solid #ed5565;
  color: #ed5565;
  background: #fdf0f1;
  display: block;
}
.login__message span {
  position: absolute;
  top: .8rem;
  right: 1.5rem;
  cursor: pointer;
}
.fa-close:before, .fa-remove:before, .fa-times:before {
  content: "\f00d";
}
.animated-faster {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
