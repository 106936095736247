.companyNew {
    .ql-editor {
        min-height: 12rem;
        border: 0 solid $borderColor;
        border-top: 0;
        padding: 1.9rem 3rem 1.2rem;
        &.ql-blank::before {
            color: $smallColor;
            left: 1.5rem;
            right: 1.5rem;
            padding-left: 1.5rem;
            font-family: $regular;
            font-weight: 500;
            top: 1.8rem;
            font-size: 1.2rem;
            line-height: 3rem;
        }
        ol,
        ul {
            padding-left: 0rem;
        }
        p {
            line-height: 2rem;
        }
    }
    .ql-container {
        background-color: $darkerWhite;
        img {
            width: auto !important;
        }
        p,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        pre,
        small,
        span,
        em,
        strong,
        sub,
        sup,
        b,
        code,
        li {
            letter-spacing: 0px !important;
            text-transform: inherit !important;
            font-family: $regular !important;
            font-weight: 500 !important;
            font-size: 1.2rem !important;
            color: $black;
            text-align: left !important;
        }
        strong,
        b {
            font-weight: 900 !important;
            p,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            pre,
            small,
            span,
            em,
            strong,
            sub,
            sup,
            b,
            code,
            li {
                font-weight: 900 !important;
            }
        }
    }
    .ql-toolbar.ql-snow {
        padding: .8rem;
        border: 1px solid $borderColor;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        button{
            height: 2.4rem !important;
            width: 2.8rem !important;
            padding: .3rem .5rem !important;
            position: relative;
            left: 0;
        }
        .ql-formats {
            display: table-cell !important;
        }
    }
}
.quill-editor {
    .ql-toolbar {
        button {
            position: relative;
        }
    }
}
