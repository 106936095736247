.preview-compose-mail {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    background-color: $color-white-2;

    &-header {
        width: 100%;
        background-color: $white;
        padding: 20px;

        &-back {
            color: $color-blue-main;
            font-size: 1.2rem;
            cursor: pointer;
            font-weight: 700;

            &-arrow {
                margin-right: 10px;
            }
        }
    }

    &-body {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        font-family: Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif;
        font-size: 1.2rem;
        max-height: 92vh;
        overflow-y: auto;

        &-content {
            margin-top: 30px;
            width: 50%;
            display: flex;
            flex-direction: column;
            line-height: 3.3rem;

            &-header {
                margin-bottom: 20px;
                display: flex;
                justify-content: center;

                &-image {
                    width: 18rem;
                }
            }

            &-letter {
                background-color: $white;
                border-radius: 5px;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

                &-image {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > img {
                        max-width: 100%;
                    }
                }

                &-text {
                    margin-top: 30px;
                    padding: 0 50px 25px 50px;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: 16px;

                    &-title {
                        font-weight: 500;
                        margin: 75px 0 25px 0;
                        text-align: left;
                        font-size: 26px;
                    }

                    &-button {
                        background: $brand;
                        padding: 20px;
                        font-size: 18px;
                        font-weight: bold;
                        margin: 40px 0 ;
                    }
                }
            }

            &-footer {
                margin-top: 20px;
                color: $color-grey-20;
                font-size: 12px;
                display: flex;
                flex-direction: column;

                &-low {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-top: 30px;

                    &-options {

                        &-option {
                            text-decoration: underline;
                        }

                        &-hubx {
                            display: flex;
                            align-items: center;

                            img {
                                margin-left: 5px;
                                width: 80px;
                            }
                        }
                    }
                }
            }
        }
    }
}
