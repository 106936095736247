.deal-dataroom {
    .dataroomLink {
        margin-right: 0;
    }
    .dataroom {
        padding: 60px 50px !important;
        height: 100%;
        padding-bottom: 200px !important;
        .container{
            width: 1000px;
            padding: 0;
        }
        .breadcrumbs {
            font-size: 16px;
            margin-bottom: 20px;
            div {
                display: inline-flex;
                cursor: pointer;
            }
            span:first-child {
                cursor: pointer;
            }
            .title {
                color: $brand;
            }
            .separator {
                padding-left: 1rem;
                padding-right: 1rem;
                .fa-angle-right {
                    color: #797878;
                }
            }
        }
        .row {
            border: 1px solid $borderColor;
            padding: 2rem;
            width: auto;
            right: 3rem;
            &.flex {
                display: flex;
            }
            .row-content {
                display: inline-flex;
                color: black;
                align-items: center;
                &.investor-rename {
                    width: 100%;
                }
                .folder-icon {
                    font-size: 24px;
                    color: #19abff;
                }
                .pdf-icon {
                    font-size: 24px;
                    color: #ff1919;
                }
                .doc-icon {
                    font-size: 24px;
                    color: #0d5aa7;
                }
                .xls-icon {
                    font-size: 24px;
                    color: #0d904f;
                }
                .ppt-icon {
                    font-size: 24px;
                    color: #ce8735;
                }
                .image-icon {
                    font-size: 24px;
                    color: #deb40e;
                }
                i{
                    width: 3rem;
                    height: 3rem;
                    margin-right: 1.5rem;
                    display: -webkit-inline-box;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    font-size: 3rem;
                }
                .name {
                    font-size: 1.4rem;
                    font-weight: 400;
                    color: $black;
                    display: inline-flex;
                    vertical-align: super;
                    &.investor {
                        word-break: break-all;
                        max-width: 85%;
                    }
                }
            }
        }
        .bottom {
            text-align: right;
            font-size: 16px;
            margin-top: 2rem;
            a {
                color: black;
                &.linkText {
                    color: $brand;
                }
            }
        }
        &__log{
            width: 1000px;
            border: 1px solid #d2d4d8;
            padding: 20px;
            margin: 0 auto 50px;
            box-sizing: border-box;
            border-radius: 3px;
            max-height: 270px;
            overflow-y: auto;
            &-header{
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 25px;
                  div{
                      font-size: 14px;
                      font-weight: 500;
                      color: $black;
                      line-height: normal;
                  }
                  a{
                      font-size: 12px;
                      color: $brand;
                      font-weight: 500;
                      line-height: normal;
                      cursor: pointer;
                  }
            }
            &-body{
                ul{
                    padding-left: 0;
                    list-style: none;
                }
                li{
                    margin-bottom: 20px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                span{
                    font-size: 12px;
                    font-weight: normal;
                    color: $black;
                    a{
                        color: $brand;
                        font-size: 12px;
                        cursor: pointer;
                    }
                }
            }
            &-delete{
                width: 14px;
                height: 14px;
                background-color: $black;
                border-radius: 100%;
                cursor: pointer;
                text-align: center;
                display: block;
                align-items: center;
                justify-content: center;

                i{
                    color: $white;
                    font-size: 10px;
                    margin-top: -2px;
                    margin-left: 1px;
                    @include breakpoint(toLaptop){
                        margin-left: 0;
                        margin-top: 1px;
                    }
                    @include breakpoint(tabletLandscape){
                        margin-left: 0;
                        margin-top: -1.2px;
                    }
                }
            }
        }
        &__content{
            width: 1000px;
            box-sizing: border-box;
            margin: 0 auto;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .do-dataroom__body-left{
                flex-basis: 25%;
                width: 25%;
                margin-right: 40px;
                li{
                    font-size: 12px;
                    padding: 13px 20px;
                }
            }
            .container{
                position: relative;
                width: 100%;

                .more-items__hub-block .arrow-top {
                    
                    &:before {
                        right: 15px;
                    }
                    &:after {
                        right: 16px;
                    }
                }
            }
            .name{
                font-size: 14px;
            }
            .row{
                padding: 20px;
                right: inherit;
                border: 1px solid #d2d4d8;
                border-bottom: 0;
                &:nth-child(3){
                    border-top-right-radius: 3px;
                    border-top-left-radius: 3px;
                }
                &:nth-last-child(2){
                    border-bottom: 1px solid #d2d4d8;
                    border-bottom-right-radius: 3px;
                    border-bottom-left-radius: 3px;
                }
            }
            .do-button{
                font-size: 12px;
            }
            .layer-body__middle-filters-search{
                padding: 10px;
                margin-bottom: 20px;
                border-radius: 3px;
                input{
                    font-size: 11px;
                    padding: 4px 0;
                }
                i{
                    font-size: 15px;
                }
            }
            .do-dataroom__body-left li{
                border-radius: 3px;
            }
            .audit{
                height: inherit;
            }
            .audit__container{
                height: 700px;
                flex: inherit;
                margin-top: 0;
                padding: 20px;
            }
            .audit__container ul li{
                font-size: 12px;
            }
            .message-empty {
                display: flex;
                justify-content: center;

                h5 {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }
    .dataroom__content{
        > div:last-child{
            flex-basis: 71%;
            width: 71%;
            .audit,
            .audit__container{
                width: 100%;
            }
        }
    }
}