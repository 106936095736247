.do-contego{
  .layer-container {
    @include breakpoint(smallLaptop) {
      left: 17.2%;
    }
    @include breakpoint(tabletLandscape) {
      left: 13.2%;
    }
  }
  background: none;
  z-index: 3;
  .do-professional .layer-body__middle-filters{
    @include breakpoint (toDesktop){
      height: 100%;
    }
  }
  .checkbox-container{
    height: auto;
    input{
      display: inline-block;
      vertical-align: top;
      &:checked:after{
        position: absolute;
        top: 0.2rem;
        left: 0.2rem;
        right: 0;
        bottom: 0;
      }
    }
  }
    &__firstStep {
      &-country {
        @include breakpoint(smallLaptop) {
          padding: 0 23rem !important;
        }
      }
      .do-professional {
        &__holder {
          @include breakpoint(smallLaptop) {
            margin: 13px !important;
          }
        }
      }
    }
  .do-protection__holder-block label{
    display: block;
    width: 100%;
    span{
      width: 90%;
      display: inline-block;
      @include breakpoint(smallLaptop) {
        width: 75%;
        margin-left: 35px;
        padding-bottom: 30px;
      }
      @include breakpoint (toDesktop){
        text-align: justify;
        word-wrap: inherit !important;
        white-space: inherit !important;
      }
    }
  }
  .multiselect {
    padding-left: 0;
    border: 0;
    background: inherit;
    width: auto;
    height: auto;
    margin: 0;
    &__select{
      z-index: 3;
      width: 2rem;
      height: 2rem;
      position: absolute;
      right: 1rem;
      top: 2.4rem;
      color: $black;
      @include breakpoint (toDesktop){
        width: 3rem;
        height: 3rem;
        top: 4rem;
      }
      &:before{
        position: relative;
        right: 0;
        top: 50%;
        color: $black;
        margin-top: .4rem;
        border-style: solid;
        border-width: 0.4rem 0.4rem 0;
        border-color: $black transparent transparent;
        content: "";
        @include breakpoint (toDesktop){
          border-width: 1.2rem 1.2rem 1.2rem;
        }
      }
    }
    &:hover{
      border: 0;
    }
    .multiselect__input {
      @extend .input;
      width: 100%;
      height: auto;
      min-height: auto;
      color: $black !important;
      font-size: 1.1rem;
      @include breakpoint (toDesktop){
        font-size: 16px;
      }
      &:hover{
        background-color: #f5f7fa;
        border: 1px solid #e6e9ed;
      }
      &::placeholder{
        color: #ccd1d9 !important;
        font-family: $regular !important;
        font-size: 1.1rem;
        @include breakpoint (toDesktop){
          font-size: 16px;
        }
      }
    }
    &__content{
      background-color: #f5f7fa !important;
      color: #ccd1d9 !important;
      left: -1px !important;
      border: 1px solid $black;
      width: 100% !important;
      overflow-x: hidden;
      z-index: 10 !important;
      li{
        border-bottom: 1px solid $borderColor !important;
        &:nth-last-child(2){
          border-bottom: 0 !important;
        }
        span{
          font-size: 1.1rem !important;
          padding: 1rem;
          @include breakpoint (toDesktop){
            font-size: 16px !important;
          }
        }
      }
    }
  }
  p{
    padding: 0 13rem;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: center;
    color: $black;
    line-height: 2rem;
    @include breakpoint (toDesktop){
      font-size: 2.1rem;
      line-height: 3rem;
      text-align: justify;
      padding: 0 3rem;
    }
  }
  .do-professional__holder{
    max-height: 100vh;
    overflow: unset;
    button, div.button{
      width: 61%;
      left: 0;
      right: 0;
      background: transparent;
      padding: 1.5rem 1rem;
      font-size: 1.2rem;
      font-weight: 600;
      background: $brand;
      color: $white;
      text-transform: uppercase;
      border-radius: 5px;
      margin: 0 auto;
      display: block;
      outline: none;
      position: relative;
      @include breakpoint (toDesktop){
        font-size: 12px;
        margin: 50px auto;
      }
      @include breakpoint (desktop){
        font-size: 12px;
        margin: 30px auto;
      }
      @include breakpoint (smallLaptop){
        font-size: 12px;
        margin: 40px auto;
      }
      @include breakpoint (laptop){
        font-size: 12px;
        margin: 45px auto;
      }
      @include breakpoint (bigScreen){
        font-size: 12px;
        margin: 50px auto;
      }
      input{
        position: absolute;
        left: 0;
        right: 0;
        opacity: 0;
        top: 0;
        bottom: 0;
        box-sizing: border-box;
        cursor: pointer;
        width: 100%;
        z-index: 1;
      }
    }
    .checkboxError {
      color: red;
    }
  }
  .todo-widget__filters-older{
    cursor: pointer;
    position: relative;
    display: block;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    font-weight: 600;
    z-index: 3;
    @include breakpoint (toDesktop){
      font-size: 1.8rem;
    }
    ul{
      min-width: 15.7rem;
      margin: 0;
      top: -0.5rem;
      right: 5rem;
    }
  }
  &__firstStep{
    .do-professional__holder{
      margin: 0 1.5rem;
      width: 32.5rem;
      padding: 0;
      overflow-x: hidden;
      @include breakpoint (toDesktop){
        margin: 0 auto;
      }
      @include breakpoint (smallLaptop){
        margin: 10px;
      }
      @include breakpoint (laptop){
        margin: 0 auto;
      }
      @include breakpoint (bigScreen){
        margin: 0 auto;
      }
      div{
        width: 32rem;
      }
      .todo-widget__filters-older{
        margin-bottom: 10px;
        padding-bottom: 15px;
      }
    }
    .layer-body__middle-holder{
      display: block;
    }
    .layer-body__middle-message{
      margin-top: 10rem;
      margin-bottom: 2rem;
      font-weight: bold;
    }
    .preferences-container{
      margin: 3rem 0;
      @include breakpoint (toDesktop){
        padding: 2rem 2rem 0;
      }
    }
    &-image{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 16rem;
      margin-top: 20px;
      a {
        display: flex;
        height: 16rem;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
      img{
        max-width: 100%;
        max-height: 100%;
      }
      span{
        position: absolute;
        top: 1rem;
        right: 1rem;
        @include breakpoint (toDesktop){
          top: 0;
        }
        i{
          font-size: 2rem;
          color: $smallColor;
          cursor: pointer;
          @include transition (.3s all ease-in-out);
          &:hover{
            color: $black;
          }
        }
      }
    }
    h4{
      padding-bottom: 1.5rem;
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 10rem;
      font-size: 1.4rem;
      font-weight: 600;
      color: $black;
      text-transform: none;
      @include breakpoint (toDesktop){
        font-size: 2.4rem;
        margin-bottom: 5rem;
      }
      &:after{
        display: none;
      }
    }
    &-country{
      padding: 0 14rem;
      &-holder{
        position: relative;
      }
      label{
        font-size: 1.1rem;
        color: $smallColor;
        font-weight: 500;
        display: block;
        text-align: left;
      }
      input{
        font-family: $regular;
        font-size: 1.1rem;
        @include breakpoint (toDesktop){
          font-size: 16px;
        }
      }
      .multiselect__select{
        width: 1rem !important;
        height: 1rem !important;
        top: 2.8rem !important;
      }
      .multiselect--active{
        .multiselect__select {
          top: 2.4rem !important;
        }
      }
    }
  }
  &__secondStep{
     .check{
      @include breakpoint (toDesktop){
        top: -0.5rem;
      }
    }
    .do-professional__holder{
      padding: 0 13rem;
      @include breakpoint (toDesktop){
        padding: 0;
      }
      @include breakpoint(desktop) {
        padding: 0;
      }
    }
    .common__modalInput{
      padding: 1rem !important;
      width: 100%;
      margin-bottom: 0;
      @include breakpoint (toDesktop){
        box-sizing: border-box;
        width: 100%;
      }
    }
    &-block{
      padding: 2rem 1rem;
      font-size: 1.1rem;
      line-height: 1.9rem;
      font-weight: 500;
      color: $black;
      @include breakpoint (toDesktop){
        font-size: 2.1rem;
        line-height: 2.9rem;
      }
    }
    &-checkbox{
      display: flex;
      align-items: center;
      margin-top: 1.2rem;
      > div{
        display: flex;
        align-items: center;
        margin-right: 2rem;
        .login__list-checkboxLogin{
          width: auto;
          flex-basis: auto;
        }
        .login__list-item-holder{
          width: auto;
          flex-basis: auto;
          padding-left: 1rem;
          padding-top: 1rem;
          @include breakpoint (toDesktop){
            padding-left: 3rem;
          }
        }
      }
    }
    &-info {
       @include breakpoint(largeScreen) {
         width: 97%;
       }
    }
    .showError{
      &:before{
        top: 4.4rem;
        @include breakpoint (toDesktop){
          top: 6.8rem;
          right: 3rem;
        }
      }
    }
    .errorText{
      z-index: 1;
    }
  }
  &__fourthStep{
    .do-contego__secondStep-block{
      margin-bottom: 2rem;
    }
  }
  &__fifthStep{
    .preferences-container{
      display: block;
      margin-top: 0;
      padding: 0;
    }
    &-block{
      border: 1px solid $borderColor;
      width: 100%;
      padding: 2rem 3rem;
      margin-bottom: 2rem;
      @include breakpoint (toDesktop){
        display: block;
        box-sizing: border-box;
      }
      h4{
        padding: 2rem;
        text-transform: none;
        font-size: 1.3rem;
        text-align: center;
        @include breakpoint (toDesktop){
          font-size: 2.3rem;
        }
        &:after{
          display: none;
        }
      }
      &-holder{
        margin: 2rem 0;
        @include breakpoint (toDesktop){
          margin: 4rem 0;
        }
      }
      &-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $black;
        span{
          font-size: 1.4rem;
          font-weight: 600;
          @include breakpoint (toDesktop){
            font-size: 2rem;
          }
        }
        i{
          font-size: 2rem;
          font-weight: 600;
          @include breakpoint (toDesktop){
            font-size: 3rem;
          }
        }
      }
    }
    .do-professional__holder{
      width: 75rem;
      margin: 0 auto;
      @include breakpoint(desktop) {
        overflow-y: auto;
        max-height: calc(100vh - 220px);
      }
      span:last-child {
        margin-bottom: 0;
      }
    }
    .vue-slider-dot{
      top: -0.7rem !important;
      width: 1rem !important;
      height: 1rem !important;
      left: 0 !important;
    }
    .vue-slider-piecewise-item{
      width: 1px !important;
    }
  }
  &__sixStep{
    .do-button{
      left: 31.2% !important;
      margin: 0 !important;
      width: 68.8% !important;
      position: fixed !important;
      @include breakpoint (toDesktop){
        left: 0 !important;
        width: 100% !important;
      }
      &:hover{
        color: $white !important;
      }
      @include breakpoint(smallLaptop) {
        left: 17.2% !important;
        width: 83% !important;
      }
      @include breakpoint(tabletLandscape) {
        left: 13.2% !important;
        width: 87% !important;
      }
      @include breakpoint(largeScreen) {
        left: calc(100% - 1600px) !important;
        width: 1600px !important;
      }
    }
    .do-protection__holder{
      padding: 0;
    }
    .checkbox-container input:checked:after{
      font-size: 16px;
    }
  }
  &__information{
    position: absolute;
    bottom: 2rem;
    left: 1rem;
    right: 0;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    color: $black;
  }
  .specialDot .vue-slider-component .vue-slider-horizontal .vue-slider-dot{
    left: -0.5rem !important;
  }
}