.do-originator {
    .layer-mask {
        .company__preview {
            .layer-container {
                p:has(br),
                h2:has(br) {
                    display: none;
                }
                .dropdown .dropdown-content a {
                    color: $black;
                }
            }
            .layer-body {
                height: auto;

            }
            .compact-navigation {
                top: 50px;
            }
            .compact-intro {
                @include multiple-media(mediumScreen, largeScreen) {
                    min-height: calc(100vh - 104px);
                    height: calc(100vh - 104px);
                }
            }
        }
        .blind-deal-compact {
            min-height: calc(100vh - 50px);
        }
        .equity-deal-compact {
            min-height: calc(100vh - 50px);

            @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
                height: auto;
            }

            &__body {
                flex-grow: 1;
                @include multiple-media(mediumScreen, largeScreen) {
                    min-height: calc(100vh - 104px);
                }
            }

            @include multiple-media(largeScreen) {
                padding-left: calc(100vw - 100%);
            }
        }
    }
    .layer-mask + .do-originator__container {
        @include breakpoint(toBigPhone) {
            display: none;
        }
    }
}
