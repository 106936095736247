.investor-onboarding-step {

  &__header {

    &--title {
      padding: 0;
      margin: 0;
      font-weight: 500;
      font-size: 2rem;
      letter-spacing: 0;
      line-height: normal;
      color: $color-grey-6;
      &__left {
          padding-top:100px;
          margin-left: 100px;
          font-size: 40px;
          font-weight: 500;
      }
      @include breakpoint(tabletScreen) {
        font-size: 2.4rem;
      }

      @include multiple-media(smallScreen, mediumScreen, largeScreen) {
        font-size: 3rem;
        letter-spacing: normal;
      }

    }
    &--info {
      font-size: 1.3rem;
      line-height: 1.69;
      letter-spacing: 0;
      text-align: justify;
      color: $color-grey-6;
      padding: 1.5rem 0 0 0;
      margin: 0;
      &__left {
        margin-left: 100px;
        font-size: 18px;
      }
      @include breakpoint(tabletScreen) {
        font-size: 1.4rem;
        line-height: 2.7rem;
        padding: 2rem 0 0 0;
      }

      @include multiple-media(smallScreen, mediumScreen, largeScreen) {
        font-size: 1.6rem;
        line-height: 2.7rem;
      }
    }
    &--back-button {
      color: $color-blue-main;
      font-size: 1.2rem;
      font-weight: 500;
      font-family: $regular;
    }

    .title-search {
      margin-top: 1rem;
      margin-bottom: .5rem;
      font-weight: normal;
    }

    .title-advisor {
      margin-top: 1rem;
      margin-bottom: 4rem;
      font-weight: normal;
    }
  }

  &__requires {
    display: flex;
    padding-top: 1rem;
    align-items: center;
    justify-content: space-between;

    .validCharacter {
      color: $color-blue-main;

      .investor-onboarding-step__requires__box--mark {
        border-bottom-color: $color-blue-main;
      }
    }

    &__box {
      text-align: center;
      color: $color-grey-3;
      letter-spacing: 0;
      width: 20%;

      @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        text-align: left;
        width: 10rem;
      }
      @include multiple-media(smallDevices) {
        text-align: left;
        width: 20%
      }

      &--mark {
        display: block;
        font-size: 3rem;
        border-bottom: .1rem solid $investmentBorder;
        padding-bottom: .5rem;
        margin-bottom: .5rem;

        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
          font-size: 4rem;
        }
      }

      &--title {
        font-size: 1rem;

        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
          font-size: 1.4rem;
        }
      }
    }
  }

  &__form {

    &--section {
      display: flex;
      //align-items: flex-end;
      flex-direction: column;
      padding-top: 2rem;
    }

    &__table {
      margin-top: 4rem;
      padding-bottom: 2rem;

      &__block {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 2rem 2rem 0 2rem;
        font-weight: normal;
        font-size: 1.3rem;

        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
          font-size: 1.4rem;
        }

        &--header {
          font-weight: 500;
          font-size: 1.4rem;
          @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            font-size: 1.6rem;
          }
        }

        &--name {
          color: $dark-gray;
          display: block;
          width: 10rem;

          @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            width: 12rem;
          }
        }

        &--description {
          color: $color-grey-6;
        }

      }

      .block-header {
        border-bottom: .1rem solid $smallBlockBorder;
        padding-bottom: 2rem;
      }
    }

    &--info-incorrect {
      padding-top: .8rem;
      display: flex;
      justify-content: flex-end;
      color: $color-blue-main;
      font-size: 1.2rem;
      font-weight: 500;
      font-style: italic;
      cursor: pointer;
    }

    &__confirm {
      padding-top: 2rem;

      .hubx-radio {

        label {
          line-height: 1.57;
          padding: 0 0 0 3.8rem;
        }

        .checker {
          background-color: $color-white;
        }
      }
    }

    &__box {
      display: flex;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      border: solid 1px $smallBlockBorder;
      background-color: $color-white;
      margin-top: 3rem;

      &__container {
        padding: 2rem 2.5rem;
        display: flex;
        width: 100%;
        flex-direction: column;

        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
          flex-direction: row;
          align-items: flex-end;
        }

        .btn-search {
          margin-top: 2rem;
          height: 4rem;

          @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            margin-left: 2rem;
            width: auto;
            margin-top: 0;
          }
        }

      }

      .error-msg {
        align-items: center;

        .btn-search {
          align-self: center;
          width: 100%;

          @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            margin-top: 0;
            align-self: center;
            width: auto;
          }
        }
      }

      .common__modal-box-inner {
        width: 100%;
      }

      .investor-onboarding-step__form--btn {
        margin-left: 2rem;
      }

      &__content {
        width: 100%;

        &__results {
          padding: 2rem 2.5rem;
          border-radius: 3px;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
          min-height: auto;
          max-height: 27rem;
          overflow: auto;

          .common__radio {
            margin-bottom: 0;
            padding: 2rem;
            background-color: $color-grey-1;
            border-radius: 0;

            &:hover {
              background-color: $color-white;
            }

            label {
              cursor: pointer;

              &:hover,.checker {
                background-color: $color-white !important;
              }

            }

            .checkmark::after {
              left: 4.9px;
              background-color: $color-white;
            }

            .hubx-radio {
              width: 100%;
            }
          }

          .checked {
            background-color: $color-white;
          }
        }

        &--title {
          font-size: 1.6rem;
          font-weight: 500;
          color: #434a54;
          padding: 2rem 2.5rem;
          display: block;
          border-bottom: .1rem solid $smallBlockBorder;
        }

        &--footer {
          padding: 2rem 2.5rem;
          display: flex;
          justify-content: center;
          flex-direction: column;

          @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            flex-direction: row;
            justify-content: space-between;
          }

          .btn-footer {
            width: auto;


          }

          .white-btn {
            color: $color-blue-main;
            cursor: pointer;
            font-style: normal;
            font-weight: 500;
            background-color: $color-white;
            border: 1px solid $color-grey-2;
            font-size: 1.2rem;
            padding: 1.2rem 2rem;
            margin-top: 2rem;
            text-align: center;

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
              margin-top: 0;
            }

          }
        }

        .emptyFirmList {
          padding: 2rem;
          font-size: 1.4rem;

          a {
            color: $color-blue-main;
          }
        }
      }
    }

    &--btn {
      margin-top: 2.5rem !important;
      width: 100%;

      @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        width: auto;
      }
      &--proceed {
        width: auto;
      }
    }

    &--error-msg {
      color: $red;
      font-size: 1.1rem;
    }
    &--input {
      margin-top: 2rem;
    }
    &--select-radio {
      margin-top: 1rem;
      border: 1px solid $color-grey-3;
      border-radius: 3px;
      &--button {
        padding: 2rem 2rem;
        background-color: $color-grey-1;
        &:not(:last-child) {
          border-bottom: 1px solid $color-grey-3;
        }
      }
      .checked {
        background-color: $color-white;
      }
    }
    .common {
      &__input-box {
         .radio-button-label {
          font-size: 1.4rem;
          font-weight: 500;
          color: $color-grey-6;
          margin-top: 4rem;
          margin-bottom: 1rem;
        }
      }
      &__modal-box-inner {
        margin-bottom: 3rem;
      }
    }
    &--with-border {
      border: 1px solid $color-grey-3;
      padding: 2.5rem;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    }
    &__join {
      padding: 2rem 2rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 3rem;

      .smallText {
        font-size: 1.4rem;
      }
    }

    &__subtitle {
      margin-top: 3rem;
      font-size: 1.4rem;
      color: $color-grey-6;
      font-weight: 500;
    }
    &--spinner {

      i {
        display: block;
        margin: 2rem auto;
      }

      @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        display: inline-block;
        margin: 2rem;
        vertical-align: middle;
      }
    }
  }

  &__information {
      padding: 0;
      border: .1rem solid $smallBlockBorder;
      margin-top: 4rem;
    &__item {
      cursor: pointer;
      padding: 2rem 1rem 2rem 2rem;
      background-color: $color-white;
      box-shadow: 0 .2rem .4rem 0 rgba(0, 0, 0, .1);
      border-bottom: .1rem solid $smallBlockBorder;
      .my-dropdown-dd {
        cursor: default;
      }
      .accordion-item-content {
        line-height: 1.57;
      }

      &--title {
        font-size: 1.6rem;

        h3 {
          font-size: 1.6rem !important;
        }

        @include breakpoint(mobileScreen) {
          h3 {
            font-size: 1.4rem !important;
          }
        }

        i {
          color: $color-blue-main;
          font-size: 1.5rem;
        }

      }

      .horizontal-line {
        background-color: $smallBlockBorder;
        margin: 2rem 1rem 0 0;
      }
    }
  }

  &--loading {
    margin-top: 9rem;
  }

  &__list {
    &.list_custom {
      counter-reset: list;

      li {
          list-style: none!important;
          font-size: 1.4rem !important;
          position: relative;
          padding-bottom: 1rem;

          &:before {
            counter-increment: list;
            position: absolute;
            left: -1.4em;
          }

          ul {
            padding: 1rem 0 0 4rem;

            li {
              padding-left: 1rem;
            }
          }
        }

      &.lower_alpha {
        li {
          &:before {
            content: "(" counter(list, lower-alpha) ") ";
            left: -1.4em;
          }
        }
      }

      &.lower_roman {
        li {
          &:before {
            content: "(" counter(list, lower-roman) ") ";
          }
        }
      }
    }
  }

  .hubx-checkbox-inline {
    display: inline-flex;
    padding-left: 0.5rem;

    .hubx-checkbox > label {
      padding-left: 2.8rem;
    }
  }
}

.investor-onboarding-step.proraise-branding {

  .btn--positive {
    background-color: $color-purple-main;
    margin-top: 30px !important;
    &:hover {
      background-color: $color-purple-main-lighter;
    }
  }

  .investor-onboarding-step {
    &__form {
      &--section:first-of-type {
        padding-top: 0;
      }

      &__confirm {
        &:first-of-type {
          padding-top: 0;
        }
      }

      &--btn {
        padding: 15px;
        border-radius: 5px;
        width: 100%;
        font-size: 18px;
      }
    }

    &__header {
      &--title {
        color: $color-purple-main;
      }

      &--info {
        color: $color-purple-main;
        margin: 30px 0;
        padding: 0;
      }

      &--back-button {
        color: $color-purple-main;
      }
    }

    &__requires {
      &__box{
        &--mark {
        font-size: 16px;
      }
        &--title {
          font-size: 12px;
        }
      }
      .validCharacter {
        color: $color-purple-main;

        .investor-onboarding-step__requires__box--mark {
          border-bottom-color: $color-purple-main;
        }
      }
    }

    &--info-incorrect {
      color: $color-purple-main;
    }

    &__information {
      border: unset;

      .accordion-container::-webkit-scrollbar-thumb {
        background-color: $color-purple-main;
      } ;

      &__item {
        box-shadow: unset;
        border-bottom: unset;
        padding-left: 6px;
        padding-right: unset;

        .proraise-left-checkbox-border {
          padding-left: 13px;
          border-left: 2px solid $color-cyan-secondary;
          margin-top: 30px;
        }

        &--title {
          flex-direction: row-reverse;
          justify-content: flex-end;
          h3 {
            color: $color-cyan-secondary;
            font-size: 18px !important;
            font-weight: bold;
          }

          i {
            color: $color-cyan-secondary;
            margin-right: 11px;
            font-size: 18px;
            font-weight: bold;
          }
        }

        a {
          color: $color-cyan-secondary;
        }
      }
    }
  }
}

