.text-input {
  label {
    display: block;
    margin-bottom: 1rem;
    color: $dark-gray;
    font-size: 1rem;
    font-weight: 500;
  }
  &.showError {
    position: relative;
    input {
      border-color: $red;
    }
    .errorText {
      display: block;
      position: absolute;
      bottom: -1.5rem;
      right: 0;
      color: $red;
    }
  }
  input {
    &:disabled {
      border-color: transparent;
    }
  }
}

.select-input {
  color: $black;
  label {
    display: block;
    margin-bottom: 1rem;
    color: $dark-gray;
    font-size: 1.1rem;
    font-weight: 600;
  }
  .multiselect {
    padding: 0;
    border: 1px solid $investmentBorder;
    background-color: $darkerWhite;
    @include transition (.3s all ease-in-out);
    outline: none;
    box-sizing: border-box;
    width: 100%;
    border-radius: 5px;
    font-family: $regular;
    position: relative;
    color: $black;
    font-size: 1.2rem;
    font-weight: 500;
    &__select {
      position: absolute;
      width: 2rem;
      height: 2rem;
      right: 0.5rem;
      top: 1.3rem;
      padding: 0.4rem 0.8rem;
      text-align: center;
      -webkit-transition: 0.3s all ease-in-out;
      transition: 0.3s all ease-in-out;
      &:before {
        top: 50%;
        border-color: $dark-gray transparent transparent;
      }
    }
    &--active {
      .multiselect__select {
        top: 1.1rem !important;
      }
    }
    &__input {
      border: 0;
      color: $tagBackground;
      font-family: "Montserrat", sans-serif;
      font-size: 1.2rem;
      font-weight: 600;
      padding: 1.7rem 2rem;
    }
    &__content {
      max-height: 20rem;
    }
  }
}

.select-group {
  flex: 0 0 33.5%;
  label {
    font-size: 1.1rem;
    letter-spacing: 0;
    color: $smallColor;
    font-weight: 500;
    margin-bottom: 1rem;
    display: block;
  }
  .select__small {
    margin-right: 0 !important;
    .multiselect {
      border-radius: 0;
    }
    &:first-of-type {
      .multiselect {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
    &:last-of-type {
      .multiselect {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
  .multidatepicker {
    &__input-mask {
      position: relative;
      .select {
        &__holder {
          .errorText {
            display: block;
            position: absolute;
            top: 5.5rem;
            right: 2.5rem;
            color: $red;
          }
        }
      }
    }
  }
}
