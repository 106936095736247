.do-profile{
  @extend .common__layer;
  &__item{
    @extend .common__layer-item;
    position: relative;
    &-info{
      margin-top: 1.3rem;
      &-small{
        font-size: 1rem;
        color: $smallColor !important;
        display: block;
        font-weight: 500;
        margin: 0.5rem 0 !important;
      }
      &-big{
        font-size: 1.1rem;
        margin: 0.4rem 0;
        color: $black;
        display: block;
        font-weight: 400;
        word-wrap: break-word;
        &.investor-email {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    &-content{
      h2{
        @extend .common__info-title;
      }
    }
  }
  .text-editor-render ul{
    padding-left: 1.7rem !important;
  }
}