.advisor-navigation {
    background-color: $color-white;
    padding: 1rem;
    box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1001;

    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        padding: 0;
    }

    .navigation-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3rem;

        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            height: 50px;
            padding: 0 2rem;
        }

        .navigation-menu-left {

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                height: 100%;
            }

            @include multiple-media(mobileScreenLandscape) {
                height: auto;
            }

            .menu-mobile {
                display: block;

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    display: none;
                }

                @include multiple-media(mobileScreenLandscape) {
                    display: block;
                }

                img {
                    width: 2rem;
                }
            }

            .menu-tabs {
                display: none;

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    display: flex;
                    align-items: center;
                    height: 100%;
                }

                @include multiple-media(mobileScreenLandscape) {
                    display: none;
                }

                &-item {
                    margin-right: 3rem;
                    cursor: pointer;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    &:not(:first-child) {

                        &:hover {

                            .menu-tabs-item--text {
                                color: $color-blue-main;
                            }

                        }
                    }

                    &--logo {
                        max-height: 4rem;
                    }

                    &--text {
                        font-size: 1.5rem;
                        color: $color-grey-6;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        border-bottom: 2px solid transparent;
                    }

                    .is-active {
                        color: $color-blue-main;
                        border-bottom-color: $color-blue-main;
                    }
                }
            }

        }

        .navigation-menu-right {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
            align-items: center;
            margin-left: 3.5rem;

            @include multiple-media(mobileScreen) {
                margin-left: 7rem;
            }

            @include multiple-media(mobileScreenLandscape) {
                margin-left: 10rem;
            }

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                flex-grow: unset;
                margin-left: 0;
            }

            .menu-item {
                position: relative;

                &:first-child {
                    @include multiple-media(tabletScreen) {
                        margin-left: 0;
                    }
                }

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    margin-left: 3rem;
                }

                &-search {
                    display: inline-flex;
                    flex: 1 1;
                    position: relative;
                    overflow: hidden;
                    background-color: $color-grey-1;
                    border-radius: 5px;
                    border: solid 1px $color-grey-2;
                    height: 4rem;
                    width: 40rem;
                    align-items: center;
                    transition: 0.5s;

                    @include multiple-media(tabletScreen) {
                        width: 25rem;
                    }

                    &--icon {
                        padding: 0 1rem;
                        width: 2rem;
                        height: 2rem;

                        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                            padding: 0 1rem;
                        }
                    }

                    &--box {
                        border: 0;
                        background-color: $color-grey-1;
                        padding: 0;
                        flex: 1;
                        height: 100%;
                        outline: none;
                    }

                    &__close {
                        cursor: pointer;
                        border: 0;
                        display: flex;
                        align-items: center;
                        padding: 1rem;
                        border-radius: 0;

                        &--icon {
                            padding-left: .5rem;
                            width: 2rem;
                        }
                    }

                    &-collapse {
                        cursor: pointer;
                        width: 3rem;
                        border: none;
                        background-color: transparent;
                    }
                }


                &__profile {
                    border: 1px solid $color-grey-2;
                    width: 3rem;
                    max-height: 3rem;

                    &--picture {
                        width: 2rem;
                    }
                }

                &--badge {
                    position: absolute;
                    top: -3px;
                    right: -2px;
                    color: white;
                    background-color: red;
                    height: 14px;
                    padding: 0 .2rem;
                    border-radius: 20%;
                    line-height: 14px;
                    font-size: 1rem;
                    text-align: center;
                    cursor: pointer;
                    border-color: red;
                }

                &__content {
                    display: flex;

                    &__body {
                        display: flex;
                        flex-grow: 1;
                        align-items: center;
                        padding: 1rem 1.5rem;
                        cursor: pointer;
                    }

                    &:not(:first-child) {
                        border-top: 1px solid $color-grey-16;
                    }

                    &--picture {

                    }

                    &--title {
                        margin-left: 1.5rem;
                        font-size: 1.2rem;
                        color: $color-grey-6;
                        font-weight: 600;
                    }
                }

                &-notifications {
                    width: 600px;

                    &__header {
                        padding: 1rem;
                        display: flex;
                        align-items: center;
                        border-bottom: solid 1px $color-grey-16;

                        .popup-menu-icon {
                            display: flex;
                            align-items: center;

                            svg {
                                fill: $color-blue-main;
                            }
                        }

                        &--title {
                            margin-left: 1rem;
                            margin-right: 1rem;
                            font-size: 1.4rem;
                            color: $color-blue-main;
                        }
                    }

                    &__main {
                        height: 20rem;

                        .notification-card {
                            display: flex;
                            padding: 1.5rem;
                            border-bottom: 1px solid $color-grey-16;

                            &__logo {
                                position: relative;
                                border: 1px solid $color-grey-16;
                                border-radius: 50%;
                                height: 4rem;
                                width: 4rem;
                                display: flex;
                                justify-content: center;

                                &--icon {
                                    width: 4rem;
                                    padding: .5rem;
                                }

                                &--badge {
                                    position: absolute;
                                    left: -1px;
                                    top: -1px;
                                    width: 4px;
                                    height: 4px;
                                    background-color: $color-red-main;
                                    border-radius: 50%;
                                }
                            }

                            &__content {
                                display: flex;
                                flex-direction: column;
                                flex-grow: 1;
                                margin-left: 1rem;

                                &--header {
                                    font-size: 1.2rem;
                                    color: $color-grey-6;
                                    margin-top: .5rem;
                                }

                                &-body {

                                    &--amount {
                                        font-size: 1.4rem;
                                        color: $color-green-dark-1;
                                        margin-top: .5rem;
                                        display: block;
                                    }

                                    &--text {
                                        font-size: 1.4rem;
                                        font-style: italic;
                                        color: $color-grey-6;
                                        margin-top: .5rem;
                                    }

                                    &-footer {
                                        margin-top: .5rem;
                                        display: flex;

                                        &-date {
                                            color: $color-grey-4;
                                            font-size: 1.2rem;
                                        }

                                        &-action {
                                            font-size: 1.2rem;
                                            margin-left: 1rem;

                                            .action-btn-confirm {
                                                color: $color-blue-main;
                                            }
                                        }
                                    }

                                }
                            }
                        }

                    }

                    &__footer {
                        padding: 1rem;
                        border-top: solid 1px $color-grey-16;

                        &--link {
                            display: block;
                            margin: 0 auto;
                            text-align: center;
                            font-size: 1.4rem;
                            color: $color-blue-main;
                            font-weight: 600;
                        }
                    }

                }

                .home-search-results {
                    display: none;
                    position: absolute;
                    width: 100%;
                    max-height: 45rem;
                    background-color: $color-white;
                    overflow-y: auto;
                    overflow-x: hidden;
                    box-shadow: 0 2px 4px 0 $color-grey-16;

                    &-info {
                        padding: 1.5rem;
                        font-size: 1.4rem;
                        color: $color-grey-6;
                    }

                    .do-network-block {
                        display: flex;
                        margin: 0 1.5rem 1.5rem 1.5rem;
                        align-items: center;

                        @include multiple-media(tabletScreen) {
                            flex-direction: column;
                        }

                        .do-network-block__first {
                            min-width: 8rem;

                            @include multiple-media(tabletScreen, smallScreen) {
                                 border-bottom: none;
                                 width: 3rem;
                            }

                            @include multiple-media(tabletScreen) {
                                width: 100%;
                                padding: 0;
                                border-bottom: 1px solid $color-grey-14;
                                display: flex;
                                justify-content: center;
                            }
                        }

                        .do-network-block__second {
                            padding: 1.5rem;
                            border-left: 1px solid $color-grey-14;

                            @include multiple-media(tabletScreen) {
                                padding: 1rem;
                                border: none;
                                display: flex;
                                flex-grow: 1;
                                width: 90%;
                            }

                            .todo-widget__block-right-info {
                                width: calc(100% - 80px);

                                .media__info-title {
                                    margin-bottom: 0;
                                }

                                &::after {
                                    display: none;
                                }
                            }
                        }

                        .do-network-block__third {
                            min-width: 7rem;
                            padding-right: 0;

                            @include multiple-media(tabletScreen) {
                                width: 100%;
                                display: flex;
                                justify-content: center;
                                border-top: 1px solid $color-grey-14;
                                height: 3rem;
                            }
                        }

                        .todo-widget__block-right-info:after {
                            display: none;
                        }

                        .do-network-matched-block {
                            display: flex !important;
                        }

                    }

                    .do-network-matched-holder {
                        padding: 0 0 0 1.5rem !important;

                        @include multiple-media(mobileScreen) {
                            padding: 0 !important;
                        }

                        @include multiple-media(tabletScreen) {
                            display: flex;
                            justify-content: center;
                            height: 100%;
                        }

                        .todo-widget__block-right-info {
                            @include multiple-media(tabletScreen) {
                                width: 10rem;
                            }
                        }

                        .todo-widget__block-right {
                            @include multiple-media(tabletScreen) {
                                padding: 1rem;
                                width: 90%;
                            }
                        }

                        .do-network-matched-block {
                            @include multiple-media(tabletScreen) {
                                flex-grow: 1;
                                justify-content: center;
                                width: 100%;
                            }

                            &-holder {
                                @include multiple-media(tabletScreen) {
                                    flex-grow: 1;
                                    display: flex;
                                    border-top: 1px solid $color-grey-14;
                                    padding-top: 1rem;
                                    padding-bottom: 1rem;
                                }
                            }
                        }
                    }
                }

            }

            .menu-item-profile {

                .menu-arrow-right {
                    right: -2px;

                    &::before {
                        right: 5px;
                    }

                    &::after {
                        right: 6px;
                    }
                }
            }

        }
    }

}
.sidenav-advisor {
    height: 100%;
    width: 0;
    position: fixed;
    top: 0;
    left: 0;
    background-color: $color-white;
    overflow-x: hidden;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    z-index: 1001;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 5rem;
        font-size: 1.8rem;
        font-weight: 500;
        box-shadow: 0 1px 0 0 $color-grey-16;
        padding: 0 1.5rem;

        &--title {

        }

        &--close {
            width: 2rem;
        }
    }

    &-body {
        margin: 0;
        padding: 0 1.5rem;
        width: 100%;

        &-item {
            text-decoration: none;
            font-size: 1.4rem;
            display: block;
            transition: 0.3s;
            text-align: left;
            padding: 1.7rem 0;

            a {
                color: $color-grey-6;

                &:hover {
                    color: $color-white-1;
                }
            }

            .is-active {
                color: $color-blue-main !important
            }


        }
    }
}

.sidenav-advisor-open {
    width: 100%;
}

.mobile-search {
    height: 100vh;
    position: absolute;
    width: 100%;
    background-color: $color-grey-12;
    z-index: 1001;

    &-box {
        display: flex;
        flex: 1 1;
        flex-grow: 1;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        left: 0;
        overflow: hidden;
        background-color: $color-white;
        height: 4rem;
        align-items: center;
        padding: .5rem 1.5rem;

        &--icon {
            padding: 1rem;
            background-color: $color-grey-1;
            width: 2rem;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

        &--find {
            border: 0;
            background-color: $color-grey-1;
            padding: 0;
            flex: 1;
            height: 100%;
            outline: none;
            margin: 0
        }

        &__close {
            border: 0;
            display: flex;
            align-items: center;
            padding: 1rem;
            background-color: $color-grey-1;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;

            &--icon {
                padding-left: .5rem;
                width: 2rem;
            }
        }
    }

    .home-search-results {
        padding:  0 1.5rem 1.5rem 1.5rem;
        background-color: $color-white;
        display: none;
        height: calc(100vh - 6rem);
        overflow: auto;
        margin-top: 5rem;

        .do-network-block {
            flex-direction: column;

            &__first {
                justify-content: center;
            }

            &__second {
                padding: 1rem;
                width: 95%;
            }


            &__third {
                display: flex;
                flex-grow: 1;
                width: 100%;
                padding: 1rem 0;
                justify-content: center;
                border-top: 1px solid $color-grey-14;
            }
        }

        .do-network-matched-holder {
            padding: 0 !important;
            justify-content: center;
            height: auto;

            .todo-widget__block-right {
                padding: 1rem;
                width: 95%;
            }

            .do-network-matched-block {
                width: 100%;
                display: flex;
                border-top: 1px solid $color-grey-14;
                padding: 1rem 0;

                &-holder {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }
            }
        }

        .home-search-results-info {
            padding: 1.5rem;
            font-size: 1.4rem;
            color: $color-grey-6;
        }

        .todo-widget__block-right-info:after {
            display: none;
        }

        .search-result-deal {

            .action__buttons {
                padding: 1rem;
            }

            .todo-matchesBlock__content {
                width: auto;

                &:before {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

.search-result-deal {
    padding: 1rem 2rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 5px;
    background-color: $color-white;
    flex-wrap: wrap;
    min-height: 6rem;
    margin: 0 1.5rem 1.5rem 1.5rem;

    @include multiple-media(mobileScreen) {
        padding: 0;
        min-height: 7.5rem;
        height: auto;
        margin: 0 0 2rem 0;
    }

    .todo-widget__block-right {
        width: auto;
        flex-basis: auto;
        margin-bottom: 0;

        @include multiple-media(mobileScreen) {
            width: 100%;
            padding: 1rem;
            min-height: 6.5rem;
        }
        &:after {
            display: none;
        }
        &-info {
            width: 17rem;

            @include multiple-media(mobileScreen) {
                width: 100%;
            }

            &:after {
                background-image: unset;
            }

            .media__info-title,
            .media__info-desc {
                word-break: break-word;
                -webkit-box-orient: vertical;
                overflow: hidden;
                display: -webkit-box;
            }
            .media__info-desc {
                -webkit-line-clamp: 1;
            }
            .media__info-title {
                -webkit-line-clamp: 2;
            }

            @include multiple-media(tabletScreen) {
                width: 10rem;
            }
        }
    }
    .do-network-matched-block{
        margin-bottom: 1rem;
    }
    .new-block-small-left {
        width: initial;
        text-align: right;
        flex: 2;
    }
    .new-block-small-right {
        width: initial;
        text-align: right;
        margin-left: 1rem;
        flex: initial;
    }
    .media {
        &__info {
            padding-left: 2rem;
        }
        &__info-desc {
            font-size: 1rem;
            line-height: normal;
            padding-right: 2rem;
        }
        &__image {
            width: 4rem;
            height: 4rem;
        }
        &__smallImage {
            width: 2rem;
            height: 2rem;
            top: -1rem;
            left: -1rem;
        }
        &__info-title{
            font-size: 1.3rem;
            margin-bottom: 3px;
            a{
                font-size: 1.3rem;
                @include transition(.3s all ease-in-out);
                &:hover{
                    color: $brand;
                }
            }
        }
    }
    .new-block-small{
        width: 10rem;
        font-size: 1.1rem;
        line-height: 1.8;
    }
    .action__buttons{
        margin-bottom: 1rem;
        text-align: center;
        font-size: 1.1rem;

        @include multiple-media(mobileScreen) {
            width: 100%;
            border-top: 1px solid $smallBlockBorder;
            padding: .5rem 1rem;
            margin-bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .bigNumber {
            display: block;
            font-size: 3.6rem;
            font-weight: 100;
            color: $dark-gray;

            @include multiple-media(mobileScreen) {
                font-size: 2.5rem;
            }
        }
        .bigText {
            text-transform: uppercase;
            font-size: 1rem;
            color: $dark-gray;
        }
        a {
            text-transform: uppercase;
            color: $brand;
            font-weight: 500;
        }
        .gray-text {
            text-transform: uppercase;
        }
    }
    .todo-matchesBlock__content {
        width: 100%;
        flex-basis: 100%;
        position: relative;
        margin: 1rem 0;
        padding: .5rem 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        @include multiple-media(mobileScreen) {
            padding: .5rem 1rem;
        }

        @include multiple-media(tabletScreen) {
            margin: 1rem;
            width: 90%;
        }

        &:before{
            position: absolute;
            display: block;
            content: '';
            left: -20px;
            right: -20px;
            top: -10px;
            height: 1px;
            background-color: $borderColor;
        }
        a{
            color: $brand;
            text-transform: uppercase;
            font-weight: 500;
            @include transition(.3s all ease-in-out);
            font-size: 1.1rem;
        }
        span{
            font-weight: 400;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $black;
            font-size: 1.1rem;
            &.media__info-desc {
                float: left;
            }
        }
    }

    @include multiple-media(tabletScreen) {
        padding: 0;
    }

    @include multiple-media(smallScreen, mediumScreen, largeScreen) {
        padding: 1rem 1.5rem;
    }

    .todo-widget__block-right {
        @include multiple-media(mobileScreen, tabletScreen) {
            flex-grow: 1;
            padding: 1rem;
        }
    }

    .action__buttons {
        @include multiple-media(tabletScreen) {
            display: flex;
            border-top: 1px solid $color-grey-14;
            padding: 1rem;
            margin: 0;
            flex-grow: 1;
            justify-content: space-between;
            align-items: center;
        }
    }
}
