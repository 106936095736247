.relativeInput {
    position: relative;
    margin-bottom: 15px;
}

.editor__input {
    .question {
        top: 7.5rem;
    }
}

.disable-company-save {
    opacity: 0.3;
    cursor: inherit !important;
}
