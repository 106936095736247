.resend-invitation {
    .modal-layer-mask {
        background-color: transparent;

        .modal-layer-container {
            top: 62px;
        }
    }
    .re-invite-block {
        max-height: unset;
        .compose-info-wrapper {
            margin: 0 -2rem;
            background: $layerBackground;
        }
    }
    .compose-body {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
    }

    .email-subject {
        label {
            font-size: 1.6rem;
        }
    }

    text-editor {
        height: 50px;
        &, input{
            font-size: 1.6rem;
            border-radius: 5px;
            border: solid 1px #b3b3b3;
            background-color: #ffffff;
        }
    }

    .editor {
        overflow-x: hidden;
        > span,
        > a{
            font-size: 1.6rem !important;
            line-height: 2.2rem !important;
        }
        .ql-editor p, .ql-editor li, .ql-editor strong, .ql-editor em, .ql-editor u{
            font-size: 1.6rem !important;
            line-height: 2.2rem !important;
            color: $black !important;
            font-family: $regular;
            @include breakpoint (toDesktop){
                font-size: 2.5rem !important;
                line-height: 3.2rem !important;
            }
        }
        *{
            font-size: 1.6rem!important;
            line-height: 2.2rem!important;
            color: #434a54!important;
            font-family: $regular;
            text-transform: none;
            @include breakpoint (toDesktop){
                font-size: 2.5rem !important;
                line-height: 3.2rem !important;
            }
        }
        h1,h2,h3,h4,h5,h6{
            font-weight: 800 !important;
        }
        h3:after{
            display: none;
        }
        .ql-editor ol, .ql-editor ul {
            padding-left: 0 !important;
        }
    }

    .emailContent {
        font-size: 1.6rem;
    }
    .user-name {
        margin-top: 5px;
    }
    .checkboxText {
        font-size: 1.6rem;
    }
    .send {
        margin-top: 15px;
    }

    .generate-link {
        margin-top: 18px;
        font-size: 1.6rem;

        label {
            font-size: 1.6rem;
        }

        .link {
            display: flex;
        }
        .note {
            color: #da4353;
        }
        input {
            margin-right: 15px;
        }
    }

    input[type='button'] {
        position: unset;
        color: #ffffff;
        min-width: 39px;
        padding: 16px 24px;
        width: unset;
        cursor: pointer;
    }
}
