.required-flag {
  border-radius: 3px;
  border: solid 1px $colorCheck;
  padding: 0.1rem 0.4rem;
  font-size: 0.9rem;
  margin-left: 1.4rem;
  background-color: $colorCheck;
  color: $white;
  &.optional {
    background-color: transparent;
    border-color: $smallColor;
    color: $smallColor;
  }
}