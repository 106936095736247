.do-originator__title{
    margin: 0;
    padding: 0;
    font-size: 2.7rem;
    letter-spacing: 0;
    font-weight: bold;
    text-transform: none;
    color: $black;
    margin-bottom: 3rem;
}
.do-originator__h3{
    font-size: 1.8rem;
    color: $black;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-align: inherit;
}
.common__info{
    &-title{
        font-size: 1.4rem;
        color: $black;
        font-weight:700;
        letter-spacing: 0px;
        padding-bottom: 1rem;
        border-bottom: 1px solid $borderColor;
        margin: 0;
        text-transform: none;
        margin-bottom: 2rem;
        &.kyc {
            margin-bottom: 0;
        }
    }
    &-box{
        display: flex;
        align-items: flex-start;
        margin-bottom: 2.2rem;
        a{
            color: $brand;
        }
        &-left{
            font-size: 1.1rem;
            color: $smallColor;
            font-weight: 400;
            flex-basis: 25%;
            width: 25%;
        }
        &-right{
            font-size: 1.2rem;
            color: $black;
            font-weight: 500;
            flex-basis: 75%;
            width: 75%;
            text-align: justify;
        }
    }
}
.common__modalTitle{
    font-size: 1.6rem;
    font-weight: 600;
    border-bottom: 1px solid $layerBorder;
    margin-top: 2rem;
    margin-bottom: 3rem;
    padding-bottom: 1rem;
    text-align: left;
    @include breakpoint(toDesktop){
        font-size: 2.6rem;
    }
}
.feedback__button{
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $black;
    margin-left: 5rem;
    font-weight: 600;
    cursor: pointer;
    @include transition (.3s all ease-in-out);
    &:hover{
        color: $brand;
    }
}
.relative{
    position: relative;
    height: 150px;
    width: 150px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        max-width: 100%;
        max-height: 100%;
    }
    > a{
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0 !important;
    }
}