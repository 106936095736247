@mixin grayText {
    font-size: 1.4rem;
    color: $black;
    opacity: 0.5;
}

.network-mail-compose {

    &__content {
        display: flex;
        width: 100%;

        &-left {
            width: 30%;
            background-color: $white;
            padding: 20px 25px;
            height: calc(100vh - 25rem);

            &-list {
                margin-top: 20px;
                height: calc(100vh - 37.2rem);
                overflow-y: auto;
            }

            &-bottom {
                border-top: 1px solid #d2d4d8;
                padding: 25px;
                margin: 0 -25px;
                font-size: 1.2rem;
                color: $color-blue-main;

                span {
                    cursor: pointer;
                }
            }
        }

        &-right {
            overflow: scroll;
            background-color: $white;
            width: 63%;
            margin-left: 26px;
            height: calc(100vh - 21rem);
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__toggle {
                display: flex;
                align-items: center;
                padding: 25px;
                border-bottom: 1px solid $smallBlockBorder;
                font-size: 1.4rem;
            }

            &__top {
                padding: 25px;
                border-bottom: 1px solid $smallBlockBorder;

                &__cc {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 25px;
                    position: relative;

                    &-title {
                        @include grayText;
                        margin: 0 7.8rem 0 0;
                    }

                    &__items {
                        display: flex;
                        margin-bottom: 10px;
                        position: relative;
                        flex-wrap: wrap;

                        &-item {
                            padding: 10px;
                            margin: 10px;
                            position: relative;
                            flex-basis: 25%;
                            height: 10px;
                            color: $white;
                            font-size: 13px;
                            border-radius: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            background-color: $brand;

                            i {
                                cursor: pointer;
                                position: absolute;
                                color: white;
                                background-color: $lightGray;
                                border-radius: 50%;
                                font-size: 13px;
                                width: 15px;
                                height: 15px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                top: -5px;
                                right: -5px;
                            }
                        }
                    }
                }

                &__from {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 25px;
                    position: relative;

                    &-title {
                        @include grayText;
                        margin: 0 6rem 0 0;
                    }

                    &-mail {
                        @include grayText;
                        flex-grow: 3;
                        margin: 0;
                    }

                    &-change {
                        flex-grow: 1;
                        margin: 0;
                        font-size: 1.4rem;
                        color: $color-blue-main;
                        cursor: pointer;
                        text-align: end;
                    }
                }

                &__recipients {
                    width: 100%;
                    display: flex;
                    margin-bottom: 25px;

                    &-title {
                        @include grayText;
                        margin-right: 8rem;
                    }

                    &-recipients {
                        flex-wrap: wrap;
                        display: flex;
                        gap: 10px;

                        &-block {
                            width: fit-content;
                            display: flex;
                            align-items: center;
                            border: 1px solid $smallBlockBorder;
                            border-radius: 5px;
                            padding: 10px;

                            &-name {
                                margin: 0 5px 0 0;
                                color: $black;
                                font-size: 1.4rem;
                            }
                        }
                    }
                }

                &__subject {
                    display: flex;
                    align-items: center;
                    position: relative;

                    &-title {
                        @include grayText;
                        margin-right: 40px;
                    }

                    &-input {
                        flex-grow: 4;
                        width: 23%;
                        padding: 0 10px;
                        height: 4rem;
                        font-size: 1.4rem;
                        font-weight: normal;
                        font-family: "Montserrat", sans-serif;
                        border: 0.1rem solid $smallBlockBorder;

                        &:focus-visible {
                            outline: none;
                        }
                    }
                }
            }

            &__mail {
                flex-grow: 2;
                padding: 25px;

                &-greeting {
                    margin-bottom: 18px;
                    font-size: 1.2rem;
                }

                &-text {
                    margin-bottom: 18px;
                    position: relative;

                    &-editor {
                        width: 80%;
                    }

                    &-additional {
                        font-size: 1.2rem;
                    }

                    .showError {
                        .ql-toolbar {
                            border-color: #ed5565;
                            background-color: #fff;
                            border-top-left-radius: 5px;
                            border-top-right-radius: 5px;
                        }

                        .ql-container {
                            border-color: #ed5565;
                            background-color: #fff;
                            border-bottom-left-radius: 5px;
                            border-bottom-right-radius: 5px;
                        }
                    }

                }

                &-signature {
                    font-size: 1.2rem;
                }
            }

            &__bottom {
                height: 72px;
                min-height: 72px;
                border-top: 1px solid $smallBlockBorder;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 25px 0 21px;

                &__buttons {
                    display: flex;
                    align-items: center;

                    &-link {
                        color: $color-blue-main;
                        font-size: 1.2rem;
                        cursor: pointer;
                        margin-right: 20px;
                        text-align: center;
                    }

                    &-button {
                        margin-left: 10px;
                    }
                }

                &__check-container {
                    font-size: 1.2rem;
                }
            }
        }
    }
}

.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

.close-icon {
    cursor: pointer;
}

.showError {
    border-color: $red;
    background-color: $white;
    border-radius: 5px;
}

.errorText {
    display: block;
    position: absolute;
    bottom: -1.5rem;
    right: 0;
    color: $red;
    font-size: 1rem;
    font-weight: 700;
}

.grey-text {
    color: lightslategrey;
    margin-top: 20px;
    font-size: 9px;
    line-height: normal;
}

.top-align {
    bottom: 4.5rem;
    @media screen and (min-width: 1800px){
        bottom: 2.7rem;
    }
}

.ql-editor {
    a {
        color: #06c !important;
    }
}
