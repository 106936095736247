.popup-menu {

    &-icon {
        cursor: pointer;

        img {
            width: 2rem;
        }
    }

    &-items {
        min-width: max-content;
        width: 100%;
        height: auto;
        border: 1px solid #d2d4d8;
        border-radius: .5rem;
        position: absolute;
        right: -.5rem;
        top: 3rem;
        background-color: $color-white;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
        z-index: 1;
    }
    .menu-arrow-left {

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 100%;
            width: 0;
            height: 0;
        }
        &::before {
            left: 19px;
            border: 10px solid transparent;
            border-bottom-color: $color-white-grey;
        }

        &::after {
            left: 20px;
            border: 9px solid transparent;
            border-bottom-color: $color-white;
        }
    }

    .menu-arrow-right {
        right: -21px;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 100%;
            width: 0;
            height: 0;
        }
        &::before {
            right: 18px;
            border: 11px solid transparent;
            border-bottom-color: $color-white-grey;
            @include breakpoint(bigScreen) {
                right: 23px;
            }
        }

        &::after {
            right: 19px;
            border: 10px solid transparent;
            border-bottom-color: $color-white;
            @include breakpoint(bigScreen) {
                right: 24px;
            }
        }
    }

    .menu-arrow-center {

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 100%;
            width: 0;
            height: 0;
        }
        &::before {
            left: 19px;
            border: 11px solid transparent;
            border-bottom-color: $color-white-grey;
        }

        &::after {
            left: 20px;
            border: 10px solid transparent;
            border-bottom-color: $color-white;
        }
    }
}
