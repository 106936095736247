.layer {
    &-container {
        position: fixed;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $white;
        z-index: 1;
        @media screen and (max-width: 1199px) {
            left: 0;
        }
        @include breakpoint(mediumScreen) {
            left: calc(100% - 1100px);
        }
        @include breakpoint(largeScreen) {
            left: calc(100% - 1600px);
        }
        &.wideLayer {
            @media screen and (max-width: 1199px) {
                left: 0;
            }
            @include breakpoint(mediumScreen) {
                left: calc(100% - 1100px);
            }
            @include breakpoint(largeScreen) {
                left: calc(100% - 1600px);
            }
        }
        .layer-header {
            box-shadow: 0 1px 0 0 #d2d4d8;
            border-bottom: 1px solid $borderColor;
        }
    }
    &-header {
        padding: 1rem 4rem;
        text-transform: uppercase;
        font-size: 1.2rem;
        @include breakpoint(mobileScreen) {
            padding: 2rem;
        }
        @include multiple-media(tabletScreen, smallScreen) {
            padding: 2rem 4rem;
        }
        a {
            color: $brand !important;
            @include transition(.3s all ease-in-out);
            margin-right: 3.5rem;
            font-weight: 500;
            font-size: 1.1rem;
            &:hover {
                color: $darkerBrand !important;
            }
        }
        i {
            margin-left: 0rem;
            display: inline-block;
            font-size: 1rem;
            margin-right: 0.5rem;
        }
    }
    &-body {
        padding: 1.2rem 4rem 0;
        background-color: $layerBackground;
        overflow-y: auto;
        @media screen and (max-width: 1199px) {
            padding: 1.2rem 1rem 0;
        }
        &__full-width-white {
            padding: 0;
            @media screen and (max-width: 1199px) {
                padding: 0;
            }
        }
        height: 100%;

        .multiselect__single {
            padding: 1.4rem 2rem;
            border: 0;
            font-size: 1.2rem;
            color: $black;
            font-weight: 500;
        }

        .multiselect__placeholder {
            padding: 1.4rem 2rem;
            display: block;
        }

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $layerBorder;
            padding-bottom: 1rem;
            margin-bottom: 2.2rem;
            min-height: 4.9rem;
            h1 {
                font-size: 2.7rem;
                color: $black;
                font-weight: bold;
                margin: 0;
            }
            a {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 1.2rem;
                color: $black;
                margin-left: 2rem;
                cursor: pointer;
                @include breakpoint(tabletScreen) {
                    margin-left: 1rem;
                }
                @include transition(.3s all ease-in-out);
                &.activeLink {
                    background-color: $white;
                    padding: 0.7rem 2rem;
                    color: $brand;
                }
                &.disabledLink {
                    color: $brand;
                    opacity: 0.2;
                    cursor: default;
                }
                &.disabledLink.box {
                    background-color: $white;
                    padding: 0.7rem 2rem;
                    cursor: pointer;
                }
                &:hover {
                    color: $brand;
                }
            }
            &-single {
                flex-wrap: wrap;
                &__dropdown {
                    display: inline-block;
                    vertical-align: middle;
                    margin-left: 3rem;
                    padding-bottom: 0.2rem;
                    .todo-widget__filters-older {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        min-width: 13rem;
                        margin-bottom: .5rem;
                    }
                    ul {
                        padding-left: 0;
                        top: 2rem !important;
                        li {
                            margin-left: 0;
                            width: 100%;
                            box-sizing: border-box;
                        }
                    }
                }
                .layer-options {
                    flex-basis: 35%;
                }
            }
            &-left {
                flex-basis: 60%;
                width: 60%;
                display: flex;
                align-items: flex-end;
                min-height: 4.9rem;
                @include breakpoint(tabletScreen) {
                    flex-basis: 50%;
                }
                &-single {
                    span {
                        font-size: 1rem;
                        font-weight: bold;
                        color: $black;
                        @include breakpoint (toDesktop) {
                            font-size: 2rem;
                        }
                    }
                    h1 {
                        color: $smallColor;
                        display: inline-block;
                        margin: 1rem;
                    }
                    h2 {
                        margin: 0;
                        font-size: 2.7rem;
                        color: $black;
                        font-weight: bold;
                        letter-spacing: 0;
                        text-transform: none;
                        line-height: 3.1rem;
                        position: relative;
                    }
                    a {
                        margin: 0;
                        text-transform: none;
                    }
                    &-item {
                        display: inline-block;
                        margin-left: 4rem;
                        margin-bottom: -.5rem;
                        &:hover {
                            cursor: default;
                        }
                        a {
                            margin-left: 0;
                            display: inline-block;
                            width: 100%;
                            height: 100%;
                            padding: 1rem;
                        }
                        a.is-active:hover {
                            cursor: default;
                        }
                        &-company {
                            &:hover {
                                cursor: pointer;
                            }
                            h2 {
                                color: $smallColor;
                            }
                            span {
                                color: $smallColor;
                            }
                        }
                    }
                    &-company {
                        h1 {
                            color: $black;
                        }
                    }
                    .investor-type {
                        display: inline-block;
                        vertical-align: middle;
                        margin-top: -0.5rem;
                        position: relative;
                        span {
                            border: 1px solid #ccd0d9;
                            top: 2px;
                            padding: 0.2rem 0.5rem;
                            font-size: 0.9rem;
                            width: auto;
                            text-align: center;
                            color: #aab2bd;
                            border-radius: 4px;
                            margin-left: 1rem;
                            vertical-align: middle;
                            display: inline-block;
                            line-height: normal;
                            font-weight: 500;
                            background: #fff;
                        }
                        @include breakpoint(tabletScreen) {
                            .investorModalPopup__position {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
            &-right {
                flex-basis: 40%;
                width: 40%;
                text-align: right;
                @include breakpoint(tabletScreen) {
                    flex-basis: 50%;
                    width: 50%;
                }

                i {
                    font-size: 14px;
                }
            }
        }
        &__navigation {
            margin-top: 2.5rem;
            margin-left: 1rem;
            a {
                margin-left: 0;
                margin-right: 5rem;
                position: relative;
                &:after {
                    position: absolute;
                    bottom: calc(-1rem - 1px);
                    left: 50%;
                    right: 50%;
                    background-color: $brand;
                    height: 1px;
                    content: '';
                    display: block;
                    @include transition (.3s all ease-in-out);
                }
                &.active {
                    color: $brand;
                    &:after {
                        left: -1rem;
                        right: -1rem;
                    }
                }
            }
        }
        &__middle {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            &-single {
                display: block;
            }
            &-filters {
                width: 200px;
                flex-basis: 200px;
                @include breakpoint(largeScreen) {
                    width: 250px;
                    flex-basis: 250px;
                }
                transform: translateZ(0);
                &-search {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: $white;
                    padding: 1rem;
                    border-radius: 3px;
                    input {
                        border: 0;
                        outline: none;
                        font-size: 1.1rem;
                        font-family: $regular;
                        width: 100%;
                        box-sizing: border-box;
                        @include breakpoint(smallLaptop) {
                            @-moz-document url-prefix() {
                                width: 95%;
                            }
                        }
                        &::placeholder {
                            color: $smallColor;
                        }
                    }
                    i {
                        font-size: 1.5rem;
                        color: $smallColor;
                        cursor: pointer;
                        &.default-cursor {
                            cursor: default;
                        }
                    }
                }
                &-holder {
                    background: $white;
                    margin-top: 2rem;
                    border-radius: 3px;
                    .dropdownFilter {
                        top: 1rem;
                    }
                    .todo-widget__block-right-info {
                        width: auto;
                        &:after {
                            display: none;
                        }
                    }
                    .media__info {
                        padding: 1.4rem;
                    }
                    &-top {
                        position: absolute;
                        background: $white;
                        left: 0;
                        right: 0;
                        top: 0;
                        z-index: 1;
                    }
                }
                &-select {
                    padding: 1rem;
                    &:first-child {
                        position: relative;
                        &:after {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border-color: $borderColor;
                            height: 1px;
                            content: '';
                            display: block;
                        }
                    }
                    .label {
                        color: $smallColor;
                        font-size: 1rem;
                        display: block;
                        margin-bottom: 0.5rem;
                        text-transform: none;
                        font-weight: 500;
                    }
                    .todo-widget__filters-older {
                        display: flex;
                        width: 100%;
                        flex-basis: 100%;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 1rem;
                        font-weight: 500;
                        color: $black;
                        text-transform: none;
                        ul {
                            left: -1.1rem;
                            right: -1.1rem;
                        }
                    }
                    .multiselect {
                        display: block;
                        background: transparent;
                        width: 100%;
                        border: 0px solid $white;
                        outline: none;
                        padding-left: 0;
                        font-size: 1.4rem;
                        position: relative;
                        margin-bottom: 0;
                        height: auto;
                        &.black-color input {
                            color: $black;
                        }
                        .multiselect--active {
                            input {
                                border: 0 !important;
                            }
                        }
                        &:hover {
                            border: 0;
                        }
                        &__input {
                            padding: 0 0 0 0.1rem;
                            border: 0 transparent;
                            font-size: 1.2rem;
                            font-weight: 500;
                            font-family: $regular;
                            position: relative !important;
                            color: $black;
                            &::placeholder {
                                color: $black;
                            }
                            &:focus,
                            &:hover {
                                outline-color: $white;
                            }
                        }
                        &__tags-wrap {
                            background: $white;
                            z-index: 1;
                            position: relative;
                        }
                        &__tag {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                            box-sizing: border-box;
                            padding: 1rem;
                            font-size: 1.2rem;
                            margin: 0.75rem 0;
                            font-weight: 600;
                            background-color: $white;
                            color: $black;
                            border: 1px solid $borderColor;
                            z-index: 1;
                            &-icon {
                                margin-left: 0.7rem;
                                width: 2.2rem;
                                line-height: 3rem;
                                position: relative;
                                &:before {
                                    content: "\f00d";
                                    font-family: FontAwesome;
                                    font-style: normal;
                                    font-weight: normal;
                                    text-decoration: inherit;
                                    color: $black;
                                    font-size: 1.7rem;
                                    position: absolute;
                                    top: -1.4rem;
                                    right: 0;
                                }
                                &:after {
                                    font-size: 2rem;
                                }
                            }
                        }
                        &__content {
                            top: 100%;
                            right: -1rem;
                            left: -1rem;
                        }
                        &__select {
                            position: absolute;
                            width: 1rem;
                            height: 1rem;
                            right: -0.8rem;
                            top: 0rem;
                            transition: inherit;
                            &:before {
                                position: relative;
                                right: 0;
                                top: 65%;
                                color: $black;
                                margin-top: 0.4rem;
                                border-style: solid;
                                border-width: 0.4rem 0.4rem 0;
                                border-color: $black transparent transparent;
                                content: "";
                            }
                        }
                        .multiselect__content-wrapper {
                            .multiselect__content {
                                background-color: $white !important;
                                border-color: $borderColor !important;
                                color: $black !important;
                            }
                        }
                    }
                }
            }
            &-holder {
                width: 40%;
                flex-basis: 40%;
                background-color: $white;
                border-radius: 3px;
                @include breakpoint(smallScreen) {
                    width: calc(40% - 60px);
                    flex-basis: calc(40% - 60px);
                }
                @include breakpoint(mediumScreen) {
                    width: calc(40% - 20px);
                    flex-basis: calc(40% - 20px);
                }
                @include breakpoint(largeScreen) {
                    width: 40%;
                    flex-basis: 40%;
                }
                &.deals-tab {
                    flex-basis: auto;
                }
                h2 {
                    margin: 0;
                    letter-spacing: 0;
                    text-transform: none;
                    font-weight: 600;
                }
                .media__image {
                    border-color: #f2f2f2;
                }
                .empty-content {
                    color: #666;
                    font-size: 14px;
                    text-align: center;
                    padding: 5rem 1rem;
                }
                &-top {
                    padding: 2rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    position: relative;
                    &:after {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 1px;
                        background-color: $borderColor;
                        display: block;
                        content: '';
                    }
                    h2 {
                        color: $black;
                        font-weight: 700;
                        font-size: 1.6rem;
                    }
                    .todo-widget__filters-older {
                        text-transform: uppercase;
                    }
                }
                &-content {
                    height: calc(100vh - 25rem);
                    overflow-y: auto;
                    @media screen and
                    (-webkit-min-device-pixel-ratio: 2) and
                    (min-resolution: 192dpi) and
                    (max-height: 1300px) {
                        min-height: 580px;
                        height: 580px;
                    }
                    @media screen and (max-height: 800px) {
                        min-height: 580px;
                        height: 580px;
                    }
                    .empty-content {
                        color: #666;
                        font-size: 14px;
                        text-align: center;
                        padding: 2rem 1rem;
                    }
                    .todo-widget__block-right {
                        width: auto;
                        flex-basis: auto;
                    }
                    .todo-widget__block-right-info {
                        &:after {
                            display: none;
                        }
                    }
                    .todo-dealsBlock {
                        margin: 2rem;
                        padding: 1.8rem 2rem;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: 5px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        .action__buttons {
                            text-transform: uppercase;
                            a {
                                color: $brand;
                                font-size: 1.2rem;
                                font-weight: 500;
                                @include transition(.3s all ease-in-out);
                                &:hover {
                                    color: $darkerBrand;
                                }
                            }
                        }
                    }
                    .todo-matchesBlock {
                        flex-wrap: wrap;
                        padding-bottom: 0;
                        .todo-widget__block-right {
                            padding-bottom: 2rem;
                        }
                        .todo-widget__block-right-title {
                            a:-webkit-any-link {
                                color: $black;
                                @include transition (.3s all ease-in-out);
                            }
                        }
                        .todo-matchesBlock__content {
                            width: 100%;
                            flex-basis: 100%;
                            position: relative;
                            margin: 1rem 0;
                            padding: 0.5rem 0;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            max-height: 1.27rem;
                            &.todo-companiesBlock {
                                a,
                                span {
                                    font-size: 1.2rem;
                                }
                            }
                            a,
                            span {
                                font-size: 1.3rem;
                                font-weight: 500;
                            }
                            a {
                                color: $brand;
                                text-transform: uppercase;
                                font-weight: 500;
                                @include transition(.3s all ease-in-out);
                                &:hover {
                                    color: $darkerBrand;
                                }
                            }
                            span {
                                color: $black;
                                font-weight: 400;
                            }
                            &:before {
                                position: absolute;
                                display: block;
                                content: '';
                                left: -2rem;
                                right: -2rem;
                                top: -1rem;
                                height: 1px;
                                background-color: $borderColor;
                            }
                            &.disabled {
                                a {
                                    color: $smallColor;
                                    @include transition(.3s all ease-in-out);
                                    &:hover {
                                        background-color: transparent;
                                        color: $smallColor;
                                        padding: 0;
                                    }
                                }
                                span {
                                    color: $smallColor;
                                }
                            }
                        }
                        .action__buttons {
                            padding-bottom: 2rem;
                            text-align: center;
                            .bigNumber {
                                display: block;
                                font-size: 3.6rem;
                                font-weight: 100;
                                color: $smallColor;
                            }
                            .bigText {
                                text-transform: uppercase;
                                font-size: 1rem;
                                color: $smallColor;
                                font-weight: 500;
                            }
                        }
                        &--modified {
                            .specialDealBlock {
                                margin: 2rem 0;
                                &:before {
                                    top: -2rem;
                                }
                                .todo-matchesBlock__content {
                                    margin: 2rem 0;
                                }
                                .todo-widget__block-right {
                                    padding-bottom: 0;
                                }
                                .action__buttons {
                                    padding-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        &__big {
            width: calc(100% - 200px);
            flex-basis: calc(100% - 200px);
            @include breakpoint(largeScreen) {
                width: calc(100% - 250px);
                flex-basis: calc(100% - 250px);
            }
            margin-left: 1.5rem;
            background: white;
            padding: 2rem;
            border-radius: 5px;
        }
    }
}

.homeLink {
    cursor: default;
    div {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 1100px;
        content: '';
        display: block;
        background-color: rgba(101, 109, 120, 0.5);
        @include multiple-media(mobileScreen, tabletScreen) {
            right: 100%;
        }
        @include breakpoint(largeScreen) {
            right: 1600px;
        }
        &.on-top {
            z-index: 1000;
            &__mask{
                background-color: rgba(101,109,120, 0.5);
            }
        }
    }
}

.layerLink {
    cursor: default;
    div {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        content: '';
        display: block;
        background-color: transparent;
    }
}

.common__layer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &-item {
        background-color: $white;
        border-radius: 5px;
        padding: 2rem;
        &:first-child {
            width: 11.8%;
            flex-basis: 11.8%;
            padding: 2.5rem;
        }
        &:last-child {
            width: 78.7%;
            flex-basis: 78.7%;
            padding-bottom: 2.5rem;
            max-height: calc(100vh - 30rem);
            overflow: hidden;
            overflow-y: auto;
        }
    }
}

.modal {
    &-layer {
        &-container {
            position: fixed;
            left: calc(100% - 680px);
            overflow-y: initial;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: $white;
            @include breakpoint(mobileScreen) {
                left: 0;
            }
            @include breakpoint(tabletScreen) {
                left: calc(100% - 540px);
            }
        }
    }
}

.slide-fade-enter-active {
    transition: all .3s ease-in-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(1rem);
    opacity: 0;
}

.fade-enter-active {
    transition-property: opacity;
    transition-duration: .3s;
}

.fade-enter {
    opacity: 0
}

.fade-leave-active {
    transition-property: opacity;
    transition-duration: .0s;
}

.fade-leave-to {
    transition-property: opacity;
    transition-duration: .0s;
}

.fade-leave {
    opacity: 0;
}

.flag__icon {
    position: absolute !important;
    top: 0.5rem;
    right: 0.5rem;
    width: 2.5rem !important;
}

.modal-layer-container {
    .common__modalBox--content {
        max-height: calc(100vh - 37rem);
        overflow: hidden;
        overflow-y: auto;
    }
}

.block {
    display: inline-block;
    word-break: break-word;
}

.layer-new {
    display: flex;
    @include breakpoint(toBigPhone) {
        overflow-y: hidden;
    }
    &.background {
        background: url('/images/investor-background.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    &__left {
        width: 100%;
    }
    &__right {
        height: 100vh;
        width: 100%;
        background-color: #E5E9EF;
        display: flex;
        flex-direction: column;
        box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.10);
        flex-shrink: 0;
        @include breakpoint(mediumScreen) {
            width: 1100px;
        }
        @include breakpoint(largeScreen) {
            width: 1600px;
        }
        .layer-body {
            @include breakpoint (toBigPhone) {
                max-height: calc(100vh - 100px);
                overflow-y: auto;
            }
        }
    }
    &__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 40px 40px 0;
        color: $black;
        border-bottom: 1px solid $smallBlockBorder;
        @include breakpoint(toBigPhone) {
            margin: 20px 20px 0;
        }
        >div {
            width: 100%;
        }
        h1 {
            font-size: 30px;
            font-weight: bold;
            text-transform: none;
            margin: 0;
            @include breakpoint(toBigPhone) {
                font-size: 22px;
            }
        }
        p {
            margin-top: 15px;
            font-size: 12px;
            font-weight: normal;
            line-height: 1.83;
        }
    }
    &__body {
        padding: 40px;
        @include breakpoint(toBigPhone) {
            padding: 20px;
            overflow-y: auto;
            max-height: calc(100vh - 160px);
        }
    }
}
