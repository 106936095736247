.company-news {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 266px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $color-white;
  font-family: $regular;
  margin-bottom: 20px;
  &__top {
    display: flex;
    flex-direction: row;
    min-height: 79px;
    border-bottom: 1px solid $smallBlockBorder;
    @include multiple-media(mobileScreen, tabletScreen) {
      display: flex;
      flex-direction: column;
    }
    &--left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 165px;
      height: 78px;
      padding: 0 31px 0 25px;
      border-right: 1px solid $smallBlockBorder;
      @include multiple-media(mobileScreen, tabletScreen) {
        width: auto;
        border-bottom: 1px solid $smallBlockBorder;
        border-right: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 35px;
        padding: 0 25px;
      }
      @include breakpoint(smallScreen) {
        width: 95px;
      }
      span {
        width: 100%;
        height: 13px;
        font-size: 1rem;
        font-weight: 500;
        color: #aab2bd;
        @include multiple-media(mobileScreen, tabletScreen) {
          text-align: right;
        }
        &:first-child{
          width: 100%;
          height: 15px;
          padding-bottom: 6px;
          color: $tagBackground;
          font-size: 1.2rem;
          text-align: left;
          @include multiple-media(mobileScreen, tabletScreen) {
            padding-bottom: 0;
          }
        }
      }
    }
    &--right {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      @include breakpoint (mobileScreen) {
        display: flex;
        flex-direction: column;
      }
      @include multiple-media(mobileScreen, tabletScreen) {
        min-height: 78px;
      }
      &-company-name {
        display: flex;
        flex-direction: row;
        width: calc(100% - 250px);
        @include breakpoint (mobileScreen) {
          width: 100%;
          align-items: center;
          padding: 10px 0;
        }
        img {
          min-width: 40px;
          height: 40px;
          padding: 0 20px;
          object-fit: scale-down;
        }
        span {
          width: 100%;
          min-height: 20px;
          font-size: 1.5rem;
          font-weight: 500;
          color: #434a54;
          align-self: center;
          a {
            color: $brand;
          }
        }
      }
      &-buttons {
        @include breakpoint(mobileScreen) {
          padding: 20px;
          width: calc(100% - 40px);
        }
        span {
          width: 100%;
          height: 15px;
          font-size: 1.2rem;
          font-weight: 600;
          padding-right: 30px;
          &:first-child{
            width: 100%;
          }
          a {
            color: $color-blue-main;
          }
        }
      }
    }
  }
  &__bottom {
    display: flex;
    flex-direction: row;
    padding: 18px 25px;
    width: fit-content;
    @include multiple-media (mobileScreen, tabletScreen) {
      display: flex;
      flex-direction: column;
    }
    &--left {
      display: flex;
      padding-right: 25px;
      background-color: $color-white;
      min-width: 300px;
      min-height: 150px;
      @include multiple-media (mobileScreen, tabletScreen) {
        margin: 2rem 0;
        align-self: center;
        padding-right: 0;
      }
    }
    &--right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-article {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 28px;
        div {
          line-height: 1.5em;
          height: 3em;
          overflow: hidden;
          font-size: 1.4rem;
          color: #434a54;
          p {
            margin: 0;
          }
          @include multiple-media(mobileScreen, tabletScreen) {
            padding: 0;
            &:first-child {
              padding: 0;
            }
          }
          &:first-child {
            line-height: 1.5em;
            height: 3em;
            overflow: hidden;
            margin-bottom: 7px;
            font-size: 1.8rem;
            a {
              display: block;
              color: $color-blue-main;
            }
          }
        }
      }
      &-footer {
        display: flex;
        flex-direction: row;
        img {
          min-width: 15px;
          height: 15px;
          padding-right: 6px;
        }
        span {
          width: 100%;
          height: 15px;
          font-size: 1.2rem;
          font-weight: normal;
          color: #aab2bd;
        }
      }
    }
  }
}
