/* The Close Button */
.modelclose {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1em 2em 0em 2em;
}

.modelclose > span:first-child {
    display: inline-block;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    color: #434a54;
}

.close {
    color: #656d78;
    font-size: 3em;
}

.close:hover,
.close:focus {
    color: #434a54;
    text-decoration: none;
    cursor: pointer;
}

/* Modal Header */
.modal-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
}

/* Modal Body */
.modal-body {
    padding: 0em 2em 2em 2em;
}

/* Modal Footer */
.modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    width: 30%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    margin-top: 5%;
}

/* Add Animation */
@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

/* Style the tab */
div.tab {
    overflow: hidden;
}

/* Style the buttons inside the tab */
div.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 15px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-bottom: 1px solid #f5f7fa;
    color: #ccd1d9;
    text-transform: uppercase;
    font-size: 12px;
}
/* Change background color of buttons on hover */
div.tab button:hover {
    color: $brand;
}

/* Create an active/current tablink class */
div.tab button.active {
    border-bottom: 1px solid $brand;
    color: $brand;
}

@-webkit-keyframes fadeEffect {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeEffect {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/*****End Login*****/
/*****Selected For You*****/

.navigation:hover {
    cursor: pointer;
}

.navigation > i {
    color: #000;
}

.scroll-down {
    background-color: #fff;
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.scroll-down > i {
    margin-right: 0.5em;
    font-size: 1em;
}

.scroll-down > span {
    font-size: 0.7em;
}
.up-arrow {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

/*****End Selected For You*****/

.data {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: #fff;
    padding: 20px;
}

.data > section > span {
    color: #aab2bd;
    text-transform: uppercase;
    font-size: 9px;
    margin-bottom: 5px;
    display: inline-block;
    font-weight: normal;
    letter-spacing: 0px;
}

h4 > sup {
    font-size: 1rem;
}

.description {
    position: relative;
}

/*****End Tile*****/
/*****Navigation*****/
.overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: auto;
    right: 0;
    background-color: #fff;
    overflow-y: hidden;
    -webkit-transition: inherit;
    transition: inherit;
    opacity: 0;
}
nav{
    z-index: 1;
    top: -1px;
}
.overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 2em;
    color: #000;
    display: block;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-transform: uppercase;
    letter-spacing: 16px;
    margin-bottom: 2em;
    font-weight: 200;
}

.overlay a:hover, .overlay a:focus {
    color: $brand;
}

/*****End Navigation*****/
.request-invite-error {
    color: #fb1000;
    margin-left: 0;
    margin-top: 5px;
    height: 20px;
    font-size: 14px;
    display: none;
}
#fos_user_resetting_form label {
    color: white;
}
.fos_user_resetting_reset {
    position: relative;
    margin: 0 auto;
    display: block;
    margin-top: 25px;
    padding-top: 14.5%;
    width: 1000px;
    margin: 0 auto;
}
.fos_user_resetting_reset input[type="submit"]{
    background-color: transparent;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    border: 2px solid $brand;
    padding: 0.7em 2em;
    text-transform: uppercase;
    color: $brand;
    letter-spacing: 3px;
    font-size: 0.9em;
}

.hr-line {
    border: none;
    height: 1px;
    background-color: rgba(51, 45, 51, 0.16);
}

.txt-bold {
    font-size: 12px;
    font-weight: 500;
}

.txt-light {
    font-weight: 100;
}

.txt-blue {
    font-size: 12px;
    font-weight: 500;
    color: #244FFF;
}

#policy > #investor-type > input {
    margin-top: 15px;
}
#hr-toggle {
    margin-top: 30px;
}

hr#bottom {
    position: relative;
    top: 30px;
}