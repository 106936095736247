$height: 40px;
$box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$border-radius: 3px;
$fz14px: 1.2rem;

.sign-panel {
    display: flex;
    align-items: flex-end;
    padding: 25px;
    background-color: $brand;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    margin-top: 25px;

    & * {
        box-sizing: border-box;
    }

    &__error {
        color: $red;
        position: absolute;
        font-weight: bold;
        left: 0;
        bottom: 0;
        font-size: 1.2rem;
        opacity: 0;
        @include transition(.3s ease);
    }
    
    &__label {
        margin-right: 40px;
        flex-grow: 2;
        position: relative;
        
        &-text {
            color: $white;
            font-size: $fz14px;
            line-height: 1;
            margin-bottom: 8px;
        }

        &--hasError {
            & > .sign-panel__input {
                border-color: $red;
            }

            & > .sign-panel__error {
                opacity: 1;
                transform: translateY(2rem);
            }
        }
    }

    

    &__input {
        display: flex;
        align-items: center;
        background-color: $white;
        border: none;
        border-radius: $border-radius;
        width: 100%;
        height: $height;
        padding: 0 15px;
        border: 2px solid transparent;
        @include transition(.3s ease);
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $brand;
        background-color: $white;
        border-radius: $border-radius;
        font-size: $fz14px;
        font-weight: 500;
        height: $height;
        text-transform: uppercase;
        @include transition(.3s ease);
        cursor: pointer;
        border: none;
        padding: 0 25px;

        &:hover {
            background-color: rgba($white, .8);
        }
    }
}