///------------------------------------
//    #SETTINGS
//------------------------------------

//------FONT SIZES CALCULATION
// DO NOT CHANGE BASE FONT SIZE, ITS EASIER FOR CALCULATION
// CHANGE FONT-SIZE USING THIS FORMULA
// A = 10  (font size we always use, just change resolution)
// B = 768 (resolution we are looking at in the desing)
// X = (A / B) * 100

//------DIMENSIONS
html {
    overflow-x: hidden;
    font-size: 10px;
	//change this part
    //from 1920px
    @include breakpoint (bigScreen) {
        font-size: 10px;
    }
    //from 601px to 1919px
    @media screen and (min-width:601px) and (max-width: 1919px) {
        font-size: 9px;
    }
    //up to 600px
    @media screen and (max-width:600px) {
        font-size: 10px;
    }
    &.noFont{
        font-size: inherit !important;
        body{
            padding: 0;
        }
    }
}

//------GLOBAL FONT SETTINGS FOR HEADERS - must be here
body {
    font-size: 100%;
    @media screen and (max-device-width: 480px){
        -webkit-text-size-adjust: none;
    }
}
$prata : 'Prata', serif;
$regular : 'Montserrat', sans-serif;
