.access-and-updates {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  &.proraise-branding {
      box-shadow: none;
      .activeTab {
          background-color: $founderBrand;
          border: 1px solid $founderBrand;
      }
      .access-and-updates-data__container--folder-toggle {
          color: $founderBrand;
      }
      .header__files-title i {
          color: $founderBrand;
      }
      .allowed-fully {
          background-color: $founderBrand;
      }
      .button-positive{
          background-color: $founderDarkerBrand;
      }
  }
  overflow: hidden;
    padding: 5px;

    .access-and-updates__investor-view {
        @include multiple-media(smallMobileScreen) {
            height: 79vh;
            margin-bottom: -22px;
            margin-top: 0;
        }
    }

    @include multiple-media(smallMobileScreen) {
        margin-left: -25px;
        margin-right: -25px;
        margin-top: -28px !important;
    }
    &.folder-list-tab {
        @include multiple-media(smallMobileScreen) {
            margin-top: -30px !important;
        }
        @include multiple-media(mobileScreen2) {
            margin-left: -25px;
            margin-right: -25px;
            margin-top: -50px;
        }

        .access-and-updates__investor-view {
            @include multiple-media(smallMobileScreen) {
                height: 90vh !important;
            }
            @include multiple-media(mobileScreen2) {
                height: 91vh;
                margin-bottom: -18px;
            }
        }
    }

  &__folder-view-header,
  &__investor-view-header {
      display: flex;
      justify-content: space-between;
      color: $black;
      margin-top: 20px;
      margin-left: 20px;
      h1 {
          font-size: 1.8rem;
            font-weight: 600;
          margin-top: 0;
      }
      &--info {
          margin-right: 25px;
          margin-top: 4px;
          span {
              font-size: 1rem;
              font-weight: 600;
              margin-right: 18px;
                color: $color-grey-21;
                font-family: NunitoBold, sans-serif;

              &:last-of-type {
                  margin-right: 18px;
              }
          }
      }
      &--files-info {
          span {
              font-size: 1.4rem;
              font-weight: 500;
              color: #434a54;
              i {
                  color: #1f7be8;
                  font-size: 3.0rem;
                  padding-right: 15px;
              }
          }
      }

  }

  &__folder-view,
  &__investor-view {
      width: 100%;
      border-radius: 3px;
      border: solid 1px $borderColor;
      background-color: $color-white;
      margin-top: 21px;
      display: flex;
      flex-direction: row;
      height: calc(74vh - 31px);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      @media screen and (min-width: 1024px) and (max-width: 1299px) {
          height: calc(74vh - 90px);
      }
      @media screen and (min-width: 1300px) and (max-width: 1440px) {
          height: calc(74vh - 91px);
      }
      @media screen and (min-width: 1441px) and (max-width: 1600px) {
          height: calc(74vh - 60px);
      }
      &--left {
          border-right: solid 1px $borderColor;

          .whitelabel-updates {
              height: calc(100% - 65px)!important;
          }
      }
      &--right {
          background-color: white;
      }

      &--left-body {
          overflow-y: auto;
          width: 390px;
            height: calc(100% - 145px);
          @include breakpoint(mediumScreen) {
              width: 327px;
          }
      }
      &--right-body {
          overflow-y: hidden;
          height: calc(100% - 65px);
          width: 100%;
          margin-right: 2rem;
          @-moz-document url-prefix() {
              position: relative
          }
          .header {
              display: flex;
              justify-content: space-between;
              &__title,
              &__files-title {
                    font-size: 1.55rem;
                  font-weight: 500;
                  color: #434a54;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                    display: flex;
                    align-items: center;
                  i {
                      color: $brand;
                      padding-right: 15px;
                      padding-bottom: 0.3rem;
                  }
                  @media screen and (min-width: 1024px) and (max-width: 1280px) {
                      font-size: 1.4rem;
                  }
              }
              &__title {
                  padding: 5px 17px;
                  i {
                      font-size: 1.2rem;
                  }
              }
              &__files-title {
                  padding: 0 17px 5px 17px;
                  i {
                      font-size: 3rem;
                  }
              }

              &__permissions,
              &__files-permissions {
                  margin-right: 23px;
                  cursor: pointer;
                  display: flex;
                  padding: 4px;
                  span {
                      margin: 0 1px 0 2px;
                  }
              }
          }
      }

      &--left-bottom,
      &--right-bottom {
          border-radius: 3px;
          box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.05);
          background-color: white;
          height: 65px;
          z-index: 1;
          position: relative;
      }

      &--right-bottom {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          z-index: 0;
          align-items: center;
      }
      &--left-bottom {
          display: flex;
          align-items: center;
          z-index: 0;
          span {
              font-size: 1.2rem;
              font-weight: 500;
              padding-left: 20px;
          }
          .switch {
              margin-left: 13px;
              .slider-button {
                  background-color: transparent;
              }
          }
      }
  }

  &__investor-view {
      &--left-body {
              height: calc(100% - 118px);
            @media screen and (max-width: 1024px) {
                height: calc(100% - 125px);
          }
          @media screen and (max-width: 1279px) {
                height: calc(100% - 125px);
          }
          @media screen and (min-width: 1920px) {
                height: calc(100% - 121px);
          }
      }
      &--left-bottom {
          display: flex;
          justify-content: space-between;
          span {
              font-size: 1.4rem !important;
          }
      }
      &--right {
          width: calc(100% - 392px);
          @include breakpoint(mediumScreen) {
              width: calc(100% - 329px);
          }
      }
  }
  &__folder-view-header {
    &--info {
      span {
        margin-right: 18px;
      }
    }
  }
  &__folder-view {
      &--left-body {
          height: calc(100% - 160px);
          @include multiple-media(smallLaptop, bigLaptop) {
              height: calc(100% - 153px);
          }

          .files-and-folders-left {
              height: 100% !important;
              .access-and-updates-data__container--folder,
              .access-and-updates-data__container--file {
                  width: calc(100% - 10px);
                  @-moz-document url-prefix() {
                      margin-left: 0 !important;
                  }
              }
          }
      }
      &--left-bottom {
          display: flex;
          span {
              font-size: 1.4rem !important;
          }
      }
      &--right {
          width: calc(100% - 392px);

          @media screen and (min-width: 1025px) and (max-width: 1799px) {
              width: calc(100% - 329px);
          }
      }
      &--right-body {
          overflow-y: hidden;
          height: calc(100% - 65px);
          width: 100%;

          .investors-list-right {
              height: 99.8%;
              position: relative;
              z-index: 0;

              .access-fixed-header {
                  display: flex;
                  flex-direction: column;
                  position: sticky;
                  background-color: #ffffff;
                  top: 0;
                  z-index: 1;
                  margin-bottom: 10px;

                  .no-edit-permissions {
                      padding-left: 3.3rem;
                  }
              }

              .investors-right {
                  max-height: inherit;
                  margin-top: 0;
                  .investors__profile {
                      cursor: default;

                      .company-name {
                          p {
                              width: fit-content;
                          }
                      }
                  }

                  .investors__profile--permissions {
                      right: 0;
                      top: 1.5rem;
                      width: 212px;
                      margin: 0;
                      position: absolute;
                      @include multiple-media(smallLaptop, bigLaptop) {
                          right: -16px;
                      }

                      span {
                          padding: 7px 14px;
                          margin-right: 1px;

                          &.not-available-watermark {
                              &:hover {
                                  i {
                                      color: $grayLight;
                                  }
                                  background-color: $darkerWhite;
                                  cursor: auto;
                              }
                              i {
                                  height: 1.8rem;
                                  top: 7px;
                              }
                          }

                          &.no-edit-permissions {
                              padding-left: 3.2rem;
                              margin-left: 3px;
                              &:hover {
                                  background-color: #f5f7fa;
                                  cursor: auto;
                              }
                          }

                          i {
                              position: relative;
                          }
                      }
                  }
              }
          }

          .header {
              &__title {
                  @media screen and (min-width: 1025px) and (max-width: 1799px) {
                      font-size: 1.4rem;
                  }

                  i {
                      font-size: 2.5rem;
                  }
              }
          }
      }
      .investors {
          margin-top: 20px;
      }
  }

  .loadingDetails {
      .investors__profile {
          cursor: default;
      }
      &.permissions span {
          cursor: default;
      }
  }

  .access-button {
      padding: 12px;
      font-size: 1.2rem;
      border-radius: 3px;
      margin: 12px 0;
      cursor: pointer;
      font-weight: 500;
      &:link {
          padding-top: 10px;
      }
      i {
          margin-right: 5px;
          font-size: 1.3rem;
      }
  }
  .button-positive {
      background-color: $brand;
      margin-right: 20px;
      color: $color-white;
  }
  .permissions {
      span {
          background-color: $darkerWhite;
          padding: 4px 14px;
          cursor: pointer;
          i {
              color: $color-grey-14;
              font-size: 2rem;
              top: 3px;
              &.fa {
                  &.fa-tint {
                      padding: 0 4px;
                  }
              }
          }

          &.allowed-partially {
              background-color: #98c7ff;

              i {
                  color: $white;
              }
          }

          &.allowed-fully {
              background-color: $brand;

              i {
                  color: $white;
              }
          }

          &:first-of-type {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
          }
          &:last-of-type {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
          }
          &:hover {
              background-color: $brand;
              i {
                  color: $white;
              }
          }
          &.no-edit-permissions {
              padding-left: 3.3rem;
              &:hover {
                  background-color: $darkerWhite;
                  cursor: auto;
              }
          }
          &.not-available-watermark {
              &:hover {
                  i {
                    color: $grayLight;
                  }
                  background-color: $darkerWhite;
                  cursor: auto;
              }
              i {
                  background-image: url('/images/tint-slash-solid.svg');
                  width: 1.8rem;
                  height: 100%;
                  background-repeat: no-repeat;
              }
          }
      }
  }
  .exchange {
      width: 22px;
      height: 22px;
      border-radius: 5px;
      color: $white;
      background-color: $brand;
      position: relative;
      left: 10px;
      i {
          font-size: 1.5rem;
          padding: 3px;
      }
  }
  .fa-rotate-135 {
      -webkit-transform: rotate(130deg);
      -moz-transform: rotate(130deg);
      -ms-transform: rotate(130deg);
      -o-transform: rotate(130deg);
      transform: rotate(130deg);
  }
  .investors-right {
      max-height: 90%;
      @media screen and (min-width: 1024px) and (max-width: 1440px) {
          max-height: 86%;
      }
  }
  .investors-left {
      max-height: 100%;
  }
  .investors {
      &__profile {
          display: block;
          height: 100%;
          border-radius: 3px;
            border-top: solid 1px $darkerWhiteDO;
            padding: 20px;
          cursor: pointer;
          position: relative;
          &.active, &:hover {
                background-color: $color-green-main-3;
          }
          .top-row {
              display: flex;
              width: 100%;
              .investors__profile--image {
                  min-width: 40px;
                    width: 40px;
                    height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  img {
                      max-height: 100%;
                      max-width: 100%;
                  }

                  .initials {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      height: 100%;
                      width: 100%;
                      box-sizing: border-box;
                      border: solid 1px #dae0e8;
                      border-radius: 5px;
                      background-color: #fafbfd;
                      color: $color-white-3;
                  }
              }
              .pointer {
                  cursor: pointer;
              }
          }
          .bottom-row {
              display: flex;
              width: 100%;
              p {
                  margin: 5px 0 0;
                  display: block;
                  width: 100%;
                  span.email-update {
                      width: 2rem;
                      position: relative;
                      margin-right: 5px;
                      i.email-sent {
                          position: absolute;
                          color: $colorCheck;
                          left: .5rem;
                          bottom: 0;
                          background-color: transparentize($white, .25);
                          font-size: .8rem;
                          border-radius: 50%;
                      }
                  }
              }
          }
          &--description {
              margin-left: 14px !important;
              flex-direction: column;
              margin: auto;
              max-width: 80%;
              flex: 1;
              @media screen and (min-width:1025px) and (max-width: 1279px) {
                  max-width: 72%;
              }
              @media screen and (min-width:1280px) and (max-width: 1367px) {
                  max-width: 74%;
              }
              &.has-email-icon {
                  max-width: 50%;
                  @media screen and (min-width:1025px) and (max-width: 1279px) {
                      max-width: 37%;
                  }
                  @media screen and (min-width:1280px) and (max-width: 1365px) {
                      max-width: 44%;
                  }
                  > div {
                      max-width: 100%;
                      @media screen and (min-width: 1366px) and (max-width: 1441px) {
                          max-width: 80%;
                      }
                  }
              }
              .investors-name {
                  & > div,
                  .presented-email-icon {
                      max-width: 100%;
                  }
                  & > div > p:first-child,
                  .presented-email-icon > p:first-child
                   {
                        font-size: 1.4rem;
                        font-weight: 500;
                      color: $titleBlack;
                  }
                  & > div > p {
                      margin-top: 0;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                  }
                  display: flex;
                  flex-direction: row;
                  font-size: 1.4rem;
                  font-weight: 500;
                  width: 80%;
                  @include breakpoint(mediumScreen) {
                      width: 55%;
                  }
                  a {
                      font-weight: 500;
                      font-size: 1.4rem;
                      color: #434a54;
                      text-overflow: ellipsis;
                      :hover {
                          cursor: pointer;
                      }
                  }
                  .badge {
                      position: relative;
                      left: 0;
                      bottom: -2px;
                  }
              }
              p {
                  font-size: 1.2rem;
                  margin: 0;
                  color: #aab2bd;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 30ch;
              }
          }
          &--mail {
              padding: 5px 10px;
              border-radius: 3px;
              background-color: $darkerWhite;
              display: flex;
              align-self: center;
              margin-left: auto;
              margin-right: 10px;
              @media screen and (max-width: 1366px) {
                  margin-right: 4px;
              }
              span {
                  font-size: 1.2rem;
                  position: relative;
                  color: $black;
                  i {
                      font-size: 1.3rem;
                      margin-right: 5px;
                  }
              }
              :hover {
                  cursor: pointer;
              }
          }
          &--three-dots {
                margin: auto;
                cursor: pointer;
                :hover {
                    cursor: pointer;
                }
              .more-items__hub-block {
                  margin-top: 6px;
                  transform: translateX(-4px);
                  &__content {
                      &:before {
                          right: 20px;
                      }
                      &:after {
                          right: 21px;
                      }
                      .three-dots-item {
                          display: flex;
                          a {
                              display: flex;
                          }
                          i {
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              width: 20px;
                              height: 17px;
                              padding-right: 10px;
                              padding-left: 5px;
                                color: $brand;
                                font-size: 16px !important;
                          }
                          span {
                                font-size: 16px !important;
                          }
                      }
                  }
                }
                .more-items--horizontal{
                    color: $color-green-main-2;
              }
          }
          &--permissions {
              margin-left: auto;
              margin-top: 15px;
              margin-right: 9px;
              cursor: pointer;
          }
          &--matched-logo {
              position: relative;
              img {
                  width: 14px;
                  height: 14px;
                  position: absolute;
                  top: -5px;
                  left: -5px;
                  z-index: 1;
              }
          }

      }
  }
  .files-and-folders-right {
      height: calc(100% - 83px);
      z-index: 0;
      position: relative;
      @include multiple-media(largeScreen) {
          height: calc(100% - 90px);
      }
      &__container {
          display: flex;
          flex-direction: column;
          width: 100%;
      }
  }
  .files-and-folders-left {
      height: 98%;
      &__container {
          margin-bottom: 15px;
          display: flex;
          flex-direction: column;
          width: 100%;
      }
  }
  .access-and-updates-data {
      &.InvestorViewDisableOverlay {
          overflow-y: visible;
          overflow-x: visible;
          height: auto !important;
      }
      width: 100%;
      overflow: auto;
      scroll-behavior: smooth;
      &__container {
          background-color: $color-white;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 25px;
          height: 100%;
          width: calc(100% - 50px);
          &--folder-toggle,
          &--file-toggle {
              margin-right: 7px;
              margin-left: 3px;
              color: $brand;
          }
          &--file-toggle {
              color: #a9b2bd;
          }
          &--file {
              @-moz-document url-prefix() {
                  margin-left: 15px !important;
              }
              .full-width-wrapper {
                  width: 100% !important;
              }
          }
          &--folder {
              @-moz-document url-prefix() {
                  margin-left: 30px !important;
              }
              .full-width-wrapper {
                  width: 100% !important;
              }
          }
          &__item-outline {
              display: flex;
              width: 100%;
              align-items: center;
          }
          &--folder,
          &--file {
              display: flex;
              align-items: center;
              flex-grow: 1;
              word-break: break-all;
              height: 38px;
              border: 1px solid transparent;
              width: 100%;
              margin-bottom: 15px;
              margin-left: 10px;
              position: relative;
              &:hover {
                  border-radius: 3px;
                  border: solid 1px #c6d2e8;
                  background-color: #e8f0fa;
                  cursor: pointer;
              }
              .file-image {
                  .fa {
                      font-size: 2.8rem;
                  }
                  img {
                      max-width: 100%;
                      max-height: 100%;
                  }
                  .pdf-icon {
                      color: #ff1919;
                  }
                  .doc-icon {
                      color: #0d5aa7;
                  }
                  .xls-icon {
                      color: #0d904f;
                  }
                  .ppt-icon {
                      color: #ce8735;
                  }
                  .image-icon {
                      color: #deb40e;
                  }
              }
              .file-name {
                    font-size: 1.55rem;
                  color: #434a54;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  position: relative;
                  top: 5px;
                  padding-left: 10px;
              }
              .folder-name {
                  font-size: 1.4rem;
                  margin-left: 10px;
                  color: #434a54;
              }
              .folder-name,
              .file-name,
              .row-title {
                  width: 100%;
                  span {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      position: relative;
                      display: inline-block;
                      width: calc(100% - 200px);
                  }
              }
              .folder-image {
                  img {
                      width: 31px;
                      height: 26px;
                      padding-top: 8px;
                      padding-bottom: 7px;
                  }
                  i {
                      color: $color-cyan-secondary;
                  }
              }
              .folder-permissions,
              .files-permissions {
                  display: flex;
                  position: relative;
                  right: 0;
                  span {
                      margin: 0 1px 0 2px;
                  }
              }
          }
          .toggle-wrapper {
              display: flex;
              flex-direction: row;
              width: 100%;
              cursor: pointer;
              .toggle-wrapper-icon {
                  width: 10px;
              }
              .toggle-wrapper-icon-file {
                  position: relative;
                  top: 3px;
                  width: 20px;
              }
              .toggle-wrapper-icon,
              .folder-name {
                  display: -webkit-flex;
                  -webkit-align-items: center;
                  display: flex;
                  align-items: center;
              }
          }
          .folder-view-file-name {
            width: 100%;
          }
          .search-result {
              .folder-image,
              .file-image {
                  padding-left: 10px;
              }
              .file-name {
                  top: 0;
                  cursor: pointer;
              }
          }
      }
  }
  .accessAndUpdatesSelectedElement{
      border-radius: 3px;
      border: solid 1px #c6d2e8;
      background-color: #e8f0fa;
  }

  .save-data-room-permission-progress {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      font-size: 5rem;
      color: $color-blue-main;
  }

  &.proraise-branding {
      .investors__profile--three-dots .more-items__hub-block__content .three-dots-item i {
          color: $founderBrand;
      }

      &__folder-view {
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
            margin: 20px 5px 5px 5px;
            box-sizing: border-box;
            width: unset;
            &--left {
                width: 63.5%;
                &-body {
                    width: unset;
                    height: calc(100% - 210px);
                    @media (min-width: 1920px) {
                        height: calc(100% - 213px);
                    }
                }
                .access-and-updates-data__container__item-outline {
                    padding-left: 12px;
              height: 50px;
              box-sizing: border-box;
              border: 1px solid transparent;
              .row-title {
                  height: 30px;
              }
                }
                .accessAndUpdatesSelectedElement {
                    border: 1px solid transparent;
                    border-radius: unset;
                    background-color: inherit;
                    .access-and-updates-data__container__item-outline {
                        background-color: #e2f5f7;
                        border-radius: 5px;
                    }
                }
            }
            &--right {
                min-width: 350px;
                width: 36.5%;
                .investors__profile {
                    height: 80px;
                    padding: 20px;
                    margin: 0;
                    box-sizing: border-box;
                    border-radius: unset;
                    border: unset;
                    border-bottom: 1px solid $color-grey-12;

                    &:active, &:hover {
                        background-color: #e2f5f7;
                    }

                    .investors-name {
                        p {
                        font-size: 16px;
                        font-weight: bold;
                        }
                        .badge {
                            bottom: -4.6px;
                        }
                    }
                }
                .investors__profile--permissions {
                    top: 28.5px;
                    right: 7px;
                    span {
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }
                }
                .investors__profile--image {
                    height: 40px;
                    width: 40px;
                }
                .access-fixed-header {
                    margin-bottom: 0;
                    h1 {
                        font-size: 18px;
                        font-weight: bold;
                        margin: 0;
                    }
                    .access-and-updates__folder-view-header {
                        margin: 0;
                        box-sizing: border-box;
                        height: 65px;
                        padding: 0 20px;
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid $color-grey-12;

                        &--info {
                            margin: 0;
                            margin-right: 7px;
                            span {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 600;
                                color: $color-grey-21;
                                text-transform: lowercase;
                                &:first-of-type {
                                    margin-right: 19px;
                                }
                            }
                        }
                    }

                    .header {
                        padding: 0 20px;
                        height: 80px;
                        border-bottom: 1px solid $color-grey-12;
                        display: flex;
                        align-items: center;
                        &__title{
                            color: #000;
                            font-size: 16px;
                            font-weight: bold;
                            i {
                                height: 40px;
                                width: 40px;
                                border: 1px solid $color-grey-12;
                                border-radius: 5px;
                                box-sizing: border-box;
                                padding: 6.5px;
                                color: $color-grey-20;
                                background-color: $color-white-2;
                                margin-right: 15px;
                            }
                        }
                    }
                    .header__permissions {
                        margin: 0;
                        padding: 0;
                        span {
                            padding-top: 5.5px;
                            padding-bottom: 5.5px;
                        }
                    }
                }
                &-body {
                    height: calc(100% - 70px);
                }
                &-bottom {
                    height: 70px;
                    padding: 0 20px;
                    box-sizing: border-box;
                    .access-button {
                        padding: 14px 20px;
                        font-size: 16px;
                        font-weight: bold;
                        text-transform: lowercase;
                        &::first-letter {
                            text-transform: capitalize;
                        }
                        margin: 0;
                        border-radius: 5px;
                        &:first-of-type {
                            margin-right: 15px;
                        }
                    }
                }
            }
          }
          &__filters {
              margin: 5px;
              &--buttons {
                  .fa {
                      color: $color-grey-20;
                  }
                  .activeTab .fa {
                      color: #fff;
                  }
              }
          }
          &__filters .activeTab {
              background-color: $color-cyan-secondary;
          }
          &__folder-view-header, &__investor-view-header {
              &--files-info span i {
                  color: $color-cyan-secondary;
              }
          }
          &__folder-view-header {
              margin: 0;
              h1 {
                  color: #000;
              }
              &--files-info {
                  padding: 0;
                  width: 100%;
                  margin-bottom: 15px;
                &__header {
                    font-size: 18px;
                    font-weight: bold;
                    padding: 20px;
                    margin-bottom: 31px;
                    border-bottom: 1px solid $color-grey-12;
                }
                &__dataroom-text {
                    margin-left: 20px;
                    span {
                        color: #000;
                        font-weight: bold;
                        font-size: 18px;
                        text-transform: lowercase;
                        display: inline-block;
                        transform: translateY(-2px);
                        &::first-letter {
                            text-transform: capitalize;
                        }
                    }
                }
            }
          }
          &__folder-view, &__investor-view {
              &--right-body .header {
                  &__title, &__files-title {
                      i {
                          color: $color-cyan-secondary;
                      }
                  }
              }
          }

          &__investor-view--left-body.whitelabel-updates, &-data.files-and-folders-right, &-data.files-and-folders-left, &-data.investors-list-right {
              &::-webkit-scrollbar-thumb {
                  background-color: $color-purple-main;
              }
          }

          &-data__container {
              &--file-toggle, &--folder-toggle {
                  i.fa-rotate-135 {
                      color:$color-grey-4;
                  }
                  i.fa-chevron-right, i.fa-chevron-down {
                      font-size: 16px;
                  }
                  i.fa-level-up {
                      color: $color-grey-20;
                  }
              }
              &--file, &--folder {
                  height: 50px;
                  margin-bottom: 0;
                  &:hover {
                      background-color: inherit;
                      border: 1px solid transparent;
                      border-radius: unset;
                      .access-and-updates-data__container__item-outline {
                          background-color: #e2f5f7;
                          border-radius: 5px;
                      }
                  }
              }
              &--folder-toggle {
                  margin-left: 6px;
              }
              .toggle-wrapper-icon {
                  margin-right: 19px;
                  &-file {
                      margin-right: 12px;
                      .fa-level-up {
                        transform: translate(5px, 16px) rotate(90deg) scale(1.3);
                      }
                  }
              }
              .row-title {
                  font-size: 18px;
                  font-weight: bold;
                  color: #000;
              }
              &.files-and-folders-right__container {
                margin-left: 12px;
                .room-tree {
                    width: calc(100% - 25px);
                }
              }
          }

      .permissions span {
          &.allowed-partially {
              background-color: $color-cyan-secondary-pale;
          }
          &.allowed-fully {
              background-color: $color-cyan-secondary;
          }
          &:hover {
              background-color: $color-cyan-secondary-darker;
          }
      }

      .save-data-room-permission-progress {
          color: $color-purple-main;
      }

      .button-positive {
          background-color: $color-purple-main;
      }

      .investors__profile--three-dots .more-items__hub-block__content .three-dots-item i {
          color: $color-cyan-secondary;
      }

      .investors__profile--description .investors-name {
          > div > p:first-child, .presented-email-icon > p:first-child {
              font-size: 1.4rem;
              font-weight: 600;
          }
      }

      .access-and-updates {
          &__folder-view-header, &__investor-view-header {
              &--files-info span i {
                  color: $color-green-main-2;
              }
          }

          &__folder-view, &__investor-view {
              &--right-body {
                  font-family: NunitoBold, sans-serif;
              }
          }

          &-data__container {
              &--folder, &--file {
                  .folder-name {
                      font-size: 1.55rem;
                  }
              }
          }
      }
  }
}
.files-and-folders-right {
.access-fixed-header {
    display: flex;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    background-color: #ffffff;
    top: 0;
    z-index: 1;
    margin-bottom: 10px;
}
.room-tree {
   margin-bottom: 0;
}
> .room-tree-child:first-child {
    width: min-content;
    min-width: 100%;
    margin-top: 15px;
}
}
.room-tree {
  width: calc(100% + 50px);
  .room-tree-child {
    width: calc(100% - 20px);
    padding-left: 20px;
      .modal-pdf.unset {
          position: unset !important;
      }
  }
}
.InvestorViewRightBottom {
  position: relative;
  z-index: -1 !important;
}
.not-available-watermark-tooltip {
  .tooltip-inner {
      font-size: 1.2rem !important;
      font-family: $regular;
      line-height: 1.6rem;
      max-width: 20rem;
      font-weight: 600;
      color: $color-white;
      border-radius: 4px;
  }
}
.permissions-position {
  position: absolute;
  left: 260px;
  margin-top: 7px;
  z-index: 1;
  display: inline-block;
  @include multiple-media(largeScreen) {
      left: calc(100% - 222px);
  }
}
.permissions {
  display: flex;
  background-color: $color-white;
  border-radius: 5px;
  span {
      &:first-of-type {
          margin-left: 0;
      }
      &:last-of-type {
          margin-right: 0;
      }
      margin: 0 1px 0 2px;
  }
}
.folder-view {
  .files-and-folders-left {
     .room-tree-folder-view-wrapper {
          width: calc(100% - 40px);
      }
  }
  .folder-name {
      span {
          width: calc(100% - 65px) !important;
      }
  }
  .access-and-updates-data__container {
      width: 100% !important;
  }
  .permissions {
      background-color: unset !important;
      &:not(:first-of-type) {
          display: block !important;
          span {
              margin: 0;
          }
      }
  }
}
.firefox-scroll-permission-position {
  left: 257px;
  @include multiple-media(largeScreen) {
      left: calc(100% - 208px);
  }
}
