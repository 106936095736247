.do-notes {
  padding-top: 0;
  height: calc(100vh - 20rem);
  overflow-y: auto;
  background-color: transparent;
  width: calc(100% - 226px);
  flex-basis: calc(100% - 226px);
  -ms-flex-preferred-size: calc(100% - 226px);
  @media screen and (max-width: 899px) {
    height: calc(100vh - 25rem);
  }
  @media screen and (min-width: 1800px) {
    width: calc(100% - 276px);
    flex-basis: calc(100% - 276px);
    -ms-flex-preferred-size: calc(100% - 276px);
  }
  textarea {
    background: $tableGray;
    height: 5.5rem;
    resize: none;
    border-radius: 5px;
    color: $black;
    font-size: 1.2rem;
    box-sizing: border-box;
    font-family: $regular;
    padding: 1.8rem 6rem 1.8rem 2rem;
    outline: none;
    border-color: $layerBorder;
    overflow-y: auto;
    font-weight: 600;
    &.inputError{
      border-color: $red;
    }
  }
  &__item {
    background-color: $white;
    border-radius: 5px;
    padding: 2rem;
    margin-top: 2rem;

    .more-items__hub-block {

      @include breakpoint(largeScreen) {
        right: -1rem;
      }
    }


    .errorText{
      display: none;
      position: absolute;
      bottom: 0.5rem;
      right: 2rem;
      color: $red;
      font-weight: bold;
      @include breakpoint (toDesktop){
        font-size: 2rem;
        bottom: -2.5rem;
      }
    }
    .errorEditText{
      position: absolute;
      bottom: 3.2rem;
      right: 2rem;
      color: $red;
      font-weight: bold;
      @include breakpoint (toDesktop){
        font-size: 2rem;
        bottom: -2.5rem;
      }
    }
    &.showError{
      background-color: $white;
      position: relative;
      .errorText{
        display: block;
      }
      .positioned {
        position: relative;
      }
      .input{
        border-color: $red;
      }
    }
    .button__approve--cancel{
      position: absolute;
      top: 1rem;
      right: 0.7rem;
      background: transparent;
      font-size: 1.1rem;
      color: $white;
      padding: 0.5rem 0.7rem;
      border-radius: 100%;
      line-height: 1.3rem;
      @include transition(.3s all ease-in-out);
    }
    &:first-child {
      margin-top: 0;
    }
    @media screen and (max-width: 767px) {
      textarea {
        height: 160px;
      }
      .button__approve--textarea {
        bottom: 19px;
        top: unset;
      }
    }
  }
  &__grey {
    background-color: $darkerWhite;
    border-color: $darkerWhite;
  }
  &__deal {
    padding: 2rem;
  }
  &__period-filter {
    cursor: pointer;
    padding: 1rem 0 1rem 2rem;
    font-weight: 500;
    &_active {
      @extend .box;
      background-color: $white;
    }
  }
  &__filters_holder {
    padding-top: 2rem;
    font-size: 1.2rem;
  }
  .my-dropdown-dd{
    position: relative !important;
    margin-top: 2rem;
  }
  .do-network-matched-holder{
    padding: 0 !important;
    margin-bottom: 0 !important;
  }
  .do-network-matched-block {
    display: flex;
    flex-direction: row;
  }

  &__period-filter_active{
    color: $brand;
  }
  &__item{
    position: relative;
    &:first-child{
      position: relative;
    }
    .do-network-matched-block{
      padding-left: 2rem;
    }
  }
  &__button{
    position: absolute;
    top: 5rem;
    font-size: 2rem;
    cursor: pointer;
  }
}
.relativeBox{
  position: relative;
  textarea{
    overflow-y: auto;
  }
}
.tasks_container{
  display: flex;
  align-items: flex-start;
  padding-top: 2rem;
  padding-bottom: 4rem;
  justify-content: space-between;
  > div{
    width: 32%;
    flex-basis: 32%;
    max-height: calc(100vh - 15rem);
    overflow-x: hidden;
    overflow-y: auto;
  }
  &-first{
    width: 200px;
    flex-basis: 200px;
    > div{
      background: $white;
      padding: 2rem;
    }
    .do-profile__item-info-small,
    .do-profile__item-info-big{
      font-weight: 500 !important;
    }
    .media__image-big {
      width: 150px !important;
      height: 150px !important;
    }
  }
  &-button{
    text-align: center;
    text-transform: uppercase;
    font-size: 1.1rem;
    background: $brand;
    padding: 1.2rem;
    width: 10rem;
    margin: 2rem auto;
    margin-bottom: 0;
    color: $white;
    cursor: pointer;
  }
  &-order{
    margin-top: 2rem;
    padding: 1.5rem 1rem !important;
    h4{
      font-weight: 600;
      font-size: 1.2rem;
      text-transform: uppercase;
      position: relative;
      color: $black;
      margin-bottom: 3rem;
      &:after{
        bottom: -1.5rem;
        left: -1rem;
        right: -1rem;
        position: absolute;
        height: 1px;
        background-color: $layerBorder;
        content: '';
        display: block;
      }
    }
    label{
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 500;
    }
    input{
      box-sizing: border-box;
      width: 6rem;
      display: block;
      border: 1px solid $layerBorder;
      padding: 0.75rem 0.5rem;
      outline: none;
      background-color: $tableGray;
      border-radius: 5px;
      color: $black;
      font-family: $regular;
      font-size: 1.1rem;
      font-weight: 500;
    }
    &-block{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;
      margin-top: 1rem;
      &-item{
        width: 6rem;
      }
    }

  }
  &-close-button {
    float: right;
    margin-top: -0.6rem;
    cursor: pointer;
    font-size: 2rem !important;
    @include transition(.3s all ease-in-out);
    &:hover{
      color: $brand;
    }
  }
  .disabled {
    cursor: text;
    opacity: 0.5;
  }
  &-add{
    margin-top: 2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: $brand;
    text-align: center;
    cursor: pointer;
    @include transition (.3s all ease-in-out);
    &.disabled {
      &:hover {
        background-color: $white;
        color: $brand;
      }
    }
    &:hover{
      background-color: $brand;
      color: $white;
    }
  }
  &__child{
    padding: 2rem;
    > div{
      width: 100%;
    }
    &:first-child{
      width: 30%;
      flex-basis: 30%;
    }
    &:last-child{
      width: 63%;
      flex-basis: 63%;
      margin-left: 0%;
      background-color: transparent;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      max-height: calc(100vh - 15rem);
      overflow-x: hidden;
      .do-user__item {
        margin-bottom: 2rem;
        width: 100%;
        margin-right: 0;
        flex-basis: 100%;
        width: auto;
        position: relative;
        &:first-child{
          padding: 2rem;
        }
      }
    }
  }
  .do-user__item{
    textarea{
      background: $tableGray;
      height: 5.5rem;
      resize: none;
      border-radius: 5px;
      color: $black;
      font-size: 1.2rem;
      box-sizing: border-box;
      font-family: $regular;
      padding: 1.8rem 6rem 1.8rem 2rem;
      outline: none;
      border-color: $layerBorder;
      overflow-y: auto;
      font-weight: 600;
      &.inputError{
        border-color: $red;
      }
    }
    .errorText{
      display: none;
      position: absolute;
      bottom: 0.5rem;
      right: 2rem;
      color: $red;
      font-weight: bold;
      @include breakpoint (toDesktop){
        font-size: 2rem;
        bottom: -2.5rem;
      }
    }
    .errorEditText{
      position: absolute;
      bottom: 3.2rem;
      right: 2rem;
      color: $red;
      font-weight: bold;
      @include breakpoint (toDesktop){
        font-size: 2rem;
        bottom: -2.5rem;
      }
    }
    &.showError{
      background-color: $white;
      position: relative;
      .errorText{
        display: block;
      }
      .positioned {
        position: relative;
      }
      .input{
        border-color: $red;
      }
    }
  }
  .todo-widget__block-right-image{
    width: 100%;
    height: 100%;
    border: 0;
  }
  .button__approve--cancel{
    position: absolute;
    top: 1rem;
    right: 1.4rem;
  }
  &-holder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .three-dots{
      margin-top: -0.2rem;
    }
  }
  .task-company-note {
    padding-bottom: 10rem !important;
    max-height: calc(100vh - 17rem) !important;

    &__edit {

      .do-user__item:last-child {
        overflow: unset;
        overflow-y: unset;
      }

      .more-items__hub-block {
        right: -1.7rem;
        top: 4rem;

        @include breakpoint(largeScreen ) {
          right: -1rem;
        }
      }

      .three-dots {
        margin-right: 1rem;
      }
    }
  }
}
.tasks_description{
  display: inline-block;
  width: 100%;
  font-weight: 500;
  color: $black;
  font-size: 1.1rem;
  line-height: 1.7rem;
  white-space: pre-wrap;
}
.tasks_assign_button{
  float: right;
  color: $brand;
}
.task_author{
  display: inline-block;
  color: $dark-gray;
}
.task_by_investor{
  color: $brand;
}
.three-dots{
  width: 10%;
  display: inline-block;
  text-align: right;
}
.task_edit{
  width: 100%;
  overflow-y: hidden;
  @include transition(.3s all ease-in-out);
  &:focus{
    background-color: $white !important;
    border-color: $black !important;
    color: $black !important;
  }
  &:hover{
    border-color: $black;
  }
}
.button__approve--textarea{
  position: absolute;
  top: 1.9rem;
  right: 2rem;
  background-image: url('/images/enter.png');
  background-repeat: no-repeat;
  background-size: contain;
  text-indent: -9999px;
  width: 6rem;
  height: 6rem;
}
