.appendix-b {
  height: 100vh;

  &__body {
      top: 50px;

      &__header {
        align-items: baseline;

        &__left {
          width: 80%;

          &--title {
            font-size: 2.4rem !important;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            @include multiple-media( mediumScreen, largeScreen) {
              font-size: 3rem !important;
            }
          }
        }

        &__link {
          .feedback__button {
            display: none;

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
              display: inline-block;
            }
          }

          .feedback__button-mobile {
            padding: 8px;
            font-size: 1.1rem;
            background-color: $color-white;
            border-radius: 3px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            border: 1px solid $smallBlockBorder;
            color: $color-blue-main;

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
              display: none;
            }
          }
        }
      }

    .layer-body {
      padding: 1rem;
      overflow-y: hidden;

      @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        padding: 2rem;
      }
    }

  }

  &__block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: calc(100vh - 170px);
    padding-bottom: 4rem;

    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
      height: calc(100vh - 175px);
    }

    &__steps {
      background-color: $color-white;
      display: none;
      margin-right: 2.5rem;
      min-width: 200px;
      border: 1px solid $smallBlockBorder;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;

      @include multiple-media(smallScreen, mediumScreen, largeScreen) {
        display: block;
      }

      @include breakpoint(largeScreen) {
        min-width: 250px;
      }

      &__tab {
        display: flex;
        justify-content: space-between;
        padding: 1.5rem;
        font-size: 1.2rem;
        font-weight: 500;
        color: $dark-gray;
        border-bottom: 1px solid $smallBlockBorder;

        &__right {
          font-size: 1.6rem;
        }
      }

      .currentStep {
        .stepText{
          color: $black;

        }
      }
      .doneStep {
        color: $positive;
      }
    }

    &__views {
      background-color: $color-white;
      border: 1px solid $smallBlockBorder;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
      flex-grow: 1;

      a {
        color: $color-blue-main;
      }
    }
  }
}
