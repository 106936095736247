.walkthrough-page {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    scroll-padding: 0 0 15rem 0;
    background-color: $black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    .exit-preview-container {
        right: 0;
        cursor: pointer;
        img {
            width: 40px;
            background-color: white;
        }
    }
    @include multiple-media(mobileScreen, mobileScreenLandscape) {
        scroll-snap-type: none;
        scroll-padding: unset;
    }

    &__container {
        min-height: calc(100vh - 106px);
        position: relative;
        padding-top: 106px;
        display: flex;
        flex-grow: 1;
        height: max-content;
        align-self: center;
        justify-content: center;
        width: 100%;

        .walkthrough-welcome {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 600px;
        }

        .walkthrough-header {
            text-align: center;
            color: $white;
            font-size: 14px;
            max-width: 500px;

            &-title {
                font-weight: 600;
                color: $black;
            }

            &-description {
                line-height: 1.57;
                color: $black;
            }
        }

        .steps {
            color: $color-grey-6;
            margin: 40px 0 0;

            .step {
                background-color: $white;
                padding: 40px 50px;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
            }

            .step:first-child {
                border-radius: 15px 15px 0 0;
            }

            .step:last-child {
                border-radius: 0 0 15px 15px;
            }

            .header {
                font-size: 24px;
                font-weight: bold;
            }

            .body {
                font-size: 14px;
                line-height: 1.57;
                padding-top: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            button {
                font-size: 14px;
                display: block;
                padding: 12px 15px;
                margin-top: 40px;
                background-color: $color-blue-main;
                border: none;
                color: white;
                cursor: pointer;
                width: max-content;
                align-self: center;
            }
        }
    }
}
