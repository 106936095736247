.input{
    padding: 1.7rem 2rem;
    border: 1px solid $investmentBorder;
    background-color: $darkerWhite;
    @include transition (.3s all ease-in-out);
    outline: none;
    box-sizing : border-box;
    width: 100%;
    border-radius: 5px;
    font-family: $regular;
    position: relative;
    color: $black;
    font-size: 1.2rem;
    font-weight: 500;
    @include breakpoint (toDesktop){
        border-radius: 0;
    }
    &::placeholder{
        font-style: italic;
        color: $smallColor;
    }
    &:focus{
        background-color: $white;
        border-color: $black;
        color: $black;
    }
    &:hover:enabled{
        border-color: $black;
    }
    &.errorInput{
        border-color: $red;
        background-color: $white;
        &:before {
            content: "\f00d";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            font-size: 1.8rem;
            position: absolute;
            top: 3.4rem;
            right: 1.7rem;
            color: $red;
            z-index: 1;
            background-color: $errorBackground;
            border-radius: 100%;
            padding: 0.5rem 0.7rem;
        }
    }
    &.successInput{
        border-color: $positive;
        background-color: $white;
        &:before {
            content: "\f00c";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            font-size: 1.8rem;
            position: absolute;
            top: 3.4rem;
            right: 1.7rem;
            color: $positive;
            z-index: 1;
            background-color: $acceptBackground;
            border-radius: 100%;
            padding: 0.5rem 0.7rem;
        }
    }
    &.currency-input {
        padding-left: 5rem;
    }
}
textarea.input{
    height: 10rem;
    resize: none;
    @include breakpoint (toDesktop){
        height: 8rem;
        resize: none;
        overflow: hidden;
    }
}
.common__input-box{
    position: relative;
    .errorText{
        display: none;
        position: absolute;
        bottom: -1.5rem;
        right: 0;
        color: $red;
    }
    &.showError{
        background-color: $white;
        position: relative;
        &:before {
            content: "\f00d";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            font-size: 1.8rem;
            position: absolute;
            top: 3.4rem;
            right: 1.7rem;
            color: $red;
            z-index: 1;
            background-color: $errorBackground;
            border-radius: 100%;
            padding: 0.5rem 0.7rem;
            @include breakpoint (toDesktop){
                font-size: 2.8rem;
                top: 6.8rem;
                z-index: 4;
            }
        }
        .errorText{
            display: block;
            z-index: 1;

        }
        .positioned {
            position: relative;
        }
        .input{
            border-color: $red;
        }
    }
    &.showSuccess{
        border-color: $positive;
        background-color: $white;
        &:before {
            content: "\f00c";
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            font-size: 1.8rem;
            position: absolute;
            top: 3.4rem;
            right: 1.7rem;
            color: $positive;
            z-index: 1;
            background-color: $acceptBackground;
            border-radius: 100%;
            padding: 0.5rem 0.7rem;
        }
        .input{
            border-color: $positive;
        }
    }
}
.component-text-edit {
    display: block;
    text-align: right;
    font-size: 1.2rem;
    letter-spacing: 1px;
    color: #aab2bd;
}
.paddingLeft{
    width: 8rem !important;
    padding-left: 5rem !important;
}
.paddingLeftSpecial {
    width: 7.5rem !important;
    padding-left: 5rem !important;
}

.specialInput {
    &__one{
        .biggerSelect{
            input{
                padding-left: 3rem;
            }
        }
        display: inline-flex !important;
        flex-basis: 22%;
        align-items: center;
        width: 22%;
        flex-wrap: wrap;
        .normalSelect .black-input {
            width: 6.2rem !important;
            border-left: 0;
            margin-left: 0 !important;
        }
    }
    &__second{
        .biggerSelect{
            height: 6.3rem;
        }
        .black-input{
            height: 5.8rem;
            padding: 0.1rem 1rem;
            padding-bottom: 0;
        }
    }
}
.noMargin{
    width: 100% !important;
    display: block;
}
.zoomIn{
    .error {
        position: absolute;
        right: 0;
        bottom: -2rem;
        color: $red;
    }
}
.delete-document{
    z-index: 1 !important;
}
