.approval-nda {
  background: $white;
  border: 1px solid $color-grey-3;
  border-radius: 5px;
  height: calc(100vh - 20rem);
  overflow-y: auto;
  .selected-option {
    font-size: 1.4rem;
    color: $color-grey-4;
    margin-top: 0.5rem;
  }
  .form-nda {
    border-bottom: 1px solid $color-grey-3;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
    padding-left: 4.7rem;
    &:last-of-type {
      border-bottom: none;
    }
    &__title {
      font-size: 1.8rem;
      color: $color-blue-main;
      margin-top: 0;
      margin-bottom: 0;
    }
    &__radio-container {
      border: 1px solid $investmentBorder;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: none;

      .common_radio {
        cursor: pointer;
        &:last-of-type {
          border-bottom: 0;
        }
        [type="radio"]:checked + label,
        [type="radio"]:not(:checked) + label {
          @include radio-label;
          text-transform: none !important;
        }
        label, p {
          font-size: 1.4rem;
        }

        label {
          text-transform: none;
        }
      }
    }
    button.btn {
      margin-top: 1.5rem;
    }
  }
}