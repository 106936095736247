.investorModal{
	position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    .peoples__links li a{
		padding-bottom: 2rem;
		@include breakpoint (toDesktop){
			display: block;
			padding-left: 0;
			font-size: 2.2rem;
		}
    }
    p{
    	margin: 0;
    }
    &Popup{
    	background-color: $white;
    	position: fixed;
    	display: block;
    	left: 40rem;
		right: 40rem;
		padding: 0.5rem 3.6rem;
		padding-bottom: 2.5rem;
		z-index: 1;
		@include breakpoint (toDesktop){
			left: 0;
			right: 0;
			padding: 0.5rem 1.6rem;
		}
		.peoples__body-name{
			justify-content: unset;
			margin-top: 1.8rem;
			align-items: flex-start;
		}
		.positionLeft{
			flex-grow: 1;
		}
		h1{
			font-size: 1.8rem;
			font-weight: 500;
			position: relative;
			display: inline-block;
			margin-bottom: 0;
			margin-top: 0;
			@include breakpoint (toDesktop){
				font-size: 3.2rem;
			}
		}
		&__location{
			@extend .smallColor;
			width: auto;
			font-weight: 400;
			display: block;
			@include breakpoint (toDesktop){
				font-size: 2.4rem !important;
			}
		}
		&__position{
			    border: 1px solid $borderColor;
			    top: 2px;
				padding: 0.3rem;
				font-size: 1.01rem;
				width: auto;
				text-align: center;
				color: $smallColor;
				border-radius: 4px;
				margin-left: 1rem;
				vertical-align: middle;
				display: inline-block;
				@include breakpoint (toDesktop){
					font-size: 2.1rem;
				}
		}
    }
    .edit--modal{
			right: 0;
		    top: 0;
		    font-size: 1.3rem;
    }
    .people__links--modal{
		position: relative;
		box-shadow: none;
    	&:after{
    		    left: 0;
			    right: 0;
			    height: 1px;
			    content: '';
			    display: block;
			    -webkit-box-shadow: 0 1.1rem 0.8rem 0 $lightGray;
   				box-shadow: 0 1.1rem 0.8rem 0 $lightGray;
   				position: absolute;
				bottom: 0.8rem;
    	}
    	ul{
    		padding-left: 0;
    		margin-top: 0.2rem;
    		margin-bottom: 0.6rem;
    	}
    	li{
    		a{
    			font-weight: 600;
    			position: relative;
    			&:after{
					left: 50%;
    				right: 50%;
    				height: 2px;
    				background-color: $brand;
    				position: absolute;
    				bottom: 1rem;
    				content: '';
    				display: block;
    				@include transition (.3s all ease-in);
    			}
    			&.active{
    				color: $brand;
    				&:after{
    					left: 0;
    					right: 0;
    				}
    			}
    		}
    	}
    }
}
.box{
  border: 1px solid #d2d4d8;
  -webkit-box-shadow: -2px 5px 12px -6px $lightGray;
  box-shadow: -2px 5px 12px -6px $lightGray;

    &.re-invite-block {
        border: none;
    }
}
.investor{
	&General{
		margin-top: 1rem;
		&__left,
		&__right{
			display: inline-block;
			vertical-align: top;
		}
		&__left{
			width: 72%;
		}
		&__right{
			    width: 25.5%;
    			padding-left: 1.8rem;
		}
		&__table{
			margin-top: 1.3rem;
			&-first{
				padding: 0.5rem;
				font-weight: 500;
				@include breakpoint (toDesktop){
					padding: 1.5rem 0.5rem;
				}
			}
			&-block{
				display: inline-block;
				font-size: 1rem;
				vertical-align: middle;
				color: $smallColor;
				@include breakpoint (toDesktop){
					font-size: 2rem;
				}
				&:nth-child(1){
					width: 24%;
					padding-left: 0.9rem;
					@include breakpoint (toDesktop){
						width: 50%;
					}
				}
				&:nth-child(2){
					width: 25%;
					@include breakpoint (toDesktop){
						width: 18.4%;
					}
				}
				&:nth-child(3){
					width: 25%;
					@include breakpoint (toDesktop){
						width: 15.5%;
					}
				}
				&:nth-child(4){
					width: 24%;
					@include breakpoint (toDesktop){
						width: 10%;
					}
				}
				&:nth-child(5){
					width: 25%;
				}
				&:nth-child(6){
					width: 13.4%;
				}
				.deal-icon{
					width: 3rem;
					height: 3rem;
					border: 1px solid $lightGray;
					box-shadow: 0 1px 5px 1px $lightGray;
					display: inline-block;
					vertical-align: middle;
					flex: 1;
					display: -webkit-box;
					display: -ms-flexbox;
					display: inline-flex;
					-webkit-box-pack: center;
					-ms-flex-pack: center;
					justify-content: center;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					@include breakpoint (toDesktop){
						width: 6rem;
						height: 6rem;
					}
					img{
						max-width: 100%;
					}
				}
			}
			&-second{
				padding: 1.5rem 0;
				border-top: 1px solid $lightGray;
				&:nth-child(2n+3){
					background-color: $darkerWhite;
				}
				.investorGeneral__table-block{
				&:nth-child(1){
					width: 23.5%;
					padding-left: 1.1rem;
					@include breakpoint (toDesktop){
						width: 50%;
					}
				}
				&:nth-child(2){
					width: 24.4%;
					@include breakpoint (toDesktop){
						width: 18.4%;
					}
				}
				&:nth-child(3){
					width: 24.5%;
					@include breakpoint (toDesktop){
						width: 7.5%;
					}
				}
				&:nth-child(4){
					width: 24%;
					@include breakpoint (toDesktop){
						width: 19%;
					}
				}
				&:nth-child(5){
					width: 25%;
				}
				&:nth-child(6){
					width: 12.2%;
				}
				}
				.deal{
					&__title{
						font-size: 1.2rem;
						color: $black;
						font-weight: 600;
						margin-bottom: 0.5rem;
						@include breakpoint (toDesktop){
							font-size: 2.2rem;
						}
					}
					&__type{
						color: $smallColor;
						font-size: 1rem;
						font-weight: 400;
						@include breakpoint (toDesktop){
							font-size: 2rem;
						}
					}
					&-info{
					display: inline-block;
					vertical-align: middle;
					margin-left: 2rem;
					font-size: 1.2rem;
						span{
							display: block;
						}
					}
				}
				.green,
				.lightOrange,
				.red,
				.date-viewed,
				.views,
				.express-interest,
				.fp-download,
				.action{
					font-size: 1.3rem;
					font-weight: 500;
					color: $black;
					@include breakpoint (toDesktop){
						font-size: 2.3rem;
					}
				}
				.green{
					color: $green;
				}
				.lightOrange{
					color: $lightOrange;
				}
			}
		}
	}
	&Suggestion{
		padding: 0 1rem;
		margin-top: 1.5rem;
		&-first{
			padding: 1.5rem 0.5rem;
			font-weight: 500;
			font-size: 1rem;
			color: $smallColor;
		}
		&-block{
			padding: 2.4rem 0rem;
			font-size: 1.2rem;
			font-weight: 500;
			position: relative;
			border-top: 1px solid $lightGray;
		}
		.green{
			color: $green;
		}
		.lightOrange{
			color: $lightOrange;
		}
		.edit{
			top: 2rem;
		}
		input{
		    width: 97%;
		    padding: 1.5rem 0;
		    background-color: $darkerWhite;
		    border: 1px solid transparent;
		    border-radius: 7px;
		    border: 1px solid #e6e9ed;
		    outline: 0;
		    padding-left: 1.5rem;
		    color: $color-grey-3;
		    font-size: 1.4rem;
		    &::placeholder{
		    	color: $color-grey-3;
		    }
		}
		.listTag{
			padding-left: 1rem;
			li{
				    display: inline-block;
				    font-size: 1.2rem;
				    border: 1px solid $tagBorder;
				    padding: 0.6rem 1.5rem;
				    margin-right: 1rem;
				    border-radius: 20px;
				    margin-bottom: 1rem;
			}
			label{
				    display: inline-block;
    				vertical-align: middle;
    				margin-right: 0.5rem;
    				color: $tagBackground;
			}
			button{
				    border: 0;
				    padding: 0;
				    border-radius: 50%;
				    padding: 0.1rem 0.25rem;
				    display: inline-block;
				    vertical-align: middle;
				    background-color: $tagBackground;
			}
			i{
				    font-size: 1.5rem;
				    color: $white;
				    padding-top: 0.12rem;
				    cursor: pointer;
			}
		}
	}
	&Preferences{
		padding-bottom: 2rem;
	}
	&Small{
		margin-top: 1.3rem;
		padding: 1.4rem 1.8rem;
		&__title{
			margin-bottom: 1.1rem;
			font-weight: 500;
			font-size: 1rem;
			color: $smallColor;
			display: block;
			@include breakpoint (toDesktop){
				font-size: 2rem;
			}
		}
		&__time{
			font-size: 1.5rem;
			font-weight: 100;
			color: $blue;
			display: block;
		}
	}
	&Deals{
		&__block{
			    width: 68%;
    			display: inline-block;
    			vertical-align: top;
    		&-right{
    				position: relative;
    			    display: inline-block;
				    width: 30%;
				    vertical-align: top;
				    text-align: right;
				    .edit{
				    	position: relative;
				    	top: 0;
				    	right: 0;
				    }
				    .edit__profile{
				    	margin-left: 0;
				    	margin-bottom: 1rem;
				    	display: block;
				    	font-size: 1.2rem;
				    }
    		}
			&-big,
			&-small{
				display: inline-block;
				vertical-align: top;
				border: 2px solid $dealBlockColor;
				img{
					max-width: 100%;
				}
			}
			&-big{
				width: 7.75rem;
				height: 7.75rem;
				float: left;
			}
			&-small{
				width: 3.5rem;
				height: 3.5rem;
				margin-bottom: 0.4rem;
				float: right;
			}
		}
	}
	&Notes{
		p{
			font-size: 1.2rem;
			color: $black;
			text-align: justify;
			font-weight: 500;
			line-height: 1.9rem;
			max-height: 70vh;
			overflow-y: auto;
			overflow-x: hidden;
			@include breakpoint (toDesktop){
				font-size: 2.2rem;
				line-height: 2.9rem;
			}
		}
		&__time{
			margin-top: 1.5rem;
		}
		.note{
			position: relative;
			&:after{
				    left: 0;
				    right: 0;
				    height: 1px;
				    content: '';
				    display: block;
				    -webkit-box-shadow: 0 11px 8px 0 #dcdee2;
				    box-shadow: 0 11px 8px 0 #dcdee2;
				    position: absolute;
				    bottom: 0.7rem;
			}
		}
		textarea{
			width: 55%;
		    padding: 1.5rem 0;
		    background-color: $darkerWhite;
		    border: 1px solid transparent;
		    border-radius: 7px;
		    border: 1px solid #e6e9ed;
		    outline: 0;
		    padding-left: 1.5rem;
		    color: $black;
		    font-size: 1.4rem;
		    height: 8rem;
		    resize: none;
			padding-right: 7rem;
		    &::placeholder{
		    	color: $color-grey-3;
		    }
		}
	}
}
.modal-input{
	position: relative;
}
.keyboard-block{
	position: absolute;
    right: 3.4rem;
    top: 0.5rem;
    background-color: $white;
	padding: 0.3rem 0.6rem;
    padding-top: 0.4rem;
    border: 0 solid $dealBlockColor;
	border-radius: 5px;
    cursor: pointer;
    i{
    	font-size: 2.3rem;
    }
    &:hover{
    	background-color: $black;
    	i{
    		color: $white;
    	}
    }
    &--bigger{
    	padding: 0.3rem 0.8rem;
    	border-bottom: 0;
	    border-bottom-right-radius: 0;
	    top: 1.5rem;
	    &:hover{
	    	&:after{
	    		background-color: $black;
	    		border: 0;
	    		right: 0;
	    	}
	    }
    	&:after{
			position: absolute;
		    /* top: 0; */
		    top: 2.9rem;
		    height: 5rem;
		    left: 35%;
		    right: 0;
		    background-color: $color-white;
		    border: 0 solid $dealBlockColor;
		    border-top: 0;
		    content: '';
		    display: block;
    	}
    }
}
#express-interest{
	.warning-txt{
		cursor: pointer;
	}
}
.warning-txt{
	font-size: 1.4rem;
}
.investorVerification{
	.special-block{
		margin-top: 1rem;
		padding-right: 5rem;
	}
	.edit{
		position: absolute;
		bottom: 0;
		right: 3.6rem;
		top: inherit;
		padding: 2rem 0;
	}
	.modal{
		position: relative;
		display: block !important;
		border: 0;
		background: transparent;
		margin-top: 3rem;
	}
	.modal-content3{
		width: 100%;
		background-color: $white;
		height: 75vh;
		box-shadow: inherit;
	}
	.modal-content3 .modal-header h1{
		font-size: 1.6rem;
		color: $black;
	}
	.modal-body2 section h3{
		color: $brand;
		@include breakpoint (toDesktop){
			font-size: 2.3rem;
		}
	}
	.modal-body .modal-body2 p{
		font-weight: 600;
	}
	.modelclose-steps{
		display: none;
	}
	.modal-header{
		padding: 0;
	}
	.modal-body2{
		h3{
			font-size: 1.3rem;
			@include breakpoint (toDesktop){
				font-size: 2.3rem;
			}
		}
		p{
			font-weight: 600;
			font-size: 1.5rem;
			color: $black;
			@include breakpoint (toDesktop){
				font-size: 2.5rem;
			}
		}
		padding: 0;
		section{
			border: 0;
		}
	}
	.modal-body2 section h3{
		color: $brand;
		margin-bottom: 1.5rem;
		@include breakpoint (toDesktop){
			font-size: 2.3rem;
		}
	}
	.modal-body2 > section hr{
		margin-bottom: 1vh;
	}
	.modal-footer{
		display: none;
	}
	.edit__profile{
		margin-left: 3rem;
	}
	.modal-body2 section > div label{
		margin-bottom: 3rem;
		@include breakpoint (toDesktop){
			font-size: 2.2rem !important;
		}
	}
	.half-size{
		margin-top: 2rem;
	}
}
.investorNda{
	header{
		display: none;
	}
	section{
		padding: 0;
    	border: 0;
    	margin-bottom: 0;
	}
	.input-gr{
		display: block;
	}
	.input-gr > div{
		display: inline-block;
		width: 31.9%;
		vertical-align: top;
	}
	.left-size{
		margin-left: inherit;
		margin: 0 1.7rem;
	}
	.profile-container > section > div{
		margin-bottom: 0;
	}
}
.investorLog{
	margin-top: 2rem;
	.log{
		margin-bottom: 1rem;
		&__time{
			display: inline-block;
    		width: 15%;
    		color: $smallColor;
    		font-size: 1.2rem;
    		font-weight: 300;
		}
		&__text{
			display: inline-block;
			color: $tagBackground;
			font-size: 1.2rem;
		}
	}
}
.investorNotesTab{
	.box{
		box-shadow: inherit;
	}
	border: 0;
	box-shadow: inherit;
	.investorSmall{
		padding: 1.4rem 0;
		border: 0;
	}
	.investorSmall__title{
		display: none;
	}
	.investorNotes__time{
		display: block;
		margin-bottom: 0;
	}
	textarea{
		width: 89.5%;
		padding-right: 7.5rem;
		@include breakpoint (toDesktop){
			font-size: 2.4rem;
			width: 86.5%;
		}
	}
	.note{
		border: 2px solid $tagBorder;
		padding: 1.5rem;
		margin-bottom: 2rem;
	}
}
.animated-faster {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.list-enter-active, .list-leave-active {
  transition: all 0.5s;
}
.list-enter{
  animation-name: fadeIn;
}
.list-leave-to {
  animation-name: fadeOut;
}

.tag-enter-active, .tag-leave-active {
  transition: all 0.5s;
}
.tag-leave-to{
  animation-name: bounceOut;
}
.note-enter-active, .note-leave-active {
  transition: all 0.5s;
}
.note-leave-to{
  animation-name: bounceOut;
}
.investorMask{
	position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
}
#signedNdasInvestor{
	margin-top: 3rem;
}
