.new-platform-agreements {
    &__modal {
        z-index: 1000;
        position: relative;

        .layer-body {
            padding-top: 7rem;
        }

        .platform-agreement__right {
            width: 100%;
            flex-basis: 100%;
            min-height: unset;
        }

        .do-experience__parent {
            height: 70%;
            flex-direction: column;
            overflow: hidden;
            border-radius: 3px;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        }

        .legal-page h2 {
            letter-spacing: normal;
            font-weight: bold;
            margin: 0;
            font-size: 2rem;
        }
    }
}