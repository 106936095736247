.description-file-container {
    .file-container {
        &:first-child {
            margin-top: 4rem;
        }
        &:not(:last-child) {
            margin-bottom: 4rem;
        }
        &__wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        &__video,
        &__image,
        .video-js {
            max-width: 100%;
            width: 100%;
            max-height: 40rem;
            object-fit: contain;
        }
        .video-js {
            .vjs-big-play-button {
                position: absolute;
                top: calc(50% - 45px);
                left: 0;
                right: 0;
                margin: auto;
            }
        }
        &__caption {
            font-size: 12px;
            font-family: $regular;
            margin-top: 1rem;
            margin-bottom: 2rem;
            color: $color-grey-6;
        }
    }
}
