.do-security{
  &-block{
    margin-bottom: 2rem;
    background-color: $white;
    position: relative;
    &:before{
      background-color: $borderColor;
      height: 2px;
      content: '';
      display: block;
      position: absolute;
      top: 6rem;
      left: 0;
      right: 0;
    }
    h3{
      text-align: left;
      text-transform: none;
      font-size: 1.6rem;
      margin-bottom: 3rem;
      padding: 2rem;
    }
    &-body{
      h4{
        font-size: 1.2rem;
        font-weight: 600;
        &.investor {
          font-size: 14px;
        }
      }
      &-box{
        padding-left: 2rem;
        padding-right: 2rem;
        border-bottom: 2px solid $borderColor;
        padding-bottom: 2rem;
        &-header{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .matching-text {
          font-size: 1.33rem;
          &.investor {
            font-size: 12px;
          }
        }
      }
      .features-description {
        padding-bottom: 40px;
        font-size: 1.33rem;
        &.investor {
          font-size: 12px;
        }
      }
    }
    &-footer{
      padding-left: 2rem;
      padding-bottom: 2rem;
      &--second{
        padding-top: 2rem;
      }
      a{
        padding: 1.2rem 4rem;
        color: $white;
        font-size: 1.1rem;
        text-transform: uppercase;
        font-weight: 500;
        border-radius: 3px;
        cursor: pointer;
        display: inline-block;
        margin-right: 2.5rem;
        @include transition(.3s all ease-in-out);
      }
      .do-security-brand{
        background-color: $brand;
        &:not(.disabled):hover{
          background-color: $darkerBrand;
        }
      }
      .do-security-red{
        background-color: $red;

          &--disabled {
              cursor: default;
              opacity: 0.5;
          }
      }
    }
  }
  .do-dataroom{
    .switch{
      width: 25px;
      background-color: #84B5F1;
    }
    .slider-button:before{
      height: 14px;
      width: 14px;
      left: 0;
      bottom: -4.75px;
    }
  }
}
.investor-profile-tabs {
  @include breakpoint (tabletScreen){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    a {
      margin-right: 0;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 767px) {
    width: 97%;
  }
  @media screen and (min-width: 768px) and (max-width: 899px) {
    width: 82%;
  }
}
