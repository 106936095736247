.investment-firms {
  .layer-body {
    position: relative;
    &__top-left {
      flex-basis: 60%;
      width: 60%;
      display: flex;
      align-items: center;
      min-height: 4.9rem;
      @include breakpoint(mobileScreen) {
        flex-basis: unset;
        width: unset;
      }
    }
    &__top-right {
      @include breakpoint(mobileScreen) {
        flex-basis: unset;
        width: unset;
      }
      .new-investment-firm-mobile {
        display: none;
        height: 30px;
        width: 30px;
        margin: 0;
        font-size: 2.5rem;
        font-weight: bold;
        background-color: $color-white;
        color: $color-blue-main;
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        text-align: center;
        @include breakpoint(mobileScreen) {
          display: block;
        }
      }
      .activeLink {
        @include breakpoint(mobileScreen) {
          display: none;
        }
      }
    }
  }
   &__container {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
  
     &__filter {
       background-color: transparent;
       width: 250px;
       @include multiple-media(tabletScreen, smallScreen, mediumScreen) {
         width: 200px;
       }
       @include breakpoint(mobileScreen) {
         width: 100%;
       }
       .investment-firm-list {
         margin-top: 2rem;
         overflow: auto;
         min-height: calc(100vh - 23.5rem);
         max-height: calc(100vh - 23.5rem);
         background-color: transparent;
         @include breakpoint(mobileScreen) {
          min-height: calc(100vh - 20rem);
          max-height: calc(100vh - 20rem);
         }
         @supports (-webkit-overflow-scrolling: touch)  {
           min-height: calc(100vh - 29rem);
           max-height: calc(100vh - 29rem);
         }
         &__item {
           margin-bottom: 10px;
           @include breakpoint(mobileScreen) {
             background-color: $color-white;
           }
           .setActive {
             background-color: $color-white;
             border: 1px solid $color-grey-2;
           }
          .firm {
             display: flex;
             flex-direction: row;
             padding: 20px;
             border-radius: 3px;
             &__logo {
               box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
               border: solid 1px $color-grey-1;
               height: 40px;
               width: 40px;
               img {
                 height: 40px;
                 width: 40px;
                 object-fit: scale-down;
               }
             }
             &__info {
               width: 100%;
               margin-left: 10px;
               &--name {
                 font-size: 1.3rem;
                 font-weight: 500;
                 color: $color-grey-6;
                 margin-bottom: 0.3rem;
                 cursor: pointer;
                 word-break: break-word;
               }
               &--type {
                 font-size: 1rem;
                 line-height: 1.3rem;
                 font-weight: 400;
                 color: $color-grey-4;
                 display: block;
                 cursor: pointer;
               }
             }
           }
         }
       }
     }
     &__filtered-data {
       display: flex;
       flex-direction: column;
       background-color: $color-white;
       width: calc(100% - 275px);
       border-radius: 3px;
       box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
       border: 1px solid $smallBlockBorder;
       max-height: calc(100vh - 18rem);
       min-height: calc(100vh - 18rem);
       @include multiple-media(tabletScreen, smallScreen, mediumScreen) {
         width: calc(100% - 220px);
       }
       @include breakpoint(mobileScreen) {
         display: none;
       }
       .investment-firm-info {
         height: 100%;
         &__content {
           display: flex;
           flex-direction: row;
           padding: 20px 20px 0 20px;
           height: calc(100% - 80px);
           overflow-y: scroll;
           @include breakpoint(tabletScreen) {
             display: flex;
             flex-direction: column;
           }
           .invesor-details-back-button {
             display: none;
           }
           &-left {
             width: calc(50% - 20px);
             margin-right: 20px;
             @include breakpoint(tabletScreen) {
               width: 100%;
             }
             .common__info {
               &-box-left {
                  @include breakpoint(mobileScreen) {
                    width: 46%;
                    flex-basis: 46%;
                  }
               }
               &-box-right {
                  padding-left: 10px;
                  @include breakpoint(mobileScreen) {
                    width: 50%;
                    flex-basis: 50%;
                    text-align: left;
                  }
               }
             }
             .box {
               box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
               border: solid 1px $color-grey-2;
             }
             .investment-firm-logo {
               display: flex;
               flex-direction: column;
               .media__image-big  {
                 height: 149px;
                 width: 149px;
                 .do-user__item-change {
                   height: 150px;
                   width: 150px;
                   .file-upload {
                     background-color: $color-brown-1;
                     bottom: -3.6rem;
                   }
                 }
               }
               .errorText {
                 color: $color-red-main;
                 font-size: 1.1rem;
                 display: block;
                 text-align: left;
                 margin-top: 40px;
               }
             }
           }
           &-right {
             width: 50%;
             @include breakpoint(tabletScreen) {
               width: 100%;
             }
             .investor-list {
               width: 100%;
               &__empty {
                 font-size: 1.3rem;
                 font-weight: 500;
                 margin-bottom: 20px;
               }
                &__item {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-self: center;
                  width: calc(100% - 55px);
                  margin-bottom: 20px;
                  padding: 15px 25px;
                  border-radius: 3px;
                  border: 1px solid $smallBlockBorder;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                  background-color: $color-white;
                }
             }
             .investor {
               display: flex;
               flex-direction: row;
               &__image {
                 margin-right: 20px;
                  img {
                    height: 40px;
                    width: 40px;
                    object-fit: scale-down;
                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
                    border: solid 1px $dealBlockColor;
                  }
               }
               &__content {
                 display: flex;
                 flex-direction: column;
                 align-self: center;
                 &__info {
                   color: $color-grey-6;
                   font-weight: 500;
                   font-size: 1.3rem;
                   cursor: pointer;
                   margin-bottom: 0.5px;
                   &:hover {
                     color: $color-blue-main;
                   }
                    &--last-name {
                      padding-top: 10px;
                    }
                  }
                 &__company-info {
                   font-size: 1rem;
                   line-height: 1.3rem;
                   font-weight: 400;
                   color: $smallColor;
                   cursor: pointer;
                 }
               }
             }
             .investor-actions {
               display: flex;
               flex-direction: column;
               align-items: center;
                &__counter {
                  font-size: 3.6rem;
                  font-weight: 100;
                  color: $color-grey-4;
                }
               &__note {
                 text-transform: uppercase;
                 font-size: 1rem;
                 color: $color-grey-5;
                 font-weight: 500;
               }
             }
           }
         }
       }
       .investment-firm-actions {
         background-color: $color-white;
         border-radius: 3px;
         box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.05);
         padding-top: 22px;
         position: relative;
         .btn {
           color: $color-white !important;
           margin-right: 25px;
           transition: none;
           @include breakpoint(tabletScreen) {
             margin-right: 20px;
           }
           @include breakpoint(mobileScreen) {
            margin-right: 15px;
            span {
              display: none;
            }
           }
           &:first-of-type {
             margin-left: 25px;
             @include breakpoint(tabletScreen) {
               margin-left: 20px;
             }
             @include breakpoint(mobileScreen) {
              margin-left: 15px;
             }
           }
         }
       }
     }
     .mobile-friendly-filtered-data {
       display: block;
       position: absolute;
       left: 0;
       top: 0;
       width: 100%;
       max-height: 100%;
       min-height: 100%;
       .investment-firm-info {
         display: flex;
         flex-direction: column;
         &__content {
           display: flex;
           flex-direction: column;
           min-height: calc(100vh - 13rem);
           max-height: calc(100vh - 13rem);
           overflow-y: auto;
           @supports (-webkit-overflow-scrolling: touch) {
             min-height: calc(100vh - 20.5rem);
             max-height: calc(100vh - 20.5rem);
           }
           &-left,
           &-right {
             width: 100%;
           }
         }
       }
       .invesor-details-back-button {
         display: block;
         color: $color-blue-main;
         font-size: 1.2rem;
         font-weight: 500;
         margin-bottom: 20px;
         cursor: pointer;
       }
     }
   }
}