.do-appendix{
  background: none;
  z-index: 1;
  .layer-body__middle-filters{
    min-height: calc(100vh - 220px);
    max-height: calc(100vh - 220px);
  }
  .layer-options {
    flex-basis: 35%;
  }
  &__first{
    &-block{
      width: 100%;
      margin-top: 5rem;
      span{
        font-size: 1.1rem;
        font-weight: 500;
        color: $black;
        display: block;
      }
      h4{
        font-size: 1.6rem;
        font-weight: 600;
        text-transform: none;
        &:after{
          display: none;
        }
      }
      &--modified {
        margin-top: 0;
        .companyNew {
          &__input-mask {
            position: relative;
            margin-right: 8px;
            .question {
              position: relative;
              left: 0;
              top: 0;
            }
          }
        }
        .do-appendix__first-block-holder {
          &-item {
            display: flex;
            align-items: center;
            @-moz-document url-prefix() {
              margin-right: 10px;
            }
          }
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: relative;
          left: -30px;
          @-moz-document url-prefix() {
            justify-content: flex-start;
          }
          @include breakpoint(largeScreen) {
            width: 80%;
          }
        }
      }
      &-holder{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;
        &-item{
          display: flex;
          align-items: center;
          justify-content: space-between;
          align-items: flex-start;
          .login__list-item-holder label{
            @include breakpoint(smallLaptop){
              margin-left: 0.5rem;
            }
          }
        }
      }
    }
    .layer-body__middle-holder{
        padding: 0 20rem;
        min-height: calc(100vh - 220px);
        max-height: calc(100vh - 220px);
        @include breakpoint(largeScreen) {
          padding: 0 35rem;
        }
    }
    .login__list-checkboxLogin{
      display: flex;
      align-items: center;
      width: 15%;
      flex-basis: 15%;
      label {
        @-moz-document url-prefix() {
         width: 25px;
        }
      }
      input[type=radio] {
        position: absolute;
        width: 20px;
        height: 20px;
        left: -4px;
        top: -12px;
      }
      .check {
        position: absolute;
        top: -8.1px;
      }
    }
    .login__list-item-holder{
      width: 85%;
      flex-basis: 85%;
    }
    .preferences-container{
      padding: 0;
    }
    .do-professional__holder{
      padding-left: 60px;
      max-height: inherit;
      overflow-y: auto;
      padding-bottom: 7rem;
      max-width: 100%;
    }
    .appendix-investment-form {
      position: relative;
      height: calc(100vh - 350px);
      @include multiple-media(smallScreen, mediumScreen) {
        height: calc(100vh - 40rem);
      }
    }
  }
  &__second{
    .text-editor-render{
      h3{
        text-align: left !important;
      }
      h4{
        padding-bottom: 0 !important;
      }
    }
    .do-appendix--special{
      padding: 0 20rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
     @include breakpoint(largeScreen) {
        padding: 0 34rem;
      }
    }
    .do-appendix--second--special{
      padding: 0 17rem;
    }
    p{
      font-size: 1.1rem;
      font-weight: 500;
      color: $black;
      line-height: 1.9rem;
      text-align: center;
      padding: 0 16rem;
    }
    .layer-body__middle-holder{
      padding: 0;
    }
    .do-professional__holder{
      padding: 0;
      width: 100%;
      flex-basis: 100%;
    }
    &-block{
      $percentage: 50%;
      width: $percentage;
      flex-basis: $percentage;
      margin-top: 2rem;
      &--modifier{
        width: 100%;
        flex-basis: 100%;
        margin-top: 0;
        padding-bottom: 10px !important;
        p{
          padding: 0;
          text-align: justify;
        }
      }
      .rights-attached {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        left: -30px;
        .companyNew__input-mask {
          position: relative;
          .question {
            position: relative;
            top: 0;
            left: 0;
          }
        }
        span {
          margin-left: 5px;
        }
      }
    }
    .common__input-box input{
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1.7rem 2rem;
    }
    .do-experience__link-left{
      right: 680px;
      @include breakpoint(largeScreen) {
        right: 1110px;
      }
    }
  }
  &__third{
    .layer-body__middle-holder{
      max-height: calc(100vh - 220px);
    }
    .preferences-container{
      padding-right: 1px;
      flex-grow: 0;
      align-items: initial;
      justify-content: initial;
    }
    .do-experience__link-left{
      right: 680px;
      @include breakpoint(largeScreen) {
        right: 1110px;
      }
    }
    .do-appendix__first-block-holder{
      justify-content: flex-start;
    }
    .layer-body__middle-holder{
      padding: 0 12rem;
      @include breakpoint(largeScreen) {
        padding: 0 15rem;
      }
    }
    .do-appendix__first-block-holder-item{
      margin-right: 3rem;
    }
    .login__list-item-holder {
      label {
        @-moz-document url-prefix() {
          margin-left: 8px;
        }
      }
    }
    .login__list-checkboxLogin{
      width: 70%;
      flex-basis: 70%;
    }
    .login__list-item-holder{
      width: 30%;
      flex-basis: 30%;
    }
    .check{
      top: -0.3rem;
    }
    p{
      font-size: 1.1rem;
      font-weight: 500;
      color: $black;
      line-height: 1.9rem;
      text-align: center;
    }
    .do-professional__holder{
      width: calc(100% - 4rem);
      margin-top: 5rem;
      max-height: calc(100vh - 430px);
      overflow-y: auto;
      padding: 0 2.2rem;
      max-width: 100%;
    }
    &-block{
      width: 100%;
      margin-top: 2rem;
      .errorText {
        font-size: 1.2rem;
        color: red;
      }
      label{
        color: $smallColor;
        font-size: 1.1rem;
        margin-bottom: 1rem;
        display: block;
      }
      textarea{
        outline: none;
        width: 100%;
        box-sizing: border-box;
        resize: none;
        border-radius: 5px;
        border: 1px solid $borderColor;
        height: 10rem;
        background: $tableGray;
        color: $black;
        padding: 2rem;
        font-family: $regular;
        font-size: 1.2rem;
        &::placeholder{
          font-style: italic;
          color: $smallColor;
        }
      }
      p{
        margin-bottom: 3rem;
      }
      &-item{
        display: flex;
        align-items: stretch;
        border: 1px solid $borderColor;
        width: 100%;
        position: relative;
        .errorText{
          color: $red;
          position: absolute;
          left: 2.5rem;
          bottom: 0.2rem;
        }
        .close {
          cursor: pointer;
          font-size: 1.5rem;
          color: $black;
          margin-top: -3px;
          position: absolute;
          right: -0.9rem;
          top: -0.9rem;
          background: $layerBackground;
          border-radius: 100%;
          padding: 0.2rem 0.5rem;
          line-height: 2rem;
          z-index: 1;
          i{
            color: $black;
          }
        }
        span.currency {
          position: absolute;
          margin-left: -1.5rem;
          margin-top: 0.1rem;
        }
        > div{
          padding: 1.6rem 2rem;
          font-size: 1.1rem;
          border-right: 1px solid $borderColor;
          align-items: center;
          display: flex;
          position: relative;
          &.first__child{
            background-color: $tableGray;
            border-top-left-radius: 5px;
          }
          &.second__child{
            span{
              display: block;
              text-align: center;
              margin: 0 auto;
            }
          }
          &.third__child{
            color: $black;
            position: relative;
            .my-dropdown-dd{
              position: absolute !important;
              bottom: -1.7rem !important;
              left: 0 !important;
              right: -2.7rem !important;
            }
            .todo-widget__filters-older{
              display: flex;
              width: 100%;
              justify-content: space-between;
            }
            .todo-widget__filters-older ul {
              position: absolute;
              right: 0;
              top: 0;
              padding: 0;
              left: auto;
              list-style: none;
              border: 1px solid #ececec;
              border-radius: 5px;
              background-color: #fff;
              z-index: 1;
              min-width: 12.5rem;
              margin-top: 0;
            }
          }
          &.last__child{
            border-right: 0;
            background-color: $tableGray;
            border-top-right-radius: 5px;
            input{
              color: $black;
              &::placeholder{
                color: $smallColor;
              }
            }
          }
        }
        input{
          box-sizing : border-box;
          width: 100%;
          background-color: transparent;
          font-weight: 600;
          font-size: 1.2rem;
          font-family: $regular;
          border: 0;
          outline: none;
          &::placeholder{
            font-style: italic;
            color: $smallColor;
          }
        }
        &-bigger{
          width: 35%;
          color: $black;
          font-weight: 600;
          input{
            color: $black;
          }
        }
        &-smaller{
          width: 15%;
          color: $smallColor;
          font-weight: 600;
          input{
            color: $black;
            &::placeholder{
              color: $smallColor;
            }
          }
        }
      }
    }
    &-add{
      padding: 0.75rem;
      text-align: center;
      font-size: 3rem;
      font-weight: 600;
      color: $black;
      border-bottom: 1px solid $borderColor;
      border-left: 1px solid $borderColor;
      border-right: 1px solid $borderColor;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      cursor: pointer;
      width: 100.2%;
      box-sizing: border-box;
      @include transition (.3s all ease-in-out);
      &:hover{
        background-color: $black;
        color: $white;
        border-color: $borderColor;
      }
    }
    .do-experience__link-left{
      right: 680px;
      @include breakpoint(largeScreen) {
        right: 1110px;
      }
    }
  }
  &__fourth{
    .do-professional__holder{
      display: block;
      max-width: 100%;
    }
    .text-editor-render{
      h3{
        text-align: left !important;
      }
      h4{
        text-transform: none !important;
        padding-bottom: 0 !important;
        &:after{
          display: none !important;
        }
      }
    }
    .preferences-container{
      margin-top: 0;
    }
    .investorModal{
      position: relative;
      left: auto;
      top: auto;
      z-index: 1;
      width: auto;
    }
    .investorMask{
      display: none;
    }
    .appendix__popup{
      position: relative;
      left: auto;
      right: auto;
      height: auto;
      padding-right: 0;
      padding-left: 0;
    }
    .layer-body__middle-holder{
      padding: 0;
      height: 100%;
    }
    .preferences-container{
      display: block;
    }
    .appendix__header{
      display: none;
    }
    .appendix__body {
      padding: 0 6rem;
      max-height: calc(100vh - 255px);
    }
    .appendix__terms{
      display: none;
    }
    .appendix__body-block{
      label{
        font-weight: 600;
        color: $black;
        font-size: 1.6rem;
      }
    }
    .appendix__body-block-left span{
      min-height: 1.455rem;
    }
    .appendix__body-block-right span{
      min-height: 1.455rem;
    }
    .appendix__confirmation .appendix__body-block-left{
      padding-right: 8rem;
    }
    .appendix-b-left {
      padding-right: 7rem !important;
    }
    .do-experience__link-left{
      z-index: 2;
      right: 680px;
      @include breakpoint(largeScreen) {
        right: 1110px;
      }
    }
    .appendix__footer-confirm--special{
      cursor: pointer;
      position: fixed;
      bottom: 0;
      right: 0;
      display: block;
      border: 0;
      padding: 2rem;
      font-size: 1.4rem;
      text-transform: uppercase;
      color: $brand;
      background-color: $white;
      font-family: $regular;
      letter-spacing: 0.5px;
      z-index: 1;
      outline: none;
      @include transition (.3s all ease-in-out);
      &:hover{
        background-color: $brand;
        color: $white;
      }
      width: 1100px;
      @include breakpoint(largeScreen) {
        width: 1600px;
      }
    }
  }
  &-a{
    .do-experience__child{
      width: 100%;
      flex-basis: 100%;
    }
  }
  &.do-appendix-a-button {
    position: relative;
    .do-appendix__fourth .layer-body__middle-holder {
      padding: 0;
      min-height: auto;
    }
    .do-experience__child {
      margin-left: 0;
      box-shadow: 0 0 5px 0 transparentize($black, .93);
    }
    .appendix__popup{
      height: 85%;
    }
    .do-experience__child {
      height: auto;
    }
    .appendix__footer-confirm--special{
      cursor: pointer;
      position: fixed;
      bottom: 0;
      left: auto;
      right: 0;
      width: 71%;
      height: 60px;
      display: block;
      border: 0;
      padding: 2rem;
      font-size: 1.4rem;
      text-transform: uppercase;
      color: $brand;
      font-family: $regular;
      letter-spacing: 0.5px;
      z-index: 2;
      outline: none;
      margin: 0;
      background-color: $white;
      line-height: 1.75vh;
      box-shadow: 0 -3px 3px 1px transparentize($black, .98);
      border-top: 1px solid $borderColor;
      @include transition (.3s all ease-in-out);
      &:hover{
        background-color: $brand;
        color: $white;
      }
      @supports (-webkit-appearance:none) {
        bottom: 0;
      }
      @include multiple-media(mobileScreen, tabletScreen, smallScreen){
        width: 100%;
      }
      @include breakpoint(mediumScreen){
        width: 1100px;
      }
      @include breakpoint(largeScreen) {
        width: 1600px;
      }
    }
  }
  .layer-body {
    &__top {
      &-left {
        flex-basis: 80%;
        width: 80%;
      }
      &-right {
        flex-basis: 20%;
        width: 20%;
      }
    }
  }
}
.split-with-other-agents {
  .companyNew {
    &__input-mask {
      position: relative;
      bottom: 2.35rem;
      left: 0;
    }
  }
  .do-appendix__first-block-holder {
    margin-left: 25px;
  }
}
