.do-company {
    overflow: hidden;
    .layer-body__top-single__dropdown {
        @include multiple-media(mobileScreen, tabletScreen) {
            display: none;
        }
        .todo-widget__filters-older ul li:last-child {
            margin-top: .5rem;
            margin-bottom: 0;
        }
    }
    .layer-body__top-left-single-company>h1 {
        cursor: default;
    }
    .layer-body {
        overflow: hidden;
    }
    .newForm {
        .layer-body {
            overflow-y: auto;
        }
    }
    .do-profile__item {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: calc(100vh - 25rem);
    }
    .layer-body__top {
        &-left {
            @include multiple-media(mobileScreen) {
                flex-basis: 100%;
            }
        }
        &-right-single {
            @include multiple-media(mobileScreen) {
                display: none;
            }
        }
        .todo-widget__filters-older {
            span,
            h2 {
                color: $smallColor;
            }
        }
    }
    .layer-body__top-left-single-item {
        margin-left: 0;
    }
}

.do-company,
.do-deals {
    .country__icon {
        width: 25px;
        height: 19px;
        border-radius: 5px;
        margin-left: 8px;
    }
    .media__info-title {
        position: relative;
        .badge {
            position: relative;
        }
    }
    .layer-body__top-right {
        a {
            &:hover {
                background-color: transparent;
                color: $darkerBrand !important;
            }
        }
    }
    a.company-name {
        cursor: default;
    }
}

.companyTab .peoples__body-name .deal-icon {
    border: 1px solid #ececec;
}

.companyTab .peoples__smallBlock .gray {
    font-family: Montserrat, sans-serif;
}

.companyTab .edit__profile {
    font-size: 1.3rem;
    font-family: Montserrat, sans-serif;
}

.companyTab .peoples__smallBlock .peoples__info-content {
    font-weight: 400;
}

.companyTab .deal .gray:hover {
    color: #003f91;
}

.companyTab {
    .edit__profile {
        margin-left: 2rem;
    }
    .peoples__smallBlock {
        .peoples__infoBlock {
            width: 33.33%;
        }
        .peoples__info-content {
            color: $black;
            font-weight: 600;
        }
        .gray {
            color: $smallColor;
            font-weight: 400;
            padding-bottom: 0;
        }
    }
    .peoples__body-name {
        position: relative;
        .edit {
            top: 30%;
            font-weight: 400;
        }
    }
    .deal {
        .gray {
            color: $blue;
        }
        .peoples__info-content {
            color: $black;
        }
    }
    &__info {
        display: inline-block;
        color: $smallColor;
        margin-left: 15rem;
        vertical-align: middle;
        flex-grow: 1;
        text-align: center;
        .thin {
            font-weight: 300;
        }
        .bold {
            font-weight: 500;
            margin-right: 2rem;
        }
    }
    .peoples__biggerBlock {
        .peoples__infoBlock {
            width: 19.9%;
            display: inline-block;
            vertical-align: top;
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
                width: 19.9%;
            }
        }
        .peoples__info-content {
            color: $black;
            font-weight: 500;
        }
    }
    .edit__profile {
        border: 0;
        outline: none;
        background-color: transparent;
        font-size: 1.2rem;
        cursor: pointer;
    }
    .company {
        &__name {
            flex-grow: 0;
        }
        &__notcompleted:hover {
            color: #434a54;
        }
    }
    .people__body-name-flex {
        display: flex;
        align-items: center;
    }
}

