.do-originator__smallBlock{
  ul{
    padding-left: 0;
    list-style: none;
    margin: 0;
    li{
      font-size: 1.1rem;
      padding: 1.4rem 2rem;
      font-weight: 500;
      border-radius: 3px;
      cursor: pointer;
      @include transition (.3s all ease-in-out);
      &:hover{
        background-color: $white;
      }
      &.active{
        background-color: $white;
        color: $brand;
        border: 1px solid $lightGray;
        -webkit-box-shadow: -2px 5px 12px -6px $lightGray;
        box-shadow: -2px 5px 12px -6px $lightGray;
      }
    }
  }
}
.legals-main-wrap {
  width: 100%;
}
.do-legals{
  width: auto;
  flex-basis: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @include breakpoint(mobileScreen) {
    flex-direction: column;
  }
  @include breakpoint(tabletScreen) {
    display: block;
    align-items: normal;
  }
  &__left {
    width: 200px;
    flex-basis: 200px;
    @include breakpoint(largeScreen){
      width: 250px;
      flex-basis: 250px;
    }
    ul{
      padding-right: 0;
    }
    @media screen and (max-width: 899px){
      width: 100%;
      flex-basis: 100%;
      margin-top: 3rem;
    }
    @include multiple-media(mobileScreen, tabletScreen) {
      display: none;
    }
  }
  &__right{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: calc(100% - 220px);
    flex-basis: calc(100% - 220px);
    min-height: 500px;
    @media screen and (min-width: 1800px) {
      width: calc(100% - 270px);
      flex-basis: calc(100% - 270px);
    }
    @media screen and (max-width: 899px){
      width: 100%;
      flex-basis: 100%;
      max-height: calc(100vh - 37rem);
      overflow-y: scroll;
    }
    &-block{
      background: $white;
      width: 22.5%;
      margin-right: 2rem;
      margin-bottom: 2rem;
      @media screen and (min-width: 0) and (max-width: 767px) {
        width: 49%;
        margin-right: 0;
        flex-shrink: 1;
        &:nth-of-type(odd) {
          margin-right: 2%;
        }
      }
      @media screen and (min-width: 768px) and (max-width: 1200px) {
        width: 32%;
        margin-right: 0;
        &:nth-of-type(3n+2) {
          margin-left: 2%;
          margin-right: 2%;
        }
        flex-shrink: 1;
      }
      &-title{
        font-size: 1.3rem;
        font-weight: 600;
        padding: 14px 20px;
        position: relative;
        border-bottom: 1px solid $layerBorder;
        display: flex;
        align-items: center;
        min-height: 3.6rem;
        .static {
          text-transform: uppercase;
        }
      }
      &-img{
        width: 9rem;
        height: 17rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media screen and (max-width: 899px) {
          padding: 10px;
        }
        img {
          width: 100%;
          display: block;
          margin: 0 auto;
            max-height: 150px;
        }

        .remove-document {
          top: 1rem;
          right: -3.5rem;
          position: absolute;
          width: auto;
          cursor: pointer;
          @media screen and (min-width: 1800px) {
            right: -8rem;
          }
          @media screen and (max-width: 899px) {
            width: 16px;
          }

        }

        .document-select {
          background-color: $brand;
          color: #fff;
          padding: 1.4rem 5rem;
          text-transform: uppercase;
          cursor: pointer;
          font-family: "Montserrat", sans-serif;
          letter-spacing: 0.4px;
          border: 0;
          font-size: 1.2rem;
          border-radius: 3px;
          outline: none;
          font-weight: 600;
          width: 100%;
          text-align: center;
          position: relative;

          .disabled-input {
            display: none;
          }

          .document-input {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
            width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          &:hover {
            background-color: $darkerBrand;
          }
        }

        .disabled {
          opacity: 0.5;
          cursor: default;
          &:hover {
            background-color: $brand;
          }
        }
      }
      .no-image {
        width: 14rem;
      }
      &-text{
        border-top: 1px solid $layerBorder;
        padding: 1.4rem 2rem;
        min-height: 3.8rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @media screen and (max-width: 899px) {
          min-height: 40px;
          max-height: 60px;
        }
        .dropdown-select {
          position: relative;
          width: 100%;

          .multiselect {
            border: 1px solid #e6e9ed;
            background-color: #f5f7fa;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            outline: none;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            width: 100%;
            border-radius: 5px;
            font-family: "Montserrat", sans-serif;
            position: relative;
            color: #434a54;
            font-size: 1.2rem;
            font-weight: 500;

            .multiselect__select {
              position: absolute;
              width: 2rem;
              height: 2rem;
              right: .5rem;
              top: 1.3rem;
              padding: .4rem .8rem;
              text-align: center;
              -webkit-transition: all .3s ease-in-out;
              transition: all .3s ease-in-out;
              cursor: pointer;
              &:before {
              position: relative;
              right: 0;
              top: 55%;
                content: "";
              -webkit-transform-origin: center;
              transform-origin: center;
                border: .4rem solid transparent;
                border-top-color: $black;
              }

              @include breakpoint(mediumScreen) {
                top: 1rem;
                right: 0;
              }
              @include breakpoint(smallScreen) {
                top: .9rem;
                right: 0;
              }
              @include breakpoint(tabletScreen) {
                top: 1rem;
              }
            }

            .multiselect__tags {
              position: relative;
              color: #434a54;
              font-size: 1.2rem;
              font-weight: 500;

              .multiselect__single {
                padding: 1.5rem 2rem;
                border: 0;
                color: #434a54;
                font-size: 1.2rem;
                font-weight: 500;
                @include breakpoint(mediumScreen) {
                  padding: 1.5rem 1rem;
                }
                @include breakpoint(smallScreen) {
                  padding: 1.5rem 1rem;
                }
                @include breakpoint(tabletScreen) {
                  padding: 1.8rem 1rem;
                }
              }
            }

            .multiselect__content-wrapper {
              .multiselect__content {
                padding-right: 0;

                .multiselect__element {
                  cursor: pointer;
                  -webkit-transition: all .3s ease-in-out;
                  transition: all .3s ease-in-out;
                  border-bottom: 1px solid #ccd0d9;
                  background-color: $white;
                  padding: 0;

                  .multiselect__option {
                    padding: 1rem;
                    display: block;
                    margin-bottom: 0;

                    span {
                      font-weight: 700;
                      color: #434a54;
                    }
                  }

                  .multiselect__option--highlight span{
                    color: $brand !important;
                  }
                }
                @include breakpoint(smallScreen) {
                  top: 5rem;
                }
                @include breakpoint(tabletScreen) {
                  top: 5.6rem;
                }
              }
            }
          }

          .multiselect--active .multiselect__select{
            top: 1.5rem;
            @include breakpoint(mediumScreen) {
              top: 1.2rem;
            }
            @include breakpoint(smallScreen) {
              top: .9rem;
            }
            @include breakpoint(tabletScreen) {
               top: 1.4rem;
            }
          }
        }
        span{
          display: block;
          &:first-child{
            font-size: 1.3rem;
            color: $black;
            font-weight: 500;
            margin-bottom: 0.5rem;
            overflow: hidden;
          }
          &:last-child{
            width: 100%;
            font-size: 1.3rem;
            font-weight: 400;
            color: $smallColor;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }

      .upload {
        @include breakpoint(largeScreen) {
          padding: 0.577rem 2rem;
        }
        @include breakpoint(mediumScreen) {
          padding: 0.577rem 1.3rem;
        }
        @include multiple-media(tabletScreen, smallScreen) {
          padding: 1rem 2rem;
        }
      }
    }
  }

  .investor-account {
    @include breakpoint(tabletLandscape) {
      max-height: calc(100vh - 30rem);
    }
    @media screen and (max-width: 899px){
      max-height: calc(100vh - 28rem);
    }
    @media screen and (min-width: $toDesktop) {
      max-height: calc(100vh - 20rem);
    }
  }
  .my-dropdown-dd {
    ul {
      li {
        height: 15px !important;
      }
    }
  }
}
.legal-documents-upload-wrap {
  width: 100%;
  margin-bottom: 2.2rem;
  #legal_documents_drop_area {
    position: relative;
    .legal-documents-progress-bar {
      width: calc(100% - 2px);
      z-index: 1;
      border-radius: 0;
      -webkit-appearance: none;
      margin-top: 10px;
      margin-bottom: -1px;
    }
    .legal-documents-progress-bar[value]::-webkit-progress-bar {
       background-color: $color-grey-7;
       border-radius: 2px;
       box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }
    .legal-documents-progress-bar::-webkit-progress-value {
      background: $positive;
      height: 1rem;
    }
    .legal-documents-drop-area {
      padding: 3rem;
      border-radius: 3px;
      background-color: $color-grey-1;
      border: 3px dotted $smallBlockBorder;
      position: relative;
      div {
        text-align: center;
        font-weight: 500;
        font-size: 1.4rem;
        a {
          cursor: pointer;
        }
        input {
          display: none;
          font-size: 0;
          text-indent: -9999px;
        }
      }
      .legal-documents-drop-text {
        color: $smallBlockBorder;
        > div{
          display: flex;
          align-items: center;
          justify-content: center;
          > span{
            display: block;
            margin-left: 1.2rem;
          }
        }
      }
      .legal-documents-link-text {
        color: $brand;
        cursor: pointer;
      }
    }
  }
}
