.modal-header .modal-box-header {
    width: 50%;
    border: none;
}
.modal-header .modal-box-header.header-width > div {
    font-size: 1.7rem;
    font-weight: 500;
}
.modal-header .modal-box-header > div {
    padding: 0;
}