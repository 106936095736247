.newForm{
  .underline-placeholder{
    width: 100%;
    height: 1px;
    background-color: $investmentTitle;
  }
  ul{
    padding-left: 0;
  }
  .showError {
    .multiselect, .input {
      border-color: $red;
    }
  }
  .common_radio {
    border-radius: 5px;
    border: solid 1px $investmentBorder;
    .radio-container {
      .radio-group {
        border-radius: 5px;
        cursor: pointer;
        .input {
          cursor: text;
        }
      }
    }
  }
  .multiselect {
    padding: 0;
    border: 1px solid $investmentBorder;
    background-color: $darkerWhite;
    @include transition (.3s all ease-in-out);
    outline: none;
    box-sizing : border-box;
    width: 100%;
    border-radius: 5px;
    font-family: $regular;
    position: relative;
    color: $black;
    font-size: 1.2rem;
    font-weight: 500;
    &__input{
      border: 0;
      color: $black;
      font-family: $regular;
      font-size: 1.2rem;
      font-weight: 600;
      padding: 1.4rem 2rem;
      &::placeholder{
        font-style: italic;
        color: $smallColor;
      }
      &:focus{
        background-color: $white;
        border: 1px solid $black;
        color: $black;
      }
    }
    &__option{
      padding: 1rem;
    }
    &__select{
      top: 1rem;
      &:before{
        top: 50%;
        border-color: $brand transparent transparent;
        border-width: 0.6rem 0.6rem 0.6rem;
      }
    }
    &--active {
      .multiselect__select {
        top: 0.5rem !important;
      }
    }
    &__content {
      top: 4.6rem;
    }
    &__element {
      border-bottom: 1px solid #ccd0d9;
      background-color: $white;
    }
  }
  &-menu {
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $layerBorder;
    padding-bottom: 1rem;
    margin-bottom: 0;
    padding-top: 15px;
    > div {
      margin-left: 15px;
      margin-top: 15px;
    }
    span {
      i {
        color: $grayLight;
        margin-right: 0.5rem;
      }
      @include multiple-media(toPhone) {
        i {
          display: none;
        }
      }
      font-weight: 500;
      font-size: 1.2rem;
      padding: 10px;
      color: $grayLight;
      cursor: pointer;
      &:hover, &.active {
        background-color: $brand;
        border-radius: 5px 5px 0 0;
        padding: 10px;
        i {
          color: $white;
        }
        a {
          color: $white;
        }
      }
    }
  }
  &__input {
    margin-bottom: 2rem;
    &.showError {
      position: relative;
      input {
        border-color: $red;
      }
      .errorText {
        display: block;
        position: absolute;
        bottom: -1.5rem;
        left: 0;
        color: #ed5565;
        font-size: 1rem;
        font-weight: 700;
      }
    }
    .input {
      padding: 1.4rem 2rem;
    }
  }
  label {
    display: block;
    margin: 1rem 0;
    color: $black;
    font-size: 1.4rem;
    font-weight: 500;
  }
  .tab-menu-item{
    font-size: 1.2rem;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    margin: 0;
    cursor: pointer;
    display: inline-block;
    padding: 1.1rem 1.5rem;
    text-transform: uppercase;
    &.active{
      color: $brand;
      position:relative;
      &:after{
        background-color: $brand;
        position: absolute;
        bottom: calc(0% - 1px);
        left:0;
        right:0;
        content: '';
        display: block;
        height: 1px;
      }
    }
  }
  .common__modalBox{
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-item-content{
    padding: 0 1.5rem 1.5rem 4.7rem;

    .accordionText {
      font-size: 1.2rem;
      font-weight: normal;
      padding-bottom: 2rem;
      line-height: 1.83;
      span {
        font-size: 1.2rem;
        font-weight: 500 !important;
        margin-right: 0 !important;
      }
    }
    .lastAccordion {
      margin-bottom: -4rem;
    }
    .radio-group {
      label {
        font-size: 1.4rem;
        bottom: 2px;
        position: relative;
      }
      p {
        margin-bottom: 0;
        font-size: 1.4rem;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: justify;
        color: $black;
        padding-left: 3.8rem;
        margin-top: 0;
        a {
          color: $brand;
        }
      }
    }
  }
  .fa-caret-down:before,
  .fa-caret-right:before {
    font-size: 1.6rem;
    color: $brand;
  }
  .my-dropdown-dd{
    position: relative !important;
    background-color: $white;
    margin-bottom: 15px;
  }
  .section-title {
    background-color: $white;
    padding: 1.5rem;
    cursor: pointer;
    h3{
      color: $brand;
      font-size: 1.8rem;
      font-weight: 500;
      display: inline;
      margin-left: 1.6rem;
    }
    h3::after {
      display: none !important;
    }
  }
  .accordion-item-content {
    h4:after {
      content: none;
    }
    .checkbox-container {
      height: 3.5rem;
      display: flex;
      align-items: center;
      @include breakpoint (toDesktop){
        height: 8.5rem;
      }
    }
  }
  .disabled{
    position: relative;
    .mask{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #fff;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      content: "";
      opacity: .5;
      display: block;
      z-index: 1;
    }
    .hideMask {
      z-index: 0;
    }
  }
  .accordion-item-content {
    &-block {
      margin-bottom: 2rem;
      &:last-child{
        margin-bottom: 3rem;
      }
      span{
        display: inline-block;
      }
      p{
        display: inline;
        font-weight: 400;
      }
      p, span, a{
        font-size: 1.2rem;
        font-weight: 400;
      }
      a{
        color: $brand;
        cursor: pointer;
        position: relative;
        input{
          position: absolute;
          left: 0;
          right: 0;
          opacity: 0;
          cursor: pointer;
        }
        &:hover{
          color: $darkerBrand;
        }
      }
    }
    .uploaded-file{
      margin-top: 1.5rem;
      background-color: $brand;
      border: 1px solid $borderColor;
      padding: 1rem;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span{
        color: $white;
        position: relative;
        a {
          color: $white;
          font-size: 1.6rem;
          margin-right: 0.6rem;
        }
        &:last-child{
          position: relative;
          cursor: pointer;
          i{
            position: absolute;
            left: 0;
            right: 0;
            top: 5px;
            bottom: 0;
            font-size: 12px;
            -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
            text-align: center;
          }
        }
      }
      &-success{
        h4{
          margin-bottom: 2rem;
          font-size: 1.6rem;
          color: $positive;
        }
        span {
          font-size: 1.6rem;
          color: $black;
        }
        &-block{
          color: $black;
          font-size: 1.2rem;
          margin-bottom: 0.2rem;
          span{
            margin-right: 0.5rem;
          }
        }
      }
      &-error{
        h4{
          margin-bottom: 2rem;
          font-size: 1.6rem;
          color: red;
        }
        &-block{
          color: $black;
          font-size: 1.2rem;
          margin-bottom: 0.2rem;
          span{
            font-size: 1.6rem;
            margin-right: 0.5rem;
            color: red;
          }
        }
      }
      &-error_header{
        padding: 2rem;
        border: 1px solid $borderColor;
        h4{
          font-size: 1.6rem;
          color: $black;
        }
        span {
          font-size: 1.6rem;
          color: $black;
        }
        &-block{
          color: $black;
          font-size: 1.2rem;
          margin-bottom: 0.2rem;
          span{
            margin-right: 0.5rem;
            color: $black;
          }
        }
      }
      &-error_footer{
        color: $black;
        font-size: 1.6rem;
        font-weight: normal;
        margin-bottom: 0.2rem;
        span, .errorText{
          margin-right: 0.5rem;
          color: $red;
        }
      }
    }
  }
  button{
    cursor: pointer;
    padding: 16px 24px;
    line-height: 1.3rem;
    font-size: 16px;
    background-color: $brand;
    text-transform: uppercase;
    color: $white;
    left: inherit;
    right: inherit;
    width: auto;
    position: relative;
    border: 0;
    border-radius: 3px;
    font-weight: 500;
    @include transition(.3s all ease-in-out);
    &:hover{
      background-color: $darkerBrand;
    }
    &.white-button {
      margin-right: 0;
      margin-left: auto;
      color: $brand;
      background-color: $white;
      border: 1px solid $borderWhite;
      &:hover {
        color: $brandHover;
      }
      &:active {
        border: 1px solid $borderWhite;
        color: $brandHover;
      }
    }
  }
  &__back-button {
    margin: 0;
    padding: 1rem;
    background-color: $white;
    border-bottom: 1px solid $borderColor;
    button {
      background-color: $white;
      color: $black;
      border: 1px solid $borderColor;
      border-radius: 5px;
      font-weight: 500;
      &:hover {
        background-color: $white;
      }
      i {
        margin-right: 1rem;
      }
    }
  }
  progress{
    border-radius: 0;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 0rem;
    margin-bottom: 1rem;
  }
  progress[value]::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 2px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  }
  progress::-webkit-progress-value {
    background: $positive;
    height: 1rem;
  }
  &__holder {
    display: flex;
  }
}
.selectedFirm {
  padding: 1.5rem 1rem;
  background-color: $brand;
  left: inherit;
  right: inherit;
  width: 100% !important;
  margin-left: 2.5rem;
  margin-bottom: 0 !important;
  border-radius: 2px;
  span:first-child {
    color: #fff !important;
    line-height: 1.3rem;
    font-size: 1.2rem !important;
    font-family: "Montserrat", sans-serif;
    font-weight: 600 !important;
    padding-left: 0 !important;
    padding-right: 13px;
    display: inline-block;
    @include break-words-nicely;
  }
  &:last-child{
    position: relative;
    cursor: pointer;
    i{
      position: absolute;
      right: 10px;
      top: 12px;
      color: $white;
      font-size: 1.6rem;
    }
  }
}
.selectedOption {
  display: none;
  width: 50% !important;
}
.text-editor-render.text-editor-render--special{
  font-size: 12px !important;
  line-height: 25px !important;
  *{
    font-size: 12px !important;
    line-height: 25px !important;
  }
  ul,ol{
    padding-left: 0px !important;
  }
}
.accordion-padding {
  padding: 0 !important;
}
