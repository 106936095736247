.hubx-select {
    display: flex;
    flex-direction: column;
    > label {
        display: block;
        margin-bottom: .8rem;
        color: $color-grey-6;
        font-size: 1.4rem;
        font-weight: 500;
        order: 1;
    }
    .multiselect {
        order: 2;
        padding: 0;
        background-color: $color-grey-1;
        transition: 0.3s all ease-in-out;
        outline: none;
        box-sizing: border-box;
        width: 100%;
        border: 0 !important;
        font-family: $regular;
        position: relative;
        color: $color-grey-6;
        font-size: 1.2rem;
        font-weight: normal;
        cursor: pointer;
        &--above {
            .multiselect {
                &__content {
                    list-style: none;
                    display: inline-block;
                    padding: 0;
                    margin: 0;
                    min-width: 99.7%;
                    max-width: 100%;
                    vertical-align: top;
                    border-radius: .5rem .5rem 0 0 !important;
                    position: relative !important;
                }
                &__content-wrapper {
                    position: absolute;
                    display: block;
                    width: 100%;
                    z-index: 50;
                    bottom: 100%;
                }
            }
        }
        &__select {
            position: absolute;
            width: 2rem;
            height: 2rem;
            right: 0.5rem;
            top: 1rem;
            padding: 0.4rem 0.8rem;
            text-align: center;
            transition: 0.3s all ease-in-out;
            &:before {
                position: relative;
                right: 0;
                border-style: solid;
                content: "";
                transform-origin: center;
                top: 50%;
                border-color: $color-blue-main transparent transparent;
                border-width: 0.6rem 0.6rem 0.6rem;
            }
            &:hover {
                + .multiselect__tags {
                    .multiselect__single {
                        border: .1rem solid $color-grey-3;
                    }
                    .multiselect__input {
                        border: .1rem solid $color-grey-3;
                    }
                }
            }
        }
        &__single {
            font-size: 1.4rem;
            color: $color-grey-6;
            background-color: transparent;
            width: 100%;
            box-sizing: border-box;
            outline: none;
            border: .1rem solid $color-grey-2;
            border-radius: 5px;
            display: block;
            padding: 1.1rem 1.5rem;
            opacity: .5;
            &:hover {
                border: .1rem solid $color-grey-3;
            }
        }
        &--active {
            .multiselect {
                &__select {
                    top: .5rem;
                    transform: rotate(180deg);
                }
                &__tags {
                    .multiselect__input {
                        border: .1rem solid $color-grey-3;
                        border-radius: .5rem .5rem 0 0;
                    }
                }
            }
            .multiselect__single {
                border-radius: 5px 5px 0 0;
                border: .1rem solid $color-grey-3;
            }
        }
        &--disabled {
            background-color: $darkerWhite;
            border-color: $borderWhite;
            opacity: .5;
            pointer-events: none;
            + label {
                opacity: .5;
            }
        }
        &__tags {
            .multiselect__input {
                background-color: transparent;
                width: 100%;
                box-sizing: border-box;
                outline: none;
                border-radius: .5rem;
                color: $color-grey-6;
                font-family: $regular;
                font-size: 1.4rem;
                font-weight: normal;
                padding: 1.1rem 1.5rem;
                border: .1rem solid $color-grey-2;
                margin: 0;
                &::placeholder {
                    color: $color-grey-6;
                    opacity: .5;
                }
                &:hover {
                    border: .1rem solid $color-grey-3;
                }
                &:active {
                    border: .1rem solid $white;
                }
            }
        }
        &__content-wrapper {
            .multiselect__content {
                top: calc(100% - 1px);
                border: .1rem solid $color-grey-3;
                border-radius: 0 0 .5rem .5rem;
                position: absolute;
                right: 0;
                left: 0;
                padding: 0;
                list-style: none;
                margin: 0;
                overflow-x: hidden;
                overflow-y: auto;
                max-height: 300px;
                z-index: 3;
                background-color: $color-white;
                .multiselect__element {
                    border: 0;
                    background-color: $color-white;
                    cursor: pointer;
                    transition: none;
                    &:first-child {
                        .multiselect__option {
                            border-top: 0;
                        }
                    }
                    .multiselect__option {
                        padding: 1rem 1.5rem;
                        display: block;
                        font-size: 1.4rem;
                        color: $color-grey-6;
                        cursor: pointer;
                        border-top: .1rem solid $color-grey-3;
                        font-weight: normal;
                        &--highlight {
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        &.multiselect--disabled.selected-value {
            .multiselect__single {
                background-color: $color-grey-1;
            }
        }
        input.multiselect__input:not(:placeholder-shown) {
            background-color: $color-white;
        }
    }
    &.error {
        input.multiselect__input,
        span.multiselect__single {
            border-color: $color-red-main;
            background-color: $white;
        }
    }
    &.showError {
        input.multiselect__input:not(:placeholder-shown) {
            border: .1rem solid $color-red-main;
            background-color: $color-white;

        }
        input.multiselect__input,
        span.multiselect__single {
            border-color: $color-red-main;
            background-color: $white;
            &:hover {
                border-color: $color-red-main;
            }
        }
        span.hubx-select-error {
            display: block;
            position: relative;
            order: 3;
            color: $color-red-main;
            font-size: 1.2rem;
            margin-top: .8rem;
        }
        .multiselect--active {
            input.multiselect__input,
            span.multiselect__single {
                border-color: $color-red-main;
                background-color: $white;
                &:hover {
                    border-color: $color-red-main;
                }
            }
        }
    }
    .selected-value {
        .multiselect__tags {
            .multiselect__single {
                background-color: $color-white;
                opacity: 1;
            }
        }
    }
}
