.express-interest-widget {
  &.express-interest-widget-professional {
    margin-bottom: 2rem;
    .todo-widget__block {
      margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      box-shadow: none;
      .action__buttons {
        .button__approve {
          font-size: 1.2rem;
          color: $brand;
          &:hover {
            color: $darkerBrand;
          }
        }
      }
      .link-disabled {
        cursor: default;
        color: $color-grey-6;
      }
    }
    .express-interest-widget-note {
      padding: 2rem;
      background-color: $white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      color: $color-grey-6;
      font-size: 1.1rem;
      border-top: 1px solid $smallBlockBorder;
      span {
        font-weight: 500;
      }
    }
  }
}
