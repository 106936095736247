.deal-updates-modal {
  font-family: $regular;
  margin: -1.2rem -4rem 0;
  height: 100%;
  background-color: #faf9f5;

  .title {
    font-size: 2rem;
    font-weight: bold;
    margin-left: 1.5em;
  }
  .close-btn-container {
      float: right;
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: flex-end;
      margin-right: 4em;
      font-size: 20px;

      .close-btn {
          cursor: pointer;
      }
  }

  &-title {
      background-color: white;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      padding-bottom: 2rem;
      position: relative;
  }
  &-content {
      display: flex;
      justify-content: space-between;

      .filter-search {
          width: 16%;
          flex-basis: 16%;
          background-color: $white;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);

          &-header {
              border-bottom: 1px solid #ccc;
              justify-content: center;
              align-items: center;

              .filter-search-options {
                  border-radius: 3px;
                  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                  display: flex;
                  width: 100%;
                  height: 5rem;
                  align-items: center;
                  justify-content: center;

                  .check-all {
                      width: 40px;
                      flex-shrink: 0;

                      .checkbox-container {
                          height: 0;
                          margin-bottom: 2rem;

                          input {
                              margin: 0 auto 0 auto;
                          }
                      }
                  }

                  .search-container {
                      flex-basis: 80%;
                      width: 80%;
                      height: 100%;
                      border-left: 1px solid #d1d3d7;
                      display: flex;
                      align-items: center;
                      padding: 0 1rem;

                      .search {
                          &-icon {
                              flex-basis: 15%;
                              width: 15%;

                              i {
                                  font-size: 1.5rem;
                              }
                          }
                          &-box {
                              flex-basis: 85%;
                              width: 85%;

                              input {
                                  width: 100%;
                                  border: 0;
                                  font-family: $regular;
                                  font-size: 1.4rem;

                                  &::placeholder {
                                      font-family: $regular;
                                      font-size: 1.4rem;
                                      color: $dark-gray;
                                  }

                                  &:focus {
                                      outline: none;
                                  }
                              }
                          }
                      }
                  }
              }
          }

          &-results {
              max-height: calc(100vh - 25rem);
              min-height: calc(100vh - 25rem);
              overflow-x: hidden;
              overflow-y: auto;

              .item-container {
                  display: flex;
                  align-items: center;
                  border-bottom: 1px solid #ccc;
                  min-height: 5.9rem;

                  .item {
                      &-checkbox {
                          width: 40px;
                          flex-shrink: 0;

                          .checkbox-container {
                              height: 0;
                              margin-bottom: 2rem;

                              input {
                                  margin: 0 auto;
                              }
                          }
                      }
                      &-content {
                          flex-basis: 80%;
                          width: 80%;

                          &-wrapper {
                              display: flex;
                              flex-direction: row;
                          }

                          .user-image {
                              width: 5rem;
                              height: 4.5rem;
                              margin-left: 2px;
                              align-self: center;

                              img {
                                  border-radius: 4px;
                                  width: 40px;
                                  height: 40px;
                              }
                          }

                          .item-title {
                              font-size: 1.3rem;
                              font-weight: 500;
                              margin: 1rem 0 1rem;

                              span {
                                  cursor: pointer;
                              }
                          }

                          .item-subtitle {
                              font-size: 1rem;
                              font-weight: 500;
                              color: $dark-gray;
                              margin: 0 0 0.5rem;

                              &.last {
                                  margin-bottom: 1rem;
                              }
                          }

                          .item-message {
                              font-size: 1rem;
                              font-weight: 500;
                              margin-bottom: 1rem;
                              position: relative;

                              i {
                                  font-size: 1.5rem;
                              }

                              .email-sent {
                                  border-radius: 50%;
                                  background-color: $white;
                                  color: $colorCheck;
                                  position: absolute;
                                  bottom: 0;
                                  font-size: 1rem;
                                  left: 0.8rem;
                              }

                              span {
                                  margin-left: 0.8rem;
                              }
                          }
                      }
                  }

              }
          }
      }

      .email-editor {
          margin-left: 4%;
          margin-right: 4%;
          width: 76%;
          flex-basis: 76%;
          background-color: $white;
          border-radius: 8px;
          box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
          margin-top: 2rem;


          .email-header {
              max-height: calc(100vh - 28rem);
              min-height: calc(100vh - 28rem);

              .email-options {
                  overflow-y: auto;
                  overflow-x: hidden;
                  max-height: 19.2rem !important;
                  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
                  @include breakpoint(smallLaptop) {
                      max-height: 16.4rem;
                  }
                  @include breakpoint(bigLaptop) {
                      max-height: 16.3rem;
                  }
                  @include breakpoint(bigScreen) {
                      max-height: 16.6rem;
                  }

                  .options-row {
                      width: 100%;
                      display: flex;
                      padding: 0.5rem 1.5rem 0.5rem 3rem;
                      align-items: center;

                      &_fixed_height {
                          height: 5rem;
                      }

                      &:first-child {
                          padding-top: 2rem;
                      }

                      &:last-child {
                          padding-bottom: 2rem;
                      }

                      .option {
                          flex-basis: 9%;
                          padding-right: 1rem;
                          font-size: 1.6rem;
                          color: $light-gray;
                      }

                      .option_new {
                          flex-basis: 58%;
                          width: 58%;
                          font-size: 1.4rem;
                          min-height: 1.5rem;
                          color: $dark-gray;
                      }

                      .value {
                          flex-basis: 92%;
                          width: 92%;
                      }

                      .subject-value {
                          font-size: 1.4rem;
                          flex-basis: 85%;
                          width: 85%;

                          input {
                              width: 100%;
                              border: 0;
                              font-family: $regular;
                              font-size: 1.4rem;
                              font-weight: 600;

                              &::placeholder {
                                  font-family: $regular;
                                  font-size: 1.4rem;
                                  font-weight: 600;
                                  color: $dark-gray;
                              }

                              &:focus {
                                  outline: none;
                              }
                          }
                      }

                      .email-value {
                          color: $dark-gray;
                          font-size: 1.4rem;
                      }

                      .to-box-wrapper {
                          display: flex;
                          flex-basis: 88%;
                          width: 88%;
                          align-items: center;
                          flex-wrap: wrap;

                          .to-box {
                              border-radius: 25px;
                              border: 1px solid $smallBlockBorder;
                              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                              padding: 1.2rem;
                              margin-right: 1.2rem;
                              margin-bottom: 1.2rem;
                              margin-top: 1rem;
                              display: flex;
                              align-items: center;

                              .email-update {
                                  width: 2rem;
                                  position: relative;

                                  .email-sent {
                                      border-radius: 50%;
                                      background-color: $white;
                                      color: $colorCheck;
                                      position: absolute;
                                      bottom: 0;
                                      font-size: 0.8rem;
                                      left: 0.8rem;
                                  }

                                  i {
                                      font-size: 1.3rem;
                                  }
                              }

                              .user {
                                  &-image {
                                      position: absolute;
                                      width: 5rem;
                                      height: 4.5rem;
                                      margin-left: -8px;

                                      img {
                                          border-radius: 50%;
                                          width: 40px;
                                          height: 40px;
                                      }
                                  }

                                  &-name {
                                      left: 38px;
                                      position: relative;
                                      font-weight: 600;
                                      font-size: 1.4rem;
                                      white-space: nowrap;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                  }
                              }
                              .remove {
                                  margin-left: 40px;
                                  flex-basis: 10%;
                                  width: 10%;
                                  text-align: right;
                                  font-size: 18px;
                                  margin-right: 2px;
                                  padding-left: 6px;


                                  i {
                                      cursor: pointer;
                                  }
                              }
                          }

                          .more-box {
                              border-radius: 5px;
                              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                              padding: 1.1rem;
                              color: $white;
                              background-color: $brand;
                              font-weight: 500;
                              font-size: 1.3rem;
                              cursor: pointer;
                              min-width: 7%;
                              text-align: center;

                              &:hover {
                                  background-color: $darkerBrand;
                              }
                          }
                      }
                  }

                  .to-row {
                      align-items: center;
                      padding: 0 1.5rem 0 3rem;
                      min-height: 6.8rem;
                      @media screen and (min-width: 1280px) and (max-width: 1439px) {
                          min-height: 6.9rem;
                      }
                  }
              }

              .editor-container {
                  .quill-editor {
                      height: calc(100vh - 55rem);
                      padding: 0 3rem;

                      .ql {
                          &-toolbar {
                              border: 0;
                              border-bottom: 1px solid #ccc;
                              padding: 2.5rem 0;
                          }
                          &-container {
                              border: 0;

                              .ql-editor {
                                  font-size: 1.5rem;
                                  line-height: 1.67;
                                  font-weight: 500;
                                  font-family: "Montserrat", sans-serif;

                                  #email-link {
                                      text-decoration: underline;
                                      color: $brand;
                                  }

                                  #investor-placeholder {
                                      box-shadow: -1px 0px 1px 1px rgba(0, 0, 0, 0.1);
                                      border-bottom-left-radius: 17.5px;
                                      border-top-left-radius: 17.5px;
                                      padding-bottom: 4px;
                                      padding-top: 4px;
                                      padding-left: 5px;
                                      font-style: normal;
                                      margin-left: 4px;
                                      -moz-user-select: none;
                                      -ms-user-select: none;
                                      user-select: none;

                                      &-close {
                                          font-size: 16px;
                                          box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.1);
                                          border-bottom-right-radius: 17.5px;
                                          border-top-right-radius: 17.5px;
                                          padding-right: 10px;
                                          padding-bottom: 4px;
                                          padding-top: 4px;
                                          cursor: pointer;
                                          margin-bottom: 4px;
                                          margin-right: 2px;
                                          -moz-user-select: none;
                                          -ms-user-select: none;
                                          user-select: none;
                                      }
                                  }
                              }
                          }
                      }

                  }
              }
          }

          .email-footer {
              box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
              display: flex;
              padding: 2rem 2rem 1rem;
              position: relative;
              height: 5rem;

              .footer-checkbox {
                  flex-basis: 100%;
                  width: 100%;

                  .checkbox-container {
                      display: inline-flex;
                      cursor: pointer;

                      input {
                          cursor: pointer;
                      }

                      .checkbox-text {
                          color: initial;
                          padding-left: 1rem;
                          font-family: Nunito;
                          font-size: 1.4rem;
                          font-weight: 500;
                      }
                  }
              }

              .footer-button {
                  button {
                      width: 10rem;
                      height: 4rem;
                      color: $white;
                      padding: 0;
                      position: relative;
                      border-radius: 3px;
                      background-color: $brand;

                      &:hover {
                          background-color: $brand;
                      }

                      &:disabled {
                          opacity: 0.3;
                          cursor: text;

                          &:hover {
                              background-color: $brand;
                          }
                      }
                  }

                  button.proraiseColor {
                      background-color: $color-purple-main;

                      &:hover {
                          background-color: $color-purple-main;
                      }

                      &:disabled {
                          opacity: 0.3;
                          cursor: text;

                          &:hover {
                              background-color: $color-purple-main-lighter;
                          }
                      }
                  }
              }
          }
      }
  }
}

.send-email-tooltip {
  @include breakpoint(toBigPhone) {
    top: 25px !important;
  }

  .tooltip-inner {
    padding: 0.2rem 1rem !important;
    font-size: 1.2rem !important;
    font-weight: 600;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: $black;
    @include breakpoint(hugeScreen) {
      font-size: 1.2rem !important;
    }
    @include breakpoint(toBigPhone) {
      padding: 0 5px !important;
    }
  }
}
