.do-user{
  @extend .common__layer;

  &__picture {
    @include multiple-media(mobileScreen, tabletScreen) {
      display: none;
    }
    .do-user__item-passport-block {
      &:first-child {
        padding: 2rem 2.5rem 5rem;
        @include breakpoint(largeScreen) {
          padding: 2rem 5rem 5rem;
        }
      }
    }
  }

  &__confirm-button {
      text-align: right;
      margin-top: 30px;
  }

  @media only screen and (max-width: 600px) {
    .action-button-text {
      display:none !important;

    }
  }

  @media only screen and (max-width: 900px) {
    .do-user__item-content {
      margin-left: 20px;
    }

    .action-button-divider {
      height: 40px !important;
      width:1px !important;
      border-right: solid 1px #dcdee2;
      border-top: none !important;
    }

    .action-button-item {
      display: flex;
      flex-direction: column;
      width: calc(50% - 20px);
    }

    .user-info-container, .action-buttons {
      display: flex;
      flex-direction: row;
    }

    .action-button-item {
      text-align: center;
    }
  }

  .action-button-divider {
    width: 100%;
    border-top: solid 1px #dcdee2;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .action-button-text {
    display: inline-block;
    margin-left: 5px;
    vertical-align: super;
  }

  .action-button-item {
    white-space: nowrap;
    margin-top: 10px;
    font-size: 14px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: inherit;
    cursor: pointer;
  }

  .color__picker .vc-chrome {
    position: absolute;
    top: 14rem;
    left: 0.2rem;
    z-index: 9;
    width: 32rem;
  }
  .checkbox-container{
    height: 2.5rem;
    font-weight: 400;
    cursor: pointer;
    font-size: 1.2rem;
    @include transition(.3s all ease-in-out);
    &:hover{
      color: $brand;
    }
    input{
      background-color: $layerBackground;
      &:checked{
        background-color: $white;
      }
    }
  }
  &__item{
    @extend .common__layer-item;
    &:first-child{
      padding-bottom: 5.5rem;
      width: 150px;
      @include breakpoint(largeScreen) {
        width: 200px;
      }
    }
    &:last-child {
      width: 100%;
      flex-basis: 100%;
      margin-left: 20px;
      max-height: calc(100vh - 24rem);
      @include breakpoint(mobileScreen) {
        margin-left: 0;
        max-height: calc(100vh - 23rem);
      }
      @include breakpoint(mobileScreenLandscape) {
        max-height: calc(100vh - 27rem);
      }
      @include breakpoint(tabletScreen) {
        margin-left: 0;
        max-height: calc(100vh - 26rem);
      }
    }
    .media__image-big{
      position: relative;
      margin-bottom: 40px;
      &:after{
        display: none;
      }
    }
    &-passport{
      padding: 0 !important;
      background-color: transparent;
      width: 16% !important;
      &-block{
        padding: 2.5rem;
        background: $white;
        border-radius: 3px;
        .media__image-big {
          margin: 0 auto;
        }
        &:last-child{
          padding: 0;
          .media__image-document{
            padding: 2rem;
            text-align: center;
            font-weight: 600;
            border: 0;
          }
        }
        &:first-child {
          padding: 2rem 5rem 5rem;
        }
      }
    }
    &-change{
      background-color: $white;
      bottom: 0;
      left: 0;
      right: 0;
      width: 150px;
      height: 150px;
      vertical-align: middle;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      overflow: hidden;
      img{
        max-width: 100%;
        max-height: 100%;
        width: auto;
      }
      span{
        background-color: $darkBlack;
        position: absolute;
        bottom: -3.4rem;
        left: 0;
        right: 0;
        content: '';
        display: block;
        color: $white;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.2rem;
        padding: 1rem;
        cursor: pointer;
      }
      input{
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -3.4rem;
        width: 100%;
        padding: 0;
        cursor: pointer;
        z-index: 1;
        height: 3.4rem;
      }
    }
    &-info{
      margin-top: 1rem;
      &-small{
        font-size: 1rem;
        color: $smallColor !important;
        display: block;
        font-weight: 500;
        margin: 0.5rem 0 !important;
      }
      &-big{
        font-size: 1.1rem;
        margin: 0.4rem 0;
        color: $black;
        display: block;
        font-weight: 600;
      }
    }
    &-content{
      h2{
        @extend .common__info-title;
      }
      button{
        background-color: $brand;
        color: $white;
        padding: 1.5rem 2rem;
        text-transform: uppercase;
        cursor: pointer;
        font-family: $regular;
        border: 0;
        font-size: 1rem;
        border-radius: 2px;
        outline: none;
      }
      .empty-content {
        color: #666;
        width: 100%;
        font-size: 14px;
        text-align: center;
        padding: 2rem;
      }
    }
    &-image,
    .do-user__item-change {
      height: 150px;
      width: 150px;

    .initials {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        background-color: #fafbfd;
        color: $color-white-3;
        margin-bottom: 30.6px;
        font-size: 6rem;
    }

      .investor-image {
        width: 150px;
        height: 150px;
        object-fit: scale-down;
      }
    }
  }
  &__item-content {
    .edit-field {
      width: 75%;
    }
    .common__info-box {
      display: flex;
      flex-direction: column;
    }
    @include breakpoint(mobileScreen) {
      .common__info-box {
        display: flex;
        flex-direction: column;
        .edit-field {
          width: 100%;
          .editable__box {
            input {
              padding-right: 85px;
            }
          }
        }
        &-left,
        &-right {
          flex-basis: 100%;
          width: 100%;
        }
        &-right {
          margin-top: 5px;
        }
      }
    }
    @include breakpoint(tabletScreen) {
      .common__info-box-left {
        flex-basis: 40%;
        width: 40%;
      }
    }
  }
  .specialBox{
    height: 45rem;
  }
}
.editable{
  cursor: pointer;
  @include transition (.3s all ease-in-out);
  &:hover{
    color: $brand;
  }
  &.showError{
    input{
      border-color: $red;
    }
    &:before{
      display: none;
    }
  }
  .multiselect__option{
    span{
      color: $black;
    }
  }
  &__box{
    position: relative;
    width: 100%;
    input{
      @extend .input;
      background-color: $white;
      width: 100%;
      box-sizing: border-box;
      padding: 1.5rem;
      border-radius: 3px;
      border: 1px solid $borderColor;
      font-size: 1.2rem;
      font-family: $regular;
    }
    i{
      font-size: 2rem;
      position: absolute;
      top: 1.2rem;
      z-index: 1;
    }
    .fa-times{
      color: $red;
      right: 6rem;
    }
    .fa-check{
      color: $positive;
      right: 1rem;
    }
  }
}
.my-account-profile,
.platform {
  &__block {
    &--right {
      margin-left: 20px;
    }
  }
}
.locationError {
  z-index: 1;
}
.locationInput {
  margin-top: 2rem;
}
.profile-email {
  display: flex;
  flex-direction: column;
  .verification-email-note {
    font-size: 1.2rem;
    color: $color-red-main;
    margin-top: 5px;
  }
}
.investor-my-account {
  .layer-body {
    &__top {
      @include breakpoint(mobileScreen) {
        padding-bottom: 0;
        margin-bottom: 1.5rem;
        position: relative;
      }
    }
    &__top-left {
      @include breakpoint(mobileScreen) {
        width: 100%;
        flex-basis: 100%;
        h1 {
          margin: 1.5rem 0 1.5rem 1.5rem;
        }
      }
    }
    &__navigation  {
      @include hide-scrollbar;
      @include breakpoint(mobileScreen) {
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        padding-bottom: 1.1rem;
        margin-left: 0;
        margin-top: 0;
      }
      &__wrapper {
        @include breakpoint(mobileScreen) {
          min-width: 48rem;
          display: flex;
        }
        a {
          position: relative;
          @include breakpoint(tabletScreen) {}
            margin-right: 4.5rem;
          @include breakpoint(mobileScreen) {
            margin-right: 2.1rem;
            &:last-of-type {
              margin-right: 0;
            }
          }
          @supports (-webkit-overflow-scrolling: touch) {
            -webkit-text-size-adjust: 100%;
          }
        }
        .icon-background {
          display: none;
          @media screen and (max-width: 480px) {
            display: inline-block;
            z-index: 1;
            height: 4rem;
            width: 6rem;
            position: absolute;
            vertical-align: middle;
            pointer-events: none;
            &:first-child {
              background-image: linear-gradient(90deg, $color-grey-2 20%, transparent);
              left: 0;
              @media screen and (-webkit-min-device-pixel-ratio:0) {
                background-image: linear-gradient(90deg, $color-grey-2 20%, rgba(255,255,255,0.001))
              }
            }
            &:last-child {
              background-image: linear-gradient(-90deg, $color-grey-2 20%, transparent);
              right: 0;
              @media screen and (-webkit-min-device-pixel-ratio:0) {
                background-image: linear-gradient(-90deg, $color-grey-2 20%, rgba(255,255,255,0.001))
              }
            }
          }
        }
        .fa-angle {
          &-left,
          &-right {
            display: none;
            @media screen and (max-width: 480px) {
              display: inline-block;
              width: 1.5rem;
              height: 1.5rem;
              font-size: 1.5rem;
              color: $color-white;
              background-color: $color-grey-6;
              text-align: center;
              border-radius: 50%;
              box-sizing: content-box;
              z-index: 2;
              position: absolute;
              pointer-events: none;
            }
          }
          &-left {
            @media screen and (max-width: 480px) {
              left: 0;
              &:before {
                top: -0.6px;
                left: 4px;
                position: absolute;
              }
            }
          }
          &-right {
            @media screen and (max-width: 480px) {
              right: .1rem;
              top: .1rem;
              &:before {
                top: -0.6px;
                left: 6px;
                position: absolute;
              }
            }
          }
        }
      }
    }
    &__middle-filters {
      @include multiple-media(devices, tabletLandscape){
        top: 0 !important;
      }
    }
  }
  @include breakpoint(mobileScreen) {
    .feedback__button {
      display: none;
    }
  }
  @include breakpoint(smallScreen) {
    .layer-body {
      &__top-right {
        flex-basis: 40%;
      }
    }
  }
  @include breakpoint(tabletScreen) {
    .layer-body {
      &__top-left {
        flex-basis: 40%;
        width: 40%;
      }
      &__top-right {
        flex-basis: 60%;
        width: 60%;
        .feedback__button {
          margin-left: 3rem;
          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }
  }
  .errorText {
    font-size: 1.2rem;
    @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
      bottom: -0.5rem;
      position: relative;
      float: right;
    }
  }
  .do-experience {
    background: transparent;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    position: relative;
    .layer-mask{
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      z-index: 3;
    }
    .layer-body{
      padding: 0;
      overflow-y: hidden;
    }
    .layer-container{
      position: relative;
      left: auto;
      right: auto;
      bottom: auto;
      top: auto;
    }
    .layer-body__top{
      display: none;
    }
    .do-stage{
      display: none;
    }
    .industry-accordion-container{
      @include breakpoint(mobileScreen){
        max-height: calc(100vh - 350px);
        display: block;
      }
      .accordion-item {
        .option-box{
          @include breakpoint(devices){
            width: 48% !important;
          }
          @include breakpoint(toBigPhone){
            width: 47% !important;
          }
          @include breakpoint (toDesktop){
            width: 29.3rem;
          }
        }
      }
    }
    &__swipe {
      @include breakpoint (toDesktop) {
        top: auto;
        bottom: 0;
        z-index: 1;
      }
      i {
        @include breakpoint(devices){
          top: 20px;
        }
        @include breakpoint(toBigPhone){
          top: 15px;
        }
      }
    }
    &__parent {
      .layer-body__middle-filters {
        @include breakpoint (toDesktop) {
          top: 6.9rem;
          width: auto;
        }
      }
    }
    &__parent-holder{
      .do-experience__child {
        @include breakpoint(smallScreen) {
          margin-left: 0;
        }
      }
    }
    &__child {
      @include breakpoint(smallScreen) {
        margin-left: 0;
      }
    }
  }
  .do-elective .do-experience__parent{
    padding-bottom: 0;
  }
  .layer-new {
    &__left {
      display: none;
    }
    &__right {
      width: 100%;
      height: calc(100vh - 200px);
      box-shadow: none;
    }
  }
  .preferences-item.deal{
    @include breakpoint(toBigPhone){
      margin: 5px !important;
      width: 65px !important;
      height: 65px !important;
    }
  }
  .preferences-container{
    @include breakpoint(toBigPhone){
      margin-top: 0;
    }
  }

}
