.tasks_container{
  display: flex;
  align-items: flex-start;
  padding-top: 2rem;
  padding-bottom: 4rem;
  justify-content: space-between;
  > div{
    width: 32%;
    flex-basis: 32%;
    max-height: calc(100vh - 15rem);
    overflow-x: hidden;
    overflow-y: auto;
  }
  &-first{
    width: 200px;
    flex-basis: 200px;
    > div{
      background: $white;
      padding: 2rem;
    }
    .do-profile__item-info-small,
    .do-profile__item-info-big{
      font-weight: 500 !important;
    }
    .media__image-big {
      width: 150px !important;
      height: 150px !important;
    }
  }
  &-button{
    text-align: center;
    text-transform: uppercase;
    font-size: 1.1rem;
    background: $brand;
    padding: 1.2rem;
    width: 10rem;
    margin: 2rem auto;
    margin-bottom: 0;
    color: $white;
    cursor: pointer;
  }
  &-order{
    margin-top: 2rem;
    padding: 1.5rem 1rem !important;
    h4{
      font-weight: 600;
      font-size: 1.2rem;
      text-transform: uppercase;
      position: relative;
      color: $black;
      margin-bottom: 3rem;
      &:after{
        bottom: -1.5rem;
        left: -1rem;
        right: -1rem;
        position: absolute;
        height: 1px;
        background-color: $layerBorder;
        content: '';
        display: block;
      }
    }
    label{
      margin-bottom: 0;
      font-size: 1rem;
      font-weight: 500;
    }
    input{
      box-sizing: border-box;
      width: 6rem;
      display: block;
      border: 1px solid $layerBorder;
      padding: 0.75rem 0.5rem;
      outline: none;
      background-color: $tableGray;
      border-radius: 5px;
      color: $black;
      font-family: $regular;
      font-size: 1.1rem;
      font-weight: 500;
    }
    &-block{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;
      margin-top: 1rem;
      &-item{
         width: 6rem;
      }
    }
  }
  &-close-button {
    float: right;
    margin-top: -0.6rem;
    cursor: pointer;
    font-size: 2rem !important;
    @include transition(.3s all ease-in-out);
    &:hover{
      color: $brand;
    }
  }
  .disabled {
    cursor: text;
    opacity: 0.5;
  }
  &-add{
    margin-top: 2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: $brand;
    text-align: center;
    cursor: pointer;
    @include transition (.3s all ease-in-out);
    &.disabled {
      &:hover {
        background-color: $white;
        color: $brand;
      }
    }
    &:hover{
      background-color: $brand;
      color: $white;
    }
  }
  &__child{
    padding: 2rem;
    > div{
      width: 100%;
    }
    &:first-child{
      width: 30%;
      flex-basis: 30%;
    }
    &:last-child{
      width: 63%;
      flex-basis: 63%;
      margin-left: 0%;
      background-color: transparent;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      max-height: calc(100vh - 15rem);
      overflow-x: hidden;
      .do-user__item {
        margin-bottom: 2rem;
        width: 100%;
        margin-right: 0;
        flex-basis: 100%;
        width: auto;
        position: relative;
        &:first-child{
          padding: 2rem;
        }
      }
    }
  }
  .do-user__item{
    textarea{
      background: $tableGray;
      height: 5.5rem;
      resize: none;
      border-radius: 5px;
      color: $black;
      font-size: 1.2rem;
      box-sizing: border-box;
      font-family: $regular;
      padding: 1.8rem 6rem 1.8rem 2rem;
      outline: none;
      border-color: $layerBorder;
      overflow-y: auto;
      font-weight: 600;
      &.inputError{
        border-color: $red;
      }
    }
    .errorText{
      display: none;
      position: absolute;
      bottom: 0.5rem;
      right: 2rem;
      color: $red;
      font-weight: bold;
      @include breakpoint (toDesktop){
        font-size: 2rem;
        bottom: -2.5rem;
      }
    }
    .errorEditText{
      position: absolute;
      bottom: 3.2rem;
      right: 2rem;
      color: $red;
      font-weight: bold;
      @include breakpoint (toDesktop){
        font-size: 2rem;
        bottom: -2.5rem;
      }
    }
    &.showError{
      background-color: $white;
      position: relative;
      .errorText{
        display: block;
      }
      .positioned {
        position: relative;
      }
      .input{
        border-color: $red;
      }
    }
  }
  .todo-widget__block-right-image{
    width: 100%;
    height: 100%;
    border: 0;
  }
  .button__approve--cancel{
    position: absolute;
    top: 1rem;
    right: 1.4rem;
  }
  &-holder{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    .three-dots{
      margin-top: -0.2rem;
    }
  }
}
.tasks_description{
  display: inline-block;
  width: 98%;
  font-weight: 500;
  color: $black;
  font-size: 1.2rem;
  line-height: 1.7rem;
  white-space: pre-wrap;
}
.tasks_assign_button{
  float: right;
  color: $brand;
}
.task_author{
  display: inline-block;
  color: $dark-gray;
}
.task_by_investor{
  color: $brand;
}
.three-dots{
  width: 5%;
  display: inline-block;
  text-align: right;
  position: absolute;
  top: 15px;
  right: 10px;
}
.task_edit{
  width: 100%;
  overflow-y: hidden;
  @include transition(.3s all ease-in-out);
  &:focus{
    background-color: $white !important;
    border-color: $black !important;
    color: $black !important;
  }
  &:hover{
    border-color: $black;
  }
}
.button__approve--textarea{
    position: absolute;
    top: 1.9rem;
    right: 2rem;
    background-image: url('/images/enter.png');
    background-repeat: no-repeat;
    background-size: contain;
    text-indent: -9999px;
    width: 6rem;
    height: 6rem;
}