.nav li a:active, .nav li .router-link-exact-active.is-active {
    color: $brand !important;
  }
  .nav li a {
    letter-spacing: 0.1rem;
    color: #434a54;
  }
  .navbar-right i {
    font-size: 2.5rem;
  }
  .nav li a:hover {
    color: $brand;
  }
  .peoples__links {
    -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
  }
  .peoples__container .peoples__smallBlock {
    -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
  }
  .peoples__bigBlock {
    -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.07);
    .peoples__info-title{
      margin-bottom: 0;
    }
  }
  .shadow, .peoples__footer:before {
    box-shadow: none;
  }
  .peoples__container .peoples__smallBlock .peoples__footer {
    -webkit-box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.02);
    box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.02);
  }
  .peoples__footer li a {
    font-weight: 400;
  }
  .peoples__footer li a:hover {
    color: #bb5815;
  }
  .peoples__info:before {
    background-color: #ececec;
  }
  .peoples__body-name small {
    font-weight: 400;
  }
  .peoples__body .deal > div.peoples__info::before {
    background-color: #c5ecfb;
  }
  section.rejected > div.peoples__info::before {
    background-color: #f2b1b8;
  }
  .peoples__body section {
    -webkit-box-shadow: -2px 5px 12px -6px #dcdee2;
    box-shadow: -2px 5px 12px -6px #dcdee2;
  }
  .dealTab .peoples__bigBlock .peoples__info .edit {
    top: 0;
  }
  .dealTab .peoples__bigBlock .peoples__smallBodyBlock small {
    color: #a9b3bf;
    font-size: 1rem;
  }
  .dealTab .peoples__bigBlock .peoples__smallBodyBlock .peoples__body-info {
    font-weight: 400;
    font-size: 1.3rem;
  }
  .dealTab .peoples__bigBlock .peoples__newDeal button {
    font-family: Montserrat, sans-serif;
    font-size: 1.3rem;
  }
  .dealTab .peoples__bigBlock .peoples__newDeal button:hover {
    color: #bb5815;
  }
  .peoples__header-search i {
    font-size: 2.5rem;
  }
  .widget__title, .syndication__widget h2 {
    color: #434a54;
  }
  .edit__profile:hover {
    color: #bb5815;
  }
  .dealTab .rejected .edit__profile:hover {
    color: #bf0013;
  }
  .dealTab .peoples__bigBlock .peoples__info{
    padding: 2.85rem 2rem;
    font-size: 1.2rem;
  }
  .dealTab .peoples__bigBlock .peoples__newDeal {
    padding: 2.85rem 2rem;
    border-bottom: 0;
  }
  .edit__withdraw:hover {
    color: #bf0013;
  }
  .edit__resend:hover {
    color: #003f91;
  }
  
  .logo-info > span:first-child {
    font-weight: 500;
    color: #434a54;
    font-size: 1.4rem;
  }
  
  .logo-info > small {
    font-weight: 400;
    color: #dddee2;
    font-size: 1.1rem;
  }
  .edit__profile {
    font-weight: 400;
    font-size: 1.3rem;
  }
  .widthFix{
      width: auto !important;
  }