.delay-01 {
    -webkit-animation-delay: 100ms; /* Chrome, Safari, Opera */
    animation-delay: 100ms;
}
.delay-02 {
    -webkit-animation-delay: 200ms; /* Chrome, Safari, Opera */
    animation-delay: 200ms;
}
.delay-03 {
    -webkit-animation-delay: 300ms; /* Chrome, Safari, Opera */
    animation-delay: 300ms;
}
.delay-04 {
    -webkit-animation-delay: 400ms; /* Chrome, Safari, Opera */
    animation-delay: 400ms;
}
.delay-05 {
    -webkit-animation-delay: 500ms; /* Chrome, Safari, Opera */
    animation-delay: 500ms;
}
.delay-06  {
    -webkit-animation-delay: 600ms; /* Chrome, Safari, Opera */
    animation-delay: 600ms;
}
.delay-07  {
    -webkit-animation-delay: 700ms; /* Chrome, Safari, Opera */
    animation-delay: 700ms;
}
.delay-08  {
    -webkit-animation-delay: 800ms; /* Chrome, Safari, Opera */
    animation-delay: 800ms;
}
.delay-09  {
    -webkit-animation-delay: 900ms; /* Chrome, Safari, Opera */
    animation-delay: 900ms;
}
.delay-10  {
    -webkit-animation-delay: 1000ms; /* Chrome, Safari, Opera */
    animation-delay: 1000ms;
}