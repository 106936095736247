.founder-activities {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 80px;

    &__main {
        position: relative;
        width: 750px;
        bottom: 35px;
        @include multiple-media(mediumScreen, largeScreen) {
            margin: 0 60px 0 60px;
        }
    }

    &__actions-toolbar {
        font-family: Nunito;
        font-size: 20px;
        font-weight: bold;
        margin: 10px;
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        @include multiple-media(
            tabletScreen,
            smallScreen,
            mediumScreen,
            largeScreen
        ) {
            flex-direction: row;
            margin: 20px 0 30px 0;
            span {
                margin-right: 10px;
            }
        }
        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
            padding-left: 0;
        }

        .more-items {
            color: $color-green-main-2;
        };
    }

    &__no-entries {
        font-family: Nunito;
        font-size: 14px;
        font-weight: bold;
        color: #99a1ab;
    }

    .more-items__hub-block__content__body div i,
    .more-items__hub-block__content__body div span {
        font-size: 1.4rem;
    }

    .more-items__hub-block__content__body div:last-child {
        border-bottom: none;
    }

    .more-items__hub-block {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
        .filter__item, .group-by__item {
            &:hover > span{
                color: $color-cyan-secondary;
            }
        }
    }

    &__actions-toolbar a {
        color: $color-cyan-secondary;
        cursor: pointer;
    }

    &__actions-toolbar .more-items {
        position: relative;
    }

    &__actions-toolbar .more-items i {
        font-size: 15px;
    }

    &__group-by-activities {
        margin-top: 15px;
        @include multiple-media(
            tabletScreen,
            smallScreen,
            mediumScreen,
            largeScreen
        ) {
            margin-top: 0;
        }
    }
}
.activity {
    &__block-container {
        width: 100%;
        border-radius: 5px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background-color: $color-white;
        text-align: center;
        position: relative;
        box-sizing: border-box;
        padding: 20px;
    }

    &__activity-item {
        margin-top: 20px;
    }

    &__body-block {
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 15px;

        .investor-tooltip {
            cursor: pointer;
        }
    }

    &__unseen-status {
        position: absolute;
        left: 10px;
        top: 5px;

        .fa-circle {
            color: $red-badge;
            font-size: 0.5rem;
        }
    }

    &__user-info {
        text-align: left;
        margin-left: 15px;
        a {
            color: $darkBlack;
            white-space: nowrap;
        }
    }

    &__user-name {
        font-family: Nunito;
        font-size: 16px;
        font-weight: bold;
        color: $darkBlack;
    }

    &__last-logged-in {
        font-family: Nunito;
        font-size: 12px;
        font-weight: 600;
        color: $color-grey-21;
        position: relative;
        left: 0;
    }

    &__dropdown-menu {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__status-box {
        width: 110px;
        height: 25px;
        border-radius: 5px;
        margin: 20px 0 15px 0;
    }

    &__interested {
        background-color: $color-green-dark-1;
    }

    &__provided-feedback {
        background-color: $color-yellow-main;
    }

    &__not-interested {
        background-color: $color-red-main;
    }

    &__status-text {
        font-family: Nunito;
        font-size: 11px;
        font-weight: bold;
        color: $color-white;
        line-height: 25px;
    }

    &__footer-block {
        width: 100%;
        display: block;
        text-align: left;
        border-top: 1px solid $borderColor;
    }

    &__activity-amount {
        margin: 15px 0 0 0;
    }

    &__amount-text {
        font-family: Nunito;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: $darkBlack;
        @include breakpoint(tabletScreen) {
            font-size: 16px;
        }
    }

    &__amount {
        color: $color-green-dark-1;
    }

    &__user-comment {
        padding-top: 10px;
        font-family: Nunito;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        color: $darkBlack;
        @include breakpoint(tabletScreen) {
            font-size: 16px;
        }
        p {
            margin: 0;
        }
    }
}

.widget-block {
    display: flex;
    align-items: flex-start;
    min-height: 4rem;
    position: relative;
    width: 200px;
    @include multiple-media(mobileScreen, smallScreen) {
        width: 100%;
    }
    @include multiple-media(mobileScreen, tabletScreen) {
        width: calc(100% - 1rem);
    }

    &__image-wrapper {
        display: flex !important;
        align-items: center;
        justify-content: center;
        border: solid 1px $color-white-3;
        background-color: $color-white-2;
        min-width: 4.45rem;
        width: 4.45rem;
        height: 4.45rem;
        border-radius: 5px;

        .media-image {
            width: 40px;
            height: 40px;
            object-fit: scale-down;
            border: 1px solid $dealBlockColor;
            box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        }

        .initials {
            font-size: 1.8rem;
            font-family: Nunito, sans-serif;
            font-weight: bold;
            text-align: center;
            color: $color-white-3;
        }
    }
}

.founders__main--navigation {
    z-index: 1;
}
