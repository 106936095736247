.full-info-page{
  padding-left: 24rem;
  section.promo {
      position: relative;
      height: 100%;
      padding-top: 0;
  }
  b.thehub-overlay {
    background-color: #000;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,100,200,0.1);
    height: 100%!important;
  }
  .bg-slider-wrapper {
    overflow: hidden;
    height: 100% !important;
  }
  .bg-slider-wrapper {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 780px;
    background: url('/images/error-background.png');
    background-size: cover;
  }
  .align-vertical {
    position: relative;
    transform: translateY(70%);
    @include breakpoint(smallLaptop) {
      transform: translateY(55%);
    }
  }
  h2 {
    font-weight: 300;
    font-size: 6rem;
    color: #ffffff;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    text-transform: none;
    padding-right: 70rem;
    @include breakpoint(smallLaptop) {
      font-size: 4rem;
    }
  }
  h3 {
    font-weight: 300;
    font-size: 4rem;
    color: $white;
    line-height: 1.23;
    letter-spacing: normal;
    text-align: left;
    margin: 2rem 0 1rem;
  }
  p {
    color: $white;
    text-align: center;
  }
  .home-page .promo p.intro {
    line-height: 1.6em;
    font-size: 1.6rem;
    color: #ffffff;
    font-weight: 300;
  }
  .intro a{
    text-decoration: underline;
    cursor: pointer;
    transition: .3s all ease-in-out;
    color: #fff;
  }
  .promo.section .intro {
    margin-top: 3rem;
    margin-bottom: 5rem;
  }
  .section-on-bg .intro {
    color: #fff;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
  }
  .section .intro {
    margin-bottom: 60px;
    text-align: left;
    color: #fff;
    font-size: 1.8rem;
  }
  .hvr-sweep-to-right {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    border: 2px solid $brand;
    padding: 0.5em 2em;
    text-transform: uppercase;
    font-size: 16px;
    background: transparent;
    color: $brand;
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  a.hvr-sweep-to-right:hover {
    text-decoration: none;
  }
  &.unsubscribe-full-page {
    .section {
      margin: 0;
      position: absolute;
      top: 30%;
      -ms-transform: translateY(-30%);
      transform: translateY(-30%);
    }
  }
}
.full-info-page .hvr-sweep-to-right:hover {
    color: white;
}
.full-info-page a:hover {
    text-decoration: underline;
    color: $brand;
}
.full-info-page h1.logo {
    text-align: left;
    margin-top: 3rem;
}
.full-info-page #header h1 a img {
    width: 14rem;
    z-index: 100;
    position: relative;
}
.not-found-page-button{
    border: solid 2px #ffffff;
    color: #fff;
    transition: .3s all ease-in-out;
    padding: 1rem 5rem;
    font-size: 1.4rem;
}
.not-found-page-button:hover{
    color: $brand;
    border-color: $brand;
    text-decoration: none !important;
}
@media (max-width: 1024px) {
    .not-found-page{
      padding-left: 10rem;
      h2{
        padding-right: 0;
        font-size: 5rem;
      }
      .section .intro{
        font-size: 2.8rem;
      }
    }
    .not-found-page-button{
        padding: 2rem 8rem;
        font-size: 2.4rem;
    }
    .full-info-page #header h1 a img{
        width: 18rem;
    }
}
