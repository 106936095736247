.compact-company-profile {
  flex-grow: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-bottom: 70px;

  &__section {
    display: flex;
    flex-grow: 1;

    &__right {
      background-color: $color-white;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      height: inherit;
      justify-content: space-between;
      @include multiple-media(mobileScreen, tabletScreen) {
        width: 100%;
          padding-bottom: 100px;
      }
      .company-content {

        .documents {
          padding: 2rem 3rem;
        }
        .my-dropdown-dd {
          position: relative !important;
        }
      }
      .text-content {
        font-size: 14px;
        line-height: 1.86;
        font-family: $regular;
        color: $color-grey-6;
        word-break: break-word;
        margin-bottom: 1rem;
        p, ol, span, li  {
          margin: 0;
          color: $color-grey-6;
          span, em, u {
            color: $color-grey-6 !important;
          }
        }
        ol, ul {
          margin-block-start: 0;
          margin-block-end: 0;
          font-size: 14px;
          color: $color-grey-6;
        }
        span, u, strong, em, pre {
          background-color: unset !important;
          font-size: 14px;
        }
        h1, h2, h3, h4 {
          letter-spacing: normal;
          color: $color-grey-6;
        }
        h1 {
          font-size: 24px;
          font-weight: 500;
        }
        h2 {
          font-size: 20px;
          font-weight: 500;
        }
        h3 {
          font-size: 18px;
          font-weight: 500;
        }
        h4 {
          font-size: 16px;
          font-weight: 500;
        }
        h5 {
          font-size: 14px;
          font-weight: 500;
        }
        h6 {
          font-size: 12px;
          font-weight: 500;
        }
        p {
          font-size: 14px;
          font-weight: normal;
        }
      }
      .content-title {
        font-family: $regular;
        font-weight: 500;
        font-size: 18px;
        color: $color-grey-6;
        margin: 20px 0;
        letter-spacing: normal;
        text-transform: capitalize;
      }
    }
  }
  .file-image {
    .fa {
      font-size: 2.8rem;
    }
    .pdf-icon {
      color: $color-file-pdf;
    }
    .doc-icon {
      color: $color-file-word;
    }
    .xls-icon {
      color: $color-file-excel;
    }
    .ppt-icon {
      color: $color-file-powerpoint;
    }
    .image-icon {
      color: $color-file-image;
    }
  }
  .accordion {
    height: 6.5rem;
    background-color: $color-white;
    color: $color-blue-main;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
    border-bottom: 1px solid $color-grey-1;
    &:hover {
      color: $color-blue-dark;
      cursor: pointer;
    }
    &__toggle {
      padding-left: 3rem;
      width: 2.4rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      .fa {
        font-weight: 600;
        font-size: 1.5rem;
      }
    }
    &__title {
      padding-left: 2rem;

    }
  }

  @extend .description-file-container;
}
