.jurisdictions {
  .layer-body__top {
    margin-left: 0;
    .layer-body__navigation span {
      font-weight: 600;
      font-size: 1.2rem;
      color: $black;
      -webkit-transition: 0.3s all ease-in-out;
      transition: 0.3s all ease-in-out;
    }
  }
  .layer-body__middle {
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    border: solid 1px $smallBlockBorder;
    padding: 25px;
    p {
      font-size: 1.2rem;
    }
    .jurisdictions-list{
      display: flex;
      ul{
        width: 50%;
        font-size: 1.2rem;
        color: #434a54;
        list-style: none;
        margin: 0;
        padding: 0;
        line-height: 1.83;
      }
    }
    .button-understand {
      width: 100%;
      position: absolute;
    }
  }
}