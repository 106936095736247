.do-investor-preferences {
  display: flex;
  flex-direction: column;
  .layer-body__middle-filters {
    border-radius: 5px;
    position: relative;
    background: $white;
    padding: 0;
    flex-basis: 200px;
    @include transition(.3s all ease-in-out);
    @include breakpoint(largeScreen) {
      flex-basis: 250px;
    }
    @include multiple-media(mobileScreen, tabletScreen) {
      border-radius: 0;
      width: auto;
      flex-basis: auto;
      min-height: auto;
      position: fixed;
      z-index: 4;
      left: -53%;
      bottom: 0;
      top: 50px;
      right: 100%;
      overflow: hidden;
      padding: 0;
    }
    &.swipeRight {
      z-index: 7;
      @include transition(.3s all ease-in-out);
      @include multiple-media(mobileScreen, tabletScreen) {
        right: 50%;
        left: 0;
        border: 1px solid $smallBlockBorder;
        bottom: 0;
        height: 100%;
        width: auto;
        padding: 0;
      }
    }
    .scrollShow {
      height: calc(100vh - 43rem);
      @include multiple-media(mobileScreen, tabletScreen) {
        height: calc(100vh - 170px);
      }
      @include multiple-media(smallScreen, mediumScreen) {
        width: 200px;
      }
      @include breakpoint(largeScreen) {
        height: calc(100vh - 41rem);
        width: 250px;
      }
    }
    &-block {
      padding: 15px;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      @include breakpoint(hugeScreen) {
        padding: 1rem;
      }
      &:after {
        position: absolute;
        bottom: 0;
        left: -25px;
        right: -25px;
        display: block;
        height: 1px;
        background-color: $dealIconColor;
        content: '';
      }
      &-left {
        .bigText {
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          font-size: 12px;
          font-weight: 500;
          color: $black;
          word-break: break-word;
          @-moz-document url-prefix() {
            word-wrap: anywhere;
          }
          @include breakpoint(hugeScreen) {
            font-size: 1rem;
          }
        }
        .smallText {
          font-size: 11px;
          font-weight: 500;
          text-transform: none;
          color: $dark-gray;
          @include breakpoint(hugeScreen) {
            font-size: 1rem;
          }
        }
      }
      &-right {
        i {
          font-size: 16px;
          color: $dark-gray;
          cursor: pointer;
          @include breakpoint(hugeScreen) {
            font-size: 1.2rem;
          }
        }
      }
    }
    h4 {
      padding: 15px;
      text-transform: uppercase;
      position: relative;
      font-weight: 500;
      font-size: 12px;
      margin: 0;
      color: $black;
      &:after {
        background-color: $dealIconColor;
        position: absolute;
        left: -25px;
        bottom: 0;
        right: -25px;
        content: '';
        display: block;
        height: 1px;
      }
      @include breakpoint(hugeScreen) {
        font-size: 1.2rem;
        padding: 1rem;
      }
    }
  }
  .do-experience__child {
    flex-grow: 1;
    flex-basis: calc(100% - 220px);
    height: 100%;
    @include multiple-media(mobileScreen, tabletScreen) {
      width: 100%;
      margin-left: 0;
    }
    @include breakpoint(largeScreen) {
      flex-basis: calc(100% - 270px);
    }
    .layer-body__middle-holder {
      flex-basis: 100%;
      height: calc(100vh - 29.1rem);
      @include multiple-media(mobileScreen, tabletScreen) {
         height: calc(100vh - 26rem);
      }
      @include breakpoint(largeScreen) {
        height: calc(100vh - 27.5rem);
      }
      .do-experience {
        &__link {
          z-index: 2 !important;
          @include multiple-media(mobileScreen, tabletScreen) {
            top: unset !important;
            bottom: 10px;
            right: 20px;
          }
          span {
            @include breakpoint(hugeScreen) {
              font-size: 1.2rem;
            }
          }
          i {
            @include breakpoint(hugeScreen) {
              font-size: 1.2rem;
            }
          }
        }
        &__link-left {
          @include multiple-media(mobileScreen, tabletScreen) {
            top: unset !important;
            right: auto;
            left: 20px;
          }
        }
      }
      .right-link-last {
        @include multiple-media(bigLaptop, bigScreen) {
          right: 60px;
        }
      }
      .search-holder {
        @include breakpoint(hugeScreen) {
          margin-bottom: 4rem;
        }

        .search-container {
          @include breakpoint(hugeScreen) {
            height: 10rem;
          }

          .search-box {
            @include breakpoint(hugeScreen) {
              padding: 3rem;
              height: 8rem;
            }
          }
        }
      }

      .selected-list {
        max-height: calc(100vh - 58rem);
        @include breakpoint(tabletScreen) {
          max-height: calc(100vh - 62rem);
        }
      }

      .preferences-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 0;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 0 15%;
        @include multiple-media(mobileScreen, tabletScreen) {
          padding: 0 5%;
        }
        svg {
          width: 100%;
          position: relative;
          top: -25px;
        }
        .preferences-row {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;

          .preferences-item {
            font-weight: 500;
            font-size: 1.2rem;
            background-color: $layerBackground;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            cursor: pointer;
          }

          .deal {
            height: 140px;
            width: 140px;
            border-radius: 50%;
            margin: 10px;
            text-align: center;
            font-size: 12px;
            @include breakpoint(smallScreen) {
              width: 120px;
              height: 120px;
            }
            @include breakpoint(mediumScreen) {
              width: 100px;
              height: 100px;
            }
            @include breakpoint(largeScreen) {
              height: 140px;
              width: 140px;
            }
            @media screen and (min-width: 2290px) {
              height: 200px;
              width: 200px;
              font-size: 18px;
            }
            @media not all and (pointer: coarse) {
              &:hover {
                background-color: $positive;
                color: $white;
              }
             }
          }

          .selected {
            background-color: $positive;
            color: $white;
          }
        }
      }

      .deal-type {
        position: relative;
        @media screen and (max-height: 768px) {
          align-items: unset;
        }
        @media screen and (max-height: 700px) {
          height: 100%;
          overflow: auto;
        }
      }

      .bar-container {
        padding: 0 150px;
        margin: 0;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        height: 300px;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        margin-top: auto;
        margin-bottom: auto;
        top: -60px;
        @include multiple-media(mobileScreen, tabletScreen) {
          padding: 0 50px;
        }
        div {
          width: 100%;
          max-height: none;
          @include breakpoint(hugeScreen) {
            width: 95%;
          }
        }

        &.preferences-deal-size {
          div {
            width: 100%;
          }
        }

        h4 {
          font-size: 12px;
          font-weight: 500;
          line-height: 1.83;
          padding: 40px 0;
          text-align: center;
          @include breakpoint(hugeScreen) {
            font-size: 1.2rem;
          }
          @media screen and (max-height: 650px) {
            padding: 20px 0;
          }
        }

        .vue-slider-component.vue-slider-horizontal .vue-slider-piecewise-label {
          font-size: 0.9rem !important;
        }
      }
      .investor-experience-selection {
        max-height: calc(100vh - 37.3rem);
        @include multiple-media(mobileScreen, tabletScreen) {
          max-height: calc(100vh - 560px);
        }

        .industry-selection {
          @include breakpoint(hugeScreen) {
            margin-left: 11rem;
            margin-right: 13rem;
          }
        }

        .results-list {
          @include breakpoint(hugeScreen) {
            max-height: 14.1rem;
          }
          @include breakpoint(smallLaptop) {
            max-height: 176px;
          }
          @media screen and (min-width: 1280px) and (max-width: 1366px) and (min-height: 600px) and (max-height: 767px) {
            max-height: 122px;
          }
        }
      }

      h3 {
        margin: 30px;
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        @include breakpoint(hugeScreen) {
          font-size: 1.4rem;
        }
      }
    }
  }
  .container-height {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .preferences-deal-size {
    @media screen and (max-height: 650px) {
      position: relative;
      top: -70px !important;
    }
  }
  .preferences-container {
    .preferences-item {
      &.stage {
        polygon {
          fill: $layerBackground;
        }
        text {
          text-anchor: middle;
          dominant-baseline: central;
          font-size: 12px;
          font-weight: 500;
          fill: $darkBlack;
          @include breakpoint (smallLaptop){
            font-size: 15px;
          }
          @include breakpoint (devices){
            font-size: 15px;
          }
          @include breakpoint (toBigPhone){
            font-size: 20px;
          }
        }
        &:hover {
          polygon {
            cursor: pointer;
            @media not all and (pointer: coarse) {
              fill: $positive;
            }
          }
          text {
            cursor: pointer;
            @media not all and (pointer: coarse) {
              fill: $white;
            }
          }
        }
        &.selected {
          polygon {
            fill: $positive;
          }
          text {
            fill: $white;
          }
        }
      }
    }
  }
  .investor-preferences-sidebar-buttons {
    width: calc(100% - 30px);
    padding-left: 15px;
    position: relative;
  }
}
.investor-preferences-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  .layer-body__middle-filters {
      @include multiple-media(mobileScreen) {
          display: none;
      }
  }
  .do-experience__child {

      @include multiple-media(mobileScreenLandscape) {
          height: auto;
      }

    .do-industry,
    .geography {

        .investor-experience-selection {
            @include multiple-media(mobileScreenLandscape) {
                overflow-y: auto;
            }
        }
    }

    .fundraising-wrapper  {
        @include multiple-media(mobileScreenLandscape) {
            padding: 0 20%;
        }
    }

    .layer-body__middle {
      height: 100%;
      align-items: unset !important;

        &-holder {
            overflow-y: hidden;

            .investor-experience-selection {
                height: 100%;

                @include multiple-media(mobileScreen) {
                    margin-bottom: 4rem;
                    margin-top: 2rem;
                }
                @include multiple-media(mobileScreenLandscape) {
                    margin-top: 0;
                }

                .selected-list {
                    min-height: 10rem;
                    max-height: calc(100vh - 60rem);
                    overflow: auto;

                    @include multiple-media(mobileScreen) {
                        max-height: calc(100vh - 47rem);
                    }

                    @include multiple-media(tabletScreen) {
                        max-height: calc(100vh - 68rem);
                    }


                }
                .tag-selected:not(.tag-primary) {
                    margin-top: 0;
                }

            }

            .do-experience__link {
                display: block;

                .break-line {
                    @include multiple-media(mobileScreen) {
                        display: none;
                    }
                }

                &-right {
                    @include multiple-media(mobileScreen) {
                        right: 2rem;
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;

                        span {
                            margin: 0 .5rem;
                        }
                    }
                }
                &-left {

                    @include multiple-media(mobileScreen) {
                        left: 2rem;
                        display: flex;
                        align-items: center;

                        span {
                            margin: 0 .5rem;
                        }
                    }
                }
            }

            .bar-container {
                @include multiple-media(mobileScreen) {
                    overflow: auto;
                    margin-bottom: 4rem;
                    height: auto;
                }
            }

            .preferences-deal-size {

                @include multiple-media(mobileScreenLandscape) {
                    overflow-y: auto;
                    padding: 5rem 14rem;
                }
            }

            .deal-type {
                @include multiple-media(mobileScreenLandscape) {
                    overflow-y: auto;
                }

                > div:first-child {
                    @include multiple-media(mobileScreenLandscape) {
                        height: 100%;
                    }
                }
            }

            h3 {

                @include multiple-media(mobileScreenLandscape) {
                    z-index: 10;
                    background: $color-white;
                    padding: 2rem;
                    margin: 0;
                }
            }

        }
    }
  }
}
.do-stage {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background: $white;
  display: flex;
  align-items: center;
  padding: 12px 40px;
  height: 47px;
  width: auto;
  @include multiple-media(mobileScreen, tabletScreen) {
    display: none;
  }
  span{
    width: 15%;
    display: block;
    font-size: 13px;
    color: $black;
    font-weight: 500;
    @include breakpoint(tabletLandscape){
      width: 35%;
    }
  }
  div{
    width: 85%;
    display: ruby !important;
    img{
      max-width: 100%;
      width: 100%;
    }
  }
}
