.deal-list-block {
  width: 210px;
  overflow-y: auto;
  height: 100%;
  @-moz-document url-prefix() {
    width: 240px;
  }
  @include multiple-media (mobileScreen, tabletScreen) {
    width: 100%;
    overflow-y: visible;
    height: 100px;
  }
  &__list {
    padding-left: 0;
    list-style: none;
    margin: 0;
    @include multiple-media (mobileScreen, tabletScreen) {
      display: none;
    }
    li {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      padding: 1rem 1.5rem;
      font-weight: 500;
      border-radius: 3px;
      cursor: pointer;
      border: 1px solid transparent;
      @include transition (.3s all ease-in-out);

      &:hover {
        background-color: $white;
        border: 1px solid $lightGray;
      }

      &.active {
        background-color: $white;
        color: $brand;
        border: 1px solid $lightGray;
        font-weight: bold;
        padding: 1rem 1.5rem;
      }
    }
  }


  .box {
    border: 1px solid $smallBlockBorder;
    box-shadow: -2px 5px 12px -6px $lightGray;
  }

  &__dropdown {
    height: 80px;

    .link {
      padding-top: 20px;
      padding-left: 20px;
    }
  }

  .dropdown-icon--vertical-center {
    right: 20px;
    top: 35px;
    position: absolute;

    .fa {
      color: $darkBlack;
    }
  }

  &__deals-dropdown {
    display: none;
    height: 80px;
    width: 100%;
    background-color: white;
    margin-bottom: 20px;
    border-radius: 3px;


    @include multiple-media (mobileScreen, tabletScreen) {
      display: block;
      box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px 0;
      border-radius: 3px;
    }

    .link {
      padding-top: 20px;
      padding-left: 20px;
      font-size: 1.2rem;
      color: $brand;
      font-weight: 500;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .my-dropdown-dd {
      max-height: 415px;
      overflow-y: scroll;
      top: 20px !important;
      border-radius: 3px;
      background-color: $darkerWhiteDO;
      position: relative !important;
      z-index: 1;
      .deal-list-block__dialog {
        ul {
          padding-inline-start: 0;
          li {
            border-radius: 3px;
            border: 1px $smallBlockBorder solid;
            font-size: 1.2rem;
            font-weight: 500;
            cursor: pointer;
            &.active {
              color: $color-blue-main;
              background-color: $color-white;
            }
          }
        }
      }
    }
  }

  &__dialog {
    display: block;
  }

}
