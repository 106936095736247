.sidebar-advanced-filter {
  &__button {
    margin-top: 2rem;
    display: flex;
    button {
      width: 100%;
      &:first-child {
        border-radius: 5px 0 0 5px;
      }
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
      &:only-child {
        border-radius: 5px;
      }
    }
    .filter-text {
      &:focus {
        outline: none;
      }
    }
    .filter-caret {
      width: fit-content;
      padding: 0 1.2rem;
      border-left: 1px solid $color-grey-6;
      @-moz-document url-prefix() {
        width: 3.5rem;
      }
      i {
        font-size: 1.6rem;
      }
      &:hover {
        ~ .filter-text {
          background-color: $color-blue-dark;
          color: $white;
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
  &__list {
    margin-top: 0.5rem;
    padding: 1rem 1.6rem;
    &--content {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 69rem);
      @include breakpoint(mediumScreen) {
        max-height: calc(100vh - 65rem);
      }
      .filters {
        line-height: 1.83;
        font-size: 1.4rem;
        @include breakpoint(mediumScreen) {
          font-size: 1.2rem;
        }
        .type {
          color: $dark-gray;
        }
        .selected {
          color: $color-grey-6;
          word-break: break-word;
          @-moz-document url-prefix() {
            word-break: break-all;
          }
        }
      }
    }
    &--options {
      margin-top: 1rem;
      padding: 1rem 0 0;
      display: flex;
      justify-content: flex-end;
      i {
        font-size: 1.6rem;
        cursor: pointer;
        color: $black;
      }
      span {
        border-radius: 50%;
        background-color: $white;
        padding: 0.6rem 0.7rem;
        cursor: pointer;
        &:hover {
          background-color: rgba(31, 123, 232, 0.1);
          transition: .25s ease-in;
          i {
            color: $brand;
          }
        }
      }
      &-left {
        display: flex;
        align-items: center;
        span {
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
      &-right {
        display: flex;
        align-items: center;
      }
    }
  }
}
.network-search-disabled {
  background-color: $color-grey-1;
  opacity: 0.5;
  input {
    &:disabled {
      background-color: $color-grey-1;
    }
  }
  i {
    &:hover {
      cursor: default;
    }
    &::before {
      cursor: default;
    }
  }
}
