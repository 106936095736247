$small-founders-info-height: 80px;

.flex-clearfix {
    position: relative;

    &:before,
    &:after {
        content: "";
        flex-basis: 0;
        order: 1;
    }
}

.founders-sign {
    @include multiple-media(smallScreen, mediumScreen, largeScreen) {
        display: flex;
        flex-direction: row;
        height: 100vh;
    }

    font-family: 'Nunito',
    sans-serif;
    background-color: $color-white-2;
    font-size: 12px;
    color: $color-grey-17;

    .founders-info {
        display: flex;
        text-align: center;
        color: $founderDarkerBrand;
        background-color: white;

        @include breakpoint(tabletScreen) {
            background-position: 50% 20%;
        }

        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
            height: 100%;
            text-align: left;
            width: 50%;
        }

        .info-wrapper {
            width: 100%;

            @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                display: flex;
                flex-grow: 1;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: 0;
            justify-content: center;

            @include multiple-media(mobileScreen, tabletScreen) {
                padding: 20px;
            }

            @include breakpoint(smallScreen) {
                padding: 40px;
            }

            @include breakpoint(mediumScreen) {
                padding: 60px 120px;
            }

            @include breakpoint(largeScreen) {
                padding: 90px 200px;
            }

            .info-logo {
                margin-bottom: 40px;
                min-height: 50px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: left;
                background-image: url('/images/logo_proraise_whitebg.png');
            }

            &--title {
                font-size: 18px;
                margin: auto;
                font-family: 'NunitoBold', sans-serif;
                line-height: 1.13;

                @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                    margin: 0 0 4.5rem 0;
                }

                @include multiple-media(smallScreen, mediumScreen, tabletScreen) {
                    font-size: 36px;
                }

                @include multiple-media(mobileScreen, tabletScreen) {
                    .break {
                        display: none;
                    }
                }

            }

            .info-block {
                font-family: 'Nunito', sans-serif;
                font-size: 1.9rem;
                line-height: 1.56;

                @media screen and (max-width: 899px) {
                    display: none;
                }

                &--title {
                    display: flex;
                }

                &--disclaimer {
                    padding-top: 6rem;
                }

                &--footer {
                    padding-top: 3rem;
                    display: flex;
                    color: #0dcfda;
                }

                &__list {
                    list-style: none;
                    padding-left: 0;
                }

                &-text {
                    &--cyan {
                        color: $founderBrand;
                    }
                }

                .info-items {
                    display: flex;
                    flex-direction: column;
                    min-width: 40rem;
                }
            }

            .info-block-img {
                margin-top: 30px;
                height: calc(100% - #{$small-founders-info-height});
                max-height: 500px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: left;
                background-image: url('/images/built-for-the-journey.png');
            }
        }

    }

    .founders-account {
        display: flex;
        flex-grow: 1;
        width: 50%;
        height: calc(100% - #{$small-founders-info-height});
        background-color: $founders-sign-color;

        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            height: auto;
        }

        @include breakpoint(tabletScreen) {
            width: 100%;
            position: relative;
            padding-top: 50px;
        }

        @include breakpoint(mobileScreen) {
            width: 100%;
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            height: 100%;
            background-color: $founders-sign-color;

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                padding: 0;
                position: relative;
                width: auto;
            }

            @include breakpoint(tabletScreen) {
                height: calc(100vh - 130px);
            }

            @include breakpoint(mobileScreen) {
                height: calc(100vh - 80px);
            }

            .founders-form-wrapper {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    justify-content: flex-start;
                }

                @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                    justify-content: center;
                    position: relative;
                }

                @include breakpoint(mobileScreen) {
                    justify-content: start;
                }

                .founders-form {
                    display: block;
                    flex-direction: column;
                    justify-content: center;
                    background-color: $color-white;
                    position: relative;
                    width: 100%;

                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
                        width: 480px;
                        border-radius: 5px;
                        position: relative;
                        display: flex;
                    }

                    .Password__strength2 {
                        &-container {
                            label {
                                color: $color-grey-17;
                                font-size: 1.2rem;
                            }
                        }

                        &-meter {
                            .check {
                                width: 16%;

                                .desc-full {
                                    font-size: 10px;

                                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                                        font-size: inherit;
                                    }
                                }
                            }
                        }
                    }

                    .founders-input-container {
                        padding: 0 20px;

                        .founders-forgot-password {
                            padding-top: 1rem;
                            color: #0dcfda;
                            cursor: pointer;
                            font-weight: 500;
                            font-size: 1.6rem;
                            text-align: right;
                            display: block;
                            font-style: italic;
                        }

                        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                            padding: 30px 50px;

                            .inline-inputs {
                                display: flex;
                                justify-content: space-between;

                                .founders-input {
                                    width: calc(50% - 10px);
                                    margin-top: 0;
                                }
                            }
                        }

                        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                            /*padding: 50px 45px;*/
                        }

                        .founders-input {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            position: relative;
                            margin-top: 3rem;

                            &--label {
                                font-size: 1.6rem;
                                color: $color-grey-17;
                                margin: 0 0 .5rem 0;
                            }

                            &--text {
                                outline: none;
                                padding: 1.8rem 1rem;
                                font-size: 1.6rem;
                                border-radius: 5px;
                                border: solid 1px $color-grey-18;
                                background-color: $color-white;
                            }

                            &--error {
                                font-size: 1.2rem;
                                color: $color-red-main;
                                display: none;
                            }

                            .multiselect__input {
                                background: $white;
                                font-size: 1.6rem;
                                font-weight: 900;
                                font-family: Nunito;
                            }

                            .multiselect__select {
                                top: 1.3rem;
                            }

                            .multiselect__select:before {
                                border-color: #000000 transparent transparent;
                            }
                        }

                        .checkbox-container input {
                            width: 1.6rem;
                            height: 1.6rem;
                            margin: 0;
                        }

                        .error-msg {
                            color: $color-red-main;
                            align-self: flex-start;
                            display: flex;
                            font-size: 1.4rem;
                            padding: 5px 0 5px;
                            font-weight: bold;

                            position: absolute;
                            bottom: -3rem;
                            left: 0;

                            @include transition(.3s ease-in-out);

                            &--in-flow {
                                position: static;
                                opacity: 0;
                            }

                            &--visible {
                                opacity: 1;
                            }

                            &:before {
                                padding: 2px 8px 0 0;
                                content: "\f06a";
                                font-family: FontAwesome;
                                font-style: normal;
                                font-weight: normal;
                            }
                        }

                        .founders-button {
                            display: flex;
                            width: 100%;
                            flex-grow: 1;
                            padding-top: 2rem;

                            .founders-btn {
                                cursor: pointer;
                                font-family: 'NunitoBold', sans-serif;
                                color: $color-white;
                                font-size: 1.6rem;
                                outline: none;
                                width: 100%;
                                padding: 2.6rem 0;
                                border: none;
                                border-radius: 5px;
                                background-color: $founderDarkerBrand;

                                &:hover {
                                    background-color: $founderDarkerBrandHover;
                                }
                            }
                        }

                        .checkbox-container {
                            display: flex;

                            label {
                                color: $color-grey-17;
                                margin-left: 1rem;
                                font-size: 12px;
                                margin-bottom: unset;
                                margin-top: 0;

                                a {
                                    color: #0dcfda;
                                }
                            }
                        }
                    }
                }

                .reroute-wrapper {
                    position: relative;
                    width: 90%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    margin-top: 20px;

                    .login-here {
                        position: relative;
                    }

                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                        align-items: center;
                    }

                    &.reroute-wrapper-mobile {
                        display: block;
                        width: 100%;

                        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                            display: none;
                        }
                    }

                    &.reroute-wrapper-not-mobile {
                        display: none;

                        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                            display: block;
                        }
                    }

                    .back-to-login {
                        width: 450px;
                        text-align: left;
                        font-size: 1.6rem;
                        color: #0dcfda;
                        cursor: pointer;
                    }

                    .account {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        font-size: 1.6rem;
                        color: $color-grey-17;
                        white-space: pre;
                        line-height: 0.8;

                        &-forward-link {
                            color: #0dcfda;
                            cursor: pointer;
                        }
                    }

                    .prompt-text {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

$small-founders-info-height: 80px;

.hubx-sign {
    @include multiple-media(smallScreen, mediumScreen, largeScreen) {
        display: flex;
        flex-direction: row;
        height: 100vh;
    }

    font-family: 'Nunito',
    sans-serif;
    background-color: $color-white-2;
    font-size: 12px;
    color: $color-grey-17;

    .founders-info {
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/images/background/whitelabel/hubx_background.jpg');
        text-align: center;
        width: 60%;

        @include breakpoint(tabletScreen) {
            background-position: 50% 20%;
        }

        @include breakpoint(smallScreen) {
            width: 50%;
        }

        @include multiple-media(mobileScreen, tabletScreen) {
            width: 100%;
        }

        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
            height: 100%;
            text-align: left;
        }

        .info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: 0;

            @include multiple-media(mobileScreen, tabletScreen) {
                padding: 20px;
            }

            @include breakpoint(smallScreen) {
                padding: 40px;
            }

            @include multiple-media(mediumScreen, largeScreen) {
                padding: 65px;
            }

            &--title {
                font-size: 18px;
                margin: auto;
                font-family: 'NunitoBold', sans-serif;
                text-shadow: 0 0 15px rgba(0, 0, 0, 0.52);
                line-height: 1.13;
                color: $color-white;

                @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                    margin: 0 0 4.5rem 0;
                }

                @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                    font-size: 36px;
                }

                @include multiple-media(mobileScreen, tabletScreen) {
                    .break {
                        display: none;
                    }
                }

            }

            .info-block {
                font-family: 'NunitoBold', sans-serif;
                font-size: 1.8rem;
                text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
                color: $color-white;
                line-height: 1.56;

                @media screen and (max-width: 899px) {
                    display: none;
                }

                &--title {
                    padding-bottom: 4.5rem;
                    display: flex;
                }

                &--footer {
                    padding-top: 4.5rem;
                    display: flex;
                }

                .info-items {
                    display: flex;
                    flex-direction: column;
                    min-width: 40rem;
                }
            }
        }

    }

    .founders-account {
        display: flex;
        flex-grow: 1;

        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
            max-width: 50%;
        }

        .investor-onboarding {
            @include multiple-media(tabletScreen, smallScreen) {
                display: block;
            }
        }

        @include breakpoint(tabletScreen) {
            width: 100%;
            position: absolute;
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            height: calc(100% - #{$small-founders-info-height});
            background-color: $color-white;

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                padding: 0;
                width: auto;
                background-color: transparent;
                height: 100%;
            }

            .founders-form-wrapper {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: column;

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    justify-content: flex-start;
                }

                @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                    justify-content: center;
                    position: relative;
                }

                .founders-form {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background-color: $color-white;
                    position: relative;
                    width: 100%;

                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
                        width: 450px;
                        border-radius: 5px;
                        position: relative;
                    }

                    .Password__strength2 {
                        &-container {
                            label {
                                color: $color-grey-17;
                                font-size: 1.2rem;
                            }
                        }

                        &-meter {
                            .check {
                                width: 16%;

                                .desc-full {
                                    font-size: 10px;

                                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                                        font-size: inherit;
                                    }
                                }
                            }
                        }
                    }

                    .founders-input-container {
                        padding: 0 20px;

                        .founders-forgot-password {
                            padding-top: 1rem;
                            color: $color-blue-main;
                            cursor: pointer;
                            font-weight: 500;
                            font-size: 1.6rem;
                            text-align: right;
                            display: block;
                            font-style: italic;
                        }

                        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                            padding: 30px 50px;

                            .inline-inputs {
                                display: flex;
                                justify-content: space-between;

                                .founders-input {
                                    width: calc(50% - 10px);
                                }
                            }
                        }

                        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                            padding: 50px 45px;
                        }

                        .founders-input {
                            display: flex;
                            flex-direction: column;
                            padding-top: 2rem;
                            width: 100%;

                            &--label {
                                font-size: 1.6rem;
                                color: $color-grey-17;
                                margin-bottom: .5rem;
                            }

                            &--text {
                                outline: none;
                                padding: 1.8rem 1rem;
                                font-size: 1.6rem;
                                border-radius: 5px;
                                border: solid 1px $color-grey-18;
                                background-color: $color-white;
                            }

                            &--error {
                                font-size: 1.2rem;
                                color: $color-red-main;
                                display: none;
                            }

                            .multiselect__input {
                                background: $white;
                                font-size: 1.6rem;
                                font-weight: 900;
                                font-family: Nunito;
                            }

                            .multiselect__select {
                                top: 1.3rem;
                            }

                            .multiselect__select:before {
                                border-color: #000000 transparent transparent;
                            }
                        }

                        .checkbox-container input {
                            width: 1.6rem;
                            height: 1.6rem;
                        }

                        .error-msg {
                            color: $color-red-main;
                            align-self: flex-start;
                            display: flex;
                            font-size: 1.4rem;
                            padding: 5px 0 5px;
                            font-weight: bold;

                            &:before {
                                padding: 2px 8px 0 0;
                                content: "\f06a";
                                font-family: FontAwesome;
                                font-style: normal;
                                font-weight: normal;
                            }
                        }

                        .founders-button {
                            display: flex;
                            width: 100%;
                            flex-grow: 1;
                            padding-top: 3rem;

                            .founders-btn {
                                cursor: pointer;
                                font-family: 'NunitoBold', sans-serif;
                                color: $color-white;
                                font-size: 1.6rem;
                                outline: none;
                                width: 100%;
                                padding: 1.6rem 0;
                                border: none;
                                border-radius: 5px;
                                background-color: $color-blue-main;
                            }
                        }

                        .checkbox-container {
                            display: flex;

                            label {
                                color: $color-grey-17;
                                margin-left: 1rem;
                                font-size: 12px;
                                margin-bottom: unset;

                                a {
                                    color: $color-blue-main;
                                }
                            }
                        }
                    }
                }

                .reroute-wrapper {
                    position: relative;
                    width: 90%;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    min-height: 60px;

                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                        align-items: center;
                    }

                    .back-to-login {
                        width: 450px;
                        text-align: left;
                        font-size: 1.6rem;
                        color: $color-blue-main;
                        cursor: pointer;
                    }

                    .account {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        font-size: 1.6rem;
                        color: $color-grey-17;
                        white-space: pre;
                        line-height: 0.8;

                        &-forward-link {
                            color: $color-blue-main;
                            cursor: pointer;
                        }
                    }

                    .prompt-text {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}


.error-msg {
    color: $color-red-main;
    align-self: flex-start;
    display: flex;
    font-size: 1.4rem;
    padding: 5px 0 5px;
    font-weight: bold;

    &:before {
        padding: 2px 8px 0 0;
        content: "\f06a";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
    }
}
