.Password__strength2-container {
    label {
        color: $black;
        font-size: 12px;
    }
}

.Password__strength2-meter {
    margin: 1rem auto;
    display: flex;
    justify-content: space-between;

    .check {
        display: flex;
        flex-direction: column;
        width: 100px;
        color: $color-grey-17;

        .desc {
            border-bottom: 1px #9a9a9a solid;
            font-size: 16px;
        }
        .desc.fill {
            border-bottom: 1px $brand solid;
            color: $brand;
        }

        .desc-full {
            font-size: 12px;
        }
        .desc-full.fill {
            color: $brand;
        }
    }
}
