.do-originator {
    &__container {
        padding: 5rem 20% 4rem;
        @include breakpoint(largeScreen) {
            padding: 5rem 31rem 4rem;
        }
        @include breakpoint(mediumScreen) {
            padding: 5rem 9rem 4rem;
        }
        @include breakpoint (smallScreen) {
            padding: 5rem 3rem 4rem;
        }
        @include multiple-media(tabletScreen) {
            padding: 5rem 2rem 4rem;
        }
        @include multiple-media(mobileScreen) {
            padding: 5rem 2rem 3rem;
        }
    }
    &__bigBlock {
        width: calc(100% - 210px);
        margin-left: 26px;
        @include transition(.1s all ease-in-out);
        .empty-content {
            text-align: center;
            margin-top: 5rem;
            font-size: 1.4rem;
        }
    }
    &__smallBlock {
        width: 210px;
        overflow-y: auto;
        height: 100%;
        .media {
            &__image {
                width: 8rem;
            }
        }
        ul {
            padding-left: 0;
            list-style: none;
            margin: 0;
            li {
                font-size: 1.2rem;
                padding: 1.4rem 1.8rem;
                font-weight: 500;
                border-radius: 3px;
                cursor: pointer;
                border: 1px solid transparent;
                @include transition (.3s all ease-in-out);
                &:hover {
                    background-color: $white;
                    border: 1px solid $lightGray;
                }
                &.active {
                    background-color: $white;
                    color: $brand;
                    border: 1px solid $lightGray;
                    font-weight: bold;
                    padding: 1.4rem 1.8rem;
                }
            }
        }

    }
}
.spacing {
    margin: 4rem 0;
    .dropdownFilter {
        top: 1rem;
    }
}

.do__originator__placeholder-container {
    display: flex;
    align-items: flex-start;
    clear: both;
}

.media {
    display: flex;
    align-items: center;
    &__buttons {
        text-align: right;
        padding-right: 1rem;
        &-item {
            border: 0;
            font-size: 1.2rem;
            font-family: $regular;
            background-color: transparent;
            cursor: pointer;
            @include transition (.3s all ease-in-out);
            font-weight: 500;
            outline: none;
            &-normal {
                color: $black;
                &:hover {
                    color: $brand;
                }
            }
            &-brand {
                color: $brand;
                &:hover {
                    color: $darkerBrand;
                }
            }
            &-red {
                color: $red;
                &:hover {
                    color: $darkerRed;
                }
            }
            &-green {
                color: $positive;
                &:hover {
                    color: $darkerGreen;
                }
            }
            &-custom {
                color: $black;
                width: 3rem;
                position: relative;
                transition: none;
                padding: 0.7rem;
                &:hover {
                    color: $brand;
                    background-color: $lightGray;
                    border-radius: 50%;
                    .email-sent {
                        bottom: 0.5rem;
                    }
                }
                .email-sent {
                    border-radius: 50%;
                    background-color: $white;
                    color: $colorCheck;
                    position: absolute;
                    bottom: 0;
                    right: 0.5rem;
                    font-size: 1rem;
                }
                i {
                    font-size: 1.5rem;
                }
            }
        }
    }
    &__image {
        width: 40px;
        height: 40px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        border: 1px solid $dealBlockColor;
        position: relative;
        background: $white;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        &-big {
            width: 100%;
            height: 14.85rem;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            border: 1px solid $dealBlockColor;
            position: relative;
            z-index: 1;
            background-color: $white;
            img {
                max-width: 100%;
                max-height: 100%;
                position: relative;
                z-index: 1;
            }
            &-line {
                position: absolute;
                top: 15rem;
                left: 0;
                right: 0;
                content: '';
                display: block;
                height: 1px;
                background-color: $layerBackground;
                z-index: 0;
            }
        }
    }
    &__info {
        padding-left: 2rem;
        &-title {
            font-size: 1.3rem;
            font-weight: 500;
            color: $black;
            display: flex;
            align-items: center;
            margin-bottom: 0.3rem;
            cursor: pointer;
            white-space: nowrap;
            flex-flow: wrap;
            @include transition(.3s all ease-in-out);

            &-text {
                margin: 0 4px 4px 0;
                word-break: break-word;
                white-space: pre-wrap;
                @media (min-width: 1200px) and (max-width: 1700px) {
                    max-width: 175px;
                }
            }

            &.viewed {
                cursor: default;
                &:hover {
                    color: $black;
                }
                .media__info-desc {
                    cursor: default !important;
                }
            }
            &:hover {
                color: $brand;
            }
            a {
                &:hover {
                    color: $brand !important;
                }
            }
            &.syndicated-investor {
                cursor: default;
                &:hover {
                    color: $black;
                }
            }
        }
        &-desc {
            font-size: 1rem;
            line-height: 1.3rem;
            font-weight: 400;
            color: $smallColor;
            display: block;
            padding-right: 2rem;
            cursor: pointer;
        }
        &-desc-simple {
            font-size: 0.9rem;
            line-height: 1.3rem;
            font-weight: 400;
        }
    }
    &__smallImage {
        position: absolute;
        width: 1.9rem;
        height: 1.9rem;
        top: -1rem;
        left: -1rem;
    }
}

.important {
    border: 1px solid $yellow !important;
}

.todo-widget__filters {
    .todo-widget__filters-older {
        text-transform: uppercase !important;
    }
}

.placeOrderModal {
    padding: 3rem 2rem;
    .common__input-box {
        padding: 0 !important;
    }
    .common__modalInput {
        margin-bottom: 0 !important;
    }
    .errorText {
        top: 100% !important;
        bottom: auto;
        right: 0;
        left: initial !important;
    }
    .showError {
        &:before {
            @include breakpoint (toDesktop) {
                top: 3.8rem;
            }
        }
    }
}

.do-match, .do-investor, .do-network {
    .content-bottom {
        box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.05);
        & > a.do-button {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.2rem;
            color: $black;
            cursor: pointer;
            @include transition(.3s all ease-in-out);
            @include breakpoint (toDesktop) {
                font-size: 2.1rem;
            }
            &.activeLink {
                background-color: $white;
                padding: 0.7rem 2rem;
                color: $brand;
            }
            &:hover {
                background-color: transparent;
                color: $darkerBrand !important;
            }
        }
        &__center {
            justify-content: center;
        }
        .hide-button {
            z-index: 0;
        }
        &-deal-updates {
            box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.05);
            z-index: 0;

            .button-left {
                i {
                    font-size: 1.5rem;
                    margin-right: 0.5rem;
                }
            }
            .button-right {
                i {
                    font-size: 1.5rem;
                    margin-left: 0.5rem;
                }
            }
        }
    }
    .layer-body__middle-holder-content-with-footer {
        height: calc(100vh - 33rem);
        @include breakpoint (smallLaptop) {
            height: calc(100vh - 33rem);
        }
        overflow: hidden;
        .do-originator__bigBlock-right {
            max-height: calc(100vh - 32rem) !important;
        }
        .layer-body__middle-holder-content {
            height: calc(100vh - 31rem);
            overflow-y: hidden;
            @include breakpoint (smallLaptop) {
                height: calc(100vh - 34rem);
            }
        }
    }
}

.search-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    .common__input-box {
        margin-bottom: 0;
        width: 65%;
    }
    button {
        margin: 0;
        padding-top: 1.9rem !important;
        padding-bottom: 1.9rem !important;
    }
}

.not-allowed {
    cursor: auto;
    &:hover {
        color: inherit;
    }
}

.widget-dropdown {
    display: none;
    width: 100%;
    height: 40px;
    background-color: $color-white;
    margin-bottom: 20px;
    border-radius: 3px;
    @include multiple-media (mobileScreen, tabletScreen) {
        display: block;
    }
    .link {
        font-size: 1.2rem;
        color: $color-blue-main;
        font-weight: 500;
        position: relative;
        padding-top: 13px;
        padding-left: 15px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .selected-filter {
            position: absolute;
            width: 90%;
            font-weight: bold;
        }
        .dropdown-icon {
            right: 10px;
            top: 15px;
            position: absolute;
            .fa {
                color: black;
            }
        }
    }
    .my-dropdown-dd {
        border-radius: 3px;
        background-color: $darkerWhiteDO;
        position: relative !important;
        top: 30px !important;
        z-index: 2;
        .link {
            position: relative;
            .selected-filter {
                width: 100%;
                height: 50px;
            }
        }
        .dialog {
            ul {
                list-style-type: none;
                padding: 0 !important;
                margin-block-start: 0 !important;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
                li {
                    font-size: 1.2rem;
                    font-weight: 500;
                    height: 40px;
                    border-radius: 3px;
                    cursor: pointer;
                    &:hover {
                        color: $color-blue-main;
                        background-color: $color-white;
                    }
                    .dropdown-item {
                        position: relative;
                        top: 13px;
                        left: 10px;
                    }
                }
                .active {
                    color: $color-blue-main;
                    background-color: $color-white;
                }
        }
    }
}
}

.country__icon {
    width: 25px;
    height: 19px;
    border-radius: 5px;
    margin: 0 4px 4px 0;
}
