nav.navbar{
    flex-direction: row;
    
    background: #fff;
    z-index: 0;
}
nav .logo-image{
        /* height: 50px; */
        width: 18rem;
}
ul.nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    align-items: center;
    display: flex;
}
.nav li {
    display: inline-flex;
    margin-right: 30px;
}
.nav li a{
    color: #686a6b;
}
.nav li a:active, .nav li .router-link-exact-active.is-active{
    color: #f8ac59 !important;
}