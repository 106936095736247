.compact-custom-area {
  .custom-area {
    padding: 20px 30px;
    background-color: $color-compact-background;
    .content-title {
      padding-top: 2rem;
      margin-top: 0;
    }
    .text-content {
      margin: 1rem 0 4rem 0;
    }
  }
}
