.deal-block{
  &__media {
    display: flex;
    width: 100%;
    height: 100%;
    flex-basis: 100%;
    align-items: center;

    &--dialog {
      padding-left: 20px;
      @include multiple-media (mobileScreen, tabletScreen) {
        width: fit-content;
        min-height: 80px;
      }
    }
  }
  &__image {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $dealBlockColor;
    position: relative;
    background: $color-white;
    @media (min-width: 480px) and (max-width: 899px) {
      border: 0;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
      object-fit: scale-down;
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: scale-down;
    }
  }
  &__small-image {
    position: absolute;
    width: 15px;
    height: 15px;
    top: -5px;
    left: -5px;
    img {
      width: 15px;
      height: 15px;
    }
  }

  &__info {
    position: relative;
    width: auto;
    margin-left: 10px;
    padding-left: 0;

    &:after {
      display: none;
    }

    @include breakpoint(smallLaptop) {
      width: 62.82%;
    }
  }
  &__info-title {
    font-size: 1.3rem;
    font-weight: 500;
    color: $color-grey-6;
    display: block;
    margin-bottom: 0.3rem;
    cursor: pointer;
  }
  &__company-name {
    word-break: break-word;
    @-moz-document url-prefix() {
      word-break: initial;
      word-wrap: break-word;
    }
  }

  &__info-desc {
    padding-right: 0;
    font-size: 1rem;
    line-height: 1.3rem;
    font-weight: 400;
    color: $dark-gray;
    display: block;
    cursor: pointer;
  }

}