.no-tasks {
  background: $white;
  border-radius: 3px;
  border: 1px solid $smallBlockBorder;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  width: auto;
  padding: 3.5rem;
  margin: 4rem 0;
  text-align: center;
  font-size: 1.6rem;
  color: $black;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  a {
    color: $brand;
  }
}