.searchFirm{
  .search-input{
    padding: 2rem;
    background-color: $white;
  }
  .firm-border {
    border: 0;
  }
  &__link{
    background-color: transparent;
    text-transform: uppercase;
    font-size: 1.2rem;
    border: 0;
    color: $brand;
    padding: 0;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    margin-right: 1.3rem;
    &:hover{
      color: $darkerBrand;
    }
  }
  .search-input-list{
    padding: 0;
    background: $white;
    position: relative;
    max-height: calc(100vh - 35rem);
    overflow-x: hidden;
    overflow-y: auto;
    @include radioGroupMixin;
    h4{
      color: $grayLight;
      font-size: 1.8rem;
      font-family: $regular;
      padding: 2rem;
    }
    &-holder{
      margin-bottom: 10rem;
    }
    &-footer{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem;
      position: absolute;
      background-color: $white;
      border-top: 1px solid $borderColor;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      a{
        color: $brand;
        text-transform: uppercase;
        font-size: 1.1rem;
        @include transition(.3s all ease-in-out);
        &:hover{
          color: $darkerBrand;
        }
      }
    }
  }
}
.createFirm{
  padding: 2rem;
  background: $white;
  h4 {
    color: $grayLight;
    font-size: 1.8rem;
    font-family: $regular;
    padding: 0 2rem 2rem 0;
  }
  .search-input-list-holder{
    margin-bottom: 1.5rem;
    border: 1px solid #e6e9ed;
    label{
      text-transform: none !important;
    }
  }
}
.empty-content {
  text-align: center;
  margin-top: 5rem;
  font-size: 1.4rem;
}
