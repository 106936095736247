.legal-page {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
  .legal__navigation{
    h1{
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-family: inherit;
      line-height: 1.1;
      font-size: 1.6rem;
      @include transition(.3s all ease-in-out);
      a{
        color: $brand;
        @include transition(.3s all ease-in-out);
        &:hover{
          color: $darkerBrand;
        }
      }
    }
  }
  &__content{
    h1{
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 2rem;
      margin-top: 2rem;
      line-height: 1.1;
    }
    p, span{
      font-family: $regular;
      font-size: 1.2rem;
    }
  }
  @media (min-width: 1200px) {
    .col-lg-offset-2 {
      margin-left: 16.66666667%;
    }
    .col-lg-8 {
      width: 66.66666667%;
      float: left;
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  hr {
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eeeeee;
  }
  h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: inherit;
    line-height: 1.1;
    color: inherit;
  }
  ul,
  ol {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .item {
    display: inline-flex;
    margin-right: 20px;
    font-weight: 500;
    @include breakpoint(toDesktop){
      margin-right: 1rem;
    }
  }

  .item a {
    color: #000000;
    @include breakpoint(toDesktop){
      font-size: 2.5rem;
    }
  }

  .item a:hover, .item.selected a {
    color: $brand;
    border-bottom: $brand 1px solid;
    cursor: pointer;
    padding-bottom: 8px;
    text-decoration: none;
  }

  .terms-privacy > div > h2 {
    letter-spacing: normal;
    font-size: 1.3em;
    text-transform: none;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .terms-privacy > div > p {
    letter-spacing: 0.01em;
    color: #565656;
    line-height: 24px;
  }

  .terms-privacy > .article-title {
    margin-top: 60px;
  }

  .terms-privacy > .article-title {
    margin-top: 60px;
  }

  .terms-privacy > .article-title > h1 {
    font-weight: 300;
    font-size: 2.1em;
    letter-spacing: 0.1em;
    text-align: left;
  }

  .terms-privacy > .article-title > div#info {
    text-align: left;
    font-size: 0.8em;
    color: #b0b4b5;
  }

  .terms-privacy > .article-title > hr {
    margin-top: 36px;
  }

  .terms-privacy {
    background: white;
    padding: 30px;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  #terms-privacy-title {
    text-align: center;
    margin-top: 30px;
    font-size: 28px;
  }

  #terms-privacy-menu {
    box-shadow: 0 10px 10px #e4e9ea;
    position: absolute;
    width: 96.7%;
    padding-top: 20px;
    padding-left: 30px;
    @include breakpoint (toDesktop){
      box-shadow: none;
      width: 93%;
      padding: 2rem 5rem;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  #terms-privacy-row {
    background: #e9edef;
  }
  .ibox {
    font-size: 14px;
  }
}
body {
  @include breakpoint(toDesktop){
    padding-left: 0;
    padding-right: 0;
  }
}
.table_of_content{
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
}
.appendix-table{
  &-center{
    text-align: center;
    display: block;
    h2{
      font-size: 1.8rem;
    }
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  th{
    background-color: #DBE5F0;
    &:first-child{
      width: 5%;
    }
    &:nth-child(2){
      width: 50%;
    }
    &:last-child{
      border-left: 0;
    }
  }
  td{
    width: 50%;
    &:first-child{
      width: 5%;
    }
  }
  td, th {
    border: 1px solid #000;
    text-align: left;
    padding: 8px;
  }
}