/*  Border Box */
@mixin box-sizing($type) {
 -webkit-box-sizing:$type;
 -moz-box-sizing:$type;
 box-sizing:$type;
}
/*  Usage @include box-sizing(border-box); */


/*  Opacity */
@mixin opacity($opacity){
  opacity: $opacity;
  filter: alpha(opacity=($opacity * 100));
}
/*  Usage @include opacity(0.5); */

@mixin disabled() {
    cursor: default !important;
    opacity: 0.5 !important;
    pointer-events: none;
}
