.do-investors {

  .no-hover {
    .media__info-title {
      color: $black;
      display: block;
      text-align: center;
      padding: 1.2rem 1.2rem 1.2rem 5rem;
      &:hover {
        cursor: default;
        color: $black;
      }
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-item {
      flex-basis: 23%;
      width: 23%;
      background-color: $white;
      padding: 0;
      height: 9.9rem;
      display: flex;
      border-radius: 5px;
      .todo-widget__block-right {
        width: 100%;
        flex-basis: 100%;
        &-info {
          &:after {
            display: none;
          }
        }
      }
      .media__info-title {
        margin: 0.6rem 0;
        font-size: 1.2rem;
      }
      .media__info-desc {
        font-size: 1.2rem;
        margin: 0.6rem 0;
      }
      &.greenBlock {
        .media__info-title {
          color: $positive;
        }
      }
      &.blueBlock {
        .media__info-title {
          color: $blue;
        }
      }
      &.yellowBlock {
        .media__info-title {
          color: $yellow;
        }
      }
      &.allBlock {
        .media__info-title {
          &:first-child {
            color: $positive;
          }
          &:nth-child(2) {
            color: $yellow;
          }
          &:nth-child(3) {
            color: $red;
          }
        }
      }
      .media__image {
        width: 8rem;
        height: 8rem;
        border: 0;
        overflow: hidden;
      }
    }
  }
  &__body {
    &-filters {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 3rem 0;

      &-item {
        font-size: 1.4rem;
        font-weight: bold;
        flex-basis: 23%;
        width: 23%;
        padding-bottom: 1rem;
        border-bottom: 1px solid $layerBorder;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $black;
      }
      .todo-widget__filters-older {
        font-weight: 500;
        font-size: 1rem;
        text-transform: uppercase;
      }
    }
    &-investors {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &-item {
        flex-basis: 23%;
        width: 23%;
        max-height: calc(100vh - 46rem);
        overflow-y: auto;
        @include breakpoint(smallLaptop){
          max-height: calc(100vh - 48rem);
        }
        .todo-widget__block-right {
          width: auto;
          flex-basis: auto;
          background-color: $white;
          border-radius: 5px;
          padding: 1.85rem;
          margin-bottom: 1.5rem;
          @media screen and (max-width: 1364px) {
            flex-wrap: nowrap;
          }
          &:last-child{
            margin-bottom: 0
          }
          &-info {
            width: 100% !important;
            &:after {
              display: none;
            }
          }
        }
        &-bottom {
          width: 100% !important;
          margin: 2rem 0 0;
          position: relative;
          padding: 2rem 1rem 0rem 0;
          cursor: default;
          span {
            padding: 0.2rem 0.7rem;
            border-radius: 3px;
            display: inline-block;
            margin-bottom: 0.5rem;
            margin-right: 0.5rem;
            &.shareholder {
              background-color: $black;
              color: $white;
            }
            &.placeorder {
              background-color: $positive;
              color: $white;
            }
            &.feedback {
              background-color: $yellow;
              color: $white;
            }
            &.rejected {
              background-color: $red;
              color: $white;
            }
          }
          &:before {
            position: absolute;
            top: 0;
            left: -2rem;
            right: -2rem;
            height: 1px;
            background-color: $layerBorder;
            content: '';
            display: block;
          }
        }
        .empty-content {
          color: #666;
          font-size: 14px;
          text-align: center;
          padding: 2rem 1rem;
        }
      }
    }
  }
  &__timeline {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: $white;
    padding: 2rem 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    @media screen and (max-width: 1199px) {
      left: 0;
    }
    @include breakpoint(mediumScreen) {
      left: calc(100% - 1100px);
    }
    @include breakpoint(largeScreen) {
      left: calc(100% - 1600px);
    }
    &-big {
      font-size: 1.8rem;
      color: $black;
      font-weight: bold;
    }
    &-small {
      font-size: 1.2rem;
      font-weight: 500;
    }
    &-bar {
      flex-grow: 1;
      margin: 0 2rem;
      position: relative;
      background-color: $darkerWhite;
      font-size: 1.5rem;
      padding: 0.3rem 0;
      text-align: right;
      padding-right: 1rem;
      &-filled {
        background-color: $yellow;
        color: $black;
        position: absolute;
        text-align: right;
        left: 0;
        top: 0;
        bottom: 0;
        line-height: 2.4rem;
        padding-right: 1rem;
        font-weight: 500;
      }
    }
  }
  .highcharts {
    height: 100%;
    width: 100%;
  }
  &--company {
    .do-investors__body-filters-item,
    .do-investors__header-item,
    .do-investors__body-investors-item {
      width: 32%;
      flex-basis: 32%;
      max-height: calc(100vh - 40rem);
      overflow-y: auto;
    }
    .do-investors__header-item {
      .media__info {
        padding-left: 0;
      }
    }
  }
  .noHover {
    text-align: center;
    &:hover {
      background-color: $white;
      color: $black;
    }
  }
}

.do-investment-firm {
  max-height: calc(100vh - 25.7rem);
  overflow-y: auto;
  background-color: transparent;
  .media__info-title {
    word-break: break-word;
  }
  .layer-body__middle-filters-holder-block.box {
    margin-bottom: 2rem;
    background-color: $white;
    border-radius: 5px;
  }
  .layer-body__middle-filters-holder-block {
    > div {
      display: flex;
      align-items: center;
    }
  }
}

.do-approval {
  .buttons__holder {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    a {
      margin: 0 1rem;
    }
  }
  &__block {
    max-height: calc(100vh - 30rem);
    width: 16.032%;
    flex-basis: 16.032%;
    &-item {
      background-color: $white;
      padding: 2rem;
      margin-bottom: 2rem;
      border-radius: 5px;
      h4 {
        font-size: 1.3rem;
        text-transform: none;
        font-weight: 500;
        color: $black;
        position: relative;
        margin-bottom: 2rem;
        text-align: center;
        &:after {
          position: absolute;
          bottom: -1rem;
          left: -2rem;
          right: -2rem;
          height: 1px;
          background-color: #ececec;
          content: '';
          display: block;
        }
      }
    }
  }
  .investor-profile-top-info {
    .layer-body__top-left {
      align-items: center;
    }
    @include breakpoint(tabletScreen) {
      .layer-options {
        flex-basis: 50%;
        width: 50%;
      }
    }
  }
}

.do-investment-firms {
  width: calc(100% - 220px);
  flex-basis: calc(100% - 220px);
  @media screen and (min-width: 1800px) {
    width: calc(100% - 270px);
    flex-basis: calc(100% - 270px);
  }
  padding: 0;
  &-content {
    min-height: calc(100vh - 30rem);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    max-height: calc(100vh - 30rem);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 2rem;
    .media__image-big {
      height: 17.85rem;
    }
    .common__info-box {
      align-items: flex-start;
    }
    .deal-icon {
      border-color: #f2f2f2;
    }
    &__holder {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .media__image {
        border-color: #f2f2f2;
      }
    }
    .do-user__item-content {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
    }
    .common__info-box-left {
      width: 50%;
      flex-basis: 50%;
      @include breakpoint(mediumScreen) {
        width: 35%;
        flex-basis: 35%;
      }
    }
    .common__info-box-right {
      width: 50%;
      flex-basis: 40%;
      text-align: left;
      @include breakpoint(mediumScreen) {
        width: 55%;
        flex-basis: 55%;
      }
      .errorText {
        color: $red;
        font-size: 1.1rem;
        display: block;
        text-align: right;
      }
    }
    .media__image-big {
      padding-bottom: 3.35rem;
      border-radius: 5px;
    }
    .do-user__item-change {
      width: 15.41rem;
      height: 14rem;
      img {
        max-width: inherit;
        max-height: inherit;
        width: 100%;
      }
      span {
        width: 15.6rem;
        bottom: 2.2rem;
        background-color: $buttonColor;
        padding: 0.7rem 0;
        margin: auto;
      }
      input {
        bottom: 2.2rem;
        width: 50%;
        padding: 0.5rem;
        margin: auto;
      }
    }
    #left {
      width: 50%;
      margin-right: 3rem;
    }
    #right {
      width: 50%;
      .peoples__body {
        padding: 0;
      }
      .media__info-title {
        display: inline-block;
      }
      .action__buttons {
        padding-bottom: 0rem;
        text-align: center;
        text-transform: uppercase;
        flex-grow: 1;
        position: absolute;
        right: 1.6rem;
      }
      .bigNumber {
        display: block;
        font-size: 3.6rem;
        font-weight: 100;
        color: #aab2bd;
      }
      .bigText {
        text-transform: uppercase;
        font-size: 1rem;
        color: #aab2bd;
        font-weight: 500;
      }
      .todo-widget__block-right {
        width: auto;
        flex-basis: auto;
        padding: 1.6rem 2rem;
        margin-bottom: 2rem;
        border-radius: 5px;
        position: relative;
        &-info {
          width: 32rem;
        }
      }
      .todo-widget__block-right-info:after {
        display: none;
      }
    }
    .do-user__item-change {
      z-index: 1;
    }
    .do-user__item-image {
      position: relative;
      &:after {
        height: 1px;
        background-color: $borderColor;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 6.5rem;
        content: '';
        display: block;
        z-index: 0;
      }
    }
  }
  .buttons__holder {
    display: flex;
    bottom: 0;
    left: 0;
    right: 0;
    height: 43px;
    box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.05);
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    @include breakpoint (smallLaptop){
      height: 35px;
      padding-top: 3px;
      padding-bottom: 3px;
    }
    a.activeLink {
      border-radius: 3px;
      background-color: $brand;
      cursor: pointer;
      font-size: 1.2rem;
      text-transform: uppercase;
      color: $white;
      padding: 13px 25px;
      margin-right: 25px;
      @include breakpoint (smallLaptop){
        padding: 10px 20px;
      }
    }
  }
}

.do-investors {
  &__one {
    .do-investors__header-item,
    .do-investors__body-filter-item,
    .do-investors__body-filters-item,
    .do-investors__body-investors-item {
      flex-basis: 100%;
      width: 100%;
    }
    .do-investors__header-item:last-child {
      .todo-widget__block-right-info {
        width: 24rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
  &__two {
    .do-investors__header-item,
    .do-investors__body-filter-item,
    .do-investors__body-filters-item,
    .do-investors__body-investors-item {
      flex-basis: 49%;
      width: 49%;
    }
    .do-investors__header-item:last-child {
      .todo-widget__block-right-info {
        width: 24rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }
  &__three {
    .do-investors__header-item,
    .do-investors__body-filter-item,
    .do-investors__body-filters-item,
    .do-investors__body-investors-item {
      flex-basis: 32%;
      width: 32%;
    }
  }
}

.syndicatedMember {
  cursor: default;
  &__title {
    cursor: default;
    :hover {
      color: $smallColor;
    }
  }
  &:hover {
    color: initial;
  }
}

.track-viewed,
.track-approached {
  .todo-widget__block-right {
    &-info {
      width: calc(100% - 65px) !important;
    }
  }
}