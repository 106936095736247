.do-modal-mask{
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(101, 109, 120, 0.5);
  z-index: 1;
}
.do-modal-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  background-color: $white;
  padding: 2rem;
  max-width: 38rem;
  border-radius: 5px;
}
.do-modal-body{
  color: $black;
  font-family: $regular;
  h4 {
    font-weight: 600;
    font-size: 1.3rem;
  }
  p{
    margin: 2rem 0 1rem;
    font-size: 1.1rem;
    line-height: 2.2rem;
    color: #757B85;
    font-weight: 500;
  }
}
.do-modal-footer{
  padding-top: 3rem;
  position: relative;
  &:before{
    content: '';
    display: block;
    top: 1rem;
    left: -2rem;
    right: -2rem;
    position: absolute;
    background-color: $borderColor;
    height: 1px;
  }
  > div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    button{
      text-transform: uppercase;
      border: 0;
      font-size: 1.2rem;
      padding: 0;
      outline: none;
      cursor: pointer;
      font-weight: 500;
      background: $white;
      @include transition(.3s all ease-in-out);
      &.brand{
        color: $color-cyan-secondary;
        &:hover{
          color: $color-cyan-secondary-darker;
        }
      }
      &.red{
        color: $red;
        &:hover{
          color: $darkerRed;
        }
      }
    }
  }
}