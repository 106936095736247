.insights-tab {
    height: calc(100vh - 24rem);
    overflow: hidden;

    .layer-body__middle-filters-search {
        margin: 0 !important;
    }

    @include multiple-media(smallMobileScreen) {
        margin-left: -20px;
        margin-right: -20px;
        margin-top: -20px;
    }

    &.is-stats-tab {
        @include multiple-media(mobileScreen2) {
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    @include multiple-media(smallMobileScreen) {
        .search-box {
            margin-bottom: -20px;
        }
    }

    &.no-shadow {
        box-shadow: none;
    }

    .no-results {
        padding: 15px;
        text-align: center;
        color: $tagBackground;
        background: $white;
        font-size: 1.4rem;
        font-weight: 500;
    }

    &__container {
        width: 100%;
        border-radius: 3px;
        border: 1px solid $borderColor;
        background-color: $white;
        margin-top: 21px;
        display: flex;
        flex-direction: row;
        height: calc(100vh - 28rem);

        @include multiple-media(allMobileScreen) {
            height: calc(100vh - 195px) !important;
        }

        .no-border-right {
            border-right: none !important;
            @include multiple-media(allMobileScreen) {
                width: 100% !important;
            }
        }

        &--block {
            border-right: 1px solid $borderColor;

            h2 {
                height: 22px;
                font-family: $regular;
                font-size: 1.8rem;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $black;
                text-transform: capitalize;
                margin-top: 20px;
                margin-left: 20px;
            }
        }
    }

    .insights-investors {
        width: 39rem;
        background-color: $white;
        border-radius: 3px;
        height: calc(100vh - 37rem);
        overflow: auto;
        @include breakpoint(miniLaptop) {
            width: 180px;
        }
        @include breakpoint(smallLaptop) {
            width: 250px;
        }

        .section-title {
            font-family: $regular;
            font-size: 1rem;
            font-weight: 600;
            color: $black;
            text-transform: uppercase;
            background-color: $darkerWhite;
            height: 25px;
            line-height: 25px;
            padding-left: 20px;
            padding-bottom: 0;
            border: 1px solid $color-grey-17;

            &:first-of-type {
                margin-top: 0;
                margin-bottom: 9px;
            }
        }

        &__access-granted,
        &__access-revoked,
        &__no-access {
            .investors__profile {
                display: block;
                height: 100%;
                border-radius: 3px;
                border: solid 1px $darkerWhiteDO;
                margin-left: 20px;
                margin-bottom: 10px;
                margin-right: 20px;
                padding: 10px;
                cursor: pointer;
                position: relative;

                &.active,
                &:hover {
                    border: solid 1px #c6d2e8;
                    background-color: $selectedLightBlue;
                }

                &--block {
                    display: flex;
                    width: 100%;
                    align-items: center;

                    &-syndicated {
                        position: relative;

                        img {
                            width: 14px;
                            height: 14px;
                            position: absolute;
                            top: -22px;
                            left: -7px;
                            z-index: 1;
                        }
                    }

                    &-image {
                        width: 30px;
                        height: 30px;

                        img {
                            max-height: 100%;
                            max-width: 100%;
                            margin: 50% 0 0 50%;
                            transform: translate3d(-50%, -50%, 0);
                        }
                    }

                    &-description {
                        margin-left: 14px !important;
                        flex-direction: column;
                        margin: auto;
                        max-width: 80%;
                        flex: 1;

                        .investors-name__block {
                            &--title {
                                max-width: 100%;

                                .investor-name {
                                    margin-top: 0;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    font-weight: 500;
                                    color: $titleBlack;
                                }
                            }

                            display: flex;
                            flex-direction: row;
                            font-size: 1.4rem;
                            font-weight: 500;
                            width: 100%;

                            a {
                                font-weight: 500;
                                font-size: 1.4rem;
                                color: $color-grey-6;
                                text-overflow: ellipsis;

                                :hover {
                                    cursor: pointer;
                                }
                            }

                            .badge {
                                position: relative;
                                left: 0;
                                bottom: -2px;
                            }
                        }

                        p {
                            font-size: 1.2rem;
                            margin: 0;
                            color: $dark-gray;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }

    .insights-stats {
        width: 100%;
        background-color: $white;
        position: relative;
        @include breakpoint(smallLaptop) {
            width: calc(100% - 180px);
        }
        @include multiple-media(allMobileScreen) {
            height: calc(100vh - 25rem) !important;
        }

        &__container {
            margin: 0 20px;
            @include multiple-media(allMobileScreen) {
                width: 93%;
            }
            h2 {
                width: 36px;
                height: 18px;
                font-family: $regular;
                font-size: 1.4rem;
                font-weight: 500;
                color: $black;
                text-transform: capitalize;
                letter-spacing: normal;
            }

            .table-responsive {
                margin-bottom: 25px;

                table {
                    width: 100%;
                    border-spacing: 5px;

                    tr {
                        &:nth-child(odd) {
                            height: 37px;
                            background-color: #f5f7fa;
                        }

                        td {
                            height: 37px;
                            width: 50%;
                            padding: 0 15px;
                            @include breakpoint(smallLaptop) {
                                padding: 8px 15px;
                            }
                            @media screen and (min-width: 1280px) and (max-width: 1499px) {
                                padding: 3px 15px;
                            }

                            h5 {
                                height: 17px;
                                font-family: $regular;
                                font-size: 1.2rem;
                                font-weight: normal;
                                font-style: normal;
                                font-stretch: normal;
                                line-height: 1.42;
                                letter-spacing: 0.3px;
                                color: $black;
                                text-transform: none;
                                margin: 0;
                                @media screen and (min-width: 1280px) and (max-width: 1499px) {
                                    width: 90%;
                                    margin-bottom: 5px;
                                }
                            }

                            strong {
                                float: right;
                                height: 17px;
                                font-family: $regular;
                                font-size: 1.6rem;
                                font-weight: 500;
                                line-height: 1.06;
                                letter-spacing: 0.4px;
                                text-align: right;
                                color: $black;
                            }
                        }
                    }
                }
            }

            .breakdown {
                padding: 0 5px;

                &__bottom {
                    &.current-access {
                        max-height: calc(100vh - 52rem);
                        @include breakpoint(laptop) {
                            max-height: calc(100vh - 56rem);
                        }
                        @include multiple-media(allMobileScreen) {
                            max-height: calc(100vh - 40rem);
                        }
                    }

                    &.access-revoked {
                        max-height: calc(100vh - 58rem);
                        @include breakpoint(laptop) {
                            max-height: calc(100vh - 62rem);
                        }
                    }

                    overflow-y: auto;
                }

                &__sort {
                    display: flex;
                    margin-bottom: 10px;

                    .title {
                        width: 45%;
                        flex: 1;
                        @include breakpoint(smallLaptop) {
                            font-size: 38%;
                        }

                        h5 {
                            margin: 0;
                            text-transform: none;
                            height: 18px;
                            font-family: $regular;
                            font-size: 1.4rem;
                            font-weight: 500;
                            color: $black;
                            @include breakpoint(smallLaptop) {
                                font-size: 1.2rem;
                            }
                        }
                    }

                    .sort-items {
                        width: 50%;
                        display: flex;
                        margin-top: 2px;
                        @include breakpoint(smallLaptop) {
                            width: 62%;
                        }

                        .sorted-item {
                            flex: 1;
                            display: flex;
                            justify-content: center;

                            h6 {
                                height: 13px;
                                font-family: $regular;
                                font-size: 1rem;
                                font-weight: 600;
                                margin: 0;
                                text-transform: uppercase;
                                color: $black;
                                cursor: pointer;
                                -khtml-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                                @include breakpoint(smallLaptop) {
                                    font-size: 0.9rem;
                                }
                            }

                            .fa {
                                font-size: 1.4rem;
                                margin-left: 3px;

                                &.fa-sort-asc {
                                    margin-top: 2px;
                                }

                                &.fa-sort-desc {
                                    margin-top: -3px;
                                }
                            }

                            img {
                                max-width: 100%;
                                max-height: 100%;
                                width: 20px;
                                height: 20px;
                                position: relative;
                                bottom: 3px;
                            }
                        }
                    }
                }

                .document {
                    display: flex;
                    flex-direction: column;
                    border-radius: 3px;
                    background-color: $white;
                    border: 1px solid $darkerWhiteDO;
                    margin-bottom: 15px;

                    .general-info {
                        display: flex;

                        .img-and-name {
                            display: flex;
                            flex: 2;

                            .document-img {
                                .fa {
                                    font-size: 2.8rem;
                                }

                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }

                                .pdf-icon {
                                    color: #ff1919;
                                }

                                .doc-icon {
                                    color: #0d5aa7;
                                }

                                .xls-icon {
                                    color: #0d904f;
                                }

                                .ppt-icon {
                                    color: #ce8735;
                                }

                                .image-icon {
                                    color: #deb40e;
                                }

                                padding: 1rem 1.5rem 1rem .5rem;
                                display: inline-flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 3rem;
                            }

                            .document-name-holder {
                                @media screen and (min-width: 1280px) and (max-width: 1499px) {
                                    width: 100%;
                                }

                                .document-name {
                                    padding-top: 5px;
                                    width: 250px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    cursor: pointer;
                                    @media screen and (max-width: 1024px) {
                                        width: 130px;
                                    }
                                    @media screen and (min-width: 1200px) and (max-width: 1799px) {
                                        width: 130px;
                                    }

                                    @include multiple-media(largeScreen) {
                                        width: 350px;
                                    }
                                }

                                h6 {
                                    font-family: $regular;
                                    font-size: 1.4rem;
                                    font-weight: normal;
                                    font-style: normal;
                                    font-stretch: normal;
                                    line-height: normal;
                                    letter-spacing: normal;
                                    color: $black;
                                    margin: 0;
                                    margin-top: 6px;
                                    @include breakpoint(smallLaptop) {
                                        font-size: 1.2rem;
                                        padding-top: 3px;
                                    }
                                }

                                p {
                                    height: 15px;
                                    font-size: 1.2rem;
                                    color: $red-badge;
                                    margin: 0;

                                    &.current-access {
                                        color: $dark-gray;
                                    }

                                    @include breakpoint(smallLaptop) {
                                        font-size: 1.1rem;
                                        padding-bottom: 6px;
                                    }
                                }
                            }
                        }

                        .document-statistics {
                            display: flex;
                            width: 50%;
                            text-align: center;
                            @include breakpoint(smallLaptop) {
                                width: 62%;
                            }

                            div {
                                flex: 1;
                                line-height: 45px;

                                span {
                                    height: 18px;
                                    font-size: 1.4rem;
                                    text-align: center;
                                    color: $black;
                                    @include breakpoint(smallLaptop) {
                                        font-size: 1.2rem;
                                    }
                                }
                            }
                        }
                    }

                    .document-path {
                        display: flex;
                        border-top: 1px solid $borderColor;

                        p {
                            font-size: 1rem;
                            font-weight: 500;
                            color: $smallColor;
                            margin-left: 10px;
                            margin-bottom: 9px;
                        }
                    }
                }
            }
        }

        .give-access {
            display: flex;
            height: 85px;
            width: 100%;
            border-radius: 3px;
            box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.05);
            background-color: $white;
            position: absolute;
            bottom: 0;

            .access-holder {
                margin: 0 20px;
                display: flex;
                width: 100%;
                line-height: 63px;

                .access-info {
                    flex: 2;
                    @include breakpoint(smallLaptop) {
                        flex: 3;
                    }

                    p {
                        height: 18px;
                        font-family: $regular;
                        font-size: 1.4rem;
                        font-weight: 500;
                        color: $black;
                        margin: 0;
                        @include breakpoint(smallLaptop) {
                            font-size: 1.1rem;
                            margin-left: 5px;
                        }
                        @media screen and (min-width: 1280px) and (max-width: 1499px) {
                            font-size: 1.2rem;
                        }
                    }
                }

                .access-info-give {
                    flex: 1;
                    text-align: right;
                    line-height: 63px;

                    button {
                        width: 115px;
                        height: 39px;
                        border-radius: 3px;
                        background-color: $brand;
                        text-transform: uppercase;
                        color: $white;
                        border: none;
                        @include breakpoint(smallLaptop) {
                            font-size: 1rem;
                            width: 90px;
                        }
                    }
                }
            }
        }

        .no-access {
            margin: 0 auto;
            margin-top: 250px;
            width: 265px;
            text-align: center;
            @include multiple-media(allMobileScreen) {
                margin-top: 30px;
            }
            @media screen and (min-width: 1280px) and (max-width: 1499px) {
                margin-top: 150px;
            }

            h3 {
                font-family: $regular;
                font-size: 1.8rem;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.44;
                letter-spacing: normal;
                text-align: center;
                color: $black;
            }

            button {
                width: 115px;
                height: 39px;
                border-radius: 3px;
                background-color: $brand;
                text-transform: uppercase;
                color: $white;
                border: none;
            }
        }
    }

    button {
        cursor: pointer;
    }

    h5 {
        .investor-name {
            max-width: 140px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: auto !important;
            display: inline-block;
            @include breakpoint(smallLaptop) {
                max-width: 120px !important;
            }
        }

        .badge {
            margin-top: -1.5rem;
            @include breakpoint(smallLaptop) {
                margin-top: -1rem;
            }
        }

        &.investorHasNoAccess {
            .badge {
                margin-top: -3.3rem;
                @media not all and (min-resolution: .001dpcm) {
                    margin-top: -1.5rem !important;
                    @include breakpoint(smallLaptop) {
                        margin-top: -1.5rem !important;
                    }
                }
                @include breakpoint(smallLaptop) {
                    margin-top: -3.5rem;
                }
            }
        }
    }
}
.insights-stats-top-bar {
    padding-top: 15px;
    padding-left: 20px;

    table {
        width: 100%;

        td.insights-stats-back-cell {
            width: 40%;

            .insights-stats-back-button {
                font-size: 16px;
                color: $brand;
            }
        }

        td.insights-stats-name-cell {
            width: 60%;

            span {
                font-size: 16px;
                font-family: Nunito-Bold;
                color: #000;
                font-weight: bold;
            }
        }
    }
}
