.suggestions-notification {
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 5px;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  position: relative;
  flex-direction: row;
  border: 1px solid $smallBlockBorder;
  box-shadow: -2px 5px 12px -6px $lightGray;
  &__left {
    width: 165px;
    flex-basis: unset !important;
    height: 78px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 25px;
    border-right: 1px solid $smallBlockBorder;
    position: relative;
    @include breakpoint(smallScreen){
      width: 110px;
    }
    @include multiple-media(mobileScreen, tabletScreen) {
      width: calc(100% - 25px);
      border-bottom: 1px solid $smallBlockBorder;
      border-right: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 35px !important;
    }
    .info {
      font-size: 1.2rem;
      font-weight: 500;
      display: block;
      margin-bottom: 0.5rem;
      color: $color-grey-6;
    }
    .date {
      color: $dark-gray;
      font-size: 1rem;
      font-weight: 500;
      @include multiple-media(mobileScreen, tabletScreen) {
        padding-right: 10px;
      }
    }
  }
  &__right {
    width: calc(100% - 230px);
    min-height: 78px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    @include breakpoint(smallScreen){
      width: calc(100% - 190px);
    }
    @include multiple-media(mobileScreen, tabletScreen) {
      width: calc(100% - 50px);
    }
    .suggestion-data {
      display: flex;
      align-items: center;
      flex-basis: 50%;
      width: 50%;
      &__logo {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $dealBlockColor;
        position: relative;
        background-color: $color-white;
      }
      &__syndicated-logo {
        position: absolute;
        width: 1.9rem;
        height: 1.9rem;
        top: 1rem;
        left: 22.5rem;
        @include breakpoint(bigScreen){
          left: 20.5rem;
        }
        @include breakpoint(smallScreen){
          left: 16.5rem;
        }
        @include multiple-media(mobileScreen, tabletScreen) {
          width: 14px;
          height: 14px;
          left: 1.5rem;
          top: 5rem;
        }
      }
      &__company-info {
        width: auto;
        padding-left: 2rem;
        .title {
          font-size: 1.3rem;
          font-weight: 500;
          color: $color-grey-6;
          display: block;
          margin-bottom: 0.3rem;
          transition: 0.3s all ease-in-out;
        }
        .subtitle {
          font-size: 1rem;
          line-height: 1.3rem;
          font-weight: 600;
          color: $dark-gray;
          display: inline-block;
          padding-right: 2rem;
        }
      }
      .image-holder {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .suggestion-actions {
      margin-left: auto;
      display: flex;
      align-items: center;
      &__dismiss {
        margin-right: 2rem;
      }

      &__dismiss,
      &__preview-deal {
        color: $color-blue-main;
        font-size: 1.2rem;
        font-weight: 600;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        transition: 0.3s all ease-in-out;
        display: inline-block;
        width: auto;
        &:hover {
          color: $darkerBrand;
          transition: 0.3s all ease-in-out;
        }
      }
    }
  }
}