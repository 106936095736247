.compact-traction {
  background-color: $color-compact-background;
  &__content {
    padding: 0 30px;
    max-width: calc(100vw - 6rem);

    @include breakpoint(mediumScreen) {
      max-width: calc(100vw - 51rem);
    }

    @include breakpoint(largeScreen) {
      max-width: calc(1440px - 51rem);
    }

  }
  .stats {
    padding-bottom: 30px;
    &__body {
      display: grid;
      grid-template-columns: 32.5% 32.5% 32.5%;
      grid-row-gap: 3rem;
      grid-column-gap: 1%;
      @include breakpoint(tabletScreen) {
        grid-template-columns: 49% 49%;
        grid-column-gap: 1%;
      }
      @include breakpoint(mobileScreen) {
        grid-template-columns: 100%;
        grid-column-gap: unset;
      }
     &__content {
       &__title {
         font-size: 12px;
         color: $color-grey-10;
         font-weight: 400;
         max-width: max-content;
         word-break: break-word;
       }
       &__value {
         font-size: 14px;
         line-height: 1.73;
         color: $color-grey-6;
         font-weight: 400;
         max-width: max-content;
         word-break: break-word;
       }
     }
    }
    &__table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      @include breakpoint(mobileScreen) {
        max-width: max-content;
        overflow-y: auto;
      }
      &__body {
        background-color: $color-white;
        border: 1px solid $color-grey-2;
      }

      .column {
        font-size: 12px;
        color: $color-grey-10;
        font-weight: 400;
      }
      .row {
        font-size: 14px;
        color: $color-grey-6;
        line-height: 1.86;
        text-align: center;
      }

      .row,
      .column {
        padding: 1.2rem;
        &:first-of-type {
          text-align: left;
          padding-left: 2rem;
        }
      }
    }
    .fundraising-table {
      .column,
      .row {
        width: 25vw;
      }
    }
  }
}
