.equity-deal-compact {
  display: flex;
  flex-direction: column;

  @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
    min-height: 100vh;
    height: 100%;
  }

  @include breakpoint(largeScreen) {
    margin: 0 auto;
  }
  &__body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;

    @include multiple-media(mediumScreen, largeScreen) {
      flex-direction: row;
      min-height: calc(100vh - 54px);
    }

    &__intro {
      display: none;
      &.active {
        display: block;
      }

      @include multiple-media(mediumScreen, largeScreen) {
        display: block;
        position: fixed;
      }
    }

    &__views {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      background-color: $color-white;

      @include multiple-media(mediumScreen, largeScreen) {
        margin-left: 45rem;
      }


      .equity-deal-actions {
        display: flex;
        flex-direction: column;
        margin-top: auto;
        padding: 2rem;
        background-color: $color-white;
        position: fixed;
        bottom: 0;
        width: 100%;
        @include breakpoint(tabletScreen) {
          flex-direction: row;
        }
        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
          flex-direction: row;
        }
        .is-active {
          color: $color-white;
        }
        .btn {
          font-size: 1.3rem;
          line-height: 1.6rem;
          -webkit-appearance: none;
          padding: 1.2rem;
          margin-bottom: 1.5rem;
          min-width: 15rem;
          min-height: min-content;

          &:last-of-type {
            @include breakpoint(mobileScreen) {
              margin-bottom: 0;
            }
          }

          @include breakpoint(tabletScreen) {
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 0 .5rem;
          }

          @include multiple-media(smallScreen, mediumScreen, largeScreen) {
            padding: 1.2rem 1.5rem;
            margin-right: 1rem;
            margin-bottom: 0;
          }
        }

        .canceled {
          color: $darkBlack !important;
          cursor: default;
        }
        .action-dropdown {
          position: relative;

          &:hover .action-dropdown__options {
            display: block;
            left: 0;
            right: 0;
          }
        }
        .disabled {
          color: $color-white;
        }
      }
    }
  }


}
.setHeight {
  height: 100% !important;
}
