.investor-profile-deals-tab {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: calc(100vh - 21rem);
  overflow-x: auto;
  overflow-y: hidden;
  .deals-left-side {
    min-width: 200px;
    max-width: 200px;
    margin-right: 20px;
    max-height: calc(100vh - 10rem);
    overflow: auto;
    @include multiple-media(mobileScreen, tabletScreen) {
      display: none;
    }
    @include breakpoint(largeScreen) {
      min-width: 250px;
      max-width: 250px;
      margin-right: 25px;
    }
    .media__image {
      width: 100%;
      height: 100%;
      border: none;
      &-big {
        height: 150px;
        width: 150px;
        margin: auto;
      }
    }

    .deals-image-holder {
      @include breakpoint(largeScreen) {
        height: 200px;
        width: 200px;
      }
    }
    .layer-body__middle-filters-holder {
      h4 {
        padding: 16px;
        border-bottom: 1px solid $smallBlockBorder;
        font-size: 1.2rem;
        font-weight: 500;
        font-family: $regular;
      }
      .do-profile__item-holder-preferences {
        padding: 0 16px 16px 16px;
        .do-profile__item-info-big {
          font-size: 1.1rem;
          font-weight: 500;
          color: $black;
        }
      }
    }
    .do-user__item {
      padding: 2.5rem;
      &:last-child {
        margin-left: 0;
      }
    }
  }
  .deals-right-side {
    display: flex;
    flex-direction: row;
    width: 100%;
    @include multiple-media(mobileScreen, tabletScreen) {
      width: auto;
    }
  }

  .deals-left-side,
  .deals-right-side {
    position: relative;
    z-index: 0;
  }

  .available-deals,
  .matched-deals {
    display: flex;
    flex-direction: column;
    width: 50%;
    background-color: $color-white;
    border-radius: 3px;
    border: 1px solid $smallBlockBorder;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    @include breakpoint(mobileScreen) {
      width: 320px;
    }
    @include breakpoint(tabletScreen) {
      width: 380px;
    }
    &__head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      border-bottom: 1px solid $smallBlockBorder;
      min-height: 55px;
      max-height: 55px;
      padding: 0 20px;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      @include breakpoint(largeScreen) {
        min-height: 72px;
        max-height: 72px;
      }
      h2 {
        font-family: $regular;
        font-size: 1.6rem;
        font-weight: bold;
        color: $black;
        text-transform: none;
        letter-spacing: normal;
      }
      span {
        @include multiple-media(mobileScreen, tabletScreen) {
          display: none;
        }
      }
    }
    &__bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 63px;
      max-height: 63px;
      width: 100%;
      background-color: $white;
      box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.05);
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      .ladda-button {
        span {
          font-family: $regular;
          font-size: 1.2rem;
          font-weight: 500;
          color: $brand;
        }
      }
    }
    &__content {
      height: 100%;
      overflow-y: hidden;
    }
    &__content__company-list {
      height: calc(100% - 4rem);
      padding: 2rem;
      overflow: auto;
    }
    .draggable {
      position: relative;
      min-height: 50%;
      .edit {
        &__investor-preferences-parent {
          cursor: grab;
          &.sortable-chosen {
            cursor: grabbing;
          }
        }
      }
    }
  }

  .matched-deals {
    margin-left: 20px;
    &__content__company-list {
      padding: 0;
      height: 100%;
      .draggable {
        padding: 2rem;
        min-height: calc(50% - 70px);
      }
      .subtitle {
        font-weight: bold;
        background-color: $darkerWhite;
        padding: 6px 0 6px 19px;
      }
    }
  }
}

.available-investor {
  overflow: auto !important;
  height: calc(100vh - 38rem) !important;

  &__block {
    padding: 2rem 2rem 2rem 2rem;
    min-height: 7rem;
    &#shortlisted {
      & > div {
        min-height: 7rem;
      }
    }
    .draggable-wrapper-max-height {
      min-height: calc(100vh - 52rem);
    }

    .todo-widget__block-right {
      width: 65% !important;

      @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        width: 78%;
      }
    }

  }

  .subtitle {
    font-weight: bold;
    background-color: $darkerWhite;
    margin-bottom: 2px;
    padding: 6px 0 6px 19px;
    font-size: 1rem;
    text-transform: uppercase;
    cursor: default;
    user-select: none;

    i {
      color: $color-blue-main;
    }
  }
}

.matched-investor-dealTab {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .matched-investors-deal {
        overflow: hidden;
        height: inherit;
        display: flex;
        flex-direction: column;

        &--right {

            .todo-widget__block-right {
                width: 65% !important;

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    width: 78%;
                }
            }

            .do-hubx-deals-block {
                height: auto;
            }

            .draggable-wrapper-max-height {
                min-height: calc(100vh - 36rem) !important;
                display: flex;
                flex-grow: 1;
                flex-direction: column;
            }
        }
    }
}
