.profile-container > header {
    box-shadow: 0px 18px 16px 0px rgba(0, 0, 0, 0.02);
    padding: 25px 40px;
}

.profile-container > header > span {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #434a54;
}

.profile-container > section {
    padding: 25px 40px;
}

.profile-container > section > div,
.profile-container > section > form > div {
    margin-bottom: 20px;
}

.profile-container > section > div > header > span,
.profile-container > section > form > div > header > span {
    color: #434a54;
    font-size: 12px;
    font-weight: 500;
}

.profile-container > section > div > header > hr,
.profile-container > section > div > hr,
.profile-container > section > form > div > header > hr,
.profile-container > section > form > div > hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #e6e9ed;
}

.left-size {
    margin-left: 26px;
}

.fix-w {
    width: 48%;
}

.profile-container > section > div > p,
.profile-container > section > form > div > p {
    color: #434a54;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
}
.add-input {
    position: relative;
}

.enter-btn {
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid #e6e9ed;
    border-radius: 5px;
    background-color: #fff;
    position: absolute;
    right: 6px;
    bottom: 6px;
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.enter-btn > i {
    font-size: 16px;
    color: #656d78;
}

.profile-container > section > div > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.profile-container > section > div > ul > li {
    color: #434a54;
    font-size: 13px;
    font-weight: 400;
    margin: 15px 0px;
}

.profile-container > section > div > ul > li > input {
    margin-right: 10px;
}

.profile-container > section > form textarea {
    background-color: #f5f7fa;
    border-radius: 5px;
    border: 1px solid #e6e9ed;
    font-size: 12px;
    padding-left: 20px;
    padding-top: 10px;
    color: #434a54;
    font-weight: 200;
    letter-spacing: 1px;
    line-height: 20px;
    resize: vertical;
}

.profile-container > section > form textarea:focus {
    background-color: #fff;
    outline: none;
}

.input-gr > div > select {
    background-color: #f5f7fa;
    border-radius: 5px;
    height: 45px;
    border: 1px solid #e6e9ed;
    font-size: 14px;
    color: #434a54;
    font-weight: 200;
    text-indent: 14px;
    
}

.input-gr > div > select:focus {
    outline: none;
}

.scans {
    max-height: 120px;
    box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.19);
}

.input-gr select.document-select {
    background-color: #f5f7fa;
    border-radius: 5px;
    height: 30px;
    border: 1px solid #e6e9ed;
    font-size: 14px;
    color: #bdc2ca;
    width: 56%;
    font-weight: 200;
    text-indent: -1px;
    margin-bottom: 11px;
    margin-left: 5px;
}

.investor-profile-file-upload-label {
    font-size: 12px;
    color: #ccd1d9;
    margin-bottom: 5px;
    line-height: 20px;
    margin-top: 15px;
    display: block;
}
.input-gr.documents-holder form > div {
    background-color: #f5f7fa;
    border-radius: 5px;
    height: 200px;
    border: 1px solid #e6e9ed;
    margin-bottom: 13px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
}
.selectize-input.not-full, .selectize-input.full {
    background-color: #f5f7fa!important;
    border: 1px solid #e6e9ed;
    line-height: 35px;
}
.selectize-input .item {
    font-size: 20px;
    color: #434a54;
    font-weight: 200;
}
.selectize-dropdown {
    background-color: #f5f7fa!important;
}
.scroller {
    overflow-y: scroll;
    height: 17rem;
}
.sliderbox {
    height: 20.8rem;
}

.vue-slider-component .vue-slider{
    padding: 0;
    background-color: #f7f7fc !important;
}
.vue-slider-component.vue-slider-horizontal .vue-slider-process {
    padding: 0;
    background-color: #EF7421;
}
.vue-slider-component .vue-slider-dot {
    padding: 5px;
    top: -5px !important;
    background-color: #EF7421 !important;
    box-shadow: none !important;
}
.vue-slider-component.vue-slider-horizontal-reverse .vue-slider-piecewise-label, .vue-slider-component.vue-slider-horizontal .vue-slider-piecewise-label {
    font-size: 8px !important;
}
.vue-slider-component .vue-slider-piecewise-dot {
    height: 9px !important;
}
.vue-slider-component .vue-slider-piecewise-item:first-child .vue-slider-piecewise-dot, .vue-slider-component .vue-slider-piecewise-item:last-child .vue-slider-piecewise-dot {
    visibility: visible !important;
}