@mixin fontFace($family,$src,$style: normal,$weight: normal) {
    @font-face {
        font-family: $family;
        src: url('#{$src}.eot'); // IE9 compat
        src: url('#{$src}.eot?#iefix') format('embedded-opentype'), // IE8 and below
        url('#{$src}.woff') format('woff'), // standards
        url('#{$src}.ttf') format('truetype'), // Safari, Android, iOS
        url('#{$src}.svg##{$family}') format('svg'); // legacy iOS

        font-style: $style;
        font-weight: $weight;
    }
}

@font-face {
    font-family: "Nunito";
    src: url(../fonts/Nunito-Regular.ttf) format("truetype");
}
@font-face {
    font-family: "NunitoBold";
    src: url(../fonts/Nunito-Bold.ttf) format("truetype");
}
@font-face {
    font-family: "NunitoItalic";
    src: url(../fonts/Nunito-Italic.ttf) format("truetype");
}

// Example to use
// @include fontFace('Montserrat Black','../_common/fonts/Montserrat-Black');
//
// $montserratBlack : 'Montserrat Black', sans-serif;

@mixin break-words-nicely {
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    @-moz-document url-prefix() {
        overflow-wrap: break-word;
        hyphens: auto;
    }
}
/*
NOTE: !IMPORTANT-s IN MIXINS ARE NECESSARY BEFORE PAGES
    CONTAINING FORCED RULES ARE REFACTORED
TODO: 1. WHEN SCSS IS CONSOLIDATED, REMOVE IMPORTANTS!
      2. JOIN quill-typography MIXIN WITH VARIABLE
        BREAKPOINTS WHEN REFACTORING
*/
@mixin quill-typography-mobile-tablet {
    h1 { font-size: 2.4rem !important; }
    h2 { font-size: 2rem !important; }
    h3 { font-size: 1.8rem !important; }
    h4 { font-size: 1.6rem !important; }
    h5 { font-size: 1.4rem !important; }
    h6 { font-size: 1.2rem !important; }
    h1, h2, h3, h4, h5, h6 {
        text-align: left !important;
        margin: .5rem 0 1rem !important;
        @include  break-words-nicely;
    }
    p, span, i, a, b, strong, u, em, mark, small, del, ins, sub, sup {
        font-size: 1.2rem !important;
        @include  break-words-nicely;
    }
    a {
        color: $brand !important;
        @include  break-words-nicely;
    }
    a[style],
    a[style] * {
        color: $brand !important;
        background-color: transparent !important;
        @include  break-words-nicely;
    }
    p, span, i, a, b, strong, u, em, mark, small, del, ins, sub, sup, p * {
        color: $black !important;
        line-height: 1.83 !important;
        @include  break-words-nicely;
    }
    ul, ol {
        padding: 0;
        padding-left: 15px;
        li {
            padding-left: 24px;
            font-size: 1.2rem !important;
            @include  break-words-nicely;
        }
    }
}
/*
TODO:   JOIN quill-typography MIXIN WITH VARIABLE
        BREAKPOINTS WHEN REFACTORING
*/
@mixin quill-typography-desktop {
    h1, h2, h3, h4, h5, h6 {
        text-align: left;
        margin: 5rem 0 3rem;
        font-size: 1.8rem;
        @include  break-words-nicely;
    }
    p, span, i, a, b, strong, u, em, mark, small, del, ins, sub, sup, blockquote {
        font-size: 1.2rem;
        line-height: 1.83;
        @include  break-words-nicely;
    }
    p {
        margin-bottom: 2rem;
    }
    a {
        color: $color-blue-main;
        font-size: 1.2rem;
        background-color: transparent;
        line-height: 1.83;
        @include  break-words-nicely;
    }
    a[style],
    a[style] * {
        /* NOTE: Must be "!important" because it is set to override inline styles! */
        color: $color-blue-main !important;
    }
    p, span, i, b, strong, u, em, mark, small, del, ins, sub, sup, blockquote {
        color: $color-grey-6;
    }
    ul, ol {
        padding: 0 0 0 1.5rem;
        li {
            padding: 0 0 0 2.4rem;
            font-size: 1.2rem;
            @include  break-words-nicely;
        }
    }
}

//================================================
/*
    MIXIN FOR TRUNCATING TEXT (AT CUSTOM WIDTH)

    USAGE:
                    @include text-ellipsis(value);

              NOTE: "value" IS CUSTOM VALUE(ANY LENGTH UNIT) WHERE YOU PLAN TO APPLY ELLIPSIS EG. @include text-ellipsis(100px)...will truncate text at 100px.

 */
@mixin text-ellipsis($width) {
    max-width: $width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
//================================================
