.compact-navigation {
	position: fixed;
	top: 0;
	left: 0;
	background-color: $color-white;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-bottom: .1rem solid $color-grey-1;


	@include multiple-media(mediumScreen, largeScreen) {
		flex-direction: row;
		height: 53px;
	}

	@include breakpoint(largeScreen) {
		margin: 0 auto;
	}

	&__back-section {
		flex-grow: 1;
		height: 60px;
		border-right: 0;
		box-shadow: none;
		display: flex;
		justify-content: flex-start;
		box-sizing: content-box;
		margin-right: 6rem;
		@include multiple-media(mediumScreen, largeScreen) {
			min-width: 45rem;
			width: 45rem;
			box-shadow: inset -.6rem 0 1.1rem 0 rgba(0, 0, 0, 0.1);
			margin-right: 0;
			flex-grow: 0;
			height: auto;
		}
		&__button {
			width: 6rem;
			&:hover {
				cursor: pointer;
			}
			.material-icons {
				display: inline-flex;
				width: 100%;
				height: 100%;
				color: $color-blue-main;
				font-size: 25px;
				font-weight: 700;
				justify-content: center;
				align-items: center;
			}
		}

		.ml-6 {
			margin-left: 6rem;
		}

		&__items {
			margin: 0 auto;
			display: flex;
			flex-grow: 1;
			align-items: center;

			@include multiple-media(mediumScreen, largeScreen) {
				margin: 0;
			}

			&__logo {
				height: 3rem;
			}

			&__name {
				display: none;

				@include multiple-media(mediumScreen, largeScreen) {
					display: inline-block;
					margin-left: 2rem;
					font-size: 14px;
					font-weight: 500;
					color: $color-grey-6;
				}
			}
		}



	}
	&__tabs {
		flex-grow: 1;
		background-color: $color-white;

		&__items{
			display: flex;
			justify-content: space-around;
			margin: 0;
			height: 100%;
			padding: 0;

			@include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
				justify-content: flex-start;
			}

			&__item {
				display: flex;
				align-items: center;
				margin: 0;
				position: relative;

				@include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
					margin: 0 30px;
				}

				.navigation-tab {
					font-size: 14px;
					font-weight: 500;
					font-style: normal;
					color: $color-grey-6;
					padding: 2rem 0;
					cursor: pointer;

					&.router-link-active,
					&.router-link-exact-active {
						border-bottom: 1px solid $color-blue-main;
						color: $color-blue-main;
						padding: 2rem 0;

						@include multiple-media(largeScreen) {
							padding: 18px 0;
						}
					}
					&:hover {
						color: $brand;
					}
				}
			}
		}
	}
	&__menu-button {
		position: absolute;
		right: 0;
		height: 6rem;
		width: 6rem;
		display: flex;
		&:hover {
			cursor: pointer;
		}
		.material-icons {
			display: flex;
			width: 100%;
			height: 100%;
			color: $color-blue-main;
			font-size: 30px;
			font-weight: 500;
			justify-content: center;
			align-items: center;
		}

		&.bigResolution {
			display: none;

			@include breakpoint(largeScreen) {
				display: flex;
				position: fixed;
			}
		}
	}

	.compact-dropdown {
		position: absolute;
		border: solid 1px $smallBlockBorder;
		list-style: none;
		padding: 0;
		background-color: $color-white;
		z-index: 10;
		top: 0;
		left: -4.5rem;
		border-radius: 5px;
		box-shadow: 0 1px 0 0 $smallBlockBorder;
		min-width: 17rem;

		&.arrow-top::before,
		&.arrow-top::after {
			content: '';
			display: block;
			position: absolute;
			bottom: 100%;
			width: 0;
			height: 0;
		}
		&.arrow-top:before {
			left: 46.5%;
			border: 8px solid transparent;
			border-bottom-color: $smallBlockBorder;
		}
		&.arrow-top:after {
			left: 47%;
			border: 7px solid transparent;
			border-bottom-color: $color-white;
		}

		&.dropdown-dataRoom {
			@include breakpoint(mobileScreen) {
				left: -7rem;
			}
		}

		&__item {
			padding: 1rem 2rem;
			border-bottom: 1px solid $smallBlockBorder;
			cursor: pointer;

			&:hover {
				background-color: $color-blue-light-hover;
			}

			&.disabled {
				pointer-events:none;
				cursor: default;
				opacity:0.6;
			}
		}

		&__deal {
			font-size: 14px;
			font-weight: 300;
			color: $color-blue-main;
			margin-bottom: 0.5rem;
			&.noDataroom {
				color: $color-grey-6;
			}
		}

		&__data {
			display: flex;
			justify-content: space-between;

			&__block {


				&__title {
					color: $color-grey-6;
					font-size: 12px;
				}

				&__value {
					color: $color-grey-6;
					font-size: 12px;
					font-weight: 800;
				}
			}

		}
	}
}