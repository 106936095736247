.sidebar{
  .multiselect{
    &__input{
      border: 2px solid $white;
    }
    &__content {
      border: 2px solid $white;
    }
  }
  &__navigation{
    width: 19.5%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: $black;
    z-index: 10;
    &-scroll {
      overflow-y: auto;
      max-height: calc(100vh - 11rem);
      @include breakpoint(smallLaptop) {
        max-height: calc(100vh - 14rem);
      }
    }
    &.topnav {
      top: 50px !important;
    }
    .multiselect{
      &__element{
        margin-bottom: 0;
        border-bottom: 1px solid $layerBorder;
        &:last-child{
          border-bottom: 0;
        }
      }
    }
    @include breakpoint (tablet){
      width: 20.5%;
    }
    @include breakpoint (phone){
      width: 20.5%;
    }
    &-title{
      padding: 3rem;
      color: $white;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 1.2rem;
      font-weight: 500;
      padding-left: 2.5rem;
      padding-bottom: 2.4rem;
      @include breakpoint (phone){
        padding: 3rem 0.5rem;
      }
    }
    ul{
      margin-top: 0;
      max-height: calc(100vh - 15rem);
      overflow-y: hidden;
      overflow-x: hidden;
      padding-left: 2.5rem;
      @include breakpoint (phone){
        padding-left: 0;
      }
      li{
        list-style: none;
        margin-bottom: 2.3rem;
        font-size: 1.4rem;
        @include breakpoint(smallLaptop) {
          margin-bottom: 2rem;
        }
        a{
          color: $smallColor;
          font-size: 1.5rem;
          font-weight: 500;
          vertical-align: middle;
          &.greenText{
            color: $green;
          }
          @include breakpoint (phone){
            font-size: 1.6rem;
          }
        }
        cursor: pointer;
        width: fit-content;
      }
      li:last-child{
        margin-bottom: 0.1rem;
      }
    }
    input{
      &:focus{
        border: 2px solid $white !important;
        outline: none;
      }
    }
    .circle{
      border: 1px solid $smallColor;
      height: 3rem;
      border-radius: 50%;
      width: 3rem;
      vertical-align: middle;
      display: inline-block;
      margin-right: 2rem;
      vertical-align: middle;
      @include breakpoint (tablet){
        margin-right: 1rem;
      }
      @include breakpoint (phone){
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
      }
      &.filled{
        border-color: $colorCheck;
        &::before{
          color: $colorCheck;
          padding: 0.3rem;
          display: inline-block;
          font-size: 2.5rem;
          @include breakpoint (toDesktop){
            font-size: 1.5rem;
          }
          @-moz-document url-prefix() {
            @include multiple-media(smallLaptop, bigLaptop) {
              padding: 0 0.2rem;
            }
            @include breakpoint(bigScreen) {
              padding: 0 0.3rem;
            }
          }
        }
      }
    }
    &-footer{
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: $white;
      margin-right: 0.1rem;
      text-align: right;
      padding: 2.5rem 1.3rem 2.5rem 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include transition(.3s all ease-in-out);
      @include breakpoint (phone){
        padding: 2.5rem 0;
      }
      .footer-close-button {
        div {
          display: inline-block;
          vertical-align: middle;
          line-height: 23px;
        }
      }
      button,
      .footer-close-button {
        background: none;
        outline: none !important;
        border: 0 !important;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.2rem !important;
        font-weight: 500;
        margin: 0 1.75rem;
        cursor: pointer !important;
        padding: 0 !important;
        text-transform: uppercase;
        .ladda-label{
          background-color: transparent !important;
        }
        &.ladda-button{
          background-color: transparent !important;
        }
        .ladda-spinner {
          left: -1.5rem !important;
          > div{
            div{
              background-color: $black !important;
            }
          }
        }
        &:hover{
          background: transparent !important;
        }
        @include breakpoint (phone){
          margin-left: 0;
          text-align: left;
          font-size: 1.9rem;
          display: block;
        }
      }
      &-red{
        color: $red;
      }
      &-black{
        color: $black;
        .ladda-label{
          color: $black;
        }
      }
      &-brand{
        color: $brand;
        .ladda-label{
          color: $brand;
        }
      }
      &-green{
        color: $positive;
        .ladda-label{
          color: $positive;
        }
      }
      &-disabled{
        color: $color-grey-22;
        .ladda-label{
          color: $color-grey-22;
        }
      }
      .hide-button{
        z-index: -1;
      }
      .button-disabled{
        cursor: default;
        color: $color-grey-22 !important;
      }
      .dropdown-content {
        display: none;
        width: 33rem;
        height: auto;
        border-radius: 6px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        background-color: $color-white;
        border: solid 1px #e7e9ed;
        position: absolute;
        bottom: 4rem;
        left: auto;
        .header {
          height: 4.5rem;
          border-radius: 3px;
          background-color: $color-white;
          border: solid 1px #d2d4d8;
          .title {
            padding: 1.5rem 2rem;
            font-family: 'Montserrat', sans-serif;
            font-size: 1.2rem;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: justify;
            text-transform: uppercase;
            color: $color-grey-6;
          }
        }
        .content {
          ul {
            padding-left: 2rem;
            padding-top: 2rem;
          }
          .line {
            width: 280px;
            font-family: 'Montserrat', sans-serif;
            font-size: 1.2rem;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: left;
            color: $color-grey-6;
            margin: 0 0 1rem;
            span {
              font-weight: normal;
            }
          }
        }
        .arrow-down {
          right: 8rem;
          width: 0;
          height: 0;
          position: absolute;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        }
        .arrow-down:after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          margin-left: 3.2em;
          bottom: -2em;
          left: 50%;
          box-sizing: border-box;
          border: 1em solid rgba(0, 0, 0, 0.1);
          border-color: transparent transparent #fff #fff;
          transform-origin: 0 0;
          transform: rotate(-45deg);
          box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.1);
        }
      }
      &-positive{
        color: $positive;
        .ladda-label{
          color: $positive;
        }
      }
      &.moveFooter{
        bottom: -7rem;
      }
    }
    .ladda-spinner{
      left: -22% !important;
      top: 50% !important;
      div{
        div{
          div{
            background-color: $black !important;
          }
        }
      }
    }
  }
}
