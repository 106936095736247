.investor-onboarding {
  height: 100vh;
  overflow: hidden;
  display: flex;
  background: none;
  width: 100%;
  font-family: $regular;

  &__background {
    background-color: white;
    flex-grow: 1;
    background-size: cover;
    background-position: inherit;
    background-repeat: no-repeat;
    .banner {
        min-height: 300px;
        background-position: center;
        background-origin: content-box;
        background-color: #434a54;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .logo {
        width: 150px;
        height: 150px;
        position: absolute;
        left: 100px;
        top: 200px;
        background-size: contain;
        background-color: $white;
        background-repeat: no-repeat;
        background-position: center center;
        box-shadow: 10px 5px 5px #0000004f;
        border: 10px solid white;
        display: flex;
        justify-content: center;
        align-items: center;

        &-image {
            max-width: 15rem;
        }
    }
    @include multiple-media(mediumScreen, largeScreen) {
      background-position: center;
    }

  }

  &__layer-body {
    display: flex;
    flex-direction: column;
    margin: 0;
    background-color: $color-white;
    overflow: auto;
    flex: 0 0 68rem;
    box-shadow: 0 1.1rem 0.8rem 0 $lightGray;

    &__disclaimer-wrapper {
      display: flex;
      align-self: end;
    }

    &__disclaimer {
      margin: 40px;
      font-family: "Montserrat", sans-serif;
      font-size: 1.3rem;
      font-weight: normal;
      color: #434a54;
    }

    @include multiple-media(mobileScreenLandscape, smallDevices) {
      flex: 0 0 100%;
    }


    &__status {
      display: flex;
      padding: 1.5rem 10% 4.6rem 10%;
      background-color: $color-grey-9;
      align-items: center;
      justify-content: space-around;
      position: relative;


      @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        padding: 3rem 10% 5.6rem 10%;
      }

      @media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
        min-height: 6.5rem;
      }

      &__item {

        &--icon{
          background-color: transparent;
          border: .1rem solid $color-grey-8;
          border-radius: 100%;
          width: 3rem;
          height: 3rem;
          text-align: center;
          font-size: 1.4rem;
          margin: 0 auto;



          i {
            position:relative;
            top: calc(50% - 1rem);
            color: $color-grey-8;
          }

          @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            width: 7rem;
            height: 7rem;
            font-size: 1.8rem;

            .fa-lock {
              font-size: 2rem;
            }
          }

        }

        &--title {
          position: absolute;
          font-size: 1.1rem;
          text-align: center;
          color: $color-grey-6;
          margin-left: .2rem;
          margin-top: .5rem;
          display: table-cell;
          text-transform: capitalize;

          @include multiple-media(tabletScreen, mediumScreen, largeScreen) {
            font-size: 1.4rem;
            margin-top: 1rem;
          }

          @include multiple-media(smallScreen, tabletScreen) {
            font-size: 1.2rem;
          }

          .break-title {
            display: none;
          }

          @include breakpoint(mobileScreen) {
            .break-title {
              display: block;
            }
          }

        }

      }

      & .divider {
        margin-left: 0;
        flex-grow: 1;
        border-bottom: .1rem solid $color-grey-8;
      }

      .next-step-divider {
        border-bottom: .1rem solid $color-blue-main;
        background-color: transparent;
      }

      & :not(:last-child) {

        span {
          margin-left: -.8rem;
          display: table-cell;

          @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            margin-left: -2rem;
          }
        }
      }

      .next-step {
        border: .1rem solid $color-blue-main;
        background-color: transparent;

        i {
          color: $color-blue-main;
        }

      }

      .active-step {
        border: .1rem solid $color-blue-main;
        background-color: $color-blue-main;

        i {
          color: $color-white !important;
        }
      }


      &--divider {
        flex-grow: 1;
        border-bottom: .1rem solid $color-grey-8;
      }
    }

    &__steps {
      padding: 2rem 2rem;
      flex: 1;

      @include multiple-media(smallScreen) {
        padding:  3rem 4rem;
      }

      @include multiple-media(mediumScreen, largeScreen) {
        padding:  5rem 4rem;
      }
    }
  }

  &__logo {
    align-self: center;
    margin-bottom: 6rem;
    margin-top: 5rem;

    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
      margin-bottom: 4rem;
    }

    &--image {
      max-width: 15rem;
      height: 5rem;
      object-fit: contain;
      @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        max-width: 20rem;
        height: 10rem;
      }
    }
  }
}

.investor-onboarding-exotix {

  .investor-onboarding__background {
    background-position: 0 0;

    @include multiple-media(toDesktop) {
      background-position: 14% 0;
    }
  }

  .investor-onboarding__layer-body {
    border-left: 1px solid #e7e7e7;
  }

}

.exit-preview-container {
    position: relative;
    left: 0;
    cursor: pointer;
    img {
        width: 40px;
        background-color: white;
    }
}


