.inv_advisors-content {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  h4 {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #434a54;
    margin-bottom: 7px;
    margin-top: 5px;
    @include breakpoint(bigScreen) {
      font-size: 16px;
    }
  }
  .inv_advisors {
    flex-basis: 35%;
    .todo-widget__block-right-image {
      float: left;
    }
    .media__info-title {
      padding-left: 55px;
      width: 200px;
      margin-top: 9px;
      font-size: 12px;
      @include breakpoint(bigScreen) {
        font-size: 14px;
        width: 230px;
      }
    }
  }
  .inv_advisors-box {
    border: 1px solid $smallBlockBorder;
    -webkit-box-shadow: -2px 5px 12px -6px $lightGray;
    box-shadow: -2px 5px 12px -6px $lightGray;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 2rem;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 2rem;
    -ms-flex-wrap: wrap;
    position: relative;
  }
  .inv_advisors-contact {
    float: left;
    margin-right: 6px;
    color: $brand;
    font-size: 12px;
    cursor: default;
    @include breakpoint(bigScreen) {
      font-size: 14px;
    }
  }
  .inv_advisors-contact-holder {
    @include breakpoint(bigScreen) {
      width: 330px;
    }
    @include breakpoint(toBigPhone) {
      width: 150px;
    }
    width: 220px;
    float: left;
  }
  .advisors-btn {
    -webkit-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    font-size: 12px;
    @include breakpoint(bigScreen) {
      font-size: 14px;
    }
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    &.btn-blue{
      color: $brand;
    }
    &.btn-disabled{
      color: #1f7be8;
      opacity: 0.2;
      cursor: default;
    }
    &.btn-decline{
      color: $red;
    }
    &.btn-confirm{
      color: $positive;
    }
    &.btn-remove{
      color: $black;
    }
  }
  .inv_middle {
    flex-basis: 50%;
  }
  .inv_middle-block {
    @include breakpoint(smallLaptop) {
      flex-basis: 42%;
    }
    @include breakpoint(laptop) {
      flex-basis: 47%;
    }
    @include breakpoint(tabletPortrait) {
      flex-basis: 35%;
    }
    @include breakpoint(tabletLandscape) {
      flex-basis: 42%;
    }
    flex-basis: 32%;
  }
  .inv_advisors_action {
    @include breakpoint(bigScreen) {
      width: 400px;
    }@include breakpoint(toBigPhone) {
       width: 400px;
     }
    width: 200px;
    text-align: right;
    a {
      &:last-child {
        float: right;
      }
      &:first-child {
        margin-right: 15px;
        @include breakpoint(toBigPhone) {
          margin-right: 0;
          float: none;
        }
      }
    }
  }
}