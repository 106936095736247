.do-modal{
    label{
      display:block;
      margin-bottom: 1rem;
      color: $smallColor;
      font-size: 1.1rem;
      font-weight: 600;
      @include breakpoint (toDesktop){
        font-size: 2.2rem;
      }
    }
    .layer-body__top-left{
      display: block !important;
      @include breakpoint (toDesktop){
        width: 100% !important;
        flex-basis: 100% !important;
      }
    }
    &__content{
      width: 100%;
      flex-basis: 100%;
    }
    .layer-body__top{
      border: 0;
    }
    form{
      background: $white;
      padding: 2rem;
      border-radius: 5px;
      &.change-password {
        .hubx-inputs {
            margin-bottom: 25px;
        }
        @media screen and (max-height: 660px) {
          padding-top: 0;
        }
      }
    }
    button {
      background-color: $white;
      color: $brand;
      padding: 2.6rem 4rem;
      text-transform: uppercase;
      cursor: pointer;
      font-family: $regular;
      border: 0;
      font-size: 1.2rem;
      border-radius: 2px;
      outline: none;
      font-weight: 600;
      position: fixed;
      bottom: 0;
      right: 0;
      text-align: center;
      @include transition(.3s all ease-in-out);
      &.input-gr-o {
        &:hover {
          background-color: $white;
          color: $brand;
        }
      }
      &:hover{
        background-color: $brand;
        color: $white;
      }
      &.advisor-send-btn {
        width: 680px;
        left: calc(100% - 680px);
        @media screen and (max-width: 899px) {
          width: 540px;
          left: calc(100% - 540px);
        }
      }
    }
    .investor-account-btn {
      width: 680px;
      left: calc(100% - 680px);
      @media screen and (max-width: 899px) {
        width: 540px;
        left: calc(100% - 540px);
      }
    }
    .submit__special{
      position: fixed;
      bottom: 0;
      right: 0;
      left: calc(100% - 680px);
      background: $white;
      text-align: center;
      width: 680px;
      @media screen and (max-width: 899px) {
        width: 540px;
        left: calc(100% - 540px);
      }
      button{
        background-color: $white;
        color: $brand;
        width: 100%;
        padding: 2rem;
        font-size: 1.2rem;
      }
    }
    .multiselect {
      @extend .input;
      padding: 0;
      &__input{
        border: 0;
        color: $black;
        font-family: $regular;
        font-size: 1.2rem;
        font-weight: 600;
        &::placeholder{
          font-style: italic;
          color: $smallColor;
        }
        @include breakpoint (toDesktop){
          font-size: 16px;
        }
      }
      &__option{
        padding: 1rem;
      }
      &__select{
        &:before{
          top: 50%;
          border-color: $black transparent transparent;
        }
        @include breakpoint (toDesktop){
          top: 2.5rem;
        }
      }
    }
}
.color__picker{
  position: relative;
  .common__info-box-left{
    margin-bottom: 1rem;
  }
  .color-picker{
    display: flex;
    align-items: center;
    border: 1px solid $borderColor;
    padding: 1rem;
    width: 30rem;

  }
  .vc-chrome {
    position: absolute;
    top: 10.5rem;
    left: 0;
    z-index: 9;
    width: 31rem;
  }
  .current-color {
    display: inline-block;
    width: 4rem;
    height: 4rem;
    background-color: $black;
    cursor: pointer;
    border-radius: 5px;
  }
  input{
    padding: 1.5rem 1rem;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    outline: none;
    border: 0px;
    font-weight: 600;
    color: $black;
  }
  .vc-input__input,
  .vc-chrome-toggle-icon,
  .vc-input__label{
    display: none;
  }

  .vc-chrome-fields-wrap {
    display: none;
  }
}
.common__save{
  border: 0;
  background-color: transparent;
  display: block;
  text-align: right;
  width: 100%;
  font-style: italic;
  font-size: 1rem !important;
  cursor: pointer;
  margin-top: 1rem;
  color: $saveButton !important;
  font-weight: 500;
}
.common__submitForm{
  position: fixed;
  bottom: 0;
  left: calc(100% - 680px);
  right: 0;
  display: block;
  background-color: $white !important;
  color: $brand !important;
  cursor: pointer;
  border-radius: 0;
  width: 680px;
  @media screen and (max-width: 899px) {
    width: 540px;
    left: calc(100% - 540px);
  }
  @include transition(.3s all ease-in-out);
  @include breakpoint(smallLaptop) {
    left: 0;
    width: 100%;
    position: absolute !important;
  }

  &:hover{
    background-color: $brand !important;
    color: $white !important;
  }
}
.common__modalBox--content{
  background-color: $white;
  padding: 2rem;
  h3{
    margin: 0;
    text-align: left;
    font-size: 1.8rem;
    position: relative;
    display: block;
    margin-bottom: 2rem;
    &:after{
      display: block;
      position: absolute;
      left: -2rem;
      right: -2rem;
      bottom: -2rem;
      content: '';
      height: 1px;
      background-color: $borderColor;
    }
  }
  .common__modalBox-inner{
    margin-top: 4rem;
    font-size: 1.2rem;
    font-weight: 500;
    color: $black;
    border: 1px solid $borderColor;
    padding: 1.5rem;
    border-radius: 5px;
    a{
      color: $brand;
      font-size: 1.2rem;
      font-weight: 500;
    }
    span{
      font-size: 1.2rem;
      font-weight: 500;
      color: $black;
      margin-bottom: 2rem;
      display: block;
      line-height: 2rem;
      text-align: justify;
    }
  }
}
.do-professional {
  .multiselect {
    display: block;
    background: transparent;
    width: 100%;
    min-height: 3rem;
    border: 0px solid $white;
    outline: none;
    padding-left: 0;
    font-size: 1.4rem;
    position: relative;
    margin-bottom: 0;
    height: auto;
    .multiselect--active {
      input {
        border: 0 !important;
      }
    }
    &:hover {
      border: 0;
      font-size: 1.2rem;
    }
    &__tags-wrap {
      background: $white;
      z-index: 1;
      position: relative;
    }
    &__tag {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding: 1rem;
      font-size: 1.2rem;
      margin: 0 0 1.5rem;
      font-weight: 500;
      background-color: $white;
      color: $black;
      border-color: $borderColor;
      z-index: 1;
      &-icon {
        margin-left: 0.7rem;
        width: 2.2rem;
        line-height: 3rem;
        &:after {
          font-size: 2rem;
        }
      }
    }
    &__select {
      top: 2.3rem;
      z-index: 3;
      @include breakpoint (toDesktop){
        top: 3.4rem;
      }
      &:before {
        position: relative;
        right: 0;
        top: 65%;
        color: $black;
        margin-top: 0.4rem;
        border-style: solid;
        border-width: 0.4rem 0.4rem 0;
        border-color: $black transparent transparent;
        content: "";
        @include breakpoint (toDesktop){
          border-width: 1.2rem 1.2rem 1.2rem;
        }
      }
    }
    .multiselect__content-wrapper {
      .multiselect__content {
        background-color: $white !important;
        border-color: $borderColor !important;
        color: $black !important;
      }
    }
  }
}

.words-count {
  float: right;
  margin-top: 1.3rem;
  font-weight: normal !important;
  font-size: 1.3rem !important;
}
.sendEmailCopy {
  padding-top: 1rem;
  margin-left: -0.5rem;
  .checkbox-container {
    display: flex;
    width: fit-content;
    cursor: pointer;
    input {
      margin-right: 1.5rem;
      cursor: pointer;
    }
  }
  .checkboxText {
    font-size: 1.4rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    color: #434a54;
  }
}
.modal-container {
  width: 44rem;
  height: fit-content;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: solid 1px #e7e9ed;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transition: all 0.9s ease;
  transition: all 0.9s ease;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.79;
  letter-spacing: normal;
  transform: translate(-50%, -50%);
  z-index: 10;
  &.disclaimer-modal {
    width: 51rem;
    .modal-title {
      font-size: 18px;
      font-weight: 500;
      font-family: Montserrat;
      color: $color-grey-6;
    }
    .modal-body {
      width: 46rem;
    }
    .modal-footer {
      width: auto;
    }
  }
  .modal-title {
    width: 40rem;
    font-size: 1.4rem;
    text-align: left;
    color: #434a54;
    padding: 2rem;
  }
  .modal-body {
    width: 39rem;
    font-size: 1.2rem;
    line-height: 1.83;
    text-align: left;
    color: #656d78;
  }
  .modal-footer {
    width: 40rem;
    height: 4rem;
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0 -4px 5px 0 rgba(0, 0, 0, 0.05);
    padding: 1rem 2rem;
    cursor: default;
    .button-discard {
      width: 50%;
      font-size: 1.2rem;
      text-transform: uppercase;
      text-align: left;
      padding-left: 0;
      padding-top: 1rem;
      display: inline-block;
      float: left;
      padding-left: 0.5rem;
      a {
        color: #ec5566;
        font-weight: 600;
        cursor: pointer;
      }
    }
    .footer-button {
      font-size: 1.2rem;
      text-transform: uppercase;
      text-align: left;
      display: inline-block;
      float: right;
      padding-top: 1rem;
      padding-right: 0.5rem;
      a {
        color: $brand;
        font-weight: 600;
        cursor: pointer;
      }
      .buttonSave {
        font-weight: 600;
        margin-right: 0;
      }
    }
  }
}
