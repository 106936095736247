// GDPR rules component style

.gdpr-wrapper {
    background: none;
    z-index: 100;

    .gdpr-content {

        h4 {
            font-size: 2.4rem;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
        }
        h4:after {
            height: 0;
        }
        p {
            margin-bottom: 3rem;
            font-size: 1.3rem;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.85;
            letter-spacing: normal;
            text-align: justify;
        }


        .gdpr-body-wrapper {
            flex: 0 0 100%;

            .gdpr-body {
                background-color: $color-white;
                overflow-y: scroll;
                padding-top: 4rem;
                max-height: calc(100vh - 16rem);
                ol {
                    padding-top: 1rem;
                    padding-left: 1.2rem;
                    li {
                        padding-left: 1rem;
                        margin-bottom: 1rem;
                        font-size: 1.3rem;
                    }
                }
            }
        }

    }
}

