@mixin button-link() {
  .buttons-holder {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    text-align: left;
    .button-link {
      display: flex;
      color: $brand;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
      -webkit-box-align: center;
      align-items: center;
      width: max-content;

      img.button-image {
        width: 12px;
        height: 14px;
        margin-right: 10px;
      }

      .fa {
        &-plus-circle {
          margin-right: 10px;
          &:before {
            font-size: 14px;
          }
        }
      }

    }
  }
}