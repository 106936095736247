.investor-assessment {
    .layer-container {
        left: 100% !important;
        @include multiple-media(smallScreen, mediumScreen) {
            left: calc(100% - 1100px) !important;
        }
        @include breakpoint(largeScreen) {
            left: calc(100% - 1600px) !important;
        }
    }

    .do-experience {
        &__parent {
            .layer-body__middle-filters {
                min-width: 170px;
                max-width: 170px;
                @include breakpoint(largeScreen) {
                    min-width: 220px;
                    max-width: 220px;
                }
            }

            height: calc(100% - 130px) !important;
        }

        &__child {
            width: calc(100% - 220px) !important;
            flex-basis: unset !important;
            @include breakpoint(largeScreen) {
                width: calc(100% - 270px) !important;
                flex-basis: unset !important;
            }

            .layer-body__middle-holder {
                height: 100%;
            }

            .do-contego {
                &__firstStep {
                    .identity-and-residence-body {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        max-height: calc(100vh - 150px);
                        overflow: auto;
                        @-moz-document url-prefix() {
                            max-height: calc(100vh - 215px);
                            .preferences-container {
                                margin-bottom: 0;
                            }
                        }

                        .layer-body__middle-message {
                            margin-top: 0;
                        }

                        .do-professional__holder {
                            padding-bottom: 60px;
                        }

                        .preferences-container {
                            @-moz-document url-prefix() {
                                padding: 0 80px;
                            }
                        }
                    }

                    &-image {
                        width: auto;
                    }
                }

                &__secondStep {
                    .layer-body__middle-holder {
                        h3 {
                            margin-bottom: 25px !important;
                        }
                    }

                    .do-professional__holder {
                        max-height: calc(100vh - 230px);
                        overflow: auto;
                        max-width: 75% !important;
                    }

                    &-block {
                        position: relative;
                    }
                }

                &__fifthStep {
                    .preferences-container {
                        display: flex;
                        align-items: center;
                        flex-grow: 1;
                        justify-content: center;

                        .do-professional {
                            &__holder {
                                max-height: calc(100vh - 240px);
                                max-width: 90%;
                            }
                        }
                    }
                }

                &__sixStep {
                    .do-addition__holder {
                        display: flex;
                        flex-direction: column;
                        max-height: unset !important;

                        &-block {
                            margin-bottom: 0 !important;
                            @include breakpoint(largeScreen) {
                                padding-left: 5%;
                            }

                            .checkbox-container {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                margin: 10px 0;

                                .protection-warning {
                                    margin-left: 20px;
                                    padding-bottom: 0;
                                    width: 85%;
                                    max-height: 47vh;
                                    overflow-y: auto;

                                    ul {
                                        padding-right: 20px;
                                    }
                                }
                            }
                        }

                        .red {
                            color: $red;
                            margin-top: 10px;
                        }

                        button {
                            display: block !important;
                            position: relative !important;
                            padding: 0 !important;
                            width: 180px !important;
                            height: 43px !important;
                            left: 35% !important;
                            margin: 20px 0 !important;

                            &:hover {
                                background-color: #125bb1;
                                color: $white;
                            }
                        }
                    }
                }
            }

            .do-experience {
                &__link {
                    right: 10px;
                    position: absolute;
                }

                &__link-left {
                    position: absolute;
                    right: auto;
                    left: 10px;
                    @include breakpoint(mediumScreen) {
                        left: 20px;
                    }
                }
            }
        }
    }
}
