.my-account {
	padding: 1rem;
	overflow: hidden;
	height: 100%;
	max-height: calc(100% - 5rem);
	@include multiple-media(mobileScreen, tabletScreen) {
		max-height: calc(100% - 2rem);
	}
	.layer-body__top {
		@include breakpoint(mobileScreen) {
			padding-bottom: 0;
			position: relative;
		}
		&-left {
			@include breakpoint(mobileScreen) {
				flex: 100%;
			}
			.common__modalBox .box .common__modalInput #feedback {
				border-radius: .5rem;
			}
		}
		&-right {
			.feedback__button {
				@include breakpoint(mobileScreen) {
					display: none;
				}
			}
		}
		.layer-body__navigation {
			@include hide-scrollbar;
			@include breakpoint(mobileScreen) {
				width: 100%;
				overflow: hidden;
				overflow-x: auto;
				padding-bottom: 1.1rem;
				margin-left: 0;
			}
			&--wrapper {
				@media (max-width:482px) {
					min-width: 50rem;
				}
				> a {
					@supports (-webkit-overflow-scrolling: touch) {
						-webkit-text-size-adjust: 100%;
					}
				}
				.icon-background {
					display: none;
					@media (max-width:482px) {
						display: inline-block;
						z-index: 1;
						height: 4rem;
						width: 6rem;
						position: absolute;
						vertical-align: middle;
						pointer-events: none;
						&:first-child {
							background-image: linear-gradient(90deg, $color-grey-2 20%, transparent);
							left: 0;
							@media screen and (-webkit-min-device-pixel-ratio:0) {
								background-image: linear-gradient(90deg, $color-grey-2 20%, rgba(255,255,255,0.001))
							}
						}
						&:last-child {
							background-image: linear-gradient(-90deg, $color-grey-2 20%, transparent);
							left: calc(100vw - 9.8rem);
							bottom: -1.3rem;
							@media screen and (-webkit-min-device-pixel-ratio:0) {
								background-image: linear-gradient(-90deg, $color-grey-2 20%, rgba(255,255,255,0.001))
							}
						}
					}
				}
				.fa-angle {
					&-left,
					&-right {
						display: none;
						@media (max-width:482px) {
							display: inline-block;
							width: 1.5rem;
							height: 1.5rem;
							font-size: 1.5rem;
							color: $color-white;
							background-color: $color-grey-6;
							text-align: center;
							border-radius: 50%;
							box-sizing: content-box;
							z-index: 2;
							position: absolute;
							pointer-events: none;
						}
					}
					&-left {
						@media (max-width:482px) {
							left: 0;
							&:before {
								top: -0.6px;
								left: 4px;
								position: absolute;
							}
						}
					}
					&-right {
						@media (max-width:482px) {
							right: .1rem;
							top: .1rem;
							&:before {
								top: -0.6px;
								left: 6px;
								position: absolute;
							}
						}
					}
				}
			}
		}
		&__middle {
			@include multiple-media(mobileScreen, tabletScreen) {
				height: 100%;
				overflow: auto;
				padding-right: 1rem;
				margin-right: -1.4rem;
			}
			@include breakpoint(mobileScreen) {
				max-height: calc(100vh - 23rem);
			}
			@include breakpoint(tabletScreen) {
				max-height: calc(100vh - 24rem);
			}
		}
	}
	.layer-body__middle {
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		max-height: calc(100% - 14rem);
		padding-right: 0;
		@include multiple-media(mediumScreen, largeScreen) {
			max-height: calc(100% - 13rem);
		}
		@media (max-width:1024px) {
			@include hide-scrollbar;
		}
		> div:nth-child(3) {
			height: auto;
		}
	}
	.layer-container.modal-layer-container.advisor {
		.investor-account-btn {
			&.input-gr-o {
				&:hover {
					cursor: default;
				}
			}
			@include breakpoint(mobileScreen) {
				width: 100%;
				left: 0;
			}
		}
	}
}
