///------------------------------------
//    #SETTINGS
//------------------------------------

// Globaly available variables





//-------------------------
//    #Colors
//    Global default settings
//	  for colors
//-------------------------


$black: #434a54;
$buttonFeedback: #F96F57;
$titleBlack: #000E2B;
$borderColor: #ececec;
$borderColorBlue: #c5ecfb;
$white: #fff;
$orange: #ee7421;
$gray: #a9b3bf;
$blue: #4fc0e8;
$dealColor: #f2fafc;
$green: #8cc155;
$lightOrange: #fdd053;
$smallColor: #aab2bd;
$red: #ed5565;
$darkBlack: #000;
$editorBackground: #fcfcfc;
$lightGreen: #ace8de;
$lightGray:#dcdee2;
$darkerWhite: #f5f7fa;
$tagBorder: #efefef;
$tagBackground: #434a54;
$dealBlockColor: #f2f2f2;
$investmentTitle : #ccd1d9;
$investmentBorder : #e6e9ed;
$addCompanySmallTitle : #656d78;
$errorBackground : #fdf0f1;
$pendingBackground : #f9f9f9;
$acceptBackground : #f3fafc;
$green: #ace9db;
$tableGray: #f6f7fb;
$colorCheck: #a0d468;
$founderBrand: #56ccbd;
$yellow: #ffce54;
$overGreen: #f9fcf6;
$lightOrange : #fff0e5;
$dealIconColor: #ececec;
$approvedColor: #4a89dc;
$grayColor: #abacb1;
$darkerBlue: #f1fbfe;
$darkerWhiteDO: #e5e9ef;
$smallBlockBorder: #d2d4d8;
$positive: #a0d468;
$dark-gray: #aab2bd;
$light-gray: #808080;
$layerBackground: #e6e9f0;
$layerBorder: #ccd0d9;
$dealCircle: #ebf3fc;
$dealCircleText: #4b8cd8;
$brand: #1f7be8;
$brandHover: #125bb1;
$saveButton: #B1C4E3;
$dropdownColor: #f7f8fc;
$buttonColor: #666666;
$inputColor: #CDD0D7;
$darkerBrand: #004699;
$darkerGreen: #3b7100;
$darkerRed: #cb0015;
$grayLight: #a9b2bd;
$founderDarkerBrand: #390153;
$founderDarkerBrandHover: #6f296c;
$founders-sign-color: #ebf6f7;
$orange-pink: #fc6e51;
$borderWhite: #e7e9ed;
$resultsBackground: #f5f7fa;
$pdfPageBorder: #525659;
$pdfScrollBar: #f0f0f1;
$pdfScrollBarThumb: #c0c0c1;
$pdfDownloadHover: #e8f1fc;
$dark-brand: #185fb2;
$orange-badge: #f6bb42;
$red-badge: #da4353;
$light-blue: #d7e9ff;
$disabled-grey: #e0e0e0;
$selectedLightBlue: #e8f0fa;
$blue-badge: #78aae6;
$azul: #1f7be8;
$charcoal-grey: #434a54;
$poisonous-green: #34f4bb;
$light-black: #333333;

// New color variables that should be used
$color-blue-main: #1f7be8;
$color-blue-light: #5d9fee;
$color-blue-dark: #125bb1;
$color-blue-light-hover: #ecf3fc;
$color-blue-dark-hover: #d2e5fa;
$color-white-grey: #ddd;

$color-green-main: #a0d468;
$color-green-light: #c1e39d;
$color-green-light-2: #31bc78;
$color-green-dark: #7fc137;
$color-green-dark-1: #6fae2c;
$color-green-dark-2: #7dd0dc;
$color-green-main-2: #56ccbd;
$color-green-main-3: #e2f5f7;

$color-red-main: #da4353;
$color-red-light: #e57c87;
$color-red-dark: #b52433;

$color-yellow-main: #f6bb42;
$color-yellow-light: #f9d283;
$color-yellow-dark: #e9a00b;

$color-white: #ffffff;
$color-white-1: #f1f1f1;
$color-white-2: #fafbfd;
$color-white-3: #dae0e8;

$color-grey-1: #f5f7fa;
$color-grey-2: #e7e9ed;
$color-grey-3: #ccd1d9;
$color-grey-4: #a9b2bd;
$color-grey-5: #656d78;
$color-grey-6: #434a54;
$color-grey-7: #eeeeee;
$color-grey-8: #e2e2e2;
$color-grey-9: #f8f8f8;
$color-grey-10: #959ea8;
$color-grey-11: #f0f0f0;
$color-grey-12: #e5e9ef;
$color-grey-13: #56585A;
$color-grey-14: #d2d4d8;
$color-grey-15: #e6e9ed;
$color-grey-16: #dee1e7;
$color-grey-17: #9a9a9a;
$color-grey-18: #dedede;
$color-grey-19: #dbe0e7;
$color-grey-20: #99a1ab;
$color-grey-21: #727a82;
$color-grey-22: #acadb2;
$color-grey-23: #aaaaaa;
$color-grey-24: #555555;
$color-grey-25: #aab8c9;
$color-grey-26: #cbcfd5;

$color-brown-1: #666666;

$color-file-excel: #0d904f;
$color-file-word: #0d5aa7;
$color-file-pdf: #ff1919;
$color-file-powerpoint: #ce8735;
$color-file-image: #deb40e;

//Compact design colors
$color-green-compact-hover: #629b25;
$color-yellow-compact-main: #f6bb42;
$color-yellow-compact-hover: #e9a00b;
$color-red-compact-main: #dd5564;
$color-red-compact-hover: #b52433;
$color-compact-background: #f9fafc;

//ProRaise colors
$color-purple-main: #390153;
$color-purple-main-lighter: #57017e;
$color-purple-main-bright: #bf43da;
$color-purple-main-pale: #744D86;
$color-cyan-secondary: #56ccbd;
$color-cyan-secondary-darker: #35b1a0;
$color-cyan-secondary-pale: #c3e8eb;
$color-cyan-light: #e2f5f7;

//Network status colors
$color-network-invited: #f6bb42;
$color-network-no-deals: #bf43da;
$color-network-contacts: #aab8c9;
$color-network-matched: #a0d468;
