.data-room {
    width: 100%
}

.sidebar-wrapper {
    height: 100%;
}

.founder-base-block {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    background: transparent;
    font-family: Nunito, sans-serif;

    @include multiple-media(tabletScreen, mobileScreen) {
        padding: 20px;
    }

    .base-block {
        border-radius: 5px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background-color: $color-white;
        padding: 2rem;
        margin-bottom: 2rem;

        &-title {
            font-family: NunitoBold, sans-serif;
            font-size: 2.4rem;
            margin-bottom: 2rem;
        }

        &-clear {
            padding: 0;
        }

        .profile-status {

            &__info {
                display: flex;
                align-items: center;

                @include multiple-media(tabletScreen, mobileScreen) {
                    flex-direction: column;
                }

                &--select {
                    font-size: 1.6rem;
                    width: 100%;

                    @include multiple-media(tabletScreen, mobileScreen) {
                        .text-input {
                            width: 100%;
                        }

                        &:nth-child(1) {
                            label {
                                margin-top: 0;
                            }
                        }
                    }

                    @include multiple-media(largeScreen, mediumScreen, smallScreen) {

                        &:nth-child(1),
                        &:nth-child(2){
                            margin-right: 2rem;
                        }
                    }


                    label {
                        font-size: 1.6rem;
                        font-weight: 600;
                        
                        @include multiple-media(tabletScreen, mobileScreen) {
                            margin: 20px 0 0 0;
                        }
                    }

                    .multiselect--active {
                        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
                    }

                    .multiselect__input,
                    input {
                        background-color: $color-white;
                        padding: 1.4rem 2rem;
                        font-size: 1.6rem;
                        font-family: 'Nunito', sans-serif;

                        &:hover,
                        &:focus {
                            border-color: $color-grey-18;
                        }
                    }

                    .multiselect__content {
                        top: 5rem;
                        font-family: 'Nunito', sans-serif;
                        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                        border: none;

                        .multiselect__element {
                            padding: 0;

                            .multiselect__option {
                                font-size: 1.6rem;
                                padding: 1.4rem 2rem;
                                color: $darkBlack;

                                &--highlight {
                                    background-color: $color-cyan-secondary-pale;
                                }
                            }
                        }
                    }
                }
            }

            &--button {
                border-radius: 5px;
                background-color: $color-purple-main;
                font-size: 1.6rem;
                padding: 1.4rem 4rem;
                margin-top: 2rem;
                outline: none;
                color: $color-white;
                cursor: pointer;
                border: none;

                &:disabled {
                    opacity: .3;
                }
            }
        }

        .profile-dataroom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include multiple-media(tabletScreen, mobileScreen) {
                flex-direction: column;
                align-items: flex-start;
            }

            &__info {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 1.8rem;

                &-permission {
                    position: relative;
                    .fa-folder {
                        color: $color-purple-main;
                        font-size: 26px;
                    }
                    .fa-check {
                        color: $color-white;
                        font-size: 11px;
                        position: absolute;
                        top: 8px;
                        left: 7px;
                    }
                }
                &-permission-not-granted {
                    position: relative;
                    .fa-folder {
                        color: $color-cyan-secondary;
                        font-size: 26px;
                    }
                    .fa-times {
                        color: $color-white;
                        font-size: 11px;
                        position: absolute;
                        top: 8px;
                        left: 7px;
                    }
                }

                &--text {
                    font-family: NunitoBold, sans-serif;
                    padding-left: 1.7rem;
                }
            }

            &--link {
                font-size: 1.6rem;
                background: $color-purple-main;
                color: $color-white;
                padding: 1.4rem 2rem;
                border-radius: 5px;
                outline: none;
                min-width: 113px;
                cursor: pointer;

                @include multiple-media(tabletScreen, mobileScreen) {
                    margin-top: 20px;
                }

            }
        }

        .investor-interested {
            display: flex;
            flex-direction: column;

            &__main {
                padding: 2rem;

                .investor-profile {
                    display: flex;
                    margin-right: 1.5rem;

                    &__avatar {
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        border: solid 1px #dae0e8;
                        background-color: #fafbfd;
                        width: 4.45rem;
                        height: 4.45rem;
                        border-radius: 5px;

                        .initials {
                            font-size: 1.8rem;
                            font-family: Nunito, sans-serif;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: $color-white-3;
                        }
                
    
                        img {
                            max-height: 100%;
                            max-width: 100%;
                        }
                    }

                    &__info {
                        display: flex;
                        flex-direction: column;
                        margin-left: 1.5rem;

                        &--name {
                            font-size: 1.6rem;
                            font-family: NunitoBold, sans-serif;
                        }

                        &--date {
                            font-size: 1.2rem;
                            color: $color-grey-21;
                        }
                    }

                    &--express {
                        cursor: pointer;
                        margin-top: 1.5rem;
                        border-radius: 5px;
                        background-color: $color-green-dark-1;
                        font-size: 1.1rem;
                        padding: .5rem .8rem;
                        color: $color-white;
                        border: none;
                        outline: none;
                    }
                    &--feedback {
                        cursor: pointer;
                        margin-top: 1.5rem;
                        border-radius: 5px;
                        background-color: $orange-badge;
                        font-size: 1.1rem;
                        padding: .5rem .8rem;
                        color: $color-white;
                        border: none;
                        outline: none;
                    }
                    &--viewed {
                        cursor: pointer;
                        margin-top: 1.5rem;
                        border-radius: 5px;
                        background-color: $blue-badge;
                        font-size: 1.1rem;
                        padding: .5rem .8rem;
                        color: $color-white;
                        border: none;
                        outline: none;
                    }
                    &--not-interest {
                        cursor: pointer;
                        margin-top: 1.5rem;
                        border-radius: 5px;
                        background-color: $red-badge;
                        font-size: 1.1rem;
                        padding: .5rem .8rem;
                        color: $color-white;
                        border: none;
                        outline: none;
                    }

                    &--login {
                        display: block;
                        font-size: 1.6rem;
                        margin-top: 1.5rem;
                    }

                }
            }

            &__info {
                display: flex;
                flex-direction: column;
                padding: 2rem;
                font-size: 1.6rem;
                border-top: 1px solid rgba(0, 0, 0, 0.2);

                &--title {
                    font-family: NunitoBold, sans-serif;

                    .price {
                        color: $color-green-dark-1;
                    }
                }

                &--text {
                    line-height: 1.63;
                    margin-top: 1.5rem;
                }
            }
        }
    }
}
