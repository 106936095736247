.common_radio {
    .radio-group{
        margin-bottom: 0rem;
        padding: 2rem;
        border-bottom: 1px solid $borderColor;
        background-color: $darkerWhite;
        @include transition(.3s all ease-in-out);
        &.checked{
            background-color: $white;
        }
        &:last-child{
            margin-bottom: 0;
        }
        & * {
            cursor: inherit;
        }
    }
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        position: relative;
        padding-left: 3.8rem;
        margin-bottom: 0;
        line-height: 2rem;
        display: inline-block;
        font-weight: 600;
        color: $black;
        text-transform: uppercase;
        @include breakpoint(smallLaptop){
            padding-left: 3.9rem;
        }
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 1px solid #e6e9ed;
        border-radius: 100%;
        background: #ffffff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 10px;
        height: 10px;
        background: $brand;
        position: absolute;
        top: 5.5px;
        left: 5.2px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
