.appendix-b-step {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

  &__content {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    &__header {
      margin-bottom: 2rem;
      padding: 30px 20px 0 20px;

      @include multiple-media(smallScreen, mediumScreen, largeScreen) {
        margin-bottom: 3rem;
      }

      &--title {
        text-align: center;
        font-weight: 600;
        font-size: 1.8rem;
        color: $color-grey-6;
        margin: 0;
      }

      &--subtitle {
        font-size: 1.2rem;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        text-align: center;
        color: $color-grey-6;
        margin-bottom: 0;

        @include  multiple-media(smallScreen, mediumScreen, largeScreen) {
          width: 60%;
          margin: 2rem auto 0 auto;
        }

      }
    }

    // Participants page style
    .appendix-b-participants {
      margin: auto;

      @include multiple-media(smallScreen, mediumScreen, largeScreen) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100vh - 330px);
      }

      &__body {

        &__header {
          text-align: center;
          margin-bottom: 2rem;

          &--subtitle {
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 1.54;
            color: $color-grey-6;
            display: block;
          }

          &--title {
            font-size: 1.8rem;
            font-weight: normal;
            line-height: 1.11;
            padding-top: 1rem;
            padding-bottom: 2rem;
          }
        }

        &__inputs {
          display: flex;
          flex-direction: column;
          align-items: center;

          .companyNew__input-mask {
            display: none;
            align-self: center;
            padding-top: 2rem;

            @include multiple-media(tabletScreen, tabletScreenLandscape, smallScreen, mediumScreen, largeScreen) {
              display: block;
            }

            .question {
              position: relative;
              top: 0;
              display: inline-block;
              left: 0;
              background-color: $smallColor;
              font-weight: bold;
              font-size: 1.8rem;
              width: 20px;
              height: 20px;
            }
          }
          .hubx-radio {
            padding-top: 2rem;
            min-width: 160px;
          }
        }
      }
    }

    // Investment page style
    .appendix-b-investment {
      padding: 0 20px;
      margin-top: 3rem;

      @include multiple-media(tabletScreen, smallScreen) {
        width: 50%;
        margin: 3rem auto;
      }

      @include multiple-media(mediumScreen, largeScreen) {
        display: flex;
        flex-direction: column;
        margin: 3rem auto 0 auto;
        justify-content: normal;
        width: 60%;
      }

      &__body {

        &__text-block {
          display: flex;
          flex-direction: row;
          margin-top: 2rem;

          &__item {
            flex-grow: 1;
            flex-basis: 0;
            margin-right: 2rem;

            &--title {
              font-size: 1.3rem;
              font-weight: bold;
              line-height: 1.54;
              margin-bottom: 1rem;
              display: block;
            }

            &--data {
              font-size: 1.8rem;
              line-height: 1.11;
            }
          }
        }

        &__date {
          margin-top: 2rem;

          label {
            font-size: 1rem;
            font-weight: 500;
            color: $smallColor;
          }

          input {
            border-radius: 5px;
            border: solid 1px $investmentBorder;
            color: $color-grey-6;
            font-size: 1.3rem;
            font-weight: 500;
            margin-top: 1rem;
            padding: 16px;

          }

          .vdp-datepicker {

            input {
              z-index: 1;
            }

            &__calendar {
              width: 250px;

              @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                width: 300px;
              }
            }
          }

          .errorText {
            color: $color-red-main;
          }

        }

        &__textarea {
          margin-top: 2rem;
          display: flex;
          flex-direction: column;

          &--max-char {
            font-size: 1rem;
            font-weight: 500;
            text-align: right;
            color: $dark-gray;
          }

          .hubx-input {
            height: 12rem;
            resize: none;
            z-index: 1;

            &-label {
              font-size: 1rem;
              font-weight: 500;
              color: $dark-gray;
            }
          }

        }
      }
    }

    // Fee structure page style
    .appendix-b-fee {
      padding: 20px;
      z-index: 10;

      @include multiple-media(tabletScreen, smallScreen) {
        width: 50%;
        margin: 0 auto;
      }

      @include  multiple-media(mediumScreen, largeScreen) {
        width: 65%;
        margin: 0 auto 2rem auto;
        display: block;
        padding: 0 2rem;
      }

      &__body {

        .companyNew__input-mask {

          .question {
            display: none;
          }
        }

        .do-appendix__third-block {
          margin: 1rem 0;
          position: relative;

          label {
            font-weight: bold;
          }

          p {
            margin-bottom: 2rem;
            font-size: 1.2rem;
            line-height: 1.67;
            letter-spacing: normal;
            text-align: center;
          }

          textarea {

            @include breakpoint(largeScreen) {
              height: 8rem;
            }
          }

          .do-appendix__third-add {
            color: $color-blue-main;
          }

          .tooltip-question {
            top: 40%;
            transform: translateY(-50%);
            font-size: 15px;
            font-weight: 600;
            position: absolute;
            left: -10px;
            background-color: $dark-gray;
            color: $color-white;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            text-align: center;
            z-index: 10;
            padding: 0;
            display: none;

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
              display: block;
            }
          }

          .tooltip-top {
            top: 50%;
          }

        }

        .do-appendix__first-block-holder {
          margin-left: 0;
          justify-content: left;
          margin-right: 2rem;

          &-item {
            margin-right: 2rem;

            .hubx-radio {
              label {
                font-size: 1.8rem !important;
                color: $color-grey-6;
                font-weight: normal;
              }
            }
          }
        }

        .do-appendix__third-block-item-bigger.last__child {
          display: flex;
          text-align: center;
          align-items: center;

          .currency {
            position: relative;
            margin-right: .5rem;
            margin-top: 0;
            margin-bottom: .1rem;
          }
        }

        .do-appendix__third-block-item .close i {
          color: $color-red-main;
        }

        .do-appendix__third-block-item > div {

          @include breakpoint(mobileScreen) {
              padding: 1rem 1.5rem;
              width: 25%;
          }

          @include multiple-media(tabletScreen, smallScreen) {
            flex: 1 1 0;
          }
        }

        .do-appendix__third-block-item-bigger.last__child {

          @include breakpoint(mobileScreen) {
            align-items: center;
          }
        }

        .do-appendix__third-block-item {

          & div.first__child {
            @include breakpoint(mobileScreen) {
              width: 10% !important;
            }
          }

          & div.third__child {
            @include breakpoint(mobileScreen) {
              width: 35% !important;
            }
          }

          & .errorText {
            @include breakpoint(mobileScreen) {
              left: 0;
              font-size: 1rem;
            }
          }
        }

      }
    }

    // Steps Infro style
    .appendix-b-steps-info {
        padding: 0 20px;
        flex-grow: 1;

      @include multiple-media(tabletScreen, smallScreen) {
        width: 70%;
      }

      @include multiple-media(mediumScreen, largeScreen) {
        width: 60%;
        margin: 0 auto;
      }

        &__body {

          &__list {
            display: flex;
            flex-direction: column;
            padding: 0;

            &__item {
              list-style: none;

              &:before {
                color: #009dcd;
                content: "\2022";
                font-weight: bold;
                display: table-cell;
                width: 25px;
                font-size: 2.2rem;
              }

              &--title {
                display: table-cell;
                font-size: 1.3rem;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.54;
              }

              &--description {
                border-left: 4px solid $color-grey-2;
                margin: 0 0 0 2px;
                padding: 5px 0 25px 22px;
                font-size: 1.4rem;
                line-height: 1.71;
              }
            }

            .lastItem {

              .appendix-b-steps-info__body__list__item--description {
                border: none;
              }

              &:before {
                color: #9dd467;
                content: "\2022";
                font-weight: bold;
                display: table-cell;
                width: 25px;
                font-size: 2.2rem;
              }
            }
          }
        }
    }

    // Confirm and sing style
    .appendix-b-confirm {
      width: 100%;
      flex-grow: 1;

      @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        width: 80%;
        padding-left: 20%;
      }

      .do-appendix__fourth {
        height: 100%;

        & .appendix-b-left {
          @include multiple-media(tabletScreen, smallScreen) {
            padding-right: 3rem !important;
          }
        }
      }

      .appendix__popup {
        height: max-content;

        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
          height: 95%;
          padding-right: 25%;
        }
      }

      &__body {
        height: 100%;

        .appendix__footer-confirm--special {
          width: 100%;
          padding: 1rem;

          @include multiple-media(tabletScreen, smallScreen) {
            padding: 2rem;
          }

          @include breakpoint(mediumScreen) {
            width: 1100px;
            padding: 2rem;
          }
          @include breakpoint(largeScreen) {
            width: 1600px;
            padding: 2rem;
          }
        }

        .appendix__body {
          padding: 0 15px;

          .appendix-b-left {

            .deal-originator {
              color: $color-grey-6;
            }

            @include breakpoint(mobileScreen) {
              padding-right: 3rem !important;
            }

            @include breakpoint(mediumScreen) {
              padding-right: 6rem !important;
            }

            @include breakpoint(largeScreen) {
              padding-right: 8rem !important;
            }
          }

          .specialBlock {


            .text-editor-render{

              & h1, h2, h3, h4, h5, h6, p, span, i, a, b,
                strong, u, em, mark, small, del, ins, sub, sup {
                font-weight: 500;
                padding: 0;
                font-size: 1.2rem;
                border-bottom: none;
                margin-bottom: 0;
              }
            }
          }

          span {
            color: $color-grey-6;
          }
        }
      }
    }
  }

  &__arrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.5;
    padding: 2rem 0;
    text-transform: uppercase;

    @include multiple-media(mobileScreenLandscape) {
      position: unset !important;
      transform: none !important;
    }

    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      justify-content: space-between;
    }

    &__link {
      display: flex;
      align-items: center;

      &__icon {

        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
          margin-bottom: .5rem;
        }

      }

    }
    .nextLink {
      color: $color-blue-main !important;
      padding-left: 13px;

      .appendix-b-step__arrows__link__icon {
        padding: 0 20px 0 10px;

        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
          padding: 0;
        }

        @include breakpoint(mobileScreenLandscape) {
          padding: 0 20px 0 10px;
        }

      }

      @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        display: flex;
        flex-direction: column-reverse;
        padding-right: 5rem;
      }

      @include breakpoint(mobileScreenLandscape) {
        display: flex;
        flex-direction: row;
        padding-right: 0;
        padding-left: 0;
      }
    }
    .prevLink {
      padding-right: 13px;
      color: $dark-gray !important;


      .appendix-b-step__arrows__link__icon {
        padding: 0 10px 0 20px;

        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
          padding: 0;
        }

        @include breakpoint(mobileScreenLandscape) {
          padding: 0 10px 0 20px;
        }
      }

      @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        display: flex;
        flex-direction: column;
        padding-left: 5rem;
      }

      @include multiple-media(tabletScreen, smallScreen, mediumScreen) {
        padding-left: 3rem;
      }

      @include breakpoint(mobileScreenLandscape) {
        display: flex;
        flex-direction: row;
        padding-right: 0;
        padding-left: 0;
      }
    }

    .right-arrow {
      margin-left: auto;
    }
  }

  &__informations {

    &__item {
      background-color: $darkerWhiteDO;
      padding: 1.5rem 3rem;
      display: flex;
      align-items: center;

      &--icon {
        background-color: $dark-gray;
        border-radius: 50%;
        text-align: center;
        padding: 5px 8px;
        color: $color-white;
        margin-right: 1rem;
      }

      &--description {
        font-size: 1rem;
        font-weight: 500;
        line-height: 2;
        color: $dark-gray;
      }
    }
  }

  .content-additional {
    overflow-y: auto;

    @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
      overflow-y: hidden;
    }

  }

}
