.dealTab {
    .noError {
        display: none;
    }
    .rejected {
        background-color: $errorBackground;
        .edit__profile {
            color: $red;
        }
        .gray {
            color: $gray !important;
            opacity: 0.5;
        }
        &__noBlock {
            display: none;
        }
        &__dealBlock {
            display: block;
            font-size: 1.1rem;
            color: $red;
            padding: 2rem 3rem;
        }
    }
    .edit__pending {
        display: none;
    }
    .blue {
        color: $blue;
    }
    .pending {
        background-color: $pendingBackground;
        .edit__resend {
            display: none;
        }
        .edit__pending {
            display: inline-block;
            color: $gray;
            opacity: 0.5;
            padding: 0;
        }
    }
    .peoples__footer {
        button {
            margin-left: 3rem;
        }
    }
    .peoples__smallBlock {
        .peoples__body-name {
            .company-name-draft {
                display: inline-block;
                vertical-align: middle;
                flex-grow: 1;
            }
        }
    }
    .peoples__bigBlock {
        .peoples__bodyBlock {
            display: inline-block;
            width: 56%;
            position: relative;
            &Bigger {
                font-size: 1.6rem;
                position: relative;
                width: 43.9%;
                display: inline-block;
                vertical-align: top;
                margin-left: -0.3rem;
                &:after {
                    display: none;
                }
                .edit__profile {
                    margin-left: 0;
                }
            }
            &:after {
                width: 1px;
                top: 0;
                bottom: 0;
                right: 0;
                position: absolute;
                background-color: $borderColor;
                content: '';
            }
        }
        .company-name-active {
            display: inline-block;
            vertical-align: middle;
        }
        .peoples__smallBodyBlock {
            color: $black;
            font-weight: 500;
            padding: 2rem 0rem;
            border-top: 1px solid $borderColor;
            position: relative;
            &:after {
                width: 1px;
                top: 0;
                bottom: 0;
                right: 50%;
                position: absolute;
                background-color: $borderColor;
                content: '';
            }
            small {
                color: $borderColor;
                font-weight: 400;
                display: block;
                margin-bottom: 1rem;
            }
            .peoples__body-info {
                width: 49.4%;
                vertical-align: top;
                display: inline-block;
                text-align: center;
            }
        }
        .peoples__info {
            border-bottom: 1px solid $borderColor;
            border-top: 0;
            font-size: 1.2rem;
            font-weight: 500;
            padding: 2rem;
            justify-content: space-between;
            align-items: center;
            &::before {
                display: none;
            }
            .edit {
                top: 2rem;
            }
            .syndicated {
                margin-left: 1rem;
                border: 1px solid $investmentTitle;
                color: $investmentTitle;
                padding-left: 0.3rem;
                padding-right: 0.3rem;
                border-radius: 4px;
            }
        }
        .peoples__newDeal {
            padding: 2rem;
            border-bottom: 1px solid $borderColor;
            button {
                border: 0;
                outline: none;
                background-color: transparent;
                font-size: 1.2rem;
                cursor: pointer;
                color: $brand;
            }
        }
        .peoples__syndicated {
            background-color: rgba(240, 115, 35, 0.23);
            color: #f07323;
            padding: 5px;
            font-size: 1.8rem;
        }
    }
}

.investmentFirm {
    .activePersonBlock {
        height: calc(100vh - 20rem);
        overflow: hidden;
        @include breakpoint (toDesktop) {
            height: auto;
            padding-bottom: 3rem;
        }
    }
}

.investmentFirm__results {
    .peoples__bigBlock {
        width: 100%;
    }
    .peoples__body {
        >div {
            width: 100%;
            max-height: 48rem;
            overflow-y: scroll;
            @media screen and (min-width: 1900px) {
                height: 30rem;
            }
            @include breakpoint (toDesktop) {
                max-height: none;
            }
        }
    }
    .peoples__footer {
        background: $white;
    }
}

.sidebar__navigation-select {
    margin: 0 3rem;
    background: $white;
    padding: 2.5rem;
    position: relative;
    &.edit-select {
        padding: 0.5rem;
        position: relative;
        border: 1px solid $white;
        background-color: transparent;
        .innerSelect {
            background: $white;
            padding: 2.5rem;
        }
    }
    span {
        text-align: center;
        display: block;
        font-size: 1.6rem;
        color: $smallColor;
        cursor: pointer;
    }
    input {
        opacity: 0;
        background-color: transparent;
        border: 0;
        outline: none;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        cursor: pointer;
    }
    .company__name,
    .deal-icon {
        display: inline-block;
        vertical-align: middle;
    }
    .company__name {
        color: $black;
        font-size: 1.2rem !important;
    }
}

.sidebarInput {
    padding: 0 3rem;
    margin-top: 0rem;
    border: 0;
    outline: none;
    .maskOver {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        display: block;
        background-color: transparent;
    }
    .biggerSelect {
        i {
            position: absolute;
            right: 2rem;
            top: 1.4rem;
            color: $white;
        }
    }
}

.companyNew-selects {
    .black-select {
        border: 1px solid #dddee2;
    }
    .specialSmallerSelect {
        width: 35.2% !important;
    }
    .currencyBlock {
        .multiselect__single {
            padding-left: 4.5rem !important;
        }
    }
}

.tagsBlock {
    padding-bottom: 22rem;
    .input-error {
        border: 0 !important;
    }
    .multiselect__content {
        border: 0;
        top: 5.9rem;
        li {
            border: 1px solid $borderColor;
            border-bottom: 0;
            &:nth-last-child(2) {
                border-bottom: 1px solid $borderColor;
            }
        }
    }
}

.feedback {
    .modal-content {
        width: 50%;
    }
    .investorNotes textarea {
        width: 90%;
    }
    .investorSmall {
        padding: 1.4rem 0;
    }
    .sliderbox {
        height: auto;
        display: inline-block;
        width: 43%;
        padding: 0 3rem;
        margin-bottom: 6rem;
        &__title {
            display: block;
            text-align: center;
            margin-bottom: 3rem;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-size: 1.1rem;
            font-weight: 600;
            color: $black;
        }
    }
    .slider {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        >span {
            font-size: 1.2rem;
            font-weight: 500;
            color: $black;
            margin: -0.5rem 1.5rem 0;
        }
    }
    &__slider {
        padding: 3rem 1rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .vue-slider-component .vue-slider-piecewise-dot {
        width: 2px;
        background-color: $investmentBorder !important;
        border-color: $investmentBorder !important;
    }
    .vue-slider-piecewise {
        top: -0.5rem !important;
        @include breakpoint(toPhone) {
            top: 4px !important;
        }
    }
    .vue-slider-horizontal {
        padding: 0 !important;
    }
    .vue-slider-dot {
        top: -0.9rem !important;
        width: 1rem !important;
        height: 1rem !important;
        left: -0.5rem !important;
    }
    .vue-slider-component ul.vue-slider-piecewise li.vue-slider-piecewise-item {
        padding-top: 1.2rem;
        top: 5px;
    }
}
.item-details>section>div>header {
    font-size: 12px;
    font-weight: 500;
    color: $addCompanySmallTitle;
    @include breakpoint (toDesktop) {
        font-size: 2.4rem;
    }
}

.item-details>section>div>footer {
    font-size: 20px;
    font-weight: normal;
    word-break: break-word;
    @include breakpoint (toDesktop) {
        font-size: 2.6rem;
    }
}

.item-details>section>div>footer>ul>li,
.item-details>section>div>footer>ol>li {
    font-size: 1.2rem;
    font-weight: 500;
}

.item-details>section>p {
    font-size: 1.2rem;
    @include breakpoint (toDesktop) {
        font-size: 2.2rem;
        line-height: 4.5rem;
    }
    ul,
    ol {
        padding-left: 2.9rem;
    }
}
.deal__preview,
.company__preview {
    >.layer-container {
        >.layer-body {
            padding: 0;
        }
    }
    .layer-container {
        background-color: $layerBackground;
        left: 0;
    }
    .layer-container {
        overflow-x: hidden;
        overflow-y: auto;
    }
    .fixed {
        padding: 0 !important;
        left: 0;
    }
}

.company__preview {
    .container {
        margin: 0;
        display: table;
        vertical-align: middle;
        height: 100%;
        margin-top: 0;
        &.advisor-preview {
            padding-bottom: 10rem;
        }
    }
}

.deal__preview {
    .layer-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: $white;
        z-index: 1;
    }
    .deal-dataroom {
        .do-modal {
            padding: 7rem 4rem;
        }
    }
    .layer-body {
        height: 100%;
    }
}
.tags>a {
    padding: 20px 30px;
    display: inline-block;
}

.navigation-link {
    font-size: 1.8rem;
    font-weight: 600;
    color: $black;
    text-transform: uppercase;
    margin-right: 0;
    display: inline-block;
    &.is-active {
        color: $brand;
    }
}

.item-details>section>div {
    margin-top: 55px;
    width: 30%;
    padding-right: 10px;
    @include breakpoint (toDesktop) {
        width: 100%;
    }
    &:nth-child(3n+1) {
        width: 30%;
        @include breakpoint (toDesktop) {
            width: 100%;
        }
    }
    &:nth-child(3n+3) {
        padding-left: 0;
        width: 30%;
        @include breakpoint (toDesktop) {
            padding-left: 0;
            width: 100%;
        }
    }
}

.marginLeft {
    margin-left: 1.5rem;
}

.submit-link {
    cursor: pointer;
    color: #8cc155 !important;
}

.submit-link-disabled {
    cursor: default;
    color: #ACADB2 !important;
}

.gray-text {
    color: #ACADB2 !important;
    font-size: 1.2rem;
    font-weight: 600;
}

.specialSubmit {
    @include breakpoint(toDesktop) {
        width: 100% !important;
        padding: 3rem;
    }
}

.do-place {
    input {
        @include breakpoint (toDesktop) {
            font-size: 16px;
        }
    }
}

.investor-place-order {
    height: calc(100vh - 5rem);
    overflow-y: auto;
    padding: 0;
    @include multiple-media(mobileScreen, tabletScreen) {
        height: calc(100vh - 8rem);
    }
    .do-place {
        padding: 1.2rem 4rem 2rem;
        .place-order-height {
            max-height: inherit;
            overflow-y: hidden;
        }
        .specialSubmit {
            &:disabled {
                opacity: 0.3;
                &:hover {
                    cursor: default;
                    background-color: $white;
                    color: $brand;
                }
            }
        }
        .place-order-error {
            padding: 2rem 0;
            text-align: center;
            &-message {
                font-size: 1.2rem;
                font-weight: normal;
            }
        }
    }
}

.uppercase {
    span {
        text-transform: uppercase !important;
    }
}

.fullWidthInput {
    width: 100%;
}

.financial {
    &__section {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
    }
    &__section-block {
        width: 32%;
        margin-right: 2rem;
        min-height: 30.2rem;
        margin-bottom: 2rem;
        position: relative;
        &:nth-child(3n+1) {
            margin-left: 0;
        }
        &:nth-child(3n+3) {
            margin-right: 0;
        }
        @include breakpoint(mediumScreen) {
            width: 31.5%;
        }
        &.addBlock {
            border: 2px solid $borderColor;
            min-height: 27.8rem;
            width: 30.3%;
            display: flex;
            align-items: center;
            span {
                position: relative;
                margin: auto;
                width: 18rem;
                height: 18rem;
                background: $darkerWhite;
                border-radius: 100%;
                text-align: center;
                padding-top: 0;
                line-height: 1.75;
                font-size: 10rem;
                color: $brand;
                cursor: pointer;
                @include transition (.3s all ease-in-out);
                &:hover {
                    color: $darkerBrand;
                }
            }
        }
        .multiselect {
            position: relative;
            &__input {
                margin-bottom: 0;
                color: $black;
                font-size: 1.2rem;
            }
        }
        .common__input-box {
            margin-top: 1rem;
        }
    }
}


.custom-content {
    position: relative;
    .remove__button {
        right: 0;
        top: 0;
    }
    .file-container .remove__button {
        right: -5px;
        top: -8px;
    }
    .content__button {
        background-color: $brand;
        color: $white;
        padding: 1.5rem 4rem;
        text-transform: uppercase;
        cursor: pointer;
        font-family: $regular;
        border: 0;
        font-size: 1rem;
        border-radius: 5px;
        outline: none;
        margin-top: 2rem;
    }
    .quill-editor {
        margin-top: 2rem;
    }
    .ql-editor {
        min-height: 15rem;
        background-color: $darkerWhite;
        p {
            font-family: $regular;
            font-size: 1.2rem;
        }
    }
    .ql-editor.ql-blank::before {
        color: $black;
        font-style: normal;
        font-weight: 500;
        font-family: $regular;
        font-size: 1.2rem;
    }
    .ql-toolbar.ql-snow {
        padding: 2rem;
    }
    .ql-snow.ql-toolbar button,
    .ql-snow .ql-toolbar button {
        padding: .3rem 0;
    }
    .financial {
        &__section {
            margin-top: 2rem;
        }
        &__section-block {
            .fixMarginTop {
                margin-top: 1rem;
            }
            &.addBlock {
                width: 31.5%;
                min-height: 27.2rem;
            }
        }
    }
    label {
        font-size: 1.1rem;
        letter-spacing: 0px;
        color: $smallColor;
        font-weight: 500;
        margin-bottom: 1.5rem;
        display: block;
    }
    .multiselect {
        input {
            display: block;
            padding: 2rem;
            width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            font-size: 1.2rem;
            outline: none;
            border: 1px solid $investmentBorder;
            font-family: Montserrat, sans-serif;
            -webkit-transition: 0.3s all ease-in-out;
            transition: 0.3s all ease-in-out;
            font-weight: 500;
            background-color: $resultsBackground;
            border-radius: 3px;
            color: $black;
        }
    }
}

.custom-buttons {
    display: flex;
    align-items: center;
    background: $black;
    h4 {
        color: $white;
        font-size: 2.6rem;
        margin-right: 4rem;
    }
    button {
        margin: 0 2rem !important;
    }
}

.form__buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    z-index: 100;
    a {
        font-size: 1.2rem;
        display: inline-block;
        font-weight: 500;
        margin: 0 2rem;
        text-transform: uppercase;
    }
    &-brand {
        color: $brand;
    }
    &-red {
        color: $red;
    }
    &-green {
        color: $positive;
    }
}

.ql-editor {
    min-height: 10rem;
}

// companyNew class has been moved to /assets/scss/pages/pages.company/page.company-edit.scss
// TODO rename companyNew class on deal form, and rename it here
.companyNew {
    padding-left: 20.5%;
    background-color: $editorBackground;
    padding-top: 5rem;
    padding-bottom: 9rem;
    overflow-x: hidden;
    @-moz-document url-prefix() {
        overflow-x: hidden;
    }
    .moveQuestion {
        .question {
            top: 4.4rem;
        }
    }
    .companyNew__input {
        &.inline-input {
            display: inline-block;
            width: 48%;
            margin-left: 2%;
            &:nth-child(2n+1) {
                margin-left: 0;
            }
        }
        input {
            margin-top: 1rem;
        }
    }
    .lastInput {
        .number {
            border-right: 1px solid $borderColor !important;
        }
    }
    .edit__investor-preferences-left,
    .edit__investor-preferences-right {
        max-height: 120rem;
        overflow-y: auto;
    }
    #stats {
        input {
            color: $white;
        }
        .error {
            position: absolute;
            right: 0;
            bottom: -2rem;
            color: $red;
            font-size: 1.2rem;
            font-weight: 500;
            &::first-letter {
                text-transform: capitalize;
            }
        }
    }
    .errorInput {
        border: 1px solid $red !important;
    }
    .multiselect {
        &.errorInput {
            border: 0 !important;
            input {
                border: 1px solid $red !important;
            }
        }
    }
    .editor__input {
        .errorInput {
            border: 0 !important;
            .quill-editor {
                border: 1px solid $red !important;
                border-radius: 5px;
            }
        }
    }
    .multiselect__select {
        top: 1.5rem !important;
    }
    .my-dropdown-dd {
        position: relative !important;
    }
    .accordion-item-content {
        display: flex;
        flex-wrap: wrap;
    }
    .accordion-item {
        height: auto !important;
        margin: 0 0rem 1.7rem;
        padding: 0 3rem;
        border: 1px solid #ccd0d9;
        border-radius: 0.4rem;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
        &:last-child {
            margin-bottom: 0.1rem;
        }
        @include breakpoint (toDesktop) {
            margin: 0 2rem 1.7rem;
        }
        .option-box {
            &:nth-child(4n+4) {
                margin-right: 0;
            }
            &:nth-child(2n+2) {
                @include breakpoint (toDesktop) {
                    margin-right: 0;
                }
            }
        }
        .accordion-item-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            h3,
            i {
                font-size: 1.5rem;
                font-weight: 500;
                cursor: pointer;
                margin: 2rem 0;
                @include transition (.3s all ease-in-out);
                @include breakpoint (toDesktop) {
                    font-size: 2.4rem !important;
                }
            }
        }
        .option-box {
            margin-right: 1.7rem;
            font-weight: 700;
            font-size: 1.1rem;
            border: 1px solid #ccd0d9;
            border-radius: 0.4rem;
            width: 17.0rem;
            min-height: 6.8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 1.8rem;
            margin-bottom: 2rem;
            cursor: pointer;
            @include breakpoint(smallLaptop) {
                width: 16.4rem;
            }
            &.selected {
                background-color: $positive;
                color: $white;
            }
            @include breakpoint (toDesktop) {
                font-size: 2.4rem;
                line-height: 2.8rem;
                width: 31.4rem;
                height: 12.8rem;
            }
        }
    }
    #stats {
        input {
            color: $white;
            background-color: transparent;
        }
        .select__big {
            input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        .select__small {
            input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
    &__input {
        >span {
            display: block;
            text-align: right;
            font-size: 1.2rem;
            letter-spacing: 1px;
            color: $smallColor;
        }
    }
    &__input-holder {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        >div {
            margin-right: 3.3rem;
            &:nth-child(3n+3) {
                margin-right: 0;
            }
        }
    }
    .multiselect {
        &__input {
            margin-bottom: 2rem;
            border: 1px solid $white;
        }
        &__content {
            @extend .blackDropdown;
            border: 1px solid $white;
        }
        &__element {
            @extend .blackDropdown__list;
            &:last-child {
                border-bottom: 0;
            }
        }
        &__option {
            &--highlight {
                background-color: $white;
                color: $black;
            }
        }
        &__select {
            &:before {
                @extend .blackDropdown__arrow;
            }
        }
    }
    .select__small {
        .multiselect__input {
            border-right: 0;
        }
    }
    .files-holder {
        text-align: center;
        width: 100%;
        min-height: 1rem;
        position: relative;
        .drag-icon {
            position: absolute;
            top: 3.8rem;
            left: 1rem;
            cursor: move;
            cursor: -webkit-grabbing;
            cursor: -moz-grabbing;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
    .files-holder .file-container img {
        max-width: 100%;
    }
    .files-holder .file-container {
        margin-left: -7rem;
        width: 100% !important;
        display: inline-block;
        padding-right: 0rem;
        padding-left: 7rem;
        margin-top: 4rem;
        position: relative;
        border: 0px solid $borderColor;
        border-radius: 5px;
        input {
            border: 1px solid $borderColor;
            &:focus {
                border: 1px solid $black;
            }
        }
    }
    @include button-link();
    .buttons-holder {
        &:first-of-type {
            margin-top: 5rem;
        }
    }
    .imageHolder {
        width: auto;
        margin: 3rem auto;
        &--input {
            margin-top: 0;
            input {
                border-radius: 0;
                border: 0;
                border-top: 1px solid $borderColor;
                &:focus {
                    border: 0;
                    border-top: 1px solid $borderColor;
                }
            }
        }
    }
    .video-js {
        width: 100%;
        height: 400px;
    }
    .video-js .vjs-tech {
        position: inherit !important;
    }
    .vjs-icon-placeholder {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        font-size: inherit;
        letter-spacing: 0;
    }
    .vjs-big-play-button {
        top: 0rem !important;
        left: 0rem !important;
        right: 0;
        text-align: center;
        margin: auto;
        bottom: 0;
    }
    .video-js .vjs-big-play-button {
        font-size: 3rem;
        line-height: 1.5em;
    }
    .ql-clipboard {
        left: 0;
    }
    &__cover {
        height: calc(100vh - 6.8rem);
        position: relative;
        background-size: cover !important;
        background: $black 50% 50%;
        >span {
            position: absolute;
            top: 0;
            left: 0;
            right: 41rem;
            bottom: 0;
            font-size: 1.2rem;
            color: $white;
            letter-spacing: 1px;
            >input {
                opacity: 0;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                width: 24.5rem;
                height: 14rem;
                z-index: 1;
                cursor: pointer;
            }
        }
        &-text {
            position: absolute;
            top: 3rem;
            left: 3rem;
            background-color: $brand;
            padding: 2rem 2rem;
            border-radius: 5px;
            text-transform: uppercase;
            font-weight: 500;
        }
        &-info {
            position: absolute;
            right: 0;
            background-color: rgba(0, 0, 0, 0.7);
            height: calc(100vh - 10.8rem);
            overflow-y: auto;
            width: 41rem;
            padding-top: 4rem;
            .errorHolder {
                position: relative;
                .error {
                    position: absolute;
                    right: 0rem;
                    bottom: 0.2rem;
                    font-size: 1.05rem;
                    color: $red;
                    &::first-letter {
                        text-transform: capitalize;
                    }
                }
            }
            .companyNew__input textarea,
            .companyNew__input input {
                padding: 2rem 3rem;
                padding-bottom: 1rem;
                font-family: Montserrat, sans-serif;
                background-color: $darkerWhite;
                border-radius: 5px;
            }
            form {
                padding-left: 2.4rem;
                overflow-y: auto;
                padding-right: 2rem;
                height: 100%;
            }
            .black-label {
                color: $white;
                font-size: 1.2rem;
                font-weight: 400;
                display: block;
                margin-bottom: 1rem;
                letter-spacing: 1px;
            }
            .black-input {
                width: 100%;
                box-sizing: border-box;
                background: transparent;
                outline: none;
                font-size: 1.2rem;
                color: $white;
                margin-bottom: 2rem;
                border-radius: 5px;
                padding: 2rem;
                font-family: $regular;
                border: 1px solid $white;
                @include transition(.3s all ease-in-out);
                &:focus {
                    border: 1px solid $black;
                }
            }
            &-header {
                background-color: $white;
                position: absolute;
                bottom: 3rem;
                left: 3rem;
                padding: 4rem;
                z-index: 1;
                background-size: 25%;
                background-position: 5% 50%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                &-text {
                    float: right;
                }
                h2 {
                    padding: 2rem 5rem;
                    font-size: 1.2rem;
                    letter-spacing: 0;
                    background-color: $brand;
                    border-radius: 5px;
                    font-weight: 500;
                    color: $white;
                    margin: 0;
                    text-transform: uppercase;
                    flex-grow: 1;
                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        opacity: 0;
                        cursor: pointer;
                        width: 100%;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
    &__description {
        margin-top: 2.5rem;
        padding: 6rem 17.5rem;
        border: 1px solid $investmentBorder;
        .error {
            position: absolute;
            text-transform: inherit;
            right: 0;
            bottom: -2rem;
            font-size: 1.1rem;
            letter-spacing: 1px;
            color: $red !important;
            &::first-letter {
                text-transform: capitalize;
            }
        }
        &.businessVision {
            margin-top: 2rem;
            margin-bottom: 2rem;
            .companyNew__button {
                button {
                    background-color: $white;
                    &:hover {
                        background-color: $black;
                    }
                }
            }
        }
    }
    .blind-description {
        margin-top: 2rem;
    }
    .metric {
        background-color: $darkBlack;
        padding: 6rem 17.5rem;
        @include button-link();
        .traction {
            background-color: $darkBlack;
            margin-top: -1px;
            border: solid 1px $tagBackground;
            border-radius: 5px;
            padding: 5rem;
            margin-bottom: 2.7rem;
            position: relative;
            &:first-of-type {
                padding-left: 0;
                padding-bottom: 2rem;
                border: none;
                border-radius: 0;
            }
            .specialWidth {
                width: 10% !important;
                margin-top: 0rem;
                .addNew {
                    width: 7rem;
                    padding: 1.4rem;
                }
            }
            h2 {
                margin-bottom: 1.5rem;
            }
            p {
                color: $white;
                font-size: 1.4rem;
            }
            .companyNew__biggerTitle {
                color: $white;
                font-weight: 400;
            }
            .multiselect__content {
                top: 5.6rem;
            }
            .companyNew__input {
                width: 30.1%;
                display: inline-block;
                vertical-align: bottom;
                position: relative;
                &--new {
                    >input {
                        border: 0;
                        padding: 0;
                        margin-bottom: 0.5rem;
                        &::placeholder {
                            color: $white;
                            font-size: 1.1rem;
                            letter-spacing: 1px;
                        }
                    }
                    .companyNew__input-mask {
                        position: relative;
                    }
                }
                .currency {
                    position: absolute;
                    top: 36.5%;
                    left: 3rem;
                    color: $white;
                    font-weight: 400;
                    font-size: 1.2rem;
                    width: 2rem;
                }
            }
            .question {
                color: $darkBlack;
                cursor: default;
                &:hover {
                    cursor: default;
                }
            }
            label {
                color: $white;
                font-size: 1.1rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
            }
            .select__big {
                input {
                    margin-bottom: 0;
                    border-right: 0;
                    color: $white;
                }
            }
            .paddingRight {
                input {
                    padding-left: 5rem;
                }
            }
            .select__small {
                input {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    border-right: 1px solid $white;
                    margin-bottom: 0;
                }
            }
            .select__half {
                flex-basis: 50%;
                width: 50%;
                &.left {
                    input {
                        margin-bottom: 0;
                        border-right: 0;
                        color: $white;
                        border-radius: 5px 0 0 5px;
                    }
                }
                &.right {
                    input {
                        padding-left: 1rem;
                        padding-right: 1rem;
                        border-right: 1px solid $white;
                        margin-bottom: 0;
                        border-radius: 0 5px 5px 0;
                    }
                }
            }
        }
    }
    .laptopValue {
        .currency {
            top: 46.5% !important;
        }
    }
    .projection {
        .addPhoto {
            height: 39.5rem;
            width: 100%;
            background-color: $black;
            position: relative;
            margin-top: 5rem;
            background-size: cover;
            cursor: pointer;
            >input {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                opacity: 0;
                outline: none;
                cursor: pointer;
            }
            >span {
                position: absolute;
                top: 2rem;
                left: 2rem;
                width: 15rem;
                bottom: 33rem;
                color: $white;
                font-weight: 400;
                font-size: 1.5rem;
                background: $black;
                input {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 15rem;
                    bottom: 0;
                    padding-top: 4.5rem;
                    cursor: pointer;
                    z-index: 1;
                }
            }
            &__add {
                position: absolute;
                padding: 1.3rem 0rem;
                text-align: center;
                display: block;
                width: 100%;
                z-index: 0;
            }
            .material-icons {
                right: -1.4rem;
            }
        }
    }
    .management {
        margin-top: 2rem;
        &__block {
            width: 30.5%;
            display: inline-block;
            vertical-align: top;
            margin: 7rem 2rem;
            position: relative;
            @include breakpoint(smallLaptop) {
                width: 29.5%;
            }
            .remove__button {
                right: -6px;
                &.remove-document {
                    right: 8px;
                    top: 8px;
                }
            }
            >.error {
                position: absolute;
                bottom: -2rem;
                font-size: 1.1rem;
                letter-spacing: 1px;
                font-weight: 500;
                left: 0;
            }
            .error__down {
                position: absolute;
                bottom: -3rem;
                left: -7rem;
                right: -7rem;
                text-align: center;
                text-shadow: initial;
                font-weight: 600;
                font-size: 1.1rem;
            }
            &:nth-child(3n+1) {
                margin-left: 0;
            }
            &:nth-child(3n+3) {
                margin-right: 0;
            }
            &-addImage {
                text-align: center;
                background: $black;
                width: 20rem;
                height: 20rem;
                margin: 0 auto;
                position: relative;
                background-size: contain;
                cursor: pointer;
                border-radius: 100%;
                margin-top: 4rem;
                background-position: 50% 50%;
                span {
                    font-size: 1.5rem;
                    color: $white;
                    font-weight: 400;
                    margin-top: 9rem;
                    display: inline-block;
                    text-shadow: 1px 1px 3px $black;
                }
            }
            &-imageHolder {
                .remove__button {
                    right: 1.5rem;
                    top: 2rem;
                    cursor: pointer;
                }
                input {
                    position: absolute;
                    width: 20.5rem;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    cursor: pointer;
                }
            }
            &-addDocument {
                background-color: $black;
                height: 35rem;
                position: relative;
                background-size: 45% 60%;
                background-position: 50%;
                cursor: pointer;
                @include breakpoint(smallLaptop) {
                    height: 25rem;
                }
                .remove__button {
                    right: -8.6rem;
                    top: 4rem;
                }
                .delete-document {
                    color: $white !important;
                }
                span {
                    position: absolute;
                    top: 2rem;
                    left: 2rem;
                    font-weight: 400;
                    color: $white;
                    font-size: 1.5rem;
                    width: 15rem;
                    z-index: 0;
                    &.add-btn {
                        font-weight: 600;
                        display: inline-block;
                        margin: 15rem 15rem 0 9.5rem;
                        top: 0;
                        left: 0;
                    }
                }
                >span {
                    color: $white;
                    font-weight: 400;
                    font-size: 1.5rem;
                    background: $black;
                    input {
                        opacity: 0;
                        position: absolute;
                        width: 30rem;
                        height: 31rem;
                        z-index: 1;
                        cursor: pointer;
                        &.add-btn {
                            padding: 0 9rem 2rem 8rem;
                        }
                    }
                }
            }
        }
        .companyNew__input {
            margin-top: 2rem;
            input,
            textarea {
                width: 100%;
            }
            textarea {
                min-height: 10rem !important;
                overflow-y: auto;
            }
        }
        .companyNew__input-no-overflow {
            textarea {
                overflow-y: hidden;
                resize: none;
            }
        }
        .addBlock {
            border: 1px solid $investmentBorder;
            height: 76rem;
            span {
                text-shadow: transparent;
            }
            .management__block-addImage {
                background-color: $darkerWhite;
                margin-top: 26rem;
                span {
                    font-size: 10rem;
                    color: $brand;
                    margin-top: 3.6rem;
                }
            }
        }
    }
    .documents {
        .companyNew__input {
            margin-top: 2rem;
            input {
                width: 100%;
            }
        }
        .addBlock {
            border: 1px solid $investmentBorder;
            height: 45.6rem;
            .management__block-addImage {
                background-color: $darkerWhite;
                margin-top: 12rem;
                border-radius: 100%;
                span {
                    font-size: 10rem;
                    color: $brand;
                    margin-top: 3.6rem;
                    text-shadow: inherit;
                }
            }
        }
        .edit__investor-preferences {
            margin: 7rem 0;
            padding: 0;
            border: 0;
            display: flex;
            align-items: flex-start;
            &-left {
                width: 25%;
                flex-basis: 25%;
                &-block {
                    border-bottom: 1px solid $borderColor;
                    padding: 1.5rem;
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: $black;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &:last-child {
                        border-bottom: 0;
                    }
                    &.activeSection {
                        color: $brand;
                    }
                    &-main {
                        text-transform: uppercase;
                    }
                }
            }
            &-right {
                margin-left: 2rem;
                margin-top: 0;
                width: 75%;
                flex-basis: 75%;
                border: 0px solid $borderColor;
                flex-grow: inherit;
            }
            &-section {
                position: relative;
                margin-bottom: 2rem;
                box-sizing: border-box;
                &:last-child {
                    margin-bottom: 0.1rem;
                }
                input {
                    font-family: $regular;
                    padding: 1.5rem 3rem 1.5rem 2rem;
                    border: 0;
                    outline: none;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 1.2rem;
                    &::placeholder {
                        color: $smallColor;
                        font-weight: 600;
                    }
                }
                i {
                    position: absolute;
                    right: 1.5rem;
                    top: 1.3rem;
                }
            }
            .search {
                background-color: $darkerWhite;
                label {
                    padding: 2rem;
                    display: inline-block;
                    vertical-align: middle;
                }
                input {
                    width: 75%;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            .scroller {
                height: 50rem;
            }
        }
        .management__block-addDocument {
            input {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                text-indent: -9999px;
                color: transparent;
                opacity: 0;
                outline: none;
                cursor: pointer;
                z-index: 1;
            }
        }
    }
    &__smallerTitle {
        margin: 0;
        text-align: left;
        font-weight: 400;
        margin-bottom: 1.5rem;
        display: block;
        color: $tagBackground;
        font-size: 1.4rem;
        line-height: 1.86;
    }
    &__biggerTitle {
        margin: 0;
        text-align: left;
        letter-spacing: 0;
        font-size: 2.6rem;
        font-weight: 600;
        color: $black;
        display: flex;
        text-transform: none;
        margin-bottom: 15px;
        align-items: center;
    }
    &__input {
        margin-top: 4rem;
        position: relative;
        &-mask {
            position: relative;
            .minimised-button {
                position: absolute;
                top: 4px;
                right: -6px;
                cursor: pointer;
                z-index: 2;
            }
            .editor-wrapper {
                padding-top: 10px;
                .counter {
                    position: absolute;
                    top: -14px;
                    right: 0;
                }
            }
            .error {
                position: absolute;
                right: 0rem;
                bottom: -2rem;
                font-size: 1.05rem;
                color: $red;
                &::first-letter {
                    text-transform: capitalize;
                }
                &__down {
                    top: auto;
                }
            }
            .question {
                background-color: $investmentBorder;
                width: 1.7rem;
                height: 1.7rem;
                border-radius: 100%;
                color: $white;
                position: absolute;
                top: 1.8rem;
                left: -1.2rem;
                font-size: 1.2rem;
                padding: .3rem;
                z-index: 2;
                cursor: default;
                margin: 0 auto;
                text-align: center;
                &:hover {
                    cursor: default;
                }
            }
        }
        &-bold {
            font-weight: 900;
            font-size: 1.2rem;
        }
        label {
            font-size: 1.1rem;
            letter-spacing: 0px;
            color: $smallColor;
            font-weight: 500;
            margin-bottom: 0rem;
            display: block;
        }
        textarea,
        input {
            display: block;
            padding: 2rem 3rem;
            width: 100%;
            box-sizing: border-box;
            font-size: 1.2rem;
            outline: none;
            border: 1px solid $investmentBorder;
            font-family: Montserrat, sans-serif;
            @include transition (.3s all ease-in-out);
            font-weight: 600;
            background-color: $darkerWhite;
            border-radius: 5px;
            &::placeholder {
                font-weight: 500;
                font-style: italic;
                color: $smallColor;
            }
            &:focus {
                border: 1px solid $black;
            }
        }
        .number {
            padding-left: 4.5rem;
            padding-right: 1.5rem;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
        }
        &-bigger {
            textarea {
                height: 10rem;
                font-family: Montserrat, sans-serif;
            }
        }
    }
    &__button {
        margin-top: 4rem;
        &-show {
            border: 1px solid $brand;
            background-color: $brand;
            font-size: 1.2rem;
            color: $white;
            margin-right: 2.5rem;
            border-radius: 0.7rem;
            padding: 2rem 4rem;
            outline: none;
            position: relative;
            display: inline-block;
            text-transform: uppercase;
            font-weight: 500;
            cursor: pointer;
            margin-top: 2rem;
            @include transition(.3s all ease-in-out);
            input {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
                cursor: pointer;
            }
            &:hover {
                background-color: $darkerBrand;
            }
        }
    }
    .description-bottom {
        margin-bottom: -2rem;
    }
    .addNew {
        border: 1px solid $brand;
        border-left: 1px solid;
        width: 100%;
        padding: 1rem;
        background-color: transparent;
        font-size: 2.9rem;
        color: $brand;
        outline: none;
        display: inline-block;
        cursor: pointer;
        margin-top: 0;
        border-radius: 5px;
        @include transition(.3s all ease-in-out);
        &:hover {
            color: $darkerBrand;
            border-color: $darkerBrand;
        }
    }
    .table {
        position: relative;
        .error {
            bottom: 4rem !important;
        }
        .companyNew__input {
            vertical-align: top;
            .addNew {
                border-top: 1px solid $brand;
                margin-left: 0px;
                padding: 1.3rem;
                border-radius: 0;
            }
        }
    }
    &__table {
        margin-top: 3rem;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        &-row,
        &-cell-info {
            display: inline-block;
            vertical-align: top;
            color: $white;
            margin: 0;
            font-size: 1.2rem;
        }
        &-row {
            width: 30rem;
            .companyNew__table-cell {
                border-top: 0;
                border-color: $black;
                text-transform: inherit;
                height: 1.55rem;
                &:first-child {
                    border-top: 1px solid $black;
                    padding: 3rem;
                    height: auto;
                }
            }
        }
        &-cell {
            border: 1px solid $investmentBorder;
            padding: 2rem 3rem;
            position: relative;
            .currency {
                position: absolute;
                top: 36%;
                left: 2rem;
                color: $white;
                font-weight: 400;
                font-size: 1.2rem;
                width: 2rem;
            }
            >input {
                left: 0;
                top: 0;
                width: 100%;
                box-sizing: border-box;
                border: 0;
                bottom: 0;
                background: transparent;
                outline: none;
                color: $white;
                font-size: 1.2rem;
                padding-left: 2rem;
                &::placeholder {
                    color: $white;
                }
            }
            span {
                i {
                    position: absolute;
                    right: 1rem;
                    top: 30%;
                }
            }
            &-info {
                position: relative;
                width: 13.3%;
                &:nth-child(1n+3) {
                    .multiselect {
                        input {
                            border-left: 0;
                        }
                    }
                    .companyNew__table-cell {
                        border-left: 0;
                    }
                }
                &:nth-child(7n+7) {
                    .multiselect {
                        input {
                            border-left: 1px solid $borderColor;
                        }
                    }
                    .companyNew__table-cell {
                        border-left: 1px solid $borderColor;
                    }
                }
                .companyNew__table-cell {
                    border-color: $investmentBorder;
                    border-top: 0;
                    padding: 1.85rem 3rem;
                    padding-right: 0;
                    height: 1.84rem;
                    @media screen and (min-width: 1025px) and (max-width: 1600px) {
                        padding: 1.85rem 3rem;
                    }
                    &:nth-child(2) {
                        border: 0px solid $black;
                        padding: 0;
                        height: auto;
                        .multiselect__input {
                            margin-bottom: 0;
                            padding: 2.2rem;
                        }
                    }
                }
            }
        }
    }
    .black-search {
        position: relative;
        i {
            position: absolute;
            right: 3.5rem;
            top: 3.2rem;
            color: $white;
            font-size: 3rem;
        }
    }
    .fullWidthSelect {
        .black-input {
            width: 34rem;
        }
    }
    .noBlocks {
        input {
            margin-bottom: 0rem;
        }
        .companyNew__input {
            input {
                width: 100%;
            }
        }
    }
    .rightSide {
        left: auto !important;
        right: 1rem;
        &__input {
            padding-left: 2.5rem !important;
            padding-right: 3.5rem !important;
        }
    }
    &__loader {
        opacity: 0.85;
        background-color: #000000;
        position: fixed;
        z-index: 999;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        div.loading-circle {
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 1000;
            margin: 0 auto;
        }
        span {
            left: 46%;
            position: absolute;
            top: 55%;
            font-family: Montserrat;
            font-size: 1.2rem;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: white;
        }
    }
}

// companyNew class has been moved to /assets/scss/pages/pages.company/page.company-edit.scss
// TODO rename companyNew class on deal form, and rename it here
.companyNew {
    .management__parent {
        .multiselect__single {
            font-size: 1.2rem;
            color: $black;
            background-color: transparent;
            width: 100%;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            outline: none;
            border: 1px solid $borderColor;
            display: block;
            padding: 2rem 2rem 2rem 3rem;
            border-radius: 5px;
            font-weight: 600;
        }
        .multiselect__tags {
            padding: 0;
            border: 0;
        }
    }
}
.widthFixed {
    width: 35rem !important;
    div {
        width: auto !important;
    }
    .multiselect__select {
        width: 1rem !important;
        height: 1rem !important;
        top: 2.8rem !important;
    }
    .multiselect--active {
        .multiselect__select {
            top: 2.4rem !important;
        }
    }
}

.custom-item {
    footer {
        margin-top: 0;
    }
    p {
        margin-top: 0;
        font-size: 1.2rem;
        font-weight: 500;
        line-height: 1.8rem;
    }
    header {
        text-transform: none;
        margin-bottom: 1.5rem;
    }
}

.document__lock {
    position: absolute;
    background: $brand;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    top: 10px;
    left: 10px;
    z-index: 1;
    i {
        font-size: 13px;
        color: $white;
        position: absolute;
        left: 0;
        right: 0;
        top: 3px;
        bottom: 0;
        margin: auto;
    }
}

.documentation {
    section {
        div {
            span {
                word-break: break-all;
                @-moz-document url-prefix() {
                    word-break: initial;
                    word-wrap: break-word;
                }
            }
            a {
                position: relative;
                display: inline-block;
            }
        }
    }
}

.primary__tag {
    cursor: pointer;
}

.management__block {
    >span.input-error {
        left: 0 !important;
    }
}

.management__block {
    label {
        margin-bottom: 1rem;
    }
    .error {
        position: absolute;
        bottom: -2rem;
        right: 0;
        color: $red !important;
        &::first-letter {
            text-transform: capitalize;
        }
    }
}

.relative-position {
    position: relative !important;
}

.stats-top-margin {
    margin-top: 4.1rem;
}

.stats-input-height {
    height: 5.681rem;
}

.financials-top-margin {
    margin-top: 1.4rem;
}

.deal-flex-sideabr {
    z-index: 10;
}

.documentation-margin {
    margin-bottom: -1.8rem;
}

.text-editor-margin {
    margin-top: 3rem;
}

.item-details-table {
    overflow-x: auto;
}

.deal-input {
    display: flex;
    align-items: center;
}

.deal-section>div:first-child {
    background-color: $white;
    border: 1px solid $borderColor;
    border-radius: 5px;
    padding: 20px;
    margin: 0 100px 20px;
    img {
        width: 100%;
    }
}

.deal-section>div:last-child {
    text-align: center;
    p {
        font-size: 10px;
        color: $grayLight;
    }
}

.deal-section:last-child {
    margin-bottom: 0;
}

.investor-place-order-modal {
    @include breakpoint(smallLaptop) {
        left: 60%;
        .specialSubmit {
            width: 40% !important;
            left: 60% !important;
            font-size: 1.2rem;
        }
    }
    .place-order-height {
        max-height: calc(100vh - 12rem);
    }
}

.preview-dataroom-document-modal {
    .modal-pdf {
        .do-modal {
            padding: 2rem 4rem;
        }
    }
}

.audit {
    &__container {
        ul {
            li {
                width: 680px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.deals-dropdown {
    height: 80px;
    .link {
        padding-top: 20px;
        padding-left: 20px;
        .deals-dropdown-toggle {
            .deals-dropdown-content {
                display: flex;
                flex-direction: row;
                &__left {
                    .company-syndicated-deal {
                        img {
                            position: absolute;
                            top: 13px;
                            left: 13px;
                            width: 14px;
                            height: 14px;
                        }
                    }
                    .company-logo {
                        height: 40px;
                        width: 40px;
                        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
                        object-fit: scale-down;
                    }
                }
                &__right {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-left: 10px;
                    .company-name {
                        font-size: 1.444rem;
                        font-weight: 500;
                        color: $black;
                    }
                    .company-deal-type {
                        font-size: 1rem;
                        color: $dark-gray;
                        font-weight: 500;
                    }
                }
            }
            .dropdown-icon {
                right: 20px;
                top: 35px;
                position: absolute;
                .fa {
                    color: #000000;
                }
            }
        }
    }
    .my-dropdown-dd {
        max-height: 415px;
        overflow-y: scroll;
        top: 20px !important;
        .dialog {
            ul {
                li {
                    height: 60px;
                    border-radius: 3px;
                    border: 1px #d2d4d8 solid;
                    &:last-child {
                        margin-bottom: 5px;
                    }
                    .dropdown-deal {
                        display: flex;
                        flex-direction: row;
                        padding-top: 10px;
                        padding-left: 5px;
                        .dropdown-deal-image {
                            position: relative;
                            .dropdown-company-image {
                                background-color: #ffffff;
                                height: 40px;
                                width: 40px;
                                img {
                                    height: 40px;
                                    width: 40px;
                                    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
                                    object-fit: scale-down;
                                }
                            }
                            .dropdown-syndicated-deal {
                                img {
                                    width: 14px;
                                    height: 14px;
                                    position: absolute;
                                    top: -7px;
                                    left: -7px;
                                }
                            }
                        }
                        .dropdown-deal-info {
                            display: flex;
                            flex-direction: column;
                            width: 93%;
                            justify-content: center;
                            padding-left: 10px;
                            .deal-company-name {
                                font-size: 1.444rem;
                                font-weight: 500;
                                color: $black;
                                width: 99%;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                            .deal-company-description {
                                font-size: 1rem;
                                color: $dark-gray;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }
}
