.contact-block{
  &-buttons-group {
    text-align: right;
    button{
      border: 0;
      background-color: transparent;
      font-family: $regular;
      cursor: pointer;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.1rem;
      padding: 0;
      border-radius: 2px;
      outline: none;
      position: static;
      width: auto;
      @include transition (.3s all ease-in-out);
      &:hover{
        background-color: transparent;
        color: $darkerBrand;
      }
      &.at-remove {
        color: $darkerRed !important;
      }
      &.at-select {
        color: $brand !important;
      }
    }
  }
}