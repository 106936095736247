.switch {
    position: relative;
    display: inline-block;
    width: 26px;
    height: 11px;
    background-color: $smallColor;
    border-radius: 5px;
    cursor: pointer;
    top: 1px;
    margin-right: 11px;
}

.switch input {display:none;}
.slider {
    background-color: $white;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 0;
        bottom: -3px;
        background-color: $white;
        -webkit-transition: .4s;
        transition: .4s;
        border: .5px solid $borderColor;
    }
}

input {
    &:checked + .slider:before {
        background-color: $brand;
        border: .5px solid $brand;
        -webkit-transform: translateX(10px);
        -ms-transform: translateX(10px);
        transform: translateX(10px);
    }

    &:focus + .slider {
        box-shadow: 0 0 1px $brand;
    }
}

.slider.round {
    &, &:before {
        border-radius: 100%;
    }
}
