.investors-sort__panel {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2rem;
    margin-bottom: 2rem;
    font-size: 14px;
    font-weight: 600;
    color: $color-grey-21;

    @media (max-width: 600px) {
        display: none;
    }

    // @media (min-width: 601px) and (max-width: 900px) {
    //     padding: 0;
    // }

    .sortable-item {
        display: flex;
        align-items: center;
        flex-grow: 1;
        cursor: pointer;
        position: relative;

        &.hidden {
            visibility: hidden;
            flex-grow: 0;
        }

        .sortable-icons {
            position: relative;
            .material-icons-outlined {
                display: block;
                margin-left: 5px;
                font-size: 10px;
            }

            .sort-up, .sort-down {
                position: absolute
            }

            .sort-up {
                bottom:0;
            };

            .sort-down {
                top: -1px;
            }
        }
    }

    .name-sorting {
        width: 195px;

        @media (min-width: 1021px) and (max-width: 1200px) {
            width: 170px;
        }

        @media (min-width: 951px) and (max-width: 1020px) {
            width: 160px;
        }

        @media (max-width: 950px) {
            width: 135px;
        }
    }

    .status-sorting {
        width: 110px;

        @media (min-width: 901px) and (max-width: 950px) {
            width: 100px;
        }

        // @media(max-width: 580px){
        //     width: 90px
        // }
    }

    .interest-sorting {
        width: 70px;
    }

    .data-room-sorting {
       left: 33px;
    }
}
