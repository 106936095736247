.invite-layer-wrapper {
  .layer-body {
    height: calc(100vh - 8rem);
    @include multiple-media(mediumScreen, largeScreen) {
      height: calc(100vh - 5rem);
    }
  }
  .invite-layer {
    &-inner {
      font-size: 1.2rem;
      font-weight: 500;
      color: #434a54;
      padding: 1.5rem;
      .link {
        color: $brand !important;
        cursor: pointer;
      }
    }
    .invite-btn-group{
      display: flex;
      justify-content: space-between;
    }
    .dropbtn{
      padding: 10px 20px;
    }
  }
}
.changeEditorFont{
  overflow-x: hidden;
  > span,
  > a{
    font-size: 1.2rem !important;
    line-height: 2.2rem !important;
  }
  .ql-editor p, .ql-editor li, .ql-editor strong, .ql-editor em, .ql-editor u{
    font-size: 1.2rem !important;
    line-height: 2.2rem !important;
    color: $black !important;
    font-family: $regular;
    @include breakpoint (toDesktop){
      font-size: 2.5rem !important;
      line-height: 3.2rem !important;
    }
  }
  *{
    font-size: 1.2rem!important;
    line-height: 2.2rem!important;
    color: #434a54!important;
    font-family: $regular;
    text-transform: none;
    @include breakpoint (toDesktop){
      font-size: 2.5rem !important;
      line-height: 3.2rem !important;
    }
  }
  h1,h2,h3,h4,h5,h6{
    font-weight: 800 !important;
  }
  h3:after{
    display: none;
  }
  .ql-editor ol, .ql-editor ul {
    padding-left: 0 !important;
  }
}
