.equity-deal-edit {
  overflow-y: hidden;
  &-wrapper {
    max-height: calc(100vh - 11.3rem);
    overflow-y: auto;
    padding-top: 0;
    padding-left: 20.5%;
    margin-top: 50px;
    background-color: $darkerWhiteDO;
    @include breakpoint(smallLaptop) {
      max-height: calc(100vh - 11.7rem);
    }
    &__cover {
      position: relative;
      background-size: cover !important;
      background: $color-grey-6 50% 50%;
      height: 48rem;
    }
    &__section {
      margin-top: 2rem;
      background-color: $white;
      padding: 5rem 17.5rem;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      &--header {
        &-title {
          margin: 0 0 1.5rem;
          text-align: left;
          letter-spacing: 0;
          font-size: 2.6rem;
          font-weight: 500;
          color: $color-grey-6;
          display: flex;
          text-transform: none;
          align-items: center;
        }
        &-subtitle {
          text-align: left;
          font-weight: 400;
          margin: 0 0 2.5rem;
          display: block;
          color: $color-grey-6;
          font-size: 1.4rem;
          line-height: 1.86;
        }
      }
      &--content {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        position: relative;
        &-input {
          width: 32%;
          margin-top: 2.5rem;
          margin-bottom: 0.5rem;
          @include multiple-media(tabletLandscape, smallLaptop) {
            width: 47%;
          }
        }
        .currency-input {
          &__heading {
            display: block;
            margin-bottom: .8rem;
            label {
              color: $black;
              font-size: 1.4rem;
              font-weight: 500;
            }
          }
          &__content {
            .currencyBlock {
              margin: 0;
              .currency {
                position: absolute;
                font-size: 1.4rem;
                color: $color-grey-4;
                top: 1.2rem;
                left: 1.5rem;
                @include breakpoint(smallLaptop) {
                  top: 1.3rem
                }
              }
              .number {
                border-radius: 5px 0 0 5px;
                padding-left: 4.7rem;
                height: 4rem;
                font-size: 1.4rem;
                font-weight: normal;
              }
              input {
                border: 0.1rem solid $color-grey-2;
                &:hover {
                  border-color: $color-grey-3;
                }
                &:focus {
                  background-color: $white;
                }
                &:active {
                  background-color: $white;
                  border-color: rgba(231, 233, 237, 0.5);
                }
              }
              .activated {
                input {
                  background-color: $color-white;
                }
              }
            }
          }
          .date-select {
            display: flex;
            align-items: center;
            &-single {
              .custom-radio {
                height: 3.9rem;
                @include breakpoint(smallLaptop) {
                  @-moz-document url-prefix() {
                    height: 3.9rem;
                  }
                }
                @include breakpoint(bigLaptop) {
                  height: 3.9rem;
                }
                @include breakpoint(largeScreen) {
                  height: 4rem;
                }
              }
              &:first-child {
                width: 30%;
                .multiselect__input {
                  border-radius: 5px 0 0 5px;
                }
              }
              &:nth-child(2) {
                .multiselect__input {
                  border-radius: 0;
                }
              }
            }
          }
          .share-class {
            display: flex;
            align-items: center;
            input {
              border-radius: 5px 0 0 5px;
            }
            .custom-radio {
              @media not all and (min-resolution:.001dpcm) {
                height: 4rem;
              }
              @include breakpoint(smallLaptop) {
                height: 3.9rem;
                @-moz-document url-prefix() {
                  height: 3.9rem;
                }
              }
              @include breakpoint(bigLaptop) {
                height: 3.9rem;
              }
            }
          }
          .custom-radio {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 1rem;
            height: 4rem;
            border: 1px solid $investmentBorder;
            border-left: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            &__check {
              position: relative;
              width: auto;
              margin-right: .3rem;
              height: 1.1rem;
              input[type=radio] {
                position: absolute;
                visibility: hidden;
              }
              input[type=radio]:checked ~ .check::before {
                background: $brand;
              }
              label {
                font-size: 1.1rem;
                letter-spacing: 0;
                color: $dark-gray;
                font-weight: 500;
                display: block;
                z-index: 9;
                cursor: pointer;
                height: 0;
                opacity: 0;
                margin: 0 0 1.5rem;
                vertical-align: middle;
                position: relative;
              }
              .check {
                display: block;
                position: absolute;
                border: 1px solid $color-grey-3;
                border-radius: 100%;
                height: 15px;
                width: 15px;
                top: -30%;
                z-index: 0;
                transition: border .25s linear;
                -webkit-transition: border .25s linear;
                &::before {
                  display: block;
                  position: absolute;
                  content: "";
                  border-radius: 100%;
                  height: 8px;
                  width: 8px;
                  top: 3px;
                  left: 4px;
                  margin: auto;
                  transition: background 0.25s linear;
                  -webkit-transition: background 0.25s linear;
                }
              }
            }
            &__holder {
              width: 93%;
              flex-basis: 93%;
              label {
                letter-spacing: 0;
                color: $black;
                font-weight: 500;
                display: block;
                margin-bottom: 0!important;
                margin-top: 0;
                font-size: 1.4rem;
                cursor: pointer;
              }
            }
          }
        }
        .no-tbd {
          .currencyBlock {
            .number {
              border-radius: 5px
            }
          }
        }
        .remove__button {
          position: absolute;
          right: -7px;
          top: 20px;
          cursor: pointer;
          z-index: 2;
        }
      }
      .financials {
        justify-content: flex-start;
        .equity-deal-edit-wrapper__section--content-input {
          margin-right: 2rem;
          &:nth-child(3n+3) {
            margin-right: 0;
          }
          @include multiple-media(tabletLandscape, smallLaptop) {
            &:nth-child(2n+2) {
              margin-right: 0;
            }
            &:nth-child(3n+3) {
              margin-right: 2rem;
            }
          }
        }
      }
      .timeline {
        justify-content: normal;
        @include multiple-media(tabletLandscape, smallLaptop) {
          justify-content: space-between;
        }
        &-input {
          margin-right: 2.5rem;
          @include multiple-media(tabletLandscape, smallLaptop) {
            margin-right: 0;
          }
          &-label {
            display: block;
            margin-bottom: 1rem;
            color: $color-grey-6;
            font-size: 1.4rem;
            font-weight: 500;
          }
          &-date {
            display: flex;
            align-items: flex-end;
            &-single {
              width: 30%;
              &:last-child {
                width: 40%;
              }
              .hubx-select {
                .multiselect__tags {
                  .multiselect__input {
                    border-radius: 0;
                  }
                }
              }
              &:first-child {
                .hubx-select {
                  .multiselect__tags {
                    .multiselect__input {
                      border-radius: .5rem 0 0 .5rem;
                    }
                  }
                }
              }
              &:last-child {
                .hubx-select {
                  .multiselect__tags {
                    .multiselect__input {
                      border-radius: 0 .5rem .5rem 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .terms,
      .purpose {
        margin-top: 5rem;
        .hubx-richtextarea {
          width: 100%;
        }
      }
      .purpose {
        &-table {
          width: 100%;
          &-header {
            margin-top: 4rem;
            display: flex;
            align-items: center;
            font-size: 1.4rem;
            color: $color-grey-6;
            font-weight: 500;
            &-cell {
              display: block;
              &:nth-child(2n+1) {
                width: 80%;
              }
              &:nth-child(2n+2) {
                width: 20%;
              }
            }
          }
          &-body {
            display: flex;
            align-items: center;
            &-cell {
              vertical-align: middle;
              height: 2rem;
              position: relative;
              padding: 1.1rem 0;
              &:nth-child(2n+1) {
                width: 80%;
                input {
                  border-radius: .5rem 0 0 .5rem;
                }
              }
              &:nth-child(2n+2) {
                width: 20%;
                input {
                  border-radius: 0 .5rem .5rem 0;
                }
              }
              .remove__button {
                top: 3px;
              }
            }
          }
          button {
            width: 100%;
            border-radius: 5px;
            display: block;
            font-size: 2.6rem;
            background: transparent;
            border: 1px solid $brand;
            color: $brand;
            outline: none;
            cursor: pointer;
            margin-top: 4rem;
            height: 4.2rem;
          }
          .no-data {
            margin-top: 4rem;
            &:hover {
              border: 1px solid $darkerBrand;
              color: $darkerBrand;
            }
          }
        }
      }
      .company-details {
        display: block;
        &-documentation {
          &-list {
            margin-bottom: 2.5rem;
            padding-left: 1.75rem;
            li {
              font-size: 2rem;
              font-weight: 400;
              span {
                font-size: 1.2rem;
                font-weight: normal;
                color: $color-grey-6;
                display: block;
                vertical-align: middle;
                line-height: 1.5;
                margin-left: 1rem;
              }
            }
          }
          p {
            font-size: 1.2rem;
            font-weight: normal;
            color: $color-grey-6;
            line-height: 1.83;
            margin-bottom: 2rem;
            &:first-of-type {
              margin-top: 5rem;
            }
            a {
              font-weight: 500;
            }
          }
        }
        &-editor {
          &-input {
            position: relative;
            margin-top: 3rem;
            &-table {
              padding: 6rem;
              position: relative;
              margin-top: 2.5rem;
              background-color: $white;
              border: 1px solid $investmentBorder;
              margin-bottom: 4rem;
              &-heading {
                display: block;
                margin-bottom: 1rem;
                color: $color-grey-6;
                font-size: 1.4rem;
                font-weight: 500;
              }
              .remove__button {
                top: -8px;
              }
            }
          }
          .team {
            border: 1px solid $borderColor;
            margin: 0 0 4rem;
            padding: 6rem;
            &-heading {
              display: block;
              margin-bottom: 1rem;
              color: $color-grey-6;
              font-size: 1.4rem;
              font-weight: 500;
            }
            .remove__button {
              top: -8px;
            }
            &-member {
              position: relative;
              margin: 2.5rem 2% 0 0;
              width: 32%;
              display: inline-block;
              vertical-align: top;
              &-input {
                margin-top: 1.5rem;
                textarea {
                 height: 50px;
                 min-height: 50px;
                 overflow: hidden;
                 resize: none;
                }
              }
              &-image {
                text-align: center;
                background: $color-grey-6;
                width: 20rem;
                height: 20rem;
                position: relative;
                background-size: contain;
                cursor: pointer;
                border-radius: 100%;
                margin: 4rem auto 0;
                background-position: 50% 50%;
                background-image: url(/images/default-person.jpg);
                background-repeat: no-repeat;
                &-holder {
                  font-size: 1.5rem;
                  color: $white;
                  font-weight: 400;
                  margin-top: 9rem;
                  display: inline-block;
                  text-shadow: 1px 1px 3px $color-grey-6;
                  input[type=file] {
                    position: absolute;
                    width: 20.5rem;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    opacity: 0;
                    cursor: pointer;
                  }
                  .remove__button {
                    right: 1.5rem;
                    top: 2rem;
                  }
                }
                span {
                  font-weight: 400;
                  margin-top: 9rem;
                  text-shadow: 1px 1px 3px $black;
                }
                .input-error {
                  color: red;
                  font-size: 1.1rem;
                  position: absolute;
                  top: auto;
                  right: 0;
                  left: auto;
                  bottom: -2rem;
                  display: block;
                  padding: 0;
                  letter-spacing: 1px;
                }
              }
              &:nth-child(3n+2) {
                margin-right: 0;
              }
            }
          }
          .button-link {
            display: flex;
            text-align: left;
            color: $brand;
            font-size: 1.2rem;
            font-weight: 500;
            cursor: pointer;
            align-items: center;
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: fit-content;
            i {
              margin-right: 1rem;
              font-size: 1.4rem;
            }
          }
        }
      }
      .documents {
        display: block;
        margin-top: 5rem;
        &-item {
          width: 30.5%;
          display: inline-block;
          vertical-align: top;
          position: relative;
          margin: 0 2rem 4rem 0;
          .remove__button {
            top: -6px;
          }
          &-add {
            background-color: $color-grey-6;
            height: 35rem;
            position: relative;
            background-size: 45% 60%;
            background-position: 50%;
            cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            @include breakpoint(smallLaptop) {
              height: 25rem;
            }
            input[type=file] {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              text-indent: -9999px;
              color: transparent;
              opacity: 0;
              outline: none;
              cursor: pointer;
              z-index: 1;
            }
          }
          .input-error {
            display: block;
            color: red;
            font-size: 1.1rem;
            position: absolute;
            top: auto;
            right: 0;
            letter-spacing: 1px;
          }
          .size-error {
            left: 0;
            bottom: 12rem;
            padding: 0 1rem;
            z-index: 1;
          }
          &-input {
            margin-top: 2rem;
          }
        }
      }
      &--footer {
        margin-top: 5rem;
        &-button-input {
          position: relative;
          input[type=file] {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            cursor: pointer;
            width: 15rem;
          }
        }
      }
    }
    .multiselect__tags {
        .multiselect__input {
            border: none;
        }
        .multiselect__placeholder {
            padding: 1.1rem 1.5rem;
            display: block;
        }
    }
    .tags {
      .multiselect {
        margin-bottom: 2rem;
        &--active {
          .multiselect__select {
            transform: none;
          }
          .multiselect__input {
            border:1px solid $color-grey-3;
          }
        }
        &__select {
          top: 0.05rem !important;
          right: 0.15rem;
          width: 6rem;
          height: 6rem;
          background-image: url('/images/enter.png');
          background-size: cover;
          padding: 0;
          &:before {
            content: none;
          }
        }
        &__input {
          &:hover {
            border-color: $color-grey-3;
          }
        }
      }
      .no-tags .multiselect__content-wrapper {
        display: none !important;
      }
    }
    .dataroom {
      margin-bottom: 2rem;
      p:first-of-type {
        text-align: left;
        font-weight: 400;
        margin: 0 0 5rem;
        display: block;
        color: $color-grey-6;
        font-size: 1.4rem;
        line-height: 1.86;
      }
      h2:first-of-type {
        margin: 0 0 1.5rem;
        font-weight: 500;
      }
    }
  }
  .sidebar {
    &__navigation {
      top: 50px;
      &-title {
        letter-spacing: 1px;
        padding-left: 3rem;
      }
      &-footer {
        padding: 0;
        height: 6.3rem;
        margin-right: 0;
        &-left,
        &-right {
          height: 100%;
          display: flex;
          button,
          .footer-close-button {
            margin: 2rem 3.9rem 2rem 3.9rem;
            &:disabled {
              margin-top: 2.5rem;
              color: #ACADB2;
            }
          }
          .buttonSave {
            &:disabled {
              margin-top: 0;
              margin-left: 2.75rem;
            }
          }
        }
        &-left {
          border-right: 1px solid $darkerWhiteDO;
          .buttonSave {
            margin: 0 0 0 1.75rem;
          }
        }
        &-right {
          border-left: 1px solid $darkerWhiteDO;
        }
        .dropdown-content {
          right: 2rem;
          bottom: 6rem;
        }
      }
      .circle {
        margin-right: 1.6rem;
        border: 2px solid $dark-gray;
      }
      ul {
        padding-left: 3rem;
        li {
          a[class=checked] {
            color: $color-green-main;
          }
        }
      }
    }
  }
  .modal-layer-mask {
    top: 0;
    left: 0;
    z-index: 11;
  }

    &__add-document {
        font-size: 1.5rem;
        color: $white;
    }
}
