@mixin chartSide {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
}

@mixin chartSideBlock {
  z-index: 1000;
  white-space: nowrap;
  position: relative;
  .small-info {
      font-size: 1.1rem;
  }
}

.do-investor{
  .media__image{
    width: 100%;
    height: 100%;
    border: 0;
    &-big{
      max-height: 14.85rem;
      height: auto;
    }
    &-big, &-document{
      position: relative;
      &:after{
        position: absolute;
        bottom: 0rem;
        left: 0;
        right: 0;
        content: "";
        display: block;
        height: 1px;
        background-color: rgb(230, 233, 240);
        z-index: 0;
      }
    }
    &-document{
      font-size: 1.3rem;
      font-weight: 600;
      padding: 6px;
    }
  }
  .do-user__item{
    padding-bottom: 2.5rem;
  }
  &__complete{
    &Block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1.5rem 0 1rem;
      span{
        font-size: 1rem;
        color: $positive;
        font-weight: 500;
        &:first-child{
          color: $smallColor;
        }
      }
    }
  }
  &__bar{
    background-color: $layerBorder;
    height: 2px;
    width: 100%;
    &-green{
      background-color: $positive;
      height: 2px;
    }
  }
  .draggable {
    .edit {
      &__investor-preferences-parent {
        cursor: grab;

        &.sortable-chosen {
          cursor: grabbing;
        }
      }
    }
  }
  .edit__investor-preferences-parent {
    border-radius: 5px;
    margin-bottom: 2rem;
    -webkit-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.07);
    background: $white;

    .edit__investor-preferences-number {
      border-top-left-radius: 5px;
      .media__image {
        padding: 1.4rem 2rem;
        &.whiteDeal {
          font-size: 1.2rem;
        }
      }
    }
    .edit__investor-preferences-description {
      position: relative;
      padding: 1.5rem 2rem;
      .media__smallImage{
        left: 1rem;
        top: 0.5rem;
        img {
          width: 100%;
        }
      }
    }
  }
  .deals-tab {
    .media__image-big {
      max-height: 150px;
      height: 150px;
      width: 150px;
      @media screen and (min-width: 1800px) {
        max-height: 200px;
        height: 200px;
        width: 200px;
      }
    }
  }
}
.network__preview{
  overflow-y: hidden;
  .layer-container{
    overflow-y: auto;
  }
}
.do-network{
  &-total {
      margin: 15px;
      font-size: 16px;
      text-align: center;
  }
  &-chart{
    display: flex;
    margin: 0 13px;
    justify-content: center;

    &-left {
      @include chartSide;
      &-block {
        @include chartSideBlock;
        text-align: right;
        margin-right: -12.5px;
        @media screen and (max-width: 1900px) {
            margin-right: -15px;
        }
      }
    }

    &-center {
      height: 13rem;
    }

    &-right {
      @include chartSide;
      &-block {
        @include chartSideBlock;
        margin-left: -12.5px;
        @media screen and (max-width: 1900px) {
          margin-left: -15px;
        }
      }
    }
    .highcharts{
      width: 100px;
      height: 100px;
    }
    &-holder{
      padding: 2rem 2rem 1rem;
    }
    &-info{
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      font-weight: 500;
      .circle{
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 1rem;
        display: inline-block;
        margin-right: 1rem;
        vertical-align: middle;
      }
      .small-info{
        display: inline-block;
        vertical-align: middle;
      }
      &.greenContent{
        .circle{
          background-color: $positive;
        }
        .small-info{
          color: $positive;
        }
      }
      &.yellowContent{
        .circle{
          background-color: $yellow;
        }
        .small-info{
          color: $yellow;
        }
      }
      &.redContent{
        .circle{
          background-color: $red;
        }
        .small-info{
          color: $red;
        }
      }
      &.purpleContent{
        .circle{
          background-color: $color-purple-main-bright;
        }
        .small-info {
          color: $color-purple-main-bright;
        }
      }
      &.grayContent{
        .circle{
          background-color: $smallColor;
        }
        .small-info{
          color: $smallColor;
        }
      }
    }
  }
  &-holder{
    padding: 2rem;
  }

  &-sidebar {
    margin-top: 15px;
    background-color: $white;
    border: 1px solid $color-grey-14;
    padding: 0 15px 5px 15px;
      .circle {
          width: 9px;
          height: 9px;
          border-radius: 1rem;
          display: inline-block;
          margin: 0 10px 3px 0;
          vertical-align: middle;
          &.purple {
              color: $color-purple-main-bright;
          }
          &.green {
              color: $color-network-matched;
          }
          &.yellow {
              color: $color-network-invited;
          }
          &.grey {
              color: $color-network-contacts;
          }
      }

      &-header {
          background: $layerBackground;
          font-size: 1.1rem;
          color: $black;
          font-weight: 500;
          padding: 5px 5px 5px 20px;
          margin: 0 -15px 10px;
      }

      &-block {
          margin-bottom: 10px;

          &-button {
              width: 100%;
              margin-top: 5px;
          }
      }
  }

  .layer-body{
    overflow-x: hidden;
    overflow-y: auto;
  }
  .do-user__item{
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 25rem);
    width: auto;
  }

  &-matched-holder{
    padding: 0 2rem;
    height: 78px;
  }
  &-matched-block{
    display: flex;
    align-items: center;
    justify-content: initial;
    span{
      display: block;
      text-transform: uppercase;
      color: $smallColor;
    }
    &-holder{
      text-align: center;
    }
    &-big{
      font-size: 3.6rem;
      font-weight: 100;
    }
    &-small{
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 500;
    }
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }
  &-block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    border-radius: 3px;
    padding: 5px 20px;
    &.greyStatus{
      .do-network-block__first{
        a{
          color: $gray;
        }
      }
      .do-network-block__third{
        button{
          color: $brand;
          &:hover{
            color: $darkerBrand;
          }
        }
      }
    }
    &.investorStatus{
      border-color: $red;
      .do-network-block__first{
        @media screen and (max-width: 1199px) {
          border-bottom: 1px solid $red;
        }
        a{
          color: $red;
        }
      }
      .do-network-block__third{
        button{
          color: $red;
          &:hover{
            color: $darkerRed;
          }
        }
      }
      .investor-contact-info {
        border-left: 1px solid $red !important;
      }
    }

    &.greenStatus{
      border-left: 2px solid $color-grey-25;

      .do-network-block__first{
        a{
          color: $positive;
        }
      }

      .do-network-block__third{
        button{
          color: $positive;
          &:hover{
            color: $darkerGreen;
          }
        }
      }

      .do-line{
        background-color: $borderColor;
      }
    }
    &.yellowStatus{
       border-left: 2px solid $orange-badge;
      .do-network-block__first{
        a{
          color: $yellow;
        }
      }
      .do-network-block__third{
        button{
          color: $brand;
          @include transition (.3s all ease-in-out);
          &:hover{
            color: $darkerBrand;
          }
        }
      }
      .do-line{
        background-color: $borderColor;
      }
    }
    &.matchStatus {
      border-left: 3px solid $colorCheck;
    }
    &.noDealsStatus {
      border-left: 3px solid $color-purple-main-bright;
    }
    &__first{
      position: relative;
      width: 78px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1199px) {
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 30px;
        border-bottom: 1px solid #d2d4d8;
        a {
          &:first-child {
            padding-left: 16px;
          }
        }
      }
      a{
        display: block;
        margin: 0.25rem 0;
        color: $smallColor;
        padding-left: 10px;
        &:first-child{
          font-size: 1.2rem;
          font-weight: 400;

          @include multiple-media(mediumScreen, largeScreen) {
            width: 100%;
            text-align: center;
            padding-left: 0;
          }
        }
        &:nth-child(2){
          font-size: 0.9rem;
          font-weight: 400;
        }
      }
    }
    &__second{
      display: flex;
      align-items: center;
      width: 100%;
      flex-flow: wrap;
      @media screen and (max-width: 1199px) {
        padding-left: 16px;
      }
      .todo-widget__block-right-info {
        width: calc(100% - 70px);
        @media screen and (max-width: 899px) {
          width: 100%;
        }
      }
      .media__info-title{
        font-weight: 500;
        color: $black;
        font-size: 1.3rem;
        @include transition (.3s all ease-in-out);
        cursor: pointer;
        flex-flow: wrap;
        &:hover{
          color: $brand;
        }
      }
      .media__info-desc{
        font-weight: 400;
        color: $smallColor;
        font-size: 0.9rem;
        cursor: pointer;
        &.preferences-score-element {
          float: left;
          color: black;
          font-size: 1.2rem;
        }
      }
      .media__image {
        width: 40px;
      }
    }
    &__third{
      align-self: center;
      padding-right: 15px;
      button{
        border: 0;
        background-color: transparent;
        color: $brand;
        font-family: $regular;
        cursor: pointer;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.1rem;
        @include transition (.3s all ease-in-out);
        &:hover{
          color: $darkerBrand;
        }
      }
    }
  }
  .three-dots{
    > span{
      position: relative;
    }
    .hub-drop-block{
      top: 0.9rem;
      right: 1rem;
    }
  }
  .todo-widget__block-right-info:after{
    display: none;
  }
  .fa-caret-down:before{
    content: "\f0d7" !important;
  }
  &__info-label-nav {
    font-size: 1rem;
    font-weight: 500;
    margin-top: 2.5rem;
    margin-left: 1rem;
    @include multiple-media(mobileScreen, tabletScreen) {
      display: none;
    }
  }
}
.relationship-owner{
  .do-security-brand {
    margin: 0 auto;
    margin-top: 2rem;
    display: block;
    font-size: 1.2rem;
  }
  &__modal{
    padding: 2rem;
    background: $white;
    max-height: calc(100vh - 20rem);
    overflow-y: auto;
    overflow-x: hidden;
    .common__radio{
      margin-bottom: 0;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $borderColor;
      padding-left: 6rem;
      min-height: 4rem;
      display: flex;
      align-items: center;
      input{
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      label{
        margin-bottom: 0;
      }
    }
    .checkmark{
      top: 38%;
      left: 2rem;
    }
    .radio-container{
      border-radius: 5px;
      border: 1px solid $borderColor;
      margin-bottom: 2rem;
    }
    .common__radio .checkmark:after{
      top: 23%;
    }
    .media__image-document-profile{
      label{
        margin-bottom: 0;
      }
    }
    .do-security-block-footer{
      padding-left: 0;
      a{
        padding: 1.2rem 6rem;
      }
    }
    .radio-group{
      background-color: $darkerWhite;
    }
  }
}
.media__image-document-profile{
  display: flex;
  align-items: center;
  p{
    width: 17rem;
    font-size: 1.1rem;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-unassigned{
    p{
      text-align: center;
    }
  }
  & > div{
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    img{
      max-width: 100%;
    }
  }
}
.relationship-owner-btn {
  padding-bottom: 0 !important;
  padding-left: 0 !important;
}
.last-radio{
  .checkmark:after{
    top: 21%;
  }
  .common__radio{
    border-bottom: 0;
  }
}
.relationship-owner-title {
  font-size: 1.2rem !important;
}
.radio-container .radio-group.checked,
.radio-container .radio-group.last-radio.checked {
  background: #fff;
}
.new-block-small{
  justify-content: space-between;
  width: 10rem;
  font-weight: 500;
  display: flex !important;
  font-size: 1.1rem;
  line-height: 1.8rem;
  span{
    text-transform: none !important;
  }
  &-left{
    width: 80%;
    text-align: right;
  }
  &-right{
    width: 20%;
    text-align: left;
    margin-left: 0.5rem;
  }
}
.invite__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  h3{
    margin-bottom: 0;
    font-size: 1.4rem;
    &:after{
      display: none;
    }
  }
}

.invite__block {

  &.invite__block--message {
    .my-dropdown-dd {
      padding-top: 4rem;
    }
  }

  .my-dropdown-dd {
    position: relative !important;
  }

  span {
    color: $smallColor;
    font-size: 1.1rem;
    display: block;
    margin-bottom: 0.5rem;

    &.errorText {
      font-size: 1rem;
      margin-bottom: 0px;
    }
  }

  .grayText {
    margin-top: 5rem;
  }

  .red {
    display: block;
    color: $red;
    font-size: 1.1rem;
    font-weight: normal;
    margin-top: 2rem;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      position: relative;
      color: $white;
      background: $brand;
      left: inherit;
      width: 9rem;
      right: inherit;
      bottom: inherit;
      padding: 1.9rem 0.5rem;
      margin-left: 3rem;
      border-radius: 5px;
    }
  }

  .common__input-box.emailContent.showError {
    .common__modalBox-inner {
      border-color: $red;
    }

    &:before {
      top: 1.25rem;
      @include breakpoint (toDesktop) {
        top: 1.25rem !important;
      }
    }
  }
  .disabled-invitation-link {
    background-color: $color-grey-1;
    pointer-events: none;
  }
}
.invitation-layer {
  .do-modal {
    .layer-body__top {
      button[class=common__submitForm] {
        &:disabled {
          opacity: 0.3;
          &:hover {
            background-color: $white !important;
            color: $brand !important;
            cursor: default;
          }
        }
      }
    }
  }
}

.network-wrapper {
  .modal-close {
    .save-btn {
      margin-left: 15px;
    }
  }
}

.network-body-left-wrapper {
  @media screen and (max-width: 899px) {
    width: 100%;
    .layer-body__middle-filters {
      width: 100%;
      flex-basis: 100%;
      .layer-body__middle-filters-holder {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    overflow-x: scroll;
    padding-top: 10px;
  }
}
.network-body-right-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1024px) {
    overflow-x: scroll;
    padding-top: 10px;

  }
  @include multiple-media(smallScreen, mediumScreen) {
    width: calc(100% - 220px);
  }
 @include breakpoint(largeScreen){
    width: calc(100% - 270px);
  }
  .investors-and-contacts,
  .matched-investors {
    width: 50%;
    flex-basis: unset;

    .layer-body__middle-holder-top {

      &-title-block {

        &-title {
          font-size: 18px;
        }

        &-description {
          font-size: 12px;
          color: $dark-gray;
        }
      }
    }

    @include multiple-media(mobileScreen, tabletScreen) {
      min-width: 350px;
      .layer-body__middle-holder-content {
        min-width: 350px;
        height: calc(100vh - 34rem);
      }
    }
  }
  .investors-and-contacts {
    .do-network-block {
      padding: 0;
      display: flex;
      flex-direction: row;
      @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
        flex-direction: column;
      }
    }
    .do-network-holder {
      @include multiple-media(mobileScreen, tabletScreen) {
        .media__image {
          display: none;
        }
        .media__info {
          padding-left: 0;
        }
      }
    }
    .investor-contact-info {
      width: 100%;
      display: flex;
      flex-direction: row;
      min-height: 78px;
      border-left: 1px solid #d2d4d8;
      padding: 5px 0 5px 20px;
      @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
        width: 100%;
        padding-left: 0;
        border-left: unset !important;
      }
    }
  }
  .matched-investors {
    margin-left: 20px;
    .do-network-matched-holder {
      @include multiple-media(mobileScreen, tabletScreen) {
        .media {
          &__image {
            display: none;
          }
          &__info {
            padding-left: 0;
          }
        }
      }

      .media__info-desc.preferences-score-element {
          float: left;
          color: black;
          font-size: 1.2rem;
      }
    }
    .todo-widget__filters-older {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}
.network-top-left {
  flex-basis: unset !important;
  width: auto !important;
}
.network-top-right {
  flex-basis: unset !important;
  width: 100% !important;
  a {
    @include breakpoint(mobileScreen) {
      &:nth-child(2),
      &:nth-child(3) {
        display: none;
      }
    }
    @include breakpoint(tabletScreen) {
      &:nth-child(2) {
        display: none;
      }
    }
  }
}

.bottom-margin {
  margin-bottom: 45px;
}
