.companyNew,
.dealNew{
.input-error {
    color: red !important;
    font-size: 1.1rem !important;
    position: absolute;
    top: auto !important;
    right: 0rem !important;
    left: auto !important;
    bottom: -2rem !important;
    display: block !important;
    padding: 0 !important;
    &.errorLarge{
      bottom: 11rem !important;;
      left: 1rem !important;
      z-index: 3 !important;
    }
    &.size-error{
      left: 0 !important;
      bottom: 11rem !important;
      padding: 0 1rem !important;
      z-index: 1;
    }
  }
}
  .hub-drop-block .drop-body{
    padding: 0;
    font-size: 0.7rem;
    background: $white;
    a{
      color: $black;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 500;
      display: block;
      @include transition (.3s all ease-in-out);
      border-bottom: 1px solid $borderColor;
      padding: 1rem;
      margin-bottom: 0;
      &:hover{
        color: $brand;
      }
    }
  }
#assessment{
    .input-gr > div{
        position: relative;
        width: 48%;
    }
    .input-gr > div > input{
        width: auto;
    }
    .input-error{
        right: 0 !important;
        top: 0.5rem !important;
    }
}
.dealNew{
  .companyNew__table-cell-info .companyNew__table-cell{
    height: 5.54rem !important;
  }
  .table .companyNew__table-cell-info .companyNew__table-cell:nth-child(2){
    height: 6.2rem !important;
  }
}
.text-editor-render {
  h1,h2,h3,h4,h5,h6{
    font-size: 1.8rem;
    margin: 0;
    line-height: 4rem;
    font-weight: 700;
    font-family: $regular;
    letter-spacing: 0;
    text-transform: none;
    text-align: inherit;
    color: $black;
    border: none;
    padding: 0;
  }
  p,li,strong,em,u,font{
    font-size: 14px;
    line-height: 2.2rem;
    margin: 0;
    color: $black;
  }
  ul,ol,dl{
    padding-left: 1.2rem;
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    color: $black;
    @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
      font-size: 2.5rem;
      line-height: 4rem;
    }
  }
}
.boxShadow{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.10);
}