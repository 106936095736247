
.founders-layer-container {
    overflow: hidden;
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    display: flex;
}
.founders-nav-title-content {
    height: 24px;
    font-size: 18px;
    padding-top: 3px;
    font-weight: bold;
    margin-left: 30px;
    margin-top: 18px;
    margin-bottom: 18px;
    width: 50%;
    @include breakpoint(mobileScreen) {
        width: 100%;
    }
}

.big-container {

    flex-direction: row;

    &__left {
        width: 16%;
        z-index: 1000;
        opacity: 0.3;
        background-color: #000000;

        @include multiple-media(smallScreen, tabletScreen, mobileScreen) {
            display: none;
        }

    }

    &__right {
        flex: 7 7 20em;
        flex-direction: row;
        flex-wrap: wrap;
        background-color: #fafbfd;
        overflow: auto;

        @include multiple-media(largeScreen) {
            min-width: 1000px;
        }

        &__navigation {
            display: flex;
            height: 60px;
            margin-bottom: 5px;
            background-color: $color-white;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
            justify-content: space-between;
            position: relative;

            &__nav-title{
                font-weight: bold;
                width: 50%;
            }
            &__nav-close{
                display: flex;
                width: 50%;
                .close-position {
                    margin-left: auto;
                    margin-right: 35px;
                    margin-top: 23px;
                }
            }
        }
        &__main {
            display: flex;
            justify-content: space-between;
            background:  #fafbfd;

            @include multiple-media(tabletScreen, mobileScreen) {
                min-height: auto;
                width: 100%;
                flex-direction: column;
                overflow-y: scroll;
                display: block;
                height: calc(100% - 60px);
            }

            &__side-bar {
                width: 270px;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                background-color: $white;
                margin-top: -5px;

                @include multiple-media(tabletScreen, mobileScreen) {
                    width: 100%;
                }

                @include breakpoint(tabletScreen) {
                    height: 210px;
                }
            }
            &__main-area {
                overflow: hidden;
                width: 100%;

                &__no-side-bar {
                    width: 100%;
                }
            }
        }


    }
}
.small-container {
    &__left {
        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
            width: calc(100% - 680px);
        }
        opacity: 0.3;
        background-color: #000000;
    }

    &__right {
        flex-direction: row;
        flex-wrap: wrap;
        background-color: #fafbfd;
        width: 680px;
        @include multiple-media(tabletScreen, mobileScreen) {
            width: 100%;
        }

        &__navigation {
            background-color: $white;
            height: 60px;
            display: flex;
            position: relative;
            padding-bottom: 2px;
            justify-content: space-between;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

            &__nav-title{
                font-family:  'Nunito', sans-serif;;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                width: 50%;
            }
            &__nav-title-content{
                height: 24px;
                font-size: 18px;
                padding-top: 3px;
                font-weight: bold;
                margin-left: 30px;
                margin-top: 18px;
                margin-bottom: 18px;
                width: 50%;
            }
            &__nav-close{
                display: flex;
                width: 50%;
                .close-position {
                    margin-left: auto;
                    margin-right: 35px;
                    margin-top: 23px;
                }
            }
        }
        &__main {
            display: flex;
            justify-content: space-between;
            min-height: 100%;
            background:  #fafbfd;
            height: 60px;
            position: relative;

            @include multiple-media(tabletScreen, mobileScreen) {
                flex-direction: column;
            }

            &__side-bar {
                width: 250px;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                background-color: $white;

                @include multiple-media(tabletScreen, mobileScreen) {
                    width: 100%;
                }

            }
            &__main-area {
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                flex-basis: 100%;
                overflow-y: scroll;
            }

            .search-input-list-footer {
                bottom: 7rem !important;
            }
        }
    }
    &__right_data_room {
        min-width: 1080px !important;
        width: 1080px !important;
    }
}
.small-container__right_data_room .small-container__right__main__main-area {
    padding:15px;
}
.x-close {
    width: 14px;
    height: 14px;
    cursor: pointer;

    &__inner {
        height: 14px;
        width: 2px;
        margin-left: 12px;
        background-color: black;
        transform: rotate(45deg);
        Z-index: 1;

        &__md {
            height: 14px;
            width: 2px;
            background-color: black;
            transform: rotate(90deg);
            Z-index: 2;
        }
    }
}

.modal-body{
    height: 100%;
    background-color: #fafbfd;
}
.no-overflow {
    overflow-y: hidden;
}
