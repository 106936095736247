.new-company-creation {
  max-height: calc(100vh - 15rem);
  form {
    padding: 2rem;
    background: $white;
  }
  > div {
    display: flex;
  }
  .search-matching-company {
    @include radioGroupMixin;
    .radio-group {
      label {
        font-size: 1.4rem;
        position: relative;
        bottom: 7px;
        margin-top: 11px;
      }
      p {
        margin-bottom: 0;
        font-size: 1.4rem;
        line-height: 1.46;
        letter-spacing: normal;
        text-align: justify;
        color: $black;
        padding-left: 3.8rem;
        margin-top: 0;
      }
      &:first-of-type {
        border-top: 1px solid #ececec;
      }
      padding: 1.5rem;
    }
    width: 100%;
    .search-input {
      padding: 2rem;
      background-color: $white;
    }
    &__link {
      background-color: transparent;
      text-transform: uppercase;
      font-size: 1.2rem;
      border: 0;
      color: $brand;
      padding: 0;
      font-weight: 500;
      outline: none;
      cursor: pointer;
      -webkit-transition: 0.3s all ease-in-out;
      transition: 0.3s all ease-in-out;
      margin-right: 1.3rem;
      &:hover {
        color: $darkerBrand;
      }
    }
    .search-input-list {
      padding: 0;
      background: $white;
      position: relative;
      max-height: calc(100vh - 18rem);
      overflow-x: hidden;
      overflow-y: auto;
      h4 {
        color: $grayLight;
        font-size: 1.8rem;
        font-family: $regular;
        padding: 2rem;
      }
      &-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem;
        background-color: $white;
        border-top: 1px solid $borderColor;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }
  }

}
