.dashboard-wrapper{
	margin-top: 7.5%;
	&.container{
		width: 95% !important;
		padding: 0;
	}
	.tab-box-footer{
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.5rem;
		.left-line{
			margin: 0;
			width: 50%;
			flex-basis: 50%;
			text-align: center;
		}
	}
	.side-block{
		width: 25%;
		margin-left: 2rem;
		max-height: 80rem;
		overflow-y: auto;
		overflow-x: hidden;
		@include breakpoint (toDesktop){
			width: 100%;
			display: block;
			margin-left: 0;
		}
	}
	.tab-block{
		padding: 2.6rem 3.5rem;
		padding-left: 2.8rem;
		padding-bottom: 0;
		display: flex;
		justify-content: space-between;
    	a{
			font-size: 1.4rem;
			@include breakpoint (toDesktop){
				font-size: 2.2rem;
			}
    	}
	}
	.tab-content{
		margin: 1rem 2rem;
		.tab-box-wrapper{
			padding: 1.5rem;
		}
	}
	.tab-box .tab-box-header .pointer{
		font-size: 1.4rem;
		padding-top: 1.5rem;
	    padding-left: 2rem;
		padding-right: 2rem;
		@include breakpoint (toDesktop){
			font-size: 2.4rem;
		}
	}
	.tab-content .tab-box-wrapper .tab-box-days{
		@include breakpoint (toDesktop){
			font-size: 2.4rem;
		}
	}
	.tab-box .tab-box-header .arrow{
		color: #e6e9ed;
	}
	.tab-box .tab-box-header > div{
		font-size: 1.4rem;
	    padding-left: 2rem;
		padding-right: 2rem;
		padding-top: 1.5rem;
		@include breakpoint (toDesktop){
			font-size: 2.4rem;
		}
	}
	.tab-box .tab-box-header > div small{
		@include breakpoint (toDesktop){
			font-size: 2.4rem;
			line-height: 3.2rem !important;
		}
	}
	.tab-box .tab-box-header > div.arrow{
		@include breakpoint (toDesktop){
			font-size: 5rem;
		}
	}
	.tab-box .tab-box-header > div.investor-pointer{
		cursor: pointer;
		@include transition (.3s all ease-in-out);
		&:hover{
			color: $brand;
		}
	}
	.tab-box .tab-box-header > div small, .modal-header .modal-box-header > div small{
		line-height: 1.8rem;
	}
	.tab-box .tab-box-header > div.arrow, .modal-header .modal-box-header > div.arrow{
		padding-left: 0rem;
		margin-left: 0rem;
		padding: 0;
	}
	.tab-box .tab-box-header, .modal-header .modal-box-header{
		padding-bottom: 1.2rem;
	}
	.tab-box .tab-box-content{
		padding: 0.3rem 2rem;
	}
	.tab-box-content.with-button > p{
		margin-right: 1rem;
		@include breakpoint (toDesktop){
			font-size: 2rem;
		}
	}
	.tab-box-content > a{
		@include breakpoint (toDesktop){
			font-size: 2rem;
		}
	}
	.tab-box-content > p{
		@include breakpoint (toDesktop){
			font-size: 2.4rem;
			line-height: 3.2rem;
		}
	}
	.tab-box-footer p{
		@include breakpoint (toDesktop){
			font-size: 2.4rem;
		}
	}
	.tab-box-footer small{
		@include breakpoint (toDesktop){
			font-size: 1.9rem !important;
		}
	}
	.tab-box .tab-box-footer p{
		padding: 1.5rem 1rem;
	}
	.tab-box .tab-box-header > div > a{
		font-size: 1.2rem;
		@include breakpoint (toDesktop){
			font-size: 2.2rem;
		}
	}
	.tab-box .tab-box-header > div{
		flex-basis: 45%;
		font-weight: 600;
		color: $black;
	}
	.tab-box .investor-pointer{
		flex-basis: 45%;
	}
	.tab-box .view-btn{
		flex-basis: 10% !important;
	}
	.tab-box .tab-box-content{
		line-height: 2rem;
    	font-weight: 500;
	}
	.tab-box .tab-box-header{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.tab-box .tab-box-header > div{
		&:nth-child(1){
			font-weight: 600;
		}
	}
}
.profile-element{
	cursor: pointer;
    background: white;
    position: relative;
    padding-top: 1rem;
    padding-left: 1rem;
    padding-bottom: 0.7rem;
	a, i{
		display: inline-block;
		vertical-align: middle;
	}
	i{
		font-size: 3.5rem;
		color: $brand;
		margin-left: -1.5rem;
 	    padding-right: 1.5rem;
    	transform-origin: 35%;
    	@include transition (.3s all ease-in-out);
    	&.rotateIcon{
    		transform: rotate(180deg);
    	}
	}
}
.noDisplay{
	display: none;
}
nav.navbar{
	z-index: 10;
	.openMask{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $darkBlack;
		z-index: 1;
		opacity: 0.7;
		@include transition (.3s all ease-in-out);
	}
	.openDropdown{
		display: block;
		position: fixed;
		top: 64px;
	    left: 0;
	    background: $white;
	    width: 44rem;

	    &__admin{
	    	padding: 2rem 0;
	    	font-size: 1.5rem;
	    	padding-left: 3rem;
	    	span{
	    		color: $black;
	    	}
	    	@include transition (.3s all ease-in-out);
	    	&:hover{
	    		background-color: $black;
	    		span{
	    			color: $white;
	    		}
	    	}
		}
		&__list{
			&-search{
				padding-left: 3rem;
				background-color: $investmentBorder;
				position: relative;
				input{
					width: 99.8%;
				    padding: 2rem 0;
				    outline: none;
				    border: 0;
				    font-size: 1.6rem;
				    background-color: $investmentBorder;
				   	color: $investmentTitle;
				    &::placeholder{
				    	color: $investmentTitle;
				    }
				}
				i{
					color: $investmentTitle;
					font-size: 3.5rem;
					position: absolute;
					top: 1.3rem;
					right: 1.3rem;

				}
			}
			&-results{
				.showAdmin,
				.showWl{
					display: block;
				}
				ul{
					margin: 0;
					padding-left: 0;
					li{
						list-style: none;
						position: relative;
						padding: 2rem 0;
						padding-left: 3rem;
						font-size: 1.5rem;
						border-bottom: 1px solid $borderColor;
						@include transition (.3s all ease-in-out);
						cursor: pointer;
						span{
							color: $black;
						}
						&:hover{
							background-color: $black;
							border-bottom: 1px solid $black;
							span{
								color: $white;
							}
						}
					}
				}
				.showAdmin,
				.showWl{
					position: absolute;
					top: 2.5rem;
					right: 2.5rem;
					border: 1px solid $borderColor;
					color: $investmentTitle;
					padding: 0.3rem 1.3rem;
					font-size: 1.2rem;
				}
				.showWl{
					    right: auto;
					    top: -0.1rem;
					    left: 100%;
					    margin-left: 2rem;
					    padding: 0.3rem 0.8rem;
				}
				&-name{
					position: relative;
				}
				&-special{
					background-color: $darkerWhite;
					.openDropdown__list-results-name{
						color: $brand;
					}
				}
			}
		}
	}
}
.getStarted{
	h2{
		margin-bottom: 0;
	}
	p{
		font-weight: 500;
		font-size: 1.3rem;
		color: $black;
		margin-bottom: 2rem;
	}
	.disabledButton{
		color: $smallColor;
	}
	.listButton{
		color: $green;
	}
	&__container{
		&-item{
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 2.5rem;
			h3{
				margin-bottom: 0.5rem;
				font-size: 1.5rem;
				text-align: left;
				@include breakpoint (toDesktop){
					font-size: 2.5rem;
				}
			}
			p{
				font-size: 1rem;
				color: $smallColor;
				margin: 0;
			}
			&-description{
				flex-grow: 1;
				text-align: left;
				padding-left: 2.5rem;
			}
			&-number{
				padding: 2rem 3rem;
				font-size: 3rem;
				width: 2rem;
				text-align: center;
				font-weight: 300;
				color: $smallColor;
				border-right: 1px solid $borderColor;
			}
			&-button{
				padding-right: 3rem;
				font-size: 1.3rem;
				a{
					color: $brand;
				}
			}
		}
		.completed__check{
			display: none;
		}
		.completed{
			.item-number{
				display: none;
			}
			.completed__check{
				display: block;
				margin-left: -0.5rem;
				color: $green;
			}
			.getStarted__container-item-description{
				h3,p{
					text-decoration: line-through;
				}
			}
			.getStarted__container-item-button.hideGetStartedButton{
				display: none;
			}
		}
	}
	&__popup{
		.getStarted__widget{
			display: flex;
			align-content: flex-start;
			justify-content: space-between;
			margin-top: 4rem;
			.getStarted__container{
				width: 80%;
				padding-right: 3rem;
				max-height: 75vh;
				overflow-y: scroll;
			}
			.getStarted__sidebar{
				width: 23%;
				img{
					width: 10rem;
					margin: 0 auto;
					display: block;
				}
				&-info{
					padding: 2rem;
					color: $smallColor;
					display: block;
					font-size: 1.3rem;
				}
				&-checkDate {
					margin-top: 5vh;
				}
				a#pending {
					background-color: $darkerWhite;
					cursor: default;
					color: $dark-gray;
				}
				a{
					background-color: $acceptBackground;
					color: $blue;
					padding: 2rem;
					display: block;
					text-align: center;
				}
			}
		}
		.getStarted__container-item-button a{
			color: $smallColor;
			margin-left: 3rem;
		}
		.yes{
			.blueButton{
				color: $blue;
			}
		}
		.maybe{
			.orangeButton{
				color: $brand;
			}
		}
		.no{
			.redButton{
				color: $red;
			}
		}
		.update-badge {
			font-size: 1.5rem;
			input {
				margin-left: 3rem;
			}
			button {
				margin-left: 4rem;
				cursor: pointer;
			}
		}
	}
}
.dashboard-wrapper .tab-box .tab-box-content {
	display: flex;
	align-items: center;
}
.placed__order .tab-box-header-verification{
	padding: 2rem;
	background-color: $darkerWhite;
	color: $black;
	font-size: 1.2rem;
	font-weight: 600;
}
.placed__order .tab-box-header{
	.view-btn{
		display: none !important;
	}
	&-name{
		display: inline-block;
		vertical-align: middle;
	}
	.investor-pointer{
		font-weight: 600;
		small{
			margin-top: 0.3rem;
			font-size: 1.2rem;
			font-weight: 600;
			color: $smallColor;
		}
	}
}
.placed__order .tab-box-content{
	display: block !important;
	&-title{
		color: $smallColor;
		font-size: 1.1rem;
		display: block;
		margin: 1rem 0;
	}
	&-block{
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.4rem;
		color: $brand;
	}
}
.placed__order .tab-box-footer{
	padding: 0.3rem 2rem;
	&-title{
		color: $smallColor;
		font-size: 1.1rem;
		display: block;
		margin: 1rem 0;
	}
	p{
		font-size: 1.1rem;
		font-weight: 500;
		line-height: 1.5rem;
		padding: 0 !important;
		margin-bottom: 2rem !important;
	}
}
.placed__order .tab-box-confirm{
	text-transform: uppercase;
	display: flex;
    justify-content: center;
	cursor: pointer;
	.placed__button{
		padding: 2rem;
		font-size: 1.5rem;
		font-weight: 500;
		flex-basis: 100%;
		text-transform: none;
		width: 100%;
		text-align: center;
		&-confirm{
			color: $blue;
			background-color: $acceptBackground;
		}
		&-request{
			color: $blue;
			background-color: $acceptBackground;
		}
		&-dismiss{
			color: $red;
			background-color: $errorBackground;
		}
	}
}
.badge{
	display: inline-block;
	vertical-align: middle;
	margin-left: 0.5rem;
	margin-top: -0.5rem;
	img{
		width: 13px;
		display: inline-block;
		vertical-align: middle;
	}
	span{
		display: inline-block;
		vertical-align: middle;
		margin-left: 0.5rem;
	}
	&__approved{
		color: $approvedColor;
	}
	&__pending{
		color: $yellow;
	}
	&__denied{
		color: $red;
	}
}

.network-investor-type-badge {
    font-size: 9px;
    border-radius: 5px;
    color: $white;
    background-color: $color-grey-25;
    padding: 3px 8px;
    white-space: nowrap;
    margin: 0 4px 4px 0;
}

.purple {
    background: $color-network-no-deals;
}

.orange {
    background: $color-network-invited;
}

.green {
    background: $color-network-matched;
}

.grey {
    background: $color-network-contacts;
}

.purple {
    background: $color-network-no-deals;
}

.orange {
    background: $color-network-invited;
}

.green {
    background: $color-network-matched;
}

.grey {
    background: $color-network-contacts;
}

.withoutBackground {
    background: white;
}

.navbar.dashboard{
	border-left: 0;
	border-right: 0;
	border-top: 0;
	@include breakpoint (toDesktop){
		display: flex;
	}
}
.dashboard-wrapper{
	@include breakpoint (toDesktop){
		margin-top: 11rem;
		display: block;
		width: auto !important;
		padding: 0rem;
	}
	.modelclose-steps{
		.close{
			margin-top: 0rem;
			font-size: 4.4rem;
		}
	}
	.modal-body2 > section hr {
		margin-bottom: 0.5rem;
	}
	.modal-footer{
		position: absolute;
		bottom: 0;
		right: 0;
		left: 0;
		box-shadow: none;
		&:before{
			-webkit-box-shadow: 0px 1.1rem 0.8rem 0px #dcdee2;
			box-shadow: 0px 1.1rem 0.8rem 0px #dcdee2;
			left: 0;
			right: 0;
			height: 1px;
			content: '';
			display: block;
			position: absolute;
			bottom: 6.8rem;
			@include breakpoint (toDesktop){
				bottom: 9.8rem;
			}
		}
	}
	.modal-body2 section > div label{
		font-size: 1.2rem;
		margin-bottom: 0;
		@include breakpoint (toDesktop){
			font-size: 2.2rem;
		}
	}
	.modal-body2 > section{
		margin-bottom: 2rem;
	}
}
.investorModalPopup{
	bottom: 0;
	top: 0;
}
.dashboard-wrapper .modal .investorMask{
	background-color: transparent;
}
.dashboard-wrapper .modal-content3{
	width: 48.47%;
	@include breakpoint (toDesktop){
		width: 100%;
	}
	p{
		font-weight: 600;
		font-size: 1.5rem;
		color: $black;
		@include breakpoint (toDesktop){
			font-size: 2.5rem;
		}
	}
	h1{
		font-size: 1.7rem;
		font-weight: 600;
		color: $black;
		@include breakpoint (toDesktop){
			font-size: 2.7rem;
		}
	}
}
.desktopShow{
	@include breakpoint (toDesktop){
		display: none;
	}
}
.closeMobile{
	position: absolute;
    top: 3rem;
	right: 3rem;
}
