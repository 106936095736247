.do-insights{
  background-color: $white;
  padding: 2rem;
  border-radius: 5px;
  display: block;
  &__item{
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    &:last-child{
      margin-bottom: 0;
    }
    &-time{
      width: 10rem;
      span{
        color: $smallColor;
        font-weight: 400;
        font-size: 0.9rem;
      }
    }
    &-text{
      span{
        color: $black;
        font-weight: 400;
        font-size: 0.9rem;
      }
      a{
        color: $brand;
        font-weight: 400;
        font-size: 0.9rem;
        cursor: pointer;
      }
    }
  }
}