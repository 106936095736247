.founders-dataroom-wrapper {
    .do-dataroom {

        &.files-tab .body-block {
            border-radius: 5px;
            padding: 4rem 2rem;
            margin: 15px 0px 15px 0;
        }

        &__navigation {
            top: 0;
            height: 60px;
            display: flex;
            font-size: 28px;

            div {
                height: 100%;
                width: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-bottom: 2px transparent solid;
                color: $color-grey-20;

                @include multiple-media(smallMobileScreen) {
                    height: 97% !important;
                }

                &.active {
                    color: $color-green-main-2;
                    border-color: $color-green-main-2;
                    z-index: 1000;
                }

                &:hover {
                    color: $color-green-main-2;
                }
            }
        }

        &__breadcrumbs {
            padding: 15px;
            border-bottom: 1px solid $borderColor;

            input {
                padding: 0.5rem;
            }

            a {
                color: $color-green-main-2;
                font-size: 1.6rem;
                font-weight: 400;
                display: inline-block;
                margin-right: 5rem;
                position: relative;

                &:before {
                    content: "\F054";
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;
                    color: $smallColor;
                    font-size: 1rem;
                    position: absolute;
                    top: 0.5rem;
                    left: -3rem;
                }

                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }

        #drop-area {
            position: relative;

            .dataroom-progress-bar {
                position: absolute;
                top: -2rem;
                z-index: 1;
            }

            .progress-message {
                font-size: 1.2rem;
                color: $black;
                height: 1.2rem;
                margin-top: 4px;
                font-weight: 500;
            }
        }

        &__holder-item {
            display: flex;
            align-items: center;
            flex-grow: 1;
        }

        &__body {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 0 !important;

            &-right {
                position: relative;
                width: 100%;

                &-block {
                    max-height: calc(100vh - 55rem);
                    overflow-x: hidden;
                    overflow-y: auto;
                    min-height: 15rem;

                    @include multiple-media(mobileScreen) {
                        max-height: calc(100vh - 10rem);
                    }

                    .search-loader {
                        text-align: center;
                        background-color: $white;
                        padding: .5rem 0;
                    }
                }

                &-image {
                    width: 3rem;
                    height: 3rem;
                    margin-right: 1.5rem;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 3rem;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }

                    i {
                        color: $color-green-main-2;
                    }

                    .pdf-icon {
                        color: #ff1919;
                    }

                    .doc-icon {
                        color: #0d5aa7;
                    }

                    .xls-icon {
                        color: #0d904f;
                    }

                    .ppt-icon {
                        color: #ce8735;
                    }

                    .image-icon {
                        color: #deb40e;
                    }
                }

                &-first {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    flex-grow: 1;
                    word-break: break-all;

                    &.fullEdit {
                        flex-grow: 1;

                        .do-dataroom__body-right-text {
                            &.investor-new-folder {
                                width: 77% !important;
                            }

                            width: 100% !important;
                        }
                    }
                }

                &-last {
                    width: 3rem;

                    &.investor {
                        margin-left: -30px;
                    }

                    > span {
                        .more-items__hub-block {
                            right: 7px;
                            @include breakpoint(largeScreen) {
                                right: 6px;
                            }
                            @include multiple-media(mobileScreen, tabletScreen, tablet) {
                                top: 3rem;
                            }
                            @media not all and (min-resolution: .001dpcm) {
                                @supports (-webkit-appearance:none) {
                                    right: 7px
                                }
                            }
                            @-moz-document url-prefix() {
                                right: 19px;
                            }
                        }

                        i {
                            font-size: 2rem;
                        }

                        a {
                            font-size: 1.3rem;
                        }

                        .hub-drop-block {
                            z-index: 1;
                            top: 0;
                        }
                    }

                    &.fullEdit {
                        .do-dataroom__avatar {
                            width: 13rem;
                            margin-left: 2rem;

                            &.investor {
                                margin-top: 1.5rem;
                                margin-left: -80px;
                            }

                            &.investor-new-folder {
                                margin-top: -0.5rem;
                                margin-left: -92px;
                            }

                            button {
                                color: $color-blue-main;
                                margin: 0 0.5rem;
                                border: 0;
                                background-color: transparent;
                                outline: none;
                                @include transition(.3s all ease-in-out);

                                &:hover {
                                    color: $color-blue-dark;
                                }
                            }
                        }
                    }
                }

                &-item {
                    background: $white;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 15px;
                    border-bottom: 1px solid $borderColor;

                    &__create-folder-row {
                        background-color: $color-white-2;
                        display: flex;
                    }

                    &.investor {
                        border: 1px solid $smallBlockBorder;
                    }

                    .counter-parent {
                        color: $tagBackground;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 310px;
                        max-width: 310px;

                        @include multiple-media(mobileScreen) {
                            width: 10%;
                        }

                    }

                    .counter-parent-edit {
                        color: $tagBackground;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 170px;
                        max-width: 170px;

                    }

                    &:last-child {
                        border-bottom: 0;

                        .hub-drop-block {
                            top: auto;
                            bottom: 0;
                        }
                    }

                    &:first-child {
                        .hub-drop-block {
                            top: 0;
                            bottom: auto;
                        }
                    }
                }

                &-text {
                    font-size: 1.55rem;
                    font-weight: bolder;
                    color: $black;
                    display: inline-flex;
                    vertical-align: super;

                    &.investor-rename {
                        width: 75%;
                    }

                    input {
                        @extend .input;
                        background-color: $white;
                    }
                }

                &-view-count {
                    font-size: 1.4rem !important;
                    width: 155px;

                    @include multiple-media(mobileScreen) {
                        display: none;
                    }

                    span {
                        &:last-child {
                            margin-right: 5px !important;
                        }

                        &.view-count--clickable {
                            cursor: pointer;
                        }
                    }

                    .investor-number {
                        width: 140px !important;
                    }

                    &.view-count--clickable {
                        cursor: pointer;
                    }
                }

                &-view-investor-count {
                    font-size: 1.4rem !important;
                    width: 125px;

                    @include multiple-media(mobileScreen) {
                        display: none;
                    }

                    span {
                        &:last-child {
                            margin-right: 5px !important;
                        }

                        &.view-count--clickable {
                            cursor: pointer;
                        }
                    }

                    &.view-count--clickable {
                        cursor: pointer;
                    }
                }

                &-buttons {
                    padding: 1rem 0;
                    display: flex;
                    justify-content: flex-start;

                    .buttons-right {
                        margin-top: 1.5rem;
                        margin-left: auto;
                        margin-right: 0;
                    }

                    .do-button {
                        padding: 1.5rem 0rem;
                        color: $brand;
                        margin-right: 2rem;
                        border: 0;
                        background-color: transparent;
                        outline: none;
                        position: relative;
                        font-family: $regular;

                        input {
                            opacity: 0;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            width: 100%;
                            box-sizing: border-box;
                            cursor: pointer;
                            font-family: $regular;
                        }

                        @include transition(.3s all ease-in-out);

                        &:hover {
                            color: $darkerBrand;
                        }

                        &:last-child {
                            margin-left: auto;
                            margin-right: 0;
                        }

                        &--last {
                            margin-left: auto;
                            margin-right: 0;
                        }

                        &--cursor-auto {
                            cursor: auto;
                        }
                    }
                }

                .upload-box {
                    background-color: $color-white;
                    border-radius: 5px;
                    border: solid 1px $color-grey-2;
                    padding: 2rem;
                    margin-bottom: 3rem;

                    &-header {
                        display: flex;
                        justify-content: space-between;
                        padding-bottom: 1.5rem;

                        &--title {
                            font-size: 1.8rem;
                        }

                        &--close {
                            font-size: 1.8rem;
                        }

                        .upload-successful {
                            color: $color-green-dark-1;
                        }

                        .upload-unsuccessful {
                            color: $color-red-main;
                        }
                    }

                    &-body {

                        &__info {
                            margin-bottom: 1.5rem;

                            &--value {
                                font-size: 1.4rem;
                                color: $color-grey-6;
                                padding-bottom: .5rem;
                                white-space: pre;
                            }
                        }

                        &__messages {

                            &--title {
                                font-size: 1.4rem;
                                color: $color-grey-6;
                                padding-bottom: .5rem;
                            }

                            &__file {
                                display: flex;
                                padding-bottom: .5rem;
                                font-size: 1.4rem;
                                color: $color-grey-6;

                                &--name {
                                    word-break: break-all;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    width: fit-content;
                                    max-width: 15rem;
                                    display: -webkit-box;
                                    padding-right: .5rem;
                                }

                                &--error {
                                    color: $color-red-main;
                                    word-break: break-word;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    overflow: hidden;
                                    display: -webkit-box;
                                    padding-left: .5rem;
                                }
                            }
                        }
                    }
                }
            }

            &-zip-in-progress-message {
                color: $black;
                font-size: 1.4rem;
            }
        }

        &__access {
            margin-top: 2rem;
            background: $white;

            h4 {
                font-size: 1.1rem;
                text-transform: uppercase;
                font-weight: 500;
                color: $black;
                padding: 1.5rem;
                position: relative;

                &:after {
                    position: absolute;
                    bottom: 0rem;
                    left: 0rem;
                    right: 0rem;
                    height: 1px;
                    background-color: #ececec;
                    content: '';
                    display: block;
                }
            }

            &-block {
                border-bottom: 1px solid $borderColor;
                max-height: calc(100vh - 35rem);
                overflow: hidden;
                overflow-y: auto;
            }

            &-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1rem 1.5rem;
                border-bottom: 1px solid $borderColor;

                &-name {
                    width: 65%;
                }

                &:last-child {
                    border-bottom: 0;
                }

                &-left,
                &-right {
                    width: 25%;
                    flex-basis: 25%;
                }

                &-left {
                    width: 75%;
                    flex-basis: 75%;
                    display: flex;
                    align-items: center;
                }

                &-right {
                    text-align: right;
                }

                &-image {
                    width: 2rem;
                    height: 2rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 1rem;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 10px;
            border-radius: 5px;
            cursor: pointer;
            overflow: hidden;
        }

        .switch input {
            display: none;
        }

        .slider-button {
            background-color: $color-grey-20 !important;
            -webkit-transition: .4s;
            transition: .4s;
        }

        input:checked + .slider-button {
            background-color: $founderBrand !important;
        }

        .slider-button:before {
            position: absolute;
            content: "";
            height: 6px;
            width: 6px;
            left: 0.15rem;
            bottom: 1.35px;
            background-color: $white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 100%;
        }

        input:focus + .slider-button {
            box-shadow: 0 0 1px $brand;
        }

        input:checked + .slider-button:before {
            -webkit-transform: translateX(10px);
            -ms-transform: translateX(10px);
            transform: translateX(10px);
        }

        /* Rounded sliders */
        .slider-button.round {
            border-radius: 100%;
        }

        .slider-button.round:before {
            border-radius: 100%;
        }

        &__avatar {
            width: 4rem;
            height: 2rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                max-width: 100%;
                max-height: 100%;
                margin-right: 1rem;
            }

            span {
                font-size: 1.4rem;
                font-weight: 500;
            }
        }

        .noLink {
            cursor: default;
        }

        &__modal {
            .todo-dealsBlock {
                margin: 0;
            }

            .common__modalBox {
                background-color: $white;
                margin-bottom: 2rem;
                max-height: calc(100vh - 38rem);
                min-height: 17rem;
                height: auto;

                &:last-child {
                    margin-bottom: 0;
                }

                &-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid $borderColor;
                    padding: 2rem;

                    h4 {
                        font-weight: 500;
                        color: $black;
                        font-size: 1.6rem;
                    }

                    span {
                        text-transform: uppercase;
                    }
                }

                &-body {
                    padding: 2rem;

                    .media__info-desc {
                        font-size: 0.9rem !important;
                        font-weight: 400 !important;
                        color: #aab2bd !important;
                    }

                    .todo-dealsBlock {
                        margin-bottom: 2rem;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .layer-body__middle-filters-search {
            border-radius: 3px;
            margin: 15px 20px 15px 0;
            height: 22px;
            padding: 1.7em 2.2em;

            .search-box-container {
                border: 1px solid $color-grey-18;
                border-radius: 5px;
                display: flex;
                align-items: center;
                padding: 0 18px;
                width: 100%;
                height: 50px;
            }

            input {
                font-size: 16px;
                line-height: 22px;
            }

            i {
                cursor: default;
                color: $color-green-main-2;
                font-size: 14px;
            }

            .download-button {
                height: 50px;
                background-color: $color-purple-main;
                color: white;
                border-radius: 5px;
                margin-left: 20px;
                font-size: 16px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include multiple-media(mobileScreen) {
                    display: none;
                }

                i {
                    color: $color-white !important;
                    font-size: 20px !important;
                    margin-right: 10px;
                    cursor: pointer;
                }
            }
        }

        &.proraise-branding {
            .do-dataroom {
                &__breadcrumbs {
                    a {
                        i {
                            color: $color-green-main-2;
                        }
                    }
                }

                &__body {
                    &-left {
                        li.active {
                            color: $color-cyan-secondary;
                        }
                    }

                    &-right {
                        &-last.fullEdit {
                            .do-dataroom__avatar button {
                                color: $color-cyan-secondary;

                                &:hover {
                                    color: $color-cyan-secondary-darker;
                                }
                            }
                        }

                        &-block {
                            &::-webkit-scrollbar-thumb {
                                background-color: $color-purple-main;
                            }
                        }

                        &-buttons .do-button {
                            color: $color-cyan-secondary;

                            &:hover {
                                color: $color-cyan-secondary-darker;
                            }
                        }
                    }
                }
            }
            .access-and-updates__filters--buttons, .layer-body__middle-filters-search {
                border: 1px solid $color-grey-18;
            }

            .layer-body__middle-filters-search input::placeholder {
                font-style: italic;
                color: $color-grey-17;
            }
        }
    }

    .do-dataroom__modalMove {
        .common__modalBox {
            padding: 2rem 0;
        }

        ul {
            padding-left: 3rem;
            list-style: none;
            margin: 0;

            li {
                color: $brand;
                text-transform: uppercase;
                cursor: pointer;
                margin-bottom: 2rem;
                position: relative;

                &:before {
                    display: none;
                    position: absolute;
                    left: -1.5rem;
                    top: 0.4rem;
                    bottom: 0;
                    background: url(/images/subfolder.png) no-repeat;
                    content: '';
                    width: 1rem;
                    height: 1rem;
                    background-size: 100% 100%;
                }

                &.submenu {
                    &:before {
                        display: block;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                span {
                    width: 2rem;
                    height: 2rem;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 1rem;
                    margin-left: 1.5rem;

                    img {
                        max-width: 100%;
                    }
                }

                @include transition(.3s all ease-in-out);

                &:hover {
                    color: $darkerBrand;
                }

                ul {
                    margin-top: 2rem;
                }
            }
        }
    }

    .currentFolder {
        opacity: 0.3;
    }

    .selectedFolder {
        filter: brightness(65%);
    }

    .dropArea {
        padding: 3rem;
        border-radius: 3px;
        background-color: #f5f7fa;
        border: 3px dotted #d2d4d8;
        margin-top: 2rem;
        position: relative;

        div {
            text-align: center;
            font-weight: 500;
            font-size: 1.4rem;

            a {
                cursor: pointer;
            }

            input {
                display: none;
                font-size: 0;
                text-indent: -9999px;
            }
        }

        .dropText {
            color: $smallBlockBorder;

            > div {
                display: flex;
                align-items: center;
                justify-content: center;

                > span {
                    display: block;
                    margin-left: 1.2rem;
                }
            }
        }

        .linkText {
            color: $color-blue-main;
            cursor: pointer;

            .tooltipText {
                visibility: hidden;
                width: 20rem;
                background-color: $color-grey-6;
                color: $color-white;
                text-align: center;
                padding: .5rem;
                font-size: 1.2rem;
                font-weight: 600;
                border-radius: 6px;
                border: 1px solid $color-grey-6;
                position: absolute;
                z-index: 1;
                top: 75%;
                left: 55%;

                &::before,
                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 100%;
                    width: 0;
                    height: 0;
                }

                &::before {
                    right: 44%;
                    border: 10px solid transparent;
                    border-bottom-color: $color-grey-6;
                }

                &::after {
                    right: 45%;
                    border: 9px solid transparent;
                    border-bottom-color: $color-grey-6;
                }
            }

            &:hover {
                .tooltipText {
                    visibility: visible;
                }
            }
        }

        &.proraise-branding {
            .linkText {
                color: $color-cyan-secondary;
            }
        }
    }

    .dataroom-progress-bar {
        border-radius: 0;
        -webkit-appearance: none;
        width: 100%;
        margin-top: 10px;
        margin-bottom: -20px;
    }

    .dataroom-progress-bar[value]::-webkit-progress-bar {
        background-color: #eee;
        border-radius: 2px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }

    .dataroom-progress-bar::-webkit-progress-value {
        background: $positive;
        height: 1rem;
    }

    .dataroom-progress-bar::-moz-progress-bar {
        background: $positive;
        height: 1rem;
    }

    .audit {
        max-height: inherit;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        width: 100%;
        margin-top: 20px;

        &__container {
            flex: 2;
            background-color: $white;
            border-radius: 3px;
            padding: 2rem;
            height: calc(100vh - 35rem);
            overflow-y: auto;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    font-size: 1.2rem;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: $black;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .dataroom__permissions {
        background: $white;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #d2d4d8;
        border-radius: 0 0 5px 5px;

        span {
            font-size: 1.3rem;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
        }

        .switch {
            margin-left: 10px;
        }
    }

    .dataroom__permissions-block {
        max-height: inherit;
        overflow: visible;
    }

    .select-destination-button {
        width: 680px;
        @media screen and (max-width: 899px) {
            width: 540px;
        }
    }

    .data-room-files-wrapper {
        width: calc(100% - 400px);

        .do-dataroom {
            &__holder-item {
                width: 100%;

                .do-dataroom {
                    &__body-right-text {
                        width: 82%;

                        .data-room-files-file-name {
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
    }

    .data-room-folder-wrapper {
        width: calc(100% - 400px);

        .do-dataroom__body-right-text {
            width: 82%;
        }
    }
}
.founder-dropText span{
    font-size: 17px;
    color: $color-grey-17;
}
.founder-dropText .linkText {
    color: $founderBrand !important;
}
.founder-save-button, .founder-cancel-button {
    height: 46px;
    width: 90px;
    background-color: #390153 !important;
    color: white !important;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    padding: 15px;
    cursor: pointer;

    @include multiple-media(mobileScreen2) {
        height: 51px;
    }
}
.founder-save-button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.founder-cancel-button {
    border-radius: 5px;
}
.founder-toolbar {
    margin-left: -5px !important;
}
.founder-create-toolbar {
    margin-left: -25px !important;
    margin-top: 29px;

    @include multiple-media(mobileScreen2) {
        margin-top: 31px;
    }
}
.founder-rename-input {
    border-radius: 5px 0 0 5px !important;
}
.founder-create-folder {
    width: 80.52%;
    flex-grow: 0 !important;
    margin: 15px 10px 15px 10px !important;

    @include multiple-media(smallMobileScreen) {
        width: 45.52% !important;
    }

    @include multiple-media(mobileScreen2) {
        width: 59.52%;
    }

    @include multiple-media(tabletScreen2) {
        width: 70.52%;
    }
}
.founder-new-folder-input {
    border-radius: 5px 0 0 5px !important;
}
.audit-search {
    margin: 0 0 15px 0 !important;
    height: 60px !important;
}
