.user-feedback {
    textarea {
        resize: none;
    }
    &__modalTitle {
        font-size: 1.4rem;
        font-weight: 500;
        border-bottom: 1px solid #ccd0d9;
        margin-top: 2rem;
        margin-bottom: 3rem;
        padding-bottom: 1rem;
        text-align: left;
    }
}
