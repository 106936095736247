.company-edit {
    .categorySelect {
        .multiselect {
            outline: none;

            &__tags {
                padding: 0rem;
                font-size: 1.2rem;
                color: black;
                background-color: $darkerWhite;
                width: 100%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                outline: none;
                border: 0px solid $borderColor;
                margin-top: 1rem;
            }

            &__placeholder {
                padding: 1.1rem 1.5rem;
            }

            &__single {
                border: 1px solid $borderColor;
                border-radius: 5px;
            }

            &__element {
                border-bottom: 1px solid $borderColor;
            }

            &__element,
            &__option--highlight {
                background: $darkerWhite;
                color: $black;
            }

            &__select:before {
                border-color: $black transparent transparent;
            }

            &__content {
                border: 1px solid $borderColor;
                border-bottom: 1px solid $borderColor;
            }
        }
    }

    .companyNew {
        padding-left: 20.5%;
        background-color: $editorBackground;
        padding-top: 50px;
        @-moz-document url-prefix() {
            overflow-x: hidden;
        }

        .text-formatting {
            text-align: left;
            color: $dark-gray;
            font-size: 1rem;
        }

        .moveQuestion {
            .question {
                top: 4.4rem;
            }
        }

        .companyNew__input {
            &.inline-input {
                display: inline-block;
                width: 48%;
                margin-left: 2%;

                &:nth-child(2n + 1) {
                    margin-left: 0;
                }
            }

            input,
            textarea {
                margin-top: 1rem;
            }
        }

        .lastInput {
            .number {
                border-right: 1px solid $borderColor !important;
            }
        }

        .edit__investor-preferences-left,
        .edit__investor-preferences-right {
            max-height: 120rem;
            overflow-y: auto;
        }

        .errorInput {
            border: 1px solid $red !important;
        }

        .multiselect {
            &.errorInput {
                border: 0 !important;

                input {
                    border: 1px solid $red !important;
                }
            }
        }

        .editor__input {
            .errorInput {
                border: 0 !important;

                .quill-editor {
                    border: 1px solid $red !important;
                    border-radius: 5px;
                }
            }
        }

        .multiselect__select {
            top: 1.5rem !important;
        }

        .my-dropdown-dd {
            position: relative !important;
        }

        .accordion-item-content {
            display: flex;
            flex-wrap: wrap;
        }

        .accordion-item {
            height: auto !important;
            margin: 0 0rem 1.7rem;
            padding: 0 3rem;
            border: 1px solid #ccd0d9;
            border-radius: 0.4rem;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

            &:last-child {
                margin-bottom: 0.1rem;
            }

            @include breakpoint(toDesktop) {
                margin: 0 2rem 1.7rem;
            }

            .option-box {
                &:nth-child(4n + 4) {
                    margin-right: 0;
                }

                &:nth-child(2n + 2) {
                    @include breakpoint(toDesktop) {
                        margin-right: 0;
                    }
                }
            }

            .accordion-item-title {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h3,
                i {
                    font-size: 1.5rem;
                    font-weight: 500;
                    cursor: pointer;
                    margin: 2rem 0;
                    @include transition(0.3s all ease-in-out);
                    @include breakpoint(toDesktop) {
                        font-size: 2.4rem !important;
                    }
                }
            }

            .option-box {
                margin-right: 1.7rem;
                font-weight: 700;
                font-size: 1.1rem;
                border: 1px solid #ccd0d9;
                border-radius: 0.4rem;
                width: 17rem;
                min-height: 6.8rem;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                line-height: 1.8rem;
                margin-bottom: 2rem;
                cursor: pointer;
                @include breakpoint(smallLaptop) {
                    width: 16.4rem;
                }

                &.selected {
                    background-color: $positive;
                    color: $white;
                }

                @include breakpoint(toDesktop) {
                    font-size: 2.4rem;
                    line-height: 2.8rem;
                    width: 31.4rem;
                    height: 12.8rem;
                }
            }
        }

        &__input {
            > span {
                display: block;
                text-align: right;
                font-size: 1.2rem;
                letter-spacing: 1px;
                color: $smallColor;
            }
        }

        &__input-holder {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;

            > div {
                margin-right: 3.3rem;

                &:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }
        }

        .multiselect {
            &__input {
                margin-bottom: 0;
                border: 1px solid $borderColor;
            }

            &__element {
                @extend .blackDropdown__list;

                &:last-child {
                    border-bottom: 0;
                }
            }

            &__option {
                &--highlight {
                    background-color: $white;
                    color: $black;
                }
            }

            &__select {
                &:before {
                    @extend .blackDropdown__arrow;
                }
            }

            &__placeholder {
                padding: 1.8rem;
                font-size: 1.4rem;
                color: #434a54;
                background-color: transparent;
                width: 100%;
                box-sizing: border-box;
                outline: none;
                border: 2px solid #ececec;
                display: block;
            }
        }

        .select__small {
            .multiselect__input {
                border-right: 0;
            }
        }

        .files-holder {
            text-align: center;
            width: 100%;
            min-height: 1rem;
            position: relative;

            .drag-icon {
                position: absolute;
                top: 3.8rem;
                left: 1rem;
                cursor: move;
                cursor: -webkit-grabbing;
                cursor: -moz-grabbing;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
        }

        .files-holder .file-container img {
            max-width: 100%;
        }

        .files-holder .file-container {
            margin-left: -7rem;
            width: 100% !important;
            display: inline-block;
            padding-right: 0rem;
            padding-left: 7rem;
            margin-top: 4rem;
            position: relative;
            border: 0px solid $borderColor;
            border-radius: 5px;

            input {
                border: 1px solid $borderColor;

                &:focus {
                    border: 1px solid $black;
                }
            }
        }

        @include button-link();

        .buttons-holder {
            &:first-of-type {
                margin-top: 5rem;
            }
        }

        .imageHolder {
            width: auto;
            margin: 3rem auto;

            &--input {
                margin-top: 0;

                input {
                    border-radius: 0;
                    border: 0;
                    border-top: 1px solid $borderColor;

                    &:focus {
                        border: 0;
                        border-top: 1px solid $borderColor;
                    }
                }
            }
        }

        .video-js {
            width: 100%;
            height: 400px;
        }

        .video-js .vjs-tech {
            position: inherit !important;
        }

        .vjs-icon-placeholder {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            font-size: inherit;
            letter-spacing: 0;
        }

        .vjs-big-play-button {
            top: 0rem !important;
            left: 0rem !important;
            right: 0;
            text-align: center;
            margin: auto;
            bottom: 0;
        }

        .video-js .vjs-big-play-button {
            font-size: 3rem;
            line-height: 1.5em;
        }

        .ql-clipboard {
            left: 0;
        }

        &__cover {
            height: calc(100vh - 6.8rem);
            position: relative;
            background-size: cover !important;
            background: $black 50% 50%;

            > span {
                position: absolute;
                top: 0;
                left: 0;
                right: 41rem;
                bottom: 0;
                font-size: 1.2rem;
                color: $white;
                letter-spacing: 1px;

                > input {
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    width: 24.5rem;
                    height: 14rem;
                    z-index: 1;
                    cursor: pointer;
                }
            }

            .change {
                > input {
                    left: 3rem;
                    right: 0;
                    bottom: 0;
                    top: 3rem;
                    width: 10rem;
                    height: 6rem;
                }
            }

            .add-your-own-cover {
                > input {
                    left: 3rem;
                    right: 0;
                    bottom: 0;
                    top: 3rem;
                    width: 21rem;
                    height: 6rem;
                }
            }

            &-text {
                position: absolute;
                top: 3rem;
                left: 3rem;
                background-color: $brand;
                padding: 2rem 2rem;
                border-radius: 5px;
                text-transform: uppercase;
                font-weight: 500;
            }

            &-info {
                position: absolute;
                right: 0;
                background-color: rgba(0, 0, 0, 0.7);
                height: calc(100vh - 10.8rem);
                overflow-y: auto;
                width: 41rem;
                padding-top: 4rem;

                .errorHolder {
                    position: relative;

                    .error {
                        position: absolute;
                        right: 0rem;
                        bottom: 0.2rem;
                        font-size: 1.05rem;
                        color: $red;

                        &::first-letter {
                            text-transform: capitalize;
                        }
                    }
                }

                .companyNew__input textarea,
                .companyNew__input input {
                    padding: 2rem 3rem;
                    padding-bottom: 1rem;
                    font-family: Montserrat, sans-serif;
                    background-color: $darkerWhite;
                    border-radius: 5px;
                }

                form {
                    padding-left: 2.4rem;
                    overflow-y: auto;
                    padding-right: 2rem;
                    height: 100%;
                }

                .black-label {
                    color: $white;
                    font-size: 1.2rem;
                    font-weight: 400;
                    display: block;
                    margin-bottom: 1rem;
                    letter-spacing: 1px;
                }

                .black-input {
                    width: 100%;
                    box-sizing: border-box;
                    background: transparent;
                    outline: none;
                    font-size: 1.2rem;
                    color: $white;
                    margin-bottom: 2rem;
                    border-radius: 5px;
                    padding: 2rem;
                    font-family: $regular;
                    border: 1px solid $white;
                    @include transition(0.3s all ease-in-out);

                    &:focus {
                        border: 1px solid $black;
                    }
                }

                &-header {
                    background-color: $white;
                    position: absolute;
                    bottom: 3rem;
                    left: 3rem;
                    padding: 4rem;
                    z-index: 1;
                    background-size: 25%;
                    background-position: 5% 50%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    cursor: auto;

                    &-text {
                        float: right;
                    }

                    h2 {
                        padding: 2rem 5rem;
                        font-size: 1.2rem;
                        letter-spacing: 0;
                        background-color: $brand;
                        border-radius: 5px;
                        font-weight: 500;
                        color: $white;
                        margin: 0;
                        text-transform: uppercase;
                        flex-grow: 1;

                        input {
                            top: 4rem;
                            left: 14rem;
                            right: 4rem;
                            bottom: 4rem;
                            width: 17rem;
                            opacity: 0;
                            cursor: pointer;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
        &__documents {
            label.management__block-addDocument {
                display: block;
            }
        }
        &__description {
            margin-top: 2.5rem;
            background-color: $white;
            padding: 6rem 17.5rem;
            border: 1px solid $investmentBorder;

            .error {
                position: absolute;
                text-transform: inherit;
                right: 0;
                bottom: -2rem;
                font-size: 1.1rem;
                letter-spacing: 1px;
                color: $red !important;

                &::first-letter {
                    text-transform: capitalize;
                }
            }

            &.businessVision {
                margin-top: 2rem;
                margin-bottom: 2rem;

                .companyNew__button {
                    button {
                        background-color: $white;

                        &:hover {
                            background-color: $black;
                        }
                    }
                }
            }
        }

        .blind-description {
            margin-top: 2rem;
        }

        .laptopValue {
            .currency {
                top: 46.5% !important;
            }
        }

        .projection {
            .addPhoto {
                height: 39.5rem;
                width: 100%;
                background-color: $black;
                position: relative;
                margin-top: 5rem;
                background-size: cover;
                cursor: pointer;

                > input {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    opacity: 0;
                    outline: none;
                    cursor: pointer;
                }

                > span {
                    position: absolute;
                    top: 2rem;
                    left: 2rem;
                    width: 15rem;
                    bottom: 33rem;
                    color: $white;
                    font-weight: 400;
                    font-size: 1.5rem;
                    background: $black;

                    input {
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 15rem;
                        bottom: 0;
                        padding-top: 4.5rem;
                        cursor: pointer;
                        z-index: 1;
                    }
                }

                &__add {
                    position: absolute;
                    padding: 1.3rem 0rem;
                    text-align: center;
                    display: block;
                    width: 100%;
                    z-index: 0;
                }

                .material-icons {
                    right: -1.4rem;
                }
            }
        }

        .management {
            margin-top: 2rem;

            &__block {
                width: 30.5%;
                display: inline-block;
                vertical-align: top;
                margin-top: 7rem;
                margin: 7rem 2rem;
                position: relative;
                @include breakpoint(smallLaptop) {
                    width: 29.5%;
                }

                .remove__button {
                    right: -6px;

                    &.remove-document {
                        right: 8px;
                        top: 8px;
                    }
                }

                > .error {
                    position: absolute;
                    bottom: -2rem;
                    font-size: 1.1rem;
                    letter-spacing: 1px;
                    font-weight: 500;
                    left: 0;
                }

                .error__down {
                    position: absolute;
                    bottom: -3rem;
                    left: -7rem;
                    right: -7rem;
                    text-align: center;
                    text-shadow: initial;
                    font-weight: 600;
                    font-size: 1.1rem;
                }

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }

                &:nth-child(3n + 3) {
                    margin-right: 0;
                }

                &-addImage {
                    text-align: center;
                    background: $black;
                    width: 20rem;
                    height: 20rem;
                    margin: 0 auto;
                    position: relative;
                    background-size: contain;
                    cursor: pointer;
                    border-radius: 100%;
                    margin-top: 4rem;
                    background-position: 50% 50%;

                    span {
                        font-size: 1.5rem;
                        color: $white;
                        font-weight: 400;
                        margin-top: 9rem;
                        display: inline-block;
                        text-shadow: 1px 1px 3px $black;
                    }

                    .error {
                        position: absolute;
                        bottom: -1rem;
                        font-size: 1.05rem;
                        left: 0;
                        text-shadow: none;
                    }
                }

                &-imageHolder {
                    .remove__button {
                        right: 1.5rem;
                        top: 2rem;
                        cursor: pointer;
                    }

                    input {
                        position: absolute;
                        width: 20.5rem;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        opacity: 0;
                        cursor: pointer;
                    }
                }

                &-addDocument {
                    background-color: $black;
                    height: 35rem;
                    position: relative;
                    background-size: 45% 60%;
                    background-position: 50%;
                    cursor: pointer;
                    @include breakpoint(smallLaptop) {
                        height: 25rem;
                    }

                    .remove__button {
                        right: -8.6rem;
                        top: 4rem;
                    }

                    .delete-document {
                        color: $white !important;
                    }

                    span {
                        position: absolute;
                        top: 2rem;
                        left: 2rem;
                        font-weight: 400;
                        color: $white;
                        font-size: 1.5rem;
                        width: 15rem;
                        z-index: 0;

                        &.add-btn {
                            font-weight: 600;
                            display: inline-block;
                            margin: 15rem 15rem 0 9.5rem;
                            top: 0;
                            left: 0;
                        }
                    }

                    > span {
                        color: $white;
                        font-weight: 400;
                        font-size: 1.5rem;
                        background: $black;

                        input {
                            opacity: 0;
                            position: absolute;
                            width: 30rem;
                            height: 31rem;
                            z-index: 1;
                            cursor: pointer;

                            &.add-document-btn {
                                display: none;
                            }
                        }
                    }
                }
            }

            .companyNew__input {
                margin-top: 2rem;

                input,
                textarea {
                    width: 100%;
                }

                textarea {
                    min-height: 10rem !important;
                    overflow-y: auto;
                }
            }

            .companyNew__input-no-overflow {
                textarea {
                    overflow-y: hidden;
                    resize: none;
                }
            }

            .addBlock {
                border: 1px solid $investmentBorder;
                height: 76rem;

                span {
                    text-shadow: transparent;
                }

                .management__block-addImage {
                    background-color: $darkerWhite;
                    margin-top: 26rem;

                    span {
                        font-size: 10rem;
                        color: $brand;
                        margin-top: 3.6rem;
                    }
                }
            }
        }

        .documents {
            .companyNew__input {
                margin-top: 2rem;

                input {
                    width: 100%;
                }
            }

            .addBlock {
                border: 1px solid $investmentBorder;
                height: 45.6rem;

                .management__block-addImage {
                    background-color: $darkerWhite;
                    margin-top: 12rem;
                    border-radius: 100%;

                    span {
                        font-size: 10rem;
                        color: $brand;
                        margin-top: 3.6rem;
                        text-shadow: inherit;
                    }
                }
            }

            .edit__investor-preferences {
                margin: 7rem 0;
                padding: 0;
                border: 0;
                display: flex;
                align-items: flex-start;

                &-left {
                    width: 25%;
                    flex-basis: 25%;

                    &-block {
                        border-bottom: 1px solid $borderColor;
                        padding: 1.5rem;
                        font-size: 1.2rem;
                        font-weight: 600;
                        color: $black;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        &:last-child {
                            border-bottom: 0;
                        }

                        &.activeSection {
                            color: $brand;
                        }

                        &-main {
                            text-transform: uppercase;
                        }
                    }
                }

                &-right {
                    margin-left: 2rem;
                    margin-top: 0;
                    width: 75%;
                    flex-basis: 75%;
                    border: 0px solid $borderColor;
                    flex-grow: inherit;
                }

                &-section {
                    position: relative;
                    margin-bottom: 2rem;
                    box-sizing: border-box;

                    &:last-child {
                        margin-bottom: 0.1rem;
                    }

                    input {
                        font-family: $regular;
                        padding: 1.5rem 3rem 1.5rem 2rem;
                        border: 0;
                        outline: none;
                        width: 100%;
                        box-sizing: border-box;
                        font-size: 1.2rem;

                        &::placeholder {
                            color: $smallColor;
                            font-weight: 600;
                        }
                    }

                    i {
                        position: absolute;
                        right: 1.5rem;
                        top: 1.3rem;
                    }
                }

                .search {
                    background-color: $darkerWhite;

                    label {
                        padding: 2rem;
                        display: inline-block;
                        vertical-align: middle;
                    }

                    input {
                        width: 75%;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }

                .scroller {
                    height: 50rem;
                }
            }

            .management__block-addDocument {
                input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    text-indent: -9999px;
                    color: transparent;
                    opacity: 0;
                    outline: none;
                    cursor: pointer;
                    z-index: 1;
                }
            }
        }

        &__smallerTitle {
            margin: 0;
            text-align: left;
            font-weight: 400;
            margin-bottom: 1.5rem;
            display: block;
            color: $tagBackground;
            font-size: 1.4rem;
            line-height: 1.86;
        }

        &__biggerTitle {
            margin: 0;
            text-align: left;
            letter-spacing: 0;
            font-size: 2.6rem;
            font-weight: 600;
            color: $black;
            display: flex;
            text-transform: none;
            margin-bottom: 15px;
            align-items: center;

            &--inBlock {
                font-size: 1.1rem;
                color: $smallColor;
                font-weight: 500;
                margin-bottom: 1rem;
            }
        }

        &__required {
            margin-right: 1rem;
        }

        &__input {
            margin-top: 4rem;
            position: relative;

            &-mask {
                position: relative;
                margin-bottom: 2rem;

                .minimised-button {
                    position: absolute;
                    top: 4px;
                    right: -6px;
                    cursor: pointer;
                    z-index: 2;
                }

                .editor-wrapper {
                    padding-top: 10px;

                    .counter {
                        position: absolute;
                        top: -14px;
                        right: 0;
                    }
                }

                .error {
                    position: absolute;
                    right: 0rem;
                    bottom: -2rem;
                    font-size: 1.05rem;
                    color: $red;

                    &::first-letter {
                        text-transform: capitalize;
                    }

                    &__down {
                        top: auto;
                    }
                }

                .question {
                    background-color: $investmentBorder;
                    width: 1.7rem;
                    height: 1.7rem;
                    border-radius: 100%;
                    color: $white;
                    position: absolute;
                    top: 1.8rem;
                    left: -1.2rem;
                    font-size: 1.2rem;
                    padding: 0.3rem;
                    z-index: 2;
                    cursor: default;
                    margin: 0 auto;
                    text-align: center;

                    &:hover {
                        cursor: default;
                    }
                }
            }

            &-bold {
                font-weight: 900;
                font-size: 1.2rem;
            }

            label {
                font-size: 1.1rem;
                letter-spacing: 0px;
                color: $smallColor;
                font-weight: 500;
                margin-bottom: 0rem;
                display: block;
            }

            textarea,
            input {
                display: block;
                padding: 2rem 3rem;
                width: 100%;
                box-sizing: border-box;
                font-size: 1.2rem;
                outline: none;
                border: 1px solid $investmentBorder;
                font-family: Montserrat, sans-serif;
                @include transition(0.3s all ease-in-out);
                font-weight: 600;
                background-color: $darkerWhite;
                border-radius: 5px;

                &::placeholder {
                    font-weight: 500;
                    font-style: italic;
                    color: $smallColor;
                }

                &:focus {
                    border: 1px solid $black;
                }
            }

            .number {
                padding-left: 4.5rem;
                padding-right: 1.5rem;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }

            &-bigger {
                textarea {
                    height: 10rem;
                    font-family: Montserrat, sans-serif;
                }
            }
        }

        &__button {
            margin-top: 4rem;

            &-show {
                border: 1px solid $brand;
                background-color: $brand;
                font-size: 1.2rem;
                color: $white;
                margin-right: 2.5rem;
                border-radius: 0.7rem;
                padding: 2rem 4rem;
                outline: none;
                position: relative;
                display: inline-block;
                text-transform: uppercase;
                font-weight: 500;
                cursor: pointer;
                margin-top: 2rem;
                @include transition(0.3s all ease-in-out);

                input {
                    position: absolute;
                    left: 0;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    opacity: 0;
                    cursor: pointer;
                }

                &:hover {
                    background-color: $darkerBrand;
                }

                &--disabled {
                    opacity: 0.5;

                    &:hover {
                        background-color: $brand;
                    }
                }
            }
        }

        .embed-video {
            input {
                width: 17rem;
            }
        }

        .add-document {
            input {
                width: 19rem;
            }
        }

        .add-image {
            input {
                width: 15rem;
            }
        }

        .description-bottom {
            margin-bottom: -2rem;
        }

        .addNew {
            border: 1px solid $brand;
            border-left: 1px solid;
            width: 100%;
            padding: 1rem;
            background-color: transparent;
            font-size: 2.9rem;
            color: $brand;
            outline: none;
            display: inline-block;
            cursor: pointer;
            margin-top: 0;
            border-radius: 5px;
            @include transition(0.3s all ease-in-out);

            &:hover {
                color: $darkerBrand;
                border-color: $darkerBrand;
            }
        }

        .table {
            position: relative;

            .error {
                bottom: 4rem !important;
            }

            .companyNew__input {
                vertical-align: top;

                .addNew {
                    border-top: 1px solid $brand;
                    margin-left: 0px;
                    padding: 1.3rem;
                    border-radius: 0;
                }
            }
        }

        &__table {
            margin-top: 3rem;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;

            &-row,
            &-cell-info {
                display: inline-block;
                vertical-align: top;
                color: $white;
                margin: 0;
                font-size: 1.2rem;
            }

            &-row {
                width: 30rem;

                .companyNew__table-cell {
                    border-top: 0;
                    border-color: $black;
                    text-transform: inherit;
                    height: 1.55rem;

                    &:first-child {
                        border-top: 1px solid $black;
                        padding: 3rem;
                        height: auto;
                    }
                }
            }

            &-cell {
                border: 1px solid $investmentBorder;
                padding: 2rem 3rem;
                position: relative;

                .currency {
                    position: absolute;
                    top: 36%;
                    left: 2rem;
                    color: $white;
                    font-weight: 400;
                    font-size: 1.2rem;
                    width: 2rem;
                }

                > input {
                    left: 0;
                    top: 0;
                    width: 100%;
                    box-sizing: border-box;
                    border: 0;
                    bottom: 0;
                    background: transparent;
                    outline: none;
                    color: $white;
                    font-size: 1.2rem;
                    padding-left: 2rem;

                    &::placeholder {
                        color: $white;
                    }
                }

                span {
                    i {
                        position: absolute;
                        right: 1rem;
                        top: 30%;
                    }
                }

                &-info {
                    position: relative;
                    width: 13.3%;

                    &:nth-child(1n + 3) {
                        .multiselect {
                            input {
                                border-left: 0;
                            }
                        }

                        .companyNew__table-cell {
                            border-left: 0;
                        }
                    }

                    &:nth-child(7n + 7) {
                        .multiselect {
                            input {
                                border-left: 1px solid $borderColor;
                            }
                        }

                        .companyNew__table-cell {
                            border-left: 1px solid $borderColor;
                        }
                    }

                    .companyNew__table-cell {
                        border-color: $investmentBorder;
                        border-top: 0;
                        padding: 1.85rem 3rem;
                        padding-right: 0;
                        height: 1.84rem;
                        @media screen and (min-width: 1025px) and (max-width: 1600px) {
                            padding: 1.85rem 3rem;
                        }

                        &:nth-child(2) {
                            border: 0px solid $black;
                            padding: 0;
                            height: auto;

                            .multiselect__input {
                                margin-bottom: 0;
                                padding: 2.2rem;
                            }
                        }
                    }
                }
            }
        }

        .black-search {
            position: relative;

            i {
                position: absolute;
                right: 3.5rem;
                top: 3.2rem;
                color: $white;
                font-size: 3rem;
            }
        }

        .fullWidthSelect {
            .black-input {
                width: 34rem;
            }
        }

        .noBlocks {
            input {
                margin-bottom: 0rem;
            }

            .companyNew__input {
                input {
                    width: 100%;
                }
            }
        }

        .rightSide {
            left: auto !important;
            right: 1rem;

            &__input {
                padding-left: 2.5rem !important;
                padding-right: 3.5rem !important;
            }
        }

        &__loader {
            opacity: 0.85;
            background-color: #000000;
            position: fixed;
            z-index: 999;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            div.loading-circle {
                position: absolute;
                left: 50%;
                top: 50%;
                z-index: 1000;
                margin: 0 auto;
            }

            span {
                left: 46%;
                position: absolute;
                top: 55%;
                font-family: Montserrat;
                font-size: 1.2rem;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: white;
            }
        }

        .management__parent {
            .multiselect__single {
                font-size: 1.2rem;
                color: $black;
                background-color: transparent;
                width: 100%;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                outline: none;
                border: 1px solid $borderColor;
                display: block;
                padding: 2rem 2rem 2rem 3rem;
                border-radius: 5px;
                font-weight: 600;
            }

            .multiselect__tags {
                padding: 0;
                border: 0;
            }
        }
    }

    #general_info {
        .multiselect {
            &__input {
                margin-bottom: 2rem;
            }
        }
    }

    #legal_info {
        padding-bottom: 8rem;

        .companyNew {
            &__smallerTitle {
                margin-bottom: 5rem;
            }

            .table {
                .error {
                    bottom: 4rem !important;
                    right: 17.5rem;
                }
            }
        }

        label {
            margin-bottom: 1rem;
        }

        .multiselect {
            &__input {
                color: $black;
            }
        }

        .categorySelect {
            .do-details [type="radio"]:checked + label,
            .do-details [type="radio"]:not(:checked) + label {
                padding-left: 3.9rem;
            }

            .do-details [type="radio"]:checked + label:after,
            .do-details [type="radio"]:not(:checked) + label:after {
                top: 6.5px;
                left: 5.5px;
                width: 8px;
                height: 8px;
            }

            &:last-child {
                margin-top: 1rem;
            }
        }

        .radio-container {
            border-radius: 5px;
        }

        .nda-approval {
            .nda-approval-subtitle {
                font-size: 1.5rem;
                margin-bottom: 2rem;
                margin-top: 4rem;
            }

            .preview-nda {
                float: right;
                font-size: 1.1rem;
                color: $brand;
                font-weight: 500;
                font-style: italic;
                line-height: normal;
                font-family: $regular;
                cursor: pointer;
            }

            .do-details {
                .radio-group {
                    label {
                        text-transform: none;
                        font-weight: normal;
                        line-height: 2.5rem;
                    }
                }
            }
        }
    }

    #stats {
        @include button-link();
        background-color: $darkBlack;
        padding: 6rem 17.5rem;

        input {
            color: $white;
            background-color: transparent;
        }

        .select__big {
            input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        .select__small {
            input {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .error {
            position: absolute;
            right: 0;
            bottom: -2rem;
            color: $red;
            font-size: 1.2rem;
            font-weight: 500;

            &::first-letter {
                text-transform: capitalize;
            }
        }

        .traction {
            background-color: $darkBlack;
            margin-top: -1px;
            border: solid 1px $tagBackground;
            border-radius: 5px;
            padding: 5rem;
            margin-bottom: 2.7rem;
            position: relative;

            &:first-of-type {
                padding-left: 0;
                padding-bottom: 2rem;
                border: none;
                border-radius: 0;
            }

            .specialWidth {
                width: 10% !important;
                margin-top: 0rem;

                .addNew {
                    width: 7rem;
                    padding: 1.4rem;
                }
            }

            h2 {
                margin-bottom: 1.5rem;
            }

            p {
                color: $white;
                font-size: 1.4rem;
            }

            .companyNew__biggerTitle {
                color: $white;
                font-weight: 400;
            }

            .multiselect {
                &__content {
                    top: 5.6rem;
                }
                &__placeholder{
                    margin-top: 0.7rem
                }
            }

            .companyNew__input {
                width: 30.1%;
                display: inline-block;
                vertical-align: bottom;
                position: relative;

                &--new {
                    > input {
                        border: 0;
                        padding: 0;
                        margin-bottom: 0.5rem;

                        &::placeholder {
                            color: $white;
                            font-size: 1.1rem;
                            letter-spacing: 1px;
                            margin-bottom: 0.7em
                        }
                    }

                    .companyNew__input-mask {
                        position: relative;
                    }
                }

                &-mask {
                    input {
                        margin-bottom: 4rem;
                    }
                }

                .currency {
                    position: absolute;
                    top: 36.5%;
                    left: 3rem;
                    color: $white;
                    font-weight: 400;
                    font-size: 1.2rem;
                    width: 2rem;
                }
            }

            .question {
                color: $darkBlack;
                cursor: default;

                &:hover {
                    cursor: default;
                }
            }

            label {
                color: $white;
                font-size: 1.1rem;
                margin-bottom: 0.5rem;
                font-weight: 400;
            }

            .select__big {
                input {
                    margin-bottom: 0;
                    border-right: 0;
                    color: $white;
                }
            }

            .paddingRight {
                input {
                    padding-left: 6rem;
                }
            }

            .select__small {
                input {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    border-right: 1px solid $white;
                    margin-bottom: 0;
                }
            }

            .select__half {
                flex-basis: 50%;
                width: 50%;

                &.left {
                    input {
                        margin-bottom: 0;
                        border-right: 0;
                        color: $white;
                        border-radius: 5px 0 0 5px;
                    }
                }

                &.right {
                    input {
                        padding-left: 1rem;
                        padding-right: 1rem;
                        border-right: 1px solid $white;
                        margin-bottom: 0;
                        border-radius: 0 5px 5px 0;
                    }
                }
            }
        }

        .noGutter {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            position: relative;

            .error {
                display: block;
                margin-top: 2rem;
                margin-left: auto;

                &::first-letter {
                    text-transform: capitalize;
                }
            }

            > div {
                input {
                    border-right: none;
                }

                &.lastChild {
                    input {
                        border-right: 1px solid $white;
                    }
                }
            }

            .select {
                &__small {
                    width: 40%;
                    flex-basis: 40%;

                    input {
                        padding-left: 2rem;
                    }
                }

                &__big {
                    width: 60%;
                    flex-basis: 60%;
                }
            }

            .text-formatting label {
                font-size: 1.1rem;
                letter-spacing: 0px;
                color: #aab2bd;
                font-weight: 500;
                margin-bottom: 0rem;
                display: block;
                text-align: left;
            }

            .multiselect,
            .currencyBlock {
                input {
                    border-radius: 0;
                }
            }

            .companyNew__input {
                width: 25% !important;

                &:first-child {
                    input {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }
                }

                &:last-child {
                    input {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }
            }

            input {
                margin-top: auto !important;
            }
        }

        .custom-black-table {
            input:hover {
                border-color: $investmentBorder;
            }
        }
    }

    .people-component {
        .category-wrapper {
            display: grid;
        }
        .errorText {
            position: absolute;
            right: 180px;
            margin-top: 5px;
            font-size: 12px;
            color: #ed5565;
            letter-spacing: 1px;
            text-align: right;
        }
        .hasError {
            border-bottom: solid 1px $red;
        }
        .management__parent {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            @include multiple-media(miniLaptop, toDesktop) {
                grid-template-columns: repeat(2, 1fr);
            }
            @include multiple-media(tabletScreen) {
                grid-template-columns: repeat(1, 1fr);
            }
            grid-column-gap: 4rem;
            grid-row-gap: 4rem;
            .management__block {
                width: auto;
                margin: 0;
                &-addImage {
                    background-color: $white;
                }
                &-imageHolder {
                    label {
                        font-size: 2rem;
                        cursor: pointer;
                    }
                }
                padding: 0 1.5rem;
                border: 1px solid $investmentBorder;
            }
        }
        .companyNew__button {
            margin: 3rem 0;
            &-show {
                margin-top: 0;
            }
        }
        hr {
            border: 0;
            border-top: 1px solid $investmentBorder;
            margin-bottom: 3rem;
        }
        .category-name {
            position: relative;
            span {
                font-size: 2rem;
                font-weight: 600;
                color: $color-grey-6;
            }
        }
        .category-description {
            font-size: 1.1rem;
            letter-spacing: 0px;
            font-weight: 500;
            margin-bottom: 1rem;
            display: block;
            color: $tagBackground;
            margin-top: 2.5rem;
        }
        .move-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem 0;
            span {
                font-size: 3rem;
                color: $dark-gray;
                font-weight: 900;
                &:nth-child(2) {
                    padding: 0 1rem;
                    font-size: 1.5rem;
                }
                i {
                    &:before {
                        font-weight: 900;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
                &.light-arrow {
                    opacity: 0.2;
                    i {
                        &:hover {
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
    }
    .geographic_focus,
    .industry {
        .selected-list {
            margin-top: 10px;
        }
    }
}
