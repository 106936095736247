.compact-navigation-menu-mask--open{
    z-index: 3;
}

.over {
    z-index: 2 !important;
}

.notificationBell {
    margin-right: 20px;
    .odometer-inside {
        font-family: $regular;
        font-size: 10px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-white;
    }
    .notification-counter {
        width: 13px!important;
        height: 13px;
    }
    .odometer-inside {
        grid-auto-columns: max-content;
        justify-content: center;
    }
}

.loaniq-header-navigation{
    background-color: $color-white;
    padding: 1rem;
    box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 2;
    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
        padding: 0;
    }

    .navigation-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3rem;

        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
            height: 50px;
            padding: 0 2rem;
        }

        .navigation-menu-left {

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                height: 100%;
            }

            @include multiple-media(mobileScreenLandscape) {
                height: auto;
            }

            .menu-mobile {
                display: block;

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    display: none;
                }

                @include multiple-media(mobileScreenLandscape) {
                    display: block;
                }

                img {
                    width: 2rem;
                }
            }

            .menu-tabs {
                display: none;

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    display: flex;
                    align-items: center;
                    height: 100%;
                }

                @include multiple-media(mobileScreenLandscape) {
                    display: none;
                }

                &-item {
                    margin-right: 3rem;
                    cursor: pointer;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    &:not(:first-child) {

                        &:hover {

                            .menu-tabs-item--text {
                                color: $color-blue-main;
                            }

                        }
                    }

                    &--logo {
                        max-height: 4rem;
                    }

                    &--text {
                        font-size: 1.5rem;
                        color: $color-grey-6;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        border-bottom: 2px solid transparent;
                    }

                    .is-active {
                        color: $color-blue-main;
                        border-bottom-color: $color-blue-main;
                    }
                }
            }

        }

        .navigation-menu-right {
            display: flex;
            flex-grow: 1;
            justify-content: space-between;
            align-items: center;
            margin-left: 3.5rem;

            @include multiple-media(mobileScreen) {
                margin-left: 7rem;
            }

            @include multiple-media(mobileScreenLandscape) {
                margin-left: 10rem;
            }

            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                flex-grow: unset;
                margin-left: 0;
            }

            .menu-item {
                position: relative;

                &:first-child {
                    @include multiple-media(tabletScreen) {
                        margin-left: 0;
                    }
                }

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    margin-left: 3rem;
                }
            }
        }
    }
}

.description-text {
    position: relative;
}

.description-text > p {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.4;
    padding-right: 10px;
}

.description-text > p {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    color: $color-white;
    margin: 0;
    margin-left: 90px;
    line-height: 1.3;
    font-size: 12px;
}

@-moz-document url-prefix() {
    .description-text {
        position: relative;
        height: auto;
        max-height: 50px;
        > p {
            display: block;
            text-overflow: ellipsis;
            word-wrap: break-word;
            line-height: 1.3;
            max-height: 50px;
        }
    }
}
.noMarginLeft p {
    margin-left: 0 !important;
}

.data {
    padding: 20px;
    display: inherit;
    -webkit-box-pack: inherit;
    -ms-flex-pack: inherit;
    justify-content: space-between;
    @include breakpoint (hugeScreen) {
        min-height: 62px;
    }
    @include multiple-media(toPhone, toPhoneLandscape) {
        padding: 15px !important;
    }
    section {
        @include breakpoint(toDesktop) {
            word-break: break-word;
        }
    }
}
.smallSection {
    flex: 0 0 75px;
    padding-left: 10px;
    @include multiple-media(toPhone, toPhoneLandscape) {
        flex: 0 0 50px;
    }
}

.dropbtn.btn-green {
    @include breakpoint(tablet) {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    @include multiple-media(toPhone, toPhoneLandscape) {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
}

.company-profile-logo-block {
    position: absolute;
    height: 55px;
    width: 55px;
    padding: 5px;
    border-radius: 5px;
    bottom: 10px;
    left: 20px;
}

.scroll-down,
.scroll-top {
    font-size: 20px;
    opacity: 0.8;
    @include breakpoint (toDesktop) {
        display: none !important;
    }
    &.hide {
        display: none;
    }
}

.lounge-link {
    color: $black;
}

.data {
    section {
        padding: 0;
        float: none;
        max-width: inherit;
        &:nth-child(1) {
            flex-grow: 1;
            flex-shrink: 0;
            word-break: break-word;
            flex-wrap: wrap;
            flex: 1;
            &.grow{
                flex: 1;
            }

        }
        &:nth-child(2) {
            flex-grow: 1;
            flex-shrink: 0;
            word-break: break-word;
            flex-wrap: wrap;
            flex: 1;
        }
    }
}

.data > section > span {
    font-weight: 500;
    @include breakpoint (toDesktop) {
        font-size: 9px;
    }
}

.tags > a > div {
    @include breakpoint (toDesktop) {
        font-size: 2rem !important;
        letter-spacing: 1px !important;
    }
}

nav {
    @include breakpoint (toDesktop) {
        right: 0rem;
    }
}

.company-descriptions-container {
    h5 {
        margin: 1em 0 1em 0em;
        font-size: 3.5rem;
    }
}

div.tab button {
    font-family: $regular;
    @include breakpoint (toDesktop) {
        font-size: 2.2rem;
    }
}
#vision-section {
    @include breakpoint (toDesktop) {
        padding: 10rem 9% !important;
    }
}
.company-block > div > p, .company-block > div > section > p {
    @include breakpoint (toDesktop) {
        margin: 2rem 0 !important;
        font-size: 2.6rem !important;
        line-height: 4.2rem !important;
    }
}

.management > div > section > figure {
    @include breakpoint (toDesktop) {
        width: 100%;
    }
    figcaption {
        @include breakpoint (toDesktop) {
            font-size: 3rem;
        }
    }
    > span {
        @include breakpoint (toDesktop) {
            font-size: 2rem;
        }
    }
}
.ladda-label {
    @include breakpoint (toDesktop) {
        font-size: 2rem;
        padding: 3rem;
    }
}

.overlay a {
    @include breakpoint (toDesktop) {
        letter-spacing: 10px;
    }
}

.company-block > div > section > p {
    color: $color-grey-6;
    font-size: 1.5rem;
    line-height: 2.5rem;
    margin: 0 8rem 0 8rem;
    text-align: justify;
    font-weight: 500;
    margin-bottom: 6rem;
}

.profile-container > header {
    @include breakpoint (toDesktop) {
        padding: 2.8rem 4rem;
    }
}

.profile-container > section {
    @include breakpoint (toDesktop) {
        padding: 2.8rem 4rem;
    }
}
.modal-content {
    @include breakpoint(toDesktop) {
        width: 100% !important;
        margin-top: 0%;
        height: 100%;
    }
    .modelclose > span:first-child {
        @include breakpoint (toDesktop) {
            font-size: 2.4rem;
        }
    }
    .close {
        @include breakpoint (toDesktop) {
            font-size: 6rem;
        }
    }
    .ladda-label {
        @include breakpoint(toDesktop) {
            padding: 2.2rem;
        }
    }
    .ladda-button {
        @include breakpoint(toDesktop) {
            padding: 0;
        }
    }
}

.companyLink {
    div {
        @include breakpoint (toDesktop) {
            color: $brand;
            border: 1px solid $brand;
        }
    }
}
.flex-direction{
    @include breakpoint(toDesktop) {
        display: flex;
        flex-wrap: wrap;
    }
    &-top {
        @include breakpoint(desktop) {
            margin: 0 auto;
        }
    }
}

.all-deals-pagination {
    user-select: none;
    right: -2px;
    @include breakpoint(tablet){
        right: 20px;
    }
    @include multiple-media(toPhone, toPhoneLandscape) {
        bottom: inherit;
        right: inherit;
        position: relative;
        margin-bottom: 60px;
    }
    > span {
        height: 43px;
        margin: 0 3px;
        > i {
            padding-top: 6px;
        }
        &.pages > span {
            padding-top: 13px;
        }
    }
}

// new Lounge style
.lounge-page {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    scroll-padding: 0 0 15rem 0;

    @include multiple-media(mobileScreen, mobileScreenLandscape) {
        scroll-snap-type: none;
        scroll-padding: unset;
    }


    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {

        @include multiple-media(tabletScreen, smallScreen) {
            scroll-snap-type: none;
            scroll-padding: unset;
        }

        @media (min-width: 900px) and (max-height: 700px)  {
            scroll-snap-type: none;
            scroll-padding: unset;
        }
    }}

    .layer-container  {

        @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
            z-index: 1001;
        }
    }

    &__container {
        min-height: calc(100vh - 6rem);
        position: relative;
        padding: 6rem 2rem 0 2rem;
        display: flex;
        flex-direction: column;
        scroll-snap-align: start;
        scroll-snap-stop: always;

        .branding-bar {
            height: 4px;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
        @include multiple-media(tabletScreen, smallScreen) {
            padding: 6rem 6rem 0 6rem;
        }

        .scroll-down,
        .scroll-top {
            font-size: 2rem;
            display: none;

            @include multiple-media(mediumScreen, largeScreen) {
                display: flex;
            }
        }

        .lounge-selected {
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            &__header {

                @include multiple-media(mediumScreen, largeScreen) {
                    width: 1000px;
                    margin: 0 auto;
                }

                &__logo-wrapper {
                    height: 8rem;

                    &--image {
                        display: block;
                        margin: 0 auto;
                        height: 100%;
                    }
                }

                &--title {
                    text-align: center;
                    margin: 2rem 0 3rem 0;
                    height: 18px;
                    font-size: 1.4rem;
                    font-weight: 300;
                    letter-spacing: 5.83px;

                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen  ) {
                        font-size: 2rem;
                        margin-bottom: 4rem;
                    }
                }
            }
        }

        .lounge-companies {
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            justify-content: center;

            &__empty {
                display: flex;
                flex-grow: 1;

                &--message {
                    text-align: center;
                    display: flex;
                    align-self: center;
                    font-size: 2rem;
                    margin: 2rem auto;
                    line-height: 1.4;
                    letter-spacing: 0.2em;
                }
            }

            .company-block {
                min-height: 280px;
                background-repeat: no-repeat;
                background-size: cover;
                padding: 0;
                margin-bottom: 2rem;
                width: 100%;


                @include multiple-media(mobileScreen) {
                    max-height: 28rem;
                }


                @media (min-width: 900px) and (max-height: 700px)  {
                    align-self: center;
                }

                .link-to-company {
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    width: 100%;
                    min-height: inherit;
                    position: relative;

                    .link-to-company-see-more {
                        display: none;
                    }


                    &:hover {
                        opacity: 0.9;
                        background: rgba(0,0,0,0.5);
                        transition: background-color 0.5s;
                        cursor: default;

                        .link-to-company-see-more {
                            display: block;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                            font-size: 2rem;
                            font-weight: 500;
                            color: $color-white;
                            text-transform: uppercase;
                        }
                    }

                    .data {
                        display: flex;
                        padding: 1.5rem 1rem;
                        flex-direction: row;
                        height: 4rem;

                        & section {
                            text-align: left;
                            margin-right: 1rem;
                            flex-grow: 1;
                            flex-basis: 0;

                            &:nth-child(1) {

                                & span {
                                    min-width: 4.2rem;
                                }
                            }

                            &:nth-child(2) {

                                & span {
                                    min-width: 5.5rem;
                                }
                            }

                            &:nth-child(4) {

                                & span {
                                    min-width: 3.8rem;
                                }
                            }

                            & span {
                                font-size: 1rem;

                                @include multiple-media(tabletScreen) {
                                    margin-bottom: 1rem;
                                }
                            }

                            & h4 {
                                font-size: 1.3rem;
                                font-weight: 500;
                                word-break: break-word;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                display: -webkit-box;
                            }
                        }
                    }

                    .description {

                        &-text {
                            position: relative;
                            display: flex;
                            align-items: flex-end;
                            height: 4.5rem;

                            &__content {
                                margin: 0;
                                color: $color-white;
                                font-size: 1rem;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                line-height: 1.7rem;
                                max-height: 5rem;

                                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                                    font-size: 1.4rem;
                                }
                            }

                            .company-profile-logo-block {
                                left: 0;
                                bottom: 0;
                                height: 55px;
                                min-width: 55px;
                                top: 0;
                                position: relative;
                                margin-right: 1rem;
                            }
                        }
                    }
                }
            }

            // Style by number of companies -  Selected for you part, one and two - More deals
            &__number-1,
            &__more-deals-number-1 {
                display: flex;
                flex-grow: 1;
                height: max-content;
                align-self: center;
                justify-content: center;
                width: 100%;

                @include multiple-media(mediumScreen, largeScreen) {
                    width: 1000px;
                    margin: 0 auto;
                }

                .company-block {

                    &:nth-child(1) {

                        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                            flex-grow: 1;
                            min-height: 30rem;
                            max-height: 60rem;
                            max-width: 65rem;
                            align-self: center;
                            height: calc(100vh - 400px);
                            margin: 0;
                        }
                    }
                }
            }

            &__number-2,
            &__more-deals-number-2 {
                display: flex;
                flex-direction: column;
                align-items: center;
                flex-grow: 1;

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    flex-direction: row;
                }

                @include multiple-media(mediumScreen, largeScreen) {
                    width: 1000px;
                    margin: 0 auto;
                }

                .company-block {

                    &:nth-child(1),
                    &:nth-child(2) {

                        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                            margin: 1rem;
                        }

                        @include multiple-media(mediumScreen, largeScreen) {
                            height: calc(100vh - 400px);
                            min-height: 25rem;
                            max-height: 50rem;
                        }
                    }
                }
            }

            &__number-3 {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                flex-grow: 1;

                @include multiple-media(tabletScreen) {
                    flex-direction: row;
                }

                @include multiple-media(smallScreen) {
                    width: 850px;
                    margin: 0 auto;
                    height: calc(100vh - 400px);
                    max-height: 500px;;
                }

                @include multiple-media(mediumScreen, largeScreen) {
                    width: 1000px;
                    margin: 0 auto;
                    height: calc(100vh - 400px);
                }

                @include multiple-media(largeScreen) {
                    max-height: 500px;
                }

                @media (min-width: 900px) and (max-height: 700px)  {
                    width: 850px !important;
                    height: 500px !important;
                }

                .company-block {

                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                        flex-grow: 1;
                    }

                    @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                        min-height: 10rem;

                    }

                    @media (min-width: 900px) and (max-height: 700px)  {
                        min-height: 100%;
                    }

                    @media not all and (min-resolution:.001dpcm)
                    { @supports (-webkit-appearance:none) {
                        @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                            height: 45%;

                        }
                    }}

                    &:nth-child(1) {

                        @include multiple-media(smallScreen) {
                            flex-basis: 100%;
                            width: 500px;
                        }

                        @include multiple-media(mediumScreen, largeScreen) {
                            flex-basis: 100%;
                            width: 600px;
                        }

                        @media (min-width: 900px) and (max-height: 700px)  {
                            min-height: unset !important;
                            width: 500px;
                        }

                        .data {

                            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                                height: max-content;

                                & h4 {
                                    font-weight: 300 !important;
                                    font-size: 2rem !important;
                                }
                            }

                            @media (min-width: 900px) and (max-height: 700px)  {
                                height: 4rem;

                                & h4 {
                                    font-weight: 500 !important;
                                    font-size: 1.3rem !important;
                                }
                            }
                        }
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        @include multiple-media(tabletScreen) {
                            width: 48%;
                            max-height: 30rem;
                        }

                        @include multiple-media(smallScreen) {
                            width: 300px;
                        }

                        @include multiple-media(mediumScreen, largeScreen) {
                            width: 360px;
                        }

                        @media (min-width: 900px) and (max-height: 700px)  {
                            width: 270px;
                            height: 20rem;
                            min-height: unset;
                        }
                    }

                    &:nth-child(2) {
                        @include multiple-media(tabletScreen) {
                            margin-right: 1rem;
                        }
                    }

                    .company-profile-logo-block {
                        @media (min-width: 900px) and (max-height: 700px)  {
                            height: 30px !important;
                            min-width: 30px !important;
                            width: 30px;
                        }
                    }
                }
            }

            &__number-1,
            &__number-2,
            &__more-deals-number-1,
            &__more-deals-number-2{

                .company-block {

                    &:nth-child(1),
                    &:nth-child(2) {

                        .data {

                            @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                                height: max-content;

                                & h4 {
                                    font-weight: 300 !important;
                                    font-size: 2rem !important;
                                }
                            }

                            @media (min-width: 900px) and (max-height: 700px)  {
                                height: 4rem;

                                & h4 {
                                    font-weight: 500 !important;
                                    font-size: 1.3rem !important;
                                }
                            }
                        }

                    }
                }
            }

            // Style by number of companies -  More deals part

            &__more-deals-number-3,
            &__more-deals-number-4,
            &__more-deals-number-5,
            &__more-deals-number-6{
                display: flex;
                flex-direction: column;

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                }

                @include multiple-media(mediumScreen, largeScreen) {
                    width: 1000px;
                    margin: 0 auto;
                    flex-grow: 1;
                    height: calc(100vh - 400px);
                    align-items: stretch;
                }

                @media (min-width: 900px) and (max-height: 700px)  {
                    height: auto !important;
                }

                .company-block {

                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                        width: 310px;
                        margin: 1rem;
                        max-height: 310px;
                    }

                    @include multiple-media(mediumScreen, largeScreen) {
                        min-height: 10rem;
                    }

                    @include multiple-media(tabletScreen, smallScreen) {
                        height: auto;
                        width: 45%;
                    }

                    @media (min-width: 900px) and (max-height: 700px)  {
                        min-height: 20rem;
                    }
                }

                .company-profile-logo-block {
                    @media (min-width: 900px) and (max-height: 700px)  {
                        height: 30px !important;
                        min-width: 30px !important;
                        width: 30px;
                    }
                }
            }
            &__more-deals-number-3 {

                @include multiple-media(mediumScreen, largeScreen) {
                    justify-content: center;
                    align-items: center;
                }

                .company-block {

                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                        min-height: 30rem;
                    }
                }

            }

            &__more-deals-number-4 {
                @include multiple-media(tabletScreen, smallScreen) {
                    justify-content: center;
                }

            }

            &__more-deals-number-5 {
                @include multiple-media(tabletScreen, smallScreen) {
                    justify-content: flex-start;
                }
            }

            &__more-deals-number-6 {
                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    justify-content: flex-start;
                }

                .company-block {
                    @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                        flex-grow: 1;
                    }
                }
                @include multiple-media(mediumScreen, largeScreen) {
                    height: calc(100vh - 400px);
                }

                @media (min-width: 900px) and (max-height: 700px)  {
                    height: auto !important;
                }
            }
        }

        .alldeals-wrapper {
            display: flex;
            flex-direction: column-reverse;
            margin: 4rem 0 5rem 0;

            @include multiple-media(mediumScreen, largeScreen) {
                margin: 4rem auto 8rem auto;
                flex-direction: row;
                width: 985px;
                align-items: center;
            }

            @include multiple-media(tabletScreen, smallScreen) {
                margin: 4rem 1rem 8rem 1rem;
                flex-direction: row;
            }

            .alldeals {
                font-size: 1.2rem;
                padding: 1.4rem 4rem;
                color: $color-white;
                letter-spacing: 0.4px;
                font-weight: 500;
                height: unset;
                margin: unset;

                &:only-child {
                    &.selectedButton {
                        @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                            margin: 0 auto;
                        }
                    }
                }

                &.selectedButton {
                    margin: unset;
                    height: 1.5rem;
                }



                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    width: max-content;
                    padding: 1.5rem 4.5rem;
                    display: block;
                    margin: 0 auto;
                }
            }

            .all-deals-pagination {
                color: $color-white;
                margin-bottom: 4rem;

                .disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }

                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    margin: 0 auto;
                }
            }

            .selectedButton + .all-deals-pagination {
                @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                    margin: 0 0 0 auto;
                }
            }
        }
    }
}

// Loaniq new lounge page
.lounge-wrapper {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-snap-type: y mandatory;
    scroll-padding: 0 0 15rem 0;
}

.loaniq-lounge-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -moz-background-size: cover;
    overflow-y: hidden;
    scroll-snap-align: start;
    position: relative;

    &__empty {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: left;
        background-color: white;
        padding: 40px 50px;
        border-radius: 15px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
        z-index: 1;
        font-size: 14px;
        max-width: 30%;
        .header {
            font-size: 24px;
            font-weight: bold;
            font-family: Montserrat;
            text-transform: none;
            margin-bottom: 20px;
        }
        a {
            color: #1f7be8;
            &:hover {
                color: $color-blue-dark
            }
        }
    }

    &.darken-pseudo {
        position: relative;
        overflow: hidden;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }

    .lounge-navigation {
        display: flex;
        justify-content: flex-end;
        padding-top: 70px;
        padding-right: 20px;

        &__lounge-name {
            display: flex;
            margin: 0 auto 0 80px;
            color: $color-white;
            z-index: 1;
            font-size: 14px;
            align-items: center;
            font-weight: 600;
        }

        &__next-lounge {
            z-index: 1;
            color: $color-white;
            font-size: 14px;
            font-weight: 600;
            margin-right: 20px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            display: flex;
            i {
                font-size: 16px;
                margin: 0 20px;
            }
        }

        &__logo {
            display: block;
            width: 30px;
            height: 30px;
            margin-left: 3rem;
            background-color: white;
            border-radius: 3px;
            padding: 0.8rem 0.7rem;
            z-index: 1;
            position: relative;
            cursor: pointer;
            border: 2px solid $brand;

            span {
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%,-50%);
                width: 100%;
                opacity: 0.2;
                font-family: Montserrat;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #434a54;
            }
        }
    }

    &__flex {
        display: flex;

        .flex-arrow-left,
        .flex-arrow-right {
            margin-top: 3rem;
            color: $color-white;
            font-size: 36px;
            cursor: pointer;
        }
    }

    &__flex-end {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 5rem 4.6rem 2rem;
        position: relative;
        flex-basis: content;

        @media screen and (max-height: 900px) {
            padding-top: 0;
            margin-top: -120px;
        }

        .company-info-section {
            display: flex;
            justify-content: flex-end;
            position: relative;
            width: 100%;

            &__arrow-left {
                font-size: 36px;
                color: $color-white;
                z-index: 1;
                position: relative;
                width: 5%;

                span > i {
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    right: 50%;
                }
            }

            &__arrow-right {
                font-size: 36px;
                color: $color-white;
                z-index: 1;
                position: relative;
                width: 5%;

                span > i {
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                }
            }

            &__company-block {
                display: flex;
                flex-direction: column;
                width: 55%;
                max-width: 600px;
                border-radius: 15px;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
                background-color: $color-white;
                z-index: 1;

                @include breakpoint(tabletScreen) {
                    width: 100%;
                    max-width: none;
                }

                @include windowsZoom {
                    margin-bottom: 50px;
                }

                .header {
                    padding: 3rem 5rem;
                    font-family: Montserrat;
                    font-stretch: normal;
                    font-style: normal;
                    letter-spacing: normal;
                    color: #434a54;
                    overflow: hidden;

                    @include windowsZoom {
                        padding: 2rem 4rem;
                    }

                    span {
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        display: -webkit-box;
                    }

                    p {
                        text-overflow: ellipsis;
                        word-wrap: break-word;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        display: -webkit-box;
                        margin-bottom: 0;
                    }

                    @include multiple-media(mobileScreen, tabletScreen, smallScreen, mediumScreen) {
                        @include retina{
                            padding: 2rem 5rem;
                        }
                    }

                    span {
                        font-size: 24px;
                        font-weight: bold;
                        line-height: normal;

                        @include multiple-media(mobileScreen, tabletScreen, smallScreen, mediumScreen) {
                            font-size: 16px;
                        }

                        @include windowsZoom {
                            font-size: 14px;
                        }
                    }

                    p {
                        font-size: 14px;
                        font-weight: normal;
                        line-height: 1.57;

                        @include multiple-media(mobileScreen, tabletScreen, smallScreen, mediumScreen) {
                            @include retina {
                                font-size: 12px;
                                line-height: 1.25;
                            }
                        }

                        @include windowsZoom {
                            font-size: 12px;
                            margin-top: 10px;
                            line-height: 1.25;
                        }
                    }
                }

                .participants {
                    padding: 1rem 5rem;
                    display: flex;
                    max-height: 145px;
                    border-top: 1px solid $color-grey-26;
                    border-bottom: 1px solid $color-grey-26;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    overflow: auto;

                    @include windowsZoom {
                        padding: 1rem 4rem;
                    }

                    .borrower {
                        min-width: 30%;
                        padding: 1rem 2rem 1rem 0;

                        p {
                            margin-top: 10px;
                            text-overflow: ellipsis;
                            word-wrap: break-word;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            display: -webkit-box;

                            @include windowsZoom {
                                font-size: 10px;
                            }
                        }

                        span {
                            @include windowsZoom {
                                font-size: 10px;
                            }
                        }
                    }

                    .lead-arrenger {
                        padding: 2rem 0;
                        min-width: 30%;
                        margin-left: 6%;

                        span {
                            @include windowsZoom {
                                font-size: 10px;
                            }
                        }

                        p {
                            text-overflow: ellipsis;
                            word-wrap: break-word;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            display: -webkit-box;

                            @include windowsZoom {
                                font-size: 10px;
                            }
                        }
                    }

                    .joint-bookrunner {
                        padding: 2rem 0;
                        min-width: 30%;
                        margin-left: 6%;

                        span {
                            @include windowsZoom {
                                font-size: 10px;
                            }
                        }

                        p {
                            text-overflow: ellipsis;
                            word-wrap: break-word;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            display: -webkit-box;

                            @include windowsZoom {
                                font-size: 10px;
                            }
                        }
                    }

                    span {
                        font-weight: 500;
                        color: #aab2bd;
                    }

                    p {
                        margin: 0;
                        font-weight: 600;
                        color: #434a54;
                    }

                    .borrower-logo {
                        display: block;
                        height: 60px;
                        width: 100px;
                        background-size: contain;
                        background-position: center center;
                        background-repeat: no-repeat;
                    }
                }

                .summary {
                    padding: 3rem 5rem;
                    font-family: Montserrat;
                    font-size: 15px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    overflow: hidden;

                    @include windowsZoom {
                        padding: 2rem 4rem;
                    }

                    .info {
                        width: 50%;
                        .row {
                            display: flex;
                            margin-top: 1rem;
                            margin-bottom: 1rem;
                            span {
                                flex: 0 0 auto;
                                color: #434a54;
                                @include multiple-media(mobileScreen, tabletScreen, smallScreen, mediumScreen) {
                                    @include retina{
                                        font-size: 13px;
                                    }
                                }

                                @include windowsZoom {
                                    font-size: 11px;
                                }

                                &:first-child {
                                    color: $color-grey-10;
                                    flex: 0 0 35%;
                                }
                            }
                        }
                    }
                    @include multiple-media(mobileScreen, tabletScreen, smallScreen, mediumScreen) {
                        @include retina {
                            padding: 2rem 5rem;
                        }
                    }

                    .read-more {
                        font-size: 16px;
                        font-weight: 600;
                        margin-top: 3rem;

                        @include multiple-media(mobileScreen, tabletScreen, smallScreen, mediumScreen) {
                            margin-top: 2rem;
                        }

                        @include windowsZoom {
                            font-size: 12px;
                            margin-top: 10px;
                        }

                        display: block;
                        background-color: #1f7be8;
                        width: 145px;
                        max-width: 145px;
                        color: #fff;
                        text-align: center;
                        line-height: 40px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        padding: 0 80px;
        flex-basis: 33%;
        justify-content: flex-end;
        transition: all 1s;
        margin-bottom: 10rem;

        @media screen and (max-height: 920px) {
            margin-top: -145px;
            margin-bottom: 0;
            justify-content: flex-end;
            flex-basis: 10%;
        }

        &:hover {
            padding: 0 80px;
        }

        .deals-navigation-block {
            display: flex;

            @include windowsZoom {
                align-items: center;
            }

            &__title {
                font-family: Montserrat;
                margin: 0 30px;
                font-size: 42px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $color-white;

                @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
                    font-size: 24px;
                }
            }
            &__load-deals-button {
                display: flex;
                font-size: 16px;
                background-color: #1f7be8;
                width: 145px;
                max-width: 145px;
                color: #fff;
                text-align: center;
                line-height: 40px;
                border-radius: 5px;
                justify-content: center;
                align-items: center;
                padding: 2px;
                cursor: pointer;
                z-index: 1;
            }
        }

        .swiper-container {
            height : auto;
            width : 100%;
        }

        .swiper-slide{
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .swiper-button-prev {
            display: block;
            position: absolute;
            top: 15%;
            left: 0;
            font-size: 36px;
            color: #fff;
            z-index: 2;
            cursor: pointer;
        }

        .swiper-button-next {
            display: block;
            position: absolute;
            top: 15%;
            right: 0;
            font-size: 36px;
            color: #fff;
            z-index: 2;
            cursor: pointer;
        }

        .loaniq-company-card {
            margin: 3rem;
            border-radius: 15px;
            height: 160px;
            display: block;
            background-color: $color-white;
            z-index: 1;
            cursor: pointer;
            transform: translateY(10%);
            transition: all 0.4s ease-in-out;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;

            @media screen and (max-height: 900px) {
                margin: 0;
            }


            @include windowsZoom {
                height: 60px;
            }

            &:hover {
                transform: translateY(0);
            }
            &__borrower-logo {
                display: block;
                margin: 20px;
                width: 40px;
                height: 40px;
                background-size: contain;
                background-repeat: no-repeat;
                background-color: $white;
                background-position: center center;
                border-radius: 3px;

                @include windowsZoom {
                    width: 25px;
                    height: 25px;
                    margin: 15px;
                }
            }
        }
    }
}
