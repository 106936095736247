.modal-content3 {
    width: 1000px;
    margin: 0 auto;
    background: #fafafa;
}
.modal-content3 .modal-header, .modal-content3 .modal-footer {
    padding: 0.2em 2em;
    display: flex;
    justify-content: space-between;
    background: transparent;
    color: #000;
}
.modal-content3 .modal-header h1 {
    font-weight: 500;
    margin-bottom: 0.5rem;
}
.modal-header > div, .modal-footer > div {
    display: flex;
    align-items: center;
}
.modal-header > div p, .modal-footer > div p {
    font-size: 1.8rem;
    float: left;
    margin-right: 4rem;
}
.modal-body2 {
    display: flex;
    flex-wrap: wrap;
}
.modal-body2 > section {
    margin-bottom: 8rem;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
}
.modal-body2 > section hr {
    margin-bottom: 3vh;
    width: 100%;
}
.modal-body2 section h3 {
    text-align: left;
    font-size: 1.6rem;
    margin: 0;
    font-weight: normal;
    color: #f8ac59;
}
.modal-body2 section > div {
    font-size: 1.8rem;
    flex: 1 0 17%;
    margin-right: 3vw;
}
.modal-body2 section > div.half-size {
    flex: 1 0 45%;
    max-width: 45%;
}
.modal-body2 section > div:last-of-type {
    margin-right: 0;
}
.modal-body2 section > div label {
    font-size: 9px;
    color: #a6b1bc;
    width: 100%;
    margin-bottom: 0.7rem;
    display: inline-block;
}
.modal-body2 section div.img-wrapper {
    float: right;
    background: #f5f7fb;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    text-align: center;
    padding: 1vw 6vw;
}
.img-wrapper img {
    margin: auto;
    width: 100%;
    max-width: 300px;
    max-height: 100%;
}

.modal-footer {
    justify-content: flex-end !important;
    -webkit-box-shadow: 0 -5px 13px -2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 -5px 13px -2px rgba(0, 0, 0, 0.2);
    box-shadow: 0 -5px 13px -2px rgba(0, 0, 0, 0.2);
}
.modal-footer > div p {
    margin-right: 4vw;
}
.modal-footer > div p:last-of-type {
    margin-right: 0;
}
