.investor-initials {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border: solid 1px #dae0e8;
    border-radius: 5px;
    background-color: #fafbfd;
    color: $color-white-3;
}
