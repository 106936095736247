.investor-sidebar {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    font-family: 'Nunito', sans-serif;

    &__container {
        @include multiple-media(tabletScreen, mobileScreen) {
            display: flex;
        }
    }

    &__profile {
        display: flex;
        flex-direction: column;

        &-logo {
            max-width: 200px;
            max-height: 200px;
            margin-bottom: 10px;

            @include multiple-media(tabletScreen, mobileScreen) {
                width: 100px;
                height: 100px;
            }

            &--image {
                border-radius: 3px;
                max-width: 100%;
                max-height: 100%;
                text-align: center;

                .investor-initials {
                    font-size: 80px;
                    width: 200px;
                    height: 200px;

                    @include multiple-media(tabletScreen, mobileScreen) {
                        font-size: 50px;
                    }
                }
            }
        }

        &--login {
            font-size: 1.2rem;
            padding-top: .5rem;
            font-family: NunitoBold, sans-serif;

            @include multiple-media(tabletScreen, mobileScreen) {
                display: none;
            }
        }
    }

    &__info {
        @include multiple-media(tabletScreen, mobileScreen) {
            display: flex;
            margin-left: 30px;
            flex-wrap: wrap;
            width: 100%;
        }

        &__item {
            padding-top: 1.5rem;
            display: flex;
            flex-direction: column;
            flex-grow: 1;

            @include breakpoint(tabletScreen) {
                width: 50%;
                padding: 0;
            }

            @include breakpoint(mobileScreen) {
                width: 100%;
                padding: 0 15px 15px 0;
            }

            &--title {
                color: $color-grey-17;
                font-size: 1.2rem;

                @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                    font-size: 12px;
                }
            }

            &--value {
                font-size: 1.4rem;
                padding-top: .5rem;
                font-family: NunitoBold, sans-serif;
                word-break: break-all;
            }

            &--link {
                cursor: pointer;
                color: $color-cyan-secondary;
                font-size: 1.4rem;
                padding-top: .5rem;
                font-weight: 600;
                font-family: Nunito, sans-serif;
            }
        }
    }

    &__links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 15px;

        @include multiple-media(tabletScreen, mobileScreen) {
            flex-direction: row;
            margin-top: 20px;

            .line-separated {
                box-shadow: 1px 0 0 0 $color-white-3;
            }

            span {
                margin: auto;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            box-shadow: 0 1px 0 0 $color-white-3;
            padding: 1.4rem 0;
            cursor: pointer;

            @include multiple-media(tabletScreen, mobileScreen) {
                width: 25%;
                height: 50px;
                flex-direction: column;
                box-shadow: unset;
                padding: 0;
            }

            .material-icons-outlined {
                color: $color-cyan-secondary;
            }

            &--name {
                font-size: 14px;

                @include multiple-media(smallScreen, mediumScreen, largeScreen) {
                    padding-left: 1.5rem;
                    font-size: 1.6rem;
                }

                @media screen and (max-width: 700px) {
                    display: none;
                }
            }
        }
    }
}

.edit-investor{
    padding: 3rem;

    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &--select {
            font-size: 1.6rem;
            margin-bottom: 2rem;

            label {
                font-size: 1.6rem;
            }

            .multiselect--active {
                box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
            }

            .multiselect__input,
            input {
                background-color: $color-white;
                padding: 1.4rem 2rem;
                font-size: 1.6rem;
                font-family: 'Nunito', sans-serif;
                margin-bottom: 1rem;

                &:hover,
                &:focus {
                    border-color: $color-grey-18;
                }
            }

            .multiselect__content {
                top: 5rem;
                font-family: 'Nunito', sans-serif;
                box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
                border: none;

                .multiselect__element {
                    padding: 0;

                    .multiselect__option {
                        font-size: 1.6rem;
                        padding: 1.4rem 2rem;
                        color: $darkBlack;

                        &--highlight {
                            background-color: $color-blue-dark-hover;
                        }
                    }
                }
            }
        }
    }

    &--button {
        border-radius: 5px;
        background-color: $color-purple-main;
        font-size: 1.6rem;
        padding: 1.4rem 4rem;
        margin-top: 2rem;
        outline: none;
        color: $color-white;
        cursor: pointer;
        border: none;

        &:disabled {
            opacity: .5;
        }
    }
    input {
        background-color: #ffffff;
        padding: 1.4rem 2rem;
        font-size: 1.6rem !important;
        font-family: 'Nunito', sans-serif;
    }

}
