.investor-welcome {
    width: 65%;
    margin: auto;

    .kyc-disclaimer {
        font-family: Nunito;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        color: #626262;
    }

    .kyc-disclaimer-section {
        margin-top: 20px !important;
        margin-bottom: 20px !important;
    }

    .p-15 {
        padding: 15px;
    }

    .fx-col {
        display: flex;
        flex-direction: column;
    }

    .fx-row {
        display: flex;
        flex-direction: row;
    }

    .divider {
        border-top: solid 1px $color-white-3;
    }

    .grey-txt {
        color: #626262 !important;
    }

    .desc {
        font-size: 14px;
        font-weight: 600;
    }

    .ml-20 {
        margin-left: 20px;
    }

    .ml-10 {
        margin-left: 10px;
    }

    .mb-20 {
        margin-bottom: 20px;
    }

    .mt-20 {
        margin-top: 20px;
    }

    .link {
        font-size: 13px;
        font-weight: 600;
        color: $brand;
    }

    .green-txt {
        display: block;
        margin: 10px 20px 10px 20px;
        font-family: Nunito;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $color-green-light-2;
    }

    .right-arrow {
        margin-top: 15px;
        width: 16px;
        height: 16px;
    }

    .play {
        width: 16px;
        height: 16px;
    }

    .check {
        width: 18px;
        margin-left: 12px;
        height: 18px;
        margin-top: 12px;
    }

    .index-container {
        background-color: $color-green-light-2;
        width: 40px;
        height:40px;
        border-radius: 25px;
        color:white;
    }

    .white-txt {
        margin-left: 15px;
        line-height: 40px;
        font-family: Nunito;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #fafafa;
    }

    &__title {
        &--h1 {
            margin-bottom: .5rem;
            margin-top: 3rem;
            font-family: NunitoBold, sans-serif;
            font-size: 3rem;
            letter-spacing: normal;
            color: $darkBlack;
        }

        &--h2 {
            margin-top: 0;
            font-size: 1.8rem;
            font-family: Nunito, sans-serif;
            text-transform: none;
            letter-spacing: normal;
            color: #626262;
        }
    }

    & section {
        margin-top: 40px;
        margin-bottom: 40px;
        border-radius: 5px;
        border: solid 1px $color-white-3;
        background-color: $color-white;
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
      }

      .modal-content {
        width: fit-content;
        height: fit-content;
        padding: 20px;
        border-radius: 8px;
      }

      .close {
        color: $color-grey-23;
        float: right;
        font-size: 28px;
        font-weight: bold;
      }

      .hidden {
          display: none;
      }

      .visible {
          display: block;
      }

      .pointer {
          cursor: pointer;
      }

      .close:hover,
      .close:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }

    .welcome-wrapper {
        margin-top: 3rem;
        margin-bottom: 3rem;
        border-radius: 5px;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
        background-color: #fff;

        .welcome-kyc {
            display: block;
            padding: 2rem;
            background-color: $founderDarkerBrand;
            border-bottom: 2px solid #f6f7fa;

            &__block {
                display: flex;
                flex-direction: row;
                align-items: center;

                &--image {
                    width: 40px;
                }

                &--spacer {
                    width: 40px;
                }

                &--disclaimer {
                    font-size: 1.6rem;
                    font-family: Nunito, sans-serif;
                }

                &--title {
                    font-size: 2rem;
                    margin-bottom: 0;
                    text-align: left;
                    color: $white;
                }
            }

            &__text-wrapper {
                color: $white;
                line-height: 1.67;
                font-family: NunitoBold, sans-serif;
                display: flex;
                flex-direction: column;
                margin-left: 1.5rem;
                width: calc(100% - 40px);
            }
            .kyc-form {
              margin-top: 25px;
              color: $white;
              margin-left: 4.5rem;
              .upload-title {
                  margin-top: 30px;
                  font-size: 16px;
              }
              .upload-subtitle {
                  margin-top: 5px;
                  font-size: 14px;
              }
              .kyc-input {
                  padding: 1.4rem 2.5rem;
              }
              .kyc-file-btn {
                  background-color: $white;
                  color: #ffffff;
              }
              .name-inputs {
                  display: block;
                  @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                      display: flex;
                  }
              }
              .name-input-container {
                  &:first-child {
                      margin-right: 15px;
                  }
                  width: 100%;
                  @include multiple-media(tabletScreen, smallScreen, mediumScreen, largeScreen) {
                      width: 50%;
                  }
                  label {
                      font-size: 16px;
                  }
                  input {
                      width: 100%;
                      border-radius: 5px;
                      margin-right: 10px;
                      box-sizing: border-box;
                      border-width: 0px;
                      font-family: Nunito;
                      font-size: 16px;
                      margin-top: 5px;
                  }
              }
              .upload-component {
                  input[type="file"] {
                      width: 0.1px;
                      height: 0.1px;
                      opacity: 0;
                      overflow: hidden;
                      position: absolute;
                      z-index: -1;
                      &:focus {
                          +label {
                              outline: 2px dotted $color-blue-light;
                          }
                      }
                  }
                  .add-btn {
                      padding: 1.2rem 2rem;
                      color: $founderDarkerBrand;
                      background-color: $white;
                      border-radius: 5px;
                      font-size: 14px;
                      font-family: NunitoBold, sans-serif;
                      cursor: pointer;
                      display: inline-block;
                      margin-top: 15px;
                  }
              }
              .kyc-submit-btn {
                  margin-top: 30px;
                  padding: 1.4rem 2.5rem;
                  background-color: rgba($white, 0.3);
                  border-radius: 5px;
                  font-size: 16px;
                  font-family: NunitoBold, sans-serif;
                  border-width: 0px;
                  color: $founderDarkerBrand;
                  &.ready-to-submit {
                      cursor: pointer;
                      background-color: rgba($white, 1);
                  }
              }
            }
        }

        .welcome-step {
            padding: 2rem;
            font-family: Nunito, sans-serif;
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid #f6f7fa;

            &__headline {
                font-size: 1.8rem;
                display: flex;
                align-items: center;

                &--number {
                    color: $color-white;
                    width: 4rem;
                    height: 4rem;
                    display: flex;
                    border-radius: 50%;
                    text-align: center;
                    align-items: center;
                    justify-content: center;

                    &.suggestions-bold-number {
                        font-weight: 900;
                    }

                    .material-icons {
                        font-size: 2rem;
                    }
                }

                .success-headline {
                    background-color: #99a1ab;
                }
                .gray-color {
                    color: #626262;
                }

                &--title {
                    font-family: NunitoBold, sans-serif;
                    margin-left: 1.5rem;
                }
            }

            &__body {
                display: flex;
                flex-direction: column;
                margin-top: 4rem;
                margin-right: 2rem;
                margin-left: 5.5rem;

                .step-info {
                    display: flex;


                    &__view {
                        background-color: #fafbfd;
                        display: flex;
                        padding: 2rem;
                        justify-content: center;
                        width: 45%;
                    }

                    &__description {
                        display: flex;
                        flex-direction: column;
                        margin-left: 3rem;

                        &--text {
                            font-family: Nunito, sans-serif;
                            font-size: 1.6rem;
                            line-height: 1.81;
                            color: #626262;
                        }

                        &--button {
                            color: $color-white;
                            padding: 1.4rem 2.5rem;
                            background-color: #390153;
                            max-width: max-content;
                            border-radius: 5px;
                            font-size: 1.6rem;
                            font-family: NunitoBold, sans-serif;
                            margin: 3rem 0;
                            cursor: pointer;
                        }
                    }
                }

                .step-video {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;

                    @include transition(.3s all ease-in-out);

                    &:hover,
                    &:focus {
                        opacity: .8;
                    }

                    &__play {
                        box-shadow: 0 2px 4px 0 rgba(210, 210, 210, 0.5);
                        background-color: #56ccbd;
                        border-radius: 5px;

                        .material-icons {
                            color: $color-white;
                            padding: .7rem 1.6rem;
                            font-size: 1.5rem;
                        }
                    }

                    &--text {
                        font-size: 1.4rem;
                        font-family: NunitoBold, sans-serif;
                        color: #56ccbd;
                        margin-left: 1rem;
                    }


                }
            }

            .company-background {
                background-color: #56ccbd;
            }

            .deal-background {
                background-color: #56ccbd;
            }

            .investor-background {
                background-color: #56ccbd;
            }
        }
    }
}
