.track-investors {
  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    input {
      height: 46px;
      width: calc(100% - 6.1rem);
      background-color: $color-white;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      border: 1px solid $smallBlockBorder;
      padding-left: 5rem;
      padding-right: 1rem;
      font-size: 1.4rem;
      &:focus {
        outline: none;
      }
    }
    .fa-search {
      position: absolute;
      margin-left: 2rem;
      font-size: 1.8rem;
      color: $color-grey-6;
    }
    &-right-section {
      display: flex;
      position: absolute;
      right: 1rem;
      a, .investors-filter {
        font-size: 1.2rem;
        color: $color-grey-6;
        cursor: pointer;
        padding: 6px 8px;
        margin: 0 9px;
        font-weight: 500;
        background-color: $color-white;
        border: solid 1px $color-grey-3;
        border-radius: 5px;
        text-transform: uppercase;
      }
      a {
        .fa {
          font-size: 1.6rem;
          color: $color-grey-6;
          margin-right: 0.3rem;
        }
      }
      .investors-filter {
        display: flex;
        align-self: flex-end;
        padding-top: 8px;
        .dropdownFilter {
          width: 100%;
          top: 10px;
          box-shadow: 0px -1px 4px 0px $smallBlockBorder;
          text-transform: none;
          &:before {
            content: "";
            position: absolute;
            border: solid 5px white;
            box-shadow: -1px -1px 0 0 $smallBlockBorder;
            transform: rotate(45deg);
            top: -4px;
            right: 5px;
          }
        }
      }
    }
  }
  &__content {
    height: calc(100vh - 26rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-x: auto;
    > div:not(:last-child) {
      margin-right: 8px;
    }
    .matched-to-deal,
    .deal-viewed,
    .track-feedback {
      width: 33.33%;
      height: 98%;
      display: flex;
      flex-direction: column;
      min-width: 255px;
      &__header {
        min-height: 50px;
        max-height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        &--title {
          span {
            font-family: $regular;
            font-size: 1.8rem;
            font-weight: 500;
            color: $color-white;
            padding-left: 15px;
          }
        }
      }
      &__content {
        width: 100%;
        flex-grow: 1;
        background-color: $color-white;
        overflow-y: auto;
      }
      &__percentage {
        height: 45px;
        width: 100%;
        border-top: 1px solid $lightGray;
        background-color: $color-white;
        display: flex;
        flex-direction: row;
        align-items: center;
        &--red, &--orange, &--green {
          color: $color-white;
          font-size: 1rem;
          font-weight: 500;
          height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &--red {
          background-color: $color-red-main;
          width: 25%;
          margin-left: 15px;
          margin-right: 2px;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
        &--orange {
          background-color: $color-yellow-main;
          width: 25%;
          margin-right: 2px;
        }
        &--green {
          background-color: $color-green-main;
          width: 50%;
          margin-right: 15px;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }
    .investors-box {
      margin: 20px;
      border: 1px solid $smallBlockBorder;
      border-radius: 3px;
      min-height: 53px;
      background-color: $color-white;
      &__anon {
        cursor: default;
      }
      &:hover:not(.investors-box__anon) {
        cursor: grab;
      }
      &.sortable-chosen:not(.investors-box__anon) {
        cursor: grabbing;
      }
      .investor {
        display: flex;
        flex-direction: row;
        padding: 9px 9px 9px 15px;
        height: 35px;
        &__image {
          position: relative;
          &--syndicated {
            position: absolute;
            left: -5px;
            top: -5px;
            img {
              height: 15px;
              width: 15px;
            }
          }
          &--profile-image {
            img {
              width: 35px;
              height: 35px;
              border: 1px solid $borderWhite;
              object-fit: cover;
            }
          }
        }
        &__info {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: calc(100% - 75px);
          padding-left: 10px;
          a, .investor-name-block {
            font-family: $regular;
            font-size: 1.4rem;
            font-weight: 500;
            color: $color-grey-6;
            padding-bottom: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          span {
            font-family: $regular;
            font-size: 1.2rem;
            color: $color-grey-4;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &--center {
            justify-content: center;
            a {
              padding-bottom: 0;
            }
          }
          &--removed {
            width: calc(100% - 160px);
          }
        }
        &__actions {
          display: flex;
          align-items: center;
          height: 100%;
          .granted-permission {
            .fa-folder {
              color: $color-blue-main;
              font-size: 26px;
            }
          }
          .not-granted-permission {
            position: relative;
            .fa-folder {
              color: $color-grey-4;
              font-size: 26px;
            }
            .fa-times {
              color: $color-white;
              font-size: 11px;
              position: absolute;
              top: 8px;
              left: 7px;
            }
          }
          .accessed-permission {
            position: relative;
            .fa-folder {
              color: $color-blue-main;
              font-size: 26px;
            }
            .fa-check {
              color: $color-white;
              font-size: 11px;
              position: absolute;
              top: 8px;
              left: 7px;
            }
          }
          &--removed-text {
            font-size: 1.3rem;
            color: $color-grey-4;
            text-align: right;
            margin-right: 0.8rem;
          }
        }
      }
      .investor.removed {
        opacity: 0.5;
      }
      .investor-feedback {
        display: flex;
        flex-direction: row;
        padding: 5px 9px 9px 15px;

        .order-placed,
        .feedback-sent,
        .not-interested {
          color: $color-white;
          font-size: 1rem;
          font-weight: 500;
          padding: 5px 8px;
          border-radius: 3px;
        }
        .order-placed {
          background-color: $color-green-main;
          margin-right: 5px;
        }
        .feedback-sent {
          background-color: $color-yellow-main;
          margin-right: 5px;
        }
        .not-interested {
          background-color: $color-red-main;
        }
      }
    }
  }
}

.investor-permission-tooltip {
    word-break: break-word;
    &--without-permission {
      width: 20.7rem;
      @include breakpoint(toBigPhone) {
        width: 14rem;
      }
    }
    &--has-permission {
      width: 25.6rem;
      @include breakpoint(toBigPhone) {
        width: 13.1rem;
      }
      @include breakpoint(smallTablet) {
        width: 18rem;
      }
    }
    &--visited {
      @include breakpoint(toBigPhone) {
        width: 9.1rem;
      }
      @include breakpoint(smallTablet) {
        width: 15.5rem;
      }
    }
    .tooltip-inner {
      padding: 0.2rem 1rem !important;
      font-size: 1.2rem !important;
      font-weight: 600;
      border-radius: 3px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      background-color: $color-grey-6;
      @include breakpoint(hugeScreen) {
        font-size: 1.2rem !important;
      }
      @include breakpoint(toBigPhone) {
        padding: 0 5px !important;
      }
   }
}
