///------------------------------------
//    #SETTINGS
//------------------------------------

// Globaly available classes





//-------------------------
//    Global default settings
//	  for ceratin classes
//-------------------------

/* Let's get this party started */
::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px transparent;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $brand;
    -webkit-box-shadow: inset 0 0 2px $brand;
}
::-webkit-scrollbar-thumb:window-inactive {
	background: $brand;
}
/* 'Corner' is white square that appear on top and bottom of scrollbars in Chrome and Firefox */
::-webkit-scrollbar-corner {
    background: rgba(0,0,0,0);
}
a:focus {
    outline: none;
}
button::-moz-focus-inner {
    border: 0;
}
$border : 1px solid $borderColor;
.multiselect {
  cursor: pointer;
}
body{
    min-height: auto;
}
.do-originator{
    background: $darkerWhiteDO;
    .edit{
        display: block;
        i{
            &:hover{
                color: $brand;
            }
        }
    }
    a {
        color: $black;
    }
    .layer-container.advisor, .modal-layer-container.advisor {
        top: 50px !important;
    }

    .track-investors {
        .layer-container.advisor, .modal-layer-container.advisor {
            z-index: 12;
        }
    }
}
.container{
    max-width: 1000px;
    margin: 0 auto;
}
.management{
    .container{
        padding: 0 5rem;
        @include breakpoint (desktop){
            width: 140rem;
        }
    }
}
.tags > a > div{
    font-size: 14px;
    padding: 30px 0;
    @include breakpoint (toDesktop){
        font-size: 2.2rem;
    }
}
.tags{
    @include breakpoint (toDesktop){
        flex-wrap: wrap;
    }
}
.management > div > section > figure > figcaption{
    font-size: 2.2rem;
    margin-top: 0.5rem;
    @include breakpoint (toDesktop){
        font-size: 2.4rem;
    }
}
.matching-score {
    cursor: pointer;
}
