.preview-company-nda {
  position: fixed;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1;
  top: 0;
  left: calc(100% - 1600px);
  border-left: 1px solid $color-grey-3;
  box-shadow: 0 0 1rem .5rem transparentize($color-grey-3, .75);
  @include breakpoint(mediumScreen) {
    left: calc(100% - 1100px);
  }
  @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
    left: 0;
  }
  @include breakpoint(mediumScreen) {
    left: calc(100% - 1100px);
  }
  @include breakpoint(largeScreen) {
    left: calc(100% - 1600px);
  }
  .layer-container {
    position: initial;
    .layer-body {
      padding: 1.2rem 4rem 0;
      &__top {
        border-bottom: 1px solid $layerBorder !important;
      }
      &__navigation {
        height: 100vh;
        #nda-modal-body.box {
          max-height: calc(100vh - 24rem);
        }
        .non-disclosure-agreement {
          min-height: calc(100vh - 28rem);
          max-height: calc(100vh - 28rem) !important;
        }
      }
    }
  }
  .do-nda .ladda-button {
    border-radius: 0;
    &:hover{
      cursor: pointer;
    }
  }
  .do-nda {
    margin-top: 50px;
  }
  .do-nda__text {
    margin-bottom: 3rem;
  }
  .homeLink  {
    div {
      left: 0;
      right: 1600px;
      opacity: .5;
      background-color: $color-grey-6;
      @include breakpoint(mediumScreen) {
        right: 1100px;
      }
      @include multiple-media(mobileScreen, tabletScreen, smallScreen) {
        display: none;
      }
    }
  }
}
