.do-bookbuilding{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .layer-body__middle-filters-search{
    margin-bottom: 2rem;
  }
  &__left{
    flex-basis: 200px;
    width: 200px;
    @include breakpoint(mediumScreen) {
      width: 200px;
      flex-basis: 200px;
    }
    @include breakpoint(largeScreen) {
      width: 250px;
      flex-basis: 250px;
    }
    @include breakpoint(smallLaptop) {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 20rem);
    }
    &-box{
      background: $white;
      border-radius: 5px;
      padding: 1.5rem;
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &-block{
      margin-bottom: 1rem;
      margin-top: 1rem;
      position: relative;
      &:last-child{
        margin-bottom: 0;
      }
      label{
        font-size: 1rem;
        font-weight: 600;
        color: $smallColor;
        padding-bottom: 0.5rem;
        display: block;
      }
      span{
        color: $black;
        font-weight: 500;
        font-size: 1.4rem;
      }
      input{
        box-sizing: border-box;
        width: 100%;
        outline: none;
        background-color: $tableGray;
        color: $black;
        font-weight: 500;
        border: 1px solid $borderColor;
        border-radius: 2.5px;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 6.2rem;
        padding-right: 1rem;
        font-size: 1.3rem;
        font-family: $regular;
        min-height: 5rem;
      }
      button{
        display: block;
        width: 100%;
        padding: 1.5rem 2rem;
        font-weight: 600;
        color: $white;
        background-color: $positive;
        border: 0;
        outline: none;
        cursor: pointer;
        text-transform: uppercase;
        font-family: $regular;
        font-size: 1.2rem;
        cursor: default;
        &.disabled{
          opacity: 0.3 !important;
        }
      }
      &-currency{
        position: absolute;
        top: 3.5rem;
        left: 3rem;
        content: '';
        display: block;
        color: $smallColor !important;
        font-size: 1.1rem !important;
        font-family: $regular;
      }

      .check {
          @include multiple-media(toDesktop) {
              height: 15px;
              width: 15px;
          }
      }

      .login__list-item-holder{
        padding-left: 3rem;
        margin-bottom: 1rem;
        &:last-child{
          margin-bottom: 0;
        }
        label{
          color: $black;
          text-transform: none;
          font-weight: 500;
          font-size: 1.2rem;
        }
      }
    }
    .check{
        top: 0.5rem;
    }
  }
  &__right{
    border: 1px solid $layerBorder;
    flex-basis: calc(100% - 220px);
    width: calc(100% - 220px);
    max-height: calc(100vh - 20rem);
    @include breakpoint(mediumScreen) {
      flex-basis: calc(100% - 220px);
      width: calc(100% - 220px);
    }
    @include breakpoint(largeScreen) {
      flex-basis: calc(100% - 270px);
      width: calc(100% - 270px);
    }
    .empty-content {
      color: #666;
      font-size: 14px;
      text-align: center;
      padding: 2rem 1rem;
    }
    .todo-widget__block-right-info:after{
      display: none;
    }
    .todo-widget__block-right--second{
      width: 60%;
      flex-basis: 60%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 6rem;
      .do-bookbuilding__right-middle{
        display: flex;
        align-items: center;
        margin: 0.25rem 0;
        width: 100%;
        position: relative;
        span{
          display: block;
          font-size: 1.1rem;
          font-weight: 500;
          width: 5.5rem;
          position: absolute;
          left: -5.5rem;
        }
        .right-absolute{
          position: absolute;
          right: -6.5rem;
          left: auto !important;
        }
      }
    }
      .investor-block-wrapper {
          overflow-x: hidden;
          overflow-y: scroll;
          max-height: calc(100vh - 25rem);
      }

      &__head {
          display: flex;
          position: sticky;
          top: 0;
          background-color: $color-white;
          padding: 1.5rem 2rem;
          border-bottom: 1px solid $layerBorder;

          &__item:nth-of-type(1) {
              flex-basis: 26%;
              min-width: 10rem;
          }
          &__item:nth-of-type(2) {
            flex-basis: 14%;
          }
          &__item:nth-of-type(3) {
            flex-basis: 47%;
            @include multiple-media(mediumScreen) {
              flex-basis: 44%;
            }
            @include multiple-media(smallScreen) {
              flex-basis: 45%;
            }

            @include multiple-media(tabletScreen) {
              flex-basis: 42%;
            }
          }
          &__item:nth-of-type(4) { flex-basis: 10%; }

        &__item {

              &--title {
                  font-size: 1.3rem;
                  font-weight: 500;
                  color: $color-grey-6;
              }
          }
      }

      &__investor {
          border-bottom: 1px solid $layerBorder;
      }

      .investor-block {
          display: flex;
          align-items: flex-start;
          padding: 2rem;
          background: $white;
          border-top: 0;
          min-height: 42px;
          border-bottom: 1px solid $layerBorder;

          &__investor-company {
              flex-basis: 28%;
              .todo-widget__block-right-info {
                  width: 100%;
              }
          }

          &__orders {
              display: flex;
              flex-direction: column;
              flex-basis: 72%;
          }

          &__order {
              display: flex;
          }

          &__amount {
              margin-top: 0.3rem;
              flex-basis: 21%;

              &--value {
                  font-weight: 500;
                  font-size: 1.3rem;
              }
          }

          &__message-content {
              display: flex;
              flex-basis: 78%;
              flex-direction: column;

              &--message {
                  word-break: break-word;
                  text-align: justify;
                  font-size: 1.3rem;
                  color: #434a54;
                  line-height: 1.46;
                  font-weight: 500;
                  white-space: pre-wrap;
              }

              &--order-info {
                  color: $color-grey-6;
                  padding: 1rem 0 1.5rem;
                  font-size: 1.2rem;
                  font-weight: normal;
              }
          }

          &__action {
              display: flex;
              justify-content: flex-end;
              flex-basis: 8%;

              button, a {
                  display: block;
                  border: 0;
                  text-transform: uppercase;
                  font-size: 1.2rem;
                  color: $brand;
                  letter-spacing: 0.5px;
                  width: auto;
                  padding: 0;
                  outline: none;
                  cursor: pointer;
                  background-color: transparent;
                  @include transition (.3s all ease-in-out);
                  font-family: $regular;
                  font-weight: 600;
                  &:hover{
                      padding: 1rem;
                      background-color: $brand;
                      color: $white;
                  }
                  &.green{
                      color: $positive;
                      font-size: 1.8rem;
                      font-weight: 600;
                      padding: 0.5rem 1rem;
                      &:hover{
                          border-radius: 100%;
                          background-color: $positive;
                          color: $white;
                      }
                  }
              }
          }
      }
  }
  &__bar{
    background-color: $layerBackground;
    padding: 0.5rem;
    margin: 0.2rem 0;
    &-green{
      background-color: $positive;
    }
  }

  .bookbuild-button {
    border: none;
    cursor: pointer;
    display: block;
    color: #1f7be8;
    font-size: 1.3rem;
    font-weight: 500;
    padding: 0;
    outline: 0;
    width: fit-content;
    background-color: white;
  }
}
