.syndicateAddModalPopup{
	.investorAddModalPopup{
		background-color: $white;
		max-width: 94.5rem;
		padding-bottom: 3rem;
		.syndication__widget-section{
			margin-bottom: 1rem;
			padding: 2rem;
		}
		.investorLog{
			padding: 2rem 3rem;
		}
		.peoples__links{
			padding: 0rem 3rem;
		    padding-bottom: 0.2rem;
			margin-bottom: 3rem;
			box-shadow: none;
		    a{
		    	padding-bottom: 2rem;
		    }
		}
		.syndication__widget-section{
			width: 100%;
			display: flex;
			.peoples__body-name{
				padding: 0;
			}
		}
		.edit__profile{
			margin-left: 2rem;
		}
	}
	&__table{
		margin: 0 3rem;
		border: 1px solid $borderColor;
		overflow-y: scroll;
		max-height: 80vh;
		&-cell{
			display: inline-block;
			vertical-align: top;	
			&:nth-child(1){
				width: 30%;
			}	
			&:nth-child(2),
			&:nth-child(3){
				width: 22%;
			}
			&:nth-child(4){
				width: 23%;
			}
			&:nth-child(5){
				width: 15.5%;
			}
		}
		.syndicateAddModalPopup__table-header{
				color: $smallColor;
			    font-size: 1.2rem;
			    padding: 2rem;
			.syndicateAddModalPopup__table-cell{

			}
		}
		.syndicateAddModalPopup__table-body{
				color: $black;
			    font-size: 1.2rem;
			    font-weight: 400;
			   	padding: 2rem 2rem;
			    &:nth-child(2n+1){
			    	background-color: $white;
			    }
			    &:nth-child(2n+2){
			    	background-color: $tableGray;
			    }
			.syndicateAddModalPopup__table-cell{
			}
		}
	}
}
.syndicate-wrapper{
	.peoples__infoBlock{
		.deny__button,
		.full-button{
			&:hover{
				cursor: pointer;
			}
		}
	}
	.peoples__bigBlock{
		.peoples__links{
			box-shadow: none;
		}
	}
	.peoples__bigBlock{
		.logo-info > span:first-child{
			@include transition (.3s all ease-in-out);
		}
		.peoples__body-name{
			&:hover{
				cursor: pointer;
				.logo-info{
					span{
						color: $brand;
					}
				}
			}
		}
	}
	.peoples__body-name{
		border-bottom: 1px solid $borderColor;
	}
	.logo-info{
		vertical-align: middle;
		display: inline-block;
		flex-grow: 1;
	}
	.peoples__container{
		.peoples__header{
			margin-bottom: 0;
		}
	}
	
	.white{
			background-color: transparent !important;
			.peoples__body-name{
				border-bottom: 0;
			}
	}
	.peoples__info-content{
		color: $black;
	}
	.deny__button{
		background-color: $errorBackground;
	}
	.accept__button{
		background-color: $acceptBackground;
	}
	.edit{
		right: 0rem;
	}
	.deny__button,
	.accept__button{
		padding: 2rem 0;
		a{
			font-size: 1.6rem;
		}
	}
	.peoples__bigBlock{
		small{
			width: auto;
		}
		.peoples__body{
			background-color: transparent;
		}
		.peoples__body-name{
			border-bottom: 0;
			&-static{
				color: $investmentTitle;
				font-weight: 300;
			}
			&-do,
			&-submited{
				margin-right: 1.5rem;
				color: $investmentTitle;
				font-weight: 500;
			}
			&-submited{
				margin-right: 0;
			}
			&-holder{
				display: inline-block;
				vertical-align: middle;
				width: 40%;
				&:nth-child(1){
					display: flex;
					align-items: center;
					width: auto;
					
				}
				&:nth-child(2){
					width: auto;
				}
			}
		}
		.edit{
			top: 33%;
		}
		.peoples__header{
			padding-bottom: 0;
		}
		.peoples__links{
			margin: 0;
			padding: 0;
			display: inline-block;
			min-width: max-content;
			ul{
				margin-top: 2rem;
				padding-left: 0;
				margin-left: 0;
				margin-bottom: 0;
				span{
					padding-bottom: 1rem;
					display: inline-block;
					margin-right: 2.5rem;
					cursor: pointer;
					position: relative;
					&:after{
						position: absolute;
						bottom: 0;
						left: 50%;
						right: 50%;
						background-color: $brand;
						display: block;
						content: '';
						height: 2px;
						@include transition (.3s all ease-in-out);
					}
				}
				.active{
					color: $brand;
					&:after{
						left: -1rem;
						right: -1rem;
					}
				}
			}
		}
	}
	.peoples__bigBlock{
		.peoples__header-search{
			width: 72%;
		}
	}
	.peoples__smallBlock:nth-child(2){
		.peoples__header h2{
			width: 46%;
		}
	}
	.peoples__header-search{
		width: 52%;
		input{
			width: 0rem;
			&.activeInput{
				width: 17rem;
			}
		}
	}
}
.syndicateMiddleModal{
	.peoples__body-name{
		padding: 2rem 3rem;
	}
	.multiselect{
		padding-left: 0;
		background: $darkerWhite;
		width: 95.6%;
		height: auto;
		min-height: auto;
		padding: 2rem 2rem;
		padding-bottom: 1.5rem;
		.multiselect__content{
			background-color: $white !important;
			top: 9rem !important;
			li{
				border-bottom: 1px solid $borderColor !important;
			}
		}
		.multiselect__option--highlight{
			background: $darkerWhite;
		}
		.multiselect__tag{
			display: inline-block;
			border-radius: 0;
			margin-right: 2rem;
			padding: 2rem 6.7rem;
			border-radius: 0;
			border-color: $borderColor;
		}
		.multiselect__tags-wrap{
			display: inline;
			> div{
				display: inline-block;
				margin-bottom: 1rem;
			}
		}
		.multiselect__tags{
			background-color: $darkerWhite;
		}
		.multiselect__tag-icon{
			cursor: pointer;
			margin-left: 7px;
			position: absolute;
			right: 1.7rem;
			top: 1.3rem;
			font-size: 1.8rem;
			background: black;
			border-radius: 20px;
			bottom: 0;
			font-weight: 700;
			width: 2.3rem;
			height: 2.3rem;
			text-align: center;
			line-height: 22px;
			transition: all .2s ease;
			border-radius: 20px;
			&:after{
				color: $white;
				font-size: 2rem;
				font-weight: 300;
				margin-top: 30rem;
				line-height: 2.3rem;
				margin-left: -0.1rem;
			}
		}
		.multiselect__input{
			position: relative;
			display: inline-block;
			min-height: 20px;
			line-height: 20px;
			border: none;
			border-radius: 5px;
			background: transparent;
			padding: 1px 0 0 5px;
			width: auto;
			transition: border .1s ease;
			box-sizing: border-box;
			margin-bottom: 8px;
			font-size: 1.2rem;
			&::placeholder{
				color: $black;
			}
		}
	}
	.investorModalPopup{
		left: 43rem;
		right: 42.5rem;
		padding: 0.5rem 1.2rem;
		&.network-approached,&.syndicated-fee {
			overflow: auto;
		}
		&.syndicated-fee {
			max-height: 100%;
		}
		@include breakpoint (toDesktop){
			left: 0;
			right: 0;
		}
		.people__links--modal:after{
			bottom: 0.2rem;
		}
	}
	.peoples__body-name{
		&:after{
			@extend .borderMask;
		}
	}
	.syndicateModal{
		margin-top: 2.5rem;
		padding: 0 3rem;
		&__block{
			display: inline-block;
			vertical-align: top;
			&:nth-child(1){
				width: 68%;
				height: 19.5rem;
				padding: 0.5rem 2rem;
				padding-bottom: 2rem;
			}
			&:nth-child(2){
				width: 25%;
				float: right;
				height: 22rem;
				label{
					margin: 2rem 1rem;
				}
			}
			&--big{
				&:nth-child(1),
				&:nth-child(2){
					width: 95.4%;
					display: block;
					height: auto;
					float: none;
				}
			}
			label{
				font-size: 1.1rem;
				color: $smallColor;
				display: block;
				margin: 1rem;
			}
			span{
				font-size: 1rem;
				color: $smallColor;
				display: block;
			}
			input{
				width: 93%;
				display: block;
				background-color: $darkerWhite;
				border: 1px solid $investmentBorder;
				color: $investmentTitle;
				padding: 2rem;
				outline: none;
				border-radius: 5px;
				&::placeholder{
					color: $investmentTitle;
				}
			}
			&-holder{
				padding: 0 2rem;
				margin: 4.3rem 0;
				display: flex;
				align-items: center;
				span{
					display: inline-block;
				}
				small{
					display: block;
					margin-top: 0.5rem;
				}
				.deal-icon{
				    width: 8rem;
					height: 9rem;
					border: 0;
				}
			}
		}
		&__suggestions{
			margin-top: 3rem;
			width: 95.4%;
			padding: 1rem 2rem;
			.logo-info{
				margin-right: 1.5rem;
			}
			.multiselect__content{
				overflow-y: auto;
				max-height: 36rem;
			}
			.multiselect__option--highlight span{
				color: $brand !important;
			}
		}
		&__tag{
			margin: 0 1rem;
			padding: 1rem;	
			display: inline-block;
			&:nth-child(2){
				margin-left: 0;
			}
			span{
				display: inline-block;
				margin: 0 0.4rem;
				position: relative;
			}
			.logo-info{
				margin-right: 0.5rem;
				&-name{
					color: $black;
					font-size: 1.2rem;
				}
				&-type{
					border: 1px solid $borderColor;
					border-radius: 5px;
					padding: 0.3rem 0.7rem;
				}
			}		
		}
		&__add{
			background-color: $brand;
			width: 1.5rem;
			height: 1.5rem;
			vertical-align: middle;
			padding: 0.5rem;
			border-radius: 1.5rem;
			display: inline-block;
			margin-left: 2rem;
			cursor: pointer;
			@include transition (.3s all ease-in-out);
			&:hover{
				background-color: $black;
			}
			span{
				color: $white;
				font-size: 1.8rem;
				position: absolute;
				top: 1px;
				left: 0.35rem;
			}
		}
		&__footer{
			position: relative;
			background: $white;
			padding-bottom: 4.5rem;
			float: right;
			&:before{
				@extend .borderMask;
			}
			.edit{
				right: 0;
				top: 3rem;
				a{
					margin-left: 3rem;
				}
			}
			.no-changes {
				color: $gray;
				cursor: default;
			}
		}
		&.syndication-fee {
			overflow: auto;
			padding-top: 1.5rem;
			.fee-wrapper {
				display: flex;
				justify-content: space-between;
				.fee-structure {
					flex-basis: 65%;
					&__other-terms{
						display: flex;
						flex-direction: column;
						margin-top: 2rem;
						label {
							color: #aab2bd;
							font-size: 1.1rem;
							margin-bottom: 1rem;
							display: block;
						}
						textarea {
							outline: none;
							width: 100%;
							box-sizing: border-box;
							resize: none;
							border-radius: 5px;
							border: 1px solid #ececec;
							height: 10rem;
							background: #f6f7fb;
							color: #434a54;
							padding: 2rem;
							font-family: "Montserrat", sans-serif;
							font-size: 1.2rem;
						}
					}
				}
				.syndication-confirmation {
					flex-basis:30%;
				}
			}
			.syndication-list {
				width: 96%;
			}
			.syndicateModal__footer {
				margin-top: 1rem;
				.edit__profile {
					cursor: pointer;
				}
				.no-originators-selected {
					color: $gray;
					cursor: default;
				}
				&:before {
					box-shadow: none;
				}
			}
		}
	}
}
.syndicateBigModal{
	.syndicateModal__footer{
		float: none;
	}
	.multiselect{
		padding-left: 0;
		background: $darkerWhite;
		width: 95.6%;
		height: auto;
		min-height: auto;
		padding: 2rem 2rem;
		padding-bottom: 1.5rem;
		.multiselect__content{
			background-color: $white !important;
			top: 9rem !important;
			li{
				border-bottom: 1px solid $borderColor !important;
			}
		}
		.multiselect__option--highlight{
			background: $darkerWhite;
		}
		.multiselect__tag{
			display: inline-block;
			border-radius: 0;
			margin-right: 2rem;
			padding: 2rem 6.7rem;
			border-radius: 0;
			border-color: $borderColor;
		}
		.multiselect__tags-wrap{
			display: inline;
		}
		.multiselect__tags{
			background-color: $darkerWhite;
		}
		.multiselect__tag-icon{
			cursor: pointer;
			margin-left: 7px;
			position: absolute;
			right: 1.7rem;
			top: 1.3rem;
			font-size: 1.8rem;
			background: black;
			border-radius: 20px;
			bottom: 0;
			font-weight: 700;
			width: 2.3rem;
			height: 2.3rem;
			text-align: center;
			line-height: 22px;
			transition: all .2s ease;
			border-radius: 20px;
			&:after{
				color: $white;
				font-size: 2rem;
				font-weight: 300;
				margin-top: 30rem;
				line-height: 2.3rem;
				margin-left: -0.1rem;
			}
		}
		.multiselect__input{
			position: relative;
			display: inline-block;
			min-height: 20px;
			line-height: 20px;
			border: none;
			border-radius: 5px;
			background: transparent;
			padding: 1px 0 0 5px;
			width: auto;
			transition: border .1s ease;
			box-sizing: border-box;
			margin-bottom: 8px;
			font-size: 1.2rem;
			&::placeholder{
				color: $black;
			}
		}
	}
	.peoples__body-name{
		&:after{
			display: none;
		}
	}
	.people__links--modal{
		display: block !important;
		padding: 0 4rem !important;
	}
	label{
		margin: 1rem 0 !important;
	}
	p{
		font-size: 1.1rem;
		color: $black;
		display: block;
		margin: 1rem 0;
		line-height: 1.7rem;
		font-weight: 500;
	}
	.syndicateModal__footer{
		padding-bottom: 5rem;
		.edit a{
			font-size: 1.4rem;
		}
	}
	.lineBorder{
		position: absolute;
		width: 1px;
		top: -2rem;
		bottom: -2rem;
		left: 0;
		content: '';
		display: block;
		background-color: $borderColor;
	}
	.lineBorderTop{

	}
	.syndicateAddModalPopup__table{
		margin: 3rem 0;
		max-height: 50vh;
		padding: 0rem 0;
		&-header{
			.syndicateAddModalPopup__table-cell{
				&:nth-child(1n+1){
					&:before{
						display: none;
					}
				}
			}
		}
		&-header,
		&-body{
			padding-right: 0;
		}
		&-body{
			border-top: 1px solid $borderColor;
			border-bottom: 0;
			border-left: 0;
			border-right: 0;
			font-weight: 500;
		}
		&-cell{
			&:nth-child(2){
				width: 12%;
				text-align: center;
				position: relative;
				&:before{
					@extend .lineBorder;
				}
			}
			&:nth-child(3){
				width: 15%;
				text-align: center;
				position: relative;
				&:before{
					@extend .lineBorder;
				}
			}
			&:nth-child(4){
				width: 15%;
				text-align: center;
				position: relative;
				&:before{
					@extend .lineBorder;
				}
			}

			&:nth-child(5),
			&:nth-child(6){
				position: relative;
				text-align: center;
				&:before{
					@extend .lineBorder;
				}
				
			}
			&:nth-child(6){
				width: 10%;
			}
		}
 	}
}
.syndicateSigned{
	border: 0;
	label{
		color: $brand;
		padding-bottom: 1rem;
		border-bottom: 1px solid $borderColor;
		display: block;
		margin-top: 4rem !important;
	}
	&--second{
		label{
			margin-top: 1rem !important;
		}
	}
	.signedBlock{
		width: 29.3%;
		display: inline-block;
		vertical-align: top;
		position: relative;
		margin-right: 5rem;
		margin-bottom: 3rem;
		&:nth-child(3n+2){
			margin-left: 0;
		}
		&:nth-child(3n+4){
			margin-right: 0;
		}
		&-left,
		&-right{
			display: inline-block;
			vertical-align: middle;
		}
		&-left{
			width: 84%;
		}
		
		&__item{
			width: 57%;
			display: inline-block;
			vertical-align: middle;
			border: 1px solid $borderColor;
			height: 9rem;
			margin-left: -0.5rem;
			border-bottom:0;
			&:nth-child(2n+1){
				width: 30%;
				text-align: center;
				margin-left: 0;
				padding-bottom: 0.1rem;
			}
			&:nth-child(4),
			&:nth-child(3){
				border-bottom: 1px solid $borderColor;
			}
			small{
				display: block;
			}
			.deal-icon{
				width: 5rem;
				height: 7rem;
				border: 0;
				padding-top: 1.3rem;
				padding-left: 0.5rem;
			}
			.logo-info{
				padding-left: 1.5rem;
				padding-top: 2.5rem;
				span{
					color: $black;
					font-weight: 500;
					font-size: 1.3rem;
				}
				small{
					color: $smallColor;
					font-size: 1.1rem;
					margin-top: 0.3rem;
				}
			}
		}
		&-right{
			position: absolute;
			top: 0;
			bottom: 0;
			left: 74.4%;
			width: 8rem;
			border: 1px solid $borderColor;
			border-left: 0;
			.signedBlock__item{
				width: 100%;
				border: 0;
				margin-top: 105%;
				.edit__withdraw{
					color: $brand;
				}
			}
		}
	}
}
.specialFooter{
	padding-bottom: 7rem !important;
}
.activeStatus{
	color: $green;
}
.pendingStatus{
	color: $yellow;
}
.rejectedStatus{
	color: $red;
}
.multiselect__holder{
	position: relative;
	&-search{
		position: absolute;
		top: 32%;
		right: 1.5rem;
		.searchButton{
			font-size: 4rem;
			color: $smallColor;
			font-weight: 100;
			vertical-align: middle;
		}
		.arrowButton{
			font-size: 3rem;
			color: $smallColor;
			vertical-align: middle;
			display: none;
		}
	}
	.syndicateModal__tag{
		margin-left: 0;
		background: $white;
	}
	.syndicateModal__add{
		background-color: $black;
	}
	.multiselect__element{
		.syndicateModal__tag{
			background-color: transparent;
		}
	}
	&.network-approached {
		.multiselect {
			&__content {
				max-height: 32rem;
				top: 0 !important;
				&-wrapper {
					position: absolute;
					width: 96%;
				}
			}
			&__holder-search {
				display: block;
				float: right;
				padding: 2rem;
				position: initial;
				top: 0;
				right: 0;
			}
			.syndicateModal__add {
				span {
					top: 0;
				}
			}
		}
	}
}
