@mixin custom_table() {
  display: -webkit-box;
  align-items: flex-start;
  overflow-y: hidden;

  .columnsPosition {
    height: 100%;
  }

  .rowBlock {
    line-height: 45px;
    &--empty {
      padding: 2.4rem;
      border: 1px solid $borderColor;
      @media not all and (min-resolution: .001dpcm) {
        margin-bottom: -3px;
        height: 45px;
        padding:0;
      }
    }

    @media not all and (min-resolution: .001dpcm) {
      margin-bottom: -4px;
    }

    &--add {
      border: 1px solid $brand;
      padding: 2rem;
      text-align: center;
      font-size: 6rem;
      line-height: 0.3;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      color: $brand;
      cursor: pointer;
    }

    &--square {
      border: 1px solid $brand;
      padding: 1.55rem;
      text-align: center;
      font-size: 6rem;
      line-height: 0.3;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      cursor: pointer;
    }

    &--relative {
      position: relative;
    }
  }

  .remove__button {
    right: 0px;
    top:  -11px;
  }
  >div {
    position: relative;
    width: 17rem;
    &:first-child,
    &:last-child {
      width: auto;
    }
    input {
      @extend .input;
      border-radius: 0;
      &:hover {
        border: 1px solid $borderColor;
      }
      &:focus {
        border: 1px solid $borderColor;
      }
    }
  }


}