//strange behaviour with overflow: hidden; automatically set when redirect to lounge detail page
html {
  overflow: visible !important;
}

.company-description {
    &__content {
      padding: 20px 30px;
      height: 50%;
      overflow-y: auto;
      background-color: $color-compact-background;
      @include multiple-media(mobileScreen, tabletScreen) {
        height: 100%;
      }
      .text-content {
        margin: 1rem 0;
      }
    }
}
