.compact-business-vision {
  background-color: $color-compact-background;
  &__content {
    padding: 0 30px;
    @include multiple-media(mobileScreen, tabletScreen) {
      height: 100%;
    }

    .file-container {
      &:first-child {
        margin-top: 4rem;
      }

      &:not(:last-child) {
        margin-bottom: 4rem;
      }
    }
  }
}
