.do-legals-originator {
  width: auto;
  flex-basis: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &__center{
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    flex-basis: 100%;
    @include breakpoint(desktop) {
      max-height: calc(100vh - 21rem);
      overflow-y: auto;
    }
    @include breakpoint (toDesktop){
      width: 100%;
      flex-basis: 100%;
      margin-top: 3rem;
      overflow-y: auto;
      max-height: calc(100vh - 45rem);
    }
    &-block{
      background: $white;
      margin-bottom: 2rem;
      @include breakpoint (toDesktop){
        width: 47%;
      }
      &-title{
        font-size: 1.3rem;
        font-weight: 600;
        padding: 14px 20px;
        position: relative;
        border-bottom: 1px solid $layerBorder;
        @include breakpoint (toDesktop){
          font-size: 2.3rem;
        }
        display: flex;
        align-items: center;
        min-height: 3.6rem;
      }
      &-img{
        width: 9rem;
        height: 17rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img{
          width: 100%;
          display: block;
          margin: 0 auto;
            max-height: 150px;
        }

      }
      &-text{
        border-top: 1px solid $layerBorder;
        padding: 1.4rem 2rem;
        min-height: 4.8rem;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        span{
          &:first-child{
            display: block;
            font-size: 1.3rem;
            color: $black;
            font-weight: 500;
            margin-bottom: 0.5rem;
            @include breakpoint (toDesktop){
              font-size: 2.3rem;
            }
          }
          &:last-child{
            display: block;
            font-size: 0.9rem;
            font-weight: 400;
            color: $smallColor;
            width: 100%;
            @include breakpoint (toDesktop){
              font-size: 1.9rem;
            }
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
    .do-legals__right-block-text {
        max-height: 4.8rem;
        padding-top: 1.4rem;
    }
}
