.hubx-deals {
  margin-top: 8rem;
  @include multiple-media (mobileScreen, tabletScreen) {
    margin-top: 4rem;
  }
  &__title {
    margin: 0;
    padding: 0;
    font-size: 2.7rem;
    letter-spacing: 0;
    font-weight: bold;
    text-transform: none;
    color: $black;
    margin-bottom: 3rem;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    clear: both;
    height: 50rem;
    overflow: hidden;
    @include multiple-media (mobileScreen, tabletScreen) {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  &__big-block {
    margin-left: 26px;
    border: 1px solid $smallBlockBorder;
    box-shadow: -2px 5px 12px -6px $lightGray;
    display: flex;
    align-items: flex-start;
    background-color: $white;
    border-radius: 5px;
    min-height: 33.3rem;
    flex-wrap: wrap;
    width: calc(100% - 220px);
    @include transition (.1s all ease-in-out);
    @include breakpoint (smallLaptop) {
      justify-content: space-between;
    }
    @include breakpoint(tabletLandscape) {
      justify-content: space-between;
    }
    @include multiple-media (mobileScreen, tabletScreen) {
      width: 100%;
      margin-left: 0;
      align-items: normal;
      padding: 0;
    }

    &-left {
      width: 310px;
      margin-right: 20px;
      @include multiple-media (mobileScreen, tabletScreen) {
        display: none;
      }

      .data {
        section {
          width: auto;
          max-width: 25%;

          &:first-child {
            padding-left: 0.5rem;
          }
        }
      }
    }

    &-right {
      width: calc(100% - 330px);
      @include multiple-media (mobileScreen, tabletScreen) {
        min-height: auto;
        width: calc(100% - 30px);
        position: relative;
        top: 25px;
        left: 15px;
        margin-bottom: 2rem;
      }
    }

    &-left,
    &-right {
      h3 {
        @extend .do-originator__h3;
        font-weight: 600;
        margin-bottom: 1.7rem !important;
      }
    }

    &-body {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 2.5rem;
      @include multiple-media (mobileScreen, tabletScreen) {
        padding: 1rem;
      }
    }

    &-footer {
      width: 100%;
      min-height: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $dealIconColor;
      @include breakpoint (mobileScreen) {
        flex-direction: column;
        padding: 5px 0;
      }
      button, a {
        background: transparent;
        border: 0;
        text-transform: uppercase;
        font-size: 1.2rem;
        cursor: pointer;
        margin: 0 1.5rem;
        padding: 1rem 2rem;
        transition: 0.3s all ease-in-out;
        font-weight: 500;
      }

      .do-button__brand:hover {
        background-color: $brand;
        color: $white !important;
      }
    }
  }

  &__company-media-info {
    background-color: transparent;
    border: 1px solid $investmentBorder;
    width: 100%;
    margin: 0;
    height: 310px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    section {
      padding: 0 5px;

      span {
        font-size: 9px;
        font-weight: normal;
        letter-spacing: 0;
        margin-bottom: 5px;
      }
    }

    h4 {
      font-size: 1.3rem;
      font-weight: 500;
    }

    .company-profile-logo-block {
      height: 55px;
      width: 55px;
      padding: 5px;
      border-radius: 5px;
      position: relative;
      bottom: 10px;
      left: 10px;
    }

    .data {
      padding: 1rem !important;

      section {
        &:nth-child(1) {
          @-moz-document url-prefix() {
            word-break: break-all;
          }
        }
      }
    }

    .link-to-company-see-more {
      display: none;
    }
  }

  &__deal-info {
    @include multiple-media (mobileScreen, tabletScreen) {
      h3 {
        font-size: 1.8rem;
        font-weight: bold;
      }
    }
  }

  &__investor-right {
    cursor: pointer;
    padding: 0 2rem;
    width: calc(100% - 100px);
    display: flex;
    align-items: center;
    @include multiple-media (mobileScreen, tabletScreen, smallScreen) {
      width: calc(100% - 70px);
      padding: 0 1.5rem;
    }
    @include breakpoint (mobileScreen) {
      padding: 0 0 0 1rem;
    }
    &-info {
      width: calc(100% - 45px);
      position: relative;
      cursor: pointer;
      padding: 2.5rem;
      @include multiple-media (tabletScreen, smallScreen) {
        padding: 0 0 0 1rem;
      }
      @include breakpoint (mobileScreen) {
        padding: 0 0 0 0.5rem;
      }
    }

    &-title {
      font-size: 1.3rem;
      font-weight: 600;
      color: $color-grey-6;
      display: block;
      margin-bottom: .3rem;
      transition: all .3s ease-in-out;
    }

    &-desc {
      font-size: 1rem;
      line-height: 1.3rem;
      font-weight: 500;
      color: $dark-gray;
      display: block;
      padding-right: 2rem;
    }

    &-type {
      font-size: 1rem;
      line-height: 1.3rem;
      font-weight: 500;
      color: $color-grey-6;
      display: block;
      padding-right: 2rem;
    }
  }

  &__investor-image {
    display: flex;
    align-items: center;
    border: 1px solid $dealBlockColor;
    position: relative;
    background: $color-white;
    justify-content: center;

    img {
      width: 40px;
      height: 40px;
      object-fit: scale-down;
    }
  }

  &__investor-block {
    border: 1px solid $smallBlockBorder;
    box-shadow: -2px 5px 12px -6px $lightGray;
    margin-bottom: 1.3rem;
    display: flex;
    align-items: center;
    border-radius: 5px;
    height: 80px;
  }

  .matching-score-default {
    width: 100px;
    height: 80px;
    font-size: 1.3rem;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    letter-spacing: 0;
    border: 0;
    @include multiple-media (mobileScreen, tabletScreen, smallScreen) {
      width: 70px;
      padding: 0;
    }
  }

  .greenDeal {
    user-select: none;
    background-color: $positive;
  }

  .yellowDeal {
    user-select: none;
    background-color: $yellow;
  }

  .redDeal {
    user-select: none;
    background-color: $red;
  }

  .grayDeal {
    user-select: none;
    background-color: $investmentTitle;
  }
  
  .greenDeal, .grayDeal, .yellowDeal, .redDeal {
    span {
      color: white;
    }
  }
  
  .whiteDeal span {
    color: black;
  }

  .whiteDeal {
    user-select: none;
    background-color: $white;
    color: $investmentTitle !important;
    border-right: 1px solid $investmentTitle !important;
    text-align: center;
  }

  .media {
    display: flex;
    align-items: center;
  }

  .loading-details {
    filter: blur(5px) grayscale(1);
  }

}
