.edit {
    position: relative;
    right: auto;
    top: auto;
    display: flex;
    align-items: center;
    &:hover {
        i {
            color: $brand;
        }
    }
    i {
        vertical-align: middle;
        @include transition (.3s all ease-in-out);
    }
    &__profile {
        color: $brand;
        margin-left: 1rem;
        @include breakpoint (toDesktop) {
            font-size: 2.3rem !important;
        }
    }
    &__invitation {
        color: $blue;
    }
    &__withdraw {
        color: $red;
    }
    &__resend {
        color: $blue;
    }
}

.selectize-input {
    z-index: 0;
}

.ladda-button {
    outline: none !important;
    font-size: 1.2rem !important;
    font-family: 'Montserrat', sans-serif;
}

.danger-txt {
    font-family: 'Montserrat', sans-serif;
}

.widget__title {
    font-size: 1.8rem;
    margin-top: 0;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: none;
    margin-bottom: 2.5rem;
    @include breakpoint (toDesktop) {
        font-size: 3.8rem;
        margin-bottom: 5.5rem;
        padding-top: 3rem;
    }
}

.widget__box {
    background-color: $white;
    padding: 2.5rem 4rem;
    margin-bottom: 2rem;
    @include breakpoint (toDesktop) {
        margin-bottom: 0;
    }
}

.deal-icon {
    width: 3.8rem;
    height: 3.8rem;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
    border: 1px solid $dealIconColor;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.5rem;
    background-color: $white;
    @include breakpoint (toDesktop) {
        width: 8rem;
        height: 8rem;
    }
    img {
        width: 100%;
    }
}

.borderMask {
    -webkit-box-shadow: 0px 11px 8px 0px #dddee2;
    box-shadow: 0px 11px 8px 0px #dddee2;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    content: '';
    display: block;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button,
input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
.edit__investor-clientTable {
    .checkbox {
        display: block;
        position: relative;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .checkbox input {
        position: absolute;
        opacity: 0;
    }
    .checkmark {
        position: absolute;
        top: -0.5rem;
        left: 0;
        height: 2.5rem;
        width: 2.5rem;
        background-color: $white;
        border: 2px solid $borderColor;
    }
    .checkbox:hover input~.checkmark {
        background-color: $white;
        border-color: $black;
    }
    .checkbox input:checked~.checkmark {
        background: url('/images/check.png');
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        /*--adjust as necessary--*/
        color: $black;
        position: absolute;
        border: 2px solid $black;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    .checkbox input:checked~.checkmark:after {
        display: block;
    }
    .checkbox .checkmark:after {
        display: none;
    }
}

.modelclose>span:first-child {
    margin-top: 3rem;
    font-size: 1.4rem;
}

.modal-content3 {
    height: 100vh;
    overflow: hidden;
    .modal-body2 {
        max-height: 75vh;
        overflow-y: scroll;
    }
}

.button {
    font-size: 2rem;
    width: 2rem;
    margin-left: 2rem;
    vertical-align: middle;
    display: inline-block;
    text-align: center;
    background: transparent;
    border-radius: 100%;
    padding: 0.5rem 0.75rem;
    @include transition (.3s all ease-in-out);
    &__approve {
        font-size: 1.1rem;
        font-weight: 500;
        color: $brand;
        text-transform: uppercase;
        letter-spacing: 0.2px;
    }
    &__delete {
        i {
            color: $red;
            @include transition (.3s all ease-in-out);
        }
        &:hover {
            background-color: $red;
            color: $white;
            i {
                color: $white;
            }
        }
    }
    &__remove {
        i {
            color: $dark-gray;
            @include transition (.3s all ease-in-out);
        }
        &:hover {
            background-color: $dark-gray;
            color: $white;
            i {
                color: $white;
            }
        }
    }
    &__confirm {
        i {
            color: $positive;
            @include transition (.3s all ease-in-out);
        }
        &:hover {
            background-color: $positive;
            color: $white;
            i {
                color: $white;
            }
        }
    }
    &__approve {
        @include transition (.3s all ease-in-out);
        display: inline-block;
        vertical-align: middle;
        cursor: pointer;
        &:hover {
            color: $brand;
        }
    }
    &__flags {
        display: inline-block;
        margin-right: 2rem;
        vertical-align: middle;
        i {
            color: $red;
            font-size: 2rem;
            display: inline-block;
            vertical-align: middle;
        }
        &-number {
            margin-left: 1rem;
            color: $red;
            font-size: 1.4rem;
            display: inline-block;
            vertical-align: middle;
        }
    }
    &Red {
        color: $red;
        &:hover {
            background-color: $red;
            color: $white;
        }
    }
    &Blue {
        color: $blue;
        &:hover {
            background-color: $blue;
            color: $white;
        }
    }
    &Yellow {
        color: $yellow;
        &:hover {
            background-color: $yellow;
            color: $white;
        }
    }
    &Green {
        color: $positive;
        &:hover {
            background-color: $positive;
            color: $white;
        }
    }
    &Orange {
        color: $brand;
        &:hover {
            background-color: $brand;
            color: $white;
        }
    }
}

.buttonBackground {
    color: $brand;
    padding: 1rem 1.5rem;
    cursor: pointer;
    @include transition (.3s all ease-in-out);
    &:hover {
        background-color: $brand;
        color: $white;
    }
}

.brandHover {
    background-color: $brand;
    color: $white;
    padding: 1rem 2rem;
}

.do-modalClose {
    background-color: transparent;
    text-transform: uppercase;
    font-size: 1.2rem;
    border: 0;
    color: $brand;
    padding: 0;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    @include transition (.3s all ease-in-out);
    &:hover {
        color: $darkerBrand;
    }
    @include breakpoint (toDesktop) {
        font-size: 2.2rem;
    }
}
.do-modalCloseLayer {
    background-color: transparent;
    text-transform: uppercase;
    font-size: 1.2rem;
    border: 0;
    color: $brand;
    padding: 0;
    font-weight: 500;
    outline: none;
    cursor: pointer;
    @include transition (.3s all ease-in-out);
    &:hover {
        color: $darkerBrand;
    }
}
a.do-button {
    &__brand {
        &:hover {
            background-color: transparent;
            color: $brand !important;
        }
    }
}

.ladda-button {
    &.do-button {
        &__brand {
            background: none;
            padding: 0;
            font-weight: 600;
            &:hover {
                background-color: transparent;
                color: $brand !important;
            }
            &[disabled],
            &[disabled]:hover {
                background: transparent;
                .ladda-spinner {
                    left: 15% !important;
                }
            }
        }
        &__red {
            background: none;
            padding: 0;
            font-weight: 600;
            color: $red !important;
            &:hover {
                background-color: transparent;
            }
            &[disabled],
            &[disabled]:hover {
                background: transparent;
                .ladda-spinner {
                    left: 15% !important;
                }
            }
        }
    }
  &.do-button {
    background: none;
    padding: 0;
    font-weight: 600;
    &[disabled], &[disabled]:hover {
      background: transparent;
      .ladda-spinner {
        left: 15% !important;
      }
    }
    &__brand {
      &:hover{
        background-color: transparent;
        color: $brand !important;
      }
    }
    &__black {
      color: $black !important;
      &:hover{
        background-color: transparent;
        color: $black !important;
      }
    }
  }
  &.do-button__red {
    background: none;
    padding: 0;
    font-weight: 600;
    color: $red !important;
    &:hover{
      background-color: transparent;
    }
    &[disabled], &[disabled]:hover {
      background: transparent;
      .ladda-spinner {
        left: 15% !important;
      }
    }
  }
}

.do-button {
    cursor: pointer;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: $black;
    font-weight: 500;
    @include transition(.3s all ease-in-out);
    &.activeLink {
        background-color: $white;
        padding: 0.7rem 2rem;
        color: $brand;
        &:hover {
            background-color: $brand;
            color: $white;
        }
    }
    &__brand {
        color: $brand !important;
        @include transition (.3s all ease-in-out);
        &:hover {
            background-color: $brand;
            color: $white !important;
        }
    }
    &__red {
        color: $red;
        &:hover {
            background-color: $red;
            color: $white;
        }
    }
    &__blue {
        color: $blue;
        &:hover {
            background-color: $blue;
            color: $white;
        }
    }
    &__yellow {
        color: $yellow;
        &:hover {
            background-color: $yellow;
            color: $white;
        }
    }
    &__green {
        color: $positive !important;
        &:hover {
            background-color: $positive;
            color: $white !important;
        }
    }
    &__normal {
        color: $black;
        @include transition (.3s all ease-in-out);
        &:hover {
            color: $brand;
        }
    }
    &.disabled {
        pointer-events: none;
        cursor: default;
        opacity: 0.5;
    }
}

.common__radio {
    display: block;
    position: relative;
    margin-bottom: 1.2rem;
    cursor: pointer;
    font-size: 2.2rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 2rem 0 2rem 4.5rem;
    border: 1px solid $borderColor;
    border-radius: 5px;
    .bigText {
        font-weight: 500;
        text-transform: uppercase;
        display: block;
        color: $black;
        font-size: 1.2rem;
        margin-bottom: 1rem;
    }
    .smallText {
        display: block;
        font-weight: 300;
        font-size: 1.2rem;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark {
        position: absolute;
        top: 18px;
        left: 10px;
        width: 18px;
        height: 18px;
        background-color: $white;
        border-radius: 50%;
        border: 1px solid $borderColor;
        &:after {
            width: 10px;
            height: 10px;
            top: 3.9px;
            left: 3.9px;
            border-radius: 50%;
            background: $brand;
        }
    }
    input:checked~.checkmark {
        background-color: $white;
    }
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }
    input:checked~.checkmark:after {
        display: block;
    }
}

.button__feedback {
    background-color: #F96F57;
}

.ladda-button {
    -webkit-font-smoothing: inherit !important;
    -webkit-tap-highlight-color: inherit !important;
}

button,
a {
    &.disabled {
        cursor: default !important;
        opacity: 0.5 !important;
        pointer-events: none;
    }
}
