.platform-tab {
	overflow: visible;
	height: 100%;
	@include multiple-media(mobileScreen, tabletScreen) {
		overflow-x: hidden;
	}
	.platform__block {
		overflow: visible;
		@include multiple-media(mobileScreen, tabletScreen) {
			flex-direction: column;
			overflow: auto;
			justify-content: flex-start;
			margin-right: -3rem;
			padding-right: 3rem;
			display: block;
			height: calc(100vh - 23rem);
		}
		&--left.do-user__item {
            padding: 20px 25px 10px 25px;
            height: 180px;
            margin-bottom: 25px;
            .media__image-big{
                margin: auto;
                height: 130px;
            }
			@include multiple-media(mobileScreen, tabletScreen) {
				display: flex;
				justify-content: center;
				width: 100%;
				box-sizing: border-box;
				margin-bottom: 2rem;
				flex: 100%;
			}
			.do-user__item-image {
				.do-user__item-change {
                    height: 120px;
                    width: 150px;
                    .investor-image {
                        height: 119px;
                    }
                    span {
                        background-color: #0000008f;
                        font-weight: 600;
                        font-size: 12px;
                    }
					@include multiple-media(mobileScreen, tabletScreen) {
						width: 15rem;
						height: 14.604rem;
					}
				}
			}
		}
        &--left {
            .box {
                width: 160px;
            }
           &.main-block {
               margin-right: 20px;
               .specialBox {
                   width: 210px;
                   overflow: visible;
                   h2 {
                       font-size: 12px;
                   }
                   .common__info-box-left {
                       width: 100%;
                   }
               }
           }
           &.do-user__item:last-child {
               margin-left: 0;
           }
        }
		&--right.do-user__item {
			padding: 0;
			overflow: hidden;
			height: auto;
			max-height: initial;
            margin-left: 0;
            @include multiple-media(mobileScreen, tabletScreen) {
				margin-left: 0;
				margin-bottom: auto;
				box-sizing: border-box;
				height: auto;
				flex: initial;
				position: relative;
				overflow: visible;
				display: block;
			}
			.color__picker {
				padding-bottom: 2rem;
				.common__info {
					&-title {
						padding: 1.5rem 2rem;
						margin-bottom: 0;
					}
					&-box-left {
						margin: 0;
						padding: 1rem 2rem;
                        font-weight: 600;
						@include breakpoint(mobileScreen) {
							width: 75%;
							flex: 75%;
						}
					}
				}
				.input-group.color-picker {
					margin-left: 2rem;
					margin-bottom: 0;
                    max-width: 80%;
                    overflow: hidden;
                    padding: 2px 3px;
                    border-radius: 5px;
                    @include multiple-media(mobileScreen, tabletScreen) {
						width: 100%;
						overflow: hidden;
						border-radius: .3rem;
						padding: 0.2rem 0.5rem 0;
						flex: 100%;
						box-sizing: border-box;
					}
					@include breakpoint(mobileScreen) {
						max-width: calc(100vw - 8.2rem);
					}
					.vc-chrome {
						margin-bottom: 3rem;
						margin-left: 2rem;
						left: 0;
						@include breakpoint(mobileScreen) {
							top: -16rem;
							@media (max-width:405px) {
								width: calc(100vw - 8rem);
								top: initial;
								bottom: 4.4rem;
							}
						}
						@include breakpoint(tabletScreen) {
							top: 16.3rem;
						}
						@include multiple-media(smallScreen, mediumScreen, largeScreen) {
							top: 18rem;
						}
					}
				}
				.input-error {
					color: $color-red-main;
					font-size: 1.2rem;
					padding-left: 2rem;
				}
			}
		}
        .platform-personalisation {
            padding-bottom: 20px;
            &__header {
                padding: 25px 20px;
                display: flex;
                border-bottom: 1px #00000026 solid;
                &__title {
                    font-size: 18px;
                    font-weight: bold;
                }
                &__preview-button {
                    margin-left: auto;
                    &__link {
                        border: 0;
                        height: auto;
                        text-transform: uppercase;
                        min-width: auto;
                        padding: 0;
                        cursor: pointer;
                        color: $brand;
                        text-align: inherit;
                        font-size: 13px;
                        font-weight: 600;
                    }
                    &__link:hover {
                        background-color: transparent;
                        color: $darkerBrand;
                    }
                }
            }
            &__form {
                padding: 10px 20px;
                .label {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
                .section {
                    &:first-child {
                        margin-top: 10px;
                    }
                    margin-top: 20px;
                }
                &__description {
                    &__input {
                        .ql-container{
                            border-radius: 0 5px 5px 5px;
                        }
                        .ql-blank {
                            background-color: $darkerWhite;
                        }
                        .ql-editor {
                            background-color: $darkerWhite;
                        }
                        .ql-toolbar {
                            border-radius: 5px;
                            .ql-formats:last-child {
                                border: 1px solid #e6e9ed;
                                border-top: 0;
                                border-bottom: 0;
                            }
                            .ql-snow {
                                background-color: $white;
                            }
                        }
                    }
                }
                &__title {
                    input.hubx-input {
                        background-color: $darkerWhite;
                    }
                }
                &__background {
                    &__image-holder {
                        &__image {
                            height: 150px;
                            position: relative;
                            background: #434a54 none repeat 50% 50%;
                            background-size: auto;
                            &__input-holder {
                                visibility: hidden;
                                input {
                                    opacity: 0;
                                    width: 20rem;
                                    height: 10rem;
                                    left: 2rem;
                                    top: 2rem;
                                    position: absolute;
                                    z-index: 9999;
                                    visibility: hidden;
                                }
                                &__button {
                                    cursor: pointer;
                                    position: absolute;
                                    top: 2rem;
                                    left: 2rem;
                                    background-color: $white;
                                    border-radius: 3px;
                                    padding: 14px 25px;
                                    text-transform: uppercase;
                                    color: $brand;
                                    font-weight: bold;
                                    visibility: visible;
                                    z-index: 997;
                                    transition: all .2s ease;
                                    &:hover {
                                        background-color: $color-white-1;
                                    }
                                }
                            }
                        }

                        .loading-info {
                            text-align: center;
                        }
                    }
                }
                &__submit {
                    padding-top: 20px;
                    &__button {
                        width: 40px;
                        padding: 12px 25px;
                        font-size: 14px;
                        background-color: $brand;
                        color: $white;
                        border-radius: 3px;
                        font-weight: 500;
                        cursor: pointer;
                        text-transform: uppercase;
                        &:hover {
                            background-color: $brandHover;
                        }
                    }
                }
            }
        }
	}
}
